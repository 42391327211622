import { FormModal } from 'common-front/src/designSystem/components/formModal';
import { TextInput } from 'common-front/src/designSystem/form/textInput';
import { OrganizationId, TagId } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { useTagUpdateNameMutation } from '../../generated/graphqlHooks';

interface ITagUpdateNameModalProps {
    organizationId: OrganizationId;
    name: string;
    tagId: TagId;

    onClose(): void;
    onSuccess(): void;
}

export const TagUpdateNameModal = (props: ITagUpdateNameModalProps) => {
    const translate = useTranslate();
    const { mutate } = useTagUpdateNameMutation();

    return (
        <FormModal
            buttonText={translate('renommer_42135')}
            icon="tag"
            initialValues={{ name: props.name }}
            title={translate('renommer_1_40542', props.name)}
            onClose={props.onClose}
            onSubmit={async (values: { name: string }) => {
                await mutate({
                    organizationId: props.organizationId,
                    tagId: props.tagId,
                    name: values.name
                });

                props.onSuccess();
                props.onClose();
            }}
        >
            <TextInput label={translate('nom_du_tag_95054')} name="name" />
        </FormModal>
    );
};
