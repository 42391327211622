import { MapInput } from 'common-front/src/components/map/mapInput';
import { LabelOptional } from 'common-front/src/designSystem/components/input/labelOptional';
import { AddressInput } from 'common-front/src/designSystem/form/addressInput';
import { FloatInput } from 'common-front/src/designSystem/form/floatInput';
import { RichSelect } from 'common-front/src/designSystem/form/richSelect';
import { Select } from 'common-front/src/designSystem/form/select/select';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { Event, MapLayer, TraceFragment, TraceId } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { Emptyable } from 'common/src/util/emptyable';
import { extractCoordinates } from 'common/src/util/map';
import { getCenter } from 'common/src/vo/event';
import { range } from 'lodash-es';
import * as React from 'react';

interface ILocalisationFormProps {
    center: Emptyable<Pick<Event, 'latitude' | 'longitude'>>;
    layer: Emptyable<MapLayer>;
    name: string;
    point: { latitude?: Emptyable<number>; longitude?: Emptyable<number> };
    traces: TraceFragment[];
    tracesIds: TraceId[];
}

export const LocationForm = (props: ILocalisationFormProps) => {
    const translate = useTranslate();

    return (
        <Flex gap="7">
            <Flex css={{ flex: '1' }} direction="column">
                <Box color="gray800" fontWeight="semiBold">
                    {translate('apparence_75510')}
                </Box>

                <Spacer height="3" />

                <Select label={translate('fond_de_carte_43822')} name={`${props.name}layer`}>
                    <option value={MapLayer.Google}>Plan</option>
                    <option value={MapLayer.GoogleSatellite}>Satellite</option>
                    <option value={MapLayer.IgnPlan}>IGN Plan</option>
                </Select>

                <Spacer height="6" />

                <Select
                    label={<LabelOptional>{translate('niveau_de_zoom_39369')}</LabelOptional>}
                    name={`${props.name}zoom`}
                    shouldParseAsInt={true}
                >
                    {range(0, 23).map((i) => (
                        <option key={i} value={i}>
                            {i}
                        </option>
                    ))}
                </Select>

                {props.traces.length > 0 && (
                    <>
                        <Spacer height="6" />

                        <RichSelect
                            isSearchVisible={true}
                            label={<LabelOptional>{translate('trac_s_41454')}</LabelOptional>}
                            multiple={true}
                            name={`${props.name}tracesIds`}
                        >
                            {props.traces.map((trace) => (
                                <option key={trace.id} value={trace.id}>
                                    {trace.name}
                                </option>
                            ))}
                        </RichSelect>
                    </>
                )}

                <Spacer height="7" />

                <Box color="gray800" fontWeight="semiBold">
                    {translate('localisation_38416')}
                </Box>

                <Spacer height="3" />

                <AddressInput
                    addressName={`${props.name}address`}
                    label={<LabelOptional>{translate('adresse_de_la_m_57494')}</LabelOptional>}
                    latitudeName={`${props.name}latitude`}
                    longitudeName={`${props.name}longitude`}
                />

                <Spacer height="6" />

                <Flex gap="4">
                    <FloatInput
                        label={<LabelOptional>{translate('latitude_46176')}</LabelOptional>}
                        name={`${props.name}latitude`}
                    />

                    <FloatInput
                        label={<LabelOptional>{translate('longitude_01695')}</LabelOptional>}
                        name={`${props.name}longitude`}
                    />
                </Flex>
            </Flex>

            <Box css={{ flex: '1' }}>
                <MapInput
                    center={getCenter(props.center)}
                    layer={props.layer}
                    point={extractCoordinates(props.point)}
                    prefix={props.name}
                    traces={props.traces.filter((t) => props.tracesIds.includes(t.id))}
                />
            </Box>
        </Flex>
    );
};
