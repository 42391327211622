import * as React from 'react';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { BadgeReimsBack } from './badgeReimsBack';
import { BadgeReimsFront } from './badgeReimsFront';

interface IBagdeReimsProps {
    event: DocumentUserCustomBadgeFragment;
}

export const BadgeReims = (props: IBagdeReimsProps) => (
    <>
        <BadgeReimsFront event={props.event} />

        <BadgeReimsBack event={props.event} />
    </>
);
