import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { PositionsPlanning } from 'common/src/documents/positionsPlanning/positionsPlanning';
import { Predicates } from 'common/src/vo/segment';
import * as React from 'react';
import { usePositionsCalendarQuery } from '../../../generated/graphqlHooks';

interface IPositionsCalendarProps {
    name: string;
    predicates: Predicates;
}

export const PositionsCalendar = (props: IPositionsCalendarProps) => {
    const {
        params: { eventId }
    } = useHeavent();
    const { data, loader } = usePositionsCalendarQuery({
        eventId,
        name: props.name,
        predicates: props.predicates
    });

    if (loader) {
        return loader;
    } else {
        return (
            <Flex css={{ flex: '1', overflow: 'hidden' }} width={1}>
                <Box css={{ overflow: 'auto' }} height={1} width={1}>
                    <PositionsPlanning
                        event={{
                            name: '',
                            segment: { name: '' },
                            positionsSlots: data.event.positionsSlots
                        }}
                        hideHeader={true}
                    />
                </Box>
            </Flex>
        );
    }
};
