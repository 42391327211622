import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import { Route, Switch } from 'react-router';
import { PositionMobile } from './positionMobile';
import { PositionSlotCheckInSheetMobile } from './positionSlotCheckInSheetMobile/positionSlotCheckInSheetMobile';
import { PositionSlotMembersCheckInMobile } from './positionSlotMembers/positionSlotMembersCheckInMobile';
import { PositionSlotMembersCheckOutMobile } from './positionSlotMembers/positionSlotMembersCheckOutMobile';
import { PositionSlotMembersCheckResetMobile } from './positionSlotMembers/positionSlotMembersCheckResetMobile';
import { PositionSlotMembersMobile } from './positionSlotMembers/positionSlotMembersMobile';
import { PositionSlotUserSheetMobile } from './positionSlotUserSheetMobile/positionSlotUserSheetMobile';

export const PositionSwitchMobile = () => (
    <>
        <Switch>
            <Route
                path={HeaventPaths.POSITION_SLOT_CHECK_MEMBERS_CHECK_IN(
                    ':organizationId',
                    ':eventId',
                    ':positionId',
                    ':positionSlotId'
                )}
            >
                <PositionSlotMembersCheckInMobile />
            </Route>
            <Route
                path={HeaventPaths.POSITION_SLOT_CHECK_MEMBERS_CHECK_OUT(
                    ':organizationId',
                    ':eventId',
                    ':positionId',
                    ':positionSlotId'
                )}
            >
                <PositionSlotMembersCheckOutMobile />
            </Route>
            <Route
                path={HeaventPaths.POSITION_SLOT_CHECK_MEMBERS_RESET(
                    ':organizationId',
                    ':eventId',
                    ':positionId',
                    ':positionSlotId'
                )}
            >
                <PositionSlotMembersCheckResetMobile />
            </Route>
            <Route
                path={HeaventPaths.POSITION_SLOT_MEMBERS(
                    ':organizationId',
                    ':eventId',
                    ':positionId',
                    ':positionSlotId'
                )}
            >
                <PositionSlotMembersMobile />
            </Route>
            <Route>
                <PositionMobile />
            </Route>
        </Switch>
        <Switch>
            <Route
                path={HeaventPaths.POSITION_SLOT_MEMBERS_CHECK_OPTIONS(
                    ':organizationId',
                    ':eventId',
                    ':positionId',
                    ':positionSlotId'
                )}
            >
                <PositionSlotCheckInSheetMobile />
            </Route>
            <Route
                path={HeaventPaths.POSITION_SLOT_MEMBERS_USER(
                    ':organizationId',
                    ':eventId',
                    ':positionId',
                    ':positionSlotId',
                    ':userInfoId'
                )}
            >
                <PositionSlotUserSheetMobile />
            </Route>
        </Switch>
    </>
);
