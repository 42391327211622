import { DeleteModal } from 'common-front/src/designSystem/components/deleteModal';
import { DropdownEllipsis } from 'common-front/src/designSystem/components/dropdown/dropdownEllipsis';
import { ItemIcon } from 'common-front/src/designSystem/components/dropdown/item';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { CellControls } from 'common/src/designSystem/components/table/cellControls';
import { Row } from 'common/src/designSystem/components/table/row';
import { Event, PositionSlotsQuery, PositionsSlotInput } from 'common/src/generated/types';
import { usePositionSlotIsRenamedRange } from 'common/src/hooks/usePositionSlotIsRenamedRange';
import { PositionsSlotInputService } from 'common/src/input/positionsSlotInput';
import { useService } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import { useEventContext } from '../../../events/show/eventContext';
import {
    usePositionSlotDeleteMutation,
    usePositionSlotUpdateMutation,
    usePositionSlotUsersInfosCheckInMutation,
    usePositionSlotUsersInfosCheckOutMutation
} from '../../../generated/graphqlHooks';
import { CreatePositionCreateSlotModal } from '../../create/createPositionCreateSlotModal';
import { PositionRowResources } from '../../list/list/positionRowResources';

interface IPositionSlotsSlotRowProps {
    event: Pick<Event, 'startAt' | 'endAt'>;
    slot: PositionSlotsQuery['event']['positionsSlots']['nodes'][0];

    reload(): void;
}

export const PositionSlotsSlotRow = (props: IPositionSlotsSlotRowProps) => {
    const {
        history,
        translate,
        params: { organizationId, eventId }
    } = useHeavent();
    const { isPositionAdmin, isPositionViewer } = useEventContext();
    const positionsSlotInput = useService(PositionsSlotInputService);
    const { mutate: positionSlotUpdate } = usePositionSlotUpdateMutation();
    const { mutate: checkIn } = usePositionSlotUsersInfosCheckInMutation();
    const { mutate: checkOut } = usePositionSlotUsersInfosCheckOutMutation();
    const { mutate: positionSlotDelete } = usePositionSlotDeleteMutation();
    const { isRenamed, finalName, rangeString } = usePositionSlotIsRenamedRange(props.slot);
    const [isEditOpen, setIsEditOpen] = React.useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);

    return (
        <>
            <Row
                css={{
                    cursor: 'pointer'
                }}
                onClick={() => {
                    history.push(
                        HeaventPaths.POSITION_SLOT_MEMBERS(
                            organizationId,
                            eventId,
                            props.slot.positionId,
                            props.slot.id
                        )
                    );
                }}
            >
                <Cell align="start" direction="column" justify="center">
                    <Box font="gray900 textSm medium">{finalName}</Box>

                    {isRenamed && <Box color="gray500">{rangeString}</Box>}
                </Cell>

                <Cell>
                    <PositionRowResources
                        assignedResources={props.slot.assignedResources}
                        resources={props.slot.resources}
                    />
                </Cell>

                <CellControls justify="end">
                    <DropdownEllipsis>
                        {isPositionAdmin(props.slot.positionCategoryId, props.slot.positionId) && (
                            <>
                                <ItemIcon
                                    icon="wand-magic-sparkles"
                                    to={HeaventPaths.POSITION_SLOT_ASSIGN_VOLUNTEERS(
                                        organizationId,
                                        eventId,
                                        props.slot.positionId,
                                        props.slot.id
                                    )}
                                >
                                    {translate('affecter_des_me_30349')}
                                </ItemIcon>

                                <ItemIcon
                                    icon="pen"
                                    onClick={() => {
                                        setIsEditOpen(true);
                                    }}
                                >
                                    {translate('_diter_62574')}
                                </ItemIcon>

                                <ItemIcon
                                    color={props.slot.assignedResources > 0 ? 'disabled' : 'red'}
                                    icon="trash-can"
                                    onClick={() => {
                                        setIsDeleteModalOpen(true);
                                    }}
                                >
                                    {translate('supprimer_43083')}
                                </ItemIcon>
                            </>
                        )}
                        {isPositionViewer(props.slot.positionCategoryId, props.slot.positionId) && (
                            <>
                                <ItemIcon
                                    icon="clock"
                                    onClick={async () => {
                                        await checkIn({
                                            eventId,
                                            positionSlotId: props.slot.id
                                        });
                                    }}
                                >
                                    {translate('pointer_les_mem_50347')}
                                </ItemIcon>

                                <ItemIcon
                                    icon="clock"
                                    onClick={async () => {
                                        await checkOut({
                                            eventId,
                                            positionSlotId: props.slot.id
                                        });
                                    }}
                                >
                                    {translate('d_pointer_les_m_17150')}
                                </ItemIcon>
                            </>
                        )}
                    </DropdownEllipsis>
                </CellControls>
            </Row>

            {isEditOpen && (
                <CreatePositionCreateSlotModal
                    event={props.event}
                    slot={positionsSlotInput.positionsSlotInputDefault(
                        props.event.startAt,
                        props.slot
                    )}
                    onClose={() => {
                        setIsEditOpen(false);
                    }}
                    onSuccess={async (updatedSlot: PositionsSlotInput) => {
                        await positionSlotUpdate({
                            eventId,
                            positionId: props.slot.positionId,
                            positionSlotId: props.slot.id,
                            positionSlot: updatedSlot
                        });

                        props.reload();
                    }}
                />
            )}

            {isDeleteModalOpen && (
                <DeleteModal
                    buttonText={translate('supprimer_43083')}
                    subtext={[translate('_tes_vous_s_r_d_48876')]}
                    text={translate('suppression_de_73669', finalName)}
                    onClose={() => {
                        setIsDeleteModalOpen(false);
                    }}
                    onDelete={async () => {
                        await positionSlotDelete({
                            eventId,
                            positionId: props.slot.positionId,
                            positionSlotId: props.slot.id
                        });

                        props.reload();
                    }}
                />
            )}
        </>
    );
};
