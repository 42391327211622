import { Button } from 'common-front/src/designSystem/components/button';
import { Dropdown } from 'common-front/src/designSystem/components/dropdown/dropdown';
import { ItemIcon } from 'common-front/src/designSystem/components/dropdown/item';
import { Menu } from 'common-front/src/designSystem/components/dropdown/menu';
import { Trigger } from 'common-front/src/designSystem/components/dropdown/trigger';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { RichText } from 'common/src/designSystem/components/richEditor/richText';
import { FormsElementId } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { Draggable, DraggableProvided } from 'react-beautiful-dnd';
import { AddTextModal } from './addTextModal';

interface IFormTextProps {
    elementId: FormsElementId;
    index: number;
    text: string;

    onEdit(index: number, text: string): void;
    onDelete(index: number): void;
}

export const FormText = (props: IFormTextProps) => {
    const translate = useTranslate();
    const [isEditOpen, setIsEditOpen] = React.useState(false);

    return (
        <>
            <Draggable draggableId={props.elementId} index={props.index}>
                {(draggableProvided: DraggableProvided) => (
                    <Flex
                        align="center"
                        css={{
                            bd: true,
                            padding: '$4 $6'
                        }}
                        gap="5"
                        {...draggableProvided.draggableProps}
                        ref={draggableProvided.innerRef}
                    >
                        <Box
                            color="gray300"
                            css={{ cursor: 'grab' }}
                            fontSize="textMd"
                            {...draggableProvided.dragHandleProps}
                        >
                            <I icon="grip-vertical" iconStyle="solid" />
                        </Box>

                        <Box css={{ flex: '1' }}>
                            <RichText text={props.text} />
                        </Box>

                        <Dropdown>
                            <Trigger>
                                <Box>
                                    <Button color="white" leftIcon="ellipsis-vertical" size="sm" />
                                </Box>
                            </Trigger>

                            <Menu placement="bottom-end">
                                <ItemIcon
                                    icon="pen"
                                    onClick={() => {
                                        setIsEditOpen(true);
                                    }}
                                >
                                    {translate('_diter_62574')}
                                </ItemIcon>

                                <ItemIcon
                                    color="red"
                                    icon="trash-can"
                                    onClick={() => {
                                        props.onDelete(props.index);
                                    }}
                                >
                                    {translate('supprimer_43083')}
                                </ItemIcon>
                            </Menu>
                        </Dropdown>
                    </Flex>
                )}
            </Draggable>

            {isEditOpen && (
                <AddTextModal
                    initialText={props.text}
                    onAdd={(text) => {
                        props.onEdit(props.index, text);
                    }}
                    onClose={() => {
                        setIsEditOpen(false);
                    }}
                />
            )}
        </>
    );
};
