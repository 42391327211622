import { Blank } from 'common-front/src/components/blank/blank';
import { Page } from 'common-front/src/components/page/page';
import { TableFilters } from 'common-front/src/designSystem/components/tableFilters';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { usePaginationInfos } from 'common-front/src/hooks/usePaginationInfos';
import { useStateDebounce } from 'common-front/src/hooks/useStateDebounce';
import { Svgs } from 'common-front/src/util/assets';
import { Box } from 'common/src/designSystem/components/box';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { HeaderCellSort } from 'common/src/designSystem/components/table/headerCellSort';
import { Row } from 'common/src/designSystem/components/table/row';
import { RowSkeleton } from 'common/src/designSystem/components/table/rowSkeleton';
import {
    PositionsSlotId,
    SegmentCustomFieldFragment,
    SortDirection,
    VolunteersRegistrationsSortAttributes
} from 'common/src/generated/types';
import { isNonEmptyArray } from 'common/src/util/array';
import { useService } from 'common/src/util/dependencies/dependencies';
import { Emptyable } from 'common/src/util/emptyable';
import { PositionsPaths } from 'common/src/util/paths/positionsPaths';
import { UserInfoIdPathParam } from 'common/src/util/pathsTypes';
import { isNonEmptyString } from 'common/src/util/string';
import { isSortable } from 'common/src/vo/segment';
import { PositionMembersSegmentsService } from 'common/src/vo/segments/positionMembersSegmentsService';
import { VolunteerDefaultColumns } from 'common/src/vo/segments/volunteersSegmentsService';
import { Sort } from 'common/src/vo/sort';
import { compact } from 'lodash-es';
import * as React from 'react';
import { Route, Switch } from 'react-router';
import { useEventContext } from '../../../events/show/eventContext';
import { usePositionMembersQuery } from '../../../generated/graphqlHooks';
import { UserOverlayEvent } from '../../../users/overlay/userOverlay';
import { PositionMemberRow } from './positionMemberRow';
import { PositionMembersFilters } from './positionMembersFilters';

interface IPositionMembersProps {
    customFields: SegmentCustomFieldFragment[];
}

export const PositionMembers = ({ customFields }: IPositionMembersProps) => {
    /*
     *  Hooks
     */
    const {
        history,
        params: { organizationId, eventId, positionId },
        translate
    } = useHeavent();

    const positionMembersSegmentService = useService(PositionMembersSegmentsService);

    /*
     *  Component Variables
     */

    // Context variables
    const isEventAdmin = useEventContext().event.permissions.isEventAdmin;

    // State variables
    const [filterSlotId, setFilterSlotId] = React.useState<Emptyable<PositionsSlotId>>(null);
    const [offset, setOffset] = React.useState(0);
    const [sort, _setSort] = React.useState<Sort<VolunteersRegistrationsSortAttributes> | null>({
        attribute: VolunteersRegistrationsSortAttributes.Name,
        direction: SortDirection.Asc
    });
    const [search, searchDebounced, setSearch] = useStateDebounce('');
    const setSort = (newSort: Sort<VolunteersRegistrationsSortAttributes> | null) => {
        _setSort(newSort);
        setOffset(0);
    };

    // Memoised variables
    const possibleColumns = React.useMemo(
        () => positionMembersSegmentService.getPositionMembersPossibleColumns(customFields),
        []
    );
    const selectedFields = React.useMemo(
        () =>
            [VolunteerDefaultColumns.Name, VolunteerDefaultColumns.Positions].flatMap((slug) =>
                compact([possibleColumns.find((pc) => pc.slug === slug)])
            ),
        [possibleColumns]
    );

    /*
     *  Queries & Mutations
     */
    const includes = {
        includePositions: true
    };
    const { data, loader, isLoading, reload } = usePositionMembersQuery({
        ...includes,
        organizationId,
        eventId,
        positionId,
        positionSlotId: filterSlotId,
        name: isNonEmptyString(searchDebounced) ? searchDebounced : null,
        offset,
        sort
    });
    const { numberOfPages, totalCount } = usePaginationInfos(
        data.organization?.event.volunteersRegistrations
    );

    // TODO - update after #177 merged
    return (
        loader || (
            <Page.Content>
                <TableFilters
                    filters={
                        <PositionMembersFilters
                            eventId={eventId}
                            filterSlotId={filterSlotId}
                            positionSlots={data.organization.event.position.slots}
                            search={search}
                            setFilterSlotId={setFilterSlotId}
                            setOffset={setOffset}
                            setSearch={setSearch}
                        />
                    }
                    headerCells={
                        <>
                            {selectedFields.map((field, index) => {
                                const firstCellCss =
                                    index === 0 && isEventAdmin ? { paddingLeft: '$3' } : {};

                                switch (field.slug) {
                                    case VolunteerDefaultColumns.Name:
                                        return (
                                            <HeaderCellSort
                                                key={field.slug}
                                                attribute={
                                                    VolunteersRegistrationsSortAttributes.Name
                                                }
                                                css={firstCellCss}
                                                setSort={setSort}
                                                sort={sort}
                                            >
                                                {field.name}
                                            </HeaderCellSort>
                                        );
                                    case VolunteerDefaultColumns.Positions:
                                        return (
                                            <HeaderCell key={field.slug} css={firstCellCss}>
                                                {translate('cr_neaux_affect_18718')}
                                            </HeaderCell>
                                        );
                                    default:
                                        return isSortable(field) ? (
                                            <HeaderCellSort
                                                key={field.slug}
                                                attribute={field.slug}
                                                css={firstCellCss}
                                                setSort={setSort}
                                                sort={sort}
                                            >
                                                <Box css={{ ellipsis: true }} title={field.name}>
                                                    {field.name}
                                                </Box>
                                            </HeaderCellSort>
                                        ) : (
                                            <HeaderCell key={field.slug} css={firstCellCss}>
                                                <Box css={{ ellipsis: true }} title={field.name}>
                                                    {field.name}
                                                </Box>
                                            </HeaderCell>
                                        );
                                }
                            })}

                            <HeaderCell width={100} />
                        </>
                    }
                    numberOfPages={numberOfPages}
                    offset={offset}
                    rows={
                        isLoading ? (
                            <>
                                <RowSkeleton bx={true} />
                                <RowSkeleton bx={true} />
                                <RowSkeleton bx={true} />
                            </>
                        ) : isNonEmptyArray(
                              data.organization.event.volunteersRegistrations.nodes
                          ) ? (
                            (data.organization?.event.volunteersRegistrations.nodes ?? []).map(
                                (member) => (
                                    <PositionMemberRow
                                        key={member.id}
                                        customBadges={data.organization.customBadges}
                                        customFields={customFields}
                                        member={member}
                                        reload={reload}
                                        selectedFields={selectedFields}
                                    />
                                )
                            )
                        ) : (
                            <Row css={{ height: '200px' }}>
                                <Cell>
                                    <Blank
                                        imageSrc={Svgs.FormsElementsBlank}
                                        title={translate('aucun_membre_da_58845')}
                                    />
                                </Cell>
                            </Row>
                        )
                    }
                    setOffset={setOffset}
                    title={translate('list_of_1_m_28968', data.organization.event.position.name)}
                    totalCount={totalCount}
                />

                <Switch>
                    <Route
                        path={PositionsPaths.POSITION_MEMBER({
                            organizationId: ':organizationId',
                            eventId: ':eventId',
                            positionId: ':positionId',
                            userInfoId: ':userInfoId'
                        })}
                    >
                        <UserOverlayEvent
                            getBasePath={(
                                userInfoId: UserInfoIdPathParam,
                                isRouteComponent?: boolean
                            ) =>
                                PositionsPaths.POSITION_MEMBER({
                                    organizationId: isRouteComponent
                                        ? ':organizationId'
                                        : organizationId,
                                    eventId: isRouteComponent ? ':eventId' : eventId,
                                    positionId: isRouteComponent ? ':positionId' : positionId,
                                    userInfoId: isRouteComponent ? ':userInfoId' : userInfoId
                                })
                            }
                            onClose={() =>
                                history.push(
                                    PositionsPaths.POSITION_MEMBERS({
                                        organizationId,
                                        eventId,
                                        positionId
                                    })
                                )
                            }
                            onDelete={() => {
                                history.push(
                                    PositionsPaths.POSITION_MEMBERS({
                                        organizationId,
                                        eventId,
                                        positionId
                                    })
                                );

                                reload();
                            }}
                            onUpdateState={reload}
                        />
                    </Route>
                </Switch>
            </Page.Content>
        )
    );
};
