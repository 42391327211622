import { Button } from 'common-front/src/designSystem/components/button';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import * as React from 'react';
import {
    useTeamAssignmentRefuseMutation,
    useTeamAssignMutation
} from '../../generated/graphqlHooks';
import { useAssignmentContext } from '../assign/assignmentContext';

export const TeamAssignmentButtons = () => {
    const {
        translate,
        params: { eventId, teamCode }
    } = useHeavent();
    const { positionsSlotsIds, showNextVolunteer } = useAssignmentContext();
    const { mutate: teamAssignmentRefuse, isLoading: refuseIsLoading } =
        useTeamAssignmentRefuseMutation();
    const { mutate: teamAssign, isLoading: assignIsLoading } = useTeamAssignMutation();

    return (
        <Flex
            align="center"
            css={{
                borderTop: '1px solid $gray200',
                px: '$7'
            }}
            gap="4"
            height={1}
            width={1}
        >
            <Box
                color="error700"
                css={{ cursor: 'pointer' }}
                onClick={async () => {
                    if (!assignIsLoading && !refuseIsLoading) {
                        await teamAssignmentRefuse({
                            eventId,
                            teamCode
                        });

                        showNextVolunteer();
                    }
                }}
            >
                {translate('refuser_l_quip_29246')}
            </Box>

            <Box css={{ flex: '1' }} />

            <Button
                color="primary"
                isLoading={assignIsLoading}
                onClick={async () => {
                    if (!assignIsLoading && !refuseIsLoading) {
                        await teamAssign({
                            eventId,
                            teamCode,
                            assignment: { positionsSlotsIds }
                        });

                        showNextVolunteer();
                    }
                }}
            >
                {translate('affecter_l_qui_23291')}
            </Button>

            <Button color="white" onClick={showNextVolunteer}>
                {translate('affecter_l_qui_37763')}
            </Button>
        </Flex>
    );
};
