import { AccreditAssignGrid } from 'common-front/src/accreditAssign/accreditAssignGrid';
import { AccreditAssignHeader } from 'common-front/src/accreditAssign/accreditAssignHeader';
import { AccreditAssignLeftPanel } from 'common-front/src/accreditAssign/leftPanel/accreditAssignLeftPanel';
import { useAccreditAssignWaitingMembersQuery } from 'common-front/src/generated/graphqlHooks';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useStateDebounce } from 'common-front/src/hooks/useStateDebounce';
import {
    AccreditationState,
    AccreditInfosQuery,
    DelegationId,
    SegmentId,
    SortDirection
} from 'common/src/generated/types';
import { useParams } from 'common/src/util/dependencies/dependencies';
import { Emptyable } from 'common/src/util/emptyable';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { isNonEmptyString } from 'common/src/util/string';
import { getAccreditationStateBadgeColorIcon } from 'common/src/vo/volunteerRegistration';
import * as React from 'react';
import { AssignmentUserPanel } from '../../assignments/assign/userPanel/assignmentUserPanel';
import { useAccreditInfosQuery } from '../../generated/graphqlHooks';
import { useLocalEventState } from '../../hooks/useLocalState';
import { AccreditButtons } from './accreditButtons';
import { AccreditContextProvider } from './accreditContext';
import { AccreditFiltersCategories } from './accreditFiltersCategories';

interface IAccreditComponentProps {
    event: AccreditInfosQuery['event'];
    organization: AccreditInfosQuery['organization'];
}

const AccreditComponent = (props: IAccreditComponentProps) => {
    const {
        history,
        params: { organizationId, eventId, userInfoId }
    } = useHeavent();
    const [isLeftPanelOpen, setIsLeftPanelOpen] = useLocalEventState(
        'accredit.isLeftPanelOpen',
        true
    );
    const [isRightPanelOpen, setIsRightPanelOpen] = useLocalEventState(
        'accredit.isRightPanelOpen',
        true
    );
    const [name, nameDebounced, setName] = useStateDebounce('');
    const [direction, setDirection] = useLocalEventState(
        'accredit.leftPanelDirection',
        SortDirection.Desc
    );
    const [segmentId, setSegmentId] = useLocalEventState<SegmentId>(
        'accredit.leftPanelSegmentId',
        -1 as SegmentId
    );
    const [delegationId, setDelegationId] = useLocalEventState<Emptyable<DelegationId>>(
        'accredit.leftPanelDelegationId',
        null
    );
    const { data, isLoading } = useAccreditAssignWaitingMembersQuery({
        eventId,
        accreditationStates: [AccreditationState.WaitingAccreditation],
        name: isNonEmptyString(nameDebounced) ? nameDebounced : undefined,
        segmentId: segmentId === -1 ? undefined : segmentId,
        delegationId: delegationId ? delegationId : undefined,
        direction,
        loadDelegations: true
    });
    const showNextMember = React.useCallback(() => {
        const vrs = data.event?.volunteersRegistrations.nodes ?? [];
        const currentMemberIndex = vrs.findIndex((vr) => vr.userInfo.id === userInfoId);
        const nextMemberId = vrs[currentMemberIndex + 1]?.userInfo.id ?? vrs[0].userInfo.id;

        if (nextMemberId === userInfoId) {
            history.push(HeaventPaths.ACCREDITATIONS_ASSIGNMENTS(organizationId, eventId));
        } else {
            history.push(HeaventPaths.ACCREDIT(organizationId, eventId, nextMemberId));
        }
    }, [organizationId, eventId, userInfoId, data.event]);
    const vr = props.event.volunteerRegistration;
    const isEdit = React.useMemo(
        () => vr.accreditationState === AccreditationState.Accredited,
        [vr]
    );
    const accreditationsSlotsIds = React.useMemo(
        () => [
            ...vr.accreditationsUsersInfos.map(({ accreditationSlotId }) => accreditationSlotId),
            ...(isEdit
                ? []
                : vr.accreditationsSlots.flatMap((as) => {
                      const da = vr.delegation?.accreditationsSlots.find(
                          (acc) => acc.accreditationSlotId === as.id
                      );
                      const isMaxedOutForDelegation =
                          da?.maxResources && da?.assignedResources >= da?.maxResources;
                      const isMaxedOutForSlot =
                          as?.maxResources && as?.assignedResources >= as?.maxResources;

                      return !isMaxedOutForSlot && !isMaxedOutForDelegation ? [as.id] : [];
                  }))
        ],
        [vr, isEdit]
    );

    return (
        <AccreditContextProvider
            accreditationsSlotsIds={accreditationsSlotsIds}
            isEdit={isEdit}
            showNextMember={showNextMember}
        >
            <AccreditAssignGrid
                buttons={
                    <AccreditButtons
                        isEdit={isEdit}
                        userInfoId={vr.userInfo.id}
                        volunteerRegistrationId={vr.id}
                    />
                }
                header={
                    <AccreditAssignHeader
                        badgeColorIcon={getAccreditationStateBadgeColorIcon(vr.accreditationState)}
                        insertedAt={vr.insertedAt}
                        isPreAssign={false}
                        showTabs={false}
                        userInfo={vr.userInfo}
                    />
                }
                isEdit={isEdit}
                isLeftPanelOpen={isLeftPanelOpen}
                isRightPanelOpen={isRightPanelOpen}
                leftPanel={
                    <AccreditAssignLeftPanel
                        delegationId={delegationId}
                        direction={direction}
                        event={data.event}
                        getPath={(userInfoId) =>
                            HeaventPaths.ACCREDIT(organizationId, eventId, userInfoId)
                        }
                        isLoading={isLoading}
                        loadDelegations={true}
                        name={name}
                        segmentId={segmentId}
                        setDelegationId={setDelegationId}
                        setDirection={setDirection}
                        setName={setName}
                        setSegmentId={setSegmentId}
                        userInfoId={vr.userInfoId}
                    />
                }
                rightPanel={
                    <AssignmentUserPanel
                        customFields={props.event.formsCustomsFields}
                        eventId={eventId}
                        formsUsersInfos={props.organization.userInfo.formsUsersInfos}
                        organizationId={organizationId}
                        showWishedAccreditations={true}
                        showWishedPositions={false}
                        userInfo={props.event.volunteerRegistration.userInfo}
                        onCollapse={() => {
                            setIsRightPanelOpen(false);
                        }}
                    />
                }
                setIsLeftPanelOpen={setIsLeftPanelOpen}
                setIsRightPanelOpen={setIsRightPanelOpen}
            >
                <AccreditFiltersCategories event={props.event} organization={props.organization} />
            </AccreditAssignGrid>
        </AccreditContextProvider>
    );
};

export const Accredit = () => {
    const { organizationId, eventId, userInfoId } = useParams();
    const { data, loader } = useAccreditInfosQuery({ organizationId, eventId, userInfoId });

    return loader || <AccreditComponent event={data.event} organization={data.organization} />;
};
