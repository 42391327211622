import { DropdownEllipsis } from 'common-front/src/designSystem/components/dropdown/dropdownEllipsis';
import { ItemIcon } from 'common-front/src/designSystem/components/dropdown/item';
import { useUserContext } from 'common-front/src/userContext';
import { BackofficeOrganizationsQuery } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import {
    useBackofficeOrganizationUserAddMutation,
    useBackofficeOrganizationUserRemoveMutation
} from '../generated/graphqlHooks';

interface IOrganizationRowDropdownProps {
    organization: BackofficeOrganizationsQuery['backoffice']['organizations']['nodes'][0];

    reload(): void;
}

export const OrganizationRowDropdown = (props: IOrganizationRowDropdownProps) => {
    const translate = useTranslate();
    const { user } = useUserContext();
    const { mutate: userAdd, isLoading: userAddLoading } =
        useBackofficeOrganizationUserAddMutation();
    const { mutate: userRemove, isLoading: userRemoveLoading } =
        useBackofficeOrganizationUserRemoveMutation();
    const usersIds = props.organization.users.map(({ id }) => id);

    return (
        <DropdownEllipsis>
            {usersIds.includes(user!.id) ? (
                <ItemIcon
                    color={userRemoveLoading ? 'disabled' : 'default'}
                    icon="minus"
                    onClick={async () => {
                        await userRemove({ organizationId: props.organization.id });

                        props.reload();
                    }}
                >
                    S&apos;enlever comme admin
                </ItemIcon>
            ) : (
                <ItemIcon
                    color={userAddLoading ? 'disabled' : 'default'}
                    icon="plus"
                    onClick={async () => {
                        await userAdd({ organizationId: props.organization.id });

                        window.open(`/organization/${props.organization.id}`, '_blank');

                        props.reload();
                    }}
                >
                    S&apos;ajouter comme admin
                </ItemIcon>
            )}

            <ItemIcon
                icon="pen"
                to={HeaventPaths.EDIT_BACKOFFICE_ORGANIZATION(props.organization.id)}
            >
                {translate('_diter_62574')}
            </ItemIcon>
        </DropdownEllipsis>
    );
};
