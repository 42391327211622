import * as React from 'react';
import { useContext, useEffect, useReducer } from 'react';
import { getToken } from '../../util/aws/cognito';
import { ErrorBoundaryContext } from '../errorBoundary/errorBoundaryContext';
import { Loader } from '../loader/loader';

interface FirstPageLoading {
    isLoading: true;
    data: undefined;
    xs: undefined;
    offset: number;
}

const isFirstPageLoading = <Result, XS>(
    state: LoadMoreState<Result, XS>
): state is FirstPageLoading => state.isLoading && state.data === undefined;

interface NextPageLoading<Result, XS> {
    isLoading: true;
    data: Result;
    xs: XS;
    offset: number;
}

interface PageLoaded<Result, XS> {
    isLoading: false;
    data: Result;
    xs: XS;
    offset: number;
}

type LoadMoreState<Result, XS> =
    | FirstPageLoading
    | NextPageLoading<Result, XS>
    | PageLoaded<Result, XS>;

interface FirstPageSuccess<Result, XS> {
    type: 'FIRST_PAGE_SUCCESS';
    data: Result;
    xs: XS;
    offset: number;
}

interface LoadMoreSucess<Result, XS> {
    type: 'LOAD_MORE_SUCCESS';
    data: Result;
    xs: XS;
    offset: number;
}

type LoadMoreAction<Result, XS> =
    | { type: 'FIRST_PAGE_INIT' }
    | FirstPageSuccess<Result, XS>
    | { type: 'LOAD_MORE_INIT' }
    | LoadMoreSucess<Result, XS>;

type LoadMoreReducer<Result, XS> = (
    state: LoadMoreState<Result, XS>,
    action: LoadMoreAction<Result, XS>
) => LoadMoreState<Result, XS>;

const loadMoreReducer = <Result, XS>(
    state: LoadMoreState<Result, XS>,
    action: LoadMoreAction<Result, XS>
): LoadMoreState<Result, XS> => {
    switch (action.type) {
        case 'FIRST_PAGE_INIT':
            return {
                ...state,
                isLoading: true,
                data: undefined,
                xs: undefined
            };
        case 'LOAD_MORE_INIT':
            return {
                ...state,
                isLoading: true
            };
        case 'FIRST_PAGE_SUCCESS':
        case 'LOAD_MORE_SUCCESS':
            return {
                ...state,
                isLoading: false,
                data: action.data,
                xs: action.xs,
                offset: action.offset
            };
    }
};

interface FirstPageLoadingResult {
    data: undefined;
    loader: JSX.Element;
    loadMore: () => void;
    xs: undefined;
}

interface NextPageLoadingResult<Result, XS> {
    data: Result;
    loader: JSX.Element;
    loadMore: () => void;
    xs: XS;
}

interface PageLoadedResult<Result, XS> {
    data: Result;
    loader: undefined;
    loadMore: () => void;
    xs: XS;
}

type LoadMoreResult<Result, XS> =
    | FirstPageLoadingResult
    | NextPageLoadingResult<Result, XS>
    | PageLoadedResult<Result, XS>;

export function useLoadMore<Variables, Result, X>(
    executeQuery: (variables: Variables, token: string | null) => Promise<Result>,
    variables: Variables & { limit: number; offset: number },
    getNodes: (result: Result) => X[]
): LoadMoreResult<Result, X[]> {
    const [state, dispatch] = useReducer<LoadMoreReducer<Result, X[]>>(loadMoreReducer, {
        isLoading: true,
        data: undefined,
        xs: undefined,
        offset: variables.offset
    });
    const { setIsError } = useContext(ErrorBoundaryContext);

    useEffect(() => {
        let didCancel = false;

        dispatch({ type: 'FIRST_PAGE_INIT' });

        getToken()
            .then((token) => executeQuery(variables, token || null))
            .then(
                (data) => {
                    if (didCancel === false) {
                        dispatch({
                            type: 'FIRST_PAGE_SUCCESS',
                            data,
                            xs: getNodes(data),
                            offset: variables.offset
                        });
                    }
                },
                () => {
                    if (didCancel === false) {
                        setIsError(true);
                    }
                }
            );

        return () => {
            didCancel = true;
        };
    }, [executeQuery, JSON.stringify(variables), getNodes]);

    const loadMore = () => {
        dispatch({ type: 'LOAD_MORE_INIT' });
        const offset = state.offset + variables.limit;

        getToken()
            .then((token) => executeQuery({ ...variables, offset }, token || null))
            .then(
                (data) => {
                    const xs = [...(state.xs || [])];
                    xs.splice(offset, 0, ...getNodes(data));
                    dispatch({
                        type: 'LOAD_MORE_SUCCESS',
                        data,
                        xs,
                        offset
                    });
                },
                () => {
                    setIsError(true);
                }
            );
    };

    if (isFirstPageLoading(state) /* TS can’t discrimate itself LoadMoreResult types. */) {
        return {
            data: state.data,
            loader: <Loader />,
            loadMore,
            xs: state.xs
        };
    } else {
        return {
            data: state.data,
            loader: state.isLoading ? <Loader /> : undefined,
            loadMore,
            xs: state.xs
        };
    }
}
