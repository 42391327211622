import { compact, groupBy, sortBy, uniq } from 'lodash-es';
import { Interval } from 'luxon';
import { DocumentUserPlanningFragment } from '../../generated/types';

export interface IGroupedAssignment {
    name: string;
    ranges: Interval[];
    slotsNames: string[];
}

export function groupAssignmentsByPositionDay(
    psuis: DocumentUserPlanningFragment['volunteerRegistration']['positionsSlotsUsersInfos']
): IGroupedAssignment[] {
    return sortBy(
        Object.entries(
            groupBy(
                psuis,
                (psui) =>
                    `${psui.position.name}-${psui.positionSlot.range
                        .start!.startOf('day')
                        .toMillis()}`
            )
        ).map(([_, groupedPsuis]) => {
            const sorted = sortBy(groupedPsuis, (psui) =>
                psui.positionSlot.range.start!.toMillis()
            );

            return {
                name: groupedPsuis[0].position.name,
                ranges: sorted.map((psui) => psui.positionSlot.range),
                slotsNames: compact(uniq(sorted.map(({ positionSlot }) => positionSlot.name)))
            };
        }),
        ({ ranges }) => ranges[0].start!.toMillis()
    );
}
