import chardet from 'chardet';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';
import { useUniqueIds } from '../../../hooks/useUniqueIds';
import { Accept, acceptString } from '../../../util/accept';
import { IInputProps } from '../input/commonInputProps';
import { Description } from '../input/description';
import { Hint } from '../input/hint';
import { Label } from '../input/label';
import { StyledInputContainer } from '../input/styledInputContainer';

const readFile = (file: File, format: 'base64' | 'string'): Promise<string> => {
    if (format === 'base64') {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                resolve(reader.result?.toString() ?? '');
            };
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    } else {
        return new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                const result = new Uint8Array(reader.result as ArrayBuffer);
                resolve(chardet.detect(result) || 'UTF-8');
            };
            reader.onerror = reject;
            reader.readAsArrayBuffer(file);
        }).then(
            (encoding) =>
                new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onload = () => resolve(reader.result?.toString() ?? '');
                    reader.onerror = reject;
                    reader.readAsText(file, encoding);
                })
        );
    }
};

export type FileInputValue = {
    name: string;
    content: string;
};

export interface IFileInputProps extends Omit<IInputProps, 'accept' | 'value'> {
    accept: Accept | Accept[];
    format: 'base64' | 'string';
    value?: FileInputValue;

    onChange(value: FileInputValue): void;
}

export const FileInput = ({
    css,
    label,
    description,
    icon,
    hint,
    state,
    value,
    placeholder,
    accept,
    format,
    onChange,
    ...rest
}: IFileInputProps) => {
    const inputRef = React.useRef<HTMLInputElement | null>(null);
    const { inputId, descId, errorId } = useUniqueIds();

    return (
        <Flex css={css} direction="column" width={1}>
            <Label htmlFor={inputId} isClickable={false}>
                {label}
            </Label>

            <Description id={descId}>{description}</Description>

            {(label || description) && <Spacer height="1" />}

            <StyledInputContainer
                cursor="pointer"
                icon={icon}
                rightIcon="file"
                state={state}
                onClick={() => {
                    inputRef.current?.click();
                }}
            >
                <Box
                    color={isNonEmptyString(value?.name) ? 'gray800' : 'gray500'}
                    css={{
                        flex: '1',
                        '& input': {
                            display: 'none'
                        }
                    }}
                >
                    {isNonEmptyString(value?.name) ? value!.name : placeholder}

                    <input
                        ref={inputRef}
                        accept={acceptString(accept)}
                        aria-describedby={description ? descId : undefined}
                        aria-errormessage={state === 'error' ? errorId : undefined}
                        aria-invalid={state === 'error'}
                        id={inputId}
                        type="file"
                        onChange={async (e) => {
                            const file = e.target.files?.[0];

                            if (file) {
                                onChange({
                                    name: file.name,
                                    content: await readFile(file, format)
                                });
                            }
                        }}
                        {...rest}
                    />
                </Box>
            </StyledInputContainer>

            <Hint id={errorId} state={state}>
                {hint}
            </Hint>
        </Flex>
    );
};
