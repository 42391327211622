import { Form } from 'common-front/src/components/form/form';
import { FieldSet } from 'common-front/src/designSystem/components/fieldSet';
import { HorizontalSpacerSeparator } from 'common-front/src/designSystem/components/separator';
import { RadioText } from 'common-front/src/designSystem/form/radio';
import { RichEditor } from 'common-front/src/designSystem/form/richEditor';
import { TextInput } from 'common-front/src/designSystem/form/textInput';
import { Box } from 'common/src/designSystem/components/box';
import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    CustomSlotsPeriodInput,
    Event,
    RegisterDaysDisplay,
    RegisterSlotDisplay
} from 'common/src/generated/types';
import { CustomSlotPeriodInputService } from 'common/src/input/customSlotPeriodInput';
import {
    FormParametersAvailabilitiesInputService,
    IUpdateFormParametersAvailabilitiesValues
} from 'common/src/input/formParametersAvailabilitiesInput';
import { ValidateService } from 'common/src/services/validateService';
import { removeAtIndex } from 'common/src/util/array';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { FormApi } from 'final-form';
import * as React from 'react';
import {
    useFormParametersAvailabilitiesQuery,
    useFormParametersAvailabilitiesUpdateMutation
} from '../../../../generated/graphqlHooks';
import { useFormContext } from '../../../show/formContext';
import { FormParametersAvailabilitiesCustomSlotsPeriods } from './formParametersAvailabilitiesCustomSlotsPeriods';

interface IFormParametersAvailabilitiesInnerProps {
    event: Pick<Event, 'startAt' | 'endAt'>;
    form: FormApi<IUpdateFormParametersAvailabilitiesValues>;
    values: IUpdateFormParametersAvailabilitiesValues;

    handleSubmit(): void;
}

const FormParametersAvailabilitiesInner = (props: IFormParametersAvailabilitiesInnerProps) => {
    const translate = useTranslate();
    const { isSaving } = useFormContext();
    const customSlotPeriodInput = useService(CustomSlotPeriodInputService);
    const addCustomSlotPeriod = React.useCallback(() => {
        props.form.change('form.customSlotsPeriods', [
            ...props.values.form.customSlotsPeriods,
            customSlotPeriodInput.customSlotPeriodInputDefault()
        ]);
    }, [props.values.form.customSlotsPeriods, props.form.change]);
    const removeCustomSlotPeriod = React.useCallback(
        (index: number) => {
            props.form.change(
                'form.customSlotsPeriods',
                removeAtIndex(props.values.form.customSlotsPeriods, index)
            );
        },
        [props.values.form.customSlotsPeriods, props.form.change]
    );
    const updateCustomSlotPeriod = React.useCallback(
        (customSlotPeriod: CustomSlotsPeriodInput, index: number) => {
            const newPeriods = [...props.values.form.customSlotsPeriods];
            newPeriods[index] = customSlotPeriod;

            props.form.change('form.customSlotsPeriods', newPeriods);
        },
        [props.values.form.customSlotsPeriods, props.form.change]
    );
    const showCustomSlots = props.values.form.slotDisplay === RegisterSlotDisplay.Custom;
    const showDaysDisplay =
        props.values.form.slotDisplay === RegisterSlotDisplay.DisplayDays ||
        props.values.form.slotDisplay === RegisterSlotDisplay.Custom;

    React.useEffect(() => {
        if (isSaving) {
            props.handleSubmit();
        }
    }, [isSaving]);

    return (
        <>
            <Box color="gray800" fontSize="textMd" fontWeight="semiBold">
                {translate('affichage_des_d_20852')}
            </Box>

            <Spacer height="1" />

            <Box color="gray500">{translate('personnaliser_l_64536')}</Box>

            <FieldSet hideTitle={true} title={translate('affichage_des_d_20852')}>
                <RadioText name="form.slotDisplay" value={RegisterSlotDisplay.Display}>
                    {translate('demander_au_mem_07520')}
                </RadioText>

                <Spacer height="3" />

                <RadioText name="form.slotDisplay" value={RegisterSlotDisplay.DisplayDays}>
                    {translate('demander_au_mem_50067')}
                </RadioText>

                <Spacer height="3" />

                <RadioText name="form.slotDisplay" value={RegisterSlotDisplay.Calendar}>
                    {translate('demander_au_mem_60083')}
                </RadioText>

                <Spacer height="3" />

                <RadioText name="form.slotDisplay" value={RegisterSlotDisplay.Custom}>
                    {translate('demander_au_mem_31132')}
                </RadioText>

                {showCustomSlots && (
                    <>
                        <Spacer height="6" />

                        <FormParametersAvailabilitiesCustomSlotsPeriods
                            add={addCustomSlotPeriod}
                            customSlotsPeriods={props.values.form.customSlotsPeriods}
                            event={props.event}
                            remove={removeCustomSlotPeriod}
                            updateCustomSlotPeriod={updateCustomSlotPeriod}
                        />
                    </>
                )}

                <Spacer height="3" />

                <RadioText name="form.slotDisplay" value={RegisterSlotDisplay.Hide}>
                    {translate('ne_pas_demander_46686')}
                </RadioText>
            </FieldSet>

            {showDaysDisplay && (
                <>
                    <HorizontalSpacerSeparator height="7" />

                    <Box color="gray800" fontSize="textMd" fontWeight="semiBold">
                        {translate('filtrer_les_dis_80634')}
                    </Box>

                    <Spacer height="1" />

                    <Box color="gray500">{translate('d_cider_de_filt_81078')}</Box>

                    <Spacer height="2" />

                    <RadioText name="form.daysDisplay" value={RegisterDaysDisplay.AllDays}>
                        {translate('afficher_l_ense_11531')}
                    </RadioText>

                    <Spacer height="3" />

                    <RadioText name="form.daysDisplay" value={RegisterDaysDisplay.DaysWithSlots}>
                        {translate('filtrer_les_jou_22784')}
                    </RadioText>
                </>
            )}

            {props.values.form.slotDisplay !== RegisterSlotDisplay.Hide && (
                <>
                    <HorizontalSpacerSeparator height="7" />

                    <Box color="gray800" fontSize="textMd" fontWeight="semiBold">
                        {translate('personnalisatio_15893')}
                    </Box>

                    <Spacer height="1" />

                    <Box color="gray500">{translate('personnaliser_l_44808')}</Box>

                    <Spacer height="4" />

                    <TextInput label={translate('nom_de_l_tape_85862')} name="form.slotTitle" />

                    <Spacer height="6" />

                    <RichEditor label={translate('description_58935')} name="form.slotSubtitle" />
                </>
            )}
        </>
    );
};

export const FormParametersAvailabilities = () => {
    const { eventId, formId, organizationId, setIsSaving } = useFormContext();
    const { data, loader } = useFormParametersAvailabilitiesQuery({
        organizationId,
        eventId: eventId!,
        formId
    });
    const validateService = useService(ValidateService);
    const formParametersAvailabilitiesInput = useService(FormParametersAvailabilitiesInputService);
    const { mutate } = useFormParametersAvailabilitiesUpdateMutation();

    return (
        loader || (
            <Form
                direction="column"
                initialValues={{
                    form: formParametersAvailabilitiesInput.formParametersAvailabilitiesInputDefault(
                        data.organization.form
                    )
                }}
                render={({ form, handleSubmit, values }) => (
                    <FormParametersAvailabilitiesInner
                        event={data.event}
                        form={form}
                        handleSubmit={handleSubmit}
                        values={values}
                    />
                )}
                validate={validateService.validateForForm(
                    formParametersAvailabilitiesInput.updateFormParametersAvailabilitiesSchema()
                )}
                width={1}
                onSubmit={async (values: IUpdateFormParametersAvailabilitiesValues) => {
                    await mutate({
                        organizationId,
                        eventId,
                        formId,
                        form: values.form
                    });

                    setIsSaving(false);
                }}
            />
        )
    );
};
