import { DropdownEllipsis } from 'common-front/src/designSystem/components/dropdown/dropdownEllipsis';
import { ItemIcon } from 'common-front/src/designSystem/components/dropdown/item';
import {
    CheckInState,
    EventId,
    PositionSlotVolunteersQuery,
    PositionsSlotId
} from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { useEventContext } from '../../../events/show/eventContext';
import {
    usePositionSlotUserInfoCheckInMutation,
    usePositionSlotUserInfoCheckInResetMutation,
    usePositionSlotUserInfoCheckOutMutation,
    useVolunteerRegistrationSlotUnassignMutation
} from '../../../generated/graphqlHooks';

interface IPositionPositionSlotVolunteerRowDropdownProps {
    eventId: EventId;
    positionSlotId: PositionsSlotId;
    volunteerRegistration: PositionSlotVolunteersQuery['event']['volunteersRegistrations']['nodes'][0];

    reload(): void;
}

export const PositionPositionSlotVolunteerRowDropdown = (
    props: IPositionPositionSlotVolunteerRowDropdownProps
) => {
    const translate = useTranslate();
    const {
        event: {
            permissions: { isEventAdmin }
        }
    } = useEventContext();
    const { mutate: positionSlotUserInfoCheckIn } = usePositionSlotUserInfoCheckInMutation();
    const { mutate: positionSlotUserInfoCheckOut } = usePositionSlotUserInfoCheckOutMutation();
    const { mutate: positionSlotUserInfoCheckInReset } =
        usePositionSlotUserInfoCheckInResetMutation();
    const { mutate: volunteerRegistrationSlotUnassign } =
        useVolunteerRegistrationSlotUnassignMutation();
    const psui = props.volunteerRegistration.positionSlotUserInfo!;

    return (
        <DropdownEllipsis>
            {psui.state === CheckInState.Waiting && (
                <ItemIcon
                    icon="clock"
                    onClick={async () => {
                        await positionSlotUserInfoCheckIn({
                            eventId: props.eventId,
                            positionSlotUserInfoId: psui.id
                        });

                        props.reload();
                    }}
                >
                    {translate('pointer_1_57919', props.volunteerRegistration.userInfo.name)}
                </ItemIcon>
            )}

            {psui.state === CheckInState.CheckedIn && (
                <ItemIcon
                    icon="clock"
                    onClick={async () => {
                        await positionSlotUserInfoCheckOut({
                            eventId: props.eventId,
                            positionSlotUserInfoId: psui.id
                        });

                        props.reload();
                    }}
                >
                    {translate('d_pointer_1_08337', props.volunteerRegistration.userInfo.name)}
                </ItemIcon>
            )}

            {psui.state === CheckInState.CheckedOut && (
                <ItemIcon
                    icon="clock"
                    onClick={async () => {
                        await positionSlotUserInfoCheckInReset({
                            eventId: props.eventId,
                            positionSlotUserInfoId: psui.id
                        });

                        props.reload();
                    }}
                >
                    {translate('remettre_z_ro_11572')}
                </ItemIcon>
            )}

            {isEventAdmin && (
                <ItemIcon
                    color="red"
                    icon="user-xmark"
                    onClick={async () => {
                        await volunteerRegistrationSlotUnassign({
                            eventId: props.eventId,
                            volunteerRegistrationId: props.volunteerRegistration.id,
                            positionSlotId: props.positionSlotId
                        });

                        props.reload();
                    }}
                >
                    {translate('d_saffecter_du_47122')}
                </ItemIcon>
            )}
        </DropdownEllipsis>
    );
};
