import { Button } from 'common-front/src/designSystem/components/button';
import { TableFilters } from 'common-front/src/designSystem/components/tableFilters';
import { usePaginationInfos } from 'common-front/src/hooks/usePaginationInfos';
import { useStateDebounce } from 'common-front/src/hooks/useStateDebounce';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { HeaderCellSort } from 'common/src/designSystem/components/table/headerCellSort';
import { RowSkeleton } from 'common/src/designSystem/components/table/rowSkeleton';
import {
    AccreditationsSlotsSortAttributes,
    EventId,
    MassAssignStrategy,
    UsersInfoId
} from 'common/src/generated/types';
import { IMassAccreditValues } from 'common/src/input/massAccreditInput';
import { isNonEmptyArray } from 'common/src/util/array';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { isNonEmptyString } from 'common/src/util/string';
import { Sort } from 'common/src/vo/sort';
import * as React from 'react';
import { useMassAccreditAccreditationsQuery } from '../../generated/graphqlHooks';
import { MassAccreditFilters } from './massAccreditFilters';
import { MassAccreditSlotRow } from './massAccreditSlotRow';

interface IMassAccreditStep2Props {
    eventId: EventId;
    numberOfSelected: number;
    submitting: boolean;
    usersInfosIds: UsersInfoId[];
    values: IMassAccreditValues;

    change(name: string, value: any): void;
    handleSubmit(): void;
}

export const MassAccreditStep2 = ({
    change,
    eventId,
    handleSubmit,
    numberOfSelected,
    submitting,
    usersInfosIds,
    values: {
        massAccredit: { accreditationsSlotsIds, strategy }
    }
}: IMassAccreditStep2Props) => {
    const translate = useTranslate();

    const [name, nameDebounced, setName] = useStateDebounce('');
    const [excludeEmpty, setExcludeEmpty] = React.useState(strategy === MassAssignStrategy.Delete);
    const [excludeFull, setExcludeFull] = React.useState(strategy === MassAssignStrategy.Add);
    const [offset, setOffset] = React.useState(0);
    const [sort, setSort] = React.useState<Sort<AccreditationsSlotsSortAttributes> | null>(null);

    const { data, isLoading } = useMassAccreditAccreditationsQuery({
        eventId,
        accreditationName: isNonEmptyString(nameDebounced) ? nameDebounced : undefined,
        excludeEmpty,
        excludeFull,
        usersInfosIds,
        offset,
        sort
    });
    const { numberOfPages, totalCount } = usePaginationInfos(data.event?.accreditationsSlots);

    const hasOverfullAccreditations = React.useMemo(
        () =>
            accreditationsSlotsIds.some((slotId) => {
                const slot = data.event?.accreditationsSlots.nodes.find(
                    (slot) => slot.id === slotId
                );

                return slot
                    ? slot.assignedResources + numberOfSelected > (slot.maxResources ?? Infinity)
                    : false;
            }),
        [data.event?.accreditationsSlots, accreditationsSlotsIds]
    );
    const hasUnderfullAccreditations = React.useMemo(
        () =>
            accreditationsSlotsIds.some((slotId) => {
                const slot = data.event?.accreditationsSlots.nodes.find(
                    (slot) => slot.id === slotId
                );

                return slot ? slot.assignedResources - slot.assignedResourcesForUsers < 0 : false;
            }),
        [data.event?.accreditationsSlots, accreditationsSlotsIds]
    );

    const _setSort = (newSort: Sort<AccreditationsSlotsSortAttributes> | null) => {
        setSort(newSort);
        setOffset(0);
    };

    return (
        <>
            <TableFilters
                filters={
                    <MassAccreditFilters
                        excludeEmpty={excludeEmpty}
                        excludeFull={excludeFull}
                        name={name}
                        setExcludeEmpty={(newExcludeEmpty) => {
                            setOffset(0);
                            setExcludeEmpty(newExcludeEmpty);
                        }}
                        setExcludeFull={(newExcludeFull) => {
                            setOffset(0);
                            setExcludeFull(newExcludeFull);
                        }}
                        setName={setName}
                        setOffset={setOffset}
                    />
                }
                headerCells={
                    <>
                        <HeaderCell css={{ paddingRigh: 0 }} width={48} />
                        <HeaderCellSort
                            attribute={AccreditationsSlotsSortAttributes.Accreditation}
                            setSort={_setSort}
                            sort={sort}
                        >
                            {translate('nom_de_l_accr_d_11908')}
                        </HeaderCellSort>
                        <HeaderCell>{translate('Date')}</HeaderCell>
                        <HeaderCell />
                    </>
                }
                numberOfPages={numberOfPages}
                offset={offset}
                rows={
                    isLoading ? (
                        <>
                            <RowSkeleton bx={true} />
                            <RowSkeleton bx={true} />
                            <RowSkeleton bx={true} />
                        </>
                    ) : (
                        data.event.accreditationsSlots.nodes.map((as) => (
                            <MassAccreditSlotRow
                                key={as.id}
                                accreditationsSlotsIds={accreditationsSlotsIds}
                                change={change}
                                numberOfSelected={numberOfSelected}
                                slot={as}
                                strategy={strategy}
                            />
                        ))
                    )
                }
                setOffset={setOffset}
                title={
                    <Flex direction="column" gap="1">
                        <Box>{translate('s_lectionner_le_27799')}</Box>
                        <Box font="gray500 textSm regular">
                            {translate(
                                strategy === MassAssignStrategy.Add
                                    ? 'les_membres_s_l_31473'
                                    : strategy === MassAssignStrategy.Replace
                                      ? 'les_membres_s_l_18309'
                                      : 'les_membres_s_l_37590'
                            )}
                        </Box>
                    </Flex>
                }
                totalCount={totalCount}
            />

            <Spacer height="7" />

            <Flex>
                <Button
                    disabled={
                        isNonEmptyArray(accreditationsSlotsIds)
                            ? strategy === MassAssignStrategy.Add
                                ? hasOverfullAccreditations
                                : strategy === MassAssignStrategy.Replace
                                  ? false
                                  : hasUnderfullAccreditations
                            : true
                    }
                    isLoading={submitting}
                    onClick={handleSubmit}
                >
                    {translate(
                        strategy === MassAssignStrategy.Add
                            ? 'accr_diter_les_78545'
                            : strategy === MassAssignStrategy.Replace
                              ? 'r_accr_diter_le_34059'
                              : 'd_saccr_diter_l_28590',
                        numberOfSelected
                    )}
                </Button>
            </Flex>
        </>
    );
};
