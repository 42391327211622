import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { PositionPlanning } from 'common/src/documents/positionPlanning/positionPlanning';
import { DocumentType } from 'common/src/generated/types';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import { usePositionPlanningDocumentQuery } from '../../generated/graphqlHooks';
import { DocumentHeader } from './documentHeader';
import { DocumentShadow } from './documentShadow';
import { DocumentsPositionSelector } from './documentsPositionSelector';

export const PositionPlanningDocument = () => {
    const {
        translate,
        params: { organizationId, eventId, positionId }
    } = useHeavent();
    const { data, loader } = usePositionPlanningDocumentQuery({
        eventId,
        positionId
    });

    return (
        <Flex css={{ overflowY: 'auto', padding: '$7' }} direction="column" height={1} width={1}>
            <DocumentHeader
                documentType={DocumentType.PositionPlanning}
                eventId={eventId}
                languages={data.event?.languages ?? []}
                organizationId={organizationId}
                segmentsFolders={data.event?.segmentsFolders ?? []}
                showDate={true}
                title={translate('planning_d_une_64581')}
            />

            <Spacer height="7" />

            {loader || (
                <Flex css={{ flex: '1' }} direction="column">
                    <DocumentsPositionSelector
                        getPath={(newPositionId) =>
                            HeaventPaths.DOCUMENTS_POSITION_PLANNING(
                                organizationId,
                                eventId,
                                newPositionId
                            )
                        }
                    />

                    <DocumentShadow>
                        <PositionPlanning event={data.event} />
                    </DocumentShadow>
                </Flex>
            )}
        </Flex>
    );
};
