import { uniq } from 'lodash-es';
import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { CommonEnvVars } from '../../../envVars';
import {
    DocumentUserCustomBadgeFragment,
    VolunteerRegistrationState
} from '../../../generated/types';
import { isNonEmptyArray } from '../../../util/array';
import { isNonEmptyString } from '../../../util/string';

const getBackground = (acronyms: string[]) => {
    if (acronyms.includes('BLEU_CLAIR')) {
        return 'https://assets.recrewteer.com/badges/paris20km/2024/badge/bleu_clair/bleu_clair_recto.jpg';
    } else if (acronyms.includes('BLEU_FONCE')) {
        return 'https://assets.recrewteer.com/badges/paris20km/2024/badge/bleu_fonce/bleu_fonce_recto.jpg';
    } else if (acronyms.includes('JAUNE')) {
        return 'https://assets.recrewteer.com/badges/paris20km/2024/badge/jaune/jaune_recto.jpg';
    } else if (acronyms.includes('ORANGE')) {
        return 'https://assets.recrewteer.com/badges/paris20km/2024/badge/orange/orange_recto.jpg';
    } else if (acronyms.includes('VERT')) {
        return 'https://assets.recrewteer.com/badges/paris20km/2024/badge/vert/vert_recto.jpg';
    } else if (acronyms.includes('VIOLET')) {
        return 'https://assets.recrewteer.com/badges/paris20km/2024/badge/violet/violet_recto.jpg';
    } else {
        return '';
    }
};

interface IBadgeParis20kmFrontProps {
    event: DocumentUserCustomBadgeFragment;
}

export const BadgeParis20kmFront = (props: IBadgeParis20kmFrontProps) => {
    const vr = props.event.volunteerRegistration;
    const userInfo = vr.userInfo;
    const acronyms = vr.accreditationsUsersInfos.map(({ accreditation }) => accreditation.acronym!);
    const positions = uniq(vr.positionsSlotsUsersInfos.map((psui) => psui.position.name));
    const color = acronyms.includes('JAUNE') || acronyms.includes('BLEU_CLAIR') ? 'black' : 'white';
    const statut = userInfo.fields?.cf20600?.value ?? '';
    const societe = userInfo.fields?.cf21104?.value ?? '';
    const domaine = userInfo.fields?.cf21129?.value ?? [];

    return (
        <Flex
            css={{
                background: `url(${getBackground(acronyms)}) no-repeat`,
                backgroundSize: 'cover',
                pageBreakBefore: 'always',
                position: 'relative'
            }}
            height={476}
            width={368}
        >
            <Box
                css={{
                    position: 'absolute',
                    right: '40px',
                    top: '43px'
                }}
                height={65}
                width={65}
            >
                {isNonEmptyString(vr.weezeventParticipantInfo?.idBarcode) && (
                    <img
                        height="100%"
                        src={`https://api.${
                            CommonEnvVars.HEAVENT_EXTERNAL_DOMAIN
                        }/barcode/qr?text=${encodeURIComponent(
                            vr.weezeventParticipantInfo!.idBarcode
                        )}&color=000000&height=65&width=65`}
                        width="100%"
                    />
                )}
            </Box>

            <Box
                css={{
                    borderRadius: '107px',
                    height: '107px',
                    left: '131px',
                    overflow: 'hidden',
                    position: 'absolute',
                    top: '127px',
                    width: '107px'
                }}
            >
                {userInfo.picture && <img height="100%" src={userInfo.picture.url} width="100%" />}
            </Box>

            <Box
                css={{
                    color,
                    fontSize: '18px',
                    fontWeight: '700',
                    position: 'absolute',
                    textAlign: 'center',
                    top: '242px',
                    width: '100%'
                }}
            >
                {userInfo.name}
            </Box>

            <Flex
                css={{
                    color,
                    fontSize: '12px',
                    gap: '2px',
                    position: 'absolute',
                    top: '271px',
                    width: '100%'
                }}
                direction="column"
            >
                {vr.state === VolunteerRegistrationState.NotApplicable ||
                statut === 'Intervenant' ? (
                    <Box textAlign="center" width={1}>
                        {societe}
                    </Box>
                ) : statut === 'Direction' ? (
                    isNonEmptyArray(domaine) ? (
                        domaine.map((domaine, index) => (
                            <Box key={index} textAlign="center" width={1}>
                                {domaine}
                            </Box>
                        ))
                    ) : null
                ) : (
                    positions.map((position, index) => (
                        <Box key={index} textAlign="center" width={1}>
                            {position}
                        </Box>
                    ))
                )}
            </Flex>

            <Box
                css={{
                    color,
                    fontSize: '32px',
                    fontWeight: '700',
                    position: 'absolute',
                    textAlign: 'center',
                    textTransform: 'uppercase',
                    top: '364px',
                    width: '100%'
                }}
            >
                {statut}
            </Box>
        </Flex>
    );
};
