import { TableFilters } from 'common-front/src/designSystem/components/tableFilters';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { usePaginationInfos } from 'common-front/src/hooks/usePaginationInfos';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { RowSkeleton } from 'common/src/designSystem/components/table/rowSkeleton';
import * as React from 'react';
import { useAccreditationSlotsQuery } from '../../../../generated/graphqlHooks';
import { AccreditationSlotsSlotRow } from './accreditationSlotsSlotRow';

export const AccreditationSlotsCard = () => {
    const {
        translate,
        params: { eventId, accreditationId }
    } = useHeavent();
    const [offset, setOffset] = React.useState(0);
    const { data, isLoading } = useAccreditationSlotsQuery({
        eventId,
        accreditationId,
        offset
    });
    const { numberOfPages, totalCount } = usePaginationInfos(data.event?.accreditationsSlots);

    return (
        <TableFilters
            filters={<></>}
            headerCells={
                <>
                    <HeaderCell>{translate('nom_du_cr_neau_54351')}</HeaderCell>
                    <HeaderCell>{translate('Date')}</HeaderCell>
                    <HeaderCell>{translate('remplissage_75916')}</HeaderCell>
                </>
            }
            numberOfPages={numberOfPages}
            offset={offset}
            rows={
                isLoading ? (
                    <>
                        <RowSkeleton bx={true} />
                        <RowSkeleton bx={true} />
                        <RowSkeleton bx={true} />
                    </>
                ) : (
                    data.event.accreditationsSlots.nodes.map((slot) => (
                        <AccreditationSlotsSlotRow key={slot.id} slot={slot} />
                    ))
                )
            }
            setOffset={setOffset}
            title={translate('liste_des_cr_ne_66393')}
            totalCount={totalCount}
        />
    );
};
