import { Empty } from 'common-front/src/components/empty/empty';
import { useMobileQuery } from 'common-front/src/hooks/useMobileQuery';
import { useParams } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { CommunityPaths } from 'common/src/util/paths/communityPaths';
import { OrganizationsPaths } from 'common/src/util/paths/organizationsPaths';
import * as React from 'react';
import { Route, Switch } from 'react-router';
import { Community } from '../community/community';
import { CustomFieldsLoader } from '../customFields/list/customFieldsLoader';
import { CreateEvent, UpdateEvent } from '../events/create/createUpdateEvent';
import { EventsLoader } from '../events/list/eventsLoader';
import { EventsMobile } from '../events/mobile/eventsMobile';
import { Event } from '../events/show/event';
import {
    CreateEmailSender,
    UpdateEmailSender
} from '../organizationParameters/emails/senders/create/createUpdateEmailSender';
import { EmailSenderPreview } from '../organizationParameters/emails/senders/preview/emailSenderPreview';
import { OrganizationParametersMobile } from '../organizationParameters/mobile/organizationParametersMobile';
import { OrganizationParameters } from '../organizationParameters/organizationParameters';
import { UserProfile } from '../users/profile/userProfile';
import { ArchiveOrganization } from './archiveOrganization';
import { OrganizationBlocked } from './blocked/organizationBlocked';
import { OrganizationExpired } from './expired/organizationExpired';
import { OrganizationComponent } from './organizationComponent';
import { OrganizationContextProvider, useOrganizationContext } from './organizationContext';
import { AcceptTerms } from './terms/acceptTerms';

const OrganizationRoutes = () => {
    const { organization } = useOrganizationContext();
    const isOrganizationAdmin = organization.permissions.isOrganizationAdmin;
    const onMobile = useMobileQuery();

    return (
        <Switch>
            {isOrganizationAdmin && (
                <Route exact path={HeaventPaths.CREATE_EVENT(':organizationId')}>
                    <CreateEvent />
                </Route>
            )}

            {isOrganizationAdmin && (
                <Route exact path={HeaventPaths.EDIT_EVENT(':organizationId', ':eventId')}>
                    <UpdateEvent />
                </Route>
            )}

            <Route path={HeaventPaths.EVENTS(':organizationId')}>
                {onMobile ? (
                    <EventsMobile />
                ) : (
                    <OrganizationComponent>
                        <EventsLoader />
                    </OrganizationComponent>
                )}
            </Route>

            <Route path={HeaventPaths.EVENT(':organizationId', ':eventId')}>
                <Event />
            </Route>

            {isOrganizationAdmin && (
                <Route path={HeaventPaths.CUSTOM_FIELDS(':organizationId')}>
                    <OrganizationComponent>
                        <CustomFieldsLoader />
                    </OrganizationComponent>
                </Route>
            )}

            {isOrganizationAdmin && (
                <Route
                    path={HeaventPaths.CREATE_ORGANIZATION_PARAMETERS_EMAILS_SENDERS(
                        ':organizationId'
                    )}
                >
                    <CreateEmailSender />
                </Route>
            )}

            {isOrganizationAdmin && (
                <Route
                    path={HeaventPaths.EDIT_ORGANIZATION_PARAMETERS_EMAILS_SENDERS(
                        ':organizationId',
                        ':emailSenderId'
                    )}
                >
                    <UpdateEmailSender />
                </Route>
            )}

            {isOrganizationAdmin && (
                <Route
                    path={HeaventPaths.ORGANIZATION_PARAMETERS_EMAILS_SENDERS_PREVIEW(
                        ':organizationId',
                        ':emailSenderId'
                    )}
                >
                    <EmailSenderPreview />
                </Route>
            )}

            <Route path={HeaventPaths.ORGANIZATION_PARAMETERS(':organizationId')}>
                {onMobile ? (
                    <OrganizationParametersMobile />
                ) : (
                    <OrganizationComponent>
                        <OrganizationParameters />
                    </OrganizationComponent>
                )}
            </Route>

            <Route path={HeaventPaths.COMMUNITY_USER_PROFILE(':organizationId', ':userInfoId')}>
                <OrganizationComponent>
                    <UserProfile />
                </OrganizationComponent>
            </Route>

            <Route path={CommunityPaths.COMMUNITY(':organizationId')}>
                <Community />
            </Route>

            {isOrganizationAdmin && (
                <Route exact path={OrganizationsPaths.ARCHIVE_ORGANIZATION(':organizationId')}>
                    <ArchiveOrganization />
                </Route>
            )}

            <Route path={OrganizationsPaths.ORGANIZATION_IS_EXPIRED(':organizationId')}>
                {' '}
                <OrganizationComponent>
                    <OrganizationExpired />
                </OrganizationComponent>
            </Route>

            <Route path={OrganizationsPaths.ORGANIZATION_IS_BLOCKED(':organizationId')}>
                {' '}
                <OrganizationComponent>
                    <OrganizationBlocked />
                </OrganizationComponent>
            </Route>

            <Route exact path={HeaventPaths.ACCEPT_TERMS_AND_CONDITIONS(':organizationId')}>
                <AcceptTerms />
            </Route>

            <Route>
                <Empty path={HeaventPaths.EVENTS(organization.id)} replace={true} />
            </Route>
        </Switch>
    );
};

export const Organization = () => {
    const { organizationId } = useParams();

    return (
        <OrganizationContextProvider organizationId={organizationId}>
            <OrganizationRoutes />
        </OrganizationContextProvider>
    );
};
