import { Button } from 'common-front/src/designSystem/components/button';
import { DateInput } from 'common-front/src/designSystem/form/date/dateInput';
import { TimeInput } from 'common-front/src/designSystem/form/date/timeInput';
import { RadioText } from 'common-front/src/designSystem/form/radio';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { CampaignState } from 'common/src/generated/types';
import { ICreateUpdateCampaignValues } from 'common/src/input/campaignInput';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { DateTime } from 'luxon';
import * as React from 'react';
import { FormRenderProps } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';

interface IDeliveryProps {
    formProps: FormRenderProps<ICreateUpdateCampaignValues>;
    timeZone: string;
}

export const Delivery = (props: IDeliveryProps) => {
    const translate = useTranslate();
    const { form, handleSubmit, values } = props.formProps;

    return (
        <>
            <OnChange name="delivery">
                {(delivery: 'now' | 'later') => {
                    form.change(
                        'campaign.state',
                        delivery === 'now' ? CampaignState.Draft : CampaignState.Scheduled
                    );
                }}
            </OnChange>

            <Box color="gray800">{translate('quand_voulez_vo_96615')}</Box>

            <Spacer height="3" />

            <RadioText name="delivery" value="now">
                {translate('je_souahite_l_e_46027')}
            </RadioText>

            <Spacer height="3" />

            <RadioText name="delivery" value="later">
                {translate('je_souhaite_pro_98854')}
            </RadioText>

            {values.delivery === 'later' && (
                <>
                    <Spacer height="2" />

                    <Flex gap="4">
                        <DateInput
                            min={DateTime.local()}
                            name="campaign.scheduledAtDate"
                            placeholder={translate('choisissez_la_d_30578')}
                        />

                        <TimeInput
                            hint={translate('le_fuseau_horai_57116', props.timeZone)}
                            name="campaign.scheduledAtTime"
                            placeholder={translate('choisissez_l_he_55311')}
                        />
                    </Flex>
                </>
            )}

            <Spacer height="7" />

            <Flex>
                <Button onClick={handleSubmit}>{translate('suivant_r_cap_22677')}</Button>
            </Flex>
        </>
    );
};
