import { Accordion } from 'common-front/src/designSystem/components/accordion';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { Comments } from '../../../users/show/comments/comments';

export const AssignmentUserPanelComments = () => {
    const translate = useTranslate();

    return (
        <Accordion title={translate('commentaires_96492')}>
            <Comments showEvent={false} />
        </Accordion>
    );
};
