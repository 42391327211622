import { CenteredContainer } from 'common-front/src/components/centeredContainer/centeredContainer';
import { Form } from 'common-front/src/components/form/form';
import { FormErrors } from 'common-front/src/components/form/formErrors';
import { FullScreenPopup } from 'common-front/src/components/fullScreenPopup/fullScreenPopup';
import { Button } from 'common-front/src/designSystem/components/button';
import { FormBox } from 'common-front/src/designSystem/components/formBox';
import { LabelOptional } from 'common-front/src/designSystem/components/input/labelOptional';
import { RichEditor } from 'common-front/src/designSystem/form/richEditor';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useReferrerContext } from 'common-front/src/util/referrerContext';
import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    CreateDelegationFormFragment,
    CreatePositionTagsFragment,
    Delegation,
    DelegationEventInfosQuery,
    DelegationId,
    DelegationInput,
    EventAccreditationsFragment,
    EventId,
    OrganizationId,
    SegmentCustomFieldFragment
} from 'common/src/generated/types';
import {
    DelegationInputService,
    ICreateUpdateDelegationValues
} from 'common/src/input/delegationInput';
import { ValidateService } from 'common/src/services/validateService';
import { isNonEmptyArray } from 'common/src/util/array';
import { decodeBase64 } from 'common/src/util/base64';
import { useService } from 'common/src/util/dependencies/dependencies';
import { Emptyable } from 'common/src/util/emptyable';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { isNonEmptyString } from 'common/src/util/string';
import { DateTime } from 'luxon';
import * as React from 'react';
import { TagsForm } from '../../accreditations/create/tagsForm';
import { FormParametersGeneralCustomFields } from '../../forms/update/parameters/general/formParametersGeneralCustomFields';
import {
    useDelegationCreateMutation,
    useDelegationEventInfosQuery,
    useDelegationEventToEditQuery,
    useDelegationOrganizationInfosQuery,
    useDelegationOrganizationToEditQuery,
    useDelegationUpdateMutation
} from '../../generated/graphqlHooks';
import { AccreditationsSelectPanel } from './accreditations/accreditationsSelectPanel';
import { AccreditationsForm } from './accreditationsForm';
import {
    CreateEventDelegationInformations,
    CreateOrganizationDelegationInformations
} from './createDelegationInformations';
import { CreateDelegationParameters } from './createDelegationParameters';

type CreateUpdateDelegationBaseProps = {
    closePath: string;
    customFields: SegmentCustomFieldFragment[];
    forms: CreateDelegationFormFragment[];
    initialValues: ICreateUpdateDelegationValues;
    isEdit: boolean;
    tags: CreatePositionTagsFragment['tags']['nodes'];

    mutate(args: {
        organizationId: OrganizationId;
        eventId: EventId;
        delegation: DelegationInput;
    }): Promise<DelegationId>;
};

type CreateUpdateEventDelegationProps = CreateUpdateDelegationBaseProps & {
    accreditationsCategories: EventAccreditationsFragment['accreditationsCategories'];
    delegationType: 'event';
    eventStartAt: DateTime;
    eventEndAt: DateTime;
    organizationDelegations: Array<Pick<Delegation, 'id' | 'name'>>;
};

type CreateUpdateOrganizationDelegationProps = CreateUpdateDelegationBaseProps & {
    delegationType: 'organization';
};

type CreateUpdateDelegationProps =
    | CreateUpdateEventDelegationProps
    | CreateUpdateOrganizationDelegationProps;

const CreateUpdateDelegation = (props: CreateUpdateDelegationProps) => {
    const {
        history,
        translate,
        params: { organizationId, eventId }
    } = useHeavent();
    const { getReferrerPath } = useReferrerContext();
    const delegationInput = useService(DelegationInputService);
    const validateService = useService(ValidateService);
    const centeredContainerRef = React.useRef<HTMLDivElement | null>(null);
    const isPrivateNoteOpen = React.useMemo(
        () => isNonEmptyString(props.initialValues.delegation.privateNote),
        [props.initialValues]
    );
    const areAccreditationsOpen = React.useMemo(
        () => props.isEdit && isNonEmptyArray(props.initialValues.delegation.accreditationsSlots),
        [props.initialValues, props.isEdit]
    );
    const areCustomFieldsOpen = React.useMemo(
        () => props.isEdit && isNonEmptyArray(props.initialValues.delegation.customFields),
        [props.initialValues, props.isEdit]
    );
    const areTagsOpen = React.useMemo(
        () => isNonEmptyArray(props.initialValues.delegation.tagsIds),
        [props.initialValues]
    );
    const [isAddAccreditationOpen, setIsAddAccreditationOpen] = React.useState(false);
    const referrerPath = getReferrerPath();

    return (
        <Form
            height={1}
            hideErrors={true}
            initialValues={props.initialValues}
            render={({ form, handleSubmit, submitting, values }) => (
                <>
                    <FullScreenPopup
                        button={
                            props.isEdit ? (
                                <Button isLoading={submitting} onClick={handleSubmit}>
                                    {translate('mettre_jour_l_69948')}
                                </Button>
                            ) : null
                        }
                        category={
                            props.isEdit
                                ? translate(
                                      'mise_jour_de_17055',
                                      props.initialValues.delegation.name
                                  )
                                : translate('cr_ation_d_une_07120')
                        }
                        closePath={referrerPath ?? props.closePath}
                        color="dark"
                        title={
                            props.isEdit
                                ? props.initialValues.delegation.name
                                : translate('nouvelle_d_l_ga_42871')
                        }
                    >
                        <CenteredContainer ref={centeredContainerRef}>
                            <FormErrors />

                            <FormBox
                                hideToggle={true}
                                initialIsOpen={true}
                                title={translate('informations_su_34607')}
                            >
                                {props.delegationType === 'event' ? (
                                    <CreateEventDelegationInformations
                                        change={form.change}
                                        initialParentId={
                                            props.initialValues.delegation.parentId as DelegationId
                                        }
                                        leadersIds={values.delegation.leadersIds}
                                        organizationDelegations={props.organizationDelegations}
                                    />
                                ) : (
                                    <CreateOrganizationDelegationInformations
                                        change={form.change}
                                        leadersIds={values.delegation.leadersIds}
                                    />
                                )}
                            </FormBox>

                            <Spacer height="5" />

                            <FormBox
                                hideToggle={true}
                                initialIsOpen={true}
                                title={translate('param_tres_de_l_81810')}
                            >
                                <CreateDelegationParameters
                                    change={form.change}
                                    customFields={props.customFields}
                                    forms={props.forms}
                                    initialValues={props.initialValues}
                                    values={values}
                                />
                            </FormBox>

                            <Spacer height="5" />

                            {props.delegationType === 'event' && (
                                <>
                                    <FormBox
                                        initialIsOpen={areAccreditationsOpen}
                                        subtitle={translate('delegation_accreditations_desc')}
                                        title={translate('accr_ditations_39450')}
                                        onToggle={(isOpen) => {
                                            if (!isOpen) {
                                                form.change('delegation.accreditationsSlots', []);
                                            }
                                        }}
                                    >
                                        <AccreditationsForm
                                            allAccreditationCategories={
                                                props.accreditationsCategories
                                            }
                                            change={form.change}
                                            setIsAddAccreditationOpen={setIsAddAccreditationOpen}
                                            values={values.delegation}
                                        />
                                    </FormBox>

                                    <Spacer height="5" />
                                </>
                            )}

                            <FormBox
                                initialIsOpen={areCustomFieldsOpen}
                                subtitle={translate('choisissez_une_24029')}
                                title={translate('d_finir_la_vale_27863')}
                                onToggle={(isOpen) => {
                                    if (!isOpen) {
                                        form.change('delegation.customFields', []);
                                    }
                                }}
                            >
                                <FormParametersGeneralCustomFields
                                    change={form.change}
                                    customFields={props.customFields}
                                    name="delegation.customFields"
                                    values={values.delegation.customFields}
                                />
                            </FormBox>

                            <Spacer height="5" />

                            <FormBox
                                initialIsOpen={isPrivateNoteOpen}
                                subtitle={translate('ajoutez_une_not_98366')}
                                title={translate('note_priv_e_06303')}
                                onToggle={(isOpen) => {
                                    if (!isOpen) {
                                        form.change('delegation.privateNote', '');
                                    }
                                }}
                            >
                                <RichEditor
                                    label={
                                        <LabelOptional>
                                            {translate('note_priv_e_06303')}
                                        </LabelOptional>
                                    }
                                    name="delegation.privateNote"
                                />
                            </FormBox>

                            <Spacer height="5" />

                            <FormBox
                                initialIsOpen={areTagsOpen}
                                subtitle={translate('ajouter_des_tag_35833')}
                                title={translate('tags_79499')}
                                onToggle={(isOpen) => {
                                    if (!isOpen) {
                                        form.change('delegation.tagsIds', []);
                                    }
                                }}
                            >
                                <TagsForm
                                    change={form.change}
                                    name="delegation."
                                    organizationId={organizationId}
                                    tags={props.tags}
                                    tagsIds={values.delegation.tagsIds}
                                />
                            </FormBox>

                            <Spacer height="5" />

                            <FormBox>
                                <Button
                                    isLoading={submitting}
                                    textAlign="center"
                                    onClick={handleSubmit}
                                >
                                    {props.isEdit
                                        ? translate('mettre_jour_l_69948')
                                        : translate('cr_er_la_d_l_ga_53268')}
                                </Button>
                            </FormBox>
                        </CenteredContainer>
                    </FullScreenPopup>

                    {props.delegationType === 'event' && isAddAccreditationOpen && (
                        <AccreditationsSelectPanel
                            allAccreditationCategories={props.accreditationsCategories}
                            change={form.change}
                            eventEndAt={props.eventEndAt}
                            eventStartAt={props.eventStartAt}
                            setIsAddAccreditationOpen={setIsAddAccreditationOpen}
                            values={values.delegation}
                        />
                    )}
                </>
            )}
            validate={validateService.validateForForm(
                delegationInput.createUpdateDelegationSchema()
            )}
            width={1}
            onShowErrors={() => {
                if (centeredContainerRef.current) {
                    centeredContainerRef.current.scrollTop = 0;
                }
            }}
            onSubmit={async (values: ICreateUpdateDelegationValues) => {
                await props.mutate({
                    organizationId,
                    eventId,
                    delegation: values.delegation
                });

                history.push(referrerPath ?? props.closePath);
            }}
        />
    );
};

export const CreateEventDelegation = () => {
    const {
        params: { organizationId, eventId, options }
    } = useHeavent();
    const delegationInput = useService(DelegationInputService);
    const { data, loader } = useDelegationEventInfosQuery({ organizationId, eventId });
    const { mutate } = useDelegationCreateMutation();
    const initialValues = React.useMemo(() => {
        if (data.organization) {
            let parentId: Emptyable<DelegationId> = null;
            let parentDelegation: Emptyable<
                DelegationEventInfosQuery['organization']['delegations']['nodes'][0]
            > = null;

            if (isNonEmptyString(options)) {
                const parsedOptions = JSON.parse(decodeBase64(options));

                parentId = parsedOptions.parentId;
                parentDelegation = parsedOptions.applyParentSettings
                    ? data.organization.delegations.nodes.find((d) => d.id === parentId)
                    : null;
            }

            return {
                delegation: delegationInput.delegationInputDefault(null, {
                    forms: data.event.forms,
                    parentId,
                    parentDelegation
                })
            };
        } else {
            return {} as any;
        }
    }, [options, data]);

    return (
        loader || (
            <CreateUpdateDelegation
                accreditationsCategories={data.event.accreditationsCategories}
                closePath={HeaventPaths.DELEGATIONS(organizationId, eventId)}
                customFields={data.organization.customFields.nodes}
                delegationType="event"
                eventEndAt={data.event.endAt}
                eventStartAt={data.event.startAt}
                forms={data.event.forms}
                initialValues={initialValues}
                isEdit={false}
                mutate={async (values) => (await mutate(values)).delegationCreate.id}
                organizationDelegations={data.organization.delegations.nodes}
                tags={data.organization.tags.nodes}
            />
        )
    );
};

export const CreateOrganizationDelegation = () => {
    const {
        params: { organizationId }
    } = useHeavent();
    const delegationInput = useService(DelegationInputService);
    const { data, loader } = useDelegationOrganizationInfosQuery({ organizationId });
    const { mutate } = useDelegationCreateMutation();

    return (
        loader || (
            <CreateUpdateDelegation
                closePath={HeaventPaths.COMMUNITY_DELEGATIONS(organizationId)}
                customFields={data.organization.customFields.nodes}
                delegationType="organization"
                forms={data.organization.forms}
                initialValues={{
                    delegation: delegationInput.delegationInputDefault(null, null)
                }}
                isEdit={false}
                mutate={async (values) => (await mutate(values)).delegationCreate.id}
                tags={data.organization.tags.nodes}
            />
        )
    );
};

export const UpdateEventDelegation = () => {
    const {
        params: { organizationId, eventId, delegationId }
    } = useHeavent();
    const delegationInput = useService(DelegationInputService);
    const { data, loader } = useDelegationEventToEditQuery({
        organizationId,
        eventId,
        delegationId
    });
    const { mutate } = useDelegationUpdateMutation();

    return (
        loader || (
            <CreateUpdateDelegation
                accreditationsCategories={data.event.accreditationsCategories}
                closePath={HeaventPaths.DELEGATION(organizationId, eventId, delegationId)}
                customFields={data.organization.customFields.nodes}
                delegationType="event"
                eventEndAt={data.event.endAt}
                eventStartAt={data.event.startAt}
                forms={data.event.forms}
                initialValues={{
                    delegation: delegationInput.delegationInputDefault(data.event.delegation, null)
                }}
                isEdit={true}
                mutate={async (values) =>
                    (
                        await mutate({
                            ...values,
                            delegationId
                        })
                    ).delegationUpdate.id
                }
                organizationDelegations={data.organization.delegations.nodes}
                tags={data.organization.tags.nodes}
            />
        )
    );
};

export const UpdateOrganizationDelegation = () => {
    const {
        params: { organizationId, delegationId }
    } = useHeavent();
    const delegationInput = useService(DelegationInputService);
    const { data, loader } = useDelegationOrganizationToEditQuery({ organizationId, delegationId });
    const { mutate } = useDelegationUpdateMutation();

    return (
        loader || (
            <CreateUpdateDelegation
                closePath={HeaventPaths.COMMUNITY_DELEGATION(organizationId, delegationId)}
                customFields={data.organization.customFields.nodes}
                delegationType="organization"
                forms={data.organization.forms}
                initialValues={{
                    delegation: delegationInput.delegationInputDefault(
                        {
                            ...data.organization.delegation,
                            accreditations: [],
                            accreditationsSlots: []
                        },
                        null
                    )
                }}
                isEdit={true}
                mutate={async (values) =>
                    (
                        await mutate({
                            ...values,
                            delegationId
                        })
                    ).delegationUpdate.id
                }
                tags={data.organization.tags.nodes}
            />
        )
    );
};
