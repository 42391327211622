import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { isNonEmptyArray } from 'common/src/util/array';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { FilterPredicate, Operator, Predicate } from 'common/src/vo/segment';
import * as React from 'react';
import { Button } from '../../designSystem/components/button';
import { RadioText } from '../../designSystem/components/radio';
import { RichSelect } from '../../designSystem/components/richSelect/richSelect';

interface IAddSelectFilterProps {
    filterPredicate: FilterPredicate;

    onAdd(predicate: Predicate): void;
}

export const AddSelectFilter = (props: IAddSelectFilterProps) => {
    const translate = useTranslate();
    const [operator, setOperator] = React.useState<Operator>(
        props.filterPredicate.predicate?.operator ?? 'is'
    );
    const options = React.useMemo(() => {
        if (isNonEmptyArray(props.filterPredicate.filter.values)) {
            return props.filterPredicate.filter.values.map(({ id, name }) => (
                <option key={id} value={id}>
                    {translate(name)}
                </option>
            ));
        } else if (isNonEmptyArray(props.filterPredicate.filter.valuesGroups)) {
            return props.filterPredicate.filter.valuesGroups.map(({ name, values }, index) => (
                <optgroup key={index} label={name}>
                    {values.map((v) => (
                        <option key={v.id} value={v.id}>
                            {v.name}
                        </option>
                    ))}
                </optgroup>
            ));
        } else {
            return [];
        }
    }, [props.filterPredicate.filter]);
    const [value, setValue] = React.useState<any[]>(
        isNonEmptyArray(props.filterPredicate.predicate?.value)
            ? props.filterPredicate.predicate!.value
            : []
    );
    const valid = operator === 'is' || operator === 'isNot' ? isNonEmptyArray(value) : true;
    const valueLength = React.useMemo(() => (Array.isArray(value) ? value.length : 0), [value]);

    return (
        <>
            <RadioText
                state={operator === 'is' ? 'checked' : 'unchecked'}
                onClick={() => {
                    setOperator('is');
                }}
            >
                {translate('est_25967')}
            </RadioText>

            {operator === 'is' && (
                <>
                    <Spacer height="1" />

                    <Flex css={{ paddingLeft: '$6' }}>
                        <RichSelect
                            hint={translate('_1_s_lectionn_51286', valueLength)}
                            isSearchVisible={true}
                            isSelectAllVisible={true}
                            multiple={true}
                            placeholder={translate('s_lectionner_de_52380')}
                            values={value}
                            onChange={setValue}
                        >
                            {options}
                        </RichSelect>
                    </Flex>

                    <Spacer height="1" />
                </>
            )}

            <Spacer height="3" />

            <RadioText
                state={operator === 'isNot' ? 'checked' : 'unchecked'}
                onClick={() => {
                    setOperator('isNot');
                }}
            >
                {translate('n_est_pas_97826')}
            </RadioText>

            {operator === 'isNot' && (
                <>
                    <Spacer height="1" />

                    <Flex css={{ paddingLeft: '$6' }}>
                        <RichSelect
                            hint={translate('_1_s_lectionn_51286', valueLength)}
                            isSearchVisible={true}
                            isSelectAllVisible={true}
                            multiple={true}
                            placeholder={translate('s_lectionner_de_52380')}
                            values={value}
                            onChange={setValue}
                        >
                            {options}
                        </RichSelect>
                    </Flex>

                    <Spacer height="1" />
                </>
            )}

            {props.filterPredicate.filter.hideEmpty !== true && (
                <>
                    <Spacer height="3" />

                    <RadioText
                        state={operator === 'isEmpty' ? 'checked' : 'unchecked'}
                        onClick={() => {
                            setOperator('isEmpty');
                        }}
                    >
                        {translate('est_vide_96759')}
                    </RadioText>

                    <Spacer height="3" />

                    <RadioText
                        state={operator === 'isNotEmpty' ? 'checked' : 'unchecked'}
                        onClick={() => {
                            setOperator('isNotEmpty');
                        }}
                    >
                        {translate('n_est_pas_vide_45804')}
                    </RadioText>
                </>
            )}

            <Spacer height="7" />

            <Flex>
                <Button
                    disabled={!valid}
                    onClick={() => {
                        props.onAdd({
                            operator,
                            value,
                            slug: props.filterPredicate.filter.slug
                        });
                    }}
                >
                    {translate('ajouter_le_filt_54222')}
                </Button>
            </Flex>
        </>
    );
};
