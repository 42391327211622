import { Form } from 'common-front/src/components/form/form';
import { FormErrors } from 'common-front/src/components/form/formErrors';
import { Button } from 'common-front/src/designSystem/components/button';
import { Textarea } from 'common-front/src/designSystem/form/textarea';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { EventId, OrganizationId, UsersInfoId } from 'common/src/generated/types';
import { CommentInputService, ICreateCommentValues } from 'common/src/input/commentInput';
import { ValidateService } from 'common/src/services/validateService';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { Emptyable } from 'common/src/util/emptyable';
import * as React from 'react';
import { useCommentCreateMutation, useCommentsQuery } from '../../generated/graphqlHooks';
import { Comment } from '../show/comments/comment';

interface IUserProfileCommentsProps {
    eventId: Emptyable<EventId>;
    organizationId: OrganizationId;
    userInfoId: UsersInfoId;
}

export const UserProfileComments = (props: IUserProfileCommentsProps) => {
    const translate = useTranslate();
    const commentInput = useService(CommentInputService);
    const validateService = useService(ValidateService);
    const { data, reload: reloadComments } = useCommentsQuery({
        organizationId: props.organizationId,
        userInfoId: props.userInfoId
    });
    const { mutate, isLoading } = useCommentCreateMutation();
    const [renderKey, setRenderKey] = React.useState(1);
    const reload = React.useCallback(() => {
        setRenderKey(renderKey + 1);
        reloadComments();
    }, [renderKey, setRenderKey, reloadComments]);

    return (
        <Form
            key={renderKey}
            direction="column"
            hideErrors={true}
            initialValues={{
                comment: commentInput.commentInputDefault()
            }}
            render={({ handleSubmit }) => (
                <>
                    <Spacer height="7" />

                    <Flex
                        css={{
                            background: 'white',
                            border: '1px solid $gray200',
                            borderRadius: '$2',
                            boxShadow: '$xs',
                            padding: '$6'
                        }}
                        direction="column"
                    >
                        <Flex direction="column" gap="6">
                            {(data.organization?.userInfo.comments ?? []).map((comment) => (
                                <Comment
                                    key={comment.id}
                                    comment={comment}
                                    reload={reload}
                                    showEvent={true}
                                />
                            ))}
                        </Flex>

                        <Spacer height="6" />

                        <FormErrors />

                        <Textarea label={translate('commentaire_08754')} name="comment.content" />

                        <Spacer height="4" />

                        <Button isLoading={isLoading} onClick={handleSubmit}>
                            <Box textAlign="center" width={1}>
                                {translate('ajouter_un_nouv_86295')}
                            </Box>
                        </Button>
                    </Flex>
                </>
            )}
            validate={validateService.validateForForm(commentInput.createCommentSchema())}
            width={1}
            onSubmit={async (values: ICreateCommentValues) => {
                await mutate({
                    organizationId: props.organizationId,
                    eventId: props.eventId,
                    userInfoId: props.userInfoId,
                    comment: values.comment
                });

                reload();
            }}
        />
    );
};
