import { NoFeature } from 'common-front/src/components/noFeature';
import { Flex } from 'common/src/designSystem/components/flex';
import { Feature } from 'common/src/generated/types';
import * as React from 'react';
import { useOrganizationContext } from '../../organization/organizationContext';
import { DelegationsLoader } from './delegationsLoader';

export const DelegationsHasFeature = () => {
    const { hasFeature } = useOrganizationContext();

    return hasFeature(Feature.Delegation) ? (
        <DelegationsLoader />
    ) : (
        <Flex align="center" height={1} justify="center" width={1}>
            <NoFeature icon="people-group" />
        </Flex>
    );
};
