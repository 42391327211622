import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';

export const BadgePastourelleexposantBack = () => (
    <Flex
        css={{
            background: `url(https://assets.recrewteer.com/badges/pastourelle2024/Badge_verso.png) no-repeat`,
            backgroundSize: 'cover',
            overflow: 'hidden',
            pageBreakBefore: 'always',
            position: 'relative'
        }}
        direction="column"
        height={597}
        width={435}
    >
        <Box
            css={{
                height: '472px',
                left: '29px',
                position: 'absolute',
                top: '45px',
                width: '376px'
            }}
        >
            <img
                height="100%"
                src="https://assets.recrewteer.com/badges/pastourelle2024/programme.png"
                width="100%"
            />
        </Box>
    </Flex>
);
