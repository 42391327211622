import * as React from 'react';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { BadgePastourelleexposantBack } from './badgePastourelleexposantBack';
import { BadgePastourelleexposantFront } from './badgePastourelleexposantFront';

interface IBadgePastourelleexposantA6Props {
    event: DocumentUserCustomBadgeFragment;
}

export const BadgePastourelleexposant = (props: IBadgePastourelleexposantA6Props) => (
    <>
        <BadgePastourelleexposantFront event={props.event} />

        <BadgePastourelleexposantBack />
    </>
);
