import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I, IIcon } from 'common/src/designSystem/components/i';
import { theme } from 'common/src/designSystem/components/stitches';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';
import { createPortal } from 'react-dom';
import { IconBackground } from './iconBackground';

type INotificationColor = 'success';

export interface INotificationProps {
    color: INotificationColor;
    icon: IIcon;
    subtext?: string;
    text: string;

    onClose(): void;
}

export const Notification = (props: INotificationProps) => {
    const divRef = React.useRef<HTMLDivElement | null>(null);
    const onRef = React.useCallback((div: HTMLDivElement) => {
        if (div) {
            divRef.current = div;

            setTimeout(() => {
                div.style.right = theme.space['6'].value;
            }, 100);
        }
    }, []);
    const onClose = React.useCallback(() => {
        divRef.current!.style.right = '-400px';

        props.onClose();
    }, [props.onClose]);

    return createPortal(
        <Flex
            ref={onRef}
            className="h-notification"
            css={{
                background: 'white',
                borderRadius: '$2',
                boxShadow: '$lg',
                maxHeight: '120px',
                padding: '$3',
                position: 'absolute',
                right: '-400px',
                top: '$6',
                transition: '0.5s right ease-in-out',
                zIndex: '1000'
            }}
            gap="5"
            role="status"
            width={400}
        >
            <IconBackground color={props.color} icon={props.icon} />

            <Flex css={{ flex: '1', py: '$2' }} direction="column" gap="4">
                <Box
                    color="gray900"
                    css={{
                        '& a': {
                            color: '$primary700'
                        }
                    }}
                    dangerouslySetInnerHTML={{
                        __html: props.text
                    }}
                />

                {isNonEmptyString(props.subtext) && <Box color="gray500">{props.subtext}</Box>}
            </Flex>

            <Flex
                align="center"
                css={{
                    color: '$gray500',
                    cursor: 'pointer',
                    fontSize: '$textMd'
                }}
                height={32}
                justify="center"
                width={32}
                onClick={onClose}
            >
                <I icon="xmark" />
            </Flex>
        </Flex>,
        document.body
    );
};
