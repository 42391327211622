import { SegmentsFolder } from 'common/src/generated/types';
import {
    ICreateSegmentFolderValues,
    SegmentFolderInputService
} from 'common/src/input/segmentFolderInput';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { FormModal } from '../../designSystem/components/formModal';
import { TextInput } from '../../designSystem/form/textInput';
import {
    useSegmentFolderCreateMutation,
    useSegmentFolderUpdateNameMutation
} from '../../generated/graphqlHooks';
import { useSegmentsContext } from '../segmentsContext';

interface ICreateFolderModalProps {
    isEdit: boolean;
    segmentFolder?: Pick<SegmentsFolder, 'id' | 'name'>;

    onClose(): void;
}

export const CreateFolderModal = (props: ICreateFolderModalProps) => {
    const translate = useTranslate();
    const { eventId, organizationId, segmentType, reload } = useSegmentsContext();
    const { mutate: segmentFolderCreate } = useSegmentFolderCreateMutation();
    const { mutate: segmentFolderUpdateName } = useSegmentFolderUpdateNameMutation();
    const segmentFolderInput = useService(SegmentFolderInputService);

    return (
        <FormModal
            buttonText={
                props.isEdit ? translate('mettre_jour_l_05529') : translate('cr_er_le_dossie_71150')
            }
            icon="text"
            initialValues={{
                segmentFolder: segmentFolderInput.segmentFolderInputDefault(
                    segmentType,
                    props.segmentFolder
                )
            }}
            size="md"
            subtitle={translate('classez_vos_vue_39895')}
            title={translate('nom_du_dossier_95781')}
            onClose={props.onClose}
            onSubmit={async (values: ICreateSegmentFolderValues) => {
                if (props.isEdit && props.segmentFolder) {
                    await segmentFolderUpdateName({
                        organizationId,
                        eventId,
                        segmentFolderId: props.segmentFolder.id,
                        name: values.segmentFolder.name
                    });
                } else {
                    await segmentFolderCreate({
                        organizationId,
                        eventId,
                        segmentFolder: values.segmentFolder
                    });
                }

                reload();
                props.onClose();
            }}
        >
            <TextInput label={translate('nom_du_dossier_95781')} name="segmentFolder.name" />
        </FormModal>
    );
};
