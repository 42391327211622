import { Flex } from 'common/src/designSystem/components/flex';
import * as React from 'react';

interface ILinkProps {
    children: React.ReactNode;
}

export const Link = (props: ILinkProps) => (
    <Flex
        align="center"
        direction={{ '@initial': 'column', '@desktop': 'row' }}
        gap="1"
        justify="center"
        width={1}
    >
        {props.children}
    </Flex>
);
