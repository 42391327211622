import { Flex, IFlexProps } from 'common/src/designSystem/components/flex';
import { styled } from 'common/src/designSystem/components/stitches';
import { UsersInfo } from 'common/src/generated/types';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';

interface IVolunteerEmailAndNameMobileMainProps {
    size: 40 | 64;
    userInfo: Pick<UsersInfo, 'name' | 'email'>;
}

type IVolunteerEmailAndNameMobileProps = IVolunteerEmailAndNameMobileMainProps &
    Omit<IFlexProps, 'children' | 'ref'>;

const _Flex = styled(Flex, {
    overflow: 'hidden'
});

const _Div = styled('div', {
    color: '$gray500',
    fontSize: '$textSm',
    ellipsis: '',
    variants: {
        size: {
            40: {
                '&:first-child': {
                    color: '$gray800',
                    fontSize: '$textMd',
                    fontWeight: '$medium'
                }
            },
            64: {
                '&:first-child': {
                    color: '$gray900',
                    fontSize: '$textXl',
                    fontWeight: '$semiBold'
                }
            }
        }
    }
});

export const VolunteerEmailAndNameMobile = ({
    userInfo,
    size,
    ...props
}: IVolunteerEmailAndNameMobileProps) => (
    <_Flex direction="column" {...props}>
        {isNonEmptyString(userInfo.name) && <_Div size={size}>{userInfo.name}</_Div>}
        <_Div size={size}>{userInfo.email}</_Div>
    </_Flex>
);
