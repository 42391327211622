import { Page } from 'common-front/src/components/page/page';
import { Tab } from 'common-front/src/designSystem/components/tabs/tab';
import { TabList } from 'common-front/src/designSystem/components/tabs/tabList';
import { TabPanel } from 'common-front/src/designSystem/components/tabs/tabPanel';
import { Tabs } from 'common-front/src/designSystem/components/tabs/tabs';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { AccreditationQuery } from 'common/src/generated/types';
import {
    AccreditationParams,
    AccreditationsPaths,
    AccreditationUserParams
} from 'common/src/util/paths/accreditationsPaths';
import { UserInfoIdPathParam } from 'common/src/util/pathsTypes';
import * as React from 'react';
import { useEventContext } from '../../events/show/eventContext';
import { UserOverlayEvent } from '../../users/overlay/userOverlay';
import { AccreditationHeader } from './accreditationHeader';
import { AccreditationDashboard } from './dashboard/accreditationDashboard';
import { AccreditationMembers } from './members/show/accreditationMembers';

interface IAccreditationProps {
    organization: AccreditationQuery['organization'];

    reload(): void;
}

export const Accreditation = ({ organization, reload }: IAccreditationProps) => {
    const {
        history,
        params: { organizationId, eventId, accreditationId },
        translate
    } = useHeavent();

    const isEventAdmin = useEventContext().event?.permissions.isEventAdmin;

    const getAccreditationParams = (isRouteComponent: boolean): AccreditationParams => ({
        organizationId: isRouteComponent ? ':organizationId' : organizationId,
        eventId: isRouteComponent ? ':eventId' : eventId,
        accreditationId: isRouteComponent ? ':accreditationId' : accreditationId
    });
    const getAccreditationUserParams = (
        isRouteComponent: boolean,
        userInfoId: UserInfoIdPathParam
    ): AccreditationUserParams => ({
        ...getAccreditationParams(isRouteComponent),
        userInfoId: isRouteComponent ? ':userInfoId' : userInfoId
    });

    return (
        <Page.Root>
            <AccreditationHeader accreditation={organization.event.accreditation} />

            <Tabs css={{ flex: '1' }}>
                <TabList css={{ px: '$7' }}>
                    <Tab
                        path={AccreditationsPaths.ACCREDITATION_DASHBOARD(
                            getAccreditationParams(false)
                        )}
                    >
                        {translate('tableau_de_bord_24627')}
                    </Tab>

                    <Tab
                        path={AccreditationsPaths.ACCREDITATION_MEMBERS(
                            getAccreditationParams(false)
                        )}
                    >
                        {translate(
                            'membres_125561',
                            organization.event.accreditation.assignedResources
                        )}
                    </Tab>
                </TabList>

                <TabPanel
                    path={AccreditationsPaths.ACCREDITATION_DASHBOARD(getAccreditationParams(true))}
                >
                    <AccreditationDashboard />
                </TabPanel>

                <TabPanel
                    path={AccreditationsPaths.ACCREDITATION_MEMBERS(getAccreditationParams(true))}
                >
                    <Page.Content>
                        <AccreditationMembers
                            accreditation={organization.event.accreditation}
                            basePath={AccreditationsPaths.ACCREDITATION_MEMBERS(
                                getAccreditationParams(false)
                            )}
                            canEditMembers={true}
                            customFields={organization.customFields.nodes}
                            isEventAdmin={isEventAdmin}
                            showDeleteFromEvent={isEventAdmin}
                            showDocumentsDownload={true}
                            userOverlay={() => {
                                const onCloseAndDelete = () => {
                                    history.push(
                                        AccreditationsPaths.ACCREDITATION_MEMBERS(
                                            getAccreditationParams(false)
                                        )
                                    );
                                };

                                return (
                                    <UserOverlayEvent
                                        getBasePath={(
                                            userInfoId: UserInfoIdPathParam,
                                            isRouteComponent: boolean = false
                                        ) =>
                                            AccreditationsPaths.ACCREDITATION_MEMBER(
                                                getAccreditationUserParams(
                                                    isRouteComponent,
                                                    userInfoId
                                                )
                                            )
                                        }
                                        onClose={onCloseAndDelete}
                                        onDelete={() => {
                                            onCloseAndDelete();

                                            reload();
                                        }}
                                        onUpdateState={reload}
                                    />
                                );
                            }}
                        />
                    </Page.Content>
                </TabPanel>
            </Tabs>
        </Page.Root>
    );
};
