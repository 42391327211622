import { Flex } from 'common/src/designSystem/components/flex';
import { Skeleton } from 'common/src/designSystem/components/skeleton';
import * as React from 'react';

export const AccreditAssignWaitingVolunteerSkeleton = () => (
    <Flex
        align="center"
        css={{
            borderBottom: '1px solid $gray200',
            padding: '$4'
        }}
        gap="3"
    >
        <Skeleton size={40} variant="circular" />

        <Flex css={{ flex: '1' }} direction="column" gap="1">
            <Skeleton borderRadius="$1" height={20} width={1} />
            <Skeleton borderRadius="$1" height={20} width={1} />
        </Flex>
    </Flex>
);
