import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { UserPositionsWished } from 'common-front/src/users/information/userPositionsWished';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { DurationService } from 'common/src/services/durationService';
import { isNonEmptyArray } from 'common/src/util/array';
import { useService } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { LocaleFormats } from 'common/src/util/luxon';
import { sortBy } from 'lodash-es';
import * as React from 'react';
import { useEventContext } from '../../../events/show/eventContext';
import { useUserOverlayPositionsQuery } from '../../../generated/graphqlHooks';
import { UserPanelPosition } from '../../show/registration/userPanelPosition';
import { UserOverlayAvailablePositionsSlots } from './userOverlayAvailablePositionsSlots';
import { UserOverlayPositionsPreassigned } from './userOverlayPositionsPreassigned';

export const UserOverlayPositions = () => {
    const {
        params: { organizationId, eventId, userInfoId },
        translate
    } = useHeavent();
    const dateTimeService = useService(DateTimeService);
    const durationService = useService(DurationService);
    const { isPositionAdmin } = useEventContext();
    const { data, loader, reload } = useUserOverlayPositionsQuery({
        organizationId,
        eventId,
        userInfoId
    });
    const assignments = React.useMemo(
        () =>
            sortBy(data.event?.volunteerRegistration.positionsSlotsUsersInfos ?? [], (psui) =>
                psui.positionSlot.range.start!.toMillis()
            ),
        [data.event]
    );

    return (
        loader || (
            <>
                <Spacer height="6" />

                {isNonEmptyArray(assignments) && (
                    <>
                        <Box font="gray800 textMd semiBold">
                            {translate('missions_affect_97811')}
                        </Box>

                        {assignments.map((assignment, index) => (
                            <React.Fragment key={index}>
                                <Spacer height="3" />

                                <UserPanelPosition
                                    positionSlotUserInfo={assignment}
                                    reload={reload}
                                />
                            </React.Fragment>
                        ))}
                    </>
                )}

                <Spacer height="6" />

                <UserOverlayPositionsPreassigned
                    eventId={eventId}
                    organizationId={organizationId}
                    volunteerRegistration={data.event.volunteerRegistration}
                />

                {data.event.hasFormWithPositionDisplay && (
                    <>
                        <Spacer height="6" />

                        <UserPositionsWished
                            editPath={HeaventPaths.EDIT_USER_MISSIONS(
                                organizationId,
                                eventId,
                                userInfoId
                            )}
                            formsUsersInfos={data.organization.userInfo.formsUsersInfos}
                        />
                    </>
                )}

                {isPositionAdmin() && (
                    <>
                        <Spacer height="6" />

                        <UserOverlayAvailablePositionsSlots
                            reload={reload}
                            volunteerRegistrationId={data.event.volunteerRegistration.id}
                        />
                    </>
                )}

                {isNonEmptyArray(assignments) && (
                    <>
                        <Box font="gray800 textMd semiBold">{translate('statistiques_98073')}</Box>

                        <Spacer height="3" />

                        <Flex
                            css={{
                                border: '1px solid $gray200',
                                borderRadius: '$2',
                                boxShadow: '$xs',
                                padding: '$6 $9'
                            }}
                        >
                            <Flex direction="column" gap="1" width={1}>
                                <Box font="gray800 displayMd semiBold">
                                    {durationService.minutesToHoursMinutesString(
                                        data.event.volunteerRegistration.volunteerMinutes
                                    )}
                                </Box>

                                <Box color="gray500">{translate('nombre_d_heures_46954')}</Box>
                            </Flex>

                            <Flex direction="column" gap="1" width={1}>
                                <Box font="gray800 displayMd semiBold">{assignments.length}</Box>

                                <Box color="gray500">{translate('nombre_de_missi_80367')}</Box>
                            </Flex>
                        </Flex>

                        <Spacer height="8" />
                    </>
                )}

                <Box font="gray800 textMd semiBold">{translate('inscription_48144')}</Box>

                <Spacer height="3" />

                <Flex
                    css={{
                        border: '1px solid $gray200',
                        borderRadius: '$2',
                        boxShadow: '$xs',
                        padding: '$4'
                    }}
                    gap="3"
                >
                    <Flex
                        align="center"
                        css={{
                            background: '$purple100',
                            borderRadius: '12px',
                            color: '$purple700'
                        }}
                        height={40}
                        justify="center"
                        width={40}
                    >
                        <I icon="calendar" />
                    </Flex>

                    <Flex direction="column">
                        <Box font="gray800 textSm semiBold">
                            {translate('date_d_inscript_31369')}
                        </Box>
                        <Box color="gray500">
                            {dateTimeService.toLocaleString(
                                data.event.volunteerRegistration.insertedAt.toLocal(),
                                LocaleFormats.DateTime
                            )}
                        </Box>
                    </Flex>
                </Flex>

                <Spacer height="8" />
            </>
        )
    );
};
