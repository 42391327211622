import { Box } from 'common/src/designSystem/components/box';
import { isNonEmptyString } from 'common/src/util/string';
import { DocumentImageElement } from 'common/src/vo/documentBuilder';
import * as React from 'react';
import { useDocumentBuilderContext } from '../documentBuilderContext';

const getSrc = (source: string) => {
    if (source === '{user.picture}') {
        return 'https://assets.recrewteer.com/badges/profile.jpg';
    } else if (source === '{user.qrcode}') {
        return `https://api.recrewteer.com/barcode/qr?text=${encodeURIComponent(
            'https://weezevent.com'
        )}&color=000000&height=360&width=360`;
    } else {
        return source;
    }
};

interface IDocumentBuilderImageProps {
    image: DocumentImageElement;
}

export const DocumentBuilderImage = (props: IDocumentBuilderImageProps) => {
    const { selectedElementId, selectElement } = useDocumentBuilderContext();

    return (
        <Box
            css={{
                border:
                    props.image.borderSize > 0
                        ? `${props.image.borderSize}px solid ${props.image.borderColor}`
                        : '',
                borderRadius: props.image.borderRadius > 0 ? `${props.image.borderRadius}px` : '0',
                left: `${props.image.left}px`,
                outline: selectedElementId === props.image.id ? '2px solid $primary700' : '',
                overflow: 'hidden',
                position: 'absolute',
                top: `${props.image.top}px`,
                transform: props.image.hasRotate
                    ? `rotate(${props.image.rotateDegree}deg)`
                    : undefined,
                transformOrigin: props.image.hasRotate ? 'top left' : undefined,
                zIndex: props.image.zIndex
            }}
            height={props.image.height}
            width={props.image.width}
            onClick={(e) => {
                e.stopPropagation();
                e.nativeEvent.stopImmediatePropagation();

                selectElement(props.image.id);
            }}
        >
            {isNonEmptyString(props.image.source) && (
                <img height="100%" src={getSrc(props.image.source)} width="100%" />
            )}
        </Box>
    );
};
