import { CampaignListFragment, CampaignState } from 'common/src/generated/types';
import * as React from 'react';

export function useInProgressRefresh(campaigns: CampaignListFragment[], reload: () => void) {
    React.useEffect(() => {
        let intervalId: number | null = null;
        const hasInProgress = campaigns.some((c) => c.state === CampaignState.InProgress);

        if (hasInProgress) {
            intervalId = window.setInterval(reload, 30000);
        }

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [campaigns]);
}
