import { Form } from 'common-front/src/components/form/form';
import { useUserInfoPositionsUpdateMutation } from 'common-front/src/generated/graphqlHooks';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { IUserUpdateMissionsValues } from 'common-front/src/users/updateMissions/userUpdateMissionsValues';
import { UserUpdateMissionsInfosQuery } from 'common/src/generated/types';
import { VolunteerRegistrationInputService } from 'common/src/input/volunteerRegistrationInput';
import { useService } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import { UserUpdateMissions } from './userUpdateMissions';
import { useReferrerContext } from 'common-front/src/util/referrerContext';

interface IUserUpdateMissionsFormProps {
    event: UserUpdateMissionsInfosQuery['event'];
    forms: UserUpdateMissionsInfosQuery['event']['forms'];
    userInfo: UserUpdateMissionsInfosQuery['organization']['userInfo'];
}

export const UserUpdateMissionsForm = (props: IUserUpdateMissionsFormProps) => {
    const {
        history,
        params: { organizationId, eventId, userInfoId }
    } = useHeavent();
    const { getReferrerPath } = useReferrerContext();
    const volunteerRegistrationInput = useService(VolunteerRegistrationInputService);
    const { mutate, isLoading } = useUserInfoPositionsUpdateMutation();
    const initialSelectedMissions = React.useMemo(
        () =>
            props.forms.map((form) => {
                const formUserInfo = props.userInfo.formsUsersInfos.find(
                    (fui) => fui.formId === form.id
                );

                return {
                    formId: form.id,
                    ...volunteerRegistrationInput.volunteersRegistrationsWishedPositionsUpdateInputDefault(
                        formUserInfo
                    )
                };
            }),
        [props.forms, props.userInfo.formsUsersInfos]
    );

    return (
        <Form
            direction="column"
            height={1}
            initialValues={{
                userInfo: { missions: initialSelectedMissions }
            }}
            render={({ form, handleSubmit, values }) => (
                <UserUpdateMissions
                    change={form.change}
                    event={props.event}
                    forms={props.forms}
                    handleSubmit={handleSubmit}
                    isLoading={isLoading}
                    userInfo={props.userInfo}
                    values={values}
                />
            )}
            width={1}
            onSubmit={async (values: IUserUpdateMissionsValues) => {
                await mutate({
                    eventId,
                    userInfoId,
                    userInfoPositions: values.userInfo.missions
                });

                history.push(getReferrerPath() ?? HeaventPaths.VOLUNTEERS(organizationId, eventId));
            }}
        />
    );
};
