import { StyledIcon } from 'common-front/src/designSystem/components/iconSelect';
import { Content } from 'common-front/src/designSystem/components/tooltip/content';
import { Tooltip } from 'common-front/src/designSystem/components/tooltip/tooltip';
import { Trigger } from 'common-front/src/designSystem/components/tooltip/trigger';
import { useReferrerContext } from 'common-front/src/util/referrerContext';
import { I, IIcon } from 'common/src/designSystem/components/i';
import { Link } from 'common/src/designSystem/components/link';
import { useLocation } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';

interface IMenuItemProps {
    icon: IIcon;
    to: string;
    title: string;

    isActive?(pathname: string): boolean;
    onClick?(): Promise<any>;
}

export const MenuItem = (props: IMenuItemProps) => {
    const { unsetReferrerPath } = useReferrerContext();
    const location = useLocation();
    const isActive = props.isActive
        ? props.isActive(location.pathname)
        : location.pathname === props.to;

    return (
        <Link
            aria-label={props.title}
            aria-selected={isActive}
            role="menuitem"
            to={props.to}
            onClick={async () => {
                unsetReferrerPath();

                if (props.onClick) {
                    await props.onClick();
                }
            }}
        >
            <Tooltip>
                <Trigger>
                    <StyledIcon active={isActive} role="presentation">
                        <I icon={props.icon} />
                    </StyledIcon>
                </Trigger>

                <Content placement="right">{props.title}</Content>
            </Tooltip>
        </Link>
    );
};
