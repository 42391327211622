import { FilesImportCustomFieldsQuery } from 'common/src/generated/types';
import * as React from 'react';
import { SelectFieldFormBox } from './selectFieldFormBox';

interface ISelectFieldsFormBoxes {
    customFileFields: FilesImportCustomFieldsQuery['organization']['customFileFields']['nodes'];
}

export const SelectFieldsFormBoxes = ({ customFileFields }: ISelectFieldsFormBoxes) => (
    <SelectFieldFormBox customFields={customFileFields} />
);
