import { LabelOptional } from 'common-front/src/designSystem/components/input/labelOptional';
import { ColorInput } from 'common-front/src/designSystem/form/colorInput';
import { IconSelect } from 'common-front/src/designSystem/form/iconSelect';
import { RichEditor } from 'common-front/src/designSystem/form/richEditor';
import { RichSelect } from 'common-front/src/designSystem/form/richSelect';
import { TextInput } from 'common-front/src/designSystem/form/textInput';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { CreatePositionEventFragment } from 'common/src/generated/types';
import * as React from 'react';
import { useEventContext } from '../../events/show/eventContext';
import { CreatePositionCategory } from '../../positionsCategories/create/createUpdatePositionCategory';

interface ICreatePositionsInformationsProps {
    event: CreatePositionEventFragment;
    change(field: string, value: any): void;
}

export const CreatePositionInformations = (props: ICreatePositionsInformationsProps) => {
    const {
        params: { eventId },
        translate
    } = useHeavent();
    const [positionsCategories, setPositionsCategories] = React.useState(
        props.event.positionsCategories
    );
    const {
        event: {
            permissions: { isEventAdmin }
        }
    } = useEventContext();
    const [isCreatePositionCategoryOpen, setIsCreatePositionCategoryOpen] = React.useState(false);

    return (
        <>
            <TextInput label={translate('nom_de_la_missi_64605')} name="position.name" />

            <Spacer height="6" />

            <Flex gap="4">
                <RichSelect
                    createText={translate('cr_er_une_nouve_72722')}
                    isCreateVisible={isEventAdmin}
                    isSearchVisible={true}
                    label={translate('cat_gorie_00291')}
                    name="position.positionCategoryId"
                    placeholder={translate('rechercher_ou_c_16469')}
                    onCreateClick={() => {
                        setIsCreatePositionCategoryOpen(true);
                    }}
                >
                    {positionsCategories.map(({ id, name }) => (
                        <option key={id} value={id}>
                            {name}
                        </option>
                    ))}
                </RichSelect>

                <TextInput
                    label={<LabelOptional>{translate('Acronym')}</LabelOptional>}
                    name="position.acronym"
                />
            </Flex>

            <Spacer height="6" />

            <Flex gap="4">
                <ColorInput label={translate('Color')} name="position.color" />

                <IconSelect label={translate('ic_ne_55554')} name="position.icon" />
            </Flex>

            <Spacer height="6" />

            <RichEditor
                label={<LabelOptional>{translate('description_58935')}</LabelOptional>}
                name="position.description"
            />

            {isCreatePositionCategoryOpen && (
                <CreatePositionCategory
                    eventId={eventId}
                    onClose={() => {
                        setIsCreatePositionCategoryOpen(false);
                    }}
                    onSuccess={(newPositionCategory) => {
                        setPositionsCategories([...positionsCategories, newPositionCategory]);

                        props.change('position.positionCategoryId', newPositionCategory.id);
                    }}
                />
            )}
        </>
    );
};
