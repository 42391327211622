import { Alert } from 'common-front/src/designSystem/components/alert';
import { FormModal } from 'common-front/src/designSystem/components/formModal';
import { RichSelect } from 'common-front/src/designSystem/form/richSelect';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { EventId, FormId, OrganizationId } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import * as yup from 'yup';
import { useAllEventsQuery, useFormDuplicateMutation } from '../../generated/graphqlHooks';

interface IFormDuplicateOtherEventModalProps {
    organizationId: OrganizationId;
    eventId: EventId;
    formId: FormId;

    onClose(): void;
}

export const FormDuplicateOtherEventModal = (props: IFormDuplicateOtherEventModalProps) => {
    const translate = useTranslate();
    const { mutate } = useFormDuplicateMutation();
    const { data } = useAllEventsQuery({ organizationId: props.organizationId });
    const events = React.useMemo(
        () => (data.organization?.events.nodes ?? []).filter((e) => e.id !== props.eventId),
        [data.organization, props.eventId]
    );
    const schema = React.useMemo(
        () =>
            yup.object().shape({
                eventId: yup
                    .number()
                    .required(translate('l_v_nement_est_88155'))
                    .oneOf(
                        events.map((e) => e.id),
                        translate('l_v_nement_est_88155')
                    )
            }),
        [events]
    );

    return (
        <FormModal
            buttonText={translate('dupliquer_ce_fo_10581')}
            icon="sliders"
            initialValues={{
                eventId: -1 as EventId
            }}
            schema={schema}
            size="md"
            subtitle={translate('dupliquer_votre_17703')}
            title={translate('dupliquer_vers_48809')}
            onClose={props.onClose}
            onSubmit={async (values) => {
                await mutate({
                    organizationId: props.organizationId,
                    eventId: props.eventId,
                    formId: props.formId,
                    newEventId: values.eventId
                });

                props.onClose();
            }}
        >
            <Alert color="warning" leftIcon="triangle-exclamation">
                {translate('toutes_les_info_60876')}
                <br />
                {translate('certains_param_05954')}
            </Alert>

            <Spacer height="6" />

            <RichSelect
                isSearchVisible={true}
                label={translate('sur_quel_v_nem_94155')}
                multiple={false}
                name="eventId"
            >
                {events.map((event) => (
                    <option key={event.id} value={event.id}>
                        {event.name}
                    </option>
                ))}
            </RichSelect>
        </FormModal>
    );
};
