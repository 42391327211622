import { FormModal } from 'common-front/src/designSystem/components/formModal';
import { LabelOptional } from 'common-front/src/designSystem/components/input/labelOptional';
import { DateInput } from 'common-front/src/designSystem/form/date/dateInput';
import { TimeInput } from 'common-front/src/designSystem/form/date/timeInput';
import { TextInput } from 'common-front/src/designSystem/form/textInput';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { Event, PositionsSlotInput } from 'common/src/generated/types';
import {
    ICreatePositionSlotValues,
    PositionsSlotInputService
} from 'common/src/input/positionsSlotInput';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { pick } from 'lodash-es';
import * as React from 'react';

interface ICreatePositionCreateSlotModalProps {
    event: Pick<Event, 'startAt' | 'endAt'>;
    slot: PositionsSlotInput;

    onClose(): void;
    onSuccess(slot: PositionsSlotInput): Promise<void>;
}

export const CreatePositionCreateSlotModal = (props: ICreatePositionCreateSlotModalProps) => {
    const translate = useTranslate();
    const positionsSlotInput = useService(PositionsSlotInputService);

    return (
        <FormModal
            buttonText={translate('mettre_jour_l_76289')}
            icon="sliders"
            initialValues={{
                slot: props.slot
            }}
            schema={positionsSlotInput.createPositionSlotSchema()}
            size="md"
            subtitle={translate('param_trer_les_00874')}
            title={translate('mise_jour_d_u_86656')}
            onClose={props.onClose}
            onSubmit={async (values: ICreatePositionSlotValues) => {
                await props.onSuccess(
                    pick(values.slot, [
                        'id',
                        'name',
                        'startDate',
                        'startTime',
                        'endDate',
                        'endTime',
                        'resources'
                    ])
                );
                props.onClose();
            }}
        >
            <Flex css={{ flex: '1', overflowY: 'auto' }} direction="column" width={1}>
                <TextInput
                    hint={translate('vous_pouvez_per_94218')}
                    label={<LabelOptional>{translate('nom_du_cr_neau_54351')}</LabelOptional>}
                    name="slot.name"
                />

                <Spacer height="7" />

                <Flex gap="4">
                    <DateInput
                        label={translate('date_de_d_but_43380')}
                        max={props.event.endAt}
                        min={props.event.startAt}
                        name="slot.startDate"
                    />

                    <TimeInput label={translate('horaire_de_d_bu_02380')} name="slot.startTime" />
                </Flex>

                <Spacer height="4" />

                <Flex gap="4">
                    <DateInput
                        label={translate('date_de_fin_86920')}
                        max={props.event.endAt}
                        min={props.event.startAt}
                        name="slot.endDate"
                    />

                    <TimeInput label={translate('horaire_de_fin_10624')} name="slot.endTime" />
                </Flex>

                <Spacer height="4" />

                <TextInput
                    label={translate('nombre_de_membr_08851')}
                    name="slot.resources"
                    shouldParseAsInt={true}
                />
            </Flex>
        </FormModal>
    );
};
