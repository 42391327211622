import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import * as React from 'react';

interface IPasswordRuleProps {
    children: React.ReactNode;
    isValid: boolean;
}

export const PasswordRule = (props: IPasswordRuleProps) => (
    <Flex align="center" gap={{ '@initial': '2', '@desktop': '1' }}>
        <Flex
            align="center"
            css={{
                background: props.isValid ? '$success100' : 'white',
                border: props.isValid ? 'none' : '1px solid $gray300',
                borderRadius: '16px',
                color: '$success500',
                fontSize: '11px',
                height: '16px',
                width: '16px'
            }}
            justify="center"
        >
            {props.isValid && <I icon="check" />}
        </Flex>

        <Box color="gray800" fontWeight="medium">
            {props.children}
        </Box>
    </Flex>
);
