import { Images, Svgs } from 'common-front/src/util/assets';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import * as React from 'react';

interface IAuthPageProps {
    children: React.ReactNode;
}

export const AuthPage = (props: IAuthPageProps) => (
    <Flex align="center" height={1} width={1}>
        <Box css={{ padding: '150px' }} height={1} width={0.5}>
            <img alt="Vabene illustration" height="100%" src={Svgs.MsoLogin} width="100%" />
        </Box>

        <Flex
            align="center"
            css={{ background: '$cyan' }}
            direction="column"
            height={1}
            justify="center"
            width={0.5}
        >
            <Box>
                <img alt="Logo Vabene by MSO" height="100" src={Images.MsoLogo1} />
            </Box>

            <Box height={108} />

            <Flex direction="column" width={400}>
                {props.children}
            </Flex>
        </Flex>
    </Flex>
);
