import { Flex } from 'common/src/designSystem/components/flex';
import { styled } from 'common/src/designSystem/components/stitches';

export const OrganizationSectionMobile = styled(Flex, {
    background: '$white',
    borderRadius: '$2',
    boxShadow: '$sm',
    gap: '$6',
    padding: '$4 $3'
});
