import { Form } from 'common-front/src/components/form/form';
import { FormErrors } from 'common-front/src/components/form/formErrors';
import { Button } from 'common-front/src/designSystem/components/button';
import { Textarea } from 'common-front/src/designSystem/form/textarea';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { CommentInputService, ICreateCommentValues } from 'common/src/input/commentInput';
import { ValidateService } from 'common/src/services/validateService';
import { useService } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { useCommentCreateMutation, useCommentsQuery } from '../../../generated/graphqlHooks';
import { Comment } from './comment';

interface ICommentsProps {
    showEvent: boolean;
}

export const Comments = (props: ICommentsProps) => {
    const {
        params: { organizationId, eventId, userInfoId },
        translate
    } = useHeavent();
    const commentInput = useService(CommentInputService);
    const validateService = useService(ValidateService);
    const {
        data,
        loader,
        reload: reloadComments
    } = useCommentsQuery({
        organizationId,
        userInfoId
    });
    const { mutate, isLoading } = useCommentCreateMutation();
    const [renderKey, setRenderKey] = React.useState(1);
    const reload = React.useCallback(() => {
        setRenderKey(renderKey + 1);
        reloadComments();
    }, [renderKey, setRenderKey, reloadComments]);

    return (
        <Form
            key={renderKey}
            direction="column"
            hideErrors={true}
            initialValues={{
                comment: commentInput.commentInputDefault()
            }}
            render={({ handleSubmit }) => (
                <>
                    {loader ||
                        (data.organization?.userInfo.comments ?? []).map((comment, index) => (
                            <React.Fragment key={index}>
                                <Spacer height="6" />

                                <Comment
                                    comment={comment}
                                    reload={reload}
                                    showEvent={props.showEvent}
                                />
                            </React.Fragment>
                        ))}

                    <Spacer height="6" />

                    <FormErrors />

                    <Textarea label={translate('commentaire_08754')} name="comment.content" />

                    <Spacer height="4" />

                    <Button isLoading={isLoading} onClick={handleSubmit}>
                        <Box textAlign="center" width={1}>
                            {translate('ajouter_un_nouv_86295')}
                        </Box>
                    </Button>

                    <Spacer height="8" />
                </>
            )}
            validate={validateService.validateForForm(commentInput.createCommentSchema())}
            width={1}
            onSubmit={async (values: ICreateCommentValues) => {
                await mutate({
                    organizationId,
                    eventId,
                    userInfoId,
                    comment: values.comment
                });

                reload();
            }}
        />
    );
};
