import { DelegationId } from 'common/src/generated/types';
import { uniq } from 'lodash-es';
import * as React from 'react';
import { ICheckboxState } from '../designSystem/components/checkbox';

type IState<T> = {
    id: T;
    delegationId?: DelegationId;
    disabled?: boolean;
    isAccredited?: boolean;
    state: ICheckboxState;
};

export type MassActionsStates<T> = { [key: string]: IState<T> };

interface IUseMassActionsResult<T> {
    numberOfSelected: number;
    numberOfDelegationSelected:
        | {
              delegationId: DelegationId;
              numberOfSelected: number;
          }[]
        | undefined;
    numberToAccredit: number;
    numberToDeaccredit: number;
    selectAllState: ICheckboxState;
    states: MassActionsStates<T>;

    setStates(states: MassActionsStates<T>): void;
    toggleRow(key: string, newState: ICheckboxState): void;
    toggleSelectAll(state: ICheckboxState): void;
}

export function useMassActions<T>(initial: { [key: string]: IState<T> }): IUseMassActionsResult<T> {
    const [states, setStates] = React.useState(initial);
    const computed = React.useMemo(() => {
        const allEnabled = Object.values(states).filter((s) => s.disabled !== true);
        const delegationsIds = uniq(allEnabled.map((s) => s.delegationId));

        const numberToAccredit = allEnabled.filter(
            (s) => s.state === 'checked' && !s.isAccredited
        ).length;
        const numberToDeaccredit = allEnabled.filter(
            (s) => s.state === 'unchecked' && s.isAccredited
        ).length;
        const numberOfSelected = allEnabled.filter((s) => s.state === 'checked').length;
        const numberOfDelegationSelected = delegationsIds
            .filter((delegationId) => delegationId !== undefined)
            .map((delegationId) => ({
                delegationId: delegationId!,
                numberOfSelected: allEnabled.filter(
                    (s) => s.delegationId === delegationId && s.state == 'checked'
                ).length
            }));

        const uniqStates = uniq(allEnabled.map(({ state }) => state));
        const selectAllState: ICheckboxState =
            uniqStates.length === 2
                ? 'indeterminate'
                : uniqStates[0] === 'checked'
                  ? 'checked'
                  : 'unchecked';

        return {
            numberOfDelegationSelected,
            numberOfSelected,
            numberToAccredit,
            numberToDeaccredit,
            selectAllState
        };
    }, [states]);
    const toggleRow = React.useCallback(
        (key: string, newState: ICheckboxState) => {
            setStates({
                ...states,
                [key]: {
                    ...states[key],
                    state: newState
                }
            });
        },
        [states, setStates]
    );
    const toggleSelectAll = React.useCallback(
        (newState) => {
            setStates(
                Object.fromEntries(
                    Object.entries(states)
                        .filter(([_, value]) => value.disabled !== true)
                        .map(([key, value]) => [
                            key,
                            {
                                ...value,
                                state: newState
                            }
                        ])
                )
            );
        },
        [states, setStates]
    );

    return {
        numberOfSelected: computed.numberOfSelected,
        numberOfDelegationSelected: computed.numberOfDelegationSelected,
        numberToAccredit: computed.numberToAccredit,
        numberToDeaccredit: computed.numberToDeaccredit,
        selectAllState: computed.selectAllState,
        states,
        setStates,
        toggleRow,
        toggleSelectAll
    };
}
