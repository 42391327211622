import { Checkbox } from 'common-front/src/designSystem/components/checkbox';
import { Badge } from 'common/src/designSystem/components/badge';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { Row } from 'common/src/designSystem/components/table/row';
import { MassPreAssignCategoriesQuery, PositionsCategoryId } from 'common/src/generated/types';
import { addOrRemove } from 'common/src/util/array';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';

interface IMassPreAssignCategoryRowProps {
    category: MassPreAssignCategoriesQuery['event']['positionsCategories'][0];
    positionsCategoriesIds: PositionsCategoryId[];

    change(name: string, value: any): void;
}

export const MassPreAssignCategoryRow = (props: IMassPreAssignCategoryRowProps) => {
    const translate = useTranslate();

    return (
        <Row>
            <Cell css={{ paddingRight: 0 }} width={48}>
                <Checkbox
                    state={
                        props.positionsCategoriesIds.includes(props.category.id)
                            ? 'checked'
                            : 'unchecked'
                    }
                    onClick={(state) => {
                        props.change(
                            'massPreAssign.positionsCategoriesIds',
                            addOrRemove(
                                props.positionsCategoriesIds,
                                props.category.id,
                                state === 'checked'
                            )
                        );
                    }}
                />
            </Cell>

            <Cell>{props.category.name}</Cell>

            <Cell>
                <Badge
                    color={
                        props.category.assignedResources >= props.category.resources
                            ? 'success'
                            : 'warning'
                    }
                    leftIcon="user-group"
                >
                    {translate(
                        '_1_2_membres_04485',
                        props.category.assignedResources,
                        props.category.resources
                    )}
                </Badge>
            </Cell>
        </Row>
    );
};
