import { HorizontalSpacerSeparator } from 'common-front/src/designSystem/components/separator';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { CommonEnvVars } from 'common/src/envVars';
import { UserOverlayIntegrationsQuery } from 'common/src/generated/types';
import * as React from 'react';

interface IUserOverlayWeezeventV2Props {
    volunteerRegistration: UserOverlayIntegrationsQuery['event']['volunteerRegistration'];
}

export const UserOverlayWeezeventV2 = (props: IUserOverlayWeezeventV2Props) => {
    const { translate } = useHeavent();

    return (
        <Flex gap="6" width={1}>
            <Flex
                css={{
                    border: '1px solid $gray200',
                    borderRadius: '$2',
                    boxShadow: '$xs',
                    padding: '$6'
                }}
                direction="column"
                width={348}
            >
                <Box font="gray800 textMd semiBold">QR Code</Box>

                <Spacer height="3" />

                <Box height={56} width={56}>
                    <img
                        height="100%"
                        src={`https://api.${
                            CommonEnvVars.HEAVENT_EXTERNAL_DOMAIN
                        }/barcode/qr?text=${encodeURIComponent(
                            props.volunteerRegistration.ticketId
                        )}&color=000000&height=100&width=100`}
                        width="100%"
                    />
                </Box>

                <HorizontalSpacerSeparator height="4" />

                <Flex align="center" gap="2">
                    <Box color="gray700">
                        <I icon="memo-circle-info" />
                    </Box>
                    <Box font="gray800 textMd medium">{translate('informations_22954')}</Box>
                </Flex>

                <Spacer height="3" />

                <Flex direction="column" gap="6">
                    <Flex direction="column" gap="1">
                        <Box font="gray800 textSm medium">{translate('code_barres_60437')}</Box>
                        <Box color="gray500">{props.volunteerRegistration.ticketId}</Box>
                    </Flex>

                    <Flex direction="column" gap="1">
                        <Box font="gray800 textSm medium">{translate('num_ro_de_puce_50039')}</Box>
                        <Box color="gray500">{props.volunteerRegistration.shortTag || '-'}</Box>
                    </Flex>
                </Flex>
            </Flex>

            <Flex css={{ flex: '1' }} />
        </Flex>
    );
};
