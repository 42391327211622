import { Form } from 'common-front/src/components/form/form';
import { RadioText } from 'common-front/src/designSystem/components/radio';
import { RichEditor } from 'common-front/src/designSystem/form/richEditor';
import { RichSelect } from 'common-front/src/designSystem/form/richSelect';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { FormParametersBroadcastOrganizationQuery, Segment } from 'common/src/generated/types';
import {
    FormParametersBroadcastInputService,
    IUpdateFormParametersBroadcastValues
} from 'common/src/input/formParametersBroadcastInput';
import { ValidateService } from 'common/src/services/validateService';
import { isNonEmptyArray } from 'common/src/util/array';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import {
    useFormParametersBroadcastEventQuery,
    useFormParametersBroadcastOrganizationQuery,
    useFormParametersBroadcastUpdateMutation
} from '../../../../generated/graphqlHooks';
import { useFormContext } from '../../../show/formContext';

interface IFormParametersBroadcastInnerProps {
    eventSegments: Array<Pick<Segment, 'id' | 'name'>>;
    organizationSegments: Array<Pick<Segment, 'id' | 'name'>>;
    values: IUpdateFormParametersBroadcastValues;

    change(name: string, value: any): void;
    handleSubmit(): void;
}

const FormParametersBroadcastInner = (props: IFormParametersBroadcastInnerProps) => {
    const translate = useTranslate();
    const { isSaving } = useFormContext();
    const [onlyViews, setOnlyViews] = React.useState(props.values.form.segmentsIds.length > 0);
    const segments = React.useMemo(() => {
        if (isNonEmptyArray(props.eventSegments) && isNonEmptyArray(props.organizationSegments)) {
            return (
                <>
                    <optgroup label={translate('vues_de_l_v_ne_57031')}>
                        {props.eventSegments.map((segment) => (
                            <option key={segment.id} value={segment.id}>
                                {segment.name}
                            </option>
                        ))}
                    </optgroup>

                    <optgroup label={translate('vues_de_l_organ_32624')}>
                        {props.organizationSegments.map((segment) => (
                            <option key={segment.id} value={segment.id}>
                                {segment.name}
                            </option>
                        ))}
                    </optgroup>
                </>
            );
        } else if (isNonEmptyArray(props.eventSegments)) {
            return (
                <>
                    {props.eventSegments.map((segment) => (
                        <option key={segment.id} value={segment.id}>
                            {segment.name}
                        </option>
                    ))}
                </>
            );
        } else if (isNonEmptyArray(props.organizationSegments)) {
            return (
                <>
                    {props.organizationSegments.map((segment) => (
                        <option key={segment.id} value={segment.id}>
                            {segment.name}
                        </option>
                    ))}
                </>
            );
        } else {
            return <></>;
        }
    }, [props.eventSegments, props.organizationSegments]);

    React.useEffect(() => {
        if (isSaving) {
            props.handleSubmit();
        }
    }, [isSaving]);

    return (
        <>
            <Box color="gray800" fontSize="textMd" fontWeight="semiBold">
                {translate('diffusion_du_fo_47764')}
            </Box>

            <Spacer height="1" />

            <Box color="gray500">{translate('personnalisez_l_57514')}</Box>

            <Spacer height="4" />

            <Box color="gray800" fontWeight="medium">
                {translate('_qui_s_adresse_82507')}
            </Box>

            <Spacer height="3" />

            <RadioText
                state={onlyViews ? 'unchecked' : 'checked'}
                onClick={() => {
                    setOnlyViews(false);
                    props.change('form.segmentsIds', []);
                }}
            >
                {translate('_tout_le_monde_35921')}
            </RadioText>

            <Spacer height="3" />

            <RadioText
                state={onlyViews ? 'checked' : 'unchecked'}
                onClick={() => {
                    setOnlyViews(true);
                }}
            >
                {translate('_certaines_vue_55512')}
            </RadioText>

            {onlyViews && (
                <>
                    <Spacer height="2" />

                    <Flex>
                        <Spacer width="6" />

                        <RichSelect isSearchVisible={true} multiple={true} name="form.segmentsIds">
                            {segments}
                        </RichSelect>
                    </Flex>

                    <Spacer height="6" />

                    <RichEditor
                        label={translate('message_affich_57692')}
                        name="form.noAccessMessage"
                    />
                </>
            )}
        </>
    );
};

interface IFormParametersBroadcastProps {
    form: FormParametersBroadcastOrganizationQuery['organization']['form'];
    eventSegments: Array<Pick<Segment, 'id' | 'name'>>;
    organizationSegments: Array<Pick<Segment, 'id' | 'name'>>;
}

const FormParametersBroadcast = (props: IFormParametersBroadcastProps) => {
    const { eventId, formId, organizationId, setIsSaving } = useFormContext();
    const validateService = useService(ValidateService);
    const formParametersBroadcastInput = useService(FormParametersBroadcastInputService);
    const { mutate } = useFormParametersBroadcastUpdateMutation();

    return (
        <Form
            direction="column"
            initialValues={{
                form: formParametersBroadcastInput.formParametersBroadcastInputDefault(props.form)
            }}
            render={({ form, handleSubmit, values }) => (
                <FormParametersBroadcastInner
                    change={form.change}
                    eventSegments={props.eventSegments}
                    handleSubmit={handleSubmit}
                    organizationSegments={props.organizationSegments}
                    values={values}
                />
            )}
            validate={validateService.validateForForm(
                formParametersBroadcastInput.updateFormParametersBroadcastSchema()
            )}
            width={1}
            onSubmit={async (values: IUpdateFormParametersBroadcastValues) => {
                await mutate({
                    organizationId,
                    eventId,
                    formId,
                    form: values.form
                });

                setIsSaving(false);
            }}
        />
    );
};

export const FormParametersBroadcastOrganization = () => {
    const { organizationId, formId } = useFormContext();
    const { data, loader } = useFormParametersBroadcastOrganizationQuery({
        organizationId,
        formId
    });

    return (
        loader || (
            <FormParametersBroadcast
                eventSegments={[]}
                form={data.organization.form}
                organizationSegments={data.organization.segments}
            />
        )
    );
};

export const FormParametersBroadcastEvent = () => {
    const { eventId, formId, organizationId } = useFormContext();
    const { data, loader } = useFormParametersBroadcastEventQuery({
        organizationId,
        eventId: eventId!,
        formId
    });

    return (
        loader || (
            <FormParametersBroadcast
                eventSegments={data.event.segments}
                form={data.organization.form}
                organizationSegments={data.organization.segments}
            />
        )
    );
};
