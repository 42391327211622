import { useDebounce } from 'common-front/src/hooks/useDebounce';
import { Box } from 'common/src/designSystem/components/box';
import { PositionQuery } from 'common/src/generated/types';
import { useParams } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { useEventContext } from '../../events/show/eventContext';
import { usePositionUpdateColorMutation } from '../../generated/graphqlHooks';

interface IPositionColorProps {
    position: Pick<
        PositionQuery['organization']['event']['position'],
        'id' | 'positionCategoryId' | 'color'
    >;
}

export const PositionColor = (props: IPositionColorProps) => {
    const { eventId, positionId } = useParams();
    const { mutate } = usePositionUpdateColorMutation();
    const inputRef = React.useRef<HTMLInputElement | null>(null);
    const [colorValue, setColorValue] = React.useState(props.position.color);
    const setColorValueDebounced = useDebounce((newColorValue: string) => {
        mutate({
            eventId,
            positionId,
            color: newColorValue
        });
    });
    const { isPositionAdmin } = useEventContext();
    const showInput = isPositionAdmin(props.position.positionCategoryId, props.position.id);

    return (
        <Box
            color="gray800"
            css={{
                background: colorValue,
                borderRadius: '20px',
                cursor: 'pointer',
                height: '20px',
                width: '20px',
                '& input': {
                    background: 'transparent',
                    border: 'none',
                    borderRadius: '20px',
                    cursor: 'pointer',
                    height: '20px',
                    width: '20px',
                    '&::-webkit-color-swatch-wrapper': {
                        padding: 0
                    },
                    '&::-webkit-color-swatch': {
                        borderRadius: '20px',
                        border: 'none'
                    },
                    '&::-moz-color-swatch': {
                        border: 'none'
                    }
                }
            }}
            onClick={() => {
                if (showInput) {
                    inputRef.current?.click();
                }
            }}
        >
            {showInput && (
                <input
                    ref={inputRef}
                    type="color"
                    value={colorValue}
                    onChange={(e) => {
                        setColorValue(e.target.value);
                        setColorValueDebounced(e.target.value);
                    }}
                />
            )}
        </Box>
    );
};
