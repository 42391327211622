import { Blank } from 'common-front/src/components/blank/blank';
import { NoFeature } from 'common-front/src/components/noFeature';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Svgs } from 'common-front/src/util/assets';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { HeaderRow } from 'common/src/designSystem/components/table/headerRow';
import { Row } from 'common/src/designSystem/components/table/row';
import { Table } from 'common/src/designSystem/components/table/table';
import { Feature } from 'common/src/generated/types';
import { DurationService } from 'common/src/services/durationService';
import { IntervalService } from 'common/src/services/intervalService';
import { isNonEmptyArray } from 'common/src/util/array';
import { useService } from 'common/src/util/dependencies/dependencies';
import { LocaleFormats } from 'common/src/util/luxon';
import { groupBy, reverse, sortBy, sum } from 'lodash-es';
import * as React from 'react';
import { useUserOverlayEventsQuery } from '../../../generated/graphqlHooks';
import { useOrganizationContext } from '../../../organization/organizationContext';

export const UserOverlayEvents = () => {
    const {
        params: { organizationId, eventId, userInfoId },
        translate
    } = useHeavent();
    const { hasFeature } = useOrganizationContext();
    const { data, loader } = useUserOverlayEventsQuery({
        organizationId,
        userInfoId
    });
    const intervalService = useService(IntervalService);
    const durationService = useService(DurationService);
    const volunteersRegistrations = React.useMemo(
        () => data.organization?.userInfo.volunteersRegistrations ?? [],
        [data.organization]
    );
    const numberOfEvents = React.useMemo(
        () => volunteersRegistrations.map((vr) => vr.event).length,
        [volunteersRegistrations]
    );
    const numberOfPositions = React.useMemo(
        () =>
            volunteersRegistrations.flatMap((vr) =>
                vr.positionsSlotsUsersInfos.map((psui) => psui.position)
            ).length,
        [volunteersRegistrations]
    );
    const volunteerMinutes = React.useMemo(
        () => sum(volunteersRegistrations.map((vr) => vr.volunteerMinutes)),
        [volunteersRegistrations]
    );
    const events = React.useMemo(
        () =>
            reverse(
                sortBy(
                    volunteersRegistrations
                        .map((vr) => vr.event)
                        .filter((event) => {
                            if (eventId) {
                                return event.id !== eventId;
                            } else {
                                return true;
                            }
                        }),
                    (e) => e.startAt.toMillis()
                )
            ),
        [volunteersRegistrations]
    );
    const eventIdToPositions = React.useMemo(
        () =>
            groupBy(
                volunteersRegistrations.flatMap((vr) =>
                    vr.positionsSlotsUsersInfos.map((psui) => ({
                        eventId: vr.event.id,
                        position: psui.position,
                        range: psui.positionSlot.range
                    }))
                ),
                (p) => p.eventId
            ),
        [volunteersRegistrations]
    );

    return (
        loader ||
        (hasFeature(Feature.Community) ? (
            <>
                <Spacer height="6" />

                {!eventId && isNonEmptyArray(events) && (
                    <>
                        <Flex
                            css={{
                                border: '1px solid $gray200',
                                borderRadius: '$2',
                                boxShadow: '$xs',
                                padding: '$6 $9'
                            }}
                            justify="between"
                        >
                            <Flex direction="column">
                                <Box color="gray800" fontSize="displayMd" fontWeight="semiBold">
                                    {numberOfEvents}
                                </Box>

                                <Box color="gray500">
                                    {translate('_v_nement_r_ali_56793', numberOfEvents)}
                                </Box>
                            </Flex>

                            <Flex direction="column">
                                <Box color="gray800" fontSize="displayMd" fontWeight="semiBold">
                                    {numberOfPositions}
                                </Box>

                                <Box color="gray500">
                                    {translate('mission_r_alis_82876', numberOfPositions)}
                                </Box>
                            </Flex>

                            <Flex direction="column">
                                <Box color="gray800" fontSize="displayMd" fontWeight="semiBold">
                                    {durationService.minutesToHoursMinutesString(volunteerMinutes)}
                                </Box>

                                <Box color="gray500">{translate('temps_de_b_n_vo_34079')}</Box>
                            </Flex>
                        </Flex>

                        <Spacer height="8" />
                    </>
                )}

                {isNonEmptyArray(events) ? (
                    events.map((event) => (
                        <React.Fragment key={event.id}>
                            <Flex direction="column">
                                <Box
                                    color="gray900"
                                    css={{
                                        border: '1px solid $gray200',
                                        borderBottom: 'none',
                                        borderTopLeftRadius: '$2',
                                        borderTopRightRadius: '$2',
                                        padding: '$6'
                                    }}
                                    fontSize="textLg"
                                    fontWeight="medium"
                                >
                                    {event.name}
                                </Box>

                                <Table>
                                    <HeaderRow css={{ borderRadius: '0 !important' }}>
                                        <HeaderCell>
                                            {translate('nom_de_la_missi_64605')}
                                        </HeaderCell>
                                        <HeaderCell>{translate('cr_neau_11117')}</HeaderCell>
                                    </HeaderRow>

                                    {(eventIdToPositions[event.id] ?? []).map(
                                        ({ position, range }, index) => (
                                            <Row key={index}>
                                                <Cell>{position.name}</Cell>
                                                <Cell>
                                                    {intervalService.toDisplayString(range)}
                                                </Cell>
                                            </Row>
                                        )
                                    )}
                                </Table>
                            </Flex>

                            <Spacer height="8" />
                        </React.Fragment>
                    ))
                ) : (
                    <>
                        <Flex
                            css={{
                                border: '1px solid $gray200',
                                borderRadius: '$2',
                                padding: '$9'
                            }}
                            height={300}
                        >
                            <Blank
                                imageSrc={Svgs.Done2}
                                title={
                                    eventId
                                        ? `${data.organization.userInfo.name} n'a pas réalisé de mission sur des événements antérieurs`
                                        : `${data.organization.userInfo.name} n'a pas encore participé à un événement`
                                }
                            />
                        </Flex>

                        <Spacer height="8" />
                    </>
                )}

                {!eventId && (
                    <>
                        <Box font="gray800 textMd semiBold">{translate('inscription_48144')}</Box>

                        <Spacer height="3" />

                        <Flex
                            css={{
                                border: '1px solid $gray200',
                                borderRadius: '$2',
                                boxShadow: '$xs',
                                padding: '$4'
                            }}
                            gap="3"
                        >
                            <Flex
                                align="center"
                                css={{
                                    background: '$purple100',
                                    borderRadius: '12px',
                                    color: '$purple700'
                                }}
                                height={40}
                                justify="center"
                                width={40}
                            >
                                <I icon="calendar" />
                            </Flex>

                            <Flex direction="column">
                                <Box font="gray800 textSm semiBold">
                                    {translate('date_d_inscript_31369')}
                                </Box>
                                <Box color="gray500">
                                    {translate(
                                        'membre_depuis_69786',
                                        data.organization.userInfo.insertedAt.toLocaleString(
                                            LocaleFormats.DateOnly.MonthLongYear
                                        ),
                                        data.organization.userInfo.insertedAt.toRelative()
                                    )}
                                </Box>
                            </Flex>
                        </Flex>

                        <Spacer height="8" />
                    </>
                )}
            </>
        ) : (
            <>
                <Spacer height="6" />

                <Flex
                    align="center"
                    css={{
                        border: '1px solid $gray200',
                        borderRadius: '$2',
                        padding: '$9'
                    }}
                    height={300}
                    justify="center"
                >
                    <NoFeature icon="users" />
                </Flex>

                <Spacer height="8" />
            </>
        ))
    );
};
