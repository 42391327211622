import { sortBy } from 'lodash-es';
import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { Span } from '../../../designSystem/components/span';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { DateTimeService } from '../../../services/dateTimeService';
import { useService } from '../../../util/dependencies/dependencies';

interface IP2NMontagePositionProps {
    psuis: DocumentUserCustomBadgeFragment['volunteerRegistration']['positionsSlotsUsersInfos'];
}

export const P2NMontagePosition = (props: IP2NMontagePositionProps) => {
    const dateTimeService = useService(DateTimeService);

    return (
        <Flex direction="column" gap="2">
            <Box css={{ color: 'black', fontWeight: '700', textTransform: 'uppercase' }}>
                {props.psuis[0].position.name}
            </Box>

            {sortBy(props.psuis, (psui) => psui.positionSlot.range.start!.toMillis()).map(
                (psui) => {
                    const slot = psui.positionSlot;

                    return (
                        <Box key={psui.id} color="gray800" css={{ fontSize: '16px' }}>
                            <Span css={{ fontWeight: '600', textTransform: 'capitalize' }}>
                                {dateTimeService.toLocaleString(slot.range.start!, {
                                    month: 'long',
                                    day: 'numeric',
                                    weekday: 'long'
                                })}
                            </Span>{' '}
                            | De {slot.range.start!.toFormat('H:mm')} à{' '}
                            {slot.range.end!.toFormat('H:mm')}
                        </Box>
                    );
                }
            )}
        </Flex>
    );
};
