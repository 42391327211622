import { useHistory } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { Loader } from '../../../components/loader/loader';
import { RightPanel } from './rightPanel';
import { RightPanelBody } from './rightPanelBody';

interface IRightPanelLoaderProps {
    path: string;
}

export const RightPanelLoader = (props: IRightPanelLoaderProps) => {
    const history = useHistory();

    return (
        <RightPanel
            size="md"
            onClose={() => {
                history.push(props.path);
            }}
        >
            <RightPanelBody>
                <Loader />
            </RightPanelBody>
        </RightPanel>
    );
};
