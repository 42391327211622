import { Button } from 'common-front/src/designSystem/components/button';
import { TableFilters } from 'common-front/src/designSystem/components/tableFilters';
import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { useStateDebounce } from 'common-front/src/hooks/useStateDebounce';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { RowSkeleton } from 'common/src/designSystem/components/table/rowSkeleton';
import { EventId } from 'common/src/generated/types';
import { IMassPreAssignValues } from 'common/src/input/massPreAssignInput';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { isNonEmptyString } from 'common/src/util/string';
import { noop } from 'lodash-es';
import * as React from 'react';
import { useMassPreAssignCategoriesQuery } from '../../generated/graphqlHooks';
import { MassPreAssignCategoryRow } from './massPreAssignCategoryRow';

interface IMassPreAssignStep2CategoriesProps {
    eventId: EventId;
    numberOfSelected: number;
    values: IMassPreAssignValues;

    change(name: string, value: any): void;
    handleSubmit(): void;
}

export const MassPreAssignStep2Categories = (props: IMassPreAssignStep2CategoriesProps) => {
    const translate = useTranslate();
    const [name, nameDebounced, setName] = useStateDebounce('');
    const { data, isLoading } = useMassPreAssignCategoriesQuery({
        eventId: props.eventId
    });
    const categories = React.useMemo(() => {
        if (isNonEmptyString(nameDebounced)) {
            return (data.event?.positionsCategories ?? []).filter((pc) =>
                pc.name.trim().toLowerCase().includes(nameDebounced.trim().toLowerCase())
            );
        } else {
            return data.event?.positionsCategories ?? [];
        }
    }, [data.event?.positionsCategories, nameDebounced]);

    return (
        <>
            <TableFilters
                filters={
                    <>
                        <Box width={320}>
                            <TextInput
                                icon="magnifying-glass"
                                placeholder={translate('rechercher_une_66722')}
                                value={name}
                                onChange={setName}
                            />
                        </Box>
                    </>
                }
                headerCells={
                    <>
                        <HeaderCell css={{ paddingRight: 0 }} width={48} />
                        <HeaderCell>{translate('nom_de_la_cat_g_03860')}</HeaderCell>
                        <HeaderCell />
                    </>
                }
                numberOfPages={1}
                offset={0}
                rows={
                    isLoading ? (
                        <>
                            <RowSkeleton bx={true} />
                            <RowSkeleton bx={true} />
                            <RowSkeleton bx={true} />
                        </>
                    ) : (
                        categories.map((category) => (
                            <MassPreAssignCategoryRow
                                key={category.id}
                                category={category}
                                change={props.change}
                                positionsCategoriesIds={
                                    props.values.massPreAssign.positionsCategoriesIds
                                }
                            />
                        ))
                    )
                }
                setOffset={noop}
                title={
                    <Flex direction="column" gap="1">
                        <Box>{translate('s_lectionner_le_58635')}</Box>
                        <Box font="gray500 textSm regular">
                            {translate('les_membres_s_l_66443')}
                        </Box>
                    </Flex>
                }
                totalCount={categories.length ?? 0}
            />

            <Spacer height="7" />

            <Flex>
                <Button onClick={props.handleSubmit}>
                    {translate('pr_affecter_le_81533', props.numberOfSelected)}
                </Button>
            </Flex>
        </>
    );
};
