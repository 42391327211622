import { Button } from 'common-front/src/designSystem/components/button';
import { IconBackground } from 'common-front/src/designSystem/components/iconBackground';
import { Modal } from 'common-front/src/designSystem/components/modal';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    CustomFieldId,
    EventId,
    MassAssignStrategy,
    OrganizationId,
    PositionId,
    SegmentId
} from 'common/src/generated/types';
import {
    IMassConditionsValues,
    MassConditionsInputService
} from 'common/src/input/massConditionsInput';
import { ValidateService } from 'common/src/services/validateService';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { Emptyable } from 'common/src/util/emptyable';
import { isEqual } from 'lodash-es';
import * as React from 'react';
import { Form } from 'react-final-form';
import { usePositionsMassConditionsMutation } from '../../generated/graphqlHooks';
import { MassConditionsStep1 } from './massConditionsStep1';
import { MassConditionsStep2 } from './massConditionsStep2';

interface IMassConditionsModalProps {
    eventId: EventId;
    organizationId: OrganizationId;
    positionsIds: PositionId[];
    segmentId: Emptyable<SegmentId>;

    onClose(): void;
    onSuccess(): void;
}

export const MassConditionsModal = (props: IMassConditionsModalProps) => {
    const translate = useTranslate();
    const massConditionsInput = useService(MassConditionsInputService);
    const validateService = useService(ValidateService);
    const { mutate } = usePositionsMassConditionsMutation();
    const [step, setStep] = React.useState(0);

    return (
        <Modal size="md" onClose={props.onClose}>
            <Form
                initialValues={{
                    massConditions: {
                        customFieldId: -1 as CustomFieldId,
                        positionsIds: props.positionsIds,
                        segmentId: props.segmentId,
                        strategy: MassAssignStrategy.Add,
                        value: ''
                    }
                }}
                initialValuesEqual={isEqual}
                render={({ form, handleSubmit, values }) => (
                    <>
                        <Flex justify="center">
                            <IconBackground color="primary" icon="lock" />
                        </Flex>

                        <Spacer height="4" />

                        <Box
                            color="gray900"
                            fontSize="textLg"
                            fontWeight="medium"
                            textAlign="center"
                        >
                            {translate('_diter_les_cond_95618')}
                        </Box>

                        <Spacer height="7" />

                        {step === 0 ? (
                            <MassConditionsStep1 />
                        ) : (
                            <MassConditionsStep2
                                change={form.change}
                                organizationId={props.organizationId}
                                values={values}
                            />
                        )}

                        <Spacer height="7" />

                        <Flex gap="3">
                            {step !== 0 && (
                                <Button
                                    color="white"
                                    leftIcon="arrow-left"
                                    onClick={() => {
                                        setStep(Math.max(0, step - 1));
                                    }}
                                >
                                    {translate('retour_92202')}
                                </Button>
                            )}

                            <Box css={{ flex: '1' }} />

                            {step === 0 ? (
                                <Button rightIcon="arrow-right" onClick={handleSubmit}>
                                    {translate('suivant_1_2_16815')}
                                </Button>
                            ) : (
                                <Button onClick={handleSubmit}>
                                    {translate('mettre_jour_l_67209')}
                                </Button>
                            )}
                        </Flex>
                    </>
                )}
                validate={(values: IMassConditionsValues) => {
                    if (step === 0) {
                        return validateService.validateForForm(
                            massConditionsInput.massConditionsStep1Schema()
                        )(values);
                    } else {
                        return validateService.validateForForm(
                            massConditionsInput.massConditionsStep2Schema()
                        )(values);
                    }
                }}
                onSubmit={async (values: IMassConditionsValues) => {
                    if (step === 1) {
                        await mutate({
                            eventId: props.eventId,
                            massConditions: values.massConditions
                        });

                        props.onSuccess();
                        props.onClose();
                    } else {
                        setStep(step + 1);
                    }
                }}
            />
        </Modal>
    );
};
