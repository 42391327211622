import { chunk, maxBy, minBy } from 'lodash-es';
import { DateTime, Interval } from 'luxon';
import * as React from 'react';
import { Flex } from '../../designSystem/components/flex';
import { DocumentUserPlanningListFragment, UserPlanningListOptions } from '../../generated/types';
import { UserPlanningHeader } from '../userPlanning/userPlanningHeader';
import { groupAssignmentsByPositionDay } from './groupAssignmentsByPositionDay';
import { UserPlanningListTable } from './userPlanningListTable';

const NUMBER_OF_ASSIGNMENTS_PER_PAGE = 20;

interface IUserPlanningListProps {
    date?: DateTime;
    endDate?: DateTime;
    event: DocumentUserPlanningListFragment;
    options: UserPlanningListOptions;
}

export const UserPlanningList = (props: IUserPlanningListProps) => {
    const assignments = React.useMemo(() => {
        const psuis = props.event.volunteerRegistration.positionsSlotsUsersInfos.filter((psui) => {
            if (props.date?.isValid && props.endDate?.isValid) {
                const interval = Interval.fromDateTimes(
                    props.date.startOf('day'),
                    props.endDate.endOf('day')
                );

                return psui.positionSlot.range.overlaps(interval);
            } else if (props.date?.isValid) {
                return (
                    psui.positionSlot.range
                        .start!.startOf('day')
                        .equals(props.date.startOf('day')) ||
                    psui.positionSlot.range.end!.startOf('day').equals(props.date.startOf('day'))
                );
            } else {
                return true;
            }
        });

        return groupAssignmentsByPositionDay(psuis);
    }, [props.event.volunteerRegistration, props.date]);

    return (
        <>
            {chunk(assignments, NUMBER_OF_ASSIGNMENTS_PER_PAGE).map((assignmentsChunk, index) => {
                const ranges = assignmentsChunk.flatMap((a) => a.ranges);
                const startDate = minBy(
                    ranges.map((r) => r.start!),
                    (d) => d.toMillis()
                )!;
                const endDate = maxBy(
                    ranges.map((r) => r.end!),
                    (d) => d.toMillis()
                )!;
                const interval = Interval.fromDateTimes(startDate, endDate);

                return (
                    <Flex
                        key={index}
                        css={{
                            background: 'white',
                            pageBreakBefore: 'always'
                        }}
                        direction="column"
                        width={1}
                    >
                        <UserPlanningHeader
                            interval={interval}
                            nameOrEmail={props.event.volunteerRegistration.userInfo.nameOrEmail}
                            numberOfAssignments={assignmentsChunk.flatMap((a) => a.ranges).length}
                        />

                        <UserPlanningListTable
                            assignments={assignmentsChunk}
                            showHours={props.options.showHours}
                        />
                    </Flex>
                );
            })}
        </>
    );
};
