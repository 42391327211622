import { Select } from 'common-front/src/designSystem/components/select/select';
import { TableFilters } from 'common-front/src/designSystem/components/tableFilters';
import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { usePaginationInfos } from 'common-front/src/hooks/usePaginationInfos';
import { useStateDebounce } from 'common-front/src/hooks/useStateDebounce';
import { Box } from 'common/src/designSystem/components/box';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { CellControls } from 'common/src/designSystem/components/table/cellControls';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { Row } from 'common/src/designSystem/components/table/row';
import { RowSkeleton } from 'common/src/designSystem/components/table/rowSkeleton';
import { EventId } from 'common/src/generated/types';
import { isNonEmptyString } from 'common/src/util/string';
import { sortBy } from 'lodash-es';
import * as React from 'react';
import { useOrganizationCategoriesAccreditationsQuery } from '../../generated/graphqlHooks';
import { OrganizationCategoriesAccreditationRowDropdown } from './organizationCategoriesAccreditationRowDropdown';

export const OrganizationCategoriesAccreditations = () => {
    const {
        translate,
        params: { organizationId }
    } = useHeavent();
    const [name, nameDebounced, setName] = useStateDebounce('');
    const [eventId, setEventId] = React.useState<EventId>(-1 as EventId);
    const [offset, setOffset] = React.useState(0);
    const { data, isLoading, reload } = useOrganizationCategoriesAccreditationsQuery({
        organizationId,
        name: isNonEmptyString(nameDebounced) ? nameDebounced : undefined,
        eventId: eventId !== -1 ? eventId : undefined,
        offset
    });
    const { numberOfPages, totalCount } = usePaginationInfos(
        data.organization?.accreditationsCategories
    );
    const events = React.useMemo(
        () => sortBy(data.organization?.events.nodes ?? [], (e) => e.name),
        [data.organization]
    );
    const eventIdToEventName = React.useMemo(
        () => Object.fromEntries(events.map((e) => [e.id, e.name])),
        [events]
    );

    return (
        <>
            <Spacer height="7" />

            <TableFilters
                filters={
                    <>
                        <Box width={200}>
                            <Select
                                shouldParseAsInt={true}
                                value={eventId}
                                onChange={(newEventId: EventId) => {
                                    setEventId(newEventId);
                                }}
                            >
                                <option value="-1">{translate('tous_les_v_nem_16033')}</option>

                                {events.map((event) => (
                                    <option key={event.id} value={event.id}>
                                        {event.name}
                                    </option>
                                ))}
                            </Select>
                        </Box>

                        <Box width={320}>
                            <TextInput
                                icon="magnifying-glass"
                                placeholder={translate('rechercher_une_66722')}
                                value={name}
                                onChange={setName}
                            />
                        </Box>
                    </>
                }
                headerCells={
                    <>
                        <HeaderCell>{translate('nom_de_la_cat_g_03860')}</HeaderCell>
                        <HeaderCell>{translate('_v_nement_22345')}</HeaderCell>
                        <HeaderCell>{translate('nombre_d_utilis_58389')}</HeaderCell>
                        <HeaderCell width={100} />
                    </>
                }
                numberOfPages={numberOfPages}
                offset={offset}
                rows={
                    isLoading ? (
                        <>
                            <RowSkeleton bx={true} />
                            <RowSkeleton bx={true} />
                            <RowSkeleton bx={true} />
                        </>
                    ) : (
                        data.organization.accreditationsCategories.nodes.map((category) => (
                            <Row key={category.id}>
                                <Cell>{category.name}</Cell>
                                <Cell>{eventIdToEventName[category.eventId] ?? ''}</Cell>
                                <Cell>{category.numberOfAccreditations}</Cell>
                                <CellControls justify="center">
                                    <OrganizationCategoriesAccreditationRowDropdown
                                        accreditationCategory={category}
                                        reload={reload}
                                    />
                                </CellControls>
                            </Row>
                        ))
                    )
                }
                setOffset={setOffset}
                title={translate('liste_des_cat_g_39450')}
                totalCount={totalCount}
            />
        </>
    );
};
