import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { SegmentsFoldersSegmentsFragment } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';
import { createPortal } from 'react-dom';
import { DeleteModal } from '../../designSystem/components/deleteModal';
import { Dropdown } from '../../designSystem/components/dropdown/dropdown';
import { ItemIcon } from '../../designSystem/components/dropdown/item';
import { Menu } from '../../designSystem/components/dropdown/menu';
import { Trigger } from '../../designSystem/components/dropdown/trigger';
import {
    useSegmentFolderDeleteMutation,
    useSegmentFolderMoveDownMutation,
    useSegmentFolderMoveUpMutation
} from '../../generated/graphqlHooks';
import { localDatabase } from '../../localDatabase';
import { useSegmentsContext } from '../segmentsContext';
import { CreateFolderModal } from './createFolderModal';
import { SegmentsFoldersReorderModal } from './reorderModal/segmentsFoldersReorderModal';
import { Segment } from './segment';

interface ISegmentsFolderProps {
    canDeleteSegment: boolean;
    canDeleteSegmentFolder: boolean;
    isFirst: boolean;
    isLast: boolean;
    search: string;
    segmentFolder: SegmentsFoldersSegmentsFragment;
    segmentsFolders: SegmentsFoldersSegmentsFragment[];

    onSegmentDelete(): Promise<any>;
}

export const SegmentsFolder = (props: ISegmentsFolderProps) => {
    const translate = useTranslate();
    const { eventId, isAdmin, organizationId, reload } = useSegmentsContext();
    const { mutate: segmentFolderDelete } = useSegmentFolderDeleteMutation();
    const { mutate: segmentFolderMoveDown } = useSegmentFolderMoveDownMutation();
    const { mutate: segmentFolderMoveUp } = useSegmentFolderMoveUpMutation();
    const [isFolderRenameOpen, setIsFolderRenameOpen] = React.useState(false);
    const [isFolderDeleteOpen, setIsFolderDeleteOpen] = React.useState(false);
    const [areSegmentsOpen, setAreSegmentsOpen] = React.useState(false);
    const [isReorderModalOpen, setIsReorderModalOpen] = React.useState(false);
    const toggleSegmentsOpen = React.useCallback(async () => {
        const open = !areSegmentsOpen;

        setAreSegmentsOpen(open);

        await localDatabase.segmentsFolders.put({ id: props.segmentFolder.id, isOpen: open });
    }, [areSegmentsOpen, setAreSegmentsOpen]);
    const segments = React.useMemo(() => {
        if (isNonEmptyString(props.search)) {
            return props.segmentFolder.segments.filter((segment) =>
                segment.name.toLowerCase().includes(props.search.toLowerCase())
            );
        } else {
            return props.segmentFolder.segments;
        }
    }, [props.segmentFolder.segments, props.search]);

    React.useEffect(() => {
        (async () => {
            setAreSegmentsOpen(
                (await localDatabase.segmentsFolders.get(props.segmentFolder.id))?.isOpen ?? true
            );
        })();
    }, []);

    return (
        <>
            <Flex direction="column">
                <Flex
                    align="center"
                    css={{
                        cursor: 'pointer',
                        userSelect: 'none',
                        '& .ellipsis': {
                            display: 'none'
                        },
                        '&:hover .ellipsis': {
                            display: 'block'
                        }
                    }}
                    gap="2"
                    onClick={toggleSegmentsOpen}
                >
                    <Box color="gray500" fontSize="textXs">
                        <I icon={areSegmentsOpen ? 'chevron-down' : 'chevron-right'} />
                    </Box>

                    <Box css={{ flex: '1' }} font="gray900 textXs semiBold">
                        {props.segmentFolder.name}
                    </Box>

                    {isAdmin && (
                        <Box
                            className="ellipsis"
                            onClick={(e) => {
                                e.nativeEvent.stopImmediatePropagation();
                                e.stopPropagation();
                            }}
                        >
                            <Dropdown>
                                <Trigger>
                                    <Flex
                                        align="center"
                                        css={{ color: '$gray500', px: '$2' }}
                                        justify="end"
                                        width={24}
                                    >
                                        <I icon="ellipsis-vertical" />
                                    </Flex>
                                </Trigger>

                                <Menu offset={0} placement="bottom-end">
                                    <ItemIcon
                                        icon="pen"
                                        onClick={() => {
                                            setIsFolderRenameOpen(true);
                                        }}
                                    >
                                        {translate('renommer_42135')}
                                    </ItemIcon>

                                    <ItemIcon
                                        icon="gear"
                                        onClick={() => {
                                            setIsReorderModalOpen(true);
                                        }}
                                    >
                                        {translate('r_ordonner_41823')}
                                    </ItemIcon>

                                    {props.canDeleteSegmentFolder && (
                                        <ItemIcon
                                            color={props.isLast ? 'disabled' : 'default'}
                                            icon="arrow-down"
                                            onClick={async () => {
                                                await segmentFolderMoveDown({
                                                    organizationId,
                                                    eventId,
                                                    segmentFolderId: props.segmentFolder.id
                                                });

                                                reload();
                                            }}
                                        >
                                            {translate('d_placer_vers_l_52432')}
                                        </ItemIcon>
                                    )}

                                    {props.canDeleteSegmentFolder && (
                                        <ItemIcon
                                            color={props.isFirst ? 'disabled' : 'default'}
                                            icon="arrow-up"
                                            onClick={async () => {
                                                await segmentFolderMoveUp({
                                                    organizationId,
                                                    eventId,
                                                    segmentFolderId: props.segmentFolder.id
                                                });

                                                reload();
                                            }}
                                        >
                                            {translate('d_placer_vers_l_49863')}
                                        </ItemIcon>
                                    )}

                                    {props.canDeleteSegmentFolder && (
                                        <ItemIcon
                                            color="red"
                                            icon="trash-can"
                                            onClick={() => {
                                                setIsFolderDeleteOpen(true);
                                            }}
                                        >
                                            {translate('supprimer_43083')}
                                        </ItemIcon>
                                    )}
                                </Menu>
                            </Dropdown>
                        </Box>
                    )}
                </Flex>

                {segments.length > 0 && areSegmentsOpen && (
                    <>
                        <Spacer height="3" />

                        <Flex direction="column" gap="1">
                            {segments.map((segment, index) => (
                                <Segment
                                    key={segment.id}
                                    canDelete={props.canDeleteSegment}
                                    canMoveFolder={props.canDeleteSegmentFolder}
                                    canMoveUpDown={props.segmentFolder.segments.length > 1}
                                    isFirst={index === 0}
                                    isLast={index === props.segmentFolder.segments.length - 1}
                                    segment={segment}
                                    segmentsFolders={props.segmentsFolders}
                                    onDelete={props.onSegmentDelete}
                                />
                            ))}
                        </Flex>
                    </>
                )}

                <Spacer height="5" />
            </Flex>

            {isFolderRenameOpen && (
                <CreateFolderModal
                    isEdit={true}
                    segmentFolder={props.segmentFolder}
                    onClose={() => {
                        setIsFolderRenameOpen(false);
                    }}
                />
            )}

            {isFolderDeleteOpen && (
                <DeleteModal
                    buttonText={translate('supprimer_43083')}
                    subtext={translate('_tes_vous_s_r_d_13885')}
                    text={translate('suppression_de_73669', props.segmentFolder.name)}
                    onClose={() => {
                        setIsFolderDeleteOpen(false);
                    }}
                    onDelete={async () => {
                        await segmentFolderDelete({
                            organizationId,
                            eventId,
                            segmentFolderId: props.segmentFolder.id
                        });

                        reload();
                    }}
                />
            )}

            {isReorderModalOpen &&
                createPortal(
                    <Box
                        css={{
                            background: 'white',
                            left: '0',
                            position: 'absolute',
                            top: '0',
                            zIndex: '200'
                        }}
                        height={1}
                        width={1}
                    >
                        <SegmentsFoldersReorderModal
                            eventId={eventId}
                            organizationId={organizationId}
                            segmentsFolders={props.segmentsFolders}
                            onClose={() => {
                                setIsReorderModalOpen(false);
                            }}
                            onSuccess={reload}
                        />
                    </Box>,
                    document.body
                )}
        </>
    );
};
