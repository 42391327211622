import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { AvatarNameEmail } from 'common/src/components/avatarNameEmail';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { EventId, OrganizationId, UserInfoTeamMemberFragment } from 'common/src/generated/types';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';

interface IUserProfilePrivateFieldsTeamProps {
    eventId: EventId;
    organizationId: OrganizationId;
    teamMembers: UserInfoTeamMemberFragment[];
}

export const UserProfilePrivateFieldsTeam = (props: IUserProfilePrivateFieldsTeamProps) => {
    const { history, translate } = useHeavent();

    return (
        <>
            <Flex align="center" gap="2">
                <Box color="gray800">
                    <I icon="user-group" />
                </Box>

                <Box font="gray800 textMd semiBold">{translate('_quipe_76857')}</Box>
            </Flex>

            <Spacer height="3" />

            <Flex
                css={{
                    border: '1px solid $gray200',
                    borderRadius: '$1',
                    boxShadow: '$xs',
                    padding: '$4'
                }}
                direction="column"
                gap="3"
            >
                {props.teamMembers.map((teamMember) => (
                    <Box
                        key={teamMember.id}
                        css={{
                            cursor: 'pointer'
                        }}
                        onClick={() => {
                            history.push(
                                HeaventPaths.EVENT_USER_PROFILE(
                                    props.organizationId,
                                    props.eventId!,
                                    teamMember.id
                                )
                            );
                        }}
                    >
                        <AvatarNameEmail hasName={true} userInfo={teamMember} />
                    </Box>
                ))}
            </Flex>

            <Spacer height="7" />
        </>
    );
};
