import { Button } from 'common-front/src/designSystem/components/button';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { Row } from 'common/src/designSystem/components/table/row';
import {
    EventId,
    UserOverlayAvailablePositionsSlotsQuery,
    VolunteersRegistrationId
} from 'common/src/generated/types';
import { IntervalService } from 'common/src/services/intervalService';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';
import { useVolunteerRegistrationSlotAssignMutation } from '../../../generated/graphqlHooks';

interface IUserOverlayAvailablePositionsSlotsRowProps {
    eventId: EventId;
    slot: UserOverlayAvailablePositionsSlotsQuery['event']['volunteerRegistration']['availablePositionsSlots'][0];
    volunteerRegistrationId: VolunteersRegistrationId;

    reload(): void;
}

export const UserOverlayAvailablePositionsSlotsRow = (
    props: IUserOverlayAvailablePositionsSlotsRowProps
) => {
    const translate = useTranslate();
    const intervalService = useService(IntervalService);
    const { mutate, isLoading } = useVolunteerRegistrationSlotAssignMutation();
    const slotName = isNonEmptyString(props.slot.name)
        ? `${props.slot.name} - ${intervalService.toDisplayString(props.slot.range)}`
        : intervalService.toDisplayString(props.slot.range);

    return (
        <Row>
            <Cell>{props.slot.position.name}</Cell>
            <Cell>{slotName}</Cell>
            <Cell justify="end" width={200}>
                <Button
                    color="white"
                    isLoading={isLoading}
                    size="sm"
                    onClick={async () => {
                        await mutate({
                            eventId: props.eventId,
                            volunteerRegistrationId: props.volunteerRegistrationId,
                            positionSlotId: props.slot.id
                        });

                        props.reload();
                    }}
                >
                    {translate('affecter_94255')}
                </Button>
            </Cell>
        </Row>
    );
};
