import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { PositionId } from 'common/src/generated/types';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import { DocumentRow } from './documentRow';
import { DocumentsList } from './documentsList';

interface IDocumentsPositionsProps {
    numberOfPositions: number;
    positionId: PositionId;
}

export const DocumentsPositions = (props: IDocumentsPositionsProps) => {
    const {
        translate,
        params: { organizationId, eventId }
    } = useHeavent();

    return (
        <DocumentsList title={translate('missions_63972')}>
            <DocumentRow
                color="blueGray"
                count={props.numberOfPositions}
                disabled={props.numberOfPositions === 0}
                icon="sitemap"
                path={HeaventPaths.DOCUMENTS_POSITION(organizationId, eventId, props.positionId)}
                text={translate('nombre_de_fiche_03009')}
                title={translate('fiche_de_missio_00324')}
            />

            <DocumentRow
                color="purple"
                count={props.numberOfPositions}
                disabled={props.numberOfPositions === 0}
                icon="calendar-day"
                path={HeaventPaths.DOCUMENTS_POSITION_PLANNING(
                    organizationId,
                    eventId,
                    props.positionId
                )}
                text={translate('nombre_de_missi_56719')}
                title={translate('planning_d_une_64581')}
            />
        </DocumentsList>
    );
};
