import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I, IIcon } from 'common/src/designSystem/components/i';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { CommonEnvVars } from 'common/src/envVars';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { Button } from '../designSystem/components/button';

interface INoFeatureProps {
    icon: IIcon;
    subtitle?: string;
    title?: string;
}

export const NoFeature = (props: INoFeatureProps) => {
    const translate = useTranslate();

    return (
        <Flex align="center" direction="column">
            <Flex
                align="center"
                css={{
                    background: '$primary500',
                    borderRadius: '48px',
                    fontSize: '$textXl',
                    color: 'white',
                    height: '48px',
                    width: '48px'
                }}
                justify="center"
            >
                <I icon={props.icon} />
            </Flex>

            <Spacer height="4" />

            <Box color="gray900" fontSize="textMd">
                {props.title || translate('la_fonctionnali_59786')}
            </Box>

            <Spacer height="1" />

            <Box color="gray500">{props.subtitle || translate('si_vous_voulez_24448')}</Box>

            <Spacer height="6" />

            <Button to={{ pathname: `mailto:${CommonEnvVars.EMAIL_FROM}` }}>
                {translate('contacter_l_qu_16617')}
            </Button>
        </Flex>
    );
};
