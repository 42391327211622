import { PaginationCell } from 'common-front/src/designSystem/components/pagination/paginationCell';
import { PaginationRow } from 'common-front/src/designSystem/components/pagination/paginationRow';
import { usePaginationInfos } from 'common-front/src/hooks/usePaginationInfos';
import { useSegmentsContext } from 'common-front/src/segments/segmentsContext';
import { Flex } from 'common/src/designSystem/components/flex';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { HeaderCellSort } from 'common/src/designSystem/components/table/headerCellSort';
import { HeaderRow } from 'common/src/designSystem/components/table/headerRow';
import { RowSkeleton } from 'common/src/designSystem/components/table/rowSkeleton';
import { TableGrid } from 'common/src/designSystem/components/table/tableGrid';
import { CustomFieldId, CustomFieldsSortAttributes } from 'common/src/generated/types';
import { useParams, useTranslate } from 'common/src/util/dependencies/dependencies';
import { isNonEmptyString } from 'common/src/util/string';
import { compact } from 'lodash-es';
import * as React from 'react';
import { useCustomFieldsQuery } from '../../generated/graphqlHooks';
import { CustomFieldRow } from './customFieldRow';

export const CustomFieldsList = () => {
    const translate = useTranslate();
    const { organizationId } = useParams();
    const {
        limit,
        nameDebounced,
        offset,
        predicates,
        reloadKey,
        segmentId,
        setLimit,
        setOffset,
        setSort,
        sort,
        columns,
        possibleColumns
    } = useSegmentsContext<CustomFieldId, CustomFieldsSortAttributes>();
    const { data, isLoading, reload } = useCustomFieldsQuery({
        organizationId,
        predicates,
        name: isNonEmptyString(nameDebounced) ? nameDebounced : null,
        limit,
        offset,
        sort
    });
    const { numberOfPages, totalCount } = usePaginationInfos(data.organization?.customFields);
    const selectedFields = React.useMemo(
        () => columns.flatMap((slug) => compact([possibleColumns.find((pc) => pc.slug === slug)])),
        [possibleColumns, columns]
    );

    React.useEffect(() => {
        if (reloadKey > 1) {
            reload();
        }
    }, [reloadKey]);

    return (
        <>
            <Flex css={{ flex: '1', overflow: 'hidden' }} width={1}>
                <TableGrid
                    headerRow={
                        <HeaderRow>
                            {selectedFields.map((field) =>
                                field.slug === 'name' ? (
                                    <HeaderCellSort
                                        key={field.slug}
                                        attribute={CustomFieldsSortAttributes.Name}
                                        setSort={setSort}
                                        sort={sort}
                                    >
                                        {translate('nom_du_champ_48329')}
                                    </HeaderCellSort>
                                ) : field.slug === 'category' ? (
                                    <HeaderCellSort
                                        key={field.slug}
                                        attribute={CustomFieldsSortAttributes.Category}
                                        setSort={setSort}
                                        sort={sort}
                                    >
                                        {translate('cat_gorie_00291')}
                                    </HeaderCellSort>
                                ) : field.slug === 'fieldType' ? (
                                    <HeaderCellSort
                                        key={field.slug}
                                        attribute={CustomFieldsSortAttributes.Type}
                                        setSort={setSort}
                                        sort={sort}
                                        width={250}
                                    >
                                        {translate('type_35427')}
                                    </HeaderCellSort>
                                ) : field.slug === 'numberOfTimesUsed' ? (
                                    <HeaderCell key={field.slug} width={200}>
                                        {translate('nombre_d_utilis_58389')}
                                    </HeaderCell>
                                ) : field.slug === 'state' ? (
                                    <HeaderCellSort
                                        key={field.slug}
                                        attribute={CustomFieldsSortAttributes.Status}
                                        setSort={setSort}
                                        sort={sort}
                                        width={200}
                                    >
                                        {translate('status_06428')}
                                    </HeaderCellSort>
                                ) : (
                                    <HeaderCell key={field.slug}>{field.name}</HeaderCell>
                                )
                            )}

                            <HeaderCell width={100} />
                        </HeaderRow>
                    }
                    paginationRow={
                        <PaginationRow css={{ borderTop: '1px solid $gray200' }}>
                            <PaginationCell
                                limit={limit}
                                numberOfPages={numberOfPages}
                                offset={offset}
                                setLimit={setLimit}
                                setOffset={setOffset}
                                showLimits={true}
                                totalCount={totalCount}
                            />
                        </PaginationRow>
                    }
                >
                    {isLoading ? (
                        <>
                            <RowSkeleton />
                            <RowSkeleton />
                            <RowSkeleton />
                        </>
                    ) : (
                        data.organization.customFields.nodes.map((customField) => (
                            <CustomFieldRow
                                key={customField.id}
                                columns={selectedFields}
                                customField={customField}
                                organizationId={organizationId}
                                reload={reload}
                                segmentId={segmentId}
                            />
                        ))
                    )}
                </TableGrid>
            </Flex>
        </>
    );
};
