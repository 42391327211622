import { Button } from 'common-front/src/designSystem/components/button';
import { Tab } from 'common-front/src/designSystem/components/tabs/tab';
import { TabList } from 'common-front/src/designSystem/components/tabs/tabList';
import { TabPanel } from 'common-front/src/designSystem/components/tabs/tabPanel';
import { Tabs } from 'common-front/src/designSystem/components/tabs/tabs';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import { OrganizationEmailsDns } from './dns/organizationEmailsDns';
import { OrganizationEmailsSenders } from './senders/list/organizationEmailsSenders';

export const OrganizationEmails = () => {
    const {
        history,
        translate,
        params: { organizationId }
    } = useHeavent();
    const isEmailsSenders = location.pathname.endsWith('emails/senders');

    return (
        <Flex css={{ overflowY: 'auto', padding: '$7' }} direction="column" height={1} width={1}>
            <Flex>
                <Flex css={{ flex: '1' }} direction="column" gap="1">
                    <Box font="gray900 displayXs semiBold">{translate('e_mails_09850')}</Box>

                    <Box color="gray500">{translate('param_trer_les_39470')}</Box>
                </Flex>

                {isEmailsSenders && (
                    <Button
                        onClick={() => {
                            history.push(
                                HeaventPaths.CREATE_ORGANIZATION_PARAMETERS_EMAILS_SENDERS(
                                    organizationId
                                )
                            );
                        }}
                    >
                        {translate('cr_er_une_nouve_77052')}
                    </Button>
                )}
            </Flex>

            <Spacer height="7" />

            <Tabs>
                <TabList>
                    <Tab path={HeaventPaths.ORGANIZATION_PARAMETERS_EMAILS_SENDERS(organizationId)}>
                        {translate('_metteurs_74545')}
                    </Tab>
                    <Tab path={HeaventPaths.ORGANIZATION_PARAMETERS_EMAILS_DNS(organizationId)}>
                        {translate('entr_es_dns_11256')}
                    </Tab>
                </TabList>

                <TabPanel
                    path={HeaventPaths.ORGANIZATION_PARAMETERS_EMAILS_SENDERS(organizationId)}
                >
                    <OrganizationEmailsSenders organizationId={organizationId} />
                </TabPanel>

                <TabPanel path={HeaventPaths.ORGANIZATION_PARAMETERS_EMAILS_DNS(organizationId)}>
                    <OrganizationEmailsDns organizationId={organizationId} />
                </TabPanel>
            </Tabs>
        </Flex>
    );
};
