import { Select } from 'common-front/src/designSystem/components/select/select';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import * as React from 'react';
import { useDocumentBuilderContext } from '../documentBuilderContext';
import { DocumentBuilderPage } from './documentBuilderPage';

export const DocumentBuilderPages = () => {
    const { customDocument, cleanSelecteds } = useDocumentBuilderContext();
    const [zoom, setZoom] = React.useState(100);

    return (
        <Box
            css={{
                background: '$gray100',
                position: 'relative'
            }}
            height={1}
            width={1}
        >
            <Flex
                align="center"
                css={{
                    overflow: 'auto',
                    padding: '$9',
                    zoom: `${zoom}%`
                }}
                direction="column"
                gap="7"
                height={1}
                width={1}
                onClick={cleanSelecteds}
            >
                {customDocument.configuration.pages.map((page, index) => (
                    <DocumentBuilderPage key={page.id} index={index} page={page} />
                ))}
            </Flex>

            <Box
                css={{
                    bottom: '24px',
                    position: 'absolute',
                    right: '24px',
                    zIndex: 1000
                }}
                width={140}
            >
                <Select shouldParseAsInt={true} value={zoom} onChange={setZoom}>
                    <option value="50">Zoom (50%)</option>
                    <option value="66">Zoom (66%)</option>
                    <option value="80">Zoom (80%)</option>
                    <option value="100">Zoom (100%)</option>
                    <option value="120">Zoom (120%)</option>
                    <option value="150">Zoom (150%)</option>
                    <option value="200">Zoom (200%)</option>
                </Select>
            </Box>
        </Box>
    );
};
