import { CreateUpdateCategoryModal } from 'common-front/src/components/createUpdateCategoryModal';
import { EventId, PositionsCategory, PositionsCategoryId } from 'common/src/generated/types';
import * as React from 'react';
import {
    usePositionCategoryCreateMutation,
    usePositionCategoryUpdateMutation
} from '../../generated/graphqlHooks';

type PositionCategoryCreate = Pick<PositionsCategory, 'id' | 'name'>;

interface ICreatePositionCategoryProps {
    eventId: EventId;

    onClose(): void;
    onSuccess(positionCategory: PositionCategoryCreate): void;
}

export const CreatePositionCategory = (props: ICreatePositionCategoryProps) => {
    const { mutate } = usePositionCategoryCreateMutation();

    return (
        <CreateUpdateCategoryModal
            initialName={''}
            isEdit={false}
            mutate={async (name) => {
                const { positionCategoryCreate } = await mutate({
                    eventId: props.eventId,
                    positionCategory: { name }
                });

                return positionCategoryCreate;
            }}
            onClose={props.onClose}
            onSuccess={props.onSuccess}
        />
    );
};

interface IUpdatePositionCategoryProps {
    positionCategoryId: PositionsCategoryId;
    positionCategoryName: string;
    eventId: EventId;

    onClose(): void;
    onSuccess(positionCategory: PositionCategoryCreate): void;
}

export const UpdatePositionCategory = (props: IUpdatePositionCategoryProps) => {
    const { mutate } = usePositionCategoryUpdateMutation();

    return (
        <CreateUpdateCategoryModal
            initialName={props.positionCategoryName}
            isEdit={true}
            mutate={async (name) => {
                const { positionCategoryUpdate } = await mutate({
                    eventId: props.eventId,
                    positionCategoryId: props.positionCategoryId,
                    positionCategory: { name }
                });

                return positionCategoryUpdate;
            }}
            onClose={props.onClose}
            onSuccess={props.onSuccess}
        />
    );
};
