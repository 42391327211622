import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { isNonEmptyString } from '../../../util/string';

const getFrontBackground = (badgeType: string) => {
    if (badgeType === 'All Access Pass') {
        return 'https://assets.recrewteer.com/badges/goldenCoast/pass_front.jpg';
    } else if (badgeType === 'All Access Pass + A') {
        return 'https://assets.recrewteer.com/badges/goldenCoast/pass_plus_front.jpg';
    } else {
        return 'https://assets.recrewteer.com/badges/goldenCoast/classique_front.jpg';
    }
};

const getBackBackground = (badgeType: string) => {
    if (badgeType === 'All Access Pass' || badgeType === 'All Access Pass + A') {
        return 'https://assets.recrewteer.com/badges/goldenCoast/pass_back.jpg';
    } else {
        return 'https://assets.recrewteer.com/badges/goldenCoast/classique_back.jpg';
    }
};

interface IBadgeGoldenCoastProps {
    event: DocumentUserCustomBadgeFragment;
}

export const BadgeGoldenCoast = (props: IBadgeGoldenCoastProps) => {
    const vr = props.event.volunteerRegistration;
    const ui = vr.userInfo;
    const statut = ui.fields?.cf37556?.value ?? '';
    const color =
        statut === 'All Access Pass' || statut === 'All Access Pass + A' ? '#faed4f' : '#294999';

    return (
        <>
            <Flex
                css={{
                    background: `url(${getFrontBackground(statut)}) no-repeat`,
                    backgroundSize: '212px 317px',
                    fontFamily: '$neueMontreal',
                    position: 'relative'
                }}
                height={317}
                width={212}
            >
                <Box
                    css={{
                        borderRadius: '4px',
                        left: '55px',
                        overflow: 'hidden',
                        position: 'absolute',
                        top: '105px'
                    }}
                    height={102}
                    width={102}
                >
                    {isNonEmptyString(ui.picture?.url) && (
                        <img height="100%" src={ui.picture!.url} width="100%" />
                    )}
                </Box>

                <Box
                    css={{
                        color,
                        fontSize: '24px',
                        fontWeight: '700',
                        position: 'absolute',
                        textTransform: 'uppercase',
                        top: '218px'
                    }}
                    textAlign="center"
                    width={1}
                >
                    {ui.lastName}
                </Box>

                <Box
                    css={{
                        color,
                        fontSize: '24px',
                        fontWeight: '700',
                        position: 'absolute',
                        top: '248px'
                    }}
                    textAlign="center"
                    width={1}
                >
                    {ui.firstName}
                </Box>

                <Box
                    css={{
                        color,
                        fontWeight: '700',
                        position: 'absolute',
                        top: '286px'
                    }}
                    textAlign="center"
                    width={1}
                >
                    {ui.fields.cf33258?.value}
                </Box>
            </Flex>

            <Flex
                css={{
                    background: `url(${getBackBackground(statut)}) no-repeat`,
                    backgroundSize: '212px 317px',
                    pageBreakBefore: 'always'
                }}
                height={317}
                width={212}
            />
        </>
    );
};
