import { ExistingUserRow } from 'common-front/src/users/create/existingUserRow';
import { ExistingUserRowSkeleton } from 'common-front/src/users/create/existingUserRowSkeleton';
import { EventId, OrganizationId, UsersInfoId } from 'common/src/generated/types';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';
import { useOrganizationUsersInfosQuery } from '../../generated/graphqlHooks';

interface IExistingVolunteersProps {
    organizationId: OrganizationId;
    eventId: EventId;
    nameOrEmail?: string;
    usersInfosIds: UsersInfoId[];

    select(userInfoId: UsersInfoId): void;
}

export const ExistingVolunteers = (props: IExistingVolunteersProps) => {
    const { data, isLoading } = useOrganizationUsersInfosQuery({
        organizationId: props.organizationId,
        excludedEventId: props.eventId,
        name: isNonEmptyString(props.nameOrEmail) ? props.nameOrEmail : null
    });

    return isLoading ? (
        <>
            <ExistingUserRowSkeleton />
            <ExistingUserRowSkeleton />
            <ExistingUserRowSkeleton />
        </>
    ) : (
        <>
            {data.organization.usersInfos.nodes.map((userInfo) => {
                const state = props.usersInfosIds.includes(userInfo.id) ? 'checked' : 'unchecked';

                return (
                    <ExistingUserRow
                        key={userInfo.id}
                        state={state}
                        userInfo={userInfo}
                        onClick={() => {
                            props.select(userInfo.id);
                        }}
                    />
                );
            })}
        </>
    );
};
