import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { SegmentsFolder, SegmentsFolderId } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { SegmentFragment } from 'common/src/vo/segment';
import * as React from 'react';
import { FormModal } from '../../designSystem/components/formModal';
import { RadioRow } from '../../designSystem/form/radioRow';
import { useSegmentMoveFolderMutation } from '../../generated/graphqlHooks';
import { useSegmentsContext } from '../segmentsContext';

interface IMoveSegmentModalProps {
    segment: SegmentFragment;
    segmentsFolders: Array<Pick<SegmentsFolder, 'id' | 'name'>>;

    onClose(): void;
}

export const MoveSegmentModal = (props: IMoveSegmentModalProps) => {
    const translate = useTranslate();
    const { eventId, organizationId, reload } = useSegmentsContext();
    const { mutate: eventSegmentMoveFolder } = useSegmentMoveFolderMutation();

    return (
        <FormModal
            buttonText={translate('d_placer_la_vue_27440')}
            icon="sliders"
            initialValues={{
                segmentFolderId: props.segment.segmentFolderId
            }}
            size="md"
            title={translate('d_placer_la_vue_55115', props.segment.name)}
            onClose={props.onClose}
            onSubmit={async (values: { segmentFolderId: SegmentsFolderId }) => {
                await eventSegmentMoveFolder({
                    organizationId,
                    eventId,
                    segmentId: props.segment.id,
                    segmentFolderId: values.segmentFolderId
                });

                reload();
                props.onClose();
            }}
        >
            <Box color="gray900" fontWeight="medium">
                {translate('vers_quel_dossi_86452')}
            </Box>

            <Spacer height="3" />

            <Flex css={{ overflowY: 'auto' }} direction="column" gap="3">
                {props.segmentsFolders.map((segmentFolder) => (
                    <RadioRow
                        key={segmentFolder.id}
                        label={segmentFolder.name}
                        name="segmentFolderId"
                        value={segmentFolder.id}
                    />
                ))}
            </Flex>
        </FormModal>
    );
};
