/* eslint-disable react/no-unused-prop-types */
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { styled } from 'common/src/designSystem/components/stitches';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';
import { useUniqueIds } from '../../hooks/useUniqueIds';
import { Label } from './input/label';

const _Toggle = styled('div', {
    alignItems: 'center',
    borderRadius: '12px',
    cursor: 'pointer',
    display: 'flex',
    position: 'relative',
    '& > div': {
        background: 'white',
        boxShadow: '$sm',
        position: 'absolute',
        transition: 'left 200ms ease-in'
    },
    variants: {
        size: {
            sm: {
                height: '16px',
                width: '30px',
                '& > div': {
                    borderRadius: '12px',
                    height: '12px',
                    width: '12px'
                }
            },
            md: {
                height: '24px',
                width: '44px',
                '& > div': {
                    borderRadius: '20px',
                    height: '20px',
                    width: '20px'
                }
            }
        },
        state: {
            unchecked: {
                background: '$gray200',
                '& > div': {
                    left: '2px'
                }
            },
            checked: {
                background: '$primary600'
            }
        }
    },
    compoundVariants: [
        {
            size: 'sm',
            state: 'checked',
            css: {
                '& > div': {
                    left: `calc(100% - 2px - 12px)`
                }
            }
        },
        {
            size: 'md',
            state: 'checked',
            css: {
                '& > div': {
                    left: `calc(100% - 2px - 20px)`
                }
            }
        }
    ],
    defaultVariants: {
        size: 'sm'
    }
});

export type IToggleSize = 'sm' | 'md';

interface IToggleProps {
    descId?: string;
    labelId?: string;
    inputId?: string;
    tabIndex?: number;
    size?: IToggleSize;
    value: boolean;

    onChange(value: boolean): void;
}

export const Toggle = (props: IToggleProps) => (
    <_Toggle
        aria-describedby={props.descId}
        aria-labelledby={props.labelId}
        id={props.inputId}
        role={'checkbox'}
        size={props.size}
        state={props.value ? 'checked' : 'unchecked'}
        tabIndex={props.tabIndex ?? 0}
        onClick={() => {
            props.onChange(!props.value);
        }}
        onKeyDown={(e) => {
            if (e.code === 'Space') {
                e.preventDefault();
                props.onChange(!props.value);
            }
        }}
    >
        <div />
    </_Toggle>
);

interface IToggleTextProps extends IToggleProps {
    children: React.ReactNode;
    subtext?: string;
}

export const ToggleText = (props: IToggleTextProps) => {
    const { inputId, descId, labelId } = useUniqueIds();
    const hasSubText = isNonEmptyString(props.subtext);

    return (
        <Flex
            align="center"
            css={{ cursor: 'pointer', userSelect: 'none' }}
            tabIndex={0}
            onClick={() => {
                props.onChange(!props.value);
            }}
            onKeyDown={(e) => {
                if (e.code === 'Space') {
                    e.preventDefault();
                    props.onChange(!props.value);
                }
            }}
        >
            <Toggle
                descId={descId}
                inputId={inputId}
                labelId={labelId}
                size={props.size}
                tabIndex={-1}
                value={props.value}
                onChange={() => {
                    // do nothing
                }}
            />

            <Spacer width="2" />

            <Flex css={{ fontSize: props.size === 'md' ? '$3' : '$2' }} direction="column">
                <Label htmlFor={inputId} id={labelId}>
                    <Box color="gray800" fontWeight="medium">
                        {props.children}
                    </Box>
                </Label>

                {hasSubText && (
                    <Box color="gray500" id={descId}>
                        {props.subtext}
                    </Box>
                )}
            </Flex>
        </Flex>
    );
};
