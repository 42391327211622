import { Box } from 'common/src/designSystem/components/box';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { UserPanelAccreditationSlotUserInfoFragment } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { AccreditationsAccreditation } from '../../accreditations/list/accreditationsAccreditation';

interface IUserPanelAccreditationsProps {
    accreditationsUsersInfos: UserPanelAccreditationSlotUserInfoFragment[];
}

export const UserPanelAccreditations = ({
    accreditationsUsersInfos
}: IUserPanelAccreditationsProps) => {
    const translate = useTranslate();

    return (
        <>
            <Spacer height="6" />

            {accreditationsUsersInfos.length > 0 && (
                <>
                    <Box color="gray800" fontSize="textMd" fontWeight="semiBold">
                        {translate('accr_ditations_39450')}
                    </Box>

                    {accreditationsUsersInfos.map((aui, index) => (
                        <React.Fragment key={index}>
                            <Spacer height="3" />

                            <AccreditationsAccreditation accreditationUserInfo={aui} />
                        </React.Fragment>
                    ))}
                </>
            )}
        </>
    );
};
