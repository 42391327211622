import { groupBy, range } from 'lodash-es';
import { DateTime, Interval } from 'luxon';
import * as React from 'react';
import { Box } from '../../designSystem/components/box';
import { Flex } from '../../designSystem/components/flex';
import { DocumentPositionsPlanningFragment } from '../../generated/types';
import { DateTimeService } from '../../services/dateTimeService';
import { getColorByBackgroundColor } from '../../util/color';
import { useService, useTranslate } from '../../util/dependencies/dependencies';
import { LocaleFormats } from '../../util/luxon';
import { isNonEmptyString } from '../../util/string';
import { HeaderCell } from '../cells';
import { PositionsVolunteersPlanningHeader } from '../positionsVolunteersPlanning/positionsVolunteersPlanningHeader';

const CELL_WIDTH = 120;

interface IPositionsPlanningPageProps {
    eventName: string;
    hideHeader?: boolean;
    positions: Array<DocumentPositionsPlanningFragment['positionsSlots']['nodes'][0]['position']>;
    segmentName: string;
    slots: DocumentPositionsPlanningFragment['positionsSlots']['nodes'];
    startDate: DateTime;
}

export const PositionsPlanningPage = (props: IPositionsPlanningPageProps) => {
    const translate = useTranslate();
    const dateTimeService = useService(DateTimeService);
    const positionIdToSlots = groupBy(props.slots, (s) => s.positionId);

    return (
        <Flex
            css={{
                background: 'white',
                pageBreakBefore: 'always'
            }}
            direction="column"
            width={1}
        >
            {props.hideHeader !== true && (
                <PositionsVolunteersPlanningHeader
                    eventName={props.eventName}
                    segmentName={props.segmentName}
                />
            )}

            <Flex
                css={{ background: 'white', padding: '$6', position: 'relative' }}
                direction="column"
                width={1}
            >
                <Flex>
                    <HeaderCell width={CELL_WIDTH * 2}>{translate('jour_87026')}</HeaderCell>
                    <HeaderCell width={CELL_WIDTH * 6}>
                        {dateTimeService.toLocaleString(
                            props.startDate,
                            LocaleFormats.DateOnly.WeekdayLongMonthLong
                        )}
                    </HeaderCell>
                </Flex>

                <Flex>
                    <HeaderCell width={CELL_WIDTH * 2}>{translate('cr_neaux_33401')}</HeaderCell>

                    {range(0, 6).map((i) => {
                        const start = props.startDate.plus({ hour: 2 * i });
                        const end = start.plus({ hour: 2 });
                        const interval = Interval.fromDateTimes(start, end);

                        return (
                            <HeaderCell key={i} width={CELL_WIDTH}>
                                {interval.toFormat('HH:mm')}
                            </HeaderCell>
                        );
                    })}
                </Flex>

                {props.positions.map((position, positionIndex) => {
                    const slots = positionIdToSlots[position.id];

                    return (
                        <React.Fragment key={position.id}>
                            <Flex>
                                <HeaderCell
                                    css={{
                                        color: '$gray700',
                                        height: '48px'
                                    }}
                                    width={CELL_WIDTH * 2}
                                >
                                    <Box css={{ ellipsis: true }} textAlign="start" width={1}>
                                        {position.name}
                                    </Box>
                                </HeaderCell>

                                {range(0, 6).map((i) => (
                                    <HeaderCell
                                        key={i}
                                        css={{ height: '48px' }}
                                        width={CELL_WIDTH}
                                    />
                                ))}
                            </Flex>

                            {slots.map((slot) => {
                                const diffWithStart = slot.range
                                    .start!.diff(props.startDate)
                                    .shiftTo('hours', 'minutes');
                                const diff = slot.range
                                    .end!.diff(slot.range.start!)
                                    .shiftTo('hours', 'minutes');
                                const left = 264 + diffWithStart.hours * 60 + diffWithStart.minutes;
                                const top = 112 + positionIndex * 48;
                                const width = diff.hours * 60 + diff.minutes;
                                const name = isNonEmptyString(slot.name) ? `${slot.name} - ` : '';
                                const fullName = `${name}${slot.range.toFormat('HH:mm')}`;

                                return (
                                    <Flex
                                        key={slot.id}
                                        align="center"
                                        css={{
                                            background: slot.position.color,
                                            borderRadius: '$1',
                                            color: getColorByBackgroundColor(slot.position.color),
                                            fontSize: '$textXs',
                                            height: '32px',
                                            left: `${left}px`,
                                            position: 'absolute',
                                            top: `${top}px`,
                                            width: `${width}px`
                                        }}
                                    >
                                        <Box
                                            css={{ ellipsis: true, px: '$1' }}
                                            textAlign="center"
                                            title={fullName}
                                            width={1}
                                        >
                                            {fullName}
                                        </Box>
                                    </Flex>
                                );
                            })}
                        </React.Fragment>
                    );
                })}
            </Flex>
        </Flex>
    );
};
