import { RightPanel } from 'common-front/src/designSystem/components/rightPanel/rightPanel';
import { RightPanelBody } from 'common-front/src/designSystem/components/rightPanel/rightPanelBody';
import { RightPanelHeader } from 'common-front/src/designSystem/components/rightPanel/rightPanelHeader';
import { UserPositionsWishedSlots } from 'common-front/src/users/information/userPositionsWishedSlots';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { EventId, OrganizationId, UsersInfoId } from 'common/src/generated/types';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import { useUserOverlaySlotsQuery } from '../../../../generated/graphqlHooks';

interface IAvailabilitiesRightPanelProps {
    eventId: EventId;
    organizationId: OrganizationId;
    userInfoId: UsersInfoId;

    onClose(): void;
}

export const AvailabilitiesRightPanel = (props: IAvailabilitiesRightPanelProps) => {
    const { data, loader } = useUserOverlaySlotsQuery({
        organizationId: props.organizationId,
        eventId: props.eventId,
        userInfoId: props.userInfoId
    });

    return (
        <RightPanel size="md" onClose={props.onClose}>
            <RightPanelHeader>Disponibilités</RightPanelHeader>

            <RightPanelBody>
                {loader || (
                    <>
                        <Spacer height="6" />
                        <UserPositionsWishedSlots
                            editPath={HeaventPaths.EDIT_USER_SLOTS(
                                props.organizationId,
                                props.eventId,
                                props.userInfoId
                            )}
                            formsUsersInfos={data.organization.userInfo.formsUsersInfos}
                        />

                        <Spacer height="6" />
                    </>
                )}
            </RightPanelBody>
        </RightPanel>
    );
};
