import * as React from 'react';
import { MappingsFormBox } from './mappingsFormBox';
import { PresetsFormBox } from './presetsFormBox';
import { OrganizationCustomFieldsFrontQuery } from 'common/src/generated/types';
import { VolunteersImportInputWithFile } from './volunteersImport';
import { FormApi } from 'final-form';
import { useCSVHeader } from './useCSVHeader';

interface IMappingsFormBoxes {
    customFields: OrganizationCustomFieldsFrontQuery['organization']['customFields']['nodes'];
    file: File;
    form: FormApi<{ input: VolunteersImportInputWithFile }>;
    values: { input: VolunteersImportInputWithFile };
}

export const MappingsFormBoxes = ({ customFields, file, form, values }: IMappingsFormBoxes) => {
    const { isLoading, columns, errors } = useCSVHeader(file);

    if (isLoading || errors) {
        return null; /* Loading doesn’t take long: don’t display a loader. Errors are aldready displayed in the <FileS3Input />. */
    } else {
        return (
            <>
                <MappingsFormBox
                    columns={columns}
                    customFields={customFields}
                    mode={values.input.mode}
                />

                <PresetsFormBox
                    change={form.change}
                    customFields={customFields}
                    values={values.input.customFields}
                />
            </>
        );
    }
};
