import { EventId, FormId, OrganizationId } from 'common/src/generated/types';
import { Emptyable } from 'common/src/util/emptyable';
import * as React from 'react';

interface IFormContext {
    basePath: string;
    eventId: Emptyable<EventId>;
    formId: FormId;
    isSaving: boolean;
    organizationId: OrganizationId;

    setIsSaving(isSaving: boolean): void;
}

const FormContext = React.createContext({} as IFormContext);

interface IFormContextProviderProps {
    basePath: string;
    children: React.ReactNode;
    eventId: Emptyable<EventId>;
    formId: FormId;
    organizationId: OrganizationId;
}

export const FormContextProvider = (props: IFormContextProviderProps) => {
    const [isSaving, setIsSaving] = React.useState(false);

    return (
        <FormContext.Provider
            value={{
                basePath: props.basePath,
                eventId: props.eventId,
                formId: props.formId,
                isSaving,
                organizationId: props.organizationId,
                setIsSaving
            }}
        >
            {props.children}
        </FormContext.Provider>
    );
};

export function useFormContext() {
    return React.useContext(FormContext);
}
