import { groupBy, sortBy } from 'lodash-es';
import { DateTime } from 'luxon';
import * as React from 'react';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { Emptyable } from '../../../util/emptyable';
import { Paris20KmAssignmentPage } from './paris20kmAssignmentPage';

interface IParis20KmAssignmentProps {
    date: Emptyable<DateTime>;
    event: DocumentUserCustomBadgeFragment;
}

export const Paris20KmAssignment = (props: IParis20KmAssignmentProps) => {
    const psuis = props.event.volunteerRegistration.positionsSlotsUsersInfos
        .filter(({ position }) => position.tags.some((t) => t.name === 'Fiche personnalisée'))
        .filter(({ positionSlot }) => {
            if (props.date?.isValid) {
                return positionSlot.range.start!.startOf('day').equals(props.date.startOf('day'));
            } else {
                return true;
            }
        });
    const grouped = sortBy(
        Object.entries(groupBy(psuis, ({ position }) => position.id)),
        ([positionName]) => positionName
    );

    return (
        <>
            {grouped.map(([_positionName, psuis], index) => (
                <Paris20KmAssignmentPage
                    key={index}
                    eventId={props.event.id}
                    psuis={psuis}
                    userInfo={props.event.volunteerRegistration.userInfo}
                />
            ))}
        </>
    );
};
