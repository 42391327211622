import { Grid } from 'common/src/designSystem/components/grid';
import { has16Chars, hasNumber, hasSpecial, hasUpper } from 'common/src/input/authInput';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { PasswordRule } from './passwordRule';

interface IPasswordRulesProps {
    password: string;
}

export const PasswordRules = (props: IPasswordRulesProps) => {
    const translate = useTranslate();

    return (
        <Grid
            css={{
                border: '1px solid $gray200',
                borderRadius: '$1',
                boxShadow: '$md',
                gap: '$3 $1',
                gridTemplateColumns: '1fr',
                gridTemplateRows: '1fr 1fr 1fr 1fr',
                padding: '$4',
                '@desktop': {
                    gridTemplateColumns: '1fr 1fr',
                    gridTemplateRows: '1fr 1fr'
                }
            }}
        >
            <PasswordRule isValid={has16Chars(props.password)}>
                {translate('16_caract_res_m_52434')}
            </PasswordRule>

            <PasswordRule isValid={hasNumber(props.password)}>
                {translate('au_moins_un_chi_73862')}
            </PasswordRule>

            <PasswordRule isValid={hasUpper(props.password)}>
                {translate('au_moins_une_ma_58302')}
            </PasswordRule>

            <PasswordRule isValid={hasSpecial(props.password)}>
                {translate('au_moins_un_car_54396')}
            </PasswordRule>
        </Grid>
    );
};
