import { Loader } from 'common-front/src/components/loader/loader';
import { Alert } from 'common-front/src/designSystem/components/alert';
import { Button } from 'common-front/src/designSystem/components/button';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    CampaignEstimate,
    CampaignState,
    CreateCampaignEmailSenderFragment
} from 'common/src/generated/types';
import { ICreateUpdateCampaignValues } from 'common/src/input/campaignInput';
import { useParams, useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { FormRenderProps } from 'react-final-form';
import { useCampaignEstimateMutation } from '../../generated/graphqlHooks';

interface ISummaryProps {
    emailsSenders: CreateCampaignEmailSenderFragment[];
    formProps: FormRenderProps<ICreateUpdateCampaignValues>;
    isEdit: boolean;
}

export const Summary = (props: ISummaryProps) => {
    const translate = useTranslate();
    const { organizationId, eventId } = useParams();
    const [campaignEstimate, setCampaignEstimate] = React.useState<CampaignEstimate | null>(null);
    const { mutate } = useCampaignEstimateMutation();
    const { form, handleSubmit, submitting, values } = props.formProps;
    const isSendDisabled = React.useMemo(() => {
        const selectedEmailSender = props.emailsSenders.find(
            (es) => es.id === props.formProps.values.campaign.emailSenderId
        );

        return selectedEmailSender && !selectedEmailSender.isValidToSendEmail;
    }, [props.emailsSenders, props.formProps.values.campaign.emailSenderId]);

    React.useEffect(() => {
        mutate({ organizationId, eventId, campaign: props.formProps.values.campaign }).then(
            (result) => {
                setCampaignEstimate(result.campaignEstimate);
            }
        );
    }, []);

    if (campaignEstimate) {
        return (
            <>
                {isSendDisabled && (
                    <>
                        <Alert color="error" leftIcon="circle-exclamation">
                            {translate('vous_ne_pouvez_33410')}
                        </Alert>

                        <Spacer height="4" />
                    </>
                )}

                <Flex gap="1">
                    <Box color="gray800" fontWeight="medium">
                        {translate('nombre_de_desti_22505')}
                    </Box>
                    <Box color="gray800">
                        {translate('_1_membre_49737', campaignEstimate.numberOfRecipients)}
                    </Box>
                </Flex>

                <Spacer height="3" />

                <Flex gap="1">
                    <Box color="gray800" fontWeight="medium">
                        {translate('prix_total_03054')}
                    </Box>
                    <Box color="gray800">
                        {campaignEstimate.price === 0
                            ? translate('gratuit_13712')
                            : `${campaignEstimate.price}€`}
                    </Box>
                </Flex>

                <Spacer height="7" />

                <Flex gap="4">
                    <Button
                        disabled={isSendDisabled}
                        isLoading={submitting}
                        onClick={() => {
                            if (values.campaign.state !== CampaignState.Scheduled) {
                                form.change('campaign.state', CampaignState.InProgress);
                            }

                            handleSubmit();
                        }}
                    >
                        {values.campaign.state === CampaignState.Scheduled
                            ? translate('programmer_la_c_01938')
                            : props.isEdit
                              ? translate('mettre_jour_l_63945')
                              : translate('cr_er_et_envoye_81501')}
                    </Button>

                    <Button
                        color="white"
                        isLoading={submitting}
                        onClick={() => {
                            form.change('campaign.state', CampaignState.Draft);
                            handleSubmit();
                        }}
                    >
                        {translate('enregistrer_en_81222')}
                    </Button>
                </Flex>
            </>
        );
    } else {
        return <Loader />;
    }
};
