import { IconBackground } from 'common-front/src/designSystem/components/iconBackground';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';

export const PositionSlotMembersBlankMobile = () => {
    const translate = useTranslate();

    return (
        <Flex align="center" css={{ flex: 1 }} direction="column" gap="4" justify="center">
            <IconBackground color="primary" icon="user-group" />
            <Box color="gray500">{translate('aucun_b_n_vole_19616')}</Box>
        </Flex>
    );
};
