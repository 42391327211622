import { DeleteModal } from 'common-front/src/designSystem/components/deleteModal';
import { DropdownEllipsis } from 'common-front/src/designSystem/components/dropdown/dropdownEllipsis';
import { ItemIcon } from 'common-front/src/designSystem/components/dropdown/item';
import { Separator } from 'common-front/src/designSystem/components/separator';
import { useVolunteersRegistrationsMassDeleteMutation } from 'common-front/src/generated/graphqlHooks';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { DelegationMembersQuery } from 'common/src/generated/types';
import * as React from 'react';

interface IAccreditationMemberRowDropdownProps {
    canEditMembers: boolean;
    member: DelegationMembersQuery['organization']['usersInfos']['nodes'][number];
    showDeleteFromEvent: boolean;
    showDocumentsDownload: boolean;

    reload(): void;
}

export const AccreditationMemberRowDropdown = ({
    canEditMembers,
    member,
    reload,
    showDeleteFromEvent,
    showDocumentsDownload
}: IAccreditationMemberRowDropdownProps) => {
    const {
        translate,
        params: { eventId }
    } = useHeavent();
    const { mutate: volunteerRegistrationDelete } = useVolunteersRegistrationsMassDeleteMutation();
    const [isDeleteFromEventOpen, setIsDeleteFromEventOpen] = React.useState(false);
    const showDocuments = showDocumentsDownload && member.links !== undefined && eventId;

    return (
        <>
            <DropdownEllipsis>
                {showDocuments && canEditMembers && (
                    <Separator color="gray100" direction="horizontal" />
                )}

                {showDeleteFromEvent && (
                    <ItemIcon
                        color="red"
                        icon="trash-can"
                        onClick={() => setIsDeleteFromEventOpen(true)}
                    >
                        {translate('delete_from_eve_69290')}
                    </ItemIcon>
                )}
            </DropdownEllipsis>

            {isDeleteFromEventOpen && member.volunteerRegistration && (
                <DeleteModal
                    buttonText={translate('supprimer_43083')}
                    subtext={[translate('are_you_sure_yo_46668')]}
                    text={translate('suppression_de_73669', member.name)}
                    onClose={() => setIsDeleteFromEventOpen(false)}
                    onDelete={async () => {
                        await volunteerRegistrationDelete({
                            eventId,
                            usersInfosIds: [member.id]
                        });

                        reload();
                    }}
                />
            )}
        </>
    );
};
