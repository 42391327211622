import { sortBy } from 'lodash-es';
import * as React from 'react';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { PlaygroundAssignmentPage } from './playgroundAssignmentPage';

interface IPlaygroundAssignmentProps {
    event: DocumentUserCustomBadgeFragment;
}

export const PlaygroundAssignment = (props: IPlaygroundAssignmentProps) => {
    const psuis = React.useMemo(
        () =>
            sortBy(props.event.volunteerRegistration.positionsSlotsUsersInfos, (psui) =>
                psui.positionSlot.range.start!.toMillis()
            ),
        [props.event.volunteerRegistration.positionsSlotsUsersInfos]
    );

    return (
        <>
            {psuis.map((psui) => (
                <PlaygroundAssignmentPage
                    key={psui.positionSlot.id}
                    eventId={props.event.id}
                    positionSlotUserInfo={psui}
                    vr={props.event.volunteerRegistration}
                />
            ))}
        </>
    );
};
