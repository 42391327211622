import * as React from 'react';
import {
    BruleursDocumentOptions,
    DocumentUserCustomBadgesFragment
} from '../../../generated/types';
import { BadgeBruleursA6 } from './badgeBruleursA6';

interface IBadgesBruleursProps {
    event: DocumentUserCustomBadgesFragment;
    options: BruleursDocumentOptions;
}

export const BadgesBruleurs = (props: IBadgesBruleursProps) => (
    <>
        {props.event.volunteersRegistrations.nodes.map((vr) => (
            <BadgeBruleursA6
                key={vr.userInfo.id}
                event={{
                    ...props.event,
                    volunteerRegistration: vr
                }}
                options={props.options}
            />
        ))}
    </>
);
