import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { EventId, EventsDocumentId } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { debounce } from 'lodash-es';
import * as React from 'react';
import { useEventDocumentUpdateMutation } from '../../generated/graphqlHooks';

interface IDocumentOptionsProps {
    children: React.ReactNode;
}

export const DocumentOptions = (props: IDocumentOptionsProps) => {
    const translate = useTranslate();

    return (
        <Flex
            css={{
                border: '1px solid $gray200',
                borderRadius: '$2',
                padding: '$6',
                overflowY: 'auto'
            }}
            direction="column"
            width={420}
        >
            <Box color="gray900" fontSize="textMd" fontWeight="medium">
                {translate('param_tres_de_l_11729')}
            </Box>

            <Spacer height="1" />

            <Box color="gray500">{translate('s_lectionnez_le_59909')}</Box>

            <Spacer height="5" />

            <Flex direction="column" gap="3" width={1}>
                {props.children}
            </Flex>
        </Flex>
    );
};

export function useDocumentOptions<T>(
    options: T,
    getDefaultOptions: T,
    eventId: EventId,
    eventDocumentId: EventsDocumentId
): [T, (opts: T) => Promise<any>] {
    const { mutate } = useEventDocumentUpdateMutation();
    const [_options, _setOptions] = React.useState<T>({
        ...getDefaultOptions,
        ...options
    });
    const save = React.useCallback(
        debounce(async (newOptions: T) => {
            await mutate({
                eventId,
                id: eventDocumentId,
                options: newOptions
            });
        }, 200),
        [eventId, eventDocumentId]
    );
    const setOptions = async (newOptions: Partial<T>) => {
        const finalOptions = {
            ..._options,
            ...newOptions
        };

        _setOptions(finalOptions);
        await save(finalOptions);
    };

    return [_options, setOptions];
}
