import { Empty } from 'common-front/src/components/empty/empty';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import { Route, Switch } from 'react-router';
import { useEventContext } from '../../events/show/eventContext';
import { useDelegationsSegmentsQuery } from '../../generated/graphqlHooks';
import { Delegations } from './delegations';

export const DelegationsLoader = () => {
    const {
        params: { organizationId, eventId }
    } = useHeavent();
    const { localEvent } = useEventContext();
    const { data, loader, reload } = useDelegationsSegmentsQuery({ eventId });
    const segmentsIds = React.useMemo(
        () => (data.event?.segmentsFolders ?? []).flatMap((sf) => sf.segments).map((s) => s.id),
        [data.event]
    );

    if (loader) {
        return loader;
    } else {
        return (
            <Switch>
                <Route
                    path={HeaventPaths.DELEGATIONS_SEGMENT(
                        ':organizationId',
                        ':eventId',
                        ':segmentId'
                    )}
                >
                    <Delegations event={data.event} reload={reload} />
                </Route>

                <Route>
                    <Empty
                        path={HeaventPaths.DELEGATIONS_SEGMENT(
                            organizationId,
                            eventId,
                            localEvent?.delegationsSegmentId &&
                                segmentsIds.includes(localEvent.delegationsSegmentId)
                                ? localEvent.delegationsSegmentId
                                : segmentsIds[0]
                        )}
                        replace={true}
                    />
                </Route>
            </Switch>
        );
    }
};
