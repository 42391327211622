import { Flex } from 'common/src/designSystem/components/flex';
import { Skeleton } from 'common/src/designSystem/components/skeleton';
import { Spacer } from 'common/src/designSystem/components/spacer';
import * as React from 'react';

export const EventsEventSkeleton = () => (
    <Flex
        css={{
            border: '1px solid $gray200',
            borderRadius: '12px',
            boxShadow: '$xs',
            cursor: 'pointer',
            padding: '$4'
        }}
        direction="column"
    >
        <Skeleton borderRadius="16px" height={22} width={65} />

        <Spacer height="1" />

        <Skeleton borderRadius="$2" height={25} width={1} />

        <Spacer height="4" />

        <Skeleton borderRadius="$2" height={46} width={1} />
    </Flex>
);
