import { Flex } from 'common/src/designSystem/components/flex';
import { AccreditInfosQuery, AccreditSlotsQuery } from 'common/src/generated/types';
import { groupBy, sortBy } from 'lodash-es';
import * as React from 'react';
import { AccreditCategory } from './accreditCategory';

interface IAccreditCategoriesProps {
    accreditationsSlots: AccreditSlotsQuery['event']['accreditationsSlots']['nodes'];
    delegation: AccreditInfosQuery['event']['volunteerRegistration']['delegation'];
}

export const AccreditCategories = ({
    accreditationsSlots,
    delegation
}: IAccreditCategoriesProps) => {
    const slotsByCategory = React.useMemo(
        () =>
            sortBy(
                Object.values(groupBy(accreditationsSlots, (as) => as.accreditationCategory.id)),
                (slots) => slots[0].accreditationCategory.name.toLowerCase()
            ),
        [accreditationsSlots]
    );

    return (
        <Flex direction="column" gap="4" width={1}>
            {slotsByCategory.map((slots) => {
                const category = slots[0].accreditationCategory;

                return (
                    <AccreditCategory
                        key={category.id}
                        category={category}
                        delegation={delegation}
                        slots={slots}
                    />
                );
            })}
        </Flex>
    );
};
