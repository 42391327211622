import {
    AccreditationIdPathParam,
    AccreditationsSlotIdPathParam,
    EventIdPathParam,
    OrganizationIdPathParam,
    UserInfoIdPathParam
} from '../pathsTypes';
import { buildPath, PathComponents as CommonPathComponents } from './commonPaths';
import { EventsPaths } from './eventsPaths';

const PathComponents = {
    ITEMS: {
        ACCREDITATIONS: {
            ACCREDITATION: 'accreditation',
            ACCREDITATIONS: 'accreditations',
            ACCREDITATION_SLOT: 'slot'
        }
    },
    SECTIONS: {
        DASHBOARD: 'dashboard',
        MEMBER: 'member',
        MEMBERS: 'members'
    }
};

const ItemPaths = {
    ACCREDITATIONS: PathComponents.ITEMS.ACCREDITATIONS.ACCREDITATIONS,
    ACCREDITATION: (accreditationId: AccreditationIdPathParam) =>
        buildPath([PathComponents.ITEMS.ACCREDITATIONS.ACCREDITATION, accreditationId]),
    ACCREDITATION_SLOT: (accreditationSlotId: AccreditationsSlotIdPathParam) =>
        buildPath([PathComponents.ITEMS.ACCREDITATIONS.ACCREDITATION_SLOT, accreditationSlotId])
};

export type AccreditationParams = {
    organizationId: OrganizationIdPathParam;
    eventId: EventIdPathParam;
    accreditationId: AccreditationIdPathParam;
};

export type AccreditationSlotParams = {
    organizationId: OrganizationIdPathParam;
    eventId: EventIdPathParam;
    accreditationId: AccreditationIdPathParam;
    accreditationSlotId: AccreditationsSlotIdPathParam;
};

export type AccreditationUserParams = AccreditationParams & {
    userInfoId: UserInfoIdPathParam;
};

export const AccreditationsPaths = {
    // Access Paths
    ACCREDITATION: ({ organizationId, eventId, accreditationId }: AccreditationParams) =>
        buildPath([
            EventsPaths.EVENT(organizationId, eventId),
            ItemPaths.ACCREDITATION(accreditationId)
        ]),
    ACCREDITATION_SLOT: ({
        organizationId,
        eventId,
        accreditationId,
        accreditationSlotId
    }: AccreditationSlotParams) =>
        buildPath([
            AccreditationsPaths.ACCREDITATION({ organizationId, eventId, accreditationId }),
            ItemPaths.ACCREDITATION_SLOT(accreditationSlotId)
        ]),
    ACCREDITATION_DASHBOARD: (params: AccreditationParams) =>
        buildPath([AccreditationsPaths.ACCREDITATION(params), PathComponents.SECTIONS.DASHBOARD]),
    ACCREDITATION_MEMBERS: (params: AccreditationParams) =>
        buildPath([AccreditationsPaths.ACCREDITATION(params), PathComponents.SECTIONS.MEMBERS]),
    ACCREDITATION_MEMBER: ({
        organizationId,
        eventId,
        accreditationId,
        userInfoId
    }: AccreditationUserParams) =>
        buildPath([
            AccreditationsPaths.ACCREDITATION_MEMBERS({ organizationId, eventId, accreditationId }),
            PathComponents.SECTIONS.MEMBER,
            userInfoId
        ]),
    ACCREDITATION_MEMBER_INFORMATION: (params: AccreditationUserParams) =>
        buildPath([
            AccreditationsPaths.ACCREDITATION_MEMBER(params),
            CommonPathComponents.SECTIONS.INFORMATION
        ])
};
