import { Form } from 'common-front/src/components/form/form';
import { Button } from 'common-front/src/designSystem/components/button';
import { HorizontalSpacerSeparator } from 'common-front/src/designSystem/components/separator';
import { ToggleText } from 'common-front/src/designSystem/form/toggle';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import * as React from 'react';
import {
    useOrganizationMembersSpaceSettingsQuery,
    useOrganizationMembersSpaceSettingsUpdateMutation
} from '../../generated/graphqlHooks';

export const OrganizationMembersSpaceSettings = () => {
    const {
        params: { organizationId },
        translate
    } = useHeavent();
    const { data, loader } = useOrganizationMembersSpaceSettingsQuery({
        organizationId
    });
    const { mutate } = useOrganizationMembersSpaceSettingsUpdateMutation();

    return (
        loader || (
            <Form
                direction="column"
                initialValues={{
                    hideAssignmentsUntilConfirmationEmailSent:
                        data.organization.hideAssignmentsUntilConfirmationEmailSent,
                    hideAccreditationsUntilConfirmationEmailSent:
                        data.organization.hideAccreditationsUntilConfirmationEmailSent
                }}
                render={({ handleSubmit, submitting }) => (
                    <>
                        <Spacer height="7" />

                        <Box color="gray900" fontSize="textLg" fontWeight="medium">
                            {translate('param_tres_de_l_83556')}
                        </Box>

                        <Box color="gray500" fontWeight="medium">
                            {translate('personnalisez_l_44387')}
                        </Box>

                        <HorizontalSpacerSeparator height="5" />

                        <Flex gap="7">
                            <Box color="gray700" fontWeight="medium" width={300}>
                                {translate('diffusion_des_a_83706')}
                            </Box>

                            <ToggleText name="hideAssignmentsUntilConfirmationEmailSent">
                                {translate('afficher_les_af_06759')}
                            </ToggleText>
                        </Flex>

                        <HorizontalSpacerSeparator height="5" />

                        <Flex gap="7">
                            <Box color="gray700" fontWeight="medium" width={300}>
                                {translate('diffusion_des_a_32535')}
                            </Box>

                            <ToggleText name="hideAccreditationsUntilConfirmationEmailSent">
                                {translate('afficher_les_ac_55704')}
                            </ToggleText>
                        </Flex>

                        <HorizontalSpacerSeparator height="5" />

                        <Flex>
                            <Button isLoading={submitting} onClick={handleSubmit}>
                                {translate('enregistrer_06519')}
                            </Button>
                        </Flex>
                    </>
                )}
                onSubmit={async (values) => {
                    await mutate({
                        organizationId,
                        membersSpaceSettings: values
                    });
                }}
            />
        )
    );
};
