import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import * as React from 'react';
import { Field } from 'react-final-form';
import { useUniqueIds } from '../../hooks/useUniqueIds';
import { Label } from '../components/input/label';
import { RadioRow as RadioRowComponent } from '../components/radioRow';

interface IRadioRowProps {
    description?: string;
    label: string;
    name: string;
    value: any;
}

export const RadioRow = ({ description, label, name, value }: IRadioRowProps) => {
    const { inputId, descId } = useUniqueIds();

    return (
        <Field
            name={name}
            render={(fieldProps) => {
                const state = fieldProps.input.checked ? 'checked' : 'unchecked';

                return (
                    <RadioRowComponent
                        descId={descId}
                        inputId={inputId}
                        state={state}
                        onClick={() => {
                            fieldProps.input.onChange(value);
                        }}
                    >
                        <Flex direction="column">
                            <Label htmlFor={inputId}>
                                <Box>{label}</Box>
                            </Label>
                            {description && (
                                <Box fontWeight="regular" id={descId}>
                                    {description}
                                </Box>
                            )}
                        </Flex>
                    </RadioRowComponent>
                );
            }}
            type={'radio'}
            value={value}
        />
    );
};
