import { useBodyClick } from 'common-front/src/hooks/useBodyClick';
import { useEsc } from 'common-front/src/hooks/useEsc';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import * as React from 'react';

interface IExportFormatSelectorProps {
    onExport(format: string): void;
    onClose(): void;
}

export const ExportFormatSelector = (props: IExportFormatSelectorProps) => {
    useEsc(props.onClose);
    useBodyClick(props.onClose);

    return (
        <Flex
            css={{
                background: 'white',
                borderRadius: '$1',
                boxShadow: '$xs',
                padding: '$1 $3',
                width: '180px'
            }}
            direction="column"
            onClick={(e) => {
                e.nativeEvent.stopImmediatePropagation();
            }}
        >
            <Flex
                align="center"
                css={{
                    borderBottom: '1px solid $gray200',
                    cursor: 'pointer',
                    py: '$2'
                }}
                gap="2"
                onClick={() => {
                    props.onExport('a3');
                }}
            >
                <Box color="gray800" css={{ flex: '1' }}>
                    A3 (420 x 297 mm)
                </Box>
            </Flex>

            <Flex
                align="center"
                css={{
                    cursor: 'pointer',
                    py: '$2'
                }}
                gap="2"
                onClick={() => {
                    props.onExport('a4');
                }}
            >
                <Box color="gray800" css={{ flex: '1' }}>
                    A4 (297 x 210 mm)
                </Box>
            </Flex>
        </Flex>
    );
};
