import { Flex } from 'common/src/designSystem/components/flex';
import { DocumentTextElement } from 'common/src/vo/documentBuilder';
import * as React from 'react';
import { useDocumentBuilderContext } from '../documentBuilderContext';

interface IDocumentBuilderTextProps {
    text: DocumentTextElement;
}

export const DocumentBuilderText = (props: IDocumentBuilderTextProps) => {
    const { selectedElementId, selectElement } = useDocumentBuilderContext();

    return (
        <Flex
            align={props.text.align}
            css={{
                backgroundColor: props.text.hasBackground ? props.text.backgroundColor : 'none',
                border:
                    props.text.borderSize > 0
                        ? `${props.text.borderSize}px solid ${props.text.borderColor}`
                        : '',
                borderRadius: props.text.borderRadius > 0 ? `${props.text.borderRadius}px` : '0',
                color: props.text.color,
                fontFamily: props.text.fontFamily,
                fontSize: `${props.text.fontSize}px`,
                fontWeight: props.text.fontWeight,
                left: `${props.text.left}px`,
                outline: selectedElementId === props.text.id ? '2px solid $primary700' : '',
                overflow: 'hidden',
                position: 'absolute',
                textAlign: props.text.justify,
                textTransform: props.text.textTransform,
                top: `${props.text.top}px`,
                transform: props.text.hasRotate
                    ? `rotate(${props.text.rotateDegree}deg)`
                    : undefined,
                transformOrigin: props.text.hasRotate ? 'top left' : undefined,
                whiteSpace: 'pre-wrap',
                zIndex: props.text.zIndex
            }}
            height={props.text.height}
            justify={props.text.justify}
            width={props.text.width}
            onClick={(e) => {
                e.stopPropagation();
                e.nativeEvent.stopImmediatePropagation();

                selectElement(props.text.id);
            }}
        >
            {props.text.text}
        </Flex>
    );
};
