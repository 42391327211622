import { UserCreateField } from 'common-front/src/components/users/create/userCreateField';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Flex } from 'common/src/designSystem/components/flex';
import { CreatePositionCustomFieldsFragment } from 'common/src/generated/types';
import * as React from 'react';

interface ICreatePositionCustomFieldsProps {
    customFields: CreatePositionCustomFieldsFragment['positionCustomFields'];

    change(name: string, value: any): void;
}

export const CreatePositionCustomFields = (props: ICreatePositionCustomFieldsProps) => {
    const {
        params: { organizationId }
    } = useHeavent();

    return (
        <Flex direction="column" gap="4">
            {props.customFields.nodes.map((customField) => (
                <UserCreateField
                    key={customField.id}
                    change={props.change}
                    display={true}
                    field={customField}
                    fileAcl="public-read"
                    isMandatory={false}
                    organizationId={organizationId}
                    prefix="position.fields."
                />
            ))}
        </Flex>
    );
};
