import { MapLayer, TraceFragment } from 'common/src/generated/types';
import { ICoordinates } from 'common/src/util/map';
import * as React from 'react';
import { Fields } from '../form/fields';
import { Map } from './map';

interface IMapInputProps {
    prefix: string;
    center?: ICoordinates;
    point?: ICoordinates;
    layer?: MapLayer | null;
    traces?: TraceFragment[];
}

export const MapInput = (props: IMapInputProps) => (
    <Fields
        names={[`${props.prefix}latitude`, `${props.prefix}longitude`, `${props.prefix}zoom`]}
        render={(fields) => (
            <Map
                center={props.center}
                coordinatesFieldsProps={{
                    latitudeProps: fields[`${props.prefix}latitude`],
                    longitudeProps: fields[`${props.prefix}longitude`],
                    zoomProps: fields[`${props.prefix}zoom`]
                }}
                layer={props.layer}
                point={props.point}
                traces={props.traces}
            />
        )}
    />
);
