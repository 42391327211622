import { IIcon } from 'common/src/designSystem/components/i';
import { Feature } from 'common/src/generated/types';
import { assertUnreachable } from 'common/src/util/assertUnreachable';

export function featureToIcon(feature: Feature): IIcon {
    switch (feature) {
        case Feature.Accreditation:
            return 'badge-check';
        case Feature.Api:
            return 'code-branch';
        case Feature.Community:
            return 'people-group';
        case Feature.CustomDocument:
            return 'file';
        case Feature.Delegation:
            return 'users';
        case Feature.Documents:
            return 'file';
        case Feature.KmlImport:
            return 'cloud-arrow-up';
        case Feature.Languages:
            return 'language';
        case Feature.Multiform:
            return 'file-lines';
        case Feature.Skidata:
            return 'person-skiing';
        case Feature.Sms:
            return 'message';
        case Feature.Weezevent:
            return 'ticket';
        case Feature.Zapier:
            return 'bolt';
        default:
            return assertUnreachable(feature);
    }
}
