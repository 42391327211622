import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { useUniqueIds } from '../../hooks/useUniqueIds';
import { ITextInputProps } from './input/commonInputProps';
import { Description } from './input/description';
import { Hint } from './input/hint';
import { Label } from './input/label';
import { StyledInputContainer } from './input/styledInputContainer';

export interface IPasswordInputProps extends ITextInputProps {
    autoComplete?: 'new-password' | 'current-password';

    onForgotPasswordClick?(): void;
}

export const PasswordInput = React.forwardRef(
    (
        {
            css,
            hint,
            description,
            icon,
            state,
            onChange,
            label,
            onForgotPasswordClick,
            ...rest
        }: IPasswordInputProps,
        ref: React.Ref<HTMLInputElement>
    ) => {
        const translate = useTranslate();
        const rootRef = React.useRef<any>();
        const [inputType, setInputType] = React.useState<'password' | 'text'>('password');
        const { inputId, descId, errorId } = useUniqueIds();

        return (
            <Flex
                ref={rootRef}
                css={css}
                direction="column"
                width={1}
                onClick={(e) => {
                    e.preventDefault();

                    rootRef.current?.querySelector('input')?.focus();
                }}
            >
                <Flex gap="3" width={1}>
                    <Box css={{ flex: '1' }}>
                        <Label htmlFor={inputId}>{label}</Label>
                    </Box>

                    {typeof onForgotPasswordClick === 'function' && (
                        <Box
                            color="primary700"
                            css={{ cursor: 'pointer' }}
                            fontWeight="medium"
                            onClick={() => {
                                onForgotPasswordClick?.();
                            }}
                        >
                            {translate('mot_de_passe_ou_17690')}
                        </Box>
                    )}
                </Flex>

                <Description id={descId}>{description}</Description>

                {(label || description) && <Spacer height="1" />}

                <StyledInputContainer
                    cursor="text"
                    icon={icon}
                    rightIcon={inputType === 'password' ? 'eye' : 'eye-slash'}
                    state={state}
                    onRightIconClick={(e) => {
                        e.stopPropagation();
                        e.nativeEvent.stopImmediatePropagation();

                        setInputType(inputType === 'password' ? 'text' : 'password');
                    }}
                >
                    <Box
                        color="inherit"
                        css={{
                            flex: '1',
                            height: '100%',
                            '& input': {
                                background: 'transparent',
                                border: 'none',
                                color: '$gray800',
                                height: '100%',
                                outline: 'none',
                                width: '100%'
                            },
                            '& input::placeholder': {
                                color: '$gray500',
                                opacity: 1
                            },
                            '& input::-ms-input-placeholder ': {
                                color: '$gray500',
                                opacity: 1
                            }
                        }}
                    >
                        <input
                            ref={ref}
                            aria-describedby={description ? descId : undefined}
                            aria-errormessage={state === 'error' ? errorId : undefined}
                            aria-invalid={state === 'error'}
                            disabled={state === 'disabled'}
                            id={inputId}
                            type={inputType}
                            onChange={(e) => {
                                onChange(e.target.value);
                            }}
                            {...rest}
                        />
                    </Box>
                </StyledInputContainer>

                <Hint id={errorId} state={state}>
                    {hint}
                </Hint>
            </Flex>
        );
    }
);

PasswordInput.displayName = 'PasswordInput';
