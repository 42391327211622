import { Empty } from 'common-front/src/components/empty/empty';
import { MobileLayout } from 'common-front/src/components/mobileLayout/mobileLayout';
import { useParams } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import { Route, Switch } from 'react-router';
import { DashboardMobile } from '../../dashboard/mobile/dashboardMobile';
import { EventSwitchSheetMobile } from '../../dashboard/mobile/eventSwitchSheetMobile';
import { useOrganizationContext } from '../../organization/organizationContext';
import { PositionsLoaderMobile } from '../../positions/mobile/list/positionsLoaderMobile';
import { PositionSwitchMobile } from '../../positions/mobile/show/positionSwitchMobile';
import { VolunteersLoaderMobile } from '../../volunteers/mobile/list/volunteersLoaderMobile';

export const EventMobile = () => {
    const { organizationId, eventId } = useParams();
    const { user } = useOrganizationContext();

    return (
        <>
            <Switch>
                <Route path={HeaventPaths.DASHBOARD(':organizationId', ':eventId')}>
                    <MobileLayout eventId={eventId} user={user}>
                        <DashboardMobile />
                    </MobileLayout>
                </Route>
                <Route path={HeaventPaths.VOLUNTEERS(':organizationId', ':eventId')}>
                    <MobileLayout eventId={eventId} user={user}>
                        <VolunteersLoaderMobile />
                    </MobileLayout>
                </Route>
                <Route path={HeaventPaths.POSITIONS(':organizationId', ':eventId')}>
                    <MobileLayout eventId={eventId} user={user}>
                        <PositionsLoaderMobile />
                    </MobileLayout>
                </Route>
                <Route path={HeaventPaths.POSITION(':organizationId', ':eventId', ':positionId')}>
                    <PositionSwitchMobile />
                </Route>
                <Route>
                    <Empty path={HeaventPaths.DASHBOARD(organizationId, eventId)} />
                </Route>
            </Switch>
            <Switch>
                <Route path={HeaventPaths.DASHBOARD_EVENT_SWITCH(':organizationId', ':eventId')}>
                    <EventSwitchSheetMobile />
                </Route>
            </Switch>
        </>
    );
};
