import { FormBox } from 'common-front/src/designSystem/components/formBox';
import { Select } from 'common-front/src/designSystem/form/select/select';
import { OrganizationCustomFieldsFrontQuery } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';

interface ISelectFieldFormBoxProps {
    customFields: OrganizationCustomFieldsFrontQuery['organization']['customFields']['nodes'];
}

export const SelectFieldFormBox = ({ customFields }: ISelectFieldFormBoxProps) => {
    const translate = useTranslate();

    return (
        <FormBox
            hideToggle={true}
            initialIsOpen={true}
            subtitle={translate('correspondance_10480')}
            title={translate('_ditez_la_corre_24969')}
        >
            <Select label={translate('sur_quel_champ_17516')} name={'input.fieldId'}>
                <option disabled hidden value={-1}>
                    {translate('s_lectionnez_un_82446')}
                </option>

                {customFields.map((field) => (
                    <option key={field.id} value={field.id}>
                        {field.name}
                    </option>
                ))}
            </Select>
        </FormBox>
    );
};
