import { AccreditationsRightPanel } from 'common-front/src/components/accreditations/rightPanel/accreditationsRightPanel';
import { BadgesPlus } from 'common-front/src/components/badgesPlus/badgesPlus';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { AvatarNameEmail } from 'common/src/components/avatarNameEmail';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { CellControls } from 'common/src/designSystem/components/table/cellControls';
import { Row } from 'common/src/designSystem/components/table/row';
import { AccreditationMembersQuery, SegmentCustomFieldFragment } from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { useService } from 'common/src/util/dependencies/dependencies';
import { AccreditationsPaths } from 'common/src/util/paths/accreditationsPaths';
import { fullName } from 'common/src/vo/accreditationSlot';
import { FieldService } from 'common/src/vo/field';
import { PossibleColumn } from 'common/src/vo/segment';
import { VolunteerDefaultColumns } from 'common/src/vo/segments/volunteersSegmentsService';
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { AccreditationMemberRowDropdown } from './accreditationMemberRowDropdown';

interface IAccreditationMemberRowProps {
    basePath: string;
    canEditMembers: boolean;
    columns: PossibleColumn[];
    customFields: SegmentCustomFieldFragment[];
    isEventAdmin?: boolean;
    member: AccreditationMembersQuery['organization']['event']['volunteersRegistrations']['nodes'][number];
    showDeleteFromEvent: boolean;
    showDocumentsDownload: boolean;

    reload(): void;
}

export const AccreditationMemberRow = ({
    basePath,
    canEditMembers,
    columns,
    customFields,
    isEventAdmin,
    member,
    showDeleteFromEvent,
    showDocumentsDownload,
    reload
}: IAccreditationMemberRowProps) => {
    const {
        history,
        params: { organizationId, eventId, accreditationId }
    } = useHeavent();

    const dateTimeService = useService(DateTimeService);
    const fieldService = useService(FieldService);

    const slugToCustomField = React.useMemo(
        () => new Map(customFields.map((cf) => [cf.slug, cf])),
        [customFields]
    );
    const accreditationPanelPath = `${basePath}/member-panel/${member.id}/accreditations`;

    return (
        <>
            <Row
                key={member.id}
                css={{
                    background: 'white',
                    cursor: 'pointer',
                    '&:hover': {
                        background: '$gray100'
                    }
                }}
                onClick={() =>
                    history.push(
                        AccreditationsPaths.ACCREDITATION_MEMBER_INFORMATION({
                            organizationId,
                            eventId,
                            accreditationId,
                            userInfoId: member.userInfo.id
                        })
                    )
                }
            >
                {columns.map((column) => {
                    const customField = slugToCustomField.get(column.slug);

                    if (column.slug === VolunteerDefaultColumns.Name) {
                        return (
                            <Cell key={column.slug}>
                                <AvatarNameEmail hasName={true} userInfo={member.userInfo} />
                            </Cell>
                        );
                    } else if (column.slug === VolunteerDefaultColumns.AccreditationsSlots) {
                        const badges = member.accreditationsUsersInfos.map((aui) => ({
                            id: aui.accreditationSlot.id,
                            color: aui.accreditation.color,
                            icon: aui.accreditation.icon,
                            text: fullName(
                                dateTimeService,
                                aui.accreditationSlot,
                                aui.accreditation.name
                            )
                        }));

                        return (
                            <Cell key={column.slug}>
                                <BadgesPlus
                                    badges={badges}
                                    onClick={() => {
                                        history.push(`${accreditationPanelPath}/assigned`);
                                    }}
                                />
                            </Cell>
                        );
                    } else {
                        return (
                            <Cell key={column.slug}>
                                {customField
                                    ? fieldService.getValueString(customField, member.userInfo, {
                                          fileReturnValue: 'name'
                                      })
                                    : ''}
                            </Cell>
                        );
                    }
                })}

                <CellControls justify="center">
                    <AccreditationMemberRowDropdown
                        canEditMembers={canEditMembers}
                        member={member.userInfo}
                        reload={reload}
                        showDeleteFromEvent={showDeleteFromEvent}
                        showDocumentsDownload={showDocumentsDownload}
                    />
                </CellControls>
            </Row>

            <Switch>
                <Route path={accreditationPanelPath}>
                    <AccreditationsRightPanel
                        basePath={accreditationPanelPath}
                        eventId={eventId}
                        showActions={!!isEventAdmin}
                        userInfoId={member.userInfo.id}
                        volunteerRegistration={member}
                        onClose={() => history.push(basePath)}
                    />
                </Route>
            </Switch>
        </>
    );
};
