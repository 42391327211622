import { useBodyClick } from 'common-front/src/hooks/useBodyClick';
import { useEsc } from 'common-front/src/hooks/useEsc';
import { Images } from 'common-front/src/util/assets';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { theme } from 'common/src/designSystem/components/stitches';
import { MapLayer } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';

interface ILayerProps {
    imageSrc: string;
    isSelected: boolean;
    name: string;

    onClick(): void;
}

const Layer = (props: ILayerProps) => (
    <Flex
        align="center"
        css={{
            cursor: 'pointer',
            py: '$2',
            '&:not(:last-child)': {
                borderBottom: '1px solid $gray200'
            }
        }}
        gap="2"
        onClick={props.onClick}
    >
        <Box
            css={{
                borderRadius: '$1',
                height: '32px',
                overflow: 'hidden',
                width: '32'
            }}
        >
            <img alt="Layer" height="32px" src={props.imageSrc} width="32px" />
        </Box>

        <Box css={{ flex: '1' }}>{props.name}</Box>

        {props.isSelected && (
            <Flex
                align="center"
                css={{
                    fontSize: '$textMd'
                }}
                justify="center"
            >
                <I
                    icon="circle-check"
                    iconStyle="duotone"
                    primaryColor="white"
                    secondaryColor={theme.colors.primary600.value}
                />
            </Flex>
        )}
    </Flex>
);

interface ILayerSelectorProps {
    selectedLayer: MapLayer;

    onChange(layer: MapLayer): void;
    onClose(): void;
}

export const LayerSelector = (props: ILayerSelectorProps) => {
    const translate = useTranslate();

    useEsc(props.onClose);
    useBodyClick(props.onClose);

    return (
        <Flex
            css={{
                background: 'white',
                borderRadius: '$1',
                boxShadow: '$xs',
                padding: '$1 $3',
                width: '180px'
            }}
            direction="column"
            onClick={(e) => {
                e.nativeEvent.stopImmediatePropagation();
            }}
        >
            <Layer
                imageSrc={Images.Maps.GooglePlan}
                isSelected={props.selectedLayer === MapLayer.Google}
                name="Plan"
                onClick={() => {
                    props.onChange(MapLayer.Google);
                }}
            />

            <Layer
                imageSrc={Images.Maps.GoogleSatellite}
                isSelected={props.selectedLayer === MapLayer.GoogleSatellite}
                name={translate('satellite_82253')}
                onClick={() => {
                    props.onChange(MapLayer.GoogleSatellite);
                }}
            />

            <Layer
                imageSrc={Images.Maps.IgnPlan}
                isSelected={props.selectedLayer === MapLayer.IgnPlan}
                name="IGN Plan"
                onClick={() => {
                    props.onChange(MapLayer.IgnPlan);
                }}
            />
        </Flex>
    );
};
