import { ProgressBar } from 'common-front/src/designSystem/components/progressBar';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { Row } from 'common/src/designSystem/components/table/row';
import { DashboardMobileQuery } from 'common/src/generated/types';
import { useHistory, useParams, useTranslate } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';

interface IDashboardPosition {
    position: DashboardMobileQuery['event']['positions']['nodes'][number];
}

export const DashboardPosition = ({ position }: IDashboardPosition) => {
    const translate = useTranslate();
    const history = useHistory();
    const { organizationId, eventId } = useParams();
    const onClick = () => {
        history.push(HeaventPaths.POSITION(organizationId, eventId, position.id));
    };

    return (
        <Row key={position.id} css={{ cursor: 'pointer' }} onClick={onClick}>
            <Cell>
                <Flex direction="column" gap="2" width={1}>
                    <Flex gap="3">
                        <Box color="gray700" css={{ flex: '1' }}>
                            {position.name}
                        </Box>

                        <Box color="gray700">
                            {translate(
                                '_1_2_membres_04485',
                                position.assignedResources,
                                position.resources
                            )}
                        </Box>
                    </Flex>

                    <Box width={1}>
                        <ProgressBar percent={position.assignedResources / position.resources} />
                    </Box>
                </Flex>
            </Cell>
        </Row>
    );
};
