import { Button } from 'common-front/src/designSystem/components/button';
import { Dropdown } from 'common-front/src/designSystem/components/dropdown/dropdown';
import { ItemIcon } from 'common-front/src/designSystem/components/dropdown/item';
import { Menu } from 'common-front/src/designSystem/components/dropdown/menu';
import { Trigger } from 'common-front/src/designSystem/components/dropdown/trigger';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useSegmentsContext } from 'common-front/src/segments/segmentsContext';
import { AccreditationsRendering } from 'common-front/src/vo/accreditationsRendering';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import { CreateAccreditationCategory } from '../../accreditationsCategories/create/createUpdateAccreditationCategory';

interface IAccreditationsHeaderProps {
    name: string;

    reload(): void;
}

export const AccreditationsHeader = (props: IAccreditationsHeaderProps) => {
    const {
        translate,
        params: { organizationId, eventId }
    } = useHeavent();
    const { rendering, setRendering } = useSegmentsContext();
    const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
    const [isCreateAccreditationCategoryOpen, setIsCreateAccreditationCategoryOpen] =
        React.useState(false);

    return (
        <Flex gap="4" width={1}>
            <Box color="gray900" css={{ flex: '1' }} fontSize="displaySm" fontWeight="semiBold">
                {props.name}
            </Box>

            <Dropdown>
                <Trigger>
                    <Button color="white" leftIcon="table">
                        {rendering === AccreditationsRendering.SlotsList
                            ? translate('vue_liste_cr_n_47406')
                            : translate('vue_liste_56882')}
                    </Button>
                </Trigger>

                <Menu placement="bottom-end">
                    <ItemIcon
                        icon="table"
                        onClick={() => {
                            setRendering(AccreditationsRendering.List);
                        }}
                    >
                        {translate('vue_liste_56882')}
                    </ItemIcon>

                    <ItemIcon
                        icon="table"
                        onClick={() => {
                            setRendering(AccreditationsRendering.SlotsList);
                        }}
                    >
                        {translate('vue_liste_cr_n_47406')}
                    </ItemIcon>
                </Menu>
            </Dropdown>

            <Button to={HeaventPaths.CREATE_ACCREDITATION(organizationId, eventId)}>
                {translate('cr_er_une_accr_33475')}
            </Button>

            <Dropdown isOpen={isDropdownOpen} onStateChange={setIsDropdownOpen}>
                <Trigger>
                    <Button color="white" leftIcon="ellipsis-vertical" />
                </Trigger>

                <Menu placement="bottom-end">
                    <ItemIcon
                        icon="plus"
                        onClick={() => {
                            setIsDropdownOpen(false);
                            setIsCreateAccreditationCategoryOpen(true);
                        }}
                    >
                        {translate('cr_er_une_cat_g_20051')}
                    </ItemIcon>
                </Menu>
            </Dropdown>

            {isCreateAccreditationCategoryOpen && (
                <CreateAccreditationCategory
                    eventId={eventId}
                    onClose={() => {
                        setIsCreateAccreditationCategoryOpen(false);
                    }}
                    onSuccess={props.reload}
                />
            )}
        </Flex>
    );
};
