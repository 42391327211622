import { File } from 'common-front/src/designSystem/components/file/file';
import { Badge } from 'common/src/designSystem/components/badge';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import {
    CampaignQuery,
    CustomBadge,
    DocumentEmailPreviewFragment
} from 'common/src/generated/types';
import { isNonEmptyArray } from 'common/src/util/array';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { Emptyable } from 'common/src/util/emptyable';
import { getDisplayName } from 'common/src/vo/customBadge';
import { ALL_USER_DOCUMENT_TYPE } from 'common/src/vo/documentType';
import * as React from 'react';
import { CampaignPreviewTo } from '../../campaigns/show/campaignPreviewTo';

interface IEmailPreviewProps {
    attachments?: Emptyable<string[]>;
    createdBy?: Emptyable<string>;
    content?: Emptyable<string>;
    documents?: DocumentEmailPreviewFragment[];
    from?: Emptyable<string>;
    subject?: Emptyable<string>;
    to?: CampaignQuery['organization']['campaign'];
}

export const EmailPreview = (props: IEmailPreviewProps) => {
    const translate = useTranslate();
    const iframeRef = React.useCallback((node) => {
        if (node) {
            node.onload = () => {
                const height = node.contentDocument.body.scrollHeight;
                node.style.height = `${height}px`;
                node.contentDocument.body.style.margin = '0';
                node.contentDocument.body.style.overflow = 'hidden';
                node.parentElement.style.height = `${height + 32 * 2}px`;
            };
        }
    }, []);

    return (
        <Flex
            css={{ background: '$gray200', minHeight: '100%', py: '$6' }}
            justify="center"
            width={1}
        >
            <Flex
                css={{
                    background: 'white',
                    borderRadius: '$2',
                    boxShadow: '$md',
                    minHeight: '100%',
                    paddingTop: '$6'
                }}
                direction="column"
                gap="3"
                width={664}
            >
                {props.createdBy && (
                    <>
                        <Flex css={{ margin: '0 $6' }} gap="1">
                            <Box color="gray500">{translate('cr_e_par_16667')}</Box>
                            <Box color="gray800">{props.createdBy}</Box>
                        </Flex>

                        <Box
                            css={{
                                borderTop: '1px solid $gray200',
                                height: '1px',
                                margin: '0 $6'
                            }}
                        />
                    </>
                )}

                {props.from && (
                    <>
                        <Flex css={{ margin: '0 $6' }} gap="1">
                            <Box color="gray500">{translate('de_70487')}</Box>
                            <Box color="gray800">{props.from}</Box>
                        </Flex>

                        <Box
                            css={{
                                borderTop: '1px solid $gray200',
                                height: '1px',
                                margin: '0 $6'
                            }}
                        />
                    </>
                )}

                {props.to && (
                    <>
                        <Box css={{ margin: '0 $6' }} width={1}>
                            <CampaignPreviewTo campaign={props.to} />
                        </Box>

                        <Box
                            css={{
                                borderTop: '1px solid $gray200',
                                height: '1px',
                                margin: '0 $6'
                            }}
                        />
                    </>
                )}

                {props.subject && (
                    <Flex css={{ margin: '0 $6' }} gap="1">
                        <Box color="gray500">{translate('objet_86482')}</Box>
                        <Box color="gray800" fontWeight="medium">
                            {props.subject}
                        </Box>
                    </Flex>
                )}

                {props.content && (
                    <Flex
                        css={{
                            background: '$gray100',
                            borderBottomLeftRadius: '$2',
                            borderBottomRightRadius: '$2',
                            flexGrow: '1',
                            padding: '$7'
                        }}
                        direction="column"
                        gap="6"
                    >
                        <Box
                            css={{
                                minHeight: '300px',
                                marginTop: '$3',
                                '& iframe': {
                                    background: 'white',
                                    border: 'none',
                                    borderRadius: '$2',
                                    overflow: 'hidden',
                                    width: '100%'
                                }
                            }}
                            width={1}
                        >
                            <iframe ref={iframeRef} srcDoc={props.content} />
                        </Box>

                        {(isNonEmptyArray(props.attachments) ||
                            isNonEmptyArray(props.documents)) && (
                            <Flex direction="column" gap="2">
                                <Box font="gray900 textXl semiBold">
                                    {translate('pi_ces_jointes_36761')}
                                </Box>

                                <Flex gap="2">
                                    {(props.attachments || []).map((attachment) => (
                                        <Badge key={attachment} color="white" size="md">
                                            {ALL_USER_DOCUMENT_TYPE.includes(attachment as any)
                                                ? translate(attachment)
                                                : getDisplayName(attachment as CustomBadge)}
                                        </Badge>
                                    ))}
                                </Flex>

                                <Flex direction="column" gap="3">
                                    {(props.documents || []).map((document) => (
                                        <File
                                            key={document.id}
                                            acl="public-read"
                                            document={document}
                                        />
                                    ))}
                                </Flex>
                            </Flex>
                        )}
                    </Flex>
                )}
            </Flex>
        </Flex>
    );
};
