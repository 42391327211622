import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { HeaderRow } from 'common/src/designSystem/components/table/headerRow';
import { Table } from 'common/src/designSystem/components/table/table';
import { EmailType } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { EmailTypeRow } from '../../../emails/templates/emailTypeRow';
import { Template } from '../../../emails/templates/template';
import { useFormContext } from '../../show/formContext';

export const FormEmails = () => {
    const translate = useTranslate();
    const { organizationId, eventId, formId, isSaving, setIsSaving } = useFormContext();
    const [emailType, setEmailType] = React.useState(EmailType.FormSubmit);

    React.useEffect(() => {
        setIsSaving(false);
    }, [isSaving]);

    return (
        <Flex justify="center" width={1}>
            <Flex direction="column" width="$container">
                <Spacer height="8" />

                <Flex gap="7">
                    <Flex css={{ flex: '1' }} direction="column">
                        <Box color="gray900" fontSize="textLg" fontWeight="medium">
                            {translate('liste_des_e_mai_73508')}
                        </Box>

                        <Spacer height="6" />

                        <Table>
                            <HeaderRow>
                                <HeaderCell>{translate('type_d_e_mail_06577')}</HeaderCell>
                            </HeaderRow>

                            <EmailTypeRow
                                isSelected={emailType === EmailType.FormSubmit}
                                subtitle={translate('cet_e_mail_est_29404')}
                                title={translate('remplissage_du_09925')}
                                onClick={() => setEmailType(EmailType.FormSubmit)}
                            />

                            <EmailTypeRow
                                isSelected={emailType === EmailType.Code}
                                subtitle={translate('cet_e_mail_est_49525')}
                                title={translate('code_20843')}
                                onClick={() => setEmailType(EmailType.Code)}
                            />

                            {eventId && (
                                <EmailTypeRow
                                    isSelected={emailType === EmailType.FormInvite}
                                    subtitle={translate('cet_email_est_e_46197')}
                                    title={translate('invitation_d_un_88753')}
                                    onClick={() => setEmailType(EmailType.FormInvite)}
                                />
                            )}
                        </Table>
                    </Flex>

                    <Box
                        css={{
                            borderRight: '1px solid $gray200',
                            width: '1px'
                        }}
                    />

                    <Flex css={{ flex: '1', py: '$3' }}>
                        <Template
                            emailType={emailType}
                            eventId={eventId}
                            formId={formId}
                            organizationId={organizationId}
                            source={null}
                        />
                    </Flex>
                </Flex>

                <Spacer height="8" />
            </Flex>
        </Flex>
    );
};
