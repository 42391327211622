import { DetailBox } from 'common-front/src/designSystem/components/detailBox';
import { LeaderInfosFragment } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { PositionCategoryEditableLeaders } from './positionCategoryEditableLeaders';
import { PositionCategoryLeadersList } from './positionCategoryLeadersList';

interface IPositionCategoryReadOnlyLeadersProps {
    leaders: LeaderInfosFragment[];
}

type PositionCategoryLeadersProps =
    | IPositionCategoryReadOnlyLeadersProps
    | React.ComponentProps<typeof PositionCategoryEditableLeaders>;

export const PositionCategoryLeaders = (props: PositionCategoryLeadersProps) => {
    const translate = useTranslate();

    if ('onSave' in props) {
        return <PositionCategoryEditableLeaders {...props} />;
    } else {
        return (
            <DetailBox title={translate('responsables_84923')}>
                <PositionCategoryLeadersList leaders={props.leaders} />
            </DetailBox>
        );
    }
};
