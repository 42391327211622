import { useHeavent } from 'common-front/src/hooks/useHeavent';
import {
    getAccreditationsSlotsValues,
    getPositionsSlotsValues
} from 'common-front/src/segments/filters/data/useFilterLoader';
import { CustomDocumentInput, CustomDocumentQuery } from 'common/src/generated/types';
import { CustomDocumentInputService } from 'common/src/input/customDocumentInput';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { IntervalService } from 'common/src/services/intervalService';
import { useService } from 'common/src/util/dependencies/dependencies';
import { slug as slugify } from 'common/src/util/string';
import { UUID, uuidv4 } from 'common/src/util/uuid';
import { CustomDocument, DocumentElement, DocumentPage } from 'common/src/vo/documentBuilder';
import { produce } from 'immer';
import React from 'react';
import { useCustomDocumentConditionsQuery } from '../generated/graphqlHooks';

interface IDocumentBuilderContext {
    accreditationsOptions: React.ReactNode;
    customDocument: CustomDocument;
    customFields: CustomDocumentQuery['organization']['customFields']['nodes'];
    population: CustomDocumentQuery['organization']['population'];
    positionsOptions: React.ReactNode;
    selectedElementId: UUID | null;
    selectedPageId: UUID | null;
    tab: 'parameters' | 'design';

    addImageElement(): void;
    addPage(): void;
    addTextElement(): void;
    cleanSelecteds(): void;
    deleteElement(id: UUID): void;
    duplicateElement(id: UUID): void;
    getSelectedElement(): DocumentElement | null;
    getSelectedPage(): DocumentPage | null;
    removePage(id: UUID): void;
    selectElement(id: UUID): void;
    selectPage(id: UUID): void;
    setDimensions(height: number, width: number): void;
    setName(name: string): void;
    setPageName(name: string): void;
    setPopulations(ids: number[]): void;
    setSlug(slug: string): void;
    setTab(tab: 'parameters' | 'design'): void;
    updateElementProperty(id: UUID, key: string, value: any): void;
}

const DocumentBuilderContext = React.createContext<IDocumentBuilderContext>({} as any);

interface IDocumentBuilderContextProviderProps {
    children: React.ReactNode;
    customFields: CustomDocumentQuery['organization']['customFields']['nodes'];
    initialCustomDocument: CustomDocumentInput;
    population: CustomDocumentQuery['organization']['population'];
}

export const DocumentBuilderContextProvider = (props: IDocumentBuilderContextProviderProps) => {
    const {
        translate,
        params: { eventId }
    } = useHeavent();
    const dateTimeService = useService(DateTimeService);
    const intervalService = useService(IntervalService);
    const customDocumentInput = useService(CustomDocumentInputService);
    const [customDocument, setCustomDocument] = React.useState<CustomDocument>(
        props.initialCustomDocument
    );
    const [selectedElementId, setSelectedElementId] = React.useState<UUID | null>(null);
    const [selectedPageId, setSelectedPageId] = React.useState<UUID | null>(null);
    const [tab, setTab] = React.useState<'parameters' | 'design'>('parameters');
    const setName = React.useCallback(
        (newName: string) => {
            setCustomDocument(
                produce((currentDocument) => {
                    currentDocument.name = newName;
                })
            );
        },
        [customDocument, setCustomDocument]
    );
    const setPageName = React.useCallback(
        (newName: string) => {
            setCustomDocument(
                produce((currentDocument) => {
                    currentDocument.configuration.pages.forEach((page) => {
                        if (page.id === selectedPageId) {
                            page.name = newName;
                        }
                    });
                })
            );
        },
        [selectedPageId, setCustomDocument]
    );
    const setPopulations = React.useCallback(
        (ids: number[]) => {
            setCustomDocument(
                produce((currentDocument) => {
                    currentDocument.populationsIds = ids;
                })
            );
        },
        [setCustomDocument]
    );
    const setSlug = React.useCallback(
        (slug: string) => {
            setCustomDocument(
                produce((currentDocument) => {
                    currentDocument.slug = slugify(slug, { spaceReplacer: '' });
                })
            );
        },
        [setCustomDocument]
    );
    const setDimensions = React.useCallback(
        (height: number, width: number) => {
            setCustomDocument(
                produce((currentDocument) => {
                    currentDocument.configuration.height = height;
                    currentDocument.configuration.width = width;
                })
            );
        },
        [customDocument, setCustomDocument]
    );
    const cleanSelecteds = React.useCallback(() => {
        setSelectedElementId(null);
        setSelectedPageId(null);
    }, [setSelectedElementId, setSelectedPageId]);
    const getSelectedElement = React.useCallback(
        () =>
            customDocument.configuration.pages.flatMap((page) =>
                page.elements.flatMap((element) =>
                    element.id === selectedElementId ? [element] : []
                )
            )[0] || null,
        [customDocument, selectedElementId]
    );
    const getSelectedPage = React.useCallback(
        () => customDocument.configuration.pages.find((p) => p.id === selectedPageId) || null,
        [customDocument, selectedPageId]
    );
    const addPage = React.useCallback(() => {
        setCustomDocument(
            produce((currentDocument) => {
                currentDocument.configuration.pages.push({
                    id: uuidv4(),
                    elements: []
                });
            })
        );
    }, [setCustomDocument]);
    const removePage = React.useCallback(
        (id: UUID) => {
            setCustomDocument(
                produce((currentDocument) => {
                    currentDocument.configuration.pages =
                        currentDocument.configuration.pages.filter((p) => p.id !== id);
                })
            );
        },
        [setCustomDocument]
    );
    const addTextElement = React.useCallback(() => {
        const newElementId = uuidv4();

        setCustomDocument(
            produce((currentDocument) => {
                currentDocument.configuration.pages.forEach((page) => {
                    if (page.id === selectedPageId) {
                        page.elements.push(
                            customDocumentInput.getTextElementDefault(
                                newElementId,
                                page.elements.length
                            )
                        );
                    }
                });
            })
        );
        setSelectedElementId(newElementId);
    }, [customDocument, selectedPageId, setCustomDocument]);
    const addImageElement = React.useCallback(() => {
        const newElementId = uuidv4();

        setCustomDocument(
            produce((currentDocument) => {
                currentDocument.configuration.pages.forEach((page) => {
                    if (page.id === selectedPageId) {
                        page.elements.push(
                            customDocumentInput.getImageElementDefault(
                                newElementId,
                                page.elements.length
                            )
                        );
                    }
                });
            })
        );
        setSelectedElementId(newElementId);
    }, [customDocument, selectedPageId, setCustomDocument, setSelectedElementId]);
    const deleteElement = React.useCallback(
        (id: UUID) => {
            setCustomDocument(
                produce((currentDocument) => {
                    currentDocument.configuration.pages.forEach((page) => {
                        page.elements = page.elements.filter((e) => e.id !== id);
                    });
                })
            );
            setSelectedElementId(null);
        },
        [customDocument, setCustomDocument, setSelectedElementId]
    );
    const duplicateElement = React.useCallback(
        (id: UUID) => {
            const newId = uuidv4();

            setCustomDocument(
                produce((currentDocument) => {
                    currentDocument.configuration.pages.forEach((page) => {
                        const element = page.elements.find((e) => e.id === id);

                        if (element) {
                            page.elements.push({
                                ...element,
                                id: newId,
                                name: translate('copie_de_1_62900', element.name)
                            });
                        }
                    });
                })
            );
            setSelectedElementId(newId);
        },
        [customDocument, setCustomDocument, setSelectedElementId]
    );
    const selectElement = React.useCallback(
        (id: UUID) => {
            const pageId = customDocument.configuration.pages.find((page) =>
                page.elements.some((e) => e.id === id)
            )?.id;

            if (pageId) {
                setSelectedElementId(id);
                setSelectedPageId(pageId);
            }
        },
        [customDocument, setSelectedElementId, setSelectedPageId]
    );
    const selectPage = React.useCallback(
        (id: UUID) => {
            setSelectedPageId(id);
            setSelectedElementId(null);
            setTab('design');
        },
        [setSelectedElementId, setSelectedPageId, setTab]
    );
    const updateElementProperty = React.useCallback(
        (id: UUID, key: string, value: any) => {
            setCustomDocument(
                produce((currentDocument) => {
                    const element = currentDocument.configuration.pages.flatMap((page) =>
                        page.elements.flatMap((element) => (element.id === id ? [element] : []))
                    )[0];

                    if (element) {
                        (element as any)[key] = value;
                    }
                })
            );
        },
        [customDocument]
    );
    const { data } = useCustomDocumentConditionsQuery({ eventId });
    const accreditationsOptions = React.useMemo(
        () =>
            getAccreditationsSlotsValues(
                dateTimeService,
                data.event?.accreditationsSlots.nodes ?? []
            ).map(({ name, values }, index) => (
                <optgroup key={index} label={name}>
                    {values.map(({ id, name }) => (
                        <option key={id} value={id}>
                            {name}
                        </option>
                    ))}
                </optgroup>
            )),
        [data.event]
    );
    const positionsOptions = React.useMemo(
        () =>
            getPositionsSlotsValues(intervalService, data.event?.positionsSlots.nodes ?? []).map(
                ({ name, values }, index) => (
                    <optgroup key={index} label={name}>
                        {values.map(({ id, name }) => (
                            <option key={id} value={id}>
                                {name}
                            </option>
                        ))}
                    </optgroup>
                )
            ),
        [data.event]
    );

    return (
        <DocumentBuilderContext.Provider
            value={{
                accreditationsOptions,
                addImageElement,
                addPage,
                addTextElement,
                cleanSelecteds,
                customDocument,
                customFields: props.customFields,
                deleteElement,
                duplicateElement,
                getSelectedElement,
                getSelectedPage,
                population: props.population,
                positionsOptions,
                removePage,
                selectElement,
                selectPage,
                selectedElementId,
                selectedPageId,
                setDimensions,
                setName,
                setPageName,
                setPopulations,
                setSlug,
                setTab,
                tab,
                updateElementProperty
            }}
        >
            {props.children}
        </DocumentBuilderContext.Provider>
    );
};

export function useDocumentBuilderContext() {
    return React.useContext(DocumentBuilderContext);
}
