import { Card } from 'common-front/src/designSystem/components/card';
import { Checkbox } from 'common-front/src/designSystem/components/checkbox';
import { Badge } from 'common/src/designSystem/components/badge';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { IntervalService } from 'common/src/services/intervalService';
import { toggle } from 'common/src/util/array';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { SplitSlotsCategory } from 'common/src/vo/positionSlot';
import * as React from 'react';
import { useAssignmentContext } from './assignmentContext';
import { AssignmentSlot } from './assignmentSlot';

interface IAssignmentCategoryProps {
    category: SplitSlotsCategory;
}

export const AssignmentCategory = (props: IAssignmentCategoryProps) => {
    const translate = useTranslate();
    const intervalService = useService(IntervalService);
    const { positionsIds, positionsCategoriesIds, setPositionsIds, setPositionsCategoriesIds } =
        useAssignmentContext();
    const toggleCategory = () => {
        setPositionsCategoriesIds(toggle(positionsCategoriesIds, props.category.id));
    };

    return (
        <Card
            css={{ background: 'white' }}
            header={
                <Flex align="center">
                    <Checkbox
                        state={
                            positionsCategoriesIds.includes(props.category.id)
                                ? 'checked'
                                : 'unchecked'
                        }
                        onClick={toggleCategory}
                    />

                    <Spacer width="3" />

                    <Box
                        color="gray800"
                        css={{ cursor: 'pointer' }}
                        fontSize="textMd"
                        fontWeight="medium"
                        onClick={toggleCategory}
                    >
                        {props.category.name}
                    </Box>

                    <Spacer width="3" />

                    <Badge color="warning" size="md">
                        {translate('_1_membre_manq_31014', props.category.resources)}
                    </Badge>

                    <Spacer width="3" />

                    <Box css={{ flex: '1' }} />
                </Flex>
            }
            initialIsOpen={true}
        >
            <Flex css={{ background: '$gray100' }} direction="column" gap="2">
                {props.category.positions.map((position) => (
                    <AssignmentSlot
                        key={position.id}
                        assignedResources={position.assignedResources}
                        color={position.color}
                        icon={position.icon}
                        isSelected={positionsIds.includes(position.id)}
                        neededResources={position.resources}
                        overlaps={false}
                        subtitle={intervalService.toDisplayString(position.range)}
                        title={position.name}
                        onClick={() => {
                            setPositionsIds(toggle(positionsIds, position.id));
                        }}
                    />
                ))}
            </Flex>
        </Card>
    );
};
