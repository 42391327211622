import { DateInput } from 'common-front/src/designSystem/components/date/dateInput';
import { TableFilters } from 'common-front/src/designSystem/components/tableFilters';
import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { usePaginationInfos } from 'common-front/src/hooks/usePaginationInfos';
import { useStateDebounce } from 'common-front/src/hooks/useStateDebounce';
import { Box } from 'common/src/designSystem/components/box';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { RowSkeleton } from 'common/src/designSystem/components/table/rowSkeleton';
import { isNonEmptyString } from 'common/src/util/string';
import { DateTime } from 'luxon';
import * as React from 'react';
import { usePositionSlotsQuery } from '../../../generated/graphqlHooks';
import { PositionSlotsSlotRow } from './positionSlotsSlotRow';

export const PositionSlots = () => {
    const {
        translate,
        params: { eventId, positionId }
    } = useHeavent();
    const [name, nameDebounced, setName] = useStateDebounce('');
    const [date, setDate] = React.useState(DateTime.invalid('Invalid'));
    const [offset, setOffset] = React.useState(0);
    const { data, isLoading, reload } = usePositionSlotsQuery({
        eventId,
        positionId,
        name: isNonEmptyString(nameDebounced) ? nameDebounced : undefined,
        date: date.isValid ? date : undefined,
        offset
    });
    const { numberOfPages, totalCount } = usePaginationInfos(data.event?.positionsSlots);

    return (
        <TableFilters
            filters={
                <>
                    <Box width={320}>
                        <TextInput
                            icon="magnifying-glass"
                            placeholder={translate('rechercher_un_c_70424')}
                            value={name}
                            onChange={setName}
                        />
                    </Box>

                    <Box width={200}>
                        <DateInput
                            max={data.event?.endAt}
                            min={data.event?.startAt}
                            value={date}
                            onChange={setDate}
                        />
                    </Box>
                </>
            }
            headerCells={
                <>
                    <HeaderCell>{translate('nom_du_cr_neau_54351')}</HeaderCell>
                    <HeaderCell>{translate('remplissage_75916')}</HeaderCell>
                    <HeaderCell width={100} />
                </>
            }
            numberOfPages={numberOfPages}
            offset={offset}
            rows={
                isLoading ? (
                    <>
                        <RowSkeleton bx={true} />
                        <RowSkeleton bx={true} />
                        <RowSkeleton bx={true} />
                    </>
                ) : (
                    <>
                        {data.event.positionsSlots.nodes.map((slot) => (
                            <PositionSlotsSlotRow
                                key={slot.id}
                                event={data.event}
                                reload={reload}
                                slot={slot}
                            />
                        ))}
                    </>
                )
            }
            setOffset={setOffset}
            title={translate('liste_des_cr_ne_86148')}
            totalCount={totalCount}
        />
    );
};
