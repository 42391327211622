import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { EventId, OrganizationQuery } from 'common/src/generated/types';
import * as React from 'react';
import { ReactNode } from 'react';
import { NavigationBar } from './navigationBar';

export const MobileLayout = ({
    children,
    user,
    eventId
}: {
    children: ReactNode;
    user: OrganizationQuery['user'];
    eventId: EventId;
}) => (
    <Flex css={{ height: '100%' }} direction="column">
        <Box css={{ flex: 1, overflow: 'auto' }}>{children}</Box>
        <NavigationBar eventId={eventId} user={user} />
    </Flex>
);
