import { QueryLoading } from 'common-front/src/components/graphql/queryLoading';
import { signInSso } from 'common-front/src/util/aws/cognito';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import { useTokensQuery } from '../generated/graphqlHooks';

export const CognitoCallback = () => {
    const [, code] = location.search.substr(1).split('=');
    const { data, loader } = useTokensQuery({ code });

    return (
        <QueryLoading
            loader={loader}
            onLoad={() => {
                signInSso(data.userTokens);

                window.location.href = HeaventPaths.HOME;
            }}
        />
    );
};
