import { closeOtherDropdowns } from 'common-front/src/designSystem/components/dropdown/dropdown';
import { DropdownEllipsis } from 'common-front/src/designSystem/components/dropdown/dropdownEllipsis';
import { ItemIcon } from 'common-front/src/designSystem/components/dropdown/item';
import { useAccreditationDuplicate } from 'common-front/src/vo/accreditation';
import { AccreditationsQuery, EventId, OrganizationId } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import { DeleteAccreditation } from '../deleteAccreditation';

interface IAccreditationRowDropdownProps {
    accreditation: AccreditationsQuery['event']['accreditations']['nodes'][0];
    eventId: EventId;
    organizationId: OrganizationId;

    reload(): void;
}

export const AccreditationRowDropdown = (props: IAccreditationRowDropdownProps) => {
    const translate = useTranslate();
    const accreditationDuplicate = useAccreditationDuplicate();
    const [isDeleteOpen, setIsDeleteOpen] = React.useState(false);

    return (
        <>
            <DropdownEllipsis>
                <ItemIcon
                    icon="pen"
                    to={HeaventPaths.EDIT_ACCREDITATION(
                        props.organizationId,
                        props.eventId,
                        props.accreditation.id
                    )}
                >
                    {translate('_diter_62574')}
                </ItemIcon>

                <ItemIcon
                    icon="clone"
                    onClick={async () => {
                        await accreditationDuplicate(props.accreditation.id);
                    }}
                >
                    {translate('dupliquer_28256')}
                </ItemIcon>

                <ItemIcon
                    color="red"
                    icon="trash-can"
                    onClick={(e) => {
                        setIsDeleteOpen(true);
                        closeOtherDropdowns(e.target);
                    }}
                >
                    {translate('supprimer_43083')}
                </ItemIcon>
            </DropdownEllipsis>

            {isDeleteOpen && (
                <DeleteAccreditation
                    accreditation={props.accreditation}
                    onClose={() => {
                        setIsDeleteOpen(false);
                    }}
                    onSuccess={props.reload}
                />
            )}
        </>
    );
};
