import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { Emptyable } from 'common/src/util/emptyable';
import * as React from 'react';
import * as yup from 'yup';
import { FormModal } from '../designSystem/components/formModal';
import { TextInput } from '../designSystem/form/textInput';

interface ICreateUpdateCategoryResult {
    id: number;
    name: string;
}

interface ICreateUpdateCategoryModalProps {
    initialName: Emptyable<string>;
    isEdit: boolean;

    mutate(name: string): Promise<ICreateUpdateCategoryResult>;
    onClose(): void;
    onSuccess(accreditationCategory: ICreateUpdateCategoryResult): void;
}

export const CreateUpdateCategoryModal = (props: ICreateUpdateCategoryModalProps) => {
    const translate = useTranslate();
    const schema = React.useMemo(
        () =>
            yup.object().shape({
                name: yup.string().required(translate('le_nom_est_requ_61536'))
            }),
        []
    );

    return (
        <FormModal
            buttonText={props.isEdit ? translate('renommer_42135') : translate('cr_er_82895')}
            icon="pen"
            initialValues={{
                name: props.initialName
            }}
            schema={schema}
            title={
                props.isEdit
                    ? translate('renommer_1_40542', props.initialName)
                    : translate('cr_er_une_nouve_72722')
            }
            onClose={props.onClose}
            onSubmit={async (values: { name: string }) => {
                const result = await props.mutate(values.name);

                props.onClose();
                props.onSuccess(result);
            }}
        >
            <TextInput label={translate('nom_de_la_cat_g_03860')} name="name" />
        </FormModal>
    );
};
