import { groupBy, partition, sortBy } from 'lodash-es';
import * as React from 'react';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { isNonEmptyArray } from '../../../util/array';
import { P2NAssignmentPage } from './p2nAssignmentPage';
import { exploit } from './p2nInterval';
import { P2NMontagePosition } from './p2nMontagePosition';

interface IP2NAssignmentProps {
    event: DocumentUserCustomBadgeFragment;
}

export const P2nAssignmentMontageDemontage = (props: IP2NAssignmentProps) => {
    const vr = props.event.volunteerRegistration;
    const [_others, montageDemontage] = partition(vr.positionsSlotsUsersInfos, (psui) =>
        psui.positionSlot.range.overlaps(exploit)
    );
    const byPosition = sortBy(
        Object.entries(groupBy(montageDemontage, (psui) => psui.position.name)),
        ([positionName]) => positionName
    );

    return (
        <>
            {isNonEmptyArray(montageDemontage) && (
                <P2NAssignmentPage event={props.event} title="Planning Montage/ Démontage P2N 2024">
                    {byPosition.map(([_positionName, psuis], index) => (
                        <P2NMontagePosition key={index} psuis={psuis} />
                    ))}
                </P2NAssignmentPage>
            )}
        </>
    );
};
