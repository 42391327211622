import { Flex } from 'common/src/designSystem/components/flex';
import * as React from 'react';

interface IRootProps {
    children: React.ReactNode;
}

export const Root = (props: IRootProps) => (
    <Flex direction="column" height={1} width={1}>
        {props.children}
    </Flex>
);
