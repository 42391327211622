import { KpiCard } from 'common-front/src/designSystem/components/kpiCard';
import { ProgressBar } from 'common-front/src/designSystem/components/progressBar';
import { TableFilters } from 'common-front/src/designSystem/components/tableFilters';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { Row } from 'common/src/designSystem/components/table/row';
import { IntervalService } from 'common/src/services/intervalService';
import {
    useHistory,
    useParams,
    useService,
    useTranslate
} from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { noop, round } from 'lodash-es';
import * as React from 'react';
import { useCommunityDashboardQuery } from '../../generated/graphqlHooks';

export const CommunityDashboard = () => {
    const translate = useTranslate();
    const { organizationId } = useParams();
    const history = useHistory();
    const { data, loader } = useCommunityDashboardQuery({ organizationId });
    const intervalService = useService(IntervalService);

    return (
        loader || (
            <Flex css={{ margin: 'auto' }} direction="column" height={1} width="$container">
                <Spacer height="9" />

                <Box color="gray900" fontSize="displayXs" fontWeight="semiBold">
                    {translate('tableau_de_bord_24627')}
                </Box>

                <Spacer height="1" />

                <Box color="gray500">{translate('en_un_coup_d_oe_57288')}</Box>

                <Spacer height="9" />

                <Flex gap="6" width={1}>
                    <KpiCard
                        color="primary"
                        count={data.organization.events.nodes.length}
                        icon="megaphone"
                        text={translate('nombre_d_v_nem_14214')}
                    />

                    <KpiCard
                        color="purple"
                        count={data.organization.numberOfUsersInfos}
                        icon="user-group"
                        text={translate('nombre_de_membr_08851')}
                    />
                </Flex>

                <Spacer height="6" />

                <TableFilters
                    filters={<></>}
                    headerCells={
                        <>
                            <HeaderCell>{translate('nom_de_l_v_nem_08711')}</HeaderCell>
                            <HeaderCell>{translate('date_de_l_v_ne_69973')}</HeaderCell>
                            <HeaderCell width={200}>
                                {translate('nombre_de_missi_67304_plural')}
                            </HeaderCell>
                            <HeaderCell>{translate('taux_de_remplis_11913')}</HeaderCell>
                        </>
                    }
                    numberOfPages={0}
                    offset={0}
                    rows={
                        <>
                            {data.organization.events.nodes.map((event) => {
                                const percent = round(
                                    (event.assignedResources / event.resources) * 100
                                );

                                return (
                                    <Row
                                        key={event.id}
                                        css={{
                                            cursor: 'pointer',
                                            '&:hover': {
                                                background: '$gray100'
                                            }
                                        }}
                                        onClick={() => {
                                            history.push(
                                                HeaventPaths.EVENT(organizationId, event.id)
                                            );
                                        }}
                                    >
                                        <Cell>{event.name}</Cell>
                                        <Cell>
                                            {event.range
                                                ? intervalService.toDisplayString(event.range)
                                                : '-'}
                                        </Cell>
                                        <Cell width={200}>{event.numberOfPositions}</Cell>
                                        <Cell>
                                            {event.resources > 0 ? (
                                                <Flex align="center" gap="3" height={1} width={1}>
                                                    <Box css={{ flex: '1' }}>
                                                        <ProgressBar
                                                            percent={
                                                                event.assignedResources /
                                                                event.resources
                                                            }
                                                        />
                                                    </Box>

                                                    <Box color="gray700" width={40}>
                                                        {percent} %
                                                    </Box>
                                                </Flex>
                                            ) : (
                                                '-'
                                            )}
                                        </Cell>
                                    </Row>
                                );
                            })}
                        </>
                    }
                    setOffset={noop}
                    title="Liste des événements"
                    totalCount={0}
                />

                <Spacer height="9" />
            </Flex>
        )
    );
};
