import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import * as React from 'react';

interface IDocumentsListProps {
    children: React.ReactNode;
    title: string;
}

export const DocumentsList = (props: IDocumentsListProps) => (
    <Flex direction="column" gap="2">
        <Box font="gray900 textLg medium">{props.title}</Box>

        <Flex
            css={{
                background: 'white',
                border: '1px solid $gray200',
                borderRadius: '$2',
                boxShadow: '$xs',
                '& > div:last-child': {
                    borderBottom: 'none'
                }
            }}
            direction="column"
        >
            {props.children}
        </Flex>
    </Flex>
);
