import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';
import { Button } from '../button';
import { Modal } from '../modal';
import { TextInput } from '../textInput';

interface IRenameModalProps {
    name: string;
    label: string;

    onClose(): void;
    onRename(name: string): Promise<any>;
}

export const RenameModal = (props: IRenameModalProps) => {
    const translate = useTranslate();
    const [name, setName] = React.useState(props.name);
    const [isLoading, setIsLoading] = React.useState(false);
    const valid = isNonEmptyString(name);

    return (
        <Modal size="md" onClose={props.onClose}>
            <Box color="primary600" fontSize="displayXs" textAlign="center">
                <i className="fa-regular fa-sliders" />
            </Box>

            <Spacer height="7" />

            <Box color="gray800" fontSize="textLg" fontWeight="medium" textAlign="center">
                {translate('renommer_1_40542', props.name)}
            </Box>

            <Spacer height="6" />

            <TextInput
                label={props.label}
                placeholder={props.label}
                value={name}
                onChange={setName}
            />

            <Spacer height="7" />

            <Flex gap="3" justify="end">
                <Button
                    disabled={!valid}
                    isLoading={isLoading}
                    onClick={async () => {
                        setIsLoading(true);
                        await props.onRename(name);
                        setIsLoading(false);
                        props.onClose();
                    }}
                >
                    {translate('renommer_42135')}
                </Button>

                <Button color="white" onClick={props.onClose}>
                    {translate('annuler_48254')}
                </Button>
            </Flex>
        </Modal>
    );
};
