import { BadgesPlus } from 'common-front/src/components/badgesPlus/badgesPlus';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { RegisterSlotDisplay, VolunteerRegistrationFragment } from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { IntervalService } from 'common/src/services/intervalService';
import { useService } from 'common/src/util/dependencies/dependencies';
import { LocaleFormats } from 'common/src/util/luxon';
import { sortBy } from 'lodash-es';
import * as React from 'react';

interface IVolunteerRegistrationAvailabilitiesProps {
    volunteerRegistration: VolunteerRegistrationFragment;

    onClick(): void;
}

export const VolunteerRegistrationAvailabilities = (
    props: IVolunteerRegistrationAvailabilitiesProps
) => {
    const dateTimeService = useService(DateTimeService);
    const intervalService = useService(IntervalService);
    const badges = React.useMemo(() => {
        const fuis = props.volunteerRegistration.userInfo.formsUsersInfos || [];
        const wishedSlots = sortBy(
            fuis.flatMap((fui) =>
                (fui.slots || []).map((slot) => ({
                    range: slot.range,
                    display: fui.form.slotDisplay
                }))
            ),
            ({ range }) => range.start!.toMillis()
        );

        return wishedSlots.map((slot, index) => {
            const text =
                slot.display === RegisterSlotDisplay.Calendar ||
                slot.display === RegisterSlotDisplay.DisplayDays
                    ? dateTimeService.toLocaleString(
                          slot.range.start!,
                          LocaleFormats.DateOnly.Numeric
                      )
                    : intervalService.toDisplayString(slot.range, {
                          formats: {
                              localeFormat: LocaleFormats.DateOnly.Numeric
                          }
                      });

            return {
                id: index,
                color: 'gray',
                icon: null,
                text
            };
        });
    }, [props.volunteerRegistration.userInfo.formsUsersInfos]);

    return (
        <Cell>
            <BadgesPlus badges={badges} doNotSort={true} onClick={props.onClick} />
        </Cell>
    );
};
