import { Page } from 'common-front/src/components/page/page';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { useParams } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { AccreditationsSlotParametersCard } from '../../../accreditationsSlots/show/cards/accreditationsSlotParametersCard';
import { AccreditationsSlotMembersCard } from '../../../accreditationsSlots/show/cards/accreditationsSlotUsersCard/accreditationsSlotMembersCard';
import { CustomFieldsCard } from '../../../common/cards/customFieldsCard';
import { DescriptionCard } from '../../../common/cards/descriptionCard';
import { FillingRateCard } from '../../../common/cards/fillingRateCard';
import { LocationCard } from '../../../common/cards/locationCard';
import { PrivateNoteCard } from '../../../common/cards/privateNoteCard';
import { TagsCard } from '../../../common/cards/tagsCard';
import {
    useAccreditationDashboardQuery,
    useTagsAccreditationAssociateMutation
} from '../../../generated/graphqlHooks';
import { AccreditationMembersCard } from '../cards/accreditationMembersCard/accreditationsSlotUsersCard/accreditationMembersCard';
import { AccreditationSlotsCard } from '../cards/accreditationSlotsCard/accreditationSlotsCard';

export const AccreditationDashboard = () => {
    const { eventId, accreditationId } = useParams();
    const { data, loader, reload } = useAccreditationDashboardQuery({ eventId, accreditationId });
    const { mutate: tagsAssociate } = useTagsAccreditationAssociateMutation();

    return (
        loader || (
            <Page.Content>
                <Flex direction="column" gap="6">
                    {data.event.accreditation.hasSlots && (
                        <Box css={{ flex: '1' }}>
                            <AccreditationSlotsCard />
                        </Box>
                    )}

                    <Flex gap="6" width={1}>
                        {!data.event.accreditation.hasSlots && (
                            <FillingRateCard
                                assignedResources={data.event.accreditation.assignedResources}
                                maxResources={data.event.accreditation.maxResources}
                            />
                        )}

                        <AccreditationsSlotParametersCard
                            accreditationCategoryName={
                                data.event.accreditation.accreditationCategory.name
                            }
                            color={data.event.accreditation.color}
                            icon={data.event.accreditation.icon}
                        />
                    </Flex>

                    <Box css={{ flex: '1' }}>
                        {data.event.accreditation.hasSlots ? (
                            <AccreditationMembersCard />
                        ) : (
                            <AccreditationsSlotMembersCard
                                hiddenSlotId={data.event.accreditation.hiddenSlotId}
                            />
                        )}
                    </Box>

                    <Flex gap="4">
                        <Flex direction="column" gap="4" width={1}>
                            <CustomFieldsCard
                                customFields={data.event.accreditation.accreditationsCustomFields}
                            />

                            <TagsCard
                                associateTags={(tagsIds) =>
                                    tagsAssociate({
                                        eventId,
                                        accreditationId,
                                        tagsIds
                                    })
                                }
                                reload={reload}
                                tags={data.event.accreditation.tags}
                            />

                            <PrivateNoteCard privateNote={data.event.accreditation.privateNote} />
                        </Flex>

                        <Flex direction={'column'} gap="4" width={1}>
                            <DescriptionCard description={data.event.accreditation.description} />

                            <LocationCard location={data.event.accreditation} />
                        </Flex>
                    </Flex>
                </Flex>
            </Page.Content>
        )
    );
};
