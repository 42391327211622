import { Button } from 'common-front/src/designSystem/components/button';
import { ToggleText } from 'common-front/src/designSystem/form/toggle';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { EventAccreditationsFragment } from 'common/src/generated/types';
import { ICreateUpdateDelegationValues } from 'common/src/input/delegationInput';
import * as React from 'react';
import { DelegationAccreditationsCategories } from './accreditations/delegationAccreditationsCategories';

interface IAccreditationsFormProps {
    allAccreditationCategories: EventAccreditationsFragment['accreditationsCategories'];
    values: ICreateUpdateDelegationValues['delegation'];

    change(name: string, value: any): void;
    setIsAddAccreditationOpen(_: boolean): void;
}

export const AccreditationsForm = ({
    allAccreditationCategories,
    setIsAddAccreditationOpen,
    change,
    values
}: IAccreditationsFormProps) => {
    const { translate } = useHeavent();
    const isDefaultByAvailabilityVisible = React.useMemo(
        () => values.accreditationsSlots.some(({ isDefault }) => isDefault),
        [values]
    );

    return (
        <Flex direction="column">
            <Box font="gray900 textMd medium">{translate('liste_des_accr_46997')}</Box>

            <Spacer height="3" />

            <DelegationAccreditationsCategories
                accreditationCategories={allAccreditationCategories}
                change={change}
                values={values}
            />

            <Spacer height="4" />

            <Button
                color="invisible"
                leftIcon="plus"
                onClick={() => {
                    setIsAddAccreditationOpen(true);
                }}
            >
                {translate('add_accreditation')}
            </Button>

            {isDefaultByAvailabilityVisible && (
                <>
                    <Spacer height="7" />

                    <Box font="gray900 textMd medium">{translate('attribution_des_59896')}</Box>

                    <Spacer height="1" />

                    <ToggleText name="delegation.addDefaultAccreditationsByAvailability">
                        {translate('attribuer_les_a_56919')}
                    </ToggleText>
                </>
            )}
        </Flex>
    );
};
