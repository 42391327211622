import * as React from 'react';
import { Flex } from '../../../designSystem/components/flex';
import { A6_SIZES } from '../../../util/pdf';

export const BadgeElephantsBack = () => (
    <Flex
        css={{
            background: `url(https://assets.recrewteer.com/badges/chambery/back.jpg) no-repeat`,
            backgroundSize: 'cover',
            pageBreakBefore: 'always'
        }}
        height={A6_SIZES['96dpi'].height}
        width={A6_SIZES['96dpi'].width}
    />
);
