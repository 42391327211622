import { BadgesPlus } from 'common-front/src/components/badgesPlus/badgesPlus';
import { Checkbox, ICheckboxState } from 'common-front/src/designSystem/components/checkbox';
import { Content } from 'common-front/src/designSystem/components/tooltip/content';
import { Tooltip } from 'common-front/src/designSystem/components/tooltip/tooltip';
import { Trigger } from 'common-front/src/designSystem/components/tooltip/trigger';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useReferrerContext } from 'common-front/src/util/referrerContext';
import { Avatar } from 'common/src/components/avatar/avatar';
import { AvatarNameEmail } from 'common/src/components/avatarNameEmail';
import { Box } from 'common/src/designSystem/components/box';
import { I } from 'common/src/designSystem/components/i';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { CellControls } from 'common/src/designSystem/components/table/cellControls';
import { Row } from 'common/src/designSystem/components/table/row';
import {
    CommunityUsersQuery,
    FieldType,
    SegmentCustomFieldFragment,
    UsersInfoId
} from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { useService } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { LocaleFormats } from 'common/src/util/luxon';
import { FieldService } from 'common/src/vo/field';
import { PossibleColumn } from 'common/src/vo/segment';
import * as React from 'react';
import { CommunityUserCheckboxCell } from './cell/communityUserCheckboxCell';
import { CommunityUserDateCell } from './cell/communityUserDateCell';
import { CommunityUserNumberCell } from './cell/communityUserNumberCell';
import { CommunityUserPhoneCell } from './cell/communityUserPhoneCell';
import { CommunityUserSelectCell } from './cell/communityUserSelectCell';
import { CommunityUserTextareaCell } from './cell/communityUserTextareaCell';
import { CommunityUserTextCell } from './cell/communityUserTextCell';

interface ICommunityUserRowProps {
    columns: PossibleColumn[];
    customFields: SegmentCustomFieldFragment[];
    state: ICheckboxState;
    userInfo: CommunityUsersQuery['organization']['usersInfos']['nodes'][0];

    reloadUserInfo(id: UsersInfoId): void;
    toggleRow(key: string, newState: ICheckboxState): void;
}

export const CommunityUserRow = (props: ICommunityUserRowProps) => {
    const {
        history,
        translate,
        params: { organizationId, segmentId }
    } = useHeavent();
    const { setReferrerPath } = useReferrerContext();
    const dateTimeService = useService(DateTimeService);
    const fieldService = useService(FieldService);
    const slugToCustomField = React.useMemo(
        () => new Map(props.customFields.map((cf) => [cf.slug, cf])),
        [props.customFields]
    );
    const reloadUserInfo = React.useCallback(() => {
        props.reloadUserInfo(props.userInfo.id);
    }, [props.userInfo, props.reloadUserInfo]);
    const formsBadges = React.useMemo(
        () =>
            (props.userInfo.formsUsersInfos || []).flatMap((fui) => {
                if (fui.form) {
                    return [
                        {
                            id: fui.form.id,
                            color: 'gray',
                            icon: null,
                            text: fui.form.name
                        }
                    ];
                } else {
                    return [];
                }
            }),
        [props.userInfo.formsUsersInfos]
    );
    const formsInsertedAt = React.useMemo(
        () =>
            (props.userInfo.formsUsersInfos || [])
                .flatMap((fui) => {
                    if (fui.form) {
                        const name = fui.form.name;
                        const insertedAt = dateTimeService.toLocaleString(
                            fui.insertedAt.toLocal(),
                            LocaleFormats.DateTime
                        );

                        return [`${name} : ${insertedAt}`];
                    } else {
                        return [];
                    }
                })
                .join(', '),
        [props.userInfo.formsUsersInfos]
    );
    const delegationsBadges = React.useMemo(
        () =>
            (props.userInfo.delegations || []).map((delegation) => ({
                id: delegation.id,
                color: 'primary',
                icon: null,
                text: delegation.name
            })),
        [props.userInfo.delegations]
    );

    return (
        <Row
            css={{
                cursor: 'pointer',
                '&:hover': {
                    background: '$gray100'
                }
            }}
            onClick={() => {
                history.push(
                    HeaventPaths.COMMUNITY_USERS_USER_INFORMATIONS(
                        organizationId,
                        segmentId,
                        props.userInfo.id
                    )
                );
            }}
        >
            <Cell css={{ paddingRight: 0 }} width={48}>
                <Checkbox
                    state={props.state}
                    onClick={(newState, e) => {
                        e.nativeEvent.stopImmediatePropagation();
                        e.stopPropagation();

                        props.toggleRow(`ui${props.userInfo.id}`, newState);
                    }}
                />
            </Cell>

            {props.columns.map((field) => {
                const customField = slugToCustomField.get(field.slug);

                return field.slug === 'name' ? (
                    <Cell key={field.slug}>
                        <AvatarNameEmail hasName={true} size={32} userInfo={props.userInfo} />
                    </Cell>
                ) : field.slug === 'id' ? (
                    <Cell key={field.slug} width={100}>
                        {props.userInfo.id}
                    </Cell>
                ) : field.slug === 'picture' ? (
                    <Cell key={field.slug} width={80}>
                        <Avatar
                            email={props.userInfo.email}
                            image={props.userInfo.picture?.url}
                            name={props.userInfo.name}
                            size={32}
                        />
                    </Cell>
                ) : field.slug === 'email' ? (
                    <Cell key={field.slug}>{props.userInfo.email}</Cell>
                ) : field.slug === 'events' ? (
                    <Cell key={field.slug}>
                        <Box css={{ ellipsis: true }} title={props.userInfo.eventsNames}>
                            {props.userInfo.eventsNames}
                        </Box>
                    </Cell>
                ) : field.slug === 'number_of_events' ? (
                    <Cell key={field.slug} width={230}>
                        {props.userInfo.numberOfEvents}
                    </Cell>
                ) : field.slug === 'forms' ? (
                    <Cell key={field.slug}>
                        <BadgesPlus badges={formsBadges} />
                    </Cell>
                ) : field.slug === 'forms_inserted_at' ? (
                    <Cell key={field.slug}>
                        <Box css={{ ellipsis: true }} title={formsInsertedAt}>
                            {formsInsertedAt}
                        </Box>
                    </Cell>
                ) : field.slug === 'delegations' ? (
                    <Cell key={field.slug}>
                        <BadgesPlus badges={delegationsBadges} />
                    </Cell>
                ) : field.slug === 'inserted_at' ? (
                    <Cell key={field.slug} width={230}>
                        {dateTimeService.toLocaleString(
                            props.userInfo.insertedAt.toLocal(),
                            LocaleFormats.DateTime
                        )}
                    </Cell>
                ) : customField?.fieldType === FieldType.Checkbox ? (
                    <CommunityUserCheckboxCell
                        key={field.slug}
                        customField={customField}
                        field={field}
                        organizationId={organizationId}
                        reload={reloadUserInfo}
                        userInfo={props.userInfo}
                    />
                ) : customField?.fieldType === FieldType.Date ? (
                    <CommunityUserDateCell
                        key={field.slug}
                        customField={customField}
                        field={field}
                        organizationId={organizationId}
                        reload={reloadUserInfo}
                        userInfo={props.userInfo}
                    />
                ) : customField?.fieldType === FieldType.Phone ? (
                    <CommunityUserPhoneCell
                        key={field.slug}
                        customField={customField}
                        field={field}
                        organizationId={organizationId}
                        reload={reloadUserInfo}
                        userInfo={props.userInfo}
                    />
                ) : customField?.fieldType === FieldType.Select ||
                  customField?.fieldType === FieldType.Country ||
                  customField?.fieldType === FieldType.Language ||
                  customField?.fieldType === FieldType.Sex ||
                  customField?.fieldType === FieldType.Nationality ? (
                    <CommunityUserSelectCell
                        key={field.slug}
                        customField={customField}
                        field={field}
                        organizationId={organizationId}
                        reload={reloadUserInfo}
                        userInfo={props.userInfo}
                    />
                ) : customField?.fieldType === FieldType.Text ? (
                    <CommunityUserTextCell
                        key={field.slug}
                        customField={customField}
                        field={field}
                        organizationId={organizationId}
                        reload={reloadUserInfo}
                        userInfo={props.userInfo}
                    />
                ) : customField?.fieldType === FieldType.Textarea ? (
                    <CommunityUserTextareaCell
                        key={field.slug}
                        customField={customField}
                        field={field}
                        organizationId={organizationId}
                        reload={reloadUserInfo}
                        userInfo={props.userInfo}
                    />
                ) : customField?.fieldType === FieldType.Number ? (
                    <CommunityUserNumberCell
                        key={field.slug}
                        customField={customField}
                        field={field}
                        organizationId={organizationId}
                        reload={reloadUserInfo}
                        userInfo={props.userInfo}
                    />
                ) : (
                    <Cell key={field.slug}>
                        {customField
                            ? fieldService.getValueString(customField, props.userInfo, {
                                  fileReturnValue: 'name'
                              })
                            : ''}
                    </Cell>
                );
            })}

            <CellControls justify="center">
                <Tooltip>
                    <Trigger>
                        <Box>
                            <I
                                icon="pen"
                                onClick={() => {
                                    setReferrerPath();
                                    history.push(
                                        HeaventPaths.COMMUNITY_EDIT_USER(
                                            organizationId,
                                            props.userInfo.id
                                        )
                                    );
                                }}
                            />
                        </Box>
                    </Trigger>

                    <Content placement="top">{translate('_diter_62574')}</Content>
                </Tooltip>
            </CellControls>
        </Row>
    );
};
