import { LabelOptional } from 'common-front/src/designSystem/components/input/labelOptional';
import { RichEditor } from 'common-front/src/designSystem/form/richEditor';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';

export const CreatePositionPrivateNote = () => {
    const translate = useTranslate();

    return (
        <>
            <RichEditor
                label={<LabelOptional>{translate('note_priv_e_06303')}</LabelOptional>}
                name="position.privateNote"
            />
        </>
    );
};
