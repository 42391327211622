import { Form } from 'common-front/src/components/form/form';
import { Button } from 'common-front/src/designSystem/components/button';
import { DeleteModal } from 'common-front/src/designSystem/components/deleteModal';
import { FileS3Input } from 'common-front/src/designSystem/form/file/fileS3Input';
import {
    useProfileQuery,
    useUserProfileUpdateMutation
} from 'common-front/src/generated/graphqlHooks';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useUserContext } from 'common-front/src/userContext';
import { ProfileTextInputs } from 'common-front/src/users/profile/profileTextInputs';
import { Accept } from 'common-front/src/util/accept';
import { signOut } from 'common-front/src/util/aws/cognito';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { IUpdateProfileValues, UserInfoInputService } from 'common/src/input/userInfoInput';
import { ValidateService } from 'common/src/services/validateService';
import { useService } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { useUserDeleteMutation } from '../../generated/graphqlHooks';
import { useOrganizationContext } from '../../organization/organizationContext';

export const Profile = () => {
    const {
        translate,
        params: { organizationId }
    } = useHeavent();
    const { data, loader } = useProfileQuery({ organizationId });
    const userInfoInput = useService(UserInfoInputService);
    const validateService = useService(ValidateService);
    const { mutate: updateProfile, isLoading: isUpdateProfileLoading } =
        useUserProfileUpdateMutation();
    const { mutate: deleteUser } = useUserDeleteMutation();
    const { user } = useUserContext();
    const { reload } = useOrganizationContext();
    const [isDeleteOpen, setIsDeleteOpen] = React.useState(false);

    return (
        loader || (
            <Form
                css={{ overflowY: 'auto', padding: '$7' }}
                direction="column"
                height={1}
                initialValues={{
                    userInfo: userInfoInput.userInfoProfileInputDefault(data.user)
                }}
                render={({ handleSubmit }) => (
                    <>
                        <Box color="gray900" fontSize="displayXs" fontWeight="semiBold">
                            {translate('mon_profil_48224')}
                        </Box>

                        <Spacer height="7" />

                        <Box color="gray800" fontSize="textLg" fontWeight="semiBold">
                            {translate('informations_pe_37892')}
                        </Box>

                        <Spacer height="6" />

                        <FileS3Input
                            accept={Accept.Images}
                            acl={'public-read'}
                            allowCrop={true}
                            label={translate('photo_de_profil_08618')}
                            organizationId={organizationId}
                            prefix="userInfo.fields.picture."
                        />

                        <Spacer height="6" />

                        <ProfileTextInputs inputName="userInfo" />

                        <Spacer height="6" />

                        <Box color="gray800" fontSize="textLg" fontWeight="semiBold">
                            {translate('compte_16579')}
                        </Box>

                        <Spacer height="6" />

                        <Box
                            color="error700"
                            css={{ cursor: 'pointer', textDecoration: 'underline' }}
                            onClick={() => {
                                setIsDeleteOpen(true);
                            }}
                        >
                            {translate('supprimer_mon_c_07051')}
                        </Box>

                        <Spacer height="7" />

                        <Flex>
                            <Button isLoading={isUpdateProfileLoading} onClick={handleSubmit}>
                                {translate('enregistrer_les_30411')}
                            </Button>
                        </Flex>

                        {isDeleteOpen && (
                            <DeleteModal
                                buttonText="Supprimer"
                                subtext={translate('_tes_vous_s_r_d_50084')}
                                text={translate('supprimer_mon_c_07051')}
                                onClose={() => {
                                    setIsDeleteOpen(false);
                                }}
                                onDelete={async () => {
                                    await deleteUser({});

                                    signOut();
                                }}
                            />
                        )}
                    </>
                )}
                validate={validateService.validateForForm(userInfoInput.updateProfileSchema())}
                width={1}
                onSubmit={async (values: IUpdateProfileValues) => {
                    const {
                        userProfileUpdate: { email, language }
                    } = await updateProfile({
                        userId: user!.id,
                        userInfoId: data.user.userInfo.id,
                        userInfo: values.userInfo
                    });

                    if (user!.email !== email || user!.language !== language) {
                        signOut();
                    } else {
                        reload();
                    }
                }}
            />
        )
    );
};
