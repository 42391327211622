import { DeleteModal } from 'common-front/src/designSystem/components/deleteModal';
import { AccreditationsCategory, EventId } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { useAccreditationCategoryDeleteMutation } from '../../generated/graphqlHooks';

interface IDeleteAccreditationCategoryProps {
    accreditationCategory: Pick<AccreditationsCategory, 'id' | 'name'>;
    eventId: EventId;

    onSuccess(): void;
    onClose(): void;
}

export const DeleteAccreditationCategory = (props: IDeleteAccreditationCategoryProps) => {
    const translate = useTranslate();
    const { mutate } = useAccreditationCategoryDeleteMutation();

    return (
        <DeleteModal
            buttonText={translate('supprimer_43083')}
            subtext={[translate('_tes_vous_s_r_d_50954'), translate('cela_supprimera_56396')]}
            text={translate('suppression_de_73669', props.accreditationCategory.name)}
            onClose={props.onClose}
            onDelete={async () => {
                await mutate({
                    eventId: props.eventId,
                    accreditationCategoryId: props.accreditationCategory.id
                });

                props.onSuccess();
            }}
        />
    );
};
