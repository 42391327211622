import { DeleteModal } from 'common-front/src/designSystem/components/deleteModal';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Accreditation } from 'common/src/generated/types';
import { compact } from 'lodash-es';
import * as React from 'react';
import { useAccreditationDeleteMutation } from '../generated/graphqlHooks';

interface IDeleteAccreditationProps {
    accreditation: Pick<Accreditation, 'id' | 'name' | 'assignedResources'>;

    onSuccess(): void;
    onClose(): void;
}

export const DeleteAccreditation = (props: IDeleteAccreditationProps) => {
    const {
        translate,
        params: { eventId }
    } = useHeavent();
    const { mutate } = useAccreditationDeleteMutation();

    return (
        <DeleteModal
            buttonText={translate('supprimer_43083')}
            subtext={compact([
                translate('_tes_vous_s_r_d_79068'),
                props.accreditation.assignedResources > 0
                    ? translate('_1_membre_est_95699', props.accreditation.assignedResources)
                    : null
            ])}
            text={translate('suppression_de_73669', props.accreditation.name)}
            onClose={props.onClose}
            onDelete={async () => {
                await mutate({ eventId, accreditationId: props.accreditation.id });

                props.onSuccess();
            }}
        />
    );
};
