import { Button } from 'common-front/src/designSystem/components/button';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Grid } from 'common/src/designSystem/components/grid';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { UserEmail } from 'common/src/generated/types';
import { useParams, useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { useOrganizationInviteMutation, useTeamQuery } from '../../generated/graphqlHooks';
import { EventUsers } from './eventUsers';
import { Invite } from './invite';
import { OrganizationUser } from './organizationUser';

export const Team = () => {
    const translate = useTranslate();
    const { organizationId } = useParams();
    const { data, loader, reload } = useTeamQuery({ organizationId });
    const { mutate, isLoading } = useOrganizationInviteMutation();
    const [isInviteOpen, setIsInviteOpen] = React.useState(false);

    return (
        loader || (
            <Flex css={{ overflowY: 'auto', padding: '$7' }} direction="column" height={1}>
                <Box color="gray900" fontSize="displayXs" fontWeight="semiBold">
                    {translate('utilisateurs_04975')}
                </Box>

                <Spacer height="9" />

                <Flex align="center" gap="4">
                    <Box
                        color="gray800"
                        css={{ flex: '1' }}
                        fontSize="textLg"
                        fontWeight="semiBold"
                    >
                        {translate('utilisateurs_de_44719', data.organization.name)}
                    </Box>

                    <Button
                        leftIcon="user-plus"
                        onClick={() => {
                            setIsInviteOpen(true);
                        }}
                    >
                        {translate('ajouter_un_util_02998')}
                    </Button>
                </Flex>

                <Spacer height="4" />

                <Grid gap="6" gridtemplatecolumns="repeat(auto-fill, minmax(360px, 1fr))">
                    {data.organization.usersOrganizations.map((userOrganization) => (
                        <OrganizationUser
                            key={userOrganization.user.id}
                            reload={reload}
                            userOrganization={userOrganization}
                        />
                    ))}
                </Grid>

                {data.organization.events.nodes.map((event, index) => (
                    <EventUsers key={index} event={event} organizationId={organizationId} />
                ))}

                {isInviteOpen && (
                    <Invite
                        isLoading={isLoading}
                        mutate={async (email: UserEmail) => {
                            await mutate({
                                organizationId,
                                email
                            });

                            reload();
                        }}
                        onClose={() => {
                            setIsInviteOpen(false);
                        }}
                    />
                )}
            </Flex>
        )
    );
};
