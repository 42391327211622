import { Button } from 'common-front/src/designSystem/components/button';
import { RadioRow } from 'common-front/src/designSystem/components/radioRow';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { EventsQuery } from 'common/src/generated/types';
import { useHistory, useParams, useTranslate } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import { useState } from 'react';
import { EventRadioContentMobile } from './eventRadioContentMobile';

export const EventSwitchMobile = ({
    events
}: {
    events: EventsQuery['organization']['events']['nodes'];
}) => {
    const { organizationId, eventId } = useParams();
    const [selectedEventId, setSelectedEventId] = useState(eventId);
    const history = useHistory();
    const switchEvent = () => {
        history.push(HeaventPaths.DASHBOARD(organizationId, selectedEventId));
    };
    const translate = useTranslate();

    return (
        <Box css={{ margin: '0 $4 $7 $4' }}>
            <Flex direction="column" gap="4">
                {events.map((event) => (
                    <RadioRow
                        key={event.id}
                        state={event.id === selectedEventId ? 'checked' : 'unchecked'}
                        onClick={() => setSelectedEventId(event.id)}
                    >
                        <EventRadioContentMobile event={event} />
                    </RadioRow>
                ))}
            </Flex>
            <Spacer height={7} />
            <Button onClick={switchEvent}>{translate('s_lectionner_l_77300')}</Button>
        </Box>
    );
};
