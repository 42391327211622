import { Button } from 'common-front/src/designSystem/components/button';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { isNonEmptyString } from 'common/src/util/string';
import { DocumentPage } from 'common/src/vo/documentBuilder';
import * as React from 'react';
import { useDocumentBuilderContext } from '../documentBuilderContext';
import { DocumentBuilderImage } from './documentBuilderImage';
import { DocumentBuilderText } from './documentBuilderText';

interface IDocumentBuilderPageProps {
    index: number;
    page: DocumentPage;
}

export const DocumentBuilderPage = (props: IDocumentBuilderPageProps) => {
    const { customDocument, addPage, removePage, selectedPageId, selectPage } =
        useDocumentBuilderContext();
    const canDelete = customDocument.configuration.pages.length > 1;

    return (
        <Flex
            css={{
                height: 'fit-content',
                width: 'fit-content'
            }}
            direction="column"
            gap="2"
        >
            <Flex align="center">
                <Box
                    css={{ cursor: 'pointer', flex: '1' }}
                    fontWeight="semiBold"
                    onClick={(e) => {
                        e.stopPropagation();
                        e.nativeEvent.stopImmediatePropagation();

                        selectPage(props.page.id);
                    }}
                >
                    {isNonEmptyString(props.page.name)
                        ? props.page.name
                        : `Page ${props.index + 1}`}
                </Box>

                <Flex gap="2">
                    <Button color="white" leftIcon="plus" size="sm" onClick={addPage} />

                    <Button
                        color="white"
                        disabled={!canDelete}
                        leftIcon="trash-can"
                        size="sm"
                        onClick={() => {
                            removePage(props.page.id);
                        }}
                    />
                </Flex>
            </Flex>

            <Flex
                css={{
                    background: 'white',
                    outline: selectedPageId === props.page.id ? '2px solid $primary700' : '',
                    position: 'relative'
                }}
                height={customDocument.configuration.height}
                width={customDocument.configuration.width}
                onClick={(e) => {
                    e.stopPropagation();
                    e.nativeEvent.stopImmediatePropagation();

                    selectPage(props.page.id);
                }}
            >
                {props.page.elements.map((element) => {
                    if (element.type === 'image') {
                        return <DocumentBuilderImage key={element.id} image={element} />;
                    } else if (element.type === 'text') {
                        return <DocumentBuilderText key={element.id} text={element} />;
                    } else {
                        return null;
                    }
                })}
            </Flex>
        </Flex>
    );
};
