import { DropdownEllipsis } from 'common-front/src/designSystem/components/dropdown/dropdownEllipsis';
import { ItemIcon } from 'common-front/src/designSystem/components/dropdown/item';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Badge } from 'common/src/designSystem/components/badge';
import { Box } from 'common/src/designSystem/components/box';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { CellControls } from 'common/src/designSystem/components/table/cellControls';
import { Row } from 'common/src/designSystem/components/table/row';
import { PositionCategoryPositionsQuery } from 'common/src/generated/types';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';

interface IPositionCategoryPositionRowProps {
    position: PositionCategoryPositionsQuery['event']['positions']['nodes'][0];
}

export const PositionCategoryPositionRow = (props: IPositionCategoryPositionRowProps) => {
    const {
        history,
        translate,
        params: { organizationId, eventId, positionCategoryId }
    } = useHeavent();
    const missingResources = React.useMemo(
        () => props.position.resources - props.position.assignedResources,
        [props.position]
    );

    return (
        <Row
            css={{
                cursor: 'pointer'
            }}
            onClick={() => {
                history.push(
                    HeaventPaths.POSITION_MEMBERS(
                        organizationId,
                        eventId,
                        positionCategoryId,
                        props.position.id
                    )
                );
            }}
        >
            <Cell>
                <Box font="gray900 textSm medium">{props.position.name}</Box>
            </Cell>

            <Cell>
                {missingResources > 0 ? (
                    <Badge color="warning">
                        {translate('_1_membre_manq_31014', missingResources)}
                    </Badge>
                ) : (
                    <Badge color="success">{translate('complet_51600')}</Badge>
                )}
            </Cell>

            <CellControls justify="end">
                <DropdownEllipsis>
                    <ItemIcon
                        icon="eye"
                        onClick={() => {
                            history.push(
                                HeaventPaths.POSITION(organizationId, eventId, props.position.id)
                            );
                        }}
                    >
                        {translate('voir_la_mission_68087')}
                    </ItemIcon>

                    <ItemIcon
                        icon="pen"
                        onClick={() => {
                            history.push(
                                HeaventPaths.EDIT_POSITION(
                                    organizationId,
                                    eventId,
                                    props.position.id
                                )
                            );
                        }}
                    >
                        {translate('_diter_la_missi_90442')}
                    </ItemIcon>
                </DropdownEllipsis>
            </CellControls>
        </Row>
    );
};
