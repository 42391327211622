import { DeleteModal } from 'common-front/src/designSystem/components/deleteModal';
import { CommentId, EventId, OrganizationId } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { Emptyable } from 'common/src/util/emptyable';
import * as React from 'react';
import { useCommentDeleteMutation } from '../../../generated/graphqlHooks';

interface IDeleteCommentProps {
    commentId: CommentId;
    eventId: Emptyable<EventId>;
    organizationId: OrganizationId;

    onClose(): void;
    onSuccess(): void;
}

export const DeleteComment = (props: IDeleteCommentProps) => {
    const translate = useTranslate();
    const { mutate } = useCommentDeleteMutation();

    return (
        <DeleteModal
            buttonText={translate('supprimer_43083')}
            subtext={translate('_tes_vous_s_r_d_55928')}
            text={translate('suppression_d_u_90124')}
            onClose={props.onClose}
            onDelete={async () => {
                await mutate({
                    organizationId: props.organizationId,
                    eventId: props.eventId,
                    commentId: props.commentId
                });

                props.onSuccess();
            }}
        />
    );
};
