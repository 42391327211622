import { FullPageHeader } from 'common-front/src/components/mobileFullPage/fullPageHeader';
import { FullPageHeaderText } from 'common-front/src/components/mobileFullPage/fullPageHeaderText';
import { Box } from 'common/src/designSystem/components/box';
import { PositionDashboardQuery } from 'common/src/generated/types';
import * as React from 'react';

interface IPositionHeaderMobileProps {
    back: () => void;
    position: Pick<PositionDashboardQuery['event']['position'], 'name' | 'positionCategoryName'>;
}

export const PositionHeaderMobile = ({ back, position }: IPositionHeaderMobileProps) => (
    <FullPageHeader back={back}>
        <FullPageHeaderText css={{ minHeight: '40px' }}>
            <Box color="gray900" fontWeight="medium" textAlign="center">
                {position.name}
            </Box>
            <Box color="gray500" fontSize="textXs" textAlign="center">
                {position.positionCategoryName}
            </Box>
        </FullPageHeaderText>
    </FullPageHeader>
);
