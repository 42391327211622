import { TextInput } from 'common-front/src/designSystem/form/textInput';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';

export const WeezeventConnectionStep1 = () => {
    const translate = useTranslate();

    return (
        <>
            <TextInput label={translate('cl_api_weezeve_96105')} name="weezevent.apiKey" />

            <Spacer height="4" />

            <TextInput label={translate('identifiant_e_m_31343')} name="weezevent.email" />

            <Spacer height="4" />

            <TextInput label={translate('mot_de_passe_41738')} name="weezevent.password" />
        </>
    );
};
