import '0_reflect-metadata';

import { LogToken } from 'common-front/src/util/logToken';
import * as React from 'react';
import { render } from 'react-dom';
import { App } from './app';
import { AppContainer } from './appContainer';
import { Hubspot } from './hubspot';

render(
    <React.StrictMode>
        <AppContainer>
            <LogToken />

            <Hubspot />

            <App />
        </AppContainer>
    </React.StrictMode>,
    document.getElementById('heavent-react-hook')
);
