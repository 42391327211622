import {
    INotificationProps,
    Notification
} from 'common-front/src/designSystem/components/notification';
import {
    DelegationsImportState,
    EventId,
    ExportState,
    FilesImportState,
    OrganizationId,
    VolunteersImportState
} from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { Emptyable } from 'common/src/util/emptyable';
import { UUID } from 'common/src/util/uuid';
import { Duration } from 'luxon';
import * as React from 'react';
import {
    useDelegationsImportResultMutation,
    useEventDocumentsExportResultMutation,
    useExportResultMutation,
    useFilesImportResultMutation,
    useVolunteersImportResultMutation
} from '../generated/graphqlHooks';

const CHECK_INTERVAL_TIME = 2_000;

type NotificationOptions = Omit<INotificationProps, 'onClose'>;

interface INotificationContext {
    checkDelegationsImport(organizationId: OrganizationId, jobId: UUID): void;
    checkDocumentsExport(eventId: EventId, jobId: UUID): void;
    checkFilesImport(organizationId: OrganizationId, jobId: UUID): void;
    checkVolunteersExport(
        organizationId: OrganizationId,
        eventId: Emptyable<EventId>,
        jobId: UUID
    ): void;
    checkVolunteersImport(organizationId: OrganizationId, jobId: UUID): void;
    showNotification(props: NotificationOptions): void;
}

const NotificationContext = React.createContext<INotificationContext>({} as any);

interface INotificationContextProviderProps {
    children: React.ReactNode;
}

export const NotificationContextProvider = (props: INotificationContextProviderProps) => {
    const translate = useTranslate();
    const [isNotificationVisible, setIsNotificationVisible] = React.useState(false);
    const [notificationOptions, setNotificationOptions] = React.useState<NotificationOptions>({
        color: 'success',
        icon: 'check',
        text: ''
    });
    const onClose = React.useCallback(() => {
        setTimeout(() => {
            setIsNotificationVisible(false);
        }, 1200);
    }, [setIsNotificationVisible]);
    const showNotification = React.useCallback(
        (options: NotificationOptions) => {
            setIsNotificationVisible(true);
            setNotificationOptions(options);

            setTimeout(onClose, Duration.fromObject({ seconds: 30 }).toMillis());
        },
        [setIsNotificationVisible, setNotificationOptions, onClose]
    );
    const { mutate: delegationsImportResultMutation } = useDelegationsImportResultMutation();
    const checkDelegationsImportId = React.useRef<number | null>(null);
    const checkDelegationsImport = React.useCallback(
        (organizationId: OrganizationId, jobId: UUID) => {
            checkDelegationsImportId.current = window.setInterval(async () => {
                const {
                    delegationsImportResult: { state, event }
                } = await delegationsImportResultMutation({
                    organizationId,
                    jobId
                });

                if (state === DelegationsImportState.Done) {
                    clearInterval(checkDelegationsImportId.current!);

                    showNotification({
                        color: 'success',
                        icon: 'cloud-arrow-up',
                        text: translate('votre_import_es_98992', event!.name)
                    });
                }
            }, CHECK_INTERVAL_TIME);
        },
        []
    );

    const { mutate: documentsExportUrlMutate } = useEventDocumentsExportResultMutation();
    const checkDocumentsExportId = React.useRef<number | null>(null);
    const checkDocumentsExport = React.useCallback((eventId: EventId, jobId: UUID) => {
        checkDocumentsExportId.current = window.setInterval(async () => {
            const { eventDocumentsExportResult } = await documentsExportUrlMutate({
                eventId,
                jobId
            });

            if (eventDocumentsExportResult.state === ExportState.Done) {
                clearInterval(checkDocumentsExportId.current!);

                showNotification({
                    color: 'success',
                    icon: 'envelope',
                    text: translate('l_export_de_vos_38257', eventDocumentsExportResult.url)
                });
            }
        }, CHECK_INTERVAL_TIME);
    }, []);
    const { mutate: filesImportResultMutation } = useFilesImportResultMutation();
    const checkFilesImportId = React.useRef<number | null>(null);
    const checkFilesImport = React.useCallback((organizationId: OrganizationId, jobId: UUID) => {
        checkFilesImportId.current = window.setInterval(async () => {
            const {
                filesImportResult: { state, event }
            } = await filesImportResultMutation({
                organizationId,
                jobId
            });

            if (state === FilesImportState.Done) {
                clearInterval(checkFilesImportId.current!);

                showNotification({
                    color: 'success',
                    icon: 'cloud-arrow-up',
                    text: event
                        ? translate('votre_import_es_11791', event.name)
                        : translate('votre_import_es_10672')
                });
            }
        }, CHECK_INTERVAL_TIME);
    }, []);

    const { mutate: exportResultMutationMutation } = useExportResultMutation();
    const checkVolunteersExportId = React.useRef<number | null>(null);
    const checkVolunteersExport = React.useCallback(
        (organizationId: OrganizationId, eventId: Emptyable<EventId>, jobId: UUID) => {
            checkVolunteersExportId.current = window.setInterval(async () => {
                const { exportResult } = await exportResultMutationMutation({
                    organizationId,
                    eventId,
                    jobId
                });

                if (exportResult.state === ExportState.Done) {
                    clearInterval(checkVolunteersExportId.current!);

                    showNotification({
                        color: 'success',
                        icon: 'cloud-arrow-down',
                        text: translate('l_export_de_vos_54640', exportResult.url)
                    });
                }
            }, CHECK_INTERVAL_TIME);
        },
        []
    );
    const { mutate: volunteersImportResultMutation } = useVolunteersImportResultMutation();
    const checkVolunteersImportId = React.useRef<number | null>(null);
    const checkVolunteersImport = React.useCallback(
        (organizationId: OrganizationId, jobId: UUID) => {
            checkVolunteersImportId.current = window.setInterval(async () => {
                const {
                    volunteersImportResult: { state, event }
                } = await volunteersImportResultMutation({
                    organizationId,
                    jobId
                });

                if (state === VolunteersImportState.Done) {
                    clearInterval(checkVolunteersImportId.current!);

                    showNotification({
                        color: 'success',
                        icon: 'cloud-arrow-up',
                        text: event
                            ? translate('votre_import_es_37822', event.name)
                            : translate('votre_import_es_14145')
                    });
                }
            }, CHECK_INTERVAL_TIME);
        },
        []
    );

    return (
        <NotificationContext.Provider
            value={{
                checkDelegationsImport,
                checkDocumentsExport,
                checkFilesImport,
                checkVolunteersExport,
                checkVolunteersImport,
                showNotification
            }}
        >
            <>
                {isNotificationVisible && (
                    <Notification {...notificationOptions} onClose={onClose} />
                )}

                {props.children}
            </>
        </NotificationContext.Provider>
    );
};

export function useNotificationContext(): INotificationContext {
    return React.useContext(NotificationContext);
}
