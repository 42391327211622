import { Alert } from 'common-front/src/designSystem/components/alert';
import { Button } from 'common-front/src/designSystem/components/button';
import { DateInput } from 'common-front/src/designSystem/components/date/dateInput';
import { Modal } from 'common-front/src/designSystem/components/modal';
import { RichSelect } from 'common-front/src/designSystem/components/richSelect/richSelect';
import { Select } from 'common-front/src/designSystem/components/select/select';
import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { useUserContext } from 'common-front/src/userContext';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    ALL_DOCUMENTSEXPORTSTRATEGY,
    CustomBadge,
    DocumentsExportStrategy,
    EventVolunteersExportFragment,
    SegmentId,
    SupportedLanguage
} from 'common/src/generated/types';
import { useParams, useTranslate } from 'common/src/util/dependencies/dependencies';
import { UUID } from 'common/src/util/uuid';
import { hasGroupedExport } from 'common/src/vo/customBadge';
import { noop } from 'lodash-es';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useNotificationContext } from '../../notifications/notificationContext';

interface IExportAllModalProps {
    badgeType?: CustomBadge;
    endAt?: DateTime;
    isSendLoading: boolean;
    languages: SupportedLanguage[];
    segmentsFolders: EventVolunteersExportFragment['segmentsFolders'];
    showDate?: boolean;
    showStrategy?: boolean;
    startAt?: DateTime;
    title: string;

    onClose(): void;
    onSend(
        segmentsIds: SegmentId[],
        language: SupportedLanguage,
        strategy?: DocumentsExportStrategy,
        date?: DateTime
    ): Promise<UUID>;
}

export const ExportAllModal = (props: IExportAllModalProps) => {
    const translate = useTranslate();
    const { eventId } = useParams();
    const { user } = useUserContext();
    const { checkDocumentsExport } = useNotificationContext();
    const [segmentsIds, setSegmentsIds] = React.useState<SegmentId[]>([
        props.segmentsFolders[0].segments[0].id
    ]);
    const [language, setLanguage] = React.useState(props.languages[0]);
    const [date, setDate] = React.useState(DateTime.invalid('Invalid'));
    const [strategy, setStrategy] = React.useState(DocumentsExportStrategy.OnePerPage);
    const showStrategy = React.useMemo(
        () => props.showStrategy && (!props.badgeType || hasGroupedExport(props.badgeType)),
        [props.badgeType, props.showStrategy]
    );

    return (
        <Modal size="md" onClose={props.onClose}>
            <Flex justify="center">
                <Flex
                    align="center"
                    css={{
                        background: '$primary50',
                        borderRadius: '48px',
                        color: '$primary700',
                        height: '48px',
                        fontSize: '20px',
                        width: '48px'
                    }}
                    justify="center"
                >
                    <I icon="envelope" />
                </Flex>
            </Flex>

            <Spacer height="4" />

            <Box color="gray900" fontSize="textLg" textAlign="center">
                {props.title}
            </Box>

            <Spacer height="1" />

            <Box
                color="gray500"
                dangerouslySetInnerHTML={{ __html: translate('en_2_clics_rec_97175') }}
                textAlign="center"
            />

            <Spacer height="7" />

            <Alert color="gray" leftIcon="circle-exclamation">
                {translate('l_e_mail_peut_m_13253')}
            </Alert>

            <Spacer height="7" />

            <Flex css={{ flex: '1', overflowY: 'auto' }} direction="column">
                <RichSelect
                    isSearchVisible={true}
                    label={translate('vue_s_personna_35768')}
                    multiple={true}
                    placeholder={translate('s_lectionnez_un_53633')}
                    values={segmentsIds}
                    onChange={setSegmentsIds}
                >
                    {props.segmentsFolders.map((sf) => (
                        <optgroup key={sf.id} label={sf.name}>
                            {sf.segments.map(({ id, name }) => (
                                <option key={id} value={id}>
                                    {name}
                                </option>
                            ))}
                        </optgroup>
                    ))}
                </RichSelect>

                {props.languages.length > 1 && (
                    <>
                        <Spacer height="4" />

                        <Select
                            label={translate('Language')}
                            placeholder={translate('Language')}
                            value={language}
                            onChange={(newLanguage) => {
                                setLanguage(newLanguage as SupportedLanguage);
                            }}
                        >
                            {props.languages.map((l) => (
                                <option key={l} value={l}>
                                    {l}
                                </option>
                            ))}
                        </Select>
                    </>
                )}

                {props.showDate && (
                    <>
                        <Spacer height="4" />

                        <DateInput
                            label={translate('exportez_vos_do_87332')}
                            max={props.endAt}
                            min={props.startAt}
                            value={date}
                            onChange={setDate}
                        />
                    </>
                )}

                {showStrategy && (
                    <>
                        <Spacer height="4" />

                        <Select
                            label={translate('strat_gie_44389')}
                            value={strategy}
                            onChange={(newStrategy) =>
                                setStrategy(newStrategy as DocumentsExportStrategy)
                            }
                        >
                            {ALL_DOCUMENTSEXPORTSTRATEGY.map((documentExportStrategy) => (
                                <option key={documentExportStrategy} value={documentExportStrategy}>
                                    {translate(documentExportStrategy)}
                                </option>
                            ))}
                        </Select>
                    </>
                )}

                <Spacer height="4" />

                <TextInput
                    label={translate('Email')}
                    state="disabled"
                    value={user?.email ?? ''}
                    onChange={noop}
                />
            </Flex>

            <Spacer height="7" />

            <Flex gap="3" justify="end">
                <Button
                    isLoading={props.isSendLoading}
                    onClick={async () => {
                        const jobId = await props.onSend(
                            segmentsIds,
                            language,
                            strategy,
                            date.isValid ? date : undefined
                        );

                        checkDocumentsExport(eventId, jobId);

                        props.onClose();
                    }}
                >
                    {translate('envoyer_26609')}
                </Button>

                <Button color="white" onClick={props.onClose}>
                    {translate('annuler_48254')}
                </Button>
            </Flex>
        </Modal>
    );
};
