import { Button } from 'common-front/src/designSystem/components/button';
import { CheckboxText } from 'common-front/src/designSystem/components/checkbox';
import { RadioText } from 'common-front/src/designSystem/components/radio';
import { TextInput } from 'common-front/src/designSystem/form/textInput';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { Row } from 'common/src/designSystem/components/table/row';
import { AccreditationDisplay, EventAccreditationsFragment } from 'common/src/generated/types';
import { ICreateUpdateDelegationValues } from 'common/src/input/delegationInput';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { useService } from 'common/src/util/dependencies/dependencies';
import { LocaleFormats } from 'common/src/util/luxon';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';

interface IDelegationAccreditationSlotProps {
    accreditationDisplay: AccreditationDisplay;
    slot: EventAccreditationsFragment['accreditationsCategories'][0]['accreditations'][0]['slots'][0];
    values: ICreateUpdateDelegationValues['delegation'];

    change(name: string, value: any): void;
}

export const DelegationAccreditationSlot = (props: IDelegationAccreditationSlotProps) => {
    const { translate } = useHeavent();
    const dateTimeService = useService(DateTimeService);
    const index = React.useMemo(
        () =>
            props.values.accreditationsSlots.findIndex(
                (da) => da.accreditationSlotId === props.slot.id
            )!,
        [props.values, props.slot]
    );
    const delegationAccreditationSlot = props.values.accreditationsSlots[index];
    const prefix = `delegation.accreditationsSlots[${index}]`;
    const state = delegationAccreditationSlot?.isDefault ? 'checked' : 'unchecked';
    const onRadioStateChange = React.useCallback(
        (state) => {
            props.change(`${prefix}.isDefault`, state === 'checked');

            if (state === 'checked') {
                props.values.accreditationsSlots.forEach((da, daIndex) => {
                    if (
                        da.accreditationSlotId !==
                            delegationAccreditationSlot.accreditationSlotId &&
                        da.accreditationId === delegationAccreditationSlot.accreditationId
                    ) {
                        props.change(`delegation.accreditationsSlots[${daIndex}].isDefault`, false);
                    }
                });
            }

            if (state === 'unchecked') {
                props.change(`${prefix}.isVisible`, true);
            }
        },
        [delegationAccreditationSlot, props.values, props.change]
    );
    const onCheckboxStateChange = React.useCallback(
        (state) => {
            props.change(`${prefix}.isDefault`, state === 'checked');

            if (state === 'unchecked') {
                props.change(`${prefix}.isVisible`, true);
            }
        },
        [props.change]
    );

    return (
        <Row>
            <Cell css={{ textTransform: 'capitalize' }}>
                {props.slot.date
                    ? dateTimeService.toLocaleString(
                          props.slot.date,
                          LocaleFormats.DateOnly.WeekdayShortMonthShort
                      )
                    : '-'}
            </Cell>

            <Cell>{isNonEmptyString(props.slot.name) ? props.slot.name : '-'}</Cell>

            <Cell>
                {props.accreditationDisplay === AccreditationDisplay.Radio ? (
                    <RadioText state={state} onClick={onRadioStateChange}>
                        {translate('accr_ditation_p_28030')}
                    </RadioText>
                ) : (
                    <CheckboxText state={state} onClick={onCheckboxStateChange}>
                        {translate('accr_ditation_p_28030')}
                    </CheckboxText>
                )}
            </Cell>

            <Cell width={100}>
                <Button
                    color="white"
                    disabled={!delegationAccreditationSlot.isDefault}
                    leftIcon={delegationAccreditationSlot.isVisible ? 'eye' : 'eye-slash'}
                    onClick={() => {
                        props.change(`${prefix}.isVisible`, !delegationAccreditationSlot.isVisible);
                    }}
                />
            </Cell>

            <Cell width={130}>
                <TextInput name={`${prefix}.maxResources`} />
            </Cell>
        </Row>
    );
};
