import { FormModal } from 'common-front/src/designSystem/components/formModal';
import { TextInput } from 'common-front/src/designSystem/form/textInput';
import { EventId, FormId, OrganizationId } from 'common/src/generated/types';
import { FormInputService, ICreateFormValues } from 'common/src/input/formInput';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { Emptyable } from 'common/src/util/emptyable';
import * as React from 'react';
import { useFormCreateMutation } from '../../generated/graphqlHooks';

interface ICreateFormModalProps {
    eventId: Emptyable<EventId>;
    organizationId: OrganizationId;

    onClose(): void;
    onSuccess(formId: FormId): void;
}

export const CreateFormModal = (props: ICreateFormModalProps) => {
    const translate = useTranslate();
    const formInput = useService(FormInputService);
    const { mutate } = useFormCreateMutation();

    return (
        <FormModal
            buttonText={translate('cr_er_le_formul_42369')}
            icon="sliders"
            initialValues={{
                form: formInput.formInputDefault()
            }}
            schema={formInput.createFormSchema()}
            size="md"
            title={translate('nouveau_formula_44488')}
            onClose={props.onClose}
            onSubmit={async (values: ICreateFormValues) => {
                const { formCreate } = await mutate({
                    organizationId: props.organizationId,
                    eventId: props.eventId,
                    form: values.form
                });

                props.onSuccess(formCreate.id);
            }}
        >
            <TextInput label={translate('nom_du_formulai_14952')} name="form.name" />
        </FormModal>
    );
};
