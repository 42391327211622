import * as React from 'react';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { BadgePastourelleBack } from './badgePastourelleBack';
import { BadgePastourelleFront } from './badgePastourelleFront';

interface IBadgePastourelleA6Props {
    event: DocumentUserCustomBadgeFragment;
}

export const BadgePastourelle = (props: IBadgePastourelleA6Props) => (
    <>
        <BadgePastourelleFront event={props.event} />

        <BadgePastourelleBack />
    </>
);
