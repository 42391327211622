import { CheckboxText } from 'common-front/src/designSystem/components/checkbox';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { UserPlanningList } from 'common/src/documents/userPlanningList/userPlanningList';
import { DocumentType, UserPlanningListDocumentQuery } from 'common/src/generated/types';
import { getDocumentFullPath } from 'common/src/util/documentsPaths';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { getDefaultUserPlanningListOptions } from 'common/src/vo/eventDocument';
import * as React from 'react';
import { useUserPlanningListDocumentQuery } from '../../generated/graphqlHooks';
import { DocumentHeader } from './documentHeader';
import { DocumentOptions, useDocumentOptions } from './documentOptions';
import { DocumentsAssignedUserSelector } from './documentsAssignedUserSelector';
import { DocumentShadow } from './documentShadow';

interface IUserPlanningListContentProps {
    data: UserPlanningListDocumentQuery;
}

const UserPlanningListContent = (props: IUserPlanningListContentProps) => {
    const {
        translate,
        params: { organizationId, eventId }
    } = useHeavent();
    const [options, setOptions] = useDocumentOptions(
        props.data.event.document.options,
        getDefaultUserPlanningListOptions(),
        eventId,
        props.data.event.document.id
    );
    const ui = props.data.event.volunteerRegistration.userInfo;

    return (
        <Flex css={{ flex: '1', overflow: 'hidden' }} gap="7" width={1}>
            <DocumentOptions>
                <CheckboxText
                    state={options.showHours ? 'checked' : 'unchecked'}
                    onClick={(state) => {
                        setOptions({ showHours: state === 'checked' });
                    }}
                >
                    {translate('afficher_le_tot_23855')}
                </CheckboxText>
            </DocumentOptions>

            <Flex css={{ flex: '1' }} direction="column">
                <DocumentsAssignedUserSelector
                    downloadPath={getDocumentFullPath(ui.links?.userPlanningListLink ?? '', 'pdf')}
                    getPath={(newUserInfoId) =>
                        HeaventPaths.DOCUMENTS_USER_PLANNING_LIST(
                            organizationId,
                            eventId,
                            newUserInfoId
                        )
                    }
                />

                <DocumentShadow width={1}>
                    <UserPlanningList event={props.data.event} options={options} />
                </DocumentShadow>
            </Flex>
        </Flex>
    );
};

export const UserPlanningListDocument = () => {
    const {
        translate,
        params: { organizationId, eventId, userInfoId }
    } = useHeavent();
    const { data, loader } = useUserPlanningListDocumentQuery({ eventId, userInfoId });

    return (
        <Flex css={{ overflowY: 'auto', padding: '$7' }} direction="column" height={1} width={1}>
            <DocumentHeader
                documentType={DocumentType.UserPlanningList}
                endAt={data.event?.endAt}
                eventId={eventId}
                languages={data.event?.languages ?? []}
                organizationId={organizationId}
                segmentsFolders={data.event?.segmentsFolders ?? []}
                showDate={true}
                startAt={data.event?.startAt}
                title={translate('plannings_indiv_97766')}
            />

            <Spacer height="7" />

            {loader || <UserPlanningListContent data={data} />}
        </Flex>
    );
};
