import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { debounce } from 'lodash-es';
import * as React from 'react';
import { useUniqueIds } from '../../hooks/useUniqueIds';
import { IInputProps } from './input/commonInputProps';
import { Description } from './input/description';
import { Hint } from './input/hint';
import { Label } from './input/label';
import { StyledInputContainer } from './input/styledInputContainer';

export interface IColorInputProps extends Omit<IInputProps, 'type'> {
    value: string;
    initialValue?: string;

    onChange(value: string): void;
}

export const ColorInput = ({
    onChange,
    css,
    label,
    description,
    value,
    hint,
    state,
    initialValue,
    icon,
    ...rest
}: IColorInputProps) => {
    const translate = useTranslate();
    const inputRef = React.useRef<HTMLInputElement | null>(null);
    const onChangedDebounced = debounce((value: string) => {
        onChange(value);
    }, 500);
    const { inputId, descId, errorId } = useUniqueIds();

    return (
        <Flex css={css} direction="column" width={1}>
            <Label htmlFor={inputId} isClickable={false}>
                {label}
            </Label>

            <Description id={descId}>{description}</Description>

            {(label || description) && <Spacer height="1" />}

            <StyledInputContainer
                cursor="default"
                icon={icon}
                state={state}
                onClick={() => {
                    inputRef.current?.click();
                }}
            >
                <Flex
                    align="center"
                    css={{
                        flex: '1'
                    }}
                    height={1}
                >
                    <Box
                        css={{
                            background: value,
                            borderRadius: '2px',
                            height: '24px',
                            width: '24px',
                            '& input': {
                                background: 'transparent',
                                border: 'none',
                                borderRadius: '2px',
                                cursor: 'pointer',
                                height: '24px',
                                width: '24px',
                                '&::-webkit-color-swatch-wrapper': {
                                    padding: 0
                                },
                                '&::-webkit-color-swatch': {
                                    borderRadius: '2px',
                                    border: 'none'
                                },
                                '&::-moz-color-swatch': {
                                    border: 'none'
                                }
                            }
                        }}
                    >
                        <input
                            ref={inputRef}
                            aria-describedby={description ? descId : undefined}
                            id={inputId}
                            type="color"
                            value={value}
                            onChange={(e) => {
                                onChangedDebounced(e.target.value);
                            }}
                            {...rest}
                        />
                    </Box>

                    <Spacer width="3" />

                    <Box
                        color="gray800"
                        css={{
                            flex: '1',
                            '& input[type=text]': {
                                background: 'transparent',
                                border: 'none',
                                color: '$gray800',
                                height: '100%',
                                outline: 'none',
                                width: '100%'
                            }
                        }}
                        height={1}
                        onClick={(e) => {
                            e.stopPropagation();
                            e.nativeEvent.stopImmediatePropagation();
                        }}
                    >
                        <input
                            aria-describedby={description ? descId : undefined}
                            aria-errormessage={state === 'error' ? errorId : undefined}
                            aria-invalid={state === 'error'}
                            disabled={state === 'disabled'}
                            id={inputId}
                            type="text"
                            value={value}
                            onChange={(e) => {
                                onChange(e.target.value);
                            }}
                            {...rest}
                        />
                    </Box>
                </Flex>

                {initialValue && (
                    <Box
                        color="primary700"
                        css={{ cursor: 'pointer', userSelect: 'none' }}
                        fontWeight="medium"
                        onClick={(e) => {
                            e.nativeEvent.stopImmediatePropagation();
                            e.stopPropagation();

                            onChange(initialValue!);
                        }}
                    >
                        {translate('remettre_sa_v_90478')}
                    </Box>
                )}
            </StyledInputContainer>

            <Hint id={errorId} state={state}>
                {hint}
            </Hint>
        </Flex>
    );
};
