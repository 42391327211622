import { Flex } from 'common/src/designSystem/components/flex';
import {
    FormCustomFieldFragment,
    FormElementsQuery,
    FormElementType
} from 'common/src/generated/types';
import { assertUnreachable } from 'common/src/util/assertUnreachable';
import * as React from 'react';
import { DragDropContext, Droppable, DroppableProvided } from 'react-beautiful-dnd';
import { FormField } from './formField';
import { FormSection } from './formSection';
import { FormText } from './formText';

interface IFormElementsListProps {
    customFields: FormCustomFieldFragment[];
    elements: FormElementsQuery['organization']['form']['elements'];

    fieldsToggleMandatory(index: number, isMandatory: boolean): void;
    onDelete(index: number): void;
    onDragEnd(params: any): void;
    sectionOnRename(index: number, name: string): void;
    textOnEdit(index: number, text: string): void;
    updateCustomField(customField: FormCustomFieldFragment): void;
}

export const FormElementsList = (props: IFormElementsListProps) => {
    const idToCustomField = React.useMemo(
        () => Object.fromEntries(props.customFields.map((cf) => [cf.id, cf])),
        [props.customFields]
    );

    return (
        <DragDropContext onDragEnd={props.onDragEnd}>
            <Droppable droppableId="1">
                {(droppableProvided: DroppableProvided) => (
                    <Flex
                        direction="column"
                        gap="3"
                        width={1}
                        {...droppableProvided.droppableProps}
                        ref={droppableProvided.innerRef}
                    >
                        {props.elements.map((element, index) => {
                            switch (element.elementType) {
                                case FormElementType.Field:
                                    return (
                                        <FormField
                                            key={element.id}
                                            elementId={element.id}
                                            field={idToCustomField[element.customFieldId!]}
                                            index={index}
                                            isMandatory={element.isMandatory!}
                                            toggleIsMandatory={props.fieldsToggleMandatory}
                                            updateCustomField={props.updateCustomField}
                                            onDelete={props.onDelete}
                                        />
                                    );
                                case FormElementType.Section:
                                    return (
                                        <FormSection
                                            key={element.id}
                                            elementId={element.id}
                                            index={index}
                                            name={element.section!}
                                            onDelete={props.onDelete}
                                            onRename={props.sectionOnRename}
                                        />
                                    );
                                case FormElementType.Text:
                                    return (
                                        <FormText
                                            key={element.id}
                                            elementId={element.id}
                                            index={index}
                                            text={element.text!}
                                            onDelete={props.onDelete}
                                            onEdit={props.textOnEdit}
                                        />
                                    );
                                default:
                                    return assertUnreachable(element.elementType);
                            }
                        })}

                        {droppableProvided.placeholder}
                    </Flex>
                )}
            </Droppable>
        </DragDropContext>
    );
};
