import { BadgesPlus } from 'common-front/src/components/badgesPlus/badgesPlus';
import { Accreditation, AccreditationProperty, Tag } from 'common/src/generated/types';
import * as React from 'react';
import { AccreditationsTagsCellInput } from './accreditationsTagsCellInput';
import { AccreditationsUpdateCell } from './accreditationsUpdateCell';

interface IAccreditationsTagsCellProps {
    accreditation: Pick<Accreditation, 'id'> & { tags: Array<Pick<Tag, 'id' | 'name'>> };

    reload(): void;
}

export const AccreditationsTagsCell = (props: IAccreditationsTagsCellProps) => {
    const tags = React.useMemo(() => props.accreditation.tags || [], [props.accreditation]);
    const badges = React.useMemo(
        () =>
            tags.map((tag) => ({
                id: tag.id,
                color: 'gray',
                icon: null,
                text: tag.name
            })),
        [tags]
    );

    return (
        <AccreditationsUpdateCell
            accreditationId={props.accreditation.id}
            initialValue={tags.map((t) => t.id)}
            property={AccreditationProperty.Tag}
            reload={props.reload}
            renderInput={(value, setValue) => (
                <AccreditationsTagsCellInput setValue={setValue} value={value} />
            )}
        >
            <BadgesPlus badges={badges} />
        </AccreditationsUpdateCell>
    );
};
