import * as React from 'react';
import { Field } from 'react-final-form';
import { isInvalid } from '../../util/isInvalid';
import { FloatInput as FloatInputComponent } from '../components/floatInput';
import { IFormInputProps, INumberInputProps } from '../components/input/commonInputProps';

export const FloatInput = ({ name, hint, state, ...rest }: IFormInputProps<INumberInputProps>) => (
    <Field
        name={name}
        render={(fieldProps) => {
            const invalid = isInvalid(fieldProps.meta);

            return (
                <FloatInputComponent
                    hint={invalid ? fieldProps.meta.error : hint}
                    name={name}
                    state={invalid ? 'error' : state}
                    value={fieldProps.input.value}
                    onBlur={fieldProps.input.onBlur}
                    onChange={fieldProps.input.onChange}
                    onFocus={fieldProps.input.onFocus}
                    {...rest}
                />
            );
        }}
    />
);
