import { compact, sortBy, uniq } from 'lodash-es';
import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { isNonEmptyString } from '../../../util/string';

interface IBadgeAuborddeleauProps {
    event: DocumentUserCustomBadgeFragment;
}

export const BadgeAuborddeleau = (props: IBadgeAuborddeleauProps) => {
    const vr = props.event.volunteerRegistration;
    const ui = vr.userInfo;
    const position = sortBy(
        uniq(uniq(vr.positionsSlotsUsersInfos.map(({ position }) => position.name)))
    ).join(', ');
    const acronyms = compact(
        vr.accreditationsUsersInfos.map(({ accreditation }) => accreditation.acronym)
    );

    return (
        <Flex
            css={{
                background: `url(https://assets.recrewteer.com/badges/auborddeleau/badge_v2.jpg) no-repeat`,
                backgroundSize: 'cover',
                fontFamily: '$roboto',
                position: 'relative'
            }}
            height={321}
            width={204}
        >
            <Flex
                align="center"
                css={{
                    color: '#1d1d1b',
                    fontSize: '12px',
                    fontWeight: '500',
                    position: 'absolute',
                    top: '92px'
                }}
                height={20}
                justify="center"
                width={1}
            >
                {ui.name}
            </Flex>

            <Box
                css={{
                    left: '8px',
                    position: 'absolute',
                    top: '145px'
                }}
                height={79}
                width={79}
            >
                {isNonEmptyString(ui.picture?.url) && (
                    <img height="100%" src={ui.picture!.url} width="100%" />
                )}
            </Box>

            <Flex
                align="center"
                css={{
                    color: '#1d1d1b',
                    fontSize: '13px',
                    fontWeight: '500',
                    position: 'absolute',
                    top: '256px'
                }}
                height={20}
                justify="center"
                width={1}
            >
                {position}
            </Flex>

            {acronyms.includes('A') && (
                <Flex
                    align="center"
                    css={{
                        color: '#1d1d1b',
                        fontSize: '14px',
                        fontWeight: '700',
                        position: 'absolute',
                        right: '7px',
                        top: '122px'
                    }}
                    height={23}
                    justify="center"
                    width={23}
                >
                    A
                </Flex>
            )}

            {acronyms.includes('B') && (
                <Flex
                    align="center"
                    css={{
                        color: '#1d1d1b',
                        fontSize: '14px',
                        fontWeight: '700',
                        position: 'absolute',
                        right: '7px',
                        top: '147px'
                    }}
                    height={23}
                    justify="center"
                    width={23}
                >
                    B
                </Flex>
            )}

            {/*acronyms.includes('C') && (
                <Flex
                    align="center"
                    justify="center"
                    height={23}
                    width={23}
                    css={{
                        color: '#1d1d1b',
                        fontSize: '14px',
                        fontWeight: '700',
                        position: 'absolute',
                        right: '7px',
                        top: '171px'
                    }}
                >
                    C
                </Flex>
            )*/}

            {acronyms.includes('JE') && (
                <Flex
                    align="center"
                    css={{
                        backgroundColor: `rgba(255, 255, 255, 0.81)`,
                        color: '#1d1d1b',
                        fontSize: '13px',
                        fontWeight: '500',
                        left: '8px',
                        position: 'absolute',
                        px: '6px',
                        top: '287px'
                    }}
                    height={18}
                    justify="center"
                >
                    Jeu
                </Flex>
            )}

            {acronyms.includes('VE') && (
                <Flex
                    align="center"
                    css={{
                        backgroundColor: `rgba(255, 255, 255, 0.81)`,
                        color: '#1d1d1b',
                        fontSize: '13px',
                        fontWeight: '500',
                        left: '50px',
                        position: 'absolute',
                        px: '6px',
                        top: '287px'
                    }}
                    height={18}
                    justify="center"
                >
                    Ven
                </Flex>
            )}

            {acronyms.includes('SA') && (
                <Flex
                    align="center"
                    css={{
                        backgroundColor: `rgba(255, 255, 255, 0.81)`,
                        color: '#1d1d1b',
                        fontSize: '13px',
                        fontWeight: '500',
                        left: '93px',
                        position: 'absolute',
                        px: '6px',
                        top: '287px'
                    }}
                    height={18}
                    justify="center"
                >
                    Sam
                </Flex>
            )}

            {acronyms.includes('DI') && (
                <Flex
                    align="center"
                    css={{
                        backgroundColor: `rgba(255, 255, 255, 0.81)`,
                        color: '#1d1d1b',
                        fontSize: '13px',
                        fontWeight: '500',
                        left: '140px',
                        position: 'absolute',
                        px: '6px',
                        top: '287px'
                    }}
                    height={18}
                    justify="center"
                >
                    Dim
                </Flex>
            )}
        </Flex>
    );
};
