import { DeleteModal } from 'common-front/src/designSystem/components/deleteModal';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { EventId, PositionsQuery, TraceId } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { useTraceDeleteMutation } from '../../../generated/graphqlHooks';
import { AddTrace } from './addTrace';

interface ITraceProps {
    eventId: EventId;
    isHidden: boolean;
    trace: PositionsQuery['event']['traces'][0];

    reload(): void;
    toggleVisible(id: TraceId): void;
}

export const Trace = (props: ITraceProps) => {
    const translate = useTranslate();
    const { mutate: deleteTrace } = useTraceDeleteMutation();
    const [isDeleteOpen, setIsDeleteOpen] = React.useState(false);
    const [isEditOpen, setIsEditOpen] = React.useState(false);

    return (
        <Flex
            align="center"
            css={{
                py: '$2',
                '&:not(:last-child)': {
                    borderBottom: '1px solid $gray200'
                }
            }}
            gap="2"
        >
            <Box
                css={{
                    background: props.trace.color,
                    borderRadius: '$1',
                    height: '16px',
                    width: '16px'
                }}
            />

            <Box color="gray900" css={{ flex: '1', ellipsis: true }}>
                {props.trace.name}
            </Box>

            <Box
                color="gray700"
                css={{ cursor: 'pointer' }}
                onClick={() => {
                    props.toggleVisible(props.trace.id);
                }}
            >
                <I icon={props.isHidden ? 'eye-slash' : 'eye'} />
            </Box>

            <Box
                color="gray700"
                css={{ cursor: 'pointer' }}
                onClick={() => {
                    setIsEditOpen(true);
                }}
            >
                <I icon="pen" />
            </Box>

            <Box
                color="gray700"
                css={{ cursor: 'pointer' }}
                onClick={() => {
                    setIsDeleteOpen(true);
                }}
            >
                <I icon="xmark" />
            </Box>

            {isDeleteOpen && (
                <DeleteModal
                    buttonText={translate('supprimer_43083')}
                    subtext={translate('_tes_vous_s_r_d_34035')}
                    text={translate('suppression_de_73669', props.trace.name)}
                    onClose={() => {
                        setIsDeleteOpen(false);
                    }}
                    onDelete={async () => {
                        await deleteTrace({
                            eventId: props.eventId,
                            traceId: props.trace.id
                        });

                        props.reload();
                    }}
                />
            )}

            {isEditOpen && (
                <AddTrace
                    eventId={props.eventId}
                    reload={props.reload}
                    trace={props.trace}
                    traceId={props.trace.id}
                    onClose={() => {
                        setIsEditOpen(false);
                    }}
                />
            )}
        </Flex>
    );
};
