import { ToggleText } from 'common-front/src/designSystem/components/toggle';
import { Box } from 'common/src/designSystem/components/box';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { FormsCustomFieldInput } from 'common/src/generated/types';
import { isNonEmptyArray } from 'common/src/util/array';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { FormParametersGeneralCustomFields } from './formParametersGeneralCustomFields';

export const FormParametersGeneralCustomFieldsToggler = (
    props: React.ComponentProps<typeof FormParametersGeneralCustomFields> & {
        name: string;
        values: FormsCustomFieldInput[];

        change(name: string, value: any): void;
    }
) => {
    const translate = useTranslate();
    const [areCustomFieldsOpen, setAreCustomFieldsOpen] = React.useState(
        isNonEmptyArray(props.values)
    );
    const toggle = React.useCallback(
        (value: boolean) => {
            setAreCustomFieldsOpen(value);

            if (!value) {
                props.change(props.name, []);
            }
        },
        [props.name, props.change, setAreCustomFieldsOpen]
    );

    return (
        <>
            <Box color="gray800" fontSize="textMd" fontWeight="semiBold">
                {translate('d_finir_la_vale_27863')}
            </Box>

            <Spacer height="1" />

            <Box color="gray500">{translate('choisissez_une_24029')}</Box>

            <Spacer height="4" />

            <ToggleText value={areCustomFieldsOpen} onChange={toggle}>
                {translate('je_souhaite_d_f_72912')}
            </ToggleText>

            {areCustomFieldsOpen && (
                <>
                    <Spacer height="3" />
                    <FormParametersGeneralCustomFields {...props} />
                </>
            )}
        </>
    );
};
