import { Flex } from 'common/src/designSystem/components/flex';
import * as React from 'react';
import { useRecentVolunteersSearchesMobileQuery } from '../../../generated/graphqlHooks';
import { useParams, useTranslate } from 'common/src/util/dependencies/dependencies';
import { Box } from 'common/src/designSystem/components/box';
import { styled } from 'common/src/designSystem/components/stitches';

const _i = styled('i', {
    fontSize: '$textXl'
});

interface IVolunteersRecentSearchesMobileProps {
    replaySearch: (search: string) => void;
}

export const VolunteersRecentSearchesMobile = ({
    replaySearch
}: IVolunteersRecentSearchesMobileProps) => {
    const { eventId } = useParams();
    const { data, loader } = useRecentVolunteersSearchesMobileQuery({ eventId });
    const translate = useTranslate();

    if (loader) {
        return loader;
    } else {
        const searches = data.event.recentVolunteersRegistrationsSearches;

        return (
            <Flex css={{ padding: '$6 0' }} direction="column" gap="6">
                <Box fontSize="textMd" fontWeight="semiBold">
                    {translate('recherches_r_ce_82891', searches.length)}
                </Box>
                {searches.map((search, index) => (
                    <Flex
                        key={index}
                        align="center"
                        gap="2"
                        onClick={() => {
                            replaySearch(search);
                        }}
                    >
                        <_i className="fa-regular fa-clock" />
                        <Box>{search}</Box>
                    </Flex>
                ))}
            </Flex>
        );
    }
};
