import { Blank } from 'common-front/src/components/blank/blank';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Svgs } from 'common-front/src/util/assets';
import { Box } from 'common/src/designSystem/components/box';
import { Spacer } from 'common/src/designSystem/components/spacer';
import * as React from 'react';
import { useUserOverlayIntegrationsQuery } from '../../../generated/graphqlHooks';
import { UserOverlayWeezevent } from './userOverlayWeezevent';
import { UserOverlayWeezeventV2 } from './userOverlayWeezeventV2';

export const UserOverlayIntegrations = () => {
    const {
        translate,
        params: { eventId, userInfoId }
    } = useHeavent();
    const { data, loader, reload } = useUserOverlayIntegrationsQuery({
        eventId,
        userInfoId
    });

    return (
        loader || (
            <>
                <Spacer height="6" />

                {data.event.volunteerRegistration.weezeventParticipantInfo ? (
                    <UserOverlayWeezevent
                        eventId={eventId}
                        participantInfo={data.event.volunteerRegistration.weezeventParticipantInfo}
                        reload={reload}
                        userInfoId={data.event.volunteerRegistration.userInfo.id}
                    />
                ) : data.event.weezevent?.id ? (
                    <UserOverlayWeezeventV2
                        volunteerRegistration={data.event.volunteerRegistration}
                    />
                ) : (
                    <Box height={250} width={1}>
                        <Blank
                            imageSrc={Svgs.Done}
                            title={translate(
                                '_1_n_est_pas_s_57616',
                                data.event.volunteerRegistration.userInfo.firstName
                            )}
                        />
                    </Box>
                )}
            </>
        )
    );
};
