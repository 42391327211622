import { Alert } from 'common-front/src/designSystem/components/alert';
import { Button } from 'common-front/src/designSystem/components/button';
import { Modal } from 'common-front/src/designSystem/components/modal';
import { DateInput } from 'common-front/src/designSystem/form/date/dateInput';
import { TextInput } from 'common-front/src/designSystem/form/textInput';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { EventsQuery } from 'common/src/generated/types';
import {
    EventDuplicateInputService,
    IEventDuplicateValues
} from 'common/src/input/eventDuplicateInput';
import { ValidateService } from 'common/src/services/validateService';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { DateTime } from 'luxon';
import * as React from 'react';
import { Form } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { useEventDuplicateMutation } from '../../generated/graphqlHooks';
import { useOrganizationContext } from '../../organization/organizationContext';

interface IEventDuplicatePopupProps {
    event: EventsQuery['organization']['events']['nodes'][0];

    onClose(): void;
    reload(): void;
}

export const EventDuplicateModal = (props: IEventDuplicatePopupProps) => {
    const translate = useTranslate();
    const eventDuplicateInput = useService(EventDuplicateInputService);
    const validateService = useService(ValidateService);
    const { mutate, isLoading } = useEventDuplicateMutation();
    const { reload } = useOrganizationContext();
    const diff = props.event.endAt.diff(props.event.startAt, 'days').days;

    return (
        <Form
            initialValues={{
                name: translate('copie_de_1_62900', props.event.name),
                startAt: props.event.startAt,
                endAt: props.event.endAt
            }}
            render={({ form, handleSubmit, invalid, values }) => (
                <Modal size="md" onClose={props.onClose}>
                    <Box color="primary600" fontSize="displayXs" textAlign="center">
                        <I icon="clone" />
                    </Box>

                    <Spacer height="7" />

                    <Box color="gray900" fontSize="textLg" textAlign="center">
                        {translate('dupliquer_l_v_72541', props.event.name)}
                    </Box>

                    <Spacer height="3" />

                    <Alert color="gray" leftIcon="circle-exclamation">
                        {translate('tous_les_param_24952')}
                    </Alert>

                    <Spacer height="6" />

                    <TextInput label={`Nom de l'événement`} name="name" />

                    <Spacer height="6" />

                    <DateInput
                        label={translate('nouvelle_date_d_75723')}
                        min={DateTime.utc()}
                        name="startAt"
                    />

                    <Spacer height="4" />

                    <DateInput
                        label={translate('nouvelle_date_d_93958')}
                        min={DateTime.utc()}
                        name="endAt"
                    />

                    <OnChange name="startAt">
                        {(startAt: DateTime) => {
                            const newEndAt = startAt.plus({ days: diff });

                            if (!newEndAt.equals(values.endAt)) {
                                form.change('endAt', newEndAt);
                            }
                        }}
                    </OnChange>

                    <OnChange name="endAt">
                        {(endAt: DateTime) => {
                            const newStartAt = endAt.minus({ days: diff });

                            if (!newStartAt.equals(values.startAt)) {
                                form.change('startAt', newStartAt);
                            }
                        }}
                    </OnChange>

                    <Spacer height="7" />

                    <Flex gap="3" justify="end">
                        <Button disabled={invalid} isLoading={isLoading} onClick={handleSubmit}>
                            {translate('dupliquer_28256')}
                        </Button>

                        <Button color="white" onClick={props.onClose}>
                            {translate('annuler_48254')}
                        </Button>
                    </Flex>
                </Modal>
            )}
            validate={validateService.validateForForm(eventDuplicateInput.eventDuplicateSchema())}
            onSubmit={async (values: IEventDuplicateValues) => {
                await mutate({
                    eventId: props.event.id,
                    name: values.name,
                    startAt: values.startAt
                });

                props.onClose();
                reload();
                props.reload();
            }}
        />
    );
};
