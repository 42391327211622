import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Badge } from 'common/src/designSystem/components/badge';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { HeaderRow } from 'common/src/designSystem/components/table/headerRow';
import { Row } from 'common/src/designSystem/components/table/row';
import { Table } from 'common/src/designSystem/components/table/table';
import {
    EventId,
    OrganizationId,
    UserProfileAccreditationsQuery
} from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { useService } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { LocaleFormats } from 'common/src/util/luxon';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';

interface IUserProfileWishedAccreditationsProps {
    eventId: EventId;
    formsUsersInfos: UserProfileAccreditationsQuery['organization']['userInfo']['formsUsersInfos'];
    organizationId: OrganizationId;
}

export const UserProfileWishedAccreditations = (props: IUserProfileWishedAccreditationsProps) => {
    const { history, translate } = useHeavent();
    const dateTimeService = useService(DateTimeService);

    return (
        <Flex
            css={{
                background: 'white',
                border: '1px solid $gray200',
                borderRadius: '$2',
                boxShadow: '$xs',
                overflow: 'hidden'
            }}
            direction="column"
        >
            <Flex align="center" css={{ padding: '$5 $6' }}>
                <Flex direction="column">
                    <Box font="gray900 textMd semiBold">{translate('accr_ditations_74375')}</Box>
                </Flex>
            </Flex>

            <Table hideBorder={true}>
                <HeaderRow>
                    <HeaderCell>{translate('nom_de_l_accr_d_11908')}</HeaderCell>
                    <HeaderCell>{translate('Date')}</HeaderCell>
                    <HeaderCell>{translate('Form')}</HeaderCell>
                </HeaderRow>

                {props.formsUsersInfos.flatMap((fui) =>
                    fui.accreditationsSlots.map((accreditationSlot) => (
                        <Row key={`${fui.form.id}-${accreditationSlot.id}`}>
                            <Cell>
                                {accreditationSlot.accreditation.name}
                                {isNonEmptyString(accreditationSlot.name)
                                    ? ` - ${accreditationSlot.name}`
                                    : ''}
                            </Cell>
                            <Cell>
                                <Box color="gray500">
                                    {accreditationSlot.date
                                        ? dateTimeService.toLocaleString(
                                              accreditationSlot.date,
                                              LocaleFormats.DateOnly.MonthLong
                                          )
                                        : '-'}
                                </Box>
                            </Cell>
                            <Cell>
                                <Badge
                                    color="primary"
                                    cursor="pointer"
                                    onClick={() => {
                                        history.push(
                                            HeaventPaths.FORM(
                                                props.organizationId,
                                                props.eventId,
                                                fui.form.id
                                            )
                                        );
                                    }}
                                >
                                    {fui.form.name}
                                </Badge>
                            </Cell>
                        </Row>
                    ))
                )}
            </Table>
        </Flex>
    );
};
