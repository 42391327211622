import { Flex } from 'common/src/designSystem/components/flex';
import {
    EventId,
    OrganizationId,
    SegmentsFolderId,
    SegmentsFoldersSegmentsFragment
} from 'common/src/generated/types';
import { reorder } from 'common/src/util/array';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { Emptyable } from 'common/src/util/emptyable';
import { without } from 'lodash-es';
import * as React from 'react';
import { DragDropContext, Droppable, DroppableProvided } from 'react-beautiful-dnd';
import { CenteredContainer } from '../../../components/centeredContainer/centeredContainer';
import { FullScreenPopup } from '../../../components/fullScreenPopup/fullScreenPopup';
import { Button } from '../../../designSystem/components/button';
import { useSegmentsFoldersReorderMutation } from '../../../generated/graphqlHooks';
import { SegmentsFoldersReorderSegmentFolder } from './segmentsFoldersReorderSegmentFolder';

interface ISegmentsFoldersReorderModalProps {
    eventId: Emptyable<EventId>;
    organizationId: OrganizationId;
    segmentsFolders: SegmentsFoldersSegmentsFragment[];

    onClose(): void;
    onSuccess(): void;
}

export const SegmentsFoldersReorderModal = (props: ISegmentsFoldersReorderModalProps) => {
    const translate = useTranslate();
    const { mutate, isLoading } = useSegmentsFoldersReorderMutation();
    const [segmentsFolders, setSegmentsFolders] = React.useState(
        props.segmentsFolders.map((sf) => ({
            id: sf.id,
            name: sf.name,
            segments: sf.segments.map((s) => ({ id: s.id, name: s.name }))
        }))
    );
    const onSave = React.useCallback(async () => {
        const segmentsFoldersIds = segmentsFolders.map((sf) => sf.id);
        const segmentsOrders = segmentsFolders.map((sf) => ({
            segmentFolderId: sf.id,
            segmentsIds: sf.segments.map((s) => s.id)
        }));

        await mutate({
            organizationId: props.organizationId,
            eventId: props.eventId,
            orders: {
                segmentsFoldersIds,
                segmentsOrders
            }
        });

        props.onClose();
        props.onSuccess();
    }, [segmentsFolders]);
    const onDragEnd = React.useCallback(
        ({ reason, source, destination }) => {
            if (
                reason === 'DROP' &&
                destination &&
                source.index !== destination.index &&
                source.droppableId === 'main' &&
                destination.droppableId === 'main'
            ) {
                setSegmentsFolders(reorder(segmentsFolders, source.index, destination.index));
            } else if (
                reason === 'DROP' &&
                destination &&
                (source.droppableId !== destination.droppableId ||
                    source.index !== destination.index) &&
                source.droppableId.startsWith('drop-sf-') &&
                destination.droppableId.startsWith('drop-sf-')
            ) {
                const sourceSegmentFolderId = parseInt(
                    source.droppableId.substring(8),
                    10
                ) as SegmentsFolderId;
                const destinationSegmentFolderId = parseInt(
                    destination.droppableId.substring(8),
                    10
                ) as SegmentsFolderId;

                if (sourceSegmentFolderId !== destinationSegmentFolderId) {
                    const sourceSegmentFolder = segmentsFolders.find(
                        (sf) => sf.id === sourceSegmentFolderId
                    )!;
                    const destinationSegmentFolder = segmentsFolders.find(
                        (sf) => sf.id === destinationSegmentFolderId
                    )!;
                    const segment = sourceSegmentFolder.segments.find(
                        (_, segmentIndex) => segmentIndex === source.index
                    )!;

                    sourceSegmentFolder.segments = without(sourceSegmentFolder.segments, segment);
                    destinationSegmentFolder.segments.splice(destination.index, 0, segment);
                    destinationSegmentFolder.segments = [...destinationSegmentFolder.segments];

                    setSegmentsFolders([...segmentsFolders]);
                } else {
                    const segmentFolder = segmentsFolders.find(
                        (sf) => sf.id === sourceSegmentFolderId
                    )!;

                    segmentFolder.segments = reorder(
                        segmentFolder.segments,
                        source.index,
                        destination.index
                    );

                    setSegmentsFolders([...segmentsFolders]);
                }
            }
        },
        [segmentsFolders, setSegmentsFolders]
    );

    return (
        <FullScreenPopup
            button={
                <Button isLoading={isLoading} onClick={onSave}>
                    {translate('enregistrer_06519')}
                </Button>
            }
            category={translate('vues_personnali_80652')}
            title={translate('changer_l_ordre_91025')}
            onClose={props.onClose}
        >
            <CenteredContainer css={{ background: 'white' }}>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="main" type="segmentFolder">
                        {(droppableProvided: DroppableProvided) => (
                            <Flex
                                direction="column"
                                gap="6"
                                width={1}
                                {...droppableProvided.droppableProps}
                                ref={droppableProvided.innerRef}
                            >
                                {segmentsFolders.map((segmentFolder, index) => (
                                    <SegmentsFoldersReorderSegmentFolder
                                        key={segmentFolder.id}
                                        index={index}
                                        segmentFolder={segmentFolder}
                                    />
                                ))}

                                {droppableProvided.placeholder}
                            </Flex>
                        )}
                    </Droppable>
                </DragDropContext>
            </CenteredContainer>
        </FullScreenPopup>
    );
};
