import { Box } from 'common/src/designSystem/components/box';
import { CSS, styled } from 'common/src/designSystem/components/stitches';
import * as React from 'react';

const Content = styled('div', {
    margin: 'auto',
    width: '$containerTablet',
    '@desktop': {
        width: '$container'
    }
});

interface ICenteredContainerProps {
    children: React.ReactNode;
    css?: CSS;
}

export const CenteredContainer = React.forwardRef(
    (props: ICenteredContainerProps, ref: React.Ref<HTMLDivElement>) => (
        <Box
            ref={ref}
            css={{
                background: '$gray50',
                overflowX: 'hidden',
                overflowY: 'auto',
                py: '$10',
                ...(props.css as any)
            }}
            height={1}
            width={1}
        >
            <Content>{props.children}</Content>
        </Box>
    )
);
CenteredContainer.displayName = 'CenteredContainer';
