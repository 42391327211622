import { RadioText } from 'common-front/src/designSystem/form/radio';
import { Textarea } from 'common-front/src/designSystem/form/textarea';
import { TextInput } from 'common-front/src/designSystem/form/textInput';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { ICreateUpdateCampaignValues } from 'common/src/input/campaignInput';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { size } from 'common/src/vo/sms';
import * as React from 'react';
import { FormRenderProps } from 'react-final-form';

interface ISmsProps {
    formProps: FormRenderProps<ICreateUpdateCampaignValues>;
}

export const Sms = (props: ISmsProps) => {
    const translate = useTranslate();
    const values = props.formProps.values;
    const { numberOfCharacters, numberOfSms } = size(values.campaign.text, values.customSender);

    return (
        <>
            <Box color="gray800" fontWeight="medium">
                {translate('voulez_vous_per_07528')}
            </Box>

            <Spacer height="2" />

            <RadioText name="customSender" value={false}>
                {translate('non_je_souhait_47056')}
            </RadioText>

            <Spacer height="3" />

            <RadioText name="customSender" value={true}>
                {translate('oui_je_souhait_40974')}
            </RadioText>

            {props.formProps.values.customSender && (
                <>
                    <Spacer height="3" />

                    <Box css={{ paddingLeft: '$6' }}>
                        <TextInput
                            hint={translate('doit_commencer_93759')}
                            name="campaign.sender"
                            placeholder={translate('_metteur_du_mes_17005')}
                        />
                    </Box>
                </>
            )}

            <Spacer height="6" />

            <Textarea
                hint={
                    <Box
                        css={{
                            '& a': {
                                color: '$primary700'
                            }
                        }}
                        dangerouslySetInnerHTML={{
                            __html: translate('vous_pouvez_ajo_23408')
                        }}
                    />
                }
                label={
                    <Flex justify="between">
                        <Box>{translate('contenu_29668')}</Box>

                        <Box color="gray500">
                            {translate('_1_caract_re_04414', numberOfCharacters, numberOfSms)}
                        </Box>
                    </Flex>
                }
                name="campaign.text"
            />

            <Spacer height="7" />
        </>
    );
};
