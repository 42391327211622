import { Button } from 'common-front/src/designSystem/components/button';
import { Modal } from 'common-front/src/designSystem/components/modal';
import { RichSelect } from 'common-front/src/designSystem/components/richSelect/richSelect';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { InvoiceId } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { last } from 'lodash-es';
import * as React from 'react';
import {
    useBackofficeInvoiceMarkAsPaidMutation,
    useQontoTransactionsQuery
} from '../../generated/graphqlHooks';

interface IMarkAsPaidModalProps {
    invoiceId: InvoiceId;

    onClose(): void;
    reload(): void;
}

export const MarkAsPaidModal = (props: IMarkAsPaidModalProps) => {
    const translate = useTranslate();
    const { mutate: markAsPaid, isLoading } = useBackofficeInvoiceMarkAsPaidMutation();
    const { data } = useQontoTransactionsQuery({});
    const [transactionId, setTransactionId] = React.useState<string | null>(null);

    return (
        <Modal size="md" onClose={props.onClose}>
            <Box color="primary600" fontSize="displayXs" textAlign="center">
                <I icon="file-invoice" />
            </Box>

            <Spacer height="7" />

            <Box color="gray900" fontSize="textLg" textAlign="center">
                {translate('marqu_e_une_fac_47139')}
            </Box>

            <Spacer height="7" />

            <RichSelect
                isSearchVisible={true}
                label={translate('s_lectionner_un_46296')}
                values={transactionId ? [transactionId] : []}
                onChange={(transactionsIds) => {
                    setTransactionId(last(transactionsIds) || null);
                }}
            >
                {(data.backoffice?.qontoTransactions ?? []).map((transaction) => (
                    <option key={transaction.id} value={transaction.id}>
                        {transaction.label}
                    </option>
                ))}
            </RichSelect>

            <Spacer height="7" />

            <Flex gap="3" justify="end">
                <Button
                    disabled={!transactionId}
                    isLoading={isLoading}
                    onClick={async () => {
                        await markAsPaid({
                            invoiceId: props.invoiceId,
                            transactionId: transactionId!
                        });

                        props.reload();
                    }}
                >
                    {translate('marqu_e_comme_p_72620')}
                </Button>

                <Button color="white" onClick={props.onClose}>
                    {translate('annuler_48254')}
                </Button>
            </Flex>
        </Modal>
    );
};
