import { useMobileQuery } from 'common-front/src/hooks/useMobileQuery';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { useHistory } from 'common/src/util/dependencies/dependencies';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';

interface IRegistrationInfoProps {
    path?: string;
    subtext: string;
    text: string;
}

export const RegistrationInfo = (props: IRegistrationInfoProps) => {
    const history = useHistory();
    const onMobile = useMobileQuery();
    const hasPath = isNonEmptyString(props.path);

    return (
        <Flex
            css={{ cursor: hasPath ? 'pointer' : 'initial' }}
            direction="column"
            onClick={() => {
                if (hasPath) {
                    history.push(props.path!);
                }
            }}
        >
            <Box
                color="gray800"
                css={{
                    fontSize: '$textSm',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                }}
                fontWeight="medium"
            >
                {props.text}
            </Box>

            <Spacer height="1" />

            <Box color="gray500" css={{ fontSize: '$textMd', '@tablet': { fontSize: '$textSm' } }}>
                {props.subtext}
            </Box>

            <Spacer height={onMobile ? 5 : 6} />
        </Flex>
    );
};
