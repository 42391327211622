import * as React from 'react';
import { BruleursDocumentOptions, DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { BadgeBruleursA6Back } from './badgeBruleursA6Back';
import { BadgeBruleursA6Front } from './badgeBruleursA6Front';

interface IBadgeBruleursA6Props {
    event: DocumentUserCustomBadgeFragment;
    options: BruleursDocumentOptions;
}

export const BadgeBruleursA6 = (props: IBadgeBruleursA6Props) => (
    <>
        <BadgeBruleursA6Front event={props.event} options={props.options} />

        <BadgeBruleursA6Back event={props.event} />
    </>
);
