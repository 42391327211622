import { Button } from 'common-front/src/designSystem/components/button';
import { Textarea } from 'common-front/src/designSystem/components/textarea';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { EventId, OrganizationId, TextMessageId } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { Emptyable } from 'common/src/util/emptyable';
import { isNonEmptyString } from 'common/src/util/string';
import { size } from 'common/src/vo/sms';
import * as React from 'react';
import { useTextMessageSendMutation } from '../../../generated/graphqlHooks';

interface ITextMessageSendNewProps {
    eventId: Emptyable<EventId>;
    organizationId: OrganizationId;
    parentId: TextMessageId;

    reload(): void;
}

export const TextMessageSendNew = (props: ITextMessageSendNewProps) => {
    const translate = useTranslate();
    const { mutate } = useTextMessageSendMutation();
    const [content, setContent] = React.useState('');
    const { numberOfCharacters, numberOfSms } = size(content, false);
    const valid = isNonEmptyString(content);

    return (
        <Flex direction="column" gap="3" width={1}>
            <Textarea
                label={
                    <Flex justify="between">
                        <Box>{translate('contenu_de_la_r_08075')}</Box>

                        <Box color="gray500">
                            {translate('_1_caract_re_04414', numberOfCharacters, numberOfSms)}
                        </Box>
                    </Flex>
                }
                value={content}
                onChange={setContent}
            />

            <Flex justify="end">
                <Button
                    disabled={!valid}
                    onClick={async () => {
                        await mutate({
                            organizationId: props.organizationId,
                            eventId: props.eventId,
                            parentId: props.parentId,
                            content
                        });

                        props.reload();
                    }}
                >
                    {translate('envoyer_le_mess_41092')}
                </Button>
            </Flex>
        </Flex>
    );
};
