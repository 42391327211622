import { Box } from 'common/src/designSystem/components/box';
import { CSS } from 'common/src/designSystem/components/stitches';
import * as React from 'react';

export const TitleBox = (props: React.ComponentProps<typeof Box> & { css?: CSS }) => {
    const defaultCSS: CSS = { fontSize: '$textLg', '@tablet': { fontSize: '$textMd' } };

    return (
        <Box color="gray800" css={{ ...defaultCSS, ...props.css }} fontWeight="semiBold">
            {props.children}
        </Box>
    );
};
