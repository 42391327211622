import { useHeavent } from 'common-front/src/hooks/useHeavent';
import {
    EmailType,
    VolunteerRegistrationState,
    VolunteersRegistrationId
} from 'common/src/generated/types';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import { useEventContext } from '../../../events/show/eventContext';
import { useEmailResendMutation, useUpdateStateMutation } from '../../../generated/graphqlHooks';
import { UserProfileAction } from './userProfileAction';
import { UserProfileActionsContainer } from './userProfileActionsContainer';

interface IEventUserProfileActionsAssignmentProps {
    state: VolunteerRegistrationState;
    volunteerRegistrationId: VolunteersRegistrationId;

    reload(): void;
}

export const EventUserProfileActionsAssignment = (
    props: IEventUserProfileActionsAssignmentProps
) => {
    const {
        params: { organizationId, eventId, userInfoId },
        translate
    } = useHeavent();
    const { isEventAdmin, isPositionAdmin } = useEventContext();
    const { mutate: emailResend, isLoading: isEmailResendLoading } = useEmailResendMutation();
    const { mutate: changeToWaiting, isLoading: isChangeToWaitingLoading } =
        useUpdateStateMutation();
    const { mutate: changeToRefused, isLoading: isChangeToRefusedLoading } =
        useUpdateStateMutation();
    const { mutate: changeToNotApplicable, isLoading: isChangeToNotApplicableLoading } =
        useUpdateStateMutation();
    const showChangeToWaiting =
        isEventAdmin && props.state !== VolunteerRegistrationState.WaitingAssignment;
    const showChangeToRefused = isEventAdmin && props.state !== VolunteerRegistrationState.Refused;
    const showChangeToNotApplicable =
        isEventAdmin &&
        (props.state === VolunteerRegistrationState.WaitingAssignment ||
            props.state === VolunteerRegistrationState.Assigned);
    const showEditAssignment = isEventAdmin && props.state === VolunteerRegistrationState.Assigned;
    const showAssign =
        isPositionAdmin() &&
        (props.state === VolunteerRegistrationState.WaitingAssignment ||
            props.state === VolunteerRegistrationState.PreAssigned);
    const showResendAssignmentEmail =
        isPositionAdmin() && props.state === VolunteerRegistrationState.Assigned;
    const showResendRefusedEmail =
        isEventAdmin && props.state === VolunteerRegistrationState.Refused;

    return (
        <UserProfileActionsContainer title={translate('affectation')}>
            {showChangeToWaiting && (
                <UserProfileAction
                    color="indigo"
                    icon="arrow-up-arrow-down"
                    isLoading={isChangeToWaitingLoading}
                    onClick={async () => {
                        await changeToWaiting({
                            eventId,
                            volunteerRegistrationId: props.volunteerRegistrationId,
                            state: VolunteerRegistrationState.WaitingAssignment
                        });

                        props.reload();
                    }}
                >
                    {translate('changer_le_stat_37044')}
                </UserProfileAction>
            )}

            {showChangeToRefused && (
                <UserProfileAction
                    color="indigo"
                    icon="arrow-up-arrow-down"
                    isLoading={isChangeToRefusedLoading}
                    onClick={async () => {
                        await changeToRefused({
                            eventId,
                            volunteerRegistrationId: props.volunteerRegistrationId,
                            state: VolunteerRegistrationState.Refused
                        });

                        props.reload();
                    }}
                >
                    {translate('changer_le_stat_62868')}
                </UserProfileAction>
            )}

            {showChangeToNotApplicable && (
                <UserProfileAction
                    color="indigo"
                    icon="arrow-up-arrow-down"
                    isLoading={isChangeToNotApplicableLoading}
                    onClick={async () => {
                        await changeToNotApplicable({
                            eventId,
                            volunteerRegistrationId: props.volunteerRegistrationId,
                            state: VolunteerRegistrationState.NotApplicable
                        });
                    }}
                >
                    {translate('changer_le_stat_92651')}
                </UserProfileAction>
            )}

            {showEditAssignment && (
                <UserProfileAction
                    color="indigo"
                    icon="pen"
                    path={HeaventPaths.ASSIGNMENT(organizationId, eventId, userInfoId)}
                >
                    {translate('_diter_les_affe_42283')}
                </UserProfileAction>
            )}

            {showAssign && (
                <UserProfileAction
                    color="indigo"
                    icon="wand-magic-sparkles"
                    path={HeaventPaths.ASSIGNMENT(organizationId, eventId, userInfoId)}
                >
                    {translate('affecter_94255')}
                </UserProfileAction>
            )}

            {showResendAssignmentEmail && (
                <UserProfileAction
                    color="indigo"
                    icon="paper-plane"
                    isLoading={isEmailResendLoading}
                    onClick={async () => {
                        await emailResend({
                            eventId,
                            userInfoId,
                            emailType: EmailType.Assignments
                        });
                    }}
                >
                    {translate('r_envoyer_l_e_65315')}
                </UserProfileAction>
            )}

            {showResendRefusedEmail && (
                <UserProfileAction
                    color="indigo"
                    icon="paper-plane"
                    isLoading={isEmailResendLoading}
                    onClick={async () => {
                        await emailResend({
                            eventId,
                            userInfoId,
                            emailType: EmailType.Refused
                        });
                    }}
                >
                    {translate('r_envoyer_l_e_06733')}
                </UserProfileAction>
            )}
        </UserProfileActionsContainer>
    );
};
