import { DeleteModal } from 'common-front/src/designSystem/components/deleteModal';
import { DropdownEllipsis } from 'common-front/src/designSystem/components/dropdown/dropdownEllipsis';
import { ItemIcon } from 'common-front/src/designSystem/components/dropdown/item';
import { Separator } from 'common-front/src/designSystem/components/separator';
import { useVolunteersRegistrationsMassDeleteMutation } from 'common-front/src/generated/graphqlHooks';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useReferrerContext } from 'common-front/src/util/referrerContext';
import { CustomBadge, PositionMembersQuery } from 'common/src/generated/types';
import { isNonEmptyArray } from 'common/src/util/array';
import { getDocumentFullPath } from 'common/src/util/documentsPaths';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { getDisplayName } from 'common/src/vo/customBadge';
import * as React from 'react';
import { useEventContext } from '../../../events/show/eventContext';

interface IPositionMemberRowDropdownProps {
    customBadges: CustomBadge[];
    member: PositionMembersQuery['organization']['event']['volunteersRegistrations']['nodes'][number];

    reload(): void;
}

export const PositionMemberRowDropdown = ({
    customBadges,
    member,
    reload
}: IPositionMemberRowDropdownProps) => {
    const {
        translate,
        params: { organizationId, eventId }
    } = useHeavent();

    const { setReferrerPath } = useReferrerContext();
    const isEventAdmin = useEventContext().event.permissions.isEventAdmin;

    const { mutate: volunteerRegistrationDelete } = useVolunteersRegistrationsMassDeleteMutation();

    const [isDeleteFromEventOpen, setIsDeleteFromEventOpen] = React.useState(false);

    const showDocuments =
        member.userInfo.links !== undefined && isNonEmptyArray(customBadges) && eventId;

    return (
        <>
            <DropdownEllipsis>
                <ItemIcon
                    icon="pen"
                    to={HeaventPaths.EDIT_USER(organizationId, eventId, member.userInfo.id)}
                    onClick={() => setReferrerPath()}
                >
                    {translate('_diter_son_prof_10465')}
                </ItemIcon>

                <ItemIcon
                    icon="pen"
                    to={HeaventPaths.ASSIGNMENT(organizationId, eventId, member.userInfo.id)}
                    onClick={() => setReferrerPath()}
                >
                    {translate('_diter_les_affe_42283')}
                </ItemIcon>

                {showDocuments && (
                    <>
                        <Separator color="gray100" direction="horizontal" />

                        {isNonEmptyArray(customBadges) && (
                            <>
                                {customBadges.map((customBadge, index) => (
                                    <ItemIcon
                                        key={index}
                                        download={`${member.userInfo.nameOrEmail}-custom-badge`}
                                        icon="arrow-down-to-bracket"
                                        target={'_blank'}
                                        to={{
                                            pathname: getDocumentFullPath(
                                                member.userInfo.links?.userCustomBadgeLinks![
                                                    customBadge
                                                ],
                                                'pdf'
                                            )
                                        }}
                                    >
                                        {translate(
                                            't_l_charger_1_80752',
                                            getDisplayName(customBadge)
                                        )}
                                    </ItemIcon>
                                ))}
                            </>
                        )}
                    </>
                )}

                <Separator color="gray100" direction="horizontal" />

                {isEventAdmin && (
                    <ItemIcon
                        color="red"
                        icon="trash-can"
                        onClick={() => setIsDeleteFromEventOpen(true)}
                    >
                        {translate('delete_from_eve_69290')}
                    </ItemIcon>
                )}
            </DropdownEllipsis>

            {isDeleteFromEventOpen && member && (
                <DeleteModal
                    buttonText={translate('supprimer_43083')}
                    subtext={[translate('are_you_sure_yo_46668')]}
                    text={translate('suppression_de_73669', member.userInfo.nameOrEmail)}
                    onClose={() => setIsDeleteFromEventOpen(false)}
                    onDelete={async () => {
                        await volunteerRegistrationDelete({
                            eventId,
                            usersInfosIds: [member.userInfo.id]
                        });

                        reload();
                    }}
                />
            )}
        </>
    );
};
