import { ToggleText } from 'common-front/src/designSystem/form/toggle';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import * as React from 'react';

export const CreateAccreditationAutoAccredit = () => {
    const { translate } = useHeavent();

    return (
        <>
            <ToggleText
                name="accreditation.willAutoAccredit"
                subtext={translate('si_la_case_est_44018')}
            >
                {translate('autoriser_l_acc_50624')}
            </ToggleText>
        </>
    );
};
