import { Avatar } from 'common/src/components/avatar/avatar';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { CampaignUserInfoFragment, CreatedByFragment } from 'common/src/generated/types';
import { Emptyable } from 'common/src/util/emptyable';
import { DateTime } from 'luxon';
import * as React from 'react';

export type MessageWithReplies = {
    replies: string[];
    sentAt: DateTime;
    text: string;
    createdBy: Emptyable<CreatedByFragment>;
};

interface IMessageWithRepliesProps {
    message: MessageWithReplies;
    userInfo: CampaignUserInfoFragment;
}

export const MessageWithReplies = (props: IMessageWithRepliesProps) => (
    <>
        <Spacer height="6" />

        <Flex gap="3" width={1}>
            <Flex css={{ flex: '1' }} direction="column" gap="1">
                {props.message.createdBy && (
                    <Box color="gray800" textAlign="end">
                        {props.message.createdBy.name}
                    </Box>
                )}

                <Box
                    css={{
                        background: '$primary600',
                        borderRadius: '$2',
                        borderTopRightRadius: '0',
                        boxShadow: '$sm',
                        color: 'white',
                        padding: '$3'
                    }}
                    width={1}
                >
                    {props.message.text}
                </Box>
            </Flex>

            {props.message.createdBy && (
                <Box>
                    <Avatar
                        email={props.message.createdBy.email}
                        image={props.message.createdBy.picture?.url}
                        name={props.message.createdBy.name}
                        size={40}
                    />
                </Box>
            )}
        </Flex>

        {props.message.replies.map((reply, index) => (
            <React.Fragment key={index}>
                <Spacer height="6" />

                <Flex gap="3" width={1}>
                    <Box>
                        <Avatar
                            email={props.userInfo.email}
                            image={props.userInfo.picture?.url}
                            name={props.userInfo.name}
                            size={40}
                        />
                    </Box>

                    <Flex css={{ flex: '1' }} direction="column" gap="1">
                        <Box color="gray800">{props.userInfo.name}</Box>

                        <Box
                            color="gray800"
                            css={{
                                background: '$gray100',
                                borderRadius: '$2',
                                borderTopLeftRadius: '0',
                                boxShadow: '$sm',
                                padding: '$3'
                            }}
                            width={1}
                        >
                            {unescape(reply)}
                        </Box>
                    </Flex>
                </Flex>
            </React.Fragment>
        ))}
    </>
);
