import { Tab } from 'common-front/src/designSystem/components/tabs/tab';
import { TabList } from 'common-front/src/designSystem/components/tabs/tabList';
import { TabPanel } from 'common-front/src/designSystem/components/tabs/tabPanel';
import { Tabs } from 'common-front/src/designSystem/components/tabs/tabs';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { OrganizationsPaths } from 'common/src/util/paths/organizationsPaths';
import * as React from 'react';
import { OrganizationMembersSpaceDesign } from './organizationMembersSpaceDesign';
import { OrganizationMembersSpaceEmails } from './organizationMembersSpaceEmails';
import { OrganizationMembersSpaceIntegration } from './organizationMembersSpaceIntegration';
import { OrganizationMembersSpaceSettings } from './organizationMembersSpaceSettings';

export const OrganizationMembersSpace = () => {
    const {
        translate,
        params: { organizationId }
    } = useHeavent();

    return (
        <Flex css={{ overflowY: 'auto', padding: '$7' }} direction="column" height={1} width={1}>
            <Box font="gray900 displayXs semiBold">{translate('espace_membres_90896')}</Box>

            <Spacer height="7" />

            <Tabs>
                <TabList>
                    <Tab
                        path={OrganizationsPaths.ORGANIZATION_SETTINGS_MEMBERS_SPACE_DESIGN(
                            organizationId
                        )}
                    >
                        {translate('design_15043')}
                    </Tab>

                    <Tab
                        path={OrganizationsPaths.ORGANIZATION_SETTINGS_MEMBERS_SPACE_INTEGRATION(
                            organizationId
                        )}
                    >
                        {translate('int_gration_10662')}
                    </Tab>

                    <Tab
                        path={OrganizationsPaths.ORGANIZATION_SETTINGS_MEMBERS_SPACE_EMAIL_TEMPLATES(
                            organizationId
                        )}
                    >
                        {translate('mod_les_e_mail_91257')}
                    </Tab>

                    <Tab
                        path={OrganizationsPaths.ORGANIZATION_SETTINGS_MEMBERS_SPACE_SETTINGS(
                            organizationId
                        )}
                    >
                        {translate('param_tres_78420')}
                    </Tab>
                </TabList>

                <TabPanel
                    path={OrganizationsPaths.ORGANIZATION_SETTINGS_MEMBERS_SPACE_DESIGN(
                        ':organizationId'
                    )}
                >
                    <OrganizationMembersSpaceDesign />
                </TabPanel>

                <TabPanel
                    path={OrganizationsPaths.ORGANIZATION_SETTINGS_MEMBERS_SPACE_INTEGRATION(
                        ':organizationId'
                    )}
                >
                    <OrganizationMembersSpaceIntegration />
                </TabPanel>

                <TabPanel
                    path={OrganizationsPaths.ORGANIZATION_SETTINGS_MEMBERS_SPACE_EMAIL_TEMPLATES(
                        ':organizationId'
                    )}
                >
                    <OrganizationMembersSpaceEmails />
                </TabPanel>

                <TabPanel
                    path={OrganizationsPaths.ORGANIZATION_SETTINGS_MEMBERS_SPACE_SETTINGS(
                        ':organizationId'
                    )}
                >
                    <OrganizationMembersSpaceSettings />
                </TabPanel>
            </Tabs>
        </Flex>
    );
};
