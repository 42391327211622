import { BadgesPlus } from 'common-front/src/components/badgesPlus/badgesPlus';
import { IconBackground } from 'common-front/src/designSystem/components/iconBackground';
import { TableFilters } from 'common-front/src/designSystem/components/tableFilters';
import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { usePaginationInfos } from 'common-front/src/hooks/usePaginationInfos';
import { useStateDebounce } from 'common-front/src/hooks/useStateDebounce';
import { AvatarNameEmail } from 'common/src/components/avatarNameEmail';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { HeaderCellSort } from 'common/src/designSystem/components/table/headerCellSort';
import { Row } from 'common/src/designSystem/components/table/row';
import { RowSkeleton } from 'common/src/designSystem/components/table/rowSkeleton';
import { SortDirection, VolunteersRegistrationsSortAttributes } from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { useService } from 'common/src/util/dependencies/dependencies';
import { isNonEmptyString } from 'common/src/util/string';
import { fullName } from 'common/src/vo/accreditationSlot';
import { Sort } from 'common/src/vo/sort';
import * as React from 'react';
import { useAccreditationMembersCardQuery } from '../../../../../generated/graphqlHooks';

export const AccreditationMembersCard = () => {
    const {
        translate,
        params: { eventId, accreditationId }
    } = useHeavent();
    const dateTimeService = useService(DateTimeService);
    const [name, nameDebounced, setName] = useStateDebounce('');
    const [offset, setOffset] = React.useState(0);
    const [sort, setSort] = React.useState<Sort<VolunteersRegistrationsSortAttributes> | null>({
        direction: SortDirection.Asc,
        attribute: VolunteersRegistrationsSortAttributes.Name
    });
    const { data, isLoading } = useAccreditationMembersCardQuery({
        eventId,
        accreditationId,
        name: isNonEmptyString(nameDebounced) ? nameDebounced : undefined,
        offset,
        sort
    });
    const { numberOfPages, totalCount } = usePaginationInfos(data.event?.volunteersRegistrations);

    return (
        <TableFilters
            filters={
                <Box width={320}>
                    <TextInput
                        icon="magnifying-glass"
                        placeholder={translate('rechercher_un_m_05904')}
                        value={name}
                        onChange={setName}
                    />
                </Box>
            }
            headerCells={
                <>
                    <HeaderCellSort
                        attribute={VolunteersRegistrationsSortAttributes.Name}
                        setSort={setSort}
                        sort={sort}
                    >
                        {translate('full_name')}
                    </HeaderCellSort>
                    <HeaderCell>{translate('Phone')}</HeaderCell>
                    <HeaderCell>{translate('cr_neaux_accr_d_70934')}</HeaderCell>
                </>
            }
            numberOfPages={numberOfPages}
            offset={offset}
            rows={
                isLoading ? (
                    <>
                        <RowSkeleton bx={true} />
                        <RowSkeleton bx={true} />
                        <RowSkeleton bx={true} />
                    </>
                ) : data.event.volunteersRegistrations.totalCount === 0 ? (
                    <Flex
                        align="center"
                        css={{
                            border: '1px solid $gray200',
                            borderTop: 'none',
                            py: '$8'
                        }}
                        direction="column"
                        gap="4"
                    >
                        <IconBackground color="primary" icon="user-group" />

                        <Box color="gray800" fontWeight="medium">
                            {translate('aucun_membre_ac_67303')}
                        </Box>
                    </Flex>
                ) : (
                    data.event.volunteersRegistrations.nodes.map((vr) => {
                        const badges = vr.accreditationsUsersInfos.map((aui) => ({
                            id: aui.accreditationSlot.id,
                            color: aui.accreditation.color,
                            icon: aui.accreditation.icon,
                            text: fullName(
                                dateTimeService,
                                aui.accreditationSlot,
                                aui.accreditation.name
                            )
                        }));

                        return (
                            <Row key={vr.userInfo.id}>
                                <Cell>
                                    <AvatarNameEmail hasName={true} userInfo={vr.userInfo} />
                                </Cell>
                                <Cell>{vr.userInfo.phone?.internationalFormat ?? '-'}</Cell>
                                <Cell>
                                    <BadgesPlus badges={badges} />
                                </Cell>
                            </Row>
                        );
                    })
                )
            }
            setOffset={setOffset}
            title={translate('liste_des_membr_60403')}
            totalCount={totalCount}
        />
    );
};
