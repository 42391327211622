import { Button } from 'common-front/src/designSystem/components/button';
import { Content } from 'common-front/src/designSystem/components/tooltip/content';
import { Tooltip } from 'common-front/src/designSystem/components/tooltip/tooltip';
import { Trigger } from 'common-front/src/designSystem/components/tooltip/trigger';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Event, PositionsSlotInput } from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { LocaleFormats } from 'common/src/util/luxon';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';
import { CreatePositionCreateSlotModal } from './createPositionCreateSlotModal';
import { RepeatSlotModal } from './repeatSlotModal';

interface ICreatePositionSlotProps {
    event: Pick<Event, 'startAt' | 'endAt'>;
    index: number;
    initialIsEditOpen: boolean;
    slot: PositionsSlotInput;

    onRepeat(slot: PositionsSlotInput, value: number, unit: string): void;
    remove(index: number): void;
    updateSlots(newSlot: PositionsSlotInput, index: number): void;
}

export const CreatePositionSlot = (props: ICreatePositionSlotProps) => {
    const translate = useTranslate();
    const dateTimeService = useService(DateTimeService);
    const name = React.useMemo(() => {
        if (isNonEmptyString(props.slot.name)) {
            return props.slot.name;
        } else {
            return translate('cr_neau_n_1_80362', props.index + 1);
        }
    }, [props.slot.name, props.index]);
    const subtext = React.useMemo(() => {
        const startDate = dateTimeService.toLocaleString(
            props.slot.startDate,
            LocaleFormats.DateOnly.WeekdayLongMonthLong
        );
        const endDate = dateTimeService.toLocaleString(
            props.slot.endDate,
            LocaleFormats.DateOnly.WeekdayLongMonthLong
        );
        const start = `${startDate} ${props.slot.startTime.substring(0, 5)}`;
        const end = `${endDate} ${props.slot.endTime.substring(0, 5)}`;
        const members = translate('_1_membre_49737', props.slot.resources);

        return `${start} - ${end} - ${members}`;
    }, [
        props.slot.startDate,
        props.slot.startTime,
        props.slot.endDate,
        props.slot.endTime,
        props.slot.resources
    ]);
    const [isEditOpen, setIsEditOpen] = React.useState(props.initialIsEditOpen);
    const [isRepeatSlotOpen, setIsRepeatSlotOpen] = React.useState(false);
    const isDeleteDisabled = (props.slot.assignedResources ?? 0) > 0;

    return (
        <>
            <Flex
                align="center"
                css={{
                    background: 'white',
                    border: '1px solid $gray200',
                    borderRadius: '$2',
                    boxShadow: '$xs',
                    padding: '$3 $4'
                }}
                gap="2"
            >
                <Flex css={{ flex: '1' }} direction="column" gap="1">
                    <Box font="gray800 textSm semiBold">{name}</Box>

                    <Box color="gray500">{subtext}</Box>
                </Flex>

                <Tooltip>
                    <Trigger>
                        <Box>
                            <Button
                                color="white"
                                leftIcon="pen"
                                size="sm"
                                onClick={() => {
                                    setIsEditOpen(true);
                                }}
                            />
                        </Box>
                    </Trigger>

                    <Content placement="top">{translate('_diter_62574')}</Content>
                </Tooltip>

                <Tooltip>
                    <Trigger>
                        <Box>
                            <Button
                                color="white"
                                leftIcon="arrows-repeat"
                                size="sm"
                                onClick={() => {
                                    setIsRepeatSlotOpen(true);
                                }}
                            />
                        </Box>
                    </Trigger>

                    <Content placement="top">{translate('r_p_ter_le_cr_n_68038')}</Content>
                </Tooltip>

                <Tooltip>
                    <Trigger>
                        <Box>
                            <Button
                                color="white"
                                disabled={isDeleteDisabled}
                                leftIcon="trash-can"
                                size="sm"
                                onClick={() => {
                                    props.remove(props.index);
                                }}
                            />
                        </Box>
                    </Trigger>

                    <Content placement="top">
                        {isDeleteDisabled
                            ? translate('des_membres_son_92320')
                            : translate('supprimer_43083')}
                    </Content>
                </Tooltip>
            </Flex>

            {isEditOpen && (
                <CreatePositionCreateSlotModal
                    event={props.event}
                    slot={props.slot}
                    onClose={() => {
                        setIsEditOpen(false);
                    }}
                    onSuccess={async (slot: PositionsSlotInput) => {
                        props.updateSlots(slot, props.index);
                    }}
                />
            )}

            {isRepeatSlotOpen && (
                <RepeatSlotModal
                    showHours={true}
                    onClose={() => {
                        setIsRepeatSlotOpen(false);
                    }}
                    onSubmit={(value, unit) => {
                        props.onRepeat(props.slot, value, unit);
                    }}
                />
            )}
        </>
    );
};
