import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { useLocation } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import { Route, Switch } from 'react-router';
import { useEventContext } from '../../events/show/eventContext';
import {
    usePositionCategoryQuery,
    usePositionsCategoryLeadersAssignMutation
} from '../../generated/graphqlHooks';
import { PositionCategoryDescription } from './positionCategoryDescription';
import { PositionCategoryGauge } from './positionCategoryGauge';
import { PositionCategoryHeader } from './positionCategoryHeader';
import { PositionCategoryLeaders } from './positionCategoryLeaders';
import { PositionCategoryPositionRightPanel } from './positions/positionCategoryPositionRightPanel';
import { PositionCategoryPositions } from './positions/positionCategoryPositions';

export const PositionCategory = () => {
    const {
        history,
        translate,
        params: { organizationId, eventId, positionCategoryId }
    } = useHeavent();
    const location = useLocation();
    const { updateLocalEvent } = useEventContext();
    const { data, loader, reload } = usePositionCategoryQuery({ eventId, positionCategoryId });
    const { mutate: leadersAssign } = usePositionsCategoryLeadersAssignMutation();

    React.useEffect(() => {
        updateLocalEvent({ positionBackPath: location.pathname });
    }, []);

    return (
        loader || (
            <>
                <Flex css={{ padding: '$7' }} direction="column" gap="7" width={1}>
                    <PositionCategoryHeader
                        category={data.event.positionCategory}
                        reload={reload}
                    />

                    <Flex gap="7">
                        <Flex direction="column" gap="4" width={470}>
                            <PositionCategoryGauge
                                category={data.event.positionCategory}
                                title={translate('liste_des_missi_82050')}
                            />

                            <PositionCategoryLeaders
                                leaders={data.event.positionCategory.leaders}
                                reload={reload}
                                onSave={(leaders) =>
                                    leadersAssign({
                                        eventId,
                                        positionCategoryId,
                                        leaders
                                    })
                                }
                            />

                            <PositionCategoryDescription
                                category={data.event.positionCategory}
                                eventId={eventId}
                                reload={reload}
                            />
                        </Flex>

                        <Box css={{ flex: '1' }}>
                            <PositionCategoryPositions />
                        </Box>
                    </Flex>
                </Flex>

                <Switch>
                    <Route
                        path={HeaventPaths.POSITION_MEMBERS(
                            ':organizationId',
                            ':eventId',
                            ':positionCategoryId',
                            ':positionId'
                        )}
                    >
                        <PositionCategoryPositionRightPanel
                            onClose={() => {
                                history.push(
                                    HeaventPaths.POSITION_CATEGORY(
                                        organizationId,
                                        eventId,
                                        positionCategoryId
                                    )
                                );
                            }}
                        />
                    </Route>
                </Switch>
            </>
        )
    );
};
