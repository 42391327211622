import { Button } from 'common-front/src/designSystem/components/button';
import { DateInput } from 'common-front/src/designSystem/components/date/dateInput';
import { DropdownFilters } from 'common-front/src/designSystem/components/dropdownFilters';
import { RichSelect } from 'common-front/src/designSystem/components/richSelect/richSelect';
import { RightPanel } from 'common-front/src/designSystem/components/rightPanel/rightPanel';
import { RightPanelBody } from 'common-front/src/designSystem/components/rightPanel/rightPanelBody';
import { RightPanelFooter } from 'common-front/src/designSystem/components/rightPanel/rightPanelFooter';
import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    AccreditationId,
    AccreditationsCategoryId,
    EventAccreditationsFragment
} from 'common/src/generated/types';
import { ICreateUpdateDelegationValues } from 'common/src/input/delegationInput';
import { isEmptyArray, isNonEmptyArray } from 'common/src/util/array';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { isEmptyString, isFuzzyMatch } from 'common/src/util/string';
import { DateTime } from 'luxon';
import * as React from 'react';
import { AccreditationsSelectPanelAccreditationCategory } from './accreditationsSelectPanelAccreditationCategory';

export type AccreditationCategories = EventAccreditationsFragment['accreditationsCategories'];
export type Accreditations =
    EventAccreditationsFragment['accreditationsCategories'][number]['accreditations'];

interface IAccreditationsSelectPanelProps {
    allAccreditationCategories: AccreditationCategories;
    eventStartAt: DateTime;
    eventEndAt: DateTime;
    values: ICreateUpdateDelegationValues['delegation'];

    change(name: string, value: any): void;
    setIsAddAccreditationOpen: any;
}

export const AccreditationsSelectPanel = ({
    allAccreditationCategories,
    change,
    eventStartAt,
    eventEndAt,
    setIsAddAccreditationOpen,
    values
}: IAccreditationsSelectPanelProps) => {
    const translate = useTranslate();

    const [searchText, setSearchText] = React.useState('');
    const [searchCategoriesIds, setSearchCategoriesIds] = React.useState<
        AccreditationsCategoryId[]
    >([]);
    const [searchAccreditationsIds, setSearchAccreditationsIds] = React.useState<AccreditationId[]>(
        []
    );
    const [searchDate, setSearchDate] = React.useState(DateTime.invalid('Invalid'));

    const accreditationSlotsIdsValues = React.useMemo(
        () => values.accreditationsSlots.flatMap((ad) => ad.accreditationSlotId),
        [values]
    );
    const accreditations = React.useMemo(
        () => allAccreditationCategories.flatMap((c) => c.accreditations),
        [allAccreditationCategories]
    );

    // Filter out unless the search term matches either the item name or the
    // names of any of its children, in which case the whole tree is retained.
    const filteredAccreditationsCategories = React.useMemo(
        () =>
            allAccreditationCategories.flatMap((category) => {
                const isCategoryMatching =
                    isEmptyArray(searchCategoriesIds) || searchCategoriesIds.includes(category.id);
                const accreditations = category.accreditations.flatMap((accreditation) => {
                    const isAccreditationMatching =
                        (isEmptyString(searchText) ||
                            isFuzzyMatch(accreditation.name, searchText)) &&
                        (isEmptyArray(searchAccreditationsIds) ||
                            searchAccreditationsIds.includes(accreditation.id));
                    const slots = accreditation.slots.filter(
                        (slot) =>
                            !searchDate?.isValid ||
                            !slot.date?.isValid ||
                            slot.date.equals(searchDate)
                    );

                    return isAccreditationMatching &&
                        (isEmptyArray(accreditation.slots) || isNonEmptyArray(slots))
                        ? [{ ...accreditation, slots }]
                        : [];
                });

                return isCategoryMatching && isNonEmptyArray(accreditations)
                    ? [{ ...category, accreditations }]
                    : [];
            }),
        [searchText, searchCategoriesIds, searchAccreditationsIds, searchDate]
    );

    return (
        <RightPanel
            size="md"
            onClose={() => {
                setIsAddAccreditationOpen(false);
            }}
        >
            <RightPanelBody>
                <Spacer height="6" />

                <Box font="gray900 textXl medium">{translate('add_an_accreditation')}</Box>

                <Box color="gray500">{translate('add_default_accreditations_to_delegation')}</Box>

                <Spacer height="4" />

                <Flex gap="3" justify="evenly">
                    <TextInput
                        icon="magnifying-glass"
                        placeholder={translate('rechercher_une_65646')}
                        value={searchText}
                        onChange={(newSearchText: string) => {
                            setSearchText(newSearchText);
                        }}
                    />

                    <DropdownFilters color="gray" showButtons={false}>
                        <RichSelect
                            isSearchVisible={true}
                            isSelectAllVisible={true}
                            label={translate('cat_gories_24533')}
                            multiple={true}
                            renderOnPortal={true}
                            values={searchCategoriesIds}
                            onChange={setSearchCategoriesIds}
                        >
                            {allAccreditationCategories.map((category) => (
                                <option key={category.id} value={category.id}>
                                    {category.name}
                                </option>
                            ))}
                        </RichSelect>

                        <RichSelect
                            isSearchVisible={true}
                            isSelectAllVisible={true}
                            label={translate('accr_ditations_39450')}
                            multiple={true}
                            renderOnPortal={true}
                            values={searchAccreditationsIds}
                            onChange={setSearchAccreditationsIds}
                        >
                            {accreditations.map((accreditation) => (
                                <option key={accreditation.id} value={accreditation.id}>
                                    {accreditation.name}
                                </option>
                            ))}
                        </RichSelect>

                        <DateInput
                            label="Date"
                            max={eventEndAt}
                            min={eventStartAt}
                            value={searchDate}
                            onChange={setSearchDate}
                        />
                    </DropdownFilters>
                </Flex>

                <Spacer height="4" />

                <Flex direction="column" gap="4">
                    {filteredAccreditationsCategories.map((accreditationCategory) => (
                        <AccreditationsSelectPanelAccreditationCategory
                            key={accreditationCategory.id}
                            accreditationCategory={accreditationCategory}
                            accreditationSlotsIdsValues={accreditationSlotsIdsValues}
                            change={change}
                            fullAccreditationCategory={
                                allAccreditationCategories.find(
                                    (ac) => ac.id === accreditationCategory.id
                                )!
                            }
                            values={values}
                        />
                    ))}
                </Flex>

                <Spacer height="6" />
            </RightPanelBody>

            <RightPanelFooter>
                <Flex gap="3" justify="end">
                    <Button onClick={() => setIsAddAccreditationOpen(false)}>
                        {translate('ajouter_97327')}
                    </Button>

                    <Button
                        color="white"
                        onClick={() => {
                            change('delegation.accreditations', []);
                            change('delegation.accreditationsSlots', []);
                        }}
                    >
                        {translate('r_initialiser_77538')}
                    </Button>
                </Flex>
            </RightPanelFooter>
        </RightPanel>
    );
};
