import { Flex } from 'common/src/designSystem/components/flex';
import {
    CustomFieldWithConditionFragment,
    PositionDashboardQuery
} from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { CustomFieldsCard } from '../../../../common/cards/customFieldsCard';
import { DescriptionCard } from '../../../../common/cards/descriptionCard';
import { LocationCard } from '../../../../common/cards/locationCard';
import { PrivateNoteCard } from '../../../../common/cards/privateNoteCard';
import { TagsCard } from '../../../../common/cards/tagsCard';
import { PositionCategoryGauge } from '../../../../positionsCategories/show/positionCategoryGauge';
import { PositionCategoryLeaders } from '../../../../positionsCategories/show/positionCategoryLeaders';
import { PositionParameters } from '../../../show/positionParameters';

interface IPositionSettingsMobileProps {
    customFields: CustomFieldWithConditionFragment[];
    position: PositionDashboardQuery['event']['position'];
}

export const PositionSettingsMobile = ({
    customFields,
    position
}: IPositionSettingsMobileProps) => {
    const translate = useTranslate();

    return (
        <Flex css={{ marginBottom: '$7' }} direction="column" gap="7">
            <PositionCategoryGauge category={position} title={translate('remplissage_des_84383')} />
            <PositionCategoryLeaders leaders={position.leaders} />
            <PositionParameters customFields={customFields} position={position} />
            <DescriptionCard description={position.description} />
            <LocationCard location={position} />
            <CustomFieldsCard customFields={position.customFields} />
            <TagsCard modalSubtitle={translate('ajouter_des_tag_64526')} tags={position.tags} />
            <PrivateNoteCard privateNote={position.privateNote} />
        </Flex>
    );
};
