import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import * as React from 'react';
import { useUniqueIds } from '../../hooks/useUniqueIds';
import { Toggle } from './toggle';

interface IFormBoxWithoutTitleProps {
    children: React.ReactNode;
}

interface IFormBoxWithTitleProps extends IFormBoxWithoutTitleProps {
    hideToggle?: boolean;
    initialIsOpen: boolean;
    subtitle: string;
    title: string;
    centerTitleAndSubtitle?: boolean;

    onToggle?(isOpen: boolean): void;
}

type IFormBoxProps = IFormBoxWithoutTitleProps | IFormBoxWithTitleProps;

function isWithTitle(formBox: IFormBoxProps): formBox is IFormBoxWithTitleProps {
    return (formBox as IFormBoxWithTitleProps).title !== undefined;
}

const FormBoxWithoutTitle = (props: IFormBoxWithoutTitleProps) => <>{props.children}</>;

const FormBoxPropsWithTitle = (props: IFormBoxWithTitleProps) => {
    const { descId, labelId } = useUniqueIds();
    const [isOpen, setIsOpen] = React.useState(props.initialIsOpen);
    const toggle = React.useCallback(
        (value: boolean) => {
            setIsOpen(value);
            props.onToggle?.(value);
        },
        [props.onToggle, setIsOpen]
    );

    return (
        <>
            <Flex
                align="center"
                css={
                    isOpen && !props.centerTitleAndSubtitle
                        ? {
                              borderBottom: '1px solid $gray200',
                              paddingBottom: '$5'
                          }
                        : {}
                }
                gap="4"
            >
                <Flex
                    css={
                        props.centerTitleAndSubtitle
                            ? { flex: '1', alignItems: 'center', marginBottom: 'var(--space32)' }
                            : { flex: '1' }
                    }
                    direction="column"
                    gap="1"
                >
                    <Box color="gray900" fontSize="textMd" fontWeight="semiBold" id={labelId}>
                        {props.title}
                    </Box>

                    <Box
                        color="gray500"
                        dangerouslySetInnerHTML={{ __html: props.subtitle }}
                        id={descId}
                    />
                </Flex>

                {!props.hideToggle && (
                    <Box>
                        <Toggle
                            descId={descId}
                            labelId={labelId}
                            size="md"
                            value={isOpen}
                            onChange={toggle}
                        />
                    </Box>
                )}
            </Flex>

            {isOpen && (
                <>
                    {!props.centerTitleAndSubtitle && <Spacer height="5" />}

                    {props.children}
                </>
            )}
        </>
    );
};

export const FormBox = (props: IFormBoxProps) => (
    <Flex
        css={{
            background: 'white',
            borderRadius: '$2',
            boxShadow: '$sm',
            padding: '$6 $7'
        }}
        direction="column"
    >
        {isWithTitle(props) ? (
            <FormBoxPropsWithTitle {...props} />
        ) : (
            <FormBoxWithoutTitle {...props} />
        )}
    </Flex>
);
