import { Accordion } from 'common-front/src/designSystem/components/accordion';
import { Box } from 'common/src/designSystem/components/box';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { HeaderRow } from 'common/src/designSystem/components/table/headerRow';
import { Row } from 'common/src/designSystem/components/table/row';
import { Table } from 'common/src/designSystem/components/table/table';
import { FormsUsersInfosWishedFragment } from 'common/src/generated/types';
import { IntervalService } from 'common/src/services/intervalService';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { sortBy } from 'lodash-es';
import * as React from 'react';

interface IAssignmentUserPanelSlotsWishedProps {
    formsUsersInfos: FormsUsersInfosWishedFragment['formsUsersInfos'];
}

export const AssignmentUserPanelSlotsWished = (props: IAssignmentUserPanelSlotsWishedProps) => {
    const translate = useTranslate();
    const intervalService = useService(IntervalService);
    const slots = React.useMemo(
        () =>
            sortBy(
                props.formsUsersInfos.flatMap((fui) => fui.slots),
                (s) => s.range.start!.toMillis()
            ).map((slot) => intervalService.toDisplayString(slot.range)),
        [props.formsUsersInfos]
    );

    return (
        <Accordion title={translate('disponibilit_s_49923')}>
            <Table>
                <HeaderRow>
                    <HeaderCell>{translate('disponibilit_92461')}</HeaderCell>
                </HeaderRow>

                {slots.map((slot, index) => (
                    <Row key={index}>
                        <Cell>
                            <Box color="gray900" fontWeight="medium">
                                {slot}
                            </Box>
                        </Cell>
                    </Row>
                ))}
            </Table>
        </Accordion>
    );
};
