import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { AvatarNameEmail } from 'common/src/components/avatarNameEmail';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { CellControls } from 'common/src/designSystem/components/table/cellControls';
import { Row } from 'common/src/designSystem/components/table/row';
import {
    CustomBadge,
    PositionMembersQuery,
    SegmentCustomFieldFragment
} from 'common/src/generated/types';
import { useService } from 'common/src/util/dependencies/dependencies';
import { PositionsPaths } from 'common/src/util/paths/positionsPaths';
import { FieldService } from 'common/src/vo/field';
import { PossibleColumn } from 'common/src/vo/segment';
import { VolunteerDefaultColumns } from 'common/src/vo/segments/volunteersSegmentsService';
import { noop } from 'lodash-es';
import * as React from 'react';
import { useEventContext } from '../../../events/show/eventContext';
import { VolunteerRegistrationPositions } from '../../../volunteers/list/list/cells/volunteerRegistrationPositions';
import { VolunteerRegistrationStateCell } from '../../../volunteers/list/list/cells/volunteerRegistrationStateCell';
import { PositionMemberRowDropdown } from './positionMemberRowDropdown';

interface IPositionMemberRowProps {
    customBadges: CustomBadge[];
    customFields: SegmentCustomFieldFragment[];
    member: PositionMembersQuery['organization']['event']['volunteersRegistrations']['nodes'][number];
    selectedFields: PossibleColumn[];

    reload(): void;
}

export const PositionMemberRow = ({
    customBadges,
    customFields,
    member,
    reload,
    selectedFields
}: IPositionMemberRowProps) => {
    const {
        history,
        params: { organizationId, eventId, positionId }
    } = useHeavent();

    const fieldService = useService(FieldService);

    const isEventAdmin = useEventContext().event.permissions.isEventAdmin;

    const slugToCustomField = React.useMemo(
        () => new Map(customFields.map((cf) => [cf.slug, cf])),
        [customFields]
    );

    return (
        <Row
            key={member.id}
            css={{
                background: 'white',
                cursor: 'pointer',
                '&:hover': {
                    background: '$gray100'
                }
            }}
            onClick={() =>
                history.push(
                    PositionsPaths.POSITION_MEMBER_INFORMATION({
                        organizationId,
                        eventId,
                        positionId,
                        userInfoId: member.userInfo.id
                    })
                )
            }
        >
            {selectedFields.map((field, index) => {
                const firstCellCss = index === 0 && isEventAdmin ? { paddingLeft: '$3' } : {};
                const customField = slugToCustomField.get(field.slug);

                switch (field.slug) {
                    case VolunteerDefaultColumns.Name:
                        return (
                            <Cell key={field.slug}>
                                <AvatarNameEmail hasName={true} userInfo={member.userInfo} />
                            </Cell>
                        );
                    case VolunteerDefaultColumns.Positions:
                        return (
                            <VolunteerRegistrationPositions
                                key={field.slug}
                                eventId={eventId}
                                firstCellCss={firstCellCss}
                                hidePosition={true}
                                reload={noop}
                                volunteerRegistration={member}
                            />
                        );
                    case VolunteerDefaultColumns.VolunteerRegistrationState:
                        return (
                            <VolunteerRegistrationStateCell
                                key={field.slug}
                                eventId={eventId}
                                field={field}
                                firstCellCss={firstCellCss}
                                reload={noop}
                                state={member.state}
                                volunteerRegistrationId={member.id}
                            />
                        );
                    default:
                        return (
                            <Cell key={field.slug}>
                                {customField
                                    ? fieldService.getValueString(customField, member.userInfo, {
                                          fileReturnValue: 'name'
                                      })
                                    : ''}
                            </Cell>
                        );
                }
            })}

            <CellControls justify="center">
                <PositionMemberRowDropdown
                    customBadges={customBadges}
                    member={member}
                    reload={reload}
                />
            </CellControls>
        </Row>
    );
};
