import { AccreditAssignGrid } from 'common-front/src/accreditAssign/accreditAssignGrid';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { TeamAssignmentInfosQuery } from 'common/src/generated/types';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import {
    useTeamAssignmentInfosQuery,
    useTeamAssignmentWaitingTeamsQuery
} from '../../generated/graphqlHooks';
import { useLocalEventState } from '../../hooks/useLocalState';
import { AssignmentCategoriesSlots } from '../assign/assignmentCategoriesSlots';
import { AssignmentContextProvider } from '../assign/assignmentContext';
import { TeamAssignmentLeftPanel } from './leftPanel/teamAssignmentLeftPanel';
import { TeamAssignmentButtons } from './teamAssignmentButtons';
import { TeamAssignmentHeader } from './teamAssignmentHeader';
import { TeamAssignmentRightPanel } from './teamAssignmentRightPanel';

interface ITeamAssignmentComponentProps {
    event: TeamAssignmentInfosQuery['event'];
    organization: TeamAssignmentInfosQuery['organization'];
}

const TeamAssignmentComponent = (props: ITeamAssignmentComponentProps) => {
    const {
        history,
        params: { organizationId, eventId, teamCode }
    } = useHeavent();
    const [isLeftPanelOpen, setIsLeftPanelOpen] = useLocalEventState(
        'assignment.isLeftPanelOpen',
        true
    );
    const [isRightPanelOpen, setIsRightPanelOpen] = useLocalEventState(
        'assignment.isRightPanelOpen',
        true
    );
    const { data, isLoading } = useTeamAssignmentWaitingTeamsQuery({
        eventId
    });
    const showNextTeam = React.useCallback(() => {
        const teams = data.event?.teams.nodes ?? [];
        const currentTeamIndex = teams.findIndex((t) => t.teamCode === teamCode);
        const nextTeamCode = teams[currentTeamIndex + 1]?.teamCode ?? teams[0].teamCode;

        if (nextTeamCode === teamCode) {
            history.push(HeaventPaths.ASSIGNMENTS(organizationId, eventId));
        } else {
            history.push(HeaventPaths.TEAM_ASSIGNMENT(organizationId, eventId, nextTeamCode));
        }
    }, [organizationId, eventId, teamCode, data.event]);
    const team = props.event.team;
    const wishes = React.useMemo(
        () => ({
            wishedPositionsCategoriesIds: team.positionsCategoriesIds,
            wishedPositionsIds: team.positionsIds,
            wishedPositionsSlotsIds: team.positionsSlotsIds,
            wishedRanges: team.slots.map(({ range }) => range)
        }),
        [team]
    );

    return (
        <AssignmentContextProvider
            isEdit={false}
            positionsCategoriesIds={[]}
            positionsIds={[]}
            positionsSlotsIds={[]}
            showNextVolunteer={showNextTeam}
        >
            <AccreditAssignGrid
                buttons={<TeamAssignmentButtons />}
                header={<TeamAssignmentHeader team={team} />}
                isEdit={false}
                isLeftPanelOpen={isLeftPanelOpen}
                isRightPanelOpen={isRightPanelOpen}
                leftPanel={<TeamAssignmentLeftPanel event={data.event} isLoading={isLoading} />}
                rightPanel={
                    <TeamAssignmentRightPanel
                        customFields={props.event.formsCustomsFields}
                        team={team}
                        onCollapse={() => {
                            setIsRightPanelOpen(false);
                        }}
                    />
                }
                setIsLeftPanelOpen={setIsLeftPanelOpen}
                setIsRightPanelOpen={setIsRightPanelOpen}
            >
                <AssignmentCategoriesSlots
                    event={props.event}
                    organization={props.organization}
                    userInfo={team.leaderUserInfo}
                    {...wishes}
                />
            </AccreditAssignGrid>
        </AssignmentContextProvider>
    );
};

export const TeamAssignment = () => {
    const {
        params: { organizationId, eventId, teamCode }
    } = useHeavent();
    const { data, loader } = useTeamAssignmentInfosQuery({ organizationId, eventId, teamCode });

    return (
        loader || <TeamAssignmentComponent event={data.event} organization={data.organization} />
    );
};
