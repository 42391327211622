import { Checkbox } from 'common-front/src/designSystem/components/checkbox';
import { RichSelectGroup } from 'common-front/src/designSystem/components/richSelect/richSelectGroup';
import { RichSelectItem } from 'common-front/src/designSystem/components/richSelect/richSelectItem';
import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { Content } from 'common-front/src/designSystem/components/tooltip/content';
import { Tooltip } from 'common-front/src/designSystem/components/tooltip/tooltip';
import { Trigger } from 'common-front/src/designSystem/components/tooltip/trigger';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { CustomFieldId, FormCustomFieldFragment } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { isNonEmptyString } from 'common/src/util/string';
import { groupBy, noop, sortBy } from 'lodash-es';
import * as React from 'react';

interface IAddFieldsMenuProps {
    customFields: FormCustomFieldFragment[];
    selectedCustomFieldsIds: Set<CustomFieldId>;

    toggle(field: FormCustomFieldFragment): void;
}

export const AddFieldsMenu = (props: IAddFieldsMenuProps) => {
    const translate = useTranslate();
    const [search, setSearch] = React.useState('');
    const displayedFields = React.useMemo(() => {
        if (isNonEmptyString(search)) {
            return props.customFields.filter((cf) =>
                cf.name.trim().toLowerCase().includes(search.trim().toLowerCase())
            );
        } else {
            return props.customFields;
        }
    }, [props.customFields, search]);
    const customFieldGrouped: Array<[string, FormCustomFieldFragment[]]> = React.useMemo(
        () =>
            sortBy(
                Object.entries(groupBy(displayedFields, (cf) => cf.customFieldCategoryName)),
                ([categoryName]) => categoryName
            ).map(([categoryName, fields]) => [categoryName, sortBy(fields, (f) => f.name)]),
        [displayedFields]
    );

    return (
        <Flex css={{ padding: '$3' }} direction="column" width={1}>
            <TextInput
                icon="magnifying-glass"
                placeholder={translate('rechercher_un_c_86475')}
                state="search"
                value={search}
                onChange={setSearch}
            />

            <Spacer height="3" />

            <Box css={{ maxHeight: '300px', overflowY: 'auto' }}>
                {customFieldGrouped.map(([categoryName, fields], index) => (
                    <React.Fragment key={index}>
                        <RichSelectGroup index={index}>{categoryName}</RichSelectGroup>

                        {fields.map((field) => {
                            const disabled =
                                field.conditionCustomField &&
                                !props.selectedCustomFieldsIds.has(field.conditionCustomField.id)
                                    ? true
                                    : false;

                            return disabled ? (
                                <Tooltip key={field.id}>
                                    <Trigger>
                                        <Flex
                                            key={field.id}
                                            align="center"
                                            css={{ px: '$3' }}
                                            gap="3"
                                            height={40}
                                            width={1}
                                        >
                                            <Checkbox
                                                disabled={true}
                                                state="unchecked"
                                                onClick={noop}
                                            />

                                            <Box
                                                color="gray400"
                                                css={{ ellipsis: true, flex: '1' }}
                                                title={field.name}
                                            >
                                                {field.name}
                                            </Box>
                                        </Flex>
                                    </Trigger>

                                    <Content placement="top-start">
                                        {translate(
                                            'ce_champ_d_pend_31209',
                                            field.conditionCustomField?.name
                                        )}
                                    </Content>
                                </Tooltip>
                            ) : (
                                <RichSelectItem
                                    key={field.id}
                                    isCheckbox={true}
                                    isSelected={props.selectedCustomFieldsIds.has(field.id)}
                                    value={field.id}
                                    onClick={() => {
                                        props.toggle(field);
                                    }}
                                >
                                    {field.name}
                                </RichSelectItem>
                            );
                        })}
                    </React.Fragment>
                ))}
            </Box>
        </Flex>
    );
};
