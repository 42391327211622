import { NoFeature } from 'common-front/src/components/noFeature';
import { Page } from 'common-front/src/components/page/page';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Feature } from 'common/src/generated/types';
import { useParams, useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { useDocumentsQuery } from '../../generated/graphqlHooks';
import { useOrganizationContext } from '../../organization/organizationContext';
import { CreateCustomDocumentModal } from '../create/createCustomDocumentModal';
import { Documents } from './documents';
import { DocumentsSkeleton } from './documentsSkeleton';

export const DocumentsLoader = () => {
    const translate = useTranslate();
    const { eventId } = useParams();
    const { hasFeature } = useOrganizationContext();
    const { data, isLoading, reload } = useDocumentsQuery({ eventId });

    return (
        <Page.Root>
            <Page.Header gap="3">
                <Flex css={{ flex: '1' }} direction="column" gap="1">
                    <Box color="gray900" fontSize="displayXs" fontWeight="semiBold">
                        {translate('documents_87028')}
                    </Box>

                    <Box color="gray500">{translate('retrouver_et_pe_99652')}</Box>
                </Flex>

                {hasFeature(Feature.CustomDocument) && <CreateCustomDocumentModal />}
            </Page.Header>

            <Page.Content>
                {hasFeature(Feature.Documents) ? (
                    isLoading ? (
                        <DocumentsSkeleton />
                    ) : (
                        <Documents event={data.event} reload={reload} />
                    )
                ) : (
                    <Flex align="center" css={{ flex: '1' }} direction="column" justify="center">
                        <NoFeature icon="folders" />
                    </Flex>
                )}
            </Page.Content>
        </Page.Root>
    );
};
