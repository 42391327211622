import { ColorInput } from 'common-front/src/designSystem/form/colorInput';
import { FloatInput } from 'common-front/src/designSystem/form/floatInput';
import { IconSelect } from 'common-front/src/designSystem/form/iconSelect';
import { RichEditor } from 'common-front/src/designSystem/form/richEditor';
import { RichSelect } from 'common-front/src/designSystem/form/richSelect';
import { Select } from 'common-front/src/designSystem/form/select/select';
import { TextInput } from 'common-front/src/designSystem/form/textInput';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { AccreditationProperty, PositionProperty, Tag, Trace } from 'common/src/generated/types';
import { assertUnreachable } from 'common/src/util/assertUnreachable';
import * as React from 'react';

interface IMassEditFieldInputProps {
    categories: Array<{ id: number; name: string }>;
    property: AccreditationProperty | PositionProperty;
    tags: Array<Pick<Tag, 'id' | 'name'>>;
    traces: Array<Pick<Trace, 'id' | 'name'>>;
    value: any;

    change(name: string, value: any): void;
}

export const MassEditFieldInput = (props: IMassEditFieldInputProps) => {
    const { translate } = useHeavent();

    React.useEffect(() => {
        switch (props.property) {
            case AccreditationProperty.Acronym:
            case AccreditationProperty.Address:
            case AccreditationProperty.Color:
            case AccreditationProperty.Description:
            case AccreditationProperty.Icon:
            case AccreditationProperty.Name:
            case PositionProperty.Acronym:
            case PositionProperty.Address:
            case PositionProperty.Color:
            case PositionProperty.Description:
            case PositionProperty.Icon:
            case PositionProperty.Name:
                if (typeof props.value !== 'string') {
                    props.change('massEdit.value', '');
                }
                break;
            case AccreditationProperty.AutoAccredit:
                if (typeof props.value !== 'boolean') {
                    props.change('massEdit.value', false);
                }
                break;
            case AccreditationProperty.Category:
            case PositionProperty.Category:
                if (typeof props.value !== 'number') {
                    props.change('massEdit.value', props.categories[0]?.id);
                }
                break;
            case AccreditationProperty.Latitude:
            case AccreditationProperty.Longitude:
            case PositionProperty.Latitude:
            case PositionProperty.Longitude:
                if (typeof props.value !== 'number') {
                    props.change('massEdit.value', 0);
                }
                break;
            case AccreditationProperty.Tag:
            case PositionProperty.Tag:
            case PositionProperty.Traces:
                if (!Array.isArray(props.value)) {
                    props.change('massEdit.value', []);
                }
                break;
            case AccreditationProperty.WeezeventNumberOfAccess:
                if (typeof props.value !== 'number') {
                    props.change('massEdit.value', 1);
                }
                break;
            default:
                assertUnreachable(props.property);
        }
    }, [props.property]);

    switch (props.property) {
        case AccreditationProperty.Acronym:
        case PositionProperty.Acronym:
            return <TextInput name="massEdit.value" />;
        case AccreditationProperty.Address:
        case PositionProperty.Address:
            return <TextInput name="massEdit.value" />;
        case AccreditationProperty.AutoAccredit:
            return (
                <Select name="massEdit.value" shouldParseAsBoolean={true}>
                    <option value="true">{translate('oui_54361')}</option>
                    <option value="false">{translate('non_33516')}</option>
                </Select>
            );
        case AccreditationProperty.Category:
        case PositionProperty.Category:
            return (
                <RichSelect isSearchVisible={true} name="massEdit.value">
                    {props.categories.map((category) => (
                        <option key={category.id} value={category.id}>
                            {category.name}
                        </option>
                    ))}
                </RichSelect>
            );
        case AccreditationProperty.Color:
        case PositionProperty.Color:
            return <ColorInput name="massEdit.value" />;
        case AccreditationProperty.Description:
        case PositionProperty.Description:
            return (
                <Box css={{ overflow: 'hidden auto' }} width={1}>
                    <RichEditor name="massEdit.value" />
                </Box>
            );
        case AccreditationProperty.Icon:
        case PositionProperty.Icon:
            return <IconSelect name="massEdit.value" />;
        case AccreditationProperty.Latitude:
        case PositionProperty.Latitude:
            return <FloatInput name="massEdit.value" />;
        case AccreditationProperty.Longitude:
        case PositionProperty.Longitude:
            return <FloatInput name="massEdit.value" />;
        case AccreditationProperty.Name:
        case PositionProperty.Name:
            throw new Error('Should not happen');
        case AccreditationProperty.Tag:
        case PositionProperty.Tag:
            return (
                <RichSelect isSearchVisible={true} multiple={true} name="massEdit.value">
                    {props.tags.map((tag) => (
                        <option key={tag.id} value={tag.id}>
                            {tag.name}
                        </option>
                    ))}
                </RichSelect>
            );
        case PositionProperty.Traces:
            return (
                <RichSelect isSearchVisible={true} multiple={true} name="massEdit.value">
                    {props.traces.map((trace) => (
                        <option key={trace.id} value={trace.id}>
                            {trace.name}
                        </option>
                    ))}
                </RichSelect>
            );
        case AccreditationProperty.WeezeventNumberOfAccess:
            return <TextInput name="massEdit.value" shouldParseAsInt={true} />;
        default:
            return assertUnreachable(props.property);
    }
};
