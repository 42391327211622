import { executeTagsQuery } from 'common-front/src/generated/graphqlHooks';
import { getToken } from 'common-front/src/util/aws/cognito';
import {
    AccreditationsCategoriesQuery,
    EventId,
    OrganizationId,
    TagsQuery
} from 'common/src/generated/types';
import { Emptyable } from 'common/src/util/emptyable';
import * as React from 'react';
import { executeAccreditationsCategoriesQuery } from '../../generated/graphqlHooks';

interface IAccreditationsContext {
    getAccreditationsCategories(): Promise<
        AccreditationsCategoriesQuery['event']['accreditationsCategories']
    >;
    getTags(): Promise<TagsQuery['organization']['tags']['nodes']>;
}

const AccreditationsContext = React.createContext<IAccreditationsContext>(
    {} as IAccreditationsContext
);

interface IAccreditationsContextProviderProps {
    children: React.ReactNode;
    eventId: EventId;
    organizationId: OrganizationId;
}

export const AccreditationsContextProvider = (props: IAccreditationsContextProviderProps) => {
    const [accreditationsCategories, setAccreditationsCategories] =
        React.useState<
            Emptyable<AccreditationsCategoriesQuery['event']['accreditationsCategories']>
        >(null);
    const [tags, setTags] =
        React.useState<Emptyable<TagsQuery['organization']['tags']['nodes']>>(null);
    const getAccreditationsCategories = React.useCallback(async () => {
        if (accreditationsCategories) {
            return accreditationsCategories;
        } else {
            const { event } = await executeAccreditationsCategoriesQuery(
                {
                    eventId: props.eventId
                },
                await getToken()
            );

            setAccreditationsCategories(event.accreditationsCategories);

            return event.accreditationsCategories;
        }
    }, [accreditationsCategories, setAccreditationsCategories]);
    const getTags = React.useCallback(async () => {
        if (tags) {
            return tags;
        } else {
            const { organization } = await executeTagsQuery(
                { organizationId: props.organizationId },
                await getToken()
            );

            setTags(organization.tags.nodes);

            return organization.tags.nodes;
        }
    }, [tags, setTags]);

    return (
        <AccreditationsContext.Provider
            value={{
                getAccreditationsCategories,
                getTags
            }}
        >
            {props.children}
        </AccreditationsContext.Provider>
    );
};

export function useAccreditationsContext() {
    return React.useContext(AccreditationsContext);
}
