import { Avatar } from 'common/src/components/avatar/avatar';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { CampaignQuery } from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { useService } from 'common/src/util/dependencies/dependencies';
import { LocaleFormats } from 'common/src/util/luxon';
import { DateTime } from 'luxon';
import * as React from 'react';
import { CampaignPreviewTo } from './campaignPreviewTo';

interface ICampaignTextMessagePreviewProps {
    campaign: CampaignQuery['organization']['campaign'];
}

export const CampaignTextMessagePreview = (props: ICampaignTextMessagePreviewProps) => {
    const dateTimeService = useService(DateTimeService);
    const sentAt = props.campaign.sentAt?.setZone(DateTime.local().zone);

    return (
        <Flex css={{ background: '$gray200', py: '$6' }} justify="center" width={1}>
            <Flex
                css={{
                    background: 'white',
                    borderRadius: '$2',
                    boxShadow: '$md',
                    padding: '$6'
                }}
                direction="column"
                gap="3"
                width={640}
            >
                <CampaignPreviewTo campaign={props.campaign} />

                <Box
                    css={{
                        borderTop: '1px solid $gray200',
                        height: '1px'
                    }}
                />

                <Flex gap="3" width={1}>
                    {props.campaign.createdBy && (
                        <Box>
                            <Avatar
                                email={props.campaign.createdBy.email}
                                image={props.campaign.createdBy.picture?.url}
                                name={props.campaign.createdBy.name}
                                size={40}
                            />
                        </Box>
                    )}

                    <Flex direction="column" gap="1" width={1}>
                        {(props.campaign.createdBy || sentAt) && (
                            <Flex gap="3" width={1}>
                                {props.campaign.createdBy && (
                                    <Box color="gray800" css={{ flex: '1' }} fontWeight="medium">
                                        {props.campaign.createdBy.name}
                                    </Box>
                                )}

                                {sentAt && (
                                    <Box color="gray500">
                                        {dateTimeService.toLocaleString(
                                            sentAt,
                                            LocaleFormats.DateTime
                                        )}
                                    </Box>
                                )}
                            </Flex>
                        )}

                        <Box
                            color="gray800"
                            css={{
                                background: '$gray100',
                                borderRadius: '$2',
                                borderTopLeftRadius: '0',
                                boxShadow: '$sm',
                                padding: '$3'
                            }}
                        >
                            {props.campaign.text}
                        </Box>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
};
