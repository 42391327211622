import { DeleteModal } from 'common-front/src/designSystem/components/deleteModal';
import {
    closeOtherDropdowns,
    Dropdown
} from 'common-front/src/designSystem/components/dropdown/dropdown';
import { ItemIcon } from 'common-front/src/designSystem/components/dropdown/item';
import { Menu } from 'common-front/src/designSystem/components/dropdown/menu';
import { Trigger } from 'common-front/src/designSystem/components/dropdown/trigger';
import { ToggleText } from 'common-front/src/designSystem/components/toggle';
import { useUserContext } from 'common-front/src/userContext';
import { Avatar } from 'common/src/components/avatar/avatar';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { TeamQuery } from 'common/src/generated/types';
import { useParams, useTranslate } from 'common/src/util/dependencies/dependencies';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';
import {
    useEmailOrganizationInviteResendMutation,
    useUserOrganizationDeleteMutation,
    useUserOrganizationUpdateMutation
} from '../../generated/graphqlHooks';

interface IOrganizationUserProps {
    userOrganization: TeamQuery['organization']['usersOrganizations'][0];

    reload(): void;
}

export const OrganizationUser = (props: IOrganizationUserProps) => {
    const translate = useTranslate();
    const { organizationId } = useParams();
    const { user: currentUser } = useUserContext();
    const { mutate: updateUser } = useUserOrganizationUpdateMutation();
    const { mutate: deleteUser } = useUserOrganizationDeleteMutation();
    const { mutate: emailResend } = useEmailOrganizationInviteResendMutation();
    const [isDeleteOpen, setIsDeleteOpen] = React.useState(false);
    const { sendNotifications, user } = props.userOrganization;
    const isUserDeleted =
        user.email.startsWith('deleted') && user.email.endsWith('@recrewteer.com');

    return (
        <Flex
            align="center"
            css={{
                backgroundColor: isUserDeleted ? '$error400' : 'inherit',
                border: isUserDeleted ? '2px solid $error600' : '1px solid $gray200',
                borderRadius: '$1',
                boxShadow: '$xs',
                color: isUserDeleted ? 'white !important' : 'inherit',
                paddingBottom: '$6',
                paddingTop: '$7',
                px: '$7'
            }}
            direction="column"
            width={1}
        >
            <Flex width={1}>
                <Box width={20} />

                <Flex css={{ flex: '1' }} justify="center">
                    <Avatar
                        email={user.email}
                        image={user.userInfo.picture?.url}
                        name={user.userInfo.name}
                        size={64}
                    />
                </Flex>

                {user.id === currentUser?.id ? (
                    <Box width={20} />
                ) : (
                    <Dropdown>
                        <Trigger>
                            <Box
                                color={isUserDeleted ? 'white' : 'gray400'}
                                css={{ cursor: 'pointer' }}
                                height={20}
                                textAlign="center"
                                width={20}
                            >
                                <I icon="ellipsis-vertical" />
                            </Box>
                        </Trigger>

                        <Menu placement="bottom-end">
                            {!isUserDeleted && (
                                <ItemIcon
                                    icon="paper-plane"
                                    onClick={async (e) => {
                                        closeOtherDropdowns(e.target);

                                        await emailResend({
                                            organizationId,
                                            userInfoId: user.userInfo.id
                                        });
                                    }}
                                >
                                    {translate('r_envoyer_l_e_96215')}
                                </ItemIcon>
                            )}

                            <ItemIcon
                                color="red"
                                icon="trash-can"
                                onClick={(e) => {
                                    setIsDeleteOpen(true);
                                    closeOtherDropdowns(e.target);
                                }}
                            >
                                {translate('supprimer_l_acc_85342')}
                            </ItemIcon>
                        </Menu>
                    </Dropdown>
                )}
            </Flex>

            <Spacer height="4" />

            <Box color={isUserDeleted ? 'white' : 'gray500'} fontSize="textLg" fontWeight="medium">
                {isNonEmptyString(user.userInfo.name) ? user.userInfo.name : '-'}
            </Box>

            <Spacer height="1" />

            <Box color={isUserDeleted ? 'white' : 'gray500'}>{user.email}</Box>

            <Spacer height="4" />

            {!isUserDeleted && (
                <ToggleText
                    value={sendNotifications}
                    onChange={async () => {
                        await updateUser({
                            organizationId,
                            userId: user.id,
                            sendNotifications: !sendNotifications
                        });

                        props.reload();
                    }}
                >
                    {sendNotifications
                        ? translate('re_oit_les_noti_86930')
                        : translate('ne_re_oit_pas_l_14213')}
                </ToggleText>
            )}

            {isDeleteOpen && (
                <DeleteModal
                    buttonText={translate('supprimer_43083')}
                    subtext={translate('_tes_vous_s_r_d_19191', user.email)}
                    text={translate('supprimer_l_acc_85342')}
                    onClose={() => {
                        setIsDeleteOpen(false);
                    }}
                    onDelete={async () => {
                        await deleteUser({
                            organizationId,
                            userId: user.id
                        });

                        props.reload();
                    }}
                />
            )}
        </Flex>
    );
};
