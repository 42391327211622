import { Button } from 'common-front/src/designSystem/components/button';
import { DeleteModal } from 'common-front/src/designSystem/components/deleteModal';
import { Dropdown } from 'common-front/src/designSystem/components/dropdown/dropdown';
import { ItemIcon } from 'common-front/src/designSystem/components/dropdown/item';
import { Menu } from 'common-front/src/designSystem/components/dropdown/menu';
import { Title } from 'common-front/src/designSystem/components/dropdown/title';
import { Trigger } from 'common-front/src/designSystem/components/dropdown/trigger';
import { Separator } from 'common-front/src/designSystem/components/separator';
import { useSegmentsContext } from 'common-front/src/segments/segmentsContext';
import { Feature, UsersInfoId, UsersInfosSortAttributes } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import {
    useOrganizationUsersInfosMassEditMutation,
    useUsersActionsQuery,
    useUsersInfosMassDeleteMutation
} from '../../generated/graphqlHooks';
import { useOrganizationContext } from '../../organization/organizationContext';
import { MassCampaignSendModal } from '../../volunteers/massCampaignSend/massCampaignSendModal';
import { MassDelegationAddModal } from '../../volunteers/massDelegationAdd/massDelegationAddModal';
import { MassEditModal } from '../../volunteers/massEdit/massEditModal';
import { MassFormAddModal } from '../../volunteers/massFormAdd/massFormAddModal';
import { MassFormRemoveModal } from '../../volunteers/massFormRemove/massFormRemoveModal';
import { MassAddEventModal } from './massAddEvent/massAddEventModal';
import { MergeMembersModal } from './mergeMembers/mergeMembersModal';

export const UsersActions = () => {
    const translate = useTranslate();
    const { mutate } = useOrganizationUsersInfosMassEditMutation();
    const { mutate: usersInfosMassDelete } = useUsersInfosMassDeleteMutation();
    const { hasFeature } = useOrganizationContext();
    const {
        isSegmentSelected,
        massEditSegmentId,
        massEditSelectedIds,
        numberOfSelected,
        onMassEditSuccess,
        organizationId,
        segment
    } = useSegmentsContext<UsersInfoId, UsersInfosSortAttributes>();
    const { data } = useUsersActionsQuery({ organizationId });
    const [isMassEditVisible, setIsMassEditVisible] = React.useState(false);
    const [isMassAddEventVisible, setIsMassAddEventVisible] = React.useState(false);
    const [isMergeModalOpen, setIsMergeModalOpen] = React.useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
    const [isMassDelegationAddVisible, setIsMassDelegationAddVisible] = React.useState(false);
    const [isMassFormAddVisible, setIsMassFormAddVisible] = React.useState(false);
    const [isMassFormRemoveVisible, setIsMassFormRemoveVisible] = React.useState(false);
    const [isMassCampaignSendVisible, setIsMassCampaignSendVisible] = React.useState(false);
    const finalNumberOfSelected = isSegmentSelected ? segment.count : numberOfSelected;

    return (
        <>
            <Button
                onClick={() => {
                    setIsMassEditVisible(true);
                }}
            >
                {translate('_diter_un_champ_64621')}
            </Button>

            <Dropdown>
                <Trigger>
                    <Button color="white" leftIcon="ellipsis-vertical" />
                </Trigger>

                <Menu placement="bottom-end">
                    <Title>{translate('gestion_des_mem_51660')}</Title>

                    <ItemIcon
                        icon="plus"
                        isDisabled={!data.organization?.hasEvents}
                        onClick={() => {
                            setIsMassAddEventVisible(true);
                        }}
                    >
                        {translate('ajouter_1_membr_25541', finalNumberOfSelected)}
                    </ItemIcon>

                    <ItemIcon
                        icon="merge"
                        onClick={() => {
                            setIsMergeModalOpen(true);
                        }}
                    >
                        {translate('fusionner_les_m_37150')}
                    </ItemIcon>

                    <Separator color="gray100" direction="horizontal" />

                    <Title>{translate('accr_ditations_39450')}</Title>

                    {hasFeature(Feature.Delegation) && (
                        <ItemIcon
                            icon="people-group"
                            isDisabled={!data.organization?.hasDelegations}
                            onClick={() => {
                                setIsMassDelegationAddVisible(true);
                            }}
                        >
                            {data.organization?.hasDelegations
                                ? translate('ajouter_1_mem_86218', finalNumberOfSelected)
                                : translate('cette_organisat_48937')}
                        </ItemIcon>
                    )}

                    <Separator color="gray100" direction="horizontal" />

                    <Title>{translate('communication_05277')}</Title>

                    <ItemIcon
                        icon="file-lines"
                        isDisabled={!data.organization?.hasForms}
                        onClick={() => {
                            setIsMassFormAddVisible(true);
                        }}
                    >
                        {translate('ajouter_1_mem_52947', finalNumberOfSelected)}
                    </ItemIcon>

                    <ItemIcon
                        icon="file-lines"
                        isDisabled={!data.organization?.hasForms}
                        onClick={() => {
                            setIsMassFormRemoveVisible(true);
                        }}
                    >
                        {translate('marquer_un_form_10633')}
                    </ItemIcon>

                    <ItemIcon
                        icon="paper-plane"
                        onClick={() => {
                            setIsMassCampaignSendVisible(true);
                        }}
                    >
                        {translate('envoyer_une_cam_94630')}
                    </ItemIcon>

                    <Separator color="gray100" direction="horizontal" />

                    <ItemIcon
                        color="red"
                        icon="trash-can"
                        onClick={() => {
                            setIsDeleteModalOpen(true);
                        }}
                    >
                        {translate('supprimer_43083')}
                    </ItemIcon>
                </Menu>
            </Dropdown>

            {isMassEditVisible && (
                <MassEditModal
                    mutate={(massEdit) =>
                        mutate({
                            organizationId,
                            massEdit
                        })
                    }
                    organizationId={organizationId}
                    segmentId={massEditSegmentId}
                    usersInfosIds={massEditSelectedIds}
                    onClose={() => {
                        setIsMassEditVisible(false);
                    }}
                    onSuccess={onMassEditSuccess}
                />
            )}

            {isMassAddEventVisible && (
                <MassAddEventModal
                    numberOfSelected={finalNumberOfSelected}
                    organizationId={organizationId}
                    segmentId={massEditSegmentId}
                    usersInfosIds={massEditSelectedIds}
                    onClose={() => {
                        setIsMassAddEventVisible(false);
                    }}
                    onSuccess={onMassEditSuccess}
                />
            )}

            {isMergeModalOpen && (
                <MergeMembersModal
                    numbersOfSelected={finalNumberOfSelected}
                    organizationId={organizationId}
                    usersInfosIds={massEditSelectedIds}
                    onClose={() => {
                        setIsMergeModalOpen(false);
                    }}
                    onSuccess={onMassEditSuccess}
                />
            )}

            {isDeleteModalOpen && (
                <DeleteModal
                    buttonText={translate('supprimer_43083')}
                    subtext={[
                        translate('_tes_vous_s_r_d_65177'),
                        translate('cette_action_es_45424')
                    ]}
                    text={translate('suppression_de_57689', finalNumberOfSelected)}
                    onClose={() => {
                        setIsDeleteModalOpen(false);
                    }}
                    onDelete={async () => {
                        await usersInfosMassDelete({
                            organizationId,
                            segmentId: massEditSegmentId,
                            usersInfosIds: massEditSelectedIds
                        });

                        onMassEditSuccess();
                    }}
                />
            )}

            {isMassFormAddVisible && (
                <MassFormAddModal
                    eventId={null}
                    organizationId={organizationId}
                    segmentId={massEditSegmentId}
                    usersInfosIds={massEditSelectedIds}
                    onClose={() => {
                        setIsMassFormAddVisible(false);
                    }}
                    onSuccess={onMassEditSuccess}
                />
            )}

            {isMassFormRemoveVisible && (
                <MassFormRemoveModal
                    eventId={null!}
                    organizationId={organizationId}
                    segmentId={massEditSegmentId}
                    usersInfosIds={massEditSelectedIds}
                    onClose={() => {
                        setIsMassFormRemoveVisible(false);
                    }}
                    onSuccess={onMassEditSuccess}
                />
            )}

            {isMassDelegationAddVisible && (
                <MassDelegationAddModal
                    eventId={null}
                    organizationId={organizationId}
                    segmentId={massEditSegmentId}
                    usersInfosIds={massEditSelectedIds}
                    onClose={() => {
                        setIsMassDelegationAddVisible(false);
                    }}
                    onSuccess={onMassEditSuccess}
                />
            )}

            {isMassCampaignSendVisible && (
                <MassCampaignSendModal
                    eventId={null}
                    organizationId={organizationId}
                    segmentId={massEditSegmentId}
                    usersInfosIds={massEditSelectedIds}
                    onClose={() => {
                        setIsMassCampaignSendVisible(false);
                    }}
                    onSuccess={onMassEditSuccess}
                />
            )}
        </>
    );
};
