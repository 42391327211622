import { Blank } from 'common-front/src/components/blank/blank';
import { TableFilters } from 'common-front/src/designSystem/components/tableFilters';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { usePaginationInfos } from 'common-front/src/hooks/usePaginationInfos';
import { useStateDebounce } from 'common-front/src/hooks/useStateDebounce';
import { Svgs } from 'common-front/src/util/assets';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { HeaderCellSort } from 'common/src/designSystem/components/table/headerCellSort';
import { Row } from 'common/src/designSystem/components/table/row';
import { RowSkeleton } from 'common/src/designSystem/components/table/rowSkeleton';
import {
    Accreditation,
    AccreditationState,
    SegmentCustomFieldFragment,
    SortDirection,
    VolunteerRegistrationState,
    VolunteersRegistrationsSortAttributes
} from 'common/src/generated/types';
import { isNonEmptyArray } from 'common/src/util/array';
import { useService } from 'common/src/util/dependencies/dependencies';
import { Emptyable } from 'common/src/util/emptyable';
import { AccreditationsPaths } from 'common/src/util/paths/accreditationsPaths';
import { isNonEmptyString } from 'common/src/util/string';
import { AccreditationMembersSegmentsService } from 'common/src/vo/segments/accreditationMembersSegmentsService';
import { VolunteerDefaultColumns } from 'common/src/vo/segments/volunteersSegmentsService';
import { Sort } from 'common/src/vo/sort';
import * as React from 'react';
import { Route, Switch } from 'react-router';
import { useAccreditationMembersQuery } from '../../../../generated/graphqlHooks';
import { AccreditationMemberRow } from './accreditationMemberRow';
import { AccreditationMembersFilters } from './accreditationMembersFilters';

interface IAccreditationMembersProps {
    accreditation: Pick<Accreditation, 'name'>;
    basePath: string;
    canEditMembers: boolean;
    customFields: SegmentCustomFieldFragment[];
    isEventAdmin?: boolean;
    showDeleteFromEvent: boolean;
    showDocumentsDownload: boolean;
    userOverlay?: React.ReactNode;
}

export const AccreditationMembers = ({
    accreditation,
    basePath,
    canEditMembers,
    customFields,
    isEventAdmin,
    showDeleteFromEvent,
    showDocumentsDownload,
    userOverlay
}: IAccreditationMembersProps) => {
    const {
        params: { organizationId, eventId, accreditationId },
        translate
    } = useHeavent();
    const accreditationnMembersSegmentsService = useService(AccreditationMembersSegmentsService);
    const [search, searchDebounced, setSearch] = useStateDebounce('');
    const [assignmentState, setAssignmentState] =
        React.useState<Emptyable<VolunteerRegistrationState>>(null);
    const [accreditationState, setAccreditationState] =
        React.useState<Emptyable<AccreditationState>>(null);
    const [limit, setLimit] = React.useState(25);
    const [offset, setOffset] = React.useState(0);
    const [sort, _setSort] = React.useState<Sort<VolunteersRegistrationsSortAttributes> | null>({
        attribute: VolunteersRegistrationsSortAttributes.Name,
        direction: SortDirection.Asc
    });
    const { data, isLoading, reload } = useAccreditationMembersQuery({
        organizationId,
        eventId,
        accreditationId,
        name: isNonEmptyString(searchDebounced) ? searchDebounced : null,
        assignmentState: assignmentState ? assignmentState : undefined,
        limit,
        offset,
        sort
    });
    const { numberOfPages, totalCount } = usePaginationInfos(
        data.organization?.event.volunteersRegistrations
    );
    const setSort = (newSort: Sort<VolunteersRegistrationsSortAttributes> | null) => {
        _setSort(newSort);
        setOffset(0);
    };
    const selectedColumns = [
        VolunteerDefaultColumns.Name,
        VolunteerDefaultColumns.AccreditationsSlots
    ];
    const columns = React.useMemo(() => {
        if (data.organization) {
            const possibleColumns =
                accreditationnMembersSegmentsService.getAccreditationMembersPossibleColumns(
                    customFields
                );

            return selectedColumns.flatMap((slug) => {
                const column = possibleColumns.find((c) => c.slug === slug);

                return column ? [column] : [];
            });
        } else {
            return [];
        }
    }, [customFields, accreditation, data.organization]);

    return (
        <>
            <TableFilters
                filters={
                    <AccreditationMembersFilters
                        accreditationState={accreditationState}
                        assignmentState={assignmentState}
                        eventId={eventId}
                        search={search}
                        setAccreditationState={setAccreditationState}
                        setAssignmentState={setAssignmentState}
                        setOffset={setOffset}
                        setSearch={setSearch}
                    />
                }
                headerCells={
                    <>
                        {columns.map((column) => {
                            if (column.slug === VolunteerDefaultColumns.Name) {
                                return (
                                    <HeaderCellSort
                                        key={column.slug}
                                        attribute={VolunteersRegistrationsSortAttributes.Name}
                                        setSort={setSort}
                                        sort={sort}
                                    >
                                        {translate('nom_complet_du_71872')}
                                    </HeaderCellSort>
                                );
                            } else if (
                                column.slug === VolunteerDefaultColumns.AccreditationState ||
                                column.slug === VolunteerDefaultColumns.VolunteerRegistrationState
                            ) {
                                return (
                                    <HeaderCell key={column.slug} width={240}>
                                        {column.name}
                                    </HeaderCell>
                                );
                            } else {
                                return <HeaderCell key={column.slug}>{column.name}</HeaderCell>;
                            }
                        })}

                        <HeaderCell width={100} />
                    </>
                }
                limit={limit}
                numberOfPages={numberOfPages}
                offset={offset}
                rows={
                    isLoading ? (
                        <>
                            <RowSkeleton bx={true} />
                            <RowSkeleton bx={true} />
                            <RowSkeleton bx={true} />
                        </>
                    ) : isNonEmptyArray(data.organization.event.volunteersRegistrations.nodes) ? (
                        (data.organization?.event?.volunteersRegistrations.nodes ?? []).map(
                            (vr) => (
                                <AccreditationMemberRow
                                    key={vr.id}
                                    basePath={basePath}
                                    canEditMembers={canEditMembers}
                                    columns={columns}
                                    customFields={customFields}
                                    isEventAdmin={isEventAdmin}
                                    member={vr}
                                    reload={reload}
                                    showDeleteFromEvent={showDeleteFromEvent}
                                    showDocumentsDownload={showDocumentsDownload}
                                />
                            )
                        )
                    ) : (
                        <Row css={{ height: '200px' }}>
                            <Cell>
                                <Blank
                                    imageSrc={Svgs.FormsElementsBlank}
                                    title={translate('aucun_membre_da_58845')}
                                />
                            </Cell>
                        </Row>
                    )
                }
                setLimit={setLimit}
                setOffset={setOffset}
                showLimits={true}
                title={translate('list_of_1_a_76308', accreditation.name)}
                totalCount={totalCount}
            />

            {userOverlay && (
                <Switch>
                    <Route
                        path={AccreditationsPaths.ACCREDITATION_MEMBER({
                            organizationId: ':organizationId',
                            eventId: ':eventId',
                            accreditationId: ':accreditationId',
                            userInfoId: ':userInfoId'
                        })}
                    >
                        {userOverlay}
                    </Route>
                </Switch>
            )}
        </>
    );
};
