import { Button } from 'common-front/src/designSystem/components/button';
import { RadioText } from 'common-front/src/designSystem/form/radio';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { ICreateUpdateCampaignValues } from 'common/src/input/campaignInput';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { FormRenderProps } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { CampaignOrganizationSegmentsRecipients } from './campaignOrganizationSegmentsRecipients';
import { CampaignOrganizationUsersRecipients } from './campaignOrganizationUsersRecipients';

interface ICampaignOrganizationRecipientsProps {
    formProps: FormRenderProps<ICreateUpdateCampaignValues>;
}

export const CampaignOrganizationRecipients = (props: ICampaignOrganizationRecipientsProps) => {
    const translate = useTranslate();
    const { form, handleSubmit, values } = props.formProps;

    return (
        <>
            <OnChange name="recipients">
                {(recipients: string) => {
                    if (recipients === 'everyone') {
                        form.change('campaign.sendToEveryone', true);
                        form.change('campaign.usersInfosIds', []);
                        form.change('campaign.segmentsIds', []);
                    } else if (recipients === 'users') {
                        form.change('campaign.sendToEveryone', false);
                        form.change('campaign.segmentsIds', []);
                    } else if (recipients === 'segments') {
                        form.change('campaign.sendToEveryone', false);
                        form.change('campaign.usersInfosIds', []);
                    } else {
                        throw new Error(`Recipients ${recipients} is not possible`);
                    }
                }}
            </OnChange>

            <OnChange name="campaign.sendToEveryone">
                {(sendToEveryone: boolean) => {
                    if (sendToEveryone) {
                        form.change('campaign.segmentsIds', []);
                        form.change('campaign.usersInfosIds', []);
                    }
                }}
            </OnChange>

            <OnChange name="campaign.usersInfosIds">
                {() => {
                    if (
                        values.campaign.sendToEveryone &&
                        values.campaign.usersInfosIds.length > 0
                    ) {
                        form.change('campaign.usersInfosIds', []);
                    }
                }}
            </OnChange>

            <OnChange name="campaign.segmentsIds">
                {() => {
                    if (values.campaign.sendToEveryone && values.campaign.segmentsIds.length > 0) {
                        form.change('campaign.segmentsIds', []);
                    }
                }}
            </OnChange>

            <RadioText name="recipients" value="everyone">
                {translate('_tous_vos_memb_83616')}
            </RadioText>

            <Spacer height="3" />

            <RadioText name="recipients" value="users">
                {translate('_certains_memb_91094')}
            </RadioText>

            <CampaignOrganizationUsersRecipients recipients={values.recipients} />

            <Spacer height="3" />

            <RadioText name="recipients" value="segments">
                {translate('_certaines_vue_55512')}
            </RadioText>

            <CampaignOrganizationSegmentsRecipients recipients={values.recipients} />

            <Spacer height="7" />

            <Flex>
                <Button onClick={handleSubmit}>{translate('suivant_livra_97068')}</Button>
            </Flex>
        </>
    );
};
