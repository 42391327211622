import { Box } from '../designSystem/components/box';
import { styled } from '../designSystem/components/stitches';

/* eslint-disable-next-line @typescript-eslint/no-var-requires */
const UnavailableCellPng = require('../../../../assets/img/unavailableCell.png');

export const HeaderCell = styled(Box, {
    alignItems: 'center',

    color: '$gray900',
    display: 'flex',
    height: '40px',
    justifyContent: 'center',
    variants: {
        border: {
            gray100: {
                borderBottom: '1px solid $gray100',
                borderRight: '1px solid $gray100'
            },
            gray200: {
                borderBottom: '1px solid $gray200',
                borderRight: '1px solid $gray200'
            }
        }
    },
    defaultVariants: {
        border: 'gray100'
    }
});

export const NameCell = styled(Box, {
    alignItems: 'center',
    borderBottom: '1px solid $gray100',
    borderRight: '1px solid $gray100',
    color: '$gray700',
    display: 'flex',
    gap: '$2',
    height: '48px',
    px: '$4',
    width: '200px'
});

export const AssignedCell = styled(Box, {
    alignItems: 'center',
    borderBottom: '1px solid $gray100',
    borderRight: '1px solid $gray100',
    color: '$gray800',
    display: 'flex',
    height: '48px',
    padding: '$2',
    width: '100px',
    '& > div': {
        alignItems: 'center',
        background: '$success400',
        borderRadius: '$1',
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        width: '100%',
        '& svg': {
            height: '50%'
        }
    }
});

export const UnavailableCell = styled(Box, {
    background: `url(${UnavailableCellPng}) no-repeat`,
    borderBottom: '1px solid $gray100',
    borderRight: '1px solid $gray100',
    color: '$gray700',
    height: '48px',
    width: '100px'
});

export const AvailableCell = styled(Box, {
    alignItems: 'center',
    borderBottom: '1px solid $gray100',
    borderRight: '1px solid $gray100',
    color: '$gray800',
    display: 'flex',
    height: '48px',
    padding: '$2',
    width: '100px',
    '& > div': {
        alignItems: 'center',
        border: '1px dashed $gray300',
        borderRadius: '$1',
        display: 'flex',
        fontSize: '$textXs',
        height: '100%',
        justifyContent: 'center',
        width: '100%'
    }
});
