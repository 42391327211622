import { EmailsSender } from '../generated/types';
import { isNonEmptyString } from '../util/string';

export function from(emailSender: Pick<EmailsSender, 'fromName' | 'fromEmail'>) {
    if (isNonEmptyString(emailSender.fromName)) {
        return `${emailSender.fromName} (${emailSender.fromEmail})`;
    } else {
        return emailSender.fromEmail;
    }
}
