import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { FilterPredicate, Operator, Predicate } from 'common/src/vo/segment';
import { DateTime } from 'luxon';
import * as React from 'react';
import { Button } from '../../designSystem/components/button';
import { DatetimeInput } from '../../designSystem/components/date/datetimeInput';
import { RadioText } from '../../designSystem/components/radio';

interface IAddDatetimeFilterProps {
    filterPredicate: FilterPredicate;

    onAdd(predicate: Predicate): void;
}

export const AddDatetimeFilter = (props: IAddDatetimeFilterProps) => {
    const translate = useTranslate();
    const [operator, setOperator] = React.useState<Operator>(
        props.filterPredicate.predicate?.operator ?? 'is'
    );
    const [value, setValue] = React.useState<DateTime | string>(
        props.filterPredicate.predicate?.value ?? ''
    );
    const [value2, setValue2] = React.useState<DateTime | string>(
        props.filterPredicate.predicate?.value2 ?? ''
    );

    const valid = ['isBefore', 'isAfter'].includes(operator)
        ? DateTime.isDateTime(value) && value.isValid
        : operator === 'between'
          ? DateTime.isDateTime(value) &&
            value.isValid &&
            DateTime.isDateTime(value2) &&
            value2.isValid
          : true;

    return (
        <>
            <RadioText
                state={operator === 'isBefore' ? 'checked' : 'unchecked'}
                onClick={() => {
                    setOperator('isBefore');
                }}
            >
                {translate('est_avant_le_16689')}
            </RadioText>

            {operator === 'isBefore' && (
                <>
                    <Spacer height="1" />

                    <Flex>
                        <Spacer width="6" />
                        <DatetimeInput value={value} onChange={setValue} />
                    </Flex>

                    <Spacer height="1" />
                </>
            )}

            <Spacer height="3" />

            <RadioText
                state={operator === 'isAfter' ? 'checked' : 'unchecked'}
                onClick={() => {
                    setOperator('isAfter');
                }}
            >
                {translate('est_apr_s_le_79703')}
            </RadioText>

            {operator === 'isAfter' && (
                <>
                    <Spacer height="1" />

                    <Flex>
                        <Spacer width="6" />
                        <DatetimeInput value={value} onChange={setValue} />
                    </Flex>

                    <Spacer height="1" />
                </>
            )}

            <Spacer height="3" />

            <RadioText
                state={operator === 'between' ? 'checked' : 'unchecked'}
                onClick={() => {
                    setOperator('between');
                }}
            >
                {translate('est_entre_born_59665')}
            </RadioText>

            {operator === 'between' && (
                <>
                    <Spacer height="1" />

                    <Flex>
                        <Spacer width="6" />
                        <DatetimeInput value={value} onChange={setValue} />
                    </Flex>

                    <Spacer height="1" />

                    <Flex>
                        <Spacer width="6" />
                        <DatetimeInput value={value2} onChange={setValue2} />
                    </Flex>

                    <Spacer height="1" />
                </>
            )}

            {props.filterPredicate.filter.hideEmpty !== true && (
                <>
                    <Spacer height="3" />

                    <RadioText
                        state={operator === 'isEmpty' ? 'checked' : 'unchecked'}
                        onClick={() => {
                            setOperator('isEmpty');
                        }}
                    >
                        {translate('est_vide_96759')}
                    </RadioText>

                    <Spacer height="3" />

                    <RadioText
                        state={operator === 'isNotEmpty' ? 'checked' : 'unchecked'}
                        onClick={() => {
                            setOperator('isNotEmpty');
                        }}
                    >
                        {translate('n_est_pas_vide_45804')}
                    </RadioText>
                </>
            )}

            <Spacer height="7" />

            <Flex>
                <Button
                    disabled={!valid}
                    onClick={() => {
                        props.onAdd({
                            operator,
                            value,
                            value2,
                            slug: props.filterPredicate.filter.slug
                        });
                    }}
                >
                    {translate('ajouter_le_filt_54222')}
                </Button>
            </Flex>
        </>
    );
};
