import { groupBy, min, sortBy } from 'lodash-es';
import { DateTime, Interval } from 'luxon';
import * as React from 'react';
import { Box } from '../../designSystem/components/box';
import {
    DocumentUserAssignmentsFragment,
    UserAssignmentsDocumentOptions
} from '../../generated/types';
import { isNonEmptyString } from '../../util/string';
import { PositionSheetsInfos } from '../positionSheet/positionSheetsInfos';
import { AssignmentHeader } from './assignmentHeader';
import { AssignmentsImageHeader } from './assignmentsImageHeader';

interface IUserAssignmentsProps {
    assignmentsInfos: DocumentUserAssignmentsFragment;
    date?: DateTime;
    endDate?: DateTime;
    options: UserAssignmentsDocumentOptions;
}

export const UserAssignments = (props: IUserAssignmentsProps) => {
    const assignments = React.useMemo(
        () =>
            props.assignmentsInfos.event.volunteerRegistration.positionsSlotsUsersInfos.filter(
                (psui) => {
                    if (props.date?.isValid && props.endDate?.isValid) {
                        const interval = Interval.fromDateTimes(
                            props.date.startOf('day'),
                            props.endDate.endOf('day')
                        );

                        return psui.positionSlot.range.overlaps(interval);
                    } else if (props.date?.isValid) {
                        return (
                            psui.positionSlot.range
                                .start!.startOf('day')
                                .equals(props.date.startOf('day')) ||
                            psui.positionSlot.range
                                .end!.startOf('day')
                                .equals(props.date.startOf('day'))
                        );
                    } else {
                        return true;
                    }
                }
            ),
        [props.assignmentsInfos.event, props.date, props.endDate]
    );
    const assignmentsByPosition = React.useMemo(
        () =>
            sortBy(
                Object.entries(groupBy(assignments, (a) => a.position.id)),
                ([_positionName, psuis]) =>
                    min(psuis.map((psui) => psui.positionSlot.range.start!.toMillis()))
            ),
        [assignments]
    );

    return (
        <Box css={{ background: 'white' }} width={1}>
            {assignmentsByPosition.map(([_positionName, psuis], index) => (
                <React.Fragment key={index}>
                    {isNonEmptyString(props.options.header?.content) ? (
                        <AssignmentsImageHeader
                            headerContent={props.options.header.content}
                            numberOfAssignments={assignments.length}
                            userInfo={props.assignmentsInfos.organization.userInfo}
                        />
                    ) : (
                        <AssignmentHeader
                            eventName={props.assignmentsInfos.event.name}
                            numberOfAssignments={assignments.length}
                            options={props.options}
                            pageBreak={index !== 0}
                            userInfo={props.assignmentsInfos.organization.userInfo}
                        />
                    )}

                    <PositionSheetsInfos
                        displayPositionName={true}
                        leadersData={props.options.leadersData}
                        position={psuis[0].position}
                        ranges={psuis.map((psui) => psui.positionSlot)}
                        showResourcesInfos={false}
                    />
                </React.Fragment>
            ))}
        </Box>
    );
};
