import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import * as React from 'react';
import { useFormElementsQuery } from '../../../generated/graphqlHooks';
import { useFormContext } from '../../show/formContext';
import { FormElementsContent } from './formElementsContent';
import { FormElementsSkeleton } from './formElementsSkeleton';

export const FormElements = () => {
    const { formId, organizationId } = useFormContext();
    const { data, isLoading } = useFormElementsQuery({ organizationId, formId });

    return (
        <Flex justify="center" width={1}>
            <Flex direction="column" width="$container">
                <Spacer height="8" />

                {isLoading ? (
                    <FormElementsSkeleton />
                ) : (
                    <FormElementsContent
                        customFields={data.organization.customFields.nodes}
                        initialElements={data.organization.form.elements}
                    />
                )}

                <Spacer height="8" />
            </Flex>
        </Flex>
    );
};
