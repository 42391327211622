import { Skeleton } from 'common/src/designSystem/components/skeleton';
import { Spacer } from 'common/src/designSystem/components/spacer';
import * as React from 'react';

export const FormsSkeleton = () => (
    <>
        <Skeleton borderRadius="$2" height={72} width={1} />
        <Spacer height="3" />
        <Skeleton borderRadius="$2" height={72} width={1} />
        <Spacer height="3" />
        <Skeleton borderRadius="$2" height={72} width={1} />
    </>
);
