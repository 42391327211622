import { Flex } from 'common/src/designSystem/components/flex';
import { Skeleton } from 'common/src/designSystem/components/skeleton';
import { Spacer } from 'common/src/designSystem/components/spacer';
import * as React from 'react';

export const DocumentsSkeleton = () => (
    <>
        <Spacer height="7" />

        <Flex direction="column" gap="2">
            <Skeleton borderRadius="$1" height={25} width={1} />
            <Skeleton borderRadius="$2" height={74} width={1} />
            <Skeleton borderRadius="$2" height={74} width={1} />
        </Flex>

        <Spacer height="7" />

        <Flex direction="column" gap="2">
            <Skeleton borderRadius="$1" height={25} width={1} />
            <Skeleton borderRadius="$2" height={74} width={1} />
            <Skeleton borderRadius="$2" height={74} width={1} />
        </Flex>
    </>
);
