import { Box } from 'common/src/designSystem/components/box';
import { PositionDashboardQuery } from 'common/src/generated/types';
import * as React from 'react';

interface IPositionColorMobileProps {
    position: Pick<PositionDashboardQuery['event']['position'], 'color'>;
}

export const PositionColorMobile = ({ position }: IPositionColorMobileProps) => (
    <Box
        color="gray800"
        css={{
            background: position.color,
            borderRadius: '20px',
            height: '20px',
            width: '20px'
        }}
    />
);
