import { BlankRow } from 'common-front/src/components/table/blankRow';
import { KpiCardMobile } from 'common-front/src/designSystem/components/kpiCardMobile';
import { TableFilters } from 'common-front/src/designSystem/components/tableFilters';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { styled } from 'common/src/designSystem/components/stitches';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { DurationService } from 'common/src/services/durationService';
import {
    useHistory,
    useParams,
    useService,
    useTranslate
} from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { noop } from 'lodash-es';
import * as React from 'react';
import { useEventContext } from '../../events/show/eventContext';
import { useDashboardMobileQuery } from '../../generated/graphqlHooks';
import { DashboardPosition } from '../dashboardPosition';
import { DashboardPositionCategory } from '../dashboardPositionCategory';

const _h1 = styled('h1', {
    fontSize: '$displaySm',
    fontWeight: '$semiBold',
    margin: 0
});

const _i = styled(I, {
    color: '$gray800',
    fontSize: '$textLg',
    padding: '$2'
});

const _h2 = styled('h2', {
    fontSize: '$textMd',
    fontWeight: '$semiBold',
    margin: 0
});

export const DashboardMobile = () => {
    const translate = useTranslate();
    const { organizationId, eventId } = useParams();
    const {
        isPositionCategoryAdmin,
        event: {
            permissions: { isEventAdmin }
        }
    } = useEventContext();
    const { data, loader } = useDashboardMobileQuery({ eventId });
    const durationService = useService(DurationService);
    const history = useHistory();
    const openSwitchEvent = () => {
        history.push(HeaventPaths.DASHBOARD_EVENT_SWITCH(organizationId, eventId));
    };

    if (loader) {
        return loader;
    } else {
        const showCategories =
            isEventAdmin ||
            data.event.positionsCategories.some((category) => isPositionCategoryAdmin(category.id));

        return (
            <Flex css={{ flex: 1, minHeight: '100%', padding: '$6 $3' }} direction="column" gap="6">
                <Flex align="center" gap="4" justify="between">
                    <_h1>{translate('accueil_70474')}</_h1>
                    <_i icon="arrow-down-arrow-up" onClick={openSwitchEvent} />
                </Flex>
                <Flex direction="column" gap="4">
                    <_h2>{data.event.name}</_h2>
                    <KpiCardMobile
                        header={translate('nombre_de_membr_60320', data.event.resources)}
                        value={data.event.resources}
                    />
                    <KpiCardMobile
                        header={translate('nombre_de_missi_67304', data.event.positions.totalCount)}
                        value={data.event.positions.totalCount}
                    />
                    <KpiCardMobile
                        header={translate('nombre_de_membr_87779', data.event.assigned.totalCount)}
                        value={data.event.assigned.totalCount}
                    />
                    <KpiCardMobile
                        header={translate(
                            'nombre_de_membr_63555',
                            data.event.waitingAssignment.totalCount
                        )}
                        value={data.event.waitingAssignment.totalCount}
                    />
                    <KpiCardMobile
                        header={translate('taux_de_remplis_11913')}
                        value={`${
                            data.event.resources === 0
                                ? '0'
                                : Math.round(
                                      (data.event.assignedResources / data.event.resources) * 100
                                  )
                        }%`}
                    />
                    <KpiCardMobile
                        header={translate('temps_de_b_n_vo_87924')}
                        value={durationService.minutesToHoursMinutesString(
                            data.event.volunteerMinutesNeeded
                        )}
                    />
                    <KpiCardMobile
                        header={translate('temps_de_b_n_vo_60725')}
                        value={durationService.minutesToHoursMinutesString(
                            data.event.volunteerMinutesAssigned
                        )}
                    />
                    <Box css={{ boxShadow: '$xs' }}>
                        {showCategories ? (
                            <TableFilters
                                filters={<></>}
                                headerCells={
                                    <HeaderCell>{translate('cat_gories_24533')}</HeaderCell>
                                }
                                numberOfPages={0}
                                offset={0}
                                rows={
                                    <>
                                        {data.event.positionsCategories.length > 0 ? (
                                            data.event.positionsCategories.map((category) => (
                                                <DashboardPositionCategory
                                                    key={category.id}
                                                    category={category}
                                                    clickable={false}
                                                    eventId={eventId}
                                                    organizationId={organizationId}
                                                />
                                            ))
                                        ) : (
                                            <BlankRow
                                                subtitle={translate('vous_n_avez_pas_59681')}
                                                title={translate('aucune_cat_gori_21347')}
                                            />
                                        )}
                                    </>
                                }
                                setOffset={noop}
                                title={translate('liste_des_membr_59735')}
                                totalCount={0}
                            />
                        ) : (
                            <TableFilters
                                filters={<></>}
                                headerCells={<HeaderCell>{translate('missions_63972')}</HeaderCell>}
                                numberOfPages={0}
                                offset={0}
                                rows={
                                    <>
                                        {data.event.positions.nodes.map((position) => (
                                            <DashboardPosition
                                                key={position.id}
                                                position={position}
                                            />
                                        ))}
                                    </>
                                }
                                setOffset={noop}
                                title={translate('liste_des_membr_57353')}
                                totalCount={0}
                            />
                        )}
                    </Box>
                </Flex>
            </Flex>
        );
    }
};
