import { Box } from 'common/src/designSystem/components/box';
import { CSS } from 'common/src/designSystem/components/stitches';
import * as React from 'react';
import { createPortal } from 'react-dom';

interface IPortalProps {
    children: React.ReactNode;
    css?: CSS;
}

export const Portal = (props: IPortalProps) =>
    createPortal(
        <Box
            css={{
                background: 'white',
                left: '0',
                position: 'absolute',
                top: '0',
                zIndex: '50',
                ...(props.css as any)
            }}
            height={1}
            width={1}
        >
            {props.children}
        </Box>,
        document.body
    );
