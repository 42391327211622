import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { CommonEnvVars } from '../../../envVars';
import { BruleursDocumentOptions, DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { A6_SIZES } from '../../../util/pdf';
import { isNonEmptyString } from '../../../util/string';

interface IBadgeBruleursA6FrontProps {
    event: DocumentUserCustomBadgeFragment;
    options: BruleursDocumentOptions;
}

export const BadgeBruleursA6Front = (props: IBadgeBruleursA6FrontProps) => {
    const vr = props.event.volunteerRegistration;
    const userInfo = vr.userInfo;
    const accreditations = vr.accreditationsUsersInfos.map((aui) => aui.accreditation);
    const vestiairesAccred = accreditations.find((a) => a.acronym === 'VEST');
    const tribunesAccred = accreditations.find((a) => a.acronym === 'TRI');
    const presseAccred = accreditations.find((a) => a.acronym === 'ZP');
    const logesAccred = accreditations.find((a) => a.acronym === 'LOG');
    const bdlAccred = accreditations.find((a) => a.acronym === 'BDL');
    const securiteAccred = accreditations.find((a) => a.acronym === 'SEC');
    const isSaison = props.event.id === 3838;
    const background = isSaison
        ? 'https://assets.recrewteer.com/badges/bruleurs/20242025/bg_accreditation_saison_recto.jpg'
        : 'https://assets.recrewteer.com/badges/bruleurs/20242025/bg_accreditation_match_recto.jpg';

    return (
        <Flex
            css={{
                background: `url(${background}) no-repeat`,
                backgroundSize: 'cover',
                fontFamily: '$bebasNeue',
                pageBreakBefore: 'always',
                position: 'relative'
            }}
            height={A6_SIZES['96dpi'].height}
            width={A6_SIZES['96dpi'].width}
        >
            <Box
                css={{
                    height: '175px',
                    left: '33px',
                    position: 'absolute',
                    top: '122px',
                    width: '163px'
                }}
            >
                {isNonEmptyString(userInfo.picture?.url) && (
                    <img height="100%" src={userInfo.picture!.url} width="100%" />
                )}
            </Box>

            {!isSaison && (
                <>
                    <Box
                        css={{
                            color: 'white',
                            fontSize: '35px',
                            left: '215px',
                            position: 'absolute',
                            top: '198px'
                        }}
                    >
                        {props.event.startAt.toFormat('dd/MM/yyyy')}
                    </Box>

                    <Box
                        css={{
                            left: '309px',
                            position: 'absolute',
                            top: '258px'
                        }}
                        height={56}
                        width={56}
                    >
                        {isNonEmptyString(props.options.logo?.content) && (
                            <img height="100%" src={props.options.logo.content} width="100%" />
                        )}
                    </Box>
                </>
            )}

            <Box
                css={{
                    color: isSaison ? '#0c2139' : 'white',
                    fontSize: '22px',
                    left: '37px',
                    position: 'absolute',
                    textTransform: 'uppercase',
                    top: '350px'
                }}
            >
                {userInfo.firstName}
            </Box>

            <Box
                css={{
                    color: isSaison ? '#0c2139' : 'white',
                    fontSize: '22px',
                    left: '37px',
                    position: 'absolute',
                    textTransform: 'uppercase',
                    top: '375px'
                }}
            >
                {userInfo.lastName}
            </Box>

            <Box
                css={{
                    color: isSaison ? '#0c2139' : 'white',
                    fontSize: '18px',
                    left: '41px',
                    position: 'absolute',
                    top: '415px'
                }}
            >
                {userInfo.fields?.cf18993?.value || ''}
            </Box>

            {tribunesAccred && (
                <Flex
                    align="center"
                    css={{
                        gap: '11px',
                        left: '37px',
                        position: 'absolute',
                        top: '455px'
                    }}
                >
                    <Flex
                        align="center"
                        css={{
                            background: tribunesAccred?.color,
                            color: 'white',
                            fontSize: '18px'
                        }}
                        height={22}
                        justify="center"
                        width={38}
                    >
                        1
                    </Flex>

                    <Box css={{ color: isSaison ? '#0c2139' : 'white' }}>Tribune</Box>
                </Flex>
            )}

            {vestiairesAccred && (
                <Flex
                    align="center"
                    css={{
                        gap: '11px',
                        left: '37px',
                        position: 'absolute',
                        top: '484px'
                    }}
                >
                    <Flex
                        align="center"
                        css={{
                            background: vestiairesAccred?.color,
                            color: 'white',
                            fontSize: '18px'
                        }}
                        height={22}
                        justify="center"
                        width={38}
                    >
                        2
                    </Flex>

                    <Box css={{ color: isSaison ? '#0c2139' : 'white' }}>Vestiaires</Box>
                </Flex>
            )}

            {presseAccred && (
                <Flex
                    align="center"
                    css={{
                        gap: '11px',
                        left: '37px',
                        position: 'absolute',
                        top: '513px'
                    }}
                >
                    <Flex
                        align="center"
                        css={{
                            background: presseAccred?.color,
                            color: 'white',
                            fontSize: '18px'
                        }}
                        height={22}
                        justify="center"
                        width={38}
                    >
                        3
                    </Flex>

                    <Box css={{ color: isSaison ? '#0c2139' : 'white' }}>Zone Presse</Box>
                </Flex>
            )}

            {logesAccred && (
                <Flex
                    align="center"
                    css={{
                        gap: '11px',
                        left: '186px',
                        position: 'absolute',
                        top: '455px'
                    }}
                >
                    <Flex
                        align="center"
                        css={{
                            background: logesAccred?.color,
                            color: 'white',
                            fontSize: '18px'
                        }}
                        height={22}
                        justify="center"
                        width={38}
                    >
                        4
                    </Flex>

                    <Box css={{ color: isSaison ? '#0c2139' : 'white' }}>Loges</Box>
                </Flex>
            )}

            {bdlAccred && (
                <Flex
                    align="center"
                    css={{
                        gap: '11px',
                        left: '186px',
                        position: 'absolute',
                        top: '484px'
                    }}
                >
                    <Flex
                        align="center"
                        css={{
                            background: bdlAccred?.color,
                            color: 'white',
                            fontSize: '18px'
                        }}
                        height={22}
                        justify="center"
                        width={38}
                    >
                        5
                    </Flex>

                    <Box css={{ color: isSaison ? '#0c2139' : 'white' }}>BDL immersion</Box>
                </Flex>
            )}

            {securiteAccred && (
                <Flex
                    align="center"
                    css={{
                        gap: '11px',
                        left: '186px',
                        position: 'absolute',
                        top: '513px'
                    }}
                >
                    <Flex
                        align="center"
                        css={{
                            background: securiteAccred?.color,
                            color: 'white',
                            fontSize: '18px'
                        }}
                        height={22}
                        justify="center"
                        width={38}
                    >
                        6
                    </Flex>

                    <Box css={{ color: isSaison ? '#0c2139' : 'white' }}>PC Sécurité</Box>
                </Flex>
            )}

            {isNonEmptyString(vr.utid) && (
                <Box
                    css={{
                        background: 'white',
                        left: '283px',
                        padding: '2px',
                        position: 'absolute',
                        top: '359px'
                    }}
                    height={82}
                    width={82}
                >
                    <img
                        height="100%"
                        src={`https://api.${
                            CommonEnvVars.HEAVENT_EXTERNAL_DOMAIN
                        }/barcode/qr?text=${encodeURIComponent(vr.utid)}&color=000000`}
                        width="100%"
                    />
                </Box>
            )}
        </Flex>
    );
};
