import { Avatar } from 'common/src/components/avatar/avatar';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { VolunteerRegistrationWaitingAssignmentFragment } from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { LocaleFormats } from 'common/src/util/luxon';
import * as React from 'react';

interface IAccreditAssignLeftPanelMemberProps {
    displayBorder: boolean;
    isSelected: boolean;
    vr: VolunteerRegistrationWaitingAssignmentFragment;

    onClick(): void;
}

export const AccreditAssignLeftPanelMember = (props: IAccreditAssignLeftPanelMemberProps) => {
    const translate = useTranslate();
    const dateTimeService = useService(DateTimeService);

    return (
        <Flex
            align="center"
            css={{
                background: props.isSelected ? '$primary100' : 'white',
                borderBottom: props.displayBorder ? '1px solid $gray200' : 'none',
                cursor: 'pointer',
                padding: '$4',
                '&:hover': {
                    background: '$primary100'
                }
            }}
            gap="3"
            onClick={props.onClick}
        >
            <Avatar
                email={props.vr.userInfo.email}
                image={props.vr.userInfo.picture?.url}
                name={props.vr.userInfo.name}
                size={40}
            />

            <Flex css={{ flex: '1' }} direction="column" gap="1">
                <Box font="gray800 textSm semiBold">{props.vr.userInfo.name}</Box>
                <Box>
                    {translate(
                        'inscrit_le_1_12018',
                        dateTimeService.toLocaleString(
                            props.vr.insertedAt,
                            LocaleFormats.DateOnly.MonthLong
                        )
                    )}
                </Box>
            </Flex>
        </Flex>
    );
};
