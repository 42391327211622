import { RadioRow } from 'common-front/src/designSystem/form/radioRow';
import { Box } from 'common/src/designSystem/components/box';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { MassAssignStrategy } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';

export const MassConditionsStep1 = () => {
    const translate = useTranslate();

    return (
        <>
            <Box color="gray900" fontWeight="medium">
                {translate('que_souhaitez_v_87607')}
            </Box>

            <Spacer height="3" />

            <RadioRow
                label={translate('ajouter_des_con_91773')}
                name="massConditions.strategy"
                value={MassAssignStrategy.Add}
            />

            <Spacer height="3" />

            <RadioRow
                label={translate('remplacer_les_c_29446')}
                name="massConditions.strategy"
                value={MassAssignStrategy.Replace}
            />
        </>
    );
};
