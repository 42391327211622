import { Blank } from 'common-front/src/components/blank/blank';
import { Select } from 'common-front/src/designSystem/components/select/select';
import { TableFilters } from 'common-front/src/designSystem/components/tableFilters';
import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { useEnumToOptions } from 'common-front/src/hooks/useEnumToOptions';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useStateDebounce } from 'common-front/src/hooks/useStateDebounce';
import { Svgs } from 'common-front/src/util/assets';
import { Box } from 'common/src/designSystem/components/box';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { HeaderCellSort } from 'common/src/designSystem/components/table/headerCellSort';
import { Row } from 'common/src/designSystem/components/table/row';
import {
    ALL_CAMPAIGNTYPE,
    CampaignsSortAttributes,
    CampaignType
} from 'common/src/generated/types';
import { isNonEmptyArray } from 'common/src/util/array';
import { isNonEmptyString } from 'common/src/util/string';
import { Sort } from 'common/src/vo/sort';
import * as React from 'react';
import { useUserOverlayCampaignsQuery } from '../../../generated/graphqlHooks';
import { UserOverlayCampaignRow } from './userOverlayCampaignRow';

export const UserOverlayCampaigns = () => {
    const {
        params: { organizationId, eventId, userInfoId },
        translate
    } = useHeavent();
    const enumToOptions = useEnumToOptions();
    const [offset, setOffset] = React.useState(0);
    const clearOffset = React.useCallback(() => {
        setOffset(0);
    }, [setOffset]);
    const [name, nameDebounced, _setName] = useStateDebounce('');
    const setName = React.useCallback(
        (newName: string) => {
            _setName(newName);
            clearOffset();
        },
        [_setName, clearOffset]
    );
    const [campaignType, setCampaignType] = React.useState<'' | CampaignType>('');
    const [sort, setSort] = React.useState<Sort<CampaignsSortAttributes> | null>(null);
    const { data, loader } = useUserOverlayCampaignsQuery({
        organizationId,
        userInfoId,
        name: isNonEmptyString(nameDebounced) ? nameDebounced : undefined,
        campaignType: campaignType !== '' ? campaignType : undefined,
        eventId,
        offset,
        sort
    });

    return (
        <>
            <Spacer height="6" />

            <TableFilters
                filters={
                    <>
                        <Box width={200}>
                            <Select
                                value={campaignType}
                                onChange={(newCampaignType: '' | CampaignType) => {
                                    setCampaignType(newCampaignType);
                                    clearOffset();
                                }}
                            >
                                <option value="">{translate('sms_et_e_mail_94587')}</option>

                                {enumToOptions(ALL_CAMPAIGNTYPE)}
                            </Select>
                        </Box>

                        <Box width={320}>
                            <TextInput
                                icon="magnifying-glass"
                                placeholder={translate('rechercher_une_69554')}
                                value={name}
                                onChange={(newName: string) => {
                                    setName(newName);
                                }}
                            />
                        </Box>
                    </>
                }
                headerCells={
                    <>
                        <HeaderCellSort
                            attribute={CampaignsSortAttributes.Name}
                            setSort={setSort}
                            sort={sort}
                        >
                            {translate('nom_de_la_campa_45063')}
                        </HeaderCellSort>
                        <HeaderCellSort
                            attribute={CampaignsSortAttributes.Type}
                            setSort={setSort}
                            sort={sort}
                            width={150}
                        >
                            {translate('type_35427')}
                        </HeaderCellSort>
                        <HeaderCell width={200}>{translate('status_06428')}</HeaderCell>
                        <HeaderCell>{translate('_v_nement_22345')}</HeaderCell>
                    </>
                }
                numberOfPages={data.organization?.userInfo.campaigns.numberOfPages ?? 0}
                offset={offset}
                rows={
                    loader ? (
                        loader
                    ) : isNonEmptyArray(data.organization.userInfo.campaigns.nodes) ? (
                        <>
                            {data.organization.userInfo.campaigns.nodes.map((campaign) => (
                                <UserOverlayCampaignRow key={campaign.id} campaign={campaign} />
                            ))}
                        </>
                    ) : (
                        <Row css={{ height: '200px' }}>
                            <Cell>
                                <Blank
                                    imageSrc={Svgs.FormsElementsBlank}
                                    title={translate('no_campaigns')}
                                />
                            </Cell>
                        </Row>
                    )
                }
                setOffset={setOffset}
                title={translate('liste_des_campa_01089')}
                totalCount={data.organization?.userInfo.campaigns.totalCount ?? 0}
            />

            <Spacer height="8" />
        </>
    );
};
