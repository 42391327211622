import * as React from 'react';
import { Box } from '../../designSystem/components/box';
import { Flex } from '../../designSystem/components/flex';
import {
    DocumentUsersListingFragment,
    VolunteersListingDocumentOptions
} from '../../generated/types';
import { useTranslate } from '../../util/dependencies/dependencies';
import { isNonEmptyString } from '../../util/string';

interface IUsersListingHeaderProps {
    documentUsersListing: DocumentUsersListingFragment;
    options: VolunteersListingDocumentOptions;
    totalCount: number;
}

export const UsersListingHeader = (props: IUsersListingHeaderProps) => {
    const translate = useTranslate();

    return isNonEmptyString(props.options.header.content) ? (
        <Box css={{ px: '56px' }} height={210} width={1}>
            <Box
                css={{
                    borderBottomLeftRadius: '$1',
                    borderBottomRightRadius: '$1',
                    overflow: 'hidden'
                }}
                height={1}
                width={1}
            >
                <img height="100%" src={props.options.header.content} width="100%" />
            </Box>
        </Box>
    ) : (
        <Flex
            css={{
                background: '$gray100',
                padding: '$7 56px'
            }}
            direction="column"
        >
            <Box color="gray900" fontSize="displayXs" fontWeight="medium">
                {props.documentUsersListing.event.segment.name}
            </Box>

            <Box color="gray500">
                {translate('liste_des_membr_89037')} ({props.totalCount})
            </Box>
        </Flex>
    );
};
