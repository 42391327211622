import { groupBy, omit, sortBy } from 'lodash-es';
import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { DateTimeService } from '../../../services/dateTimeService';
import { IntervalService } from '../../../services/intervalService';
import { useService } from '../../../util/dependencies/dependencies';
import { LocaleFormats } from '../../../util/luxon';
import { PastourelleAccreditations } from './pastourelleAccreditations';

interface IBadgePastourelleA6FrontProps {
    event: DocumentUserCustomBadgeFragment;
}

export const BadgePastourelleFront = (props: IBadgePastourelleA6FrontProps) => {
    const dateTimeService = useService(DateTimeService);
    const intervalService = useService(IntervalService);
    const userInfo = props.event.volunteerRegistration.userInfo;
    const assignmentss = sortBy(
        Object.values(
            groupBy(props.event.volunteerRegistration.positionsSlotsUsersInfos, (psui) =>
                psui.positionSlot.range.start!.startOf('day').toISO()
            )
        ),
        (a) => a[0].positionSlot.range.start!.startOf('day').toMillis()
    );
    const dateFormat = omit(LocaleFormats.DateOnly.WeekdayLongMonthLong, 'year');
    const statut = userInfo.fields?.cf16442?.value ?? '';

    return (
        <Flex
            css={{
                background: `url(https://assets.recrewteer.com/badges/pastourelle2024/Badge_orga-bene_recto.png) no-repeat`,
                backgroundSize: 'cover',
                overflow: 'hidden',
                position: 'relative'
            }}
            direction="column"
            height={597}
            width={434}
        >
            <Box
                color="gray500"
                css={{
                    fontSize: '12px',
                    fontWeight: '500',
                    position: 'absolute',
                    top: '248px'
                }}
                textAlign="center"
                width={1}
            >
                {userInfo.name}
            </Box>

            <Box
                css={{
                    position: 'absolute',
                    textTransform: 'uppercase',
                    top: '268px'
                }}
                font="gray800 displayXs bold"
                textAlign="center"
                width={1}
            >
                {statut || 'Bénévole'}
            </Box>

            <Flex
                css={{
                    position: 'absolute',
                    px: '60px',
                    top: '312px'
                }}
                direction="column"
                gap="2"
            >
                {assignmentss.map((assignments, index) => (
                    <Flex key={index} direction="column">
                        <Box
                            color="gray800"
                            css={{ fontSize: '10px', textTransform: 'capitalize' }}
                        >
                            {dateTimeService.toLocaleString(
                                assignments[0].positionSlot.range.start!,
                                dateFormat
                            )}
                        </Box>

                        {assignments.map((assignment, assignmentIndex) => (
                            <Box
                                key={assignmentIndex}
                                color="gray800"
                                css={{ fontSize: '10px' }}
                                fontWeight="semiBold"
                            >
                                {assignment.position.name} :{' '}
                                {intervalService.toLocaleString(
                                    assignment.positionSlot.range,
                                    { locale: 'fr-FR' },
                                    {
                                        hour: 'numeric',
                                        minute: 'numeric'
                                    }
                                )}
                            </Box>
                        ))}
                    </Flex>
                ))}
            </Flex>

            <PastourelleAccreditations event={props.event} />
        </Flex>
    );
};
