import { Button } from 'common-front/src/designSystem/components/button';
import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { FormId } from 'common/src/generated/types';
import * as React from 'react';
import { CreateFormModal } from '../create/createFormModal';

interface IFormsHeaderProps {
    canCreateNewForm: boolean;
    isSearchVisible: boolean;
    name: string;

    getFormPath(formId: FormId): string;
    setName(name: string): void;
}

export const FormsHeader = (props: IFormsHeaderProps) => {
    const {
        history,
        translate,
        params: { organizationId, eventId }
    } = useHeavent();
    const [isCreateOpen, setIsCreateOpen] = React.useState(false);

    return (
        <>
            <Flex gap="4" width={1}>
                <Box css={{ flex: '1' }} font="gray900 displayXs semiBold">
                    {translate('Form_plural')}
                </Box>

                {props.isSearchVisible && (
                    <Box width={320}>
                        <TextInput
                            icon="magnifying-glass"
                            placeholder={translate('rechercher_un_f_28561')}
                            value={props.name}
                            onChange={props.setName}
                        />
                    </Box>
                )}

                {props.canCreateNewForm && (
                    <Button
                        onClick={() => {
                            setIsCreateOpen(true);
                        }}
                    >
                        {translate('cr_er_un_formul_78352')}
                    </Button>
                )}
            </Flex>

            {isCreateOpen && (
                <CreateFormModal
                    eventId={eventId}
                    organizationId={organizationId}
                    onClose={() => {
                        setIsCreateOpen(false);
                    }}
                    onSuccess={(formId) => {
                        history.push(props.getFormPath(formId));
                    }}
                />
            )}
        </>
    );
};
