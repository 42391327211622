import { Avatar } from 'common/src/components/avatar/avatar';
import { AvatarWithState } from 'common/src/components/avatarWithState/avatarWithState';
import { Flex } from 'common/src/designSystem/components/flex';
import {
    CheckInState,
    Document,
    UsersInfo,
    VolunteerRegistrationState
} from 'common/src/generated/types';
import { Emptyable } from 'common/src/util/emptyable';
import * as React from 'react';
import { VolunteerEmailAndNameMobile } from '../volunteerEmailAndNameMobile/volunteerEmailAndNameMobile';

interface IMainProps {
    openVolunteer: () => void;
    userInfo: Pick<UsersInfo, 'id' | 'name' | 'email'> & {
        picture?: Emptyable<Pick<Document, 'url'>>;
    };
}

interface IRegistrationState {
    registrationState: VolunteerRegistrationState;
}

interface ICheckInState {
    checkInState: CheckInState;
}

type IMainPropsWithState = (IRegistrationState | ICheckInState) & IMainProps;

type IVolunteerRowMobileProps = IMainProps | IMainPropsWithState;

export const VolunteerRowMobile = (props: IVolunteerRowMobileProps) => {
    const avatarProps = {
        name: props.userInfo.name,
        email: props.userInfo.email,
        image: props.userInfo.picture?.url
    };

    return (
        <Flex align="center" onClick={props.openVolunteer}>
            {'registrationState' in props ? (
                <AvatarWithState
                    {...avatarProps}
                    registrationState={props.registrationState}
                    size={40}
                />
            ) : 'checkInState' in props ? (
                <AvatarWithState {...avatarProps} checkInState={props.checkInState} size={40} />
            ) : (
                <Avatar {...avatarProps} size={40} />
            )}
            <VolunteerEmailAndNameMobile
                css={{ marginLeft: '$3' }}
                size={40}
                userInfo={props.userInfo}
            />
        </Flex>
    );
};
