import { MobileOverlay } from 'common-front/src/components/mobileOverlay/mobileOverlay';
import { Button } from 'common-front/src/designSystem/components/button';
import { Flex } from 'common/src/designSystem/components/flex';
import { useHistory, useParams, useTranslate } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';

export const PositionSlotCheckInSheetMobile = () => {
    const { organizationId, eventId, positionId, positionSlotId } = useParams();
    const history = useHistory();
    const onClose = () => {
        history.push(
            HeaventPaths.POSITION_SLOT_MEMBERS(organizationId, eventId, positionId, positionSlotId)
        );
    };
    const translate = useTranslate();

    return (
        <MobileOverlay onClose={onClose}>
            <Flex css={{ margin: '$3' }} direction="column" gap="3">
                <Button
                    onClick={() => {
                        history.push(
                            HeaventPaths.POSITION_SLOT_CHECK_MEMBERS_CHECK_IN(
                                organizationId,
                                eventId,
                                positionId,
                                positionSlotId
                            )
                        );
                    }}
                >
                    {translate('pointer_des_mem_09244')}
                </Button>
                <Button
                    onClick={() => {
                        history.push(
                            HeaventPaths.POSITION_SLOT_CHECK_MEMBERS_CHECK_OUT(
                                organizationId,
                                eventId,
                                positionId,
                                positionSlotId
                            )
                        );
                    }}
                >
                    {translate('d_pointer_des_m_31557')}
                </Button>
                <Button
                    onClick={() => {
                        history.push(
                            HeaventPaths.POSITION_SLOT_CHECK_MEMBERS_RESET(
                                organizationId,
                                eventId,
                                positionId,
                                positionSlotId
                            )
                        );
                    }}
                >
                    {translate('remettre_z_ro_65589')}
                </Button>
            </Flex>
        </MobileOverlay>
    );
};
