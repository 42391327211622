import { LeaderAssignmentFragment } from '../generated/types';
import { isNonEmptyString } from '../util/string';

export function leaderInfos(leader: LeaderAssignmentFragment, leadersData: string): string {
    const infos: string[] = [];

    if (leadersData === 'both' || leadersData === 'email') {
        infos.push(leader.userInfo.email);
    }

    if (isNonEmptyString(leader.userInfo.name)) {
        infos.unshift(leader.userInfo.name);
    }

    if (leader.userInfo.phone && (leadersData === 'both' || leadersData === 'phone')) {
        infos.push(
            `<a href="tel:${leader.userInfo.phone.internationalFormat}">${leader.userInfo.phone.internationalFormat}</a>`
        );
    }

    return infos.join('<br />');
}
