import { Button } from 'common-front/src/designSystem/components/button';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I, IIcon } from 'common/src/designSystem/components/i';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';

interface IUserProfileActionProps {
    children: React.ReactNode;
    color: 'error' | 'indigo' | 'purple' | 'success';
    icon: IIcon;
    isLoading?: boolean;
    path?: string;

    onClick?(): void;
}

export const UserProfileAction = (props: IUserProfileActionProps) => {
    const { history } = useHeavent();

    return (
        <Flex
            align="center"
            css={{
                background: 'white',
                cursor: 'pointer',
                padding: '$4 $6'
            }}
            gap="4"
            onClick={() => {
                if (!props.isLoading) {
                    if (isNonEmptyString(props.path)) {
                        history.push(props.path);
                    } else if (props.onClick) {
                        props.onClick();
                    }
                }
            }}
        >
            <Flex
                align="center"
                css={{
                    background: `$${props.color}100`,
                    borderRadius: '12px',
                    color: `$${props.color}700`,
                    fontSize: '$textMd',
                    height: '40px',
                    width: '40px'
                }}
                justify="center"
            >
                <I icon={props.icon} />
            </Flex>

            <Box css={{ flex: '1' }} font="gray800 textSm semiBold">
                {props.children}
            </Box>

            <Button color="white" isLoading={props.isLoading} leftIcon="chevron-right" size="sm" />
        </Flex>
    );
};
