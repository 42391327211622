import { Box } from 'common/src/designSystem/components/box';
import * as React from 'react';

interface ICampaignKpiProps {
    title: string;
    content: string | number;
}

export const CampaignKpi = (props: ICampaignKpiProps) => (
    <Box
        css={{
            border: '1px solid $gray200',
            borderRadius: '$2',
            boxShadow: '$xs',
            padding: '$6',
            width: '100%'
        }}
    >
        <Box color="gray500" fontWeight="medium">
            {props.title}
        </Box>

        <Box color="gray900" fontSize="displayMd" fontWeight="semiBold">
            {props.content}
        </Box>
    </Box>
);
