import { Content } from 'common-front/src/designSystem/components/tooltip/content';
import { Tooltip } from 'common-front/src/designSystem/components/tooltip/tooltip';
import { Trigger } from 'common-front/src/designSystem/components/tooltip/trigger';
import { copy } from 'common-front/src/util/commands';
import { Avatar } from 'common/src/components/avatar/avatar';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { theme } from 'common/src/designSystem/components/stitches';
import {
    EventId,
    OrganizationId,
    UserInfoOverlayFragment,
    VolunteerRegistrationState
} from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { Emptyable } from 'common/src/util/emptyable';
import { isNonEmptyString } from 'common/src/util/string';
import { getStateBadgeColorIcon } from 'common/src/vo/volunteerRegistration';
import * as React from 'react';
import { UseroverlayEditEmailModal } from './useroverlayEditEmailModal';

interface IUserOverlayHeaderProps {
    canEditEmail: boolean;
    eventId: Emptyable<EventId>;
    organizationId: OrganizationId;
    state?: VolunteerRegistrationState;
    userInfo: UserInfoOverlayFragment;

    reload(): void;
}

export const UserOverlayHeader = (props: IUserOverlayHeaderProps) => {
    const translate = useTranslate();
    const colorIcon = React.useMemo(() => {
        if (props.state) {
            return getStateBadgeColorIcon(props.state);
        } else {
            return null;
        }
    }, [props.state]);
    const [isCopyEmailLoading, setIsCopyEmailLoading] = React.useState(false);
    const [isEditEmailOpen, setIsEditEmailOpen] = React.useState(false);
    const copyEmailClick = React.useCallback(() => {
        setIsCopyEmailLoading(true);
        copy(props.userInfo.email);

        setTimeout(() => {
            setIsCopyEmailLoading(false);
        }, 500);
    }, [props.userInfo.email, setIsCopyEmailLoading]);
    const [isCopyPhoneLoading, setIsCopyPhoneLoading] = React.useState(false);
    const copyPhoneClick = React.useCallback(() => {
        setIsCopyPhoneLoading(true);
        copy(props.userInfo.phone?.internationalFormat);

        setTimeout(() => {
            setIsCopyPhoneLoading(false);
        }, 500);
    }, [props.userInfo.phone, setIsCopyPhoneLoading]);

    return (
        <>
            <Flex align="center" direction="column" width={1}>
                <Box css={{ position: 'relative' }}>
                    <Avatar
                        email={props.userInfo.email}
                        image={props.userInfo.picture?.url}
                        name={props.userInfo.name}
                        size={96}
                    />

                    {colorIcon && (
                        <Flex
                            align="center"
                            css={{
                                background: theme.colors[`${colorIcon.color}100`].value,
                                border: '2px solid white',
                                borderRadius: '24px',
                                bottom: '0',
                                color: theme.colors[`${colorIcon.color}700`].value,
                                fontSize: '11px',
                                position: 'absolute',
                                right: '0'
                            }}
                            height={24}
                            justify="center"
                            width={24}
                        >
                            <I icon={colorIcon.leftIcon} />
                        </Flex>
                    )}
                </Box>

                <Spacer height="6" />

                <Box font="gray900 displayXs semiBold">
                    {isNonEmptyString(props.userInfo.name) ? props.userInfo.name : '-'}
                </Box>

                <Spacer height="1" />

                <Flex
                    align="center"
                    css={{
                        color: '$gray500',
                        cursor: 'pointer',
                        '&:hover': {
                            color: '$primary700'
                        },
                        '& .h-hidden i': {
                            visibility: 'hidden'
                        },
                        '&:hover .h-hidden i': {
                            visibility: 'visible'
                        }
                    }}
                    gap="3"
                >
                    <Box width={props.canEditEmail ? 40 : 14} />

                    <Box width={14}>
                        <I icon="at" />
                    </Box>

                    <Box onClick={copyEmailClick}>{props.userInfo.email}</Box>

                    <Tooltip>
                        <Trigger>
                            <Box className="h-hidden" onClick={copyEmailClick}>
                                <I icon={isCopyEmailLoading ? 'loader' : 'copy'} />
                            </Box>
                        </Trigger>

                        <Content placement="top">{translate('copier_l_adress_05303')}</Content>
                    </Tooltip>

                    {props.canEditEmail && (
                        <Tooltip>
                            <Trigger>
                                <Box
                                    className="h-hidden"
                                    onClick={() => {
                                        setIsEditEmailOpen(true);
                                    }}
                                >
                                    <I icon="pen" />
                                </Box>
                            </Trigger>

                            <Content placement="top">{translate('modifier_l_adre_94026')}</Content>
                        </Tooltip>
                    )}
                </Flex>

                <Spacer height="1" />

                <Flex
                    align="center"
                    css={{
                        color: '$gray500',
                        cursor: 'pointer',
                        '&:hover': {
                            color: '$primary700'
                        },
                        '& .h-hidden i': {
                            visibility: 'hidden'
                        },
                        '&:hover .h-hidden i': {
                            visibility: 'visible'
                        }
                    }}
                    gap="3"
                >
                    <Box width={14} />

                    <Box width={14}>
                        <I icon="phone" />
                    </Box>

                    <Box onClick={copyPhoneClick}>
                        {props.userInfo.phone?.internationalFormat ?? '-'}
                    </Box>

                    <Tooltip>
                        <Trigger>
                            <Box className="h-hidden" onClick={copyPhoneClick}>
                                <I icon={isCopyPhoneLoading ? 'loader' : 'copy'} />
                            </Box>
                        </Trigger>

                        <Content placement="top">{translate('copier_le_num_r_77931')}</Content>
                    </Tooltip>
                </Flex>
            </Flex>

            {props.canEditEmail && isEditEmailOpen && (
                <UseroverlayEditEmailModal
                    email={props.userInfo.email}
                    eventId={props.eventId}
                    organizationId={props.organizationId}
                    userInfoId={props.userInfo.id}
                    onClose={() => {
                        setIsEditEmailOpen(false);
                    }}
                    onSuccess={props.reload}
                />
            )}
        </>
    );
};
