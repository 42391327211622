import { RichSelect } from 'common-front/src/designSystem/form/richSelect';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { getToken } from 'common-front/src/util/aws/cognito';
import { Box } from 'common/src/designSystem/components/box';
import { Skeleton } from 'common/src/designSystem/components/skeleton';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { CampaignEventAccreditationsRecipientsQuery } from 'common/src/generated/types';
import { CampaignRecipients } from 'common/src/input/campaignInput';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { isNonEmptyArray } from 'common/src/util/array';
import { useService } from 'common/src/util/dependencies/dependencies';
import { LocaleFormats } from 'common/src/util/luxon';
import { groupBy, sortBy } from 'lodash-es';
import * as React from 'react';
import { executeCampaignEventAccreditationsRecipientsQuery } from '../../../generated/graphqlHooks';

interface ICampaignEventAccreditationsRecipientsProps {
    recipients: CampaignRecipients;
}

export const CampaignEventAccreditationsRecipients = (
    props: ICampaignEventAccreditationsRecipientsProps
) => {
    const {
        translate,
        params: { eventId }
    } = useHeavent();
    const dateTimeService = useService(DateTimeService);
    const [isFirstTime, setIsFirstTime] = React.useState(true);
    const [isLoading, setIsLoading] = React.useState(true);
    const [categories, setCategories] = React.useState<
        CampaignEventAccreditationsRecipientsQuery['event']['accreditationsCategories']
    >([]);
    const [accreditationsGrouped, setAccreditationsGrouped] = React.useState<
        Array<[string, any[]]>
    >([]);
    const [hasSlots, setHasSlots] = React.useState(false);
    const [slotsGrouped, setSlotsGrouped] = React.useState<Array<[string, any[]]>>([]);

    React.useEffect(() => {
        (async () => {
            if (props.recipients === 'accreditations' && isFirstTime) {
                const data = await executeCampaignEventAccreditationsRecipientsQuery(
                    { eventId },
                    await getToken()
                );

                setCategories(sortBy(data.event.accreditationsCategories, (c) => c.name));
                setAccreditationsGrouped(
                    Object.entries(
                        groupBy(data.event.accreditations.nodes, (a) => a.accreditationCategoryName)
                    )
                );
                setHasSlots(
                    isNonEmptyArray(data.event.accreditations.nodes.flatMap((a) => a.slots))
                );
                setSlotsGrouped(
                    data.event.accreditations.nodes.map((accreditation) => {
                        const slots = accreditation.slots.map((slot) => {
                            const date = slot.date
                                ? dateTimeService.toLocaleString(
                                      slot.date,
                                      LocaleFormats.DateOnly.MonthLong
                                  )
                                : '';
                            const name = slot.date
                                ? `${slot.name} - ${date} - ${accreditation.name}`
                                : `${slot.name} - ${accreditation.name}`;

                            return {
                                id: slot.id,
                                name
                            };
                        });

                        return [accreditation.name, slots];
                    })
                );
                setIsFirstTime(false);
                setIsLoading(false);
            }
        })();
    }, [props.recipients, isFirstTime]);

    if (props.recipients === CampaignRecipients.Accreditations) {
        return (
            <>
                <Spacer height="2" />

                <Box css={{ marginLeft: '$2' }}>
                    {isLoading ? (
                        <>
                            <Skeleton borderRadius="$1" height={40} width={1} />
                            <Spacer height="2" />
                            <Skeleton borderRadius="$1" height={40} width={1} />
                            <Spacer height="2" />
                            <Skeleton borderRadius="$1" height={40} width={1} />
                        </>
                    ) : (
                        <>
                            <RichSelect
                                isSearchVisible={true}
                                multiple={true}
                                name="campaign.accreditationsCategoriesIds"
                                placeholder={translate('ajouter_des_cat_17200')}
                            >
                                {categories.map(({ id, name }) => (
                                    <option key={id} value={id}>
                                        {name}
                                    </option>
                                ))}
                            </RichSelect>

                            <Spacer height="2" />

                            <RichSelect
                                isSearchVisible={true}
                                multiple={true}
                                name="campaign.accreditationsIds"
                                placeholder={translate('ajouter_des_acc_26514')}
                            >
                                {accreditationsGrouped.map(
                                    ([categoryName, accreditations], index) => (
                                        <optgroup key={index} label={categoryName}>
                                            {accreditations.map((accreditation) => (
                                                <option
                                                    key={accreditation.id}
                                                    value={accreditation.id}
                                                >
                                                    {accreditation.name}
                                                </option>
                                            ))}
                                        </optgroup>
                                    )
                                )}
                            </RichSelect>

                            {hasSlots && (
                                <>
                                    <Spacer height="2" />

                                    <RichSelect
                                        isSearchVisible={true}
                                        multiple={true}
                                        name="campaign.accreditationsSlotsIds"
                                        placeholder={translate('ajouter_des_cr_25566')}
                                    >
                                        {slotsGrouped.map(([accreditationName, slots], index) => (
                                            <optgroup key={index} label={accreditationName}>
                                                {slots.map((slot) => (
                                                    <option key={slot.id} value={slot.id}>
                                                        {slot.name}
                                                    </option>
                                                ))}
                                            </optgroup>
                                        ))}
                                    </RichSelect>
                                </>
                            )}
                        </>
                    )}
                </Box>
            </>
        );
    } else {
        return null;
    }
};
