import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { styled } from 'common/src/designSystem/components/stitches';
import { CommonEnvVars } from 'common/src/envVars';
import { HeaventEnv } from 'common/src/heaventEnv';
import { assertUnreachable } from 'common/src/util/assertUnreachable';
import * as React from 'react';
import LogoWhite from 'svgComponents/logo/logo.svg';
import { Button } from '../../designSystem/components/button';
import { Images } from '../../util/assets';

interface IOnboardingViewProps {
    children: React.ReactNode;

    onClose(): void;
}

const _OnboardingView = styled(Flex, {
    backgroundColor: '$blueGray900',
    [`.amicale-theme &, .volunteers-theme &`]: {
        backgroundColor: 'white'
    },
    [`.mso-theme &`]: {
        backgroundColor: '$cyan'
    }
});

const Logo = () => {
    switch (CommonEnvVars.HEAVENT_ENV) {
        case HeaventEnv.Amicale:
            return <img alt="Logo" height="32px" src={Images.AmicaleLogo} />;
        case HeaventEnv.Mso:
            return <img alt="Logo" height="32px" src={Images.MsoLogo1} />;
        case HeaventEnv.Recrewteer:
            return <LogoWhite />;
        default:
            return assertUnreachable(CommonEnvVars.HEAVENT_ENV);
    }
};

export const OnboardingView = React.forwardRef<HTMLDivElement, IOnboardingViewProps>(
    (props, ref) => (
        <_OnboardingView
            ref={ref}
            align="stretch"
            css={{ overflow: 'auto', padding: '$6' }}
            justify="between"
            width={1}
        >
            <Box width={100} />

            <Flex
                align="stretch"
                css={{
                    height: 'fit-content',
                    py: '$2',
                    width: '920px'
                }}
                direction="column"
                gap="6"
            >
                <Flex
                    css={{
                        marginBottom: '$7',
                        '& svg': {
                            height: '100%'
                        }
                    }}
                    height={32}
                    justify="center"
                >
                    <Logo />
                </Flex>

                {props.children}
            </Flex>

            <Flex justify="end" width={100}>
                <Button color="gray" leftIcon="xmark" onClick={props.onClose} />
            </Flex>
        </_OnboardingView>
    )
);
OnboardingView.displayName = 'OnboardingView';
