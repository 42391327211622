import { RichSelect } from 'common-front/src/designSystem/components/richSelect/richSelect';
import { Select } from 'common-front/src/designSystem/components/select/select';
import { useCustomFieldOptions } from 'common-front/src/hooks/useCustomFieldOptions';
import {
    FieldType,
    OrganizationId,
    SegmentCustomFieldFragment,
    UserInfoCommunityFragment
} from 'common/src/generated/types';
import { isNonEmptyArray } from 'common/src/util/array';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { FieldService } from 'common/src/vo/field';
import { PossibleColumn } from 'common/src/vo/segment';
import * as React from 'react';
import { UserInfoUpdateCell } from '../../../volunteers/list/list/cells/volunteerRegistrationUpdateCell';

interface ICommunityUserSelectCellProps {
    customField: SegmentCustomFieldFragment;
    field: PossibleColumn;
    organizationId: OrganizationId;
    userInfo: UserInfoCommunityFragment;

    reload(): void;
}

export const CommunityUserSelectCell = (props: ICommunityUserSelectCellProps) => {
    const translate = useTranslate();
    const fieldService = useService(FieldService);
    const valueString = fieldService.getValueString(props.customField, props.userInfo);
    const initialValue = React.useMemo(() => {
        const value = fieldService.getValue<any>(props.customField, props.userInfo);

        if (props.customField.canSelectMultiple) {
            return isNonEmptyArray(value) ? value : [];
        } else {
            return value || '';
        }
    }, []);
    const options = useCustomFieldOptions(props.customField);

    return (
        <UserInfoUpdateCell
            customField={props.customField}
            field={props.field}
            initialValue={initialValue}
            organizationId={props.organizationId}
            reload={props.reload}
            userInfoId={props.userInfo.id}
            valueString={valueString}
        >
            {(value, setValue) => {
                if (props.customField.canSelectMultiple) {
                    return (
                        <RichSelect
                            label={props.customField.name}
                            multiple={true}
                            renderOnPortal={true}
                            values={value}
                            onChange={setValue}
                        >
                            {options}
                        </RichSelect>
                    );
                } else {
                    return (
                        <Select
                            label={props.customField.name}
                            shouldParseAsBoolean={false}
                            shouldParseAsInt={props.customField.fieldType === FieldType.Select}
                            value={value}
                            onChange={setValue}
                        >
                            <option disabled={true} value="">
                                {translate('choisir_une_val_00277')}
                            </option>

                            {options}
                        </Select>
                    );
                }
            }}
        </UserInfoUpdateCell>
    );
};
