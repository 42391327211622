import { BackofficeInvoicesQuery } from 'common/src/generated/types';
import * as React from 'react';
import { InvoiceRow } from '../../organizationParameters/invoices/invoiceRow';
import { BackofficeInvoicesColumns } from './backofficeInvoicesColumns';
import { MarkAsPaidModal } from './markAsPaidModal';

interface IBackofficeInvoiceRowProps {
    invoice: BackofficeInvoicesQuery['backoffice']['invoices']['nodes'][0];

    reload(): void;
    showColumn(column: BackofficeInvoicesColumns): boolean;
}

export const BackofficeInvoiceRow = (props: IBackofficeInvoiceRowProps) => {
    const [isMarkAsPaidOpen, setMarkAsPaidOpen] = React.useState(false);

    return (
        <>
            <InvoiceRow
                invoice={props.invoice}
                isBackoffice={true}
                markAsPaid={() => {
                    setMarkAsPaidOpen(true);
                }}
                showColumn={props.showColumn}
            />

            {isMarkAsPaidOpen && (
                <MarkAsPaidModal
                    invoiceId={props.invoice.id}
                    reload={props.reload}
                    onClose={() => {
                        setMarkAsPaidOpen(false);
                    }}
                />
            )}
        </>
    );
};
