import { Button } from 'common-front/src/designSystem/components/button';
import { Dropdown } from 'common-front/src/designSystem/components/dropdown/dropdown';
import { ItemIcon } from 'common-front/src/designSystem/components/dropdown/item';
import { Menu } from 'common-front/src/designSystem/components/dropdown/menu';
import { Trigger } from 'common-front/src/designSystem/components/dropdown/trigger';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { PositionCategoryQuery } from 'common/src/generated/types';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import { useEventContext } from '../../events/show/eventContext';
import { usePositionCategoryDuplicateMutation } from '../../generated/graphqlHooks';
import { DeletePositionCategory } from '../../positions/list/deletePositionCategory';
import { UpdatePositionCategory } from '../create/createUpdatePositionCategory';

interface IPositionCategoryHeaderProps {
    category: PositionCategoryQuery['event']['positionCategory'];

    reload(): void;
}

export const PositionCategoryHeader = (props: IPositionCategoryHeaderProps) => {
    const {
        translate,
        history,
        params: { organizationId, eventId }
    } = useHeavent();
    const { isEventAdmin, localEvent, isPositionCategoryAdmin } = useEventContext();
    const { mutate: positionCategoryDuplicate } = usePositionCategoryDuplicateMutation();
    const [isRenameOpen, setIsRenameOpen] = React.useState(false);
    const [isDeleteOpen, setIsDeleteOpen] = React.useState(false);

    return (
        <Flex align="center" gap="3">
            <Box
                color="gray900"
                css={{ cursor: 'pointer' }}
                fontSize="textXl"
                onClick={() => {
                    history.push(
                        localEvent?.positionCategoryBackPath ??
                            HeaventPaths.POSITIONS(organizationId, eventId)
                    );
                }}
            >
                <I icon="arrow-left" />
            </Box>

            <Box color="gray900" css={{ flex: '1' }} fontSize="displayXs" fontWeight="medium">
                {props.category.name}
            </Box>

            {isPositionCategoryAdmin(props.category.id) && (
                <Button
                    onClick={() => {
                        history.push(
                            HeaventPaths.CREATE_POSITION(organizationId, eventId, props.category.id)
                        );
                    }}
                >
                    {translate('ajouter_une_mis_99800')}
                </Button>
            )}

            {isEventAdmin && (
                <Dropdown>
                    <Trigger>
                        <Button color="white" leftIcon="ellipsis-vertical" />
                    </Trigger>

                    <Menu placement="bottom-end">
                        <ItemIcon
                            icon="pen"
                            onClick={() => {
                                setIsRenameOpen(true);
                            }}
                        >
                            {translate('renommer_42135')}
                        </ItemIcon>

                        <ItemIcon
                            icon="copy"
                            onClick={async () => {
                                const {
                                    positionCategoryDuplicate: { id: newPositionCategoyId }
                                } = await positionCategoryDuplicate({
                                    eventId,
                                    positionCategoryId: props.category.id
                                });

                                history.push(
                                    HeaventPaths.POSITION_CATEGORY(
                                        organizationId,
                                        eventId,
                                        newPositionCategoyId
                                    )
                                );
                            }}
                        >
                            {translate('dupliquer_28256')}
                        </ItemIcon>

                        <ItemIcon
                            color="red"
                            icon="trash-can"
                            onClick={() => {
                                setIsDeleteOpen(true);
                            }}
                        >
                            {translate('supprimer_43083')}
                        </ItemIcon>
                    </Menu>
                </Dropdown>
            )}

            {isRenameOpen && (
                <UpdatePositionCategory
                    eventId={eventId}
                    positionCategoryId={props.category.id}
                    positionCategoryName={props.category.name}
                    onClose={() => {
                        setIsRenameOpen(false);
                    }}
                    onSuccess={props.reload}
                />
            )}

            {isDeleteOpen && (
                <DeletePositionCategory
                    eventId={eventId}
                    positionCategory={props.category}
                    onClose={() => {
                        setIsDeleteOpen(false);
                    }}
                    onSuccess={() => {
                        history.push(HeaventPaths.POSITIONS(organizationId, eventId));
                    }}
                />
            )}
        </Flex>
    );
};
