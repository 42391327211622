import { executeTagsQuery } from 'common-front/src/generated/graphqlHooks';
import { getToken } from 'common-front/src/util/aws/cognito';
import {
    EventId,
    OrganizationId,
    PositionsCategoriesQuery,
    TagsQuery
} from 'common/src/generated/types';
import { Emptyable } from 'common/src/util/emptyable';
import * as React from 'react';
import { executePositionsCategoriesQuery } from '../../generated/graphqlHooks';

interface IPoint {
    latitude: number;
    longitude: number;
}

interface IPositionsContext {
    newPositionLatLng?: IPoint;

    getPositionsCategories(): Promise<PositionsCategoriesQuery['event']['positionsCategories']>;
    getTags(): Promise<TagsQuery['organization']['tags']['nodes']>;
    setNewPositionLatLng(point: IPoint): void;
}

const PositionsContext = React.createContext<IPositionsContext>({} as IPositionsContext);

interface IPositionsContextProviderProps {
    children: React.ReactNode;
    eventId: EventId;
    organizationId: OrganizationId;
}

export const PositionsContextProvider = (props: IPositionsContextProviderProps) => {
    const [point, setPoint] = React.useState<IPoint | undefined>();
    const [positionsCategories, setPositionsCategories] =
        React.useState<Emptyable<PositionsCategoriesQuery['event']['positionsCategories']>>(null);
    const [tags, setTags] =
        React.useState<Emptyable<TagsQuery['organization']['tags']['nodes']>>(null);
    const getPositionsCategories = React.useCallback(async () => {
        if (positionsCategories) {
            return positionsCategories;
        } else {
            const { event } = await executePositionsCategoriesQuery(
                { eventId: props.eventId },
                await getToken()
            );

            setPositionsCategories(event.positionsCategories);

            return event.positionsCategories;
        }
    }, [positionsCategories, setPositionsCategories]);
    const getTags = React.useCallback(async () => {
        if (tags) {
            return tags;
        } else {
            const { organization } = await executeTagsQuery(
                { organizationId: props.organizationId },
                await getToken()
            );

            setTags(organization.tags.nodes);

            return organization.tags.nodes;
        }
    }, [tags, setTags]);

    return (
        <PositionsContext.Provider
            value={{
                newPositionLatLng: point,
                getPositionsCategories,
                getTags,
                setNewPositionLatLng: setPoint
            }}
        >
            {props.children}
        </PositionsContext.Provider>
    );
};

export function usePositionsContext() {
    return React.useContext(PositionsContext);
}
