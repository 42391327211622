import { useStateDebounce } from 'common-front/src/hooks/useStateDebounce';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    AccreditationId,
    AccreditationsCategoryId,
    AccreditInfosQuery,
    SegmentId,
    TagId
} from 'common/src/generated/types';
import { isNonEmptyArray } from 'common/src/util/array';
import { useParams } from 'common/src/util/dependencies/dependencies';
import { isNonEmptyString } from 'common/src/util/string';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useAccreditSlotsQuery } from '../../generated/graphqlHooks';
import { useLocalEventState } from '../../hooks/useLocalState';
import { AccreditCategories } from './accreditCategories';
import { AccreditFilters } from './filters/accreditFilters';

interface IAccreditFiltersCategoriesProps {
    event: AccreditInfosQuery['event'];
    organization: AccreditInfosQuery['organization'];
}

export const AccreditFiltersCategories = ({
    event,
    organization
}: IAccreditFiltersCategoriesProps) => {
    const { eventId } = useParams();
    const [name, nameDebounced, setName] = useStateDebounce('');
    const [accreditationsCategoriesIds, setAccreditationsCategoriesIds] = useLocalEventState<
        AccreditationsCategoryId[] | null
    >('accredit.filters.accreditationsCategoriesIds', null);
    const [accreditationsIds, setAccreditationsIds] = useLocalEventState<AccreditationId[] | null>(
        'accredit.filters.accreditationsIds',
        null
    );
    const [date, setDate] = useLocalEventState<DateTime | null>('accredit.filters.date', null);
    const [tagsIds, setTagsIds] = useLocalEventState<TagId[] | null>(
        'accredit.filters.tagsIds',
        null
    );
    const [segmentId, setSegmentId] = useLocalEventState<SegmentId | null>(
        'accredit.filters.segmentId',
        null
    );
    const [excludeFull, setExcludeFull] = useLocalEventState('accredit.filters.excludeFull', false);
    const { data, loader } = useAccreditSlotsQuery({
        eventId,
        name: isNonEmptyString(nameDebounced) ? nameDebounced : undefined,
        accreditationCategoriesIds: isNonEmptyArray(accreditationsCategoriesIds)
            ? accreditationsCategoriesIds
            : undefined,
        accreditationsIds: isNonEmptyArray(accreditationsIds) ? accreditationsIds : undefined,
        date,
        tagsIds: isNonEmptyArray(tagsIds) ? tagsIds : undefined,
        segmentId,
        excludeFull
    });

    return (
        <Flex
            css={{
                background: '$gray50',
                minHeight: '100%',
                padding: '$6 $7'
            }}
            direction="column"
            width={1}
        >
            <AccreditFilters
                accreditationsCategoriesIds={accreditationsCategoriesIds}
                accreditationsIds={accreditationsIds}
                date={date}
                event={event}
                excludeFull={excludeFull}
                name={name}
                organization={organization}
                segmentId={segmentId}
                setAccreditationsCategoriesIds={setAccreditationsCategoriesIds}
                setAccreditationsIds={setAccreditationsIds}
                setDate={setDate}
                setExcludeFull={setExcludeFull}
                setName={setName}
                setSegmentId={setSegmentId}
                setTagsIds={setTagsIds}
                tagsIds={tagsIds}
            />

            <Spacer height="7" />

            {loader ? (
                loader
            ) : (
                <AccreditCategories
                    accreditationsSlots={data.event.accreditationsSlots.nodes}
                    delegation={event.volunteerRegistration.delegation}
                />
            )}
        </Flex>
    );
};
