import { Button } from 'common-front/src/designSystem/components/button';
import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import * as React from 'react';
import { useDocumentBuilderContext } from '../documentBuilderContext';

export const DocumentBuilderLeftPanelDesign = () => {
    const { translate } = useHeavent();
    const { addImageElement, addTextElement, getSelectedPage, setPageName } =
        useDocumentBuilderContext();
    const selectedPage = getSelectedPage();

    React.useEffect(() => {}, []);

    return selectedPage ? (
        <Flex direction="column" gap="6">
            <TextInput
                label={translate('nom_de_la_page_82146')}
                value={selectedPage.name || ''}
                onChange={setPageName}
            />

            <Flex
                css={{
                    border: '1px solid $gray200',
                    borderRadius: '$2',
                    boxShadow: '$xs'
                }}
                direction="column"
            >
                <Flex
                    align="center"
                    css={{
                        borderBottom: '1px solid $gray200',
                        cursor: 'pointer',
                        padding: '$4 $6'
                    }}
                    gap="3"
                    onClick={addTextElement}
                >
                    <Flex align="center" height={24} justify="center" width={24}>
                        <I icon="text" />
                    </Flex>

                    <Box css={{ flex: '1' }} font="gray800 textSm medium">
                        {translate('Text')}
                    </Box>

                    <Button color="white" leftIcon="plus" size="sm" />
                </Flex>

                <Flex
                    align="center"
                    css={{ cursor: 'pointer', padding: '$4 $6' }}
                    gap="3"
                    onClick={addImageElement}
                >
                    <Flex align="center" height={24} justify="center" width={24}>
                        <I icon="image" />
                    </Flex>

                    <Box css={{ flex: '1' }} font="gray800 textSm medium">
                        {translate('image_40624')}
                    </Box>

                    <Button color="white" leftIcon="plus" size="sm" />
                </Flex>
            </Flex>
        </Flex>
    ) : (
        <Flex
            align="center"
            css={{
                border: '1px solid $gray200',
                borderRadius: '$2',
                boxShadow: '$xs',
                px: '$9'
            }}
            direction="column"
            gap="4"
            height={1}
            justify="center"
            width={1}
        >
            <Flex
                css={{
                    border: '1px solid $gray200',
                    borderRadius: '10px',
                    padding: '$3'
                }}
            >
                <I icon="hand-pointer" />
            </Flex>

            <Box font="gray800 textMd medium" textAlign="center">
                {translate('pour_ajouter_de_11528')}
            </Box>
        </Flex>
    );
};
