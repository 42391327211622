import { Map } from 'common-front/src/components/map/map';
import { MapLoaded } from 'common-front/src/components/map/mapLoaded';
import { Box } from 'common/src/designSystem/components/box';
import { useParams } from 'common/src/util/dependencies/dependencies';
import { extractCoordinates } from 'common/src/util/map';
import * as React from 'react';
import { useAccreditationMapQuery } from '../../generated/graphqlHooks';

export const AccreditationMap = () => {
    const { eventId, accreditationId } = useParams();
    const { data, loader } = useAccreditationMapQuery({ eventId, accreditationId });
    const [isLoadedVisible, setIsLoadedVisible] = React.useState(false);

    if (loader) {
        return loader;
    } else {
        const accreditation = data.eventPublic.accreditation;

        return (
            <>
                <Box
                    css={{
                        '& .gm-style-cc': {
                            display: 'none'
                        }
                    }}
                    height={1}
                    width={1}
                >
                    <Map
                        initialZoom={accreditation.zoom}
                        layer={accreditation.layer}
                        mapOptions={{
                            disableDefaultUI: true,
                            keyboardShortcuts: false
                        }}
                        point={extractCoordinates(accreditation)}
                        onTilesloaded={() => {
                            setIsLoadedVisible(true);
                        }}
                    />
                </Box>

                {isLoadedVisible && <MapLoaded />}
            </>
        );
    }
};
