import { Button } from 'common-front/src/designSystem/components/button';
import { RichEditor } from 'common-front/src/designSystem/components/richEditor/richEditor';
import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useUserContext } from 'common-front/src/userContext';
import { Badge } from 'common/src/designSystem/components/badge';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import {
    SupportedLanguage,
    TranslationFragment,
    TranslationType
} from 'common/src/generated/types';
import { ignore } from 'common/src/util/debug';
import { getUnformattedString } from 'common/src/vo/translation';
import * as React from 'react';
import { useTranslationUpdateMutation } from '../../generated/graphqlHooks';

interface IOrganizationTranslationProps {
    isFirst: boolean;
    isLast: boolean;
    languages: SupportedLanguage[];
    translation: TranslationFragment;
}

export const OrganizationTranslation = (props: IOrganizationTranslationProps) => {
    const {
        translate,
        params: { organizationId }
    } = useHeavent();
    const { user } = useUserContext();
    const { mutate: translationUpdate, isLoading: isTranslationUpdateLoading } =
        useTranslationUpdateMutation();
    const [isOpen, setIsOpen] = React.useState(false);
    const [values, setValues] = React.useState(
        Object.fromEntries(
            props.languages.map((language) => [
                language,
                (props.translation as any)[language.toLowerCase()] || ''
            ])
        )
    );
    const language = user!.language;
    const isTranslated = props.languages.every(
        (l) => (props.translation as any)[`is${l}Translated`]
    );

    ignore(organizationId, translationUpdate);

    return (
        <Flex
            css={{
                border: '1px solid $gray200',
                borderBottom: props.isLast ? '1px solid $gray200' : 'none',
                borderBottomLeftRadius: props.isLast ? '$2' : '0',
                borderBottomRightRadius: props.isLast ? '$2' : '0',
                borderTopLeftRadius: props.isFirst ? '$2' : '0',
                borderTopRightRadius: props.isFirst ? '$2' : '0',
                overflow: 'hidden'
            }}
            direction="column"
        >
            <Flex
                align="center"
                css={{
                    background: 'white',
                    cursor: 'pointer',
                    padding: '$4',
                    userSelect: 'none'
                }}
                gap="2"
                width={1}
                onClick={() => {
                    setIsOpen(!isOpen);
                }}
            >
                <Button
                    color="invisible"
                    css={{ color: '$gray700' }}
                    leftIcon={isOpen ? 'chevron-down' : 'chevron-right'}
                />

                <Box font="gray800 textMd medium">
                    {getUnformattedString(props.translation, language)}
                </Box>

                <Flex css={{ flex: '1' }} justify="between">
                    <Badge>{translate(props.translation.module)}</Badge>
                    {isTranslated ? (
                        <Badge color="success">{translate('Translated')}</Badge>
                    ) : (
                        <Badge color="warning">{translate('NotTranslated')}</Badge>
                    )}
                </Flex>
            </Flex>

            {isOpen && (
                <Flex
                    css={{
                        background: '$gray100',
                        padding: '$6'
                    }}
                    direction="column"
                    gap="6"
                >
                    {props.languages.map((language) => {
                        const label = `Version ${language}`;

                        return props.translation.translationType === TranslationType.RichText ? (
                            <RichEditor
                                key={language}
                                initialValue={values[language]}
                                label={label}
                                onChange={(newValue) => {
                                    setValues({
                                        ...values,
                                        [language]: newValue
                                    });
                                }}
                            />
                        ) : (
                            <TextInput
                                key={language}
                                label={label}
                                value={values[language]}
                                onChange={(newValue) => {
                                    setValues({
                                        ...values,
                                        [language]: newValue
                                    });
                                }}
                            />
                        );
                    })}

                    <Flex>
                        <Button
                            isLoading={isTranslationUpdateLoading}
                            onClick={async () => {
                                await translationUpdate({
                                    organizationId,
                                    translationId: props.translation.id,
                                    translation: Object.fromEntries(
                                        Object.entries(values).map(([key, value]) => [
                                            key.toLowerCase(),
                                            value
                                        ])
                                    )
                                });
                            }}
                        >
                            {translate('mettre_jour_85141')}
                        </Button>
                    </Flex>
                </Flex>
            )}
        </Flex>
    );
};
