import { ToggleText } from 'common-front/src/designSystem/components/toggle';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { EventId } from 'common/src/generated/types';
import { IUpdateFormParametersMissionsValues } from 'common/src/input/formParametersMissionsInput';
import { isNonEmptyArray } from 'common/src/util/array';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { FormParametersMissionsHiddenDisplayedSelects } from './formParametersMissionsHiddenDisplayedSelects';

interface IFormParametersMissionsHiddenDisplayedProps {
    eventId: EventId;
    values: IUpdateFormParametersMissionsValues;

    change(name: string, value: any): void;
}

export const FormParametersMissionsHiddenDisplayed = (
    props: IFormParametersMissionsHiddenDisplayedProps
) => {
    const translate = useTranslate();
    const [isOpen, setIsOpen] = React.useState(
        isNonEmptyArray(props.values.form.hiddenPositionsCategoriesIds) ||
            isNonEmptyArray(props.values.form.hiddenPositionsIds) ||
            isNonEmptyArray(props.values.form.hiddenPositionsSlotsIds) ||
            isNonEmptyArray(props.values.form.displayedPositionsCategoriesIds) ||
            isNonEmptyArray(props.values.form.displayedPositionsIds) ||
            isNonEmptyArray(props.values.form.displayedPositionsSlotsIds)
    );

    return (
        <>
            <ToggleText
                value={isOpen}
                onChange={(value) => {
                    setIsOpen(value);

                    if (!value) {
                        props.change('form.hiddenPositionsCategoriesIds', []);
                        props.change('form.hiddenPositionsIds', []);
                        props.change('form.hiddenPositionsSlotsIds', []);
                        props.change('form.displayedPositionsCategoriesIds', []);
                        props.change('form.displayedPositionsIds', []);
                        props.change('form.displayedPositionsSlotsIds', []);
                    }
                }}
            >
                {translate('afficher_masq_74825')}
            </ToggleText>

            {isOpen && (
                <>
                    <Spacer height="4" />

                    <FormParametersMissionsHiddenDisplayedSelects
                        change={props.change}
                        eventId={props.eventId}
                        values={props.values}
                    />
                </>
            )}
        </>
    );
};
