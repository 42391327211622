import { Box } from 'common/src/designSystem/components/box';
import * as React from 'react';
import { createPortal } from 'react-dom';

export const MapLoaded = () =>
    createPortal(
        <Box
            className="h-map-loaded"
            css={{
                background: 'transparent',
                height: '1px',
                left: '-100px',
                position: 'absolute',
                top: '-100px',
                width: '1px'
            }}
        />,
        document.body
    );
