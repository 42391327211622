import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { fromIconVO, I } from 'common/src/designSystem/components/i';
import {
    EventId,
    OrganizationId,
    UserProfileAccreditationsQuery
} from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { getColorByBackgroundColor } from 'common/src/util/color';
import { useService } from 'common/src/util/dependencies/dependencies';
import { LocaleFormats } from 'common/src/util/luxon';
import { AccreditationsPaths } from 'common/src/util/paths/accreditationsPaths';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';

interface IUserProfileAccreditationProps {
    accreditationUserInfo: UserProfileAccreditationsQuery['event']['volunteerRegistration']['accreditationsUsersInfos'][0];
    eventId: EventId;
    organizationId: OrganizationId;
}

export const UserProfileAccreditation = (props: IUserProfileAccreditationProps) => {
    const { history } = useHeavent();
    const dateTimeService = useService(DateTimeService);
    const accreditation = props.accreditationUserInfo.accreditation;
    const accreditationSlot = props.accreditationUserInfo.accreditationSlot;
    const name = React.useMemo(() => {
        if (isNonEmptyString(accreditationSlot.name)) {
            return `${accreditation.name} - ${accreditationSlot.name}`;
        } else {
            return accreditation.name;
        }
    }, [accreditation.name, accreditationSlot.name]);

    return (
        <Flex
            key={accreditationSlot.id}
            align="center"
            css={{
                background: 'white',
                border: '1px solid $gray200',
                borderRadius: '$2',
                boxShadow: '$xs',
                cursor: 'pointer',
                padding: '$4 $6'
            }}
            gap="3"
            onClick={() => {
                history.push(
                    AccreditationsPaths.ACCREDITATION({
                        organizationId: props.organizationId,
                        eventId: props.eventId,
                        accreditationId: accreditation.id
                    })
                );
            }}
        >
            {/* TODO: refactor icon */}
            <Flex
                align="center"
                css={{
                    background: accreditation.color,
                    borderRadius: '12px',
                    color: getColorByBackgroundColor(accreditation.color),
                    fontSize: '$textMd',
                    height: '40px',
                    width: '40px'
                }}
                justify="center"
            >
                <I icon={fromIconVO(accreditation.icon)} />
            </Flex>

            <Flex direction="column">
                <Box font="gray800 textSm semiBold">{name}</Box>

                {accreditationSlot.date && (
                    <Box color="gray500">
                        {dateTimeService.toLocaleString(
                            accreditationSlot.date,
                            LocaleFormats.DateOnly.MonthShort
                        )}
                    </Box>
                )}
            </Flex>
        </Flex>
    );
};
