import Backpack from 'svgComponents/fontawesome/backpack.svg';
import BadgeCheck from 'svgComponents/fontawesome/badgeCheck.svg';
import BagShopping from 'svgComponents/fontawesome/bagShopping.svg';
import Bed from 'svgComponents/fontawesome/bed.svg';
import BriefcaseMedical from 'svgComponents/fontawesome/briefcaseMedical.svg';
import BurgerSoda from 'svgComponents/fontawesome/burgerSoda.svg';
import CakeCandles from 'svgComponents/fontawesome/cakeCandles.svg';
import Camera from 'svgComponents/fontawesome/camera.svg';
import Car from 'svgComponents/fontawesome/car.svg';
import CircleParking from 'svgComponents/fontawesome/circleParking.svg';
import CircleQuestion from 'svgComponents/fontawesome/circleQuestion.svg';
import Droplet from 'svgComponents/fontawesome/droplet.svg';
import ForkKnife from 'svgComponents/fontawesome/forkKnife.svg';
import HatCowboy from 'svgComponents/fontawesome/hatCowboy.svg';
import House from 'svgComponents/fontawesome/house.svg';
import LocationDot from 'svgComponents/fontawesome/locationDot.svg';
import MartiniGlass from 'svgComponents/fontawesome/martiniGlass.svg';
import Megaphone from 'svgComponents/fontawesome/megaphone.svg';
import PersonBiking from 'svgComponents/fontawesome/personBiking.svg';
import PersonHiking from 'svgComponents/fontawesome/personHiking.svg';
import ScrewdriverWrench from 'svgComponents/fontawesome/screwdriverWrench.svg';
import Shirt from 'svgComponents/fontawesome/shirt.svg';
import SignsPost from 'svgComponents/fontawesome/signsPost.svg';
import Star from 'svgComponents/fontawesome/star.svg';
import Ticket from 'svgComponents/fontawesome/ticket.svg';
import UserPolice from 'svgComponents/fontawesome/userPolice.svg';
import { Icon } from '../../generated/types';
import { assertUnreachable } from '../../util/assertUnreachable';

export function getIconComponent(icon: Icon) {
    switch (icon) {
        case Icon.Backpack:
            return Backpack;
        case Icon.BadgeCheck:
            return BadgeCheck;
        case Icon.BagShopping:
            return BagShopping;
        case Icon.Bed:
            return Bed;
        case Icon.BriefcaseMedical:
            return BriefcaseMedical;
        case Icon.BurgerSoda:
            return BurgerSoda;
        case Icon.CakeCandles:
            return CakeCandles;
        case Icon.Camera:
            return Camera;
        case Icon.Car:
            return Car;
        case Icon.CircleParking:
            return CircleParking;
        case Icon.CircleQuestion:
            return CircleQuestion;
        case Icon.Droplet:
            return Droplet;
        case Icon.ForkKnife:
            return ForkKnife;
        case Icon.HatCowboy:
            return HatCowboy;
        case Icon.House:
            return House;
        case Icon.LocationDot:
            return LocationDot;
        case Icon.MartiniGlass:
            return MartiniGlass;
        case Icon.Megaphone:
            return Megaphone;
        case Icon.PersonBiking:
            return PersonBiking;
        case Icon.PersonHiking:
            return PersonHiking;
        case Icon.ScrewdriverWrench:
            return ScrewdriverWrench;
        case Icon.Shirt:
            return Shirt;
        case Icon.SignsPost:
            return SignsPost;
        case Icon.Star:
            return Star;
        case Icon.Ticket:
            return Ticket;
        case Icon.UserPolice:
            return UserPolice;
        default:
            return assertUnreachable(icon);
    }
}
