import * as React from 'react';
import {
    DocumentUserCustomBadgeFragment,
    T24assignmentDocumentOptions
} from '../../../generated/types';
import { T24AssignmentPage } from './t24AssignmentPage';

interface IT24AssignmentProps {
    event: DocumentUserCustomBadgeFragment;
    options: T24assignmentDocumentOptions;
}

export const T24Assignment = (props: IT24AssignmentProps) => (
    <>
        {props.event.volunteerRegistration.positionsSlotsUsersInfos.map((psui, index) => (
            <T24AssignmentPage
                key={index}
                options={props.options}
                positionSlotUserInfo={psui}
                userInfo={props.event.volunteerRegistration.userInfo}
            />
        ))}
    </>
);
