import { AccreditationsSlotId } from 'common/src/generated/types';
import * as React from 'react';

interface IAccreditContext {
    accreditationsSlotsIds: AccreditationsSlotId[];
    isEdit: boolean;

    showNextMember(): void;
    setAccreditationsSlotsIds(accreditationsSlotsIds: AccreditationsSlotId[]): void;
}

const AccreditContext = React.createContext<IAccreditContext>({} as any);

interface IAccreditContextProviderProps {
    accreditationsSlotsIds: AccreditationsSlotId[];
    children: React.ReactNode;
    isEdit: boolean;

    showNextMember(): void;
}

export const AccreditContextProvider = (props: IAccreditContextProviderProps) => {
    const [accreditationsSlotsIds, setAccreditationsSlotsIds] = React.useState(
        props.accreditationsSlotsIds
    );

    return (
        <AccreditContext.Provider
            value={{
                accreditationsSlotsIds,
                isEdit: props.isEdit,
                showNextMember: props.showNextMember,
                setAccreditationsSlotsIds
            }}
        >
            {props.children}
        </AccreditContext.Provider>
    );
};

export function useAccreditContext() {
    return React.useContext(AccreditContext);
}
