import { Badge } from 'common/src/designSystem/components/badge';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { HeaderRow } from 'common/src/designSystem/components/table/headerRow';
import { Row } from 'common/src/designSystem/components/table/row';
import { RowSkeleton } from 'common/src/designSystem/components/table/rowSkeleton';
import { Table } from 'common/src/designSystem/components/table/table';
import { EventId, OrganizationId, UsersInfoId } from 'common/src/generated/types';
import { IntervalService } from 'common/src/services/intervalService';
import { isNonEmptyArray } from 'common/src/util/array';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { Emptyable } from 'common/src/util/emptyable';
import { getStateBadgeColorIcon } from 'common/src/vo/volunteerRegistration';
import * as React from 'react';
import { useUserProfileHistoryEventsQuery } from '../../../generated/graphqlHooks';

interface IUserProfileHistoryEventsProps {
    eventId: Emptyable<EventId>;
    organizationId: OrganizationId;
    userInfoId: UsersInfoId;
}

export const UserProfileHistoryEvents = (props: IUserProfileHistoryEventsProps) => {
    const translate = useTranslate();
    const intervalService = useService(IntervalService);
    const { data, isLoading } = useUserProfileHistoryEventsQuery({
        organizationId: props.organizationId,
        userInfoId: props.userInfoId
    });
    const events = React.useMemo(
        () =>
            (data.organization?.userInfo.volunteersRegistrations ?? [])
                .filter((vr) => vr.event.id !== props.eventId)
                .flatMap((vr) => {
                    if (isNonEmptyArray(vr.positionsSlotsUsersInfos)) {
                        return vr.positionsSlotsUsersInfos.map((psui) => {
                            const range = `${
                                psui.positionSlot.name
                            } ${intervalService.toDisplayString(psui.positionSlot.range)}`;
                            return {
                                eventId: vr.event.id,
                                eventName: vr.event.name,
                                state: vr.state,
                                positionName: psui.position.name,
                                range
                            };
                        });
                    } else {
                        return [
                            {
                                eventId: vr.event.id,
                                eventName: vr.event.name,
                                state: vr.state,
                                positionName: '-',
                                range: '-'
                            }
                        ];
                    }
                }),
        [data.organization]
    );

    return (
        <Table hideBorder={true}>
            <HeaderRow>
                <HeaderCell>{translate('_v_nement_22345')}</HeaderCell>
                <HeaderCell>{translate('status_06428')}</HeaderCell>
                <HeaderCell>{translate('Position')}</HeaderCell>
                <HeaderCell>{translate('cr_neau_11117')}</HeaderCell>
            </HeaderRow>

            {isLoading ? (
                <>
                    <RowSkeleton />
                    <RowSkeleton />
                    <RowSkeleton />
                </>
            ) : (
                events.map((event, index) => (
                    <Row key={index}>
                        <Cell>{event.eventName}</Cell>
                        <Cell>
                            <Badge {...getStateBadgeColorIcon(event.state)}>
                                {translate(event.state)}
                            </Badge>
                        </Cell>
                        <Cell>{event.positionName}</Cell>
                        <Cell>{event.range}</Cell>
                    </Row>
                ))
            )}
        </Table>
    );
};
