import { Badge } from 'common/src/designSystem/components/badge';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import {
    AssignmentInfosQuery,
    PositionId,
    PositionsCategoryId,
    SegmentId,
    TagId
} from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { LocaleFormats } from 'common/src/util/luxon';
import { isNonEmptyString } from 'common/src/util/string';
import { DateTime } from 'luxon';
import * as React from 'react';

interface IAssignmentAppliedFiltersProps {
    date: DateTime | null;
    event: Pick<
        AssignmentInfosQuery['event'],
        'startAt' | 'endAt' | 'positionsCategories' | 'positions' | 'segments'
    >;
    excludeFull: boolean;
    organization: Pick<AssignmentInfosQuery['organization'], 'tags'>;
    positionsCategoriesIds: PositionsCategoryId[] | null;
    positionsIds: PositionId[] | null;
    segmentId: SegmentId | null;
    tagsIds: TagId[] | null;

    reset(): void;
    setDate(date: DateTime | null): void;
    setExcludeFull(excludeFull: boolean): void;
    setPositionsCategoriesIds(positionsCategoriesIds: PositionsCategoryId[] | null): void;
    setPositionsIds(positionsIds: PositionId[] | null): void;
    setSegmentId(segmentId: SegmentId | null): void;
    setTagsIds(tagsIds: TagId[] | null): void;
}

export const AssignmentAppliedFilters = (props: IAssignmentAppliedFiltersProps) => {
    const translate = useTranslate();
    const dateTimeService = useService(DateTimeService);
    const selectedCategories = React.useMemo(
        () =>
            (props.positionsCategoriesIds || [])
                .map((id) => props.event.positionsCategories.find((pc) => pc.id === id)!.name)
                .join(', '),
        [props.positionsCategoriesIds, props.event]
    );
    const selectedPositions = React.useMemo(
        () =>
            (props.positionsIds || [])
                .map((id) => props.event.positions.nodes.find((p) => p.id === id)!.name)
                .join(', '),
        [props.positionsIds, props.event]
    );
    const selectedDate = React.useMemo(
        () =>
            props.date
                ? dateTimeService.toLocaleString(props.date, LocaleFormats.DateOnly.MonthLong)
                : '',
        [props.date]
    );
    const selectedSegment = React.useMemo(
        () =>
            props.segmentId ? props.event.segments.find((s) => s.id === props.segmentId)!.name : '',
        [props.segmentId, props.event]
    );
    const selectedTags = React.useMemo(
        () =>
            (props.tagsIds || [])
                .map((id) => props.organization.tags.nodes.find((t) => t.id === id)!.name)
                .join(', '),
        [props.tagsIds, props.organization]
    );
    const isAppliedFiltersVisible =
        isNonEmptyString(selectedCategories) ||
        isNonEmptyString(selectedPositions) ||
        isNonEmptyString(selectedDate) ||
        isNonEmptyString(selectedSegment) ||
        isNonEmptyString(selectedTags) ||
        props.excludeFull;

    return isAppliedFiltersVisible ? (
        <Flex align="center" gap="3" wrap="wrap">
            {isNonEmptyString(selectedCategories) && (
                <Badge
                    color="white"
                    rightIcon="xmark"
                    onRightIconClick={() => {
                        props.setPositionsCategoriesIds(null);
                    }}
                >
                    {translate('cat_gories_1_42029', selectedCategories)}
                </Badge>
            )}

            {isNonEmptyString(selectedPositions) && (
                <Badge
                    color="white"
                    rightIcon="xmark"
                    onRightIconClick={() => {
                        props.setPositionsIds(null);
                    }}
                >
                    {translate('missions_1_62834', selectedPositions)}
                </Badge>
            )}

            {isNonEmptyString(selectedDate) && (
                <Badge
                    color="white"
                    rightIcon="xmark"
                    onRightIconClick={() => {
                        props.setDate(null);
                    }}
                >
                    {translate('date_1_17337', selectedDate)}
                </Badge>
            )}

            {isNonEmptyString(selectedSegment) && (
                <Badge
                    color="white"
                    rightIcon="xmark"
                    onRightIconClick={() => {
                        props.setSegmentId(null);
                    }}
                >
                    {translate('vue_1_29260', selectedSegment)}
                </Badge>
            )}

            {isNonEmptyString(selectedTags) && (
                <Badge
                    color="white"
                    rightIcon="xmark"
                    onRightIconClick={() => {
                        props.setTagsIds(null);
                    }}
                >
                    {translate('tags_1_43158', selectedTags)}
                </Badge>
            )}

            {props.excludeFull && (
                <Badge
                    color="white"
                    rightIcon="xmark"
                    onRightIconClick={() => {
                        props.setExcludeFull(false);
                    }}
                >
                    {translate('cr_neaux_comple_77561')}
                </Badge>
            )}

            <Box css={{ cursor: 'pointer' }} font="gray700 textXs medium" onClick={props.reset}>
                {translate('r_initialiser_l_66835')}
            </Box>
        </Flex>
    ) : null;
};
