import { FormModal } from 'common-front/src/designSystem/components/formModal';
import { TextInput } from 'common-front/src/designSystem/form/textInput';
import { useEmailUpdateMutation } from 'common-front/src/generated/graphqlHooks';
import { EventId, OrganizationId, UsersInfoId } from 'common/src/generated/types';
import { AuthInputService } from 'common/src/input/authInput';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { Emptyable } from 'common/src/util/emptyable';
import * as React from 'react';

interface IUseroverlayEditEmailModalProps {
    email: string;
    eventId: Emptyable<EventId>;
    organizationId: OrganizationId;
    userInfoId: UsersInfoId;

    onClose(): void;
    onSuccess(): void;
}

export const UseroverlayEditEmailModal = (props: IUseroverlayEditEmailModalProps) => {
    const translate = useTranslate();
    const authInput = useService(AuthInputService);
    const { mutate } = useEmailUpdateMutation();

    return (
        <FormModal
            buttonText={translate('modifier_l_adre_94026')}
            icon="pen"
            initialValues={{
                email: props.email
            }}
            schema={authInput.emailSchema()}
            title={translate('modification_de_69462')}
            onClose={props.onClose}
            onSubmit={async (values) => {
                await mutate({
                    email: values.email,
                    eventId: props.eventId,
                    organizationId: props.organizationId,
                    userInfoId: props.userInfoId
                });

                props.onClose();
                props.onSuccess();
            }}
        >
            <TextInput label={translate('adresse_e_mail_60930')} name="email" />
        </FormModal>
    );
};
