import { FormBox } from 'common-front/src/designSystem/components/formBox';
import { RadioText } from 'common-front/src/designSystem/form/radio';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { VolunteersImportMode } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { ColumnMappings } from './columnMappings';

export const MappingsFormBox = (props: React.ComponentProps<typeof ColumnMappings>) => {
    const translate = useTranslate();

    return (
        <FormBox
            hideToggle={true}
            initialIsOpen={true}
            subtitle={translate('_ditez_la_corre_12431')}
            title={translate('liste_des_champ_40104')}
        >
            <Flex direction="column" gap="2">
                <Box color="gray800" fontWeight="medium">
                    {translate('que_souhaitez_v_33936')}
                </Box>
                <RadioText name="input.mode" value={VolunteersImportMode.Create}>
                    {translate('ajouter_un_memb_41735')}
                </RadioText>
                <RadioText name="input.mode" value={VolunteersImportMode.CreateUpdateFromEmail}>
                    {translate('ajouter_un_memb_91120')}
                </RadioText>
                <RadioText name="input.mode" value={VolunteersImportMode.UpdateFromId}>
                    {translate('n_ajouter_aucun_41072')}
                </RadioText>
            </Flex>

            <Spacer height="5" />

            <ColumnMappings {...props} />
        </FormBox>
    );
};
