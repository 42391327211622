import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { CSS } from 'common/src/designSystem/components/stitches';
import * as React from 'react';
import { Button } from './button';

interface ICardProps {
    children: React.ReactNode;
    css?: CSS;
    header: React.ReactNode;
    initialIsOpen: boolean;
}

export const Card = (props: ICardProps) => {
    const [isOpen, setIsOpen] = React.useState(props.initialIsOpen);

    return (
        <Flex
            css={{
                border: '1px solid $gray200',
                borderRadius: '$2',
                boxShadow: '$xs',
                userSelect: 'none',
                ...(props.css as any)
            }}
            direction="column"
            width={1}
        >
            <Flex align="center" css={{ padding: '$6' }} gap="3">
                <Box css={{ flex: '1' }}>{props.header}</Box>

                <Button
                    color="white"
                    leftIcon={isOpen ? 'chevron-up' : 'chevron-down'}
                    size="sm"
                    onClick={() => {
                        setIsOpen(!isOpen);
                    }}
                />
            </Flex>

            {isOpen && (
                <Flex
                    css={{
                        background: '$gray100',
                        padding: '$6'
                    }}
                    direction="column"
                >
                    {props.children}
                </Flex>
            )}
        </Flex>
    );
};
