import { BlankRow } from 'common-front/src/components/table/blankRow';
import { Button } from 'common-front/src/designSystem/components/button';
import { KpiCard } from 'common-front/src/designSystem/components/kpiCard';
import { Select } from 'common-front/src/designSystem/components/select/select';
import { TableFilters } from 'common-front/src/designSystem/components/tableFilters';
import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { useEnumToOptions } from 'common-front/src/hooks/useEnumToOptions';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useStateDebounce } from 'common-front/src/hooks/useStateDebounce';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { RowSkeleton } from 'common/src/designSystem/components/table/rowSkeleton';
import {
    ALL_EMAILSTATE,
    EmailState,
    SendAssignmentsEmailsInput,
    SortDirection
} from 'common/src/generated/types';
import { isNonEmptyArray } from 'common/src/util/array';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';
import { CampaignEmailRow } from '../../campaigns/show/campaignEmailRow';
import { useEventContext } from '../../events/show/eventContext';
import { useAssignmentsEmailsQuery, useAssignmentsKpisQuery } from '../../generated/graphqlHooks';
import { SendAssignmentsEmailsConfirmationModal } from './sendAssignmentsEmailsConfirmationModal';
import { SendAssignmentsEmailsModal } from './sendAssignmentsEmailsModal';

export const AssignmentsEventAdmin = () => {
    const {
        history,
        translate,
        params: { organizationId, eventId }
    } = useHeavent();
    const { localEvent, setNumberOfWaitingAssigments } = useEventContext();
    const { data, loader, reload } = useAssignmentsKpisQuery({
        organizationId,
        eventId,
        direction: localEvent?.['assignment.leftPanelDirection'] ?? SortDirection.Desc
    });
    const enumToOptions = useEnumToOptions();
    const [name, nameDebounced, setName] = useStateDebounce('');
    const [state, setState] = React.useState<'' | EmailState>('');
    const [offset, setOffset] = React.useState(0);
    const { data: emailsData, isLoading: areEmailsLoading } = useAssignmentsEmailsQuery({
        eventId,
        name: isNonEmptyString(nameDebounced) ? nameDebounced : undefined,
        state: state !== '' ? state : undefined,
        offset
    });
    const [isSendEmailsModalOpen, setIsSendEmailsModalOpen] = React.useState(false);
    const [sendAssignmentsEmailsInput, setSendAssignmentsEmailsInput] =
        React.useState<SendAssignmentsEmailsInput | null>(null);
    const [numberOfEmails, setNumberOfemails] = React.useState(0);
    const [isSendEmailsConfirmationModalOpen, setIsSendEmailsConfirmationModalOpen] =
        React.useState(false);

    React.useEffect(() => {
        if (data.event?.waitingAssignment) {
            setNumberOfWaitingAssigments(data.event.waitingAssignment.totalCount);
        }
    }, [data]);

    return (
        loader || (
            <Flex css={{ padding: '$7' }} direction="column">
                <Box color="gray900" css={{ flex: '1' }} fontSize="displayXs" fontWeight="semiBold">
                    {translate('mes_affectation_73224')}
                </Box>

                <Spacer height="7" />

                <Flex gap="6">
                    <KpiCard
                        button={
                            <>
                                {data.event.hasFormWithTeamsAllowed && (
                                    <Button
                                        color="invisible"
                                        disabled={data.event.waitingTeams.totalCount === 0}
                                        onClick={() => {
                                            history.push(
                                                HeaventPaths.TEAM_ASSIGNMENT(
                                                    organizationId,
                                                    eventId,
                                                    data.event.waitingTeams.nodes[0].teamCode
                                                )
                                            );
                                        }}
                                    >
                                        {translate('affecter_les_q_17322')}
                                    </Button>
                                )}

                                <Button
                                    disabled={data.event.waitingAssignment.totalCount === 0}
                                    onClick={() => {
                                        history.push(
                                            HeaventPaths.ASSIGNMENT(
                                                organizationId,
                                                eventId,
                                                data.event.waitingAssignment.nodes[0].userInfoId
                                            )
                                        );
                                    }}
                                >
                                    {translate('affecter_les_me_77160')}
                                </Button>
                            </>
                        }
                        color="success"
                        count={data.event.waitingAssignment.totalCount}
                        icon="arrow-trend-up"
                        text={translate(
                            'membre_en_atten_84228',
                            data.event.waitingAssignment.totalCount
                        )}
                    />

                    <KpiCard
                        button={
                            <Button
                                onClick={() => {
                                    setIsSendEmailsModalOpen(true);
                                }}
                            >
                                {translate('param_trer_et_e_31095')}
                            </Button>
                        }
                        color="success"
                        count={data.event.emailsStats.numberOfWaiting}
                        icon="at"
                        text={translate(
                            'e_mail_de_confi_84099',
                            data.event.emailsStats.numberOfWaiting
                        )}
                    />

                    <KpiCard
                        button={
                            <Button
                                onClick={() => {
                                    history.push(HeaventPaths.VOLUNTEERS(organizationId, eventId));
                                }}
                            >
                                {translate('voir_les_membre_27923')}
                            </Button>
                        }
                        color="success"
                        count={data.event.assigned.totalCount}
                        icon="user-check"
                        text={translate('membre_affect_05484', data.event.assigned.totalCount)}
                    />
                </Flex>

                <Spacer height="9" />

                <Box color="gray900" fontSize="textLg" fontWeight="medium">
                    {translate('historique_des_68195')}
                </Box>

                <Spacer height="4" />

                <Flex gap="6">
                    <KpiCard
                        color="purple"
                        count={data.event.emailsStats.numberOfSent ?? 0}
                        icon="paper-plane"
                        text={translate('nombre_d_envois_95106')}
                    />

                    <KpiCard
                        color="success"
                        count={data.event.emailsStats.numberOfOpen ?? 0}
                        icon="eye"
                        text={translate('nombre_d_ouvert_84187')}
                    />

                    <KpiCard
                        color="primary"
                        count={data.event.emailsStats.numberOfClick ?? 0}
                        icon="hand-pointer"
                        text={translate('nombre_de_clics_64232')}
                    />
                </Flex>

                <Spacer height="6" />

                <TableFilters
                    filters={
                        <>
                            <Box width={200}>
                                <Select
                                    value={state}
                                    onChange={(newState: '' | EmailState) => {
                                        setState(newState);
                                        setOffset(0);
                                    }}
                                >
                                    <option value="">{translate('tous_les_status_67644')}</option>

                                    {enumToOptions(
                                        ALL_EMAILSTATE.filter((s) => s !== EmailState.Queued)
                                    )}
                                </Select>
                            </Box>

                            <Box width={320}>
                                <TextInput
                                    icon="magnifying-glass"
                                    placeholder={translate('rechercher_un_d_02329')}
                                    value={name}
                                    onChange={(newName: string) => {
                                        setName(newName);
                                        setOffset(0);
                                    }}
                                />
                            </Box>
                        </>
                    }
                    headerCells={
                        <>
                            <HeaderCell>{translate('nom_du_membre_69353')}</HeaderCell>
                            <HeaderCell>{translate('date_d_envoi_74668')}</HeaderCell>
                            <HeaderCell>{translate('status_06428')}</HeaderCell>
                        </>
                    }
                    numberOfPages={emailsData.event?.emails.numberOfPages ?? 0}
                    offset={offset}
                    rows={
                        areEmailsLoading ? (
                            <>
                                <RowSkeleton bx={true} />
                                <RowSkeleton bx={true} />
                                <RowSkeleton bx={true} />
                            </>
                        ) : isNonEmptyArray(emailsData.event.emails.nodes) ? (
                            <>
                                {emailsData.event.emails.nodes.map((email) => (
                                    <CampaignEmailRow key={email.id} email={email} />
                                ))}
                            </>
                        ) : (
                            <BlankRow
                                subtitle={translate('vous_n_avez_pas_92612')}
                                title={translate('aucun_courriel_12121')}
                            />
                        )
                    }
                    setOffset={setOffset}
                    title={translate('liste_des_envoi_52697')}
                    totalCount={emailsData.event?.emails.totalCount ?? 0}
                />

                {isSendEmailsModalOpen && (
                    <SendAssignmentsEmailsModal
                        emailSender={data.organization.emailTemplate?.emailSender}
                        eventId={eventId}
                        onClose={() => {
                            setIsSendEmailsModalOpen(false);
                        }}
                        onSuccess={(newNumberOfEmails, sendAssignmentsEmails) => {
                            setNumberOfemails(newNumberOfEmails);
                            setSendAssignmentsEmailsInput(sendAssignmentsEmails);
                            setIsSendEmailsConfirmationModalOpen(true);
                        }}
                    />
                )}

                {isSendEmailsConfirmationModalOpen && (
                    <SendAssignmentsEmailsConfirmationModal
                        eventId={eventId}
                        numberOfEmails={numberOfEmails}
                        reload={reload}
                        sendAssignmentsEmails={sendAssignmentsEmailsInput!}
                        onClose={() => {
                            setIsSendEmailsConfirmationModalOpen(false);
                        }}
                    />
                )}
            </Flex>
        )
    );
};
