import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { styled } from 'common/src/designSystem/components/stitches';
import { EventId } from 'common/src/generated/types';
import { useHistory, useLocation, useParams } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { generatePath, matchPath } from 'react-router';

const Link = styled(Flex, {
    variants: {
        active: {
            true: {
                color: '$blue600'
            }
        }
    }
});

interface INavigationLinkProps {
    children: (active: boolean) => React.ReactNode;
    path: string;
    text: string;
    eventId: EventId;
}

export const NavigationLink = ({ children, path, text, eventId }: INavigationLinkProps) => {
    const location = useLocation();
    const active = matchPath(location.pathname, { path }) !== null;
    const params = useParams();
    const history = useHistory();

    const onClick = () => {
        if (active === false) {
            history.push(generatePath(path, { ...params, eventId: params.eventId || eventId }));
        }
    };

    return (
        <Link active={active} align="center" direction="column" onClick={onClick}>
            <Box>{children(active)}</Box>
            <Box fontSize="textXs">{text}</Box>
        </Link>
    );
};
