import { EmailState } from '../generated/types';
import { IBadgeColorIcon } from './volunteerRegistration';

export function getStateBadgeColorIcon(state: EmailState): IBadgeColorIcon {
    switch (state) {
        case EmailState.Waiting:
        case EmailState.Queued:
            return { color: 'warning', leftIcon: 'hourglass-empty' };
        case EmailState.Bounced:
            return { color: 'error', leftIcon: 'circle-exclamation' };
        default:
            return { color: 'success', leftIcon: 'check' };
    }
}
