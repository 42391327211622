import { styled } from 'common/src/designSystem/components/stitches';
import { LeaderInfosFragment, PositionsCategory } from 'common/src/generated/types';
import { isNonEmptyArray } from 'common/src/util/array';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';

const Name = styled('div', {
    color: '$gray800',
    fontWeight: '$semiBold',
    lineHeight: '24px'
});

const LeadersLabel = styled('div', {
    color: '$gray400',
    fontSize: '$textSm'
});

const Leaders = styled('span', {
    color: '$gray800'
});

interface IPositionCategoryMobileProps {
    category: Pick<PositionsCategory, 'id' | 'name'> & {
        leaders: LeaderInfosFragment[];
    };
}

export const PositionCategoryMobile = ({ category }: IPositionCategoryMobileProps) => {
    const translate = useTranslate();

    return (
        <>
            <Name>{category.name}</Name>
            {isNonEmptyArray(category.leaders) && (
                <LeadersLabel>
                    {`${translate('responsable_category', category.leaders.length)} `}
                    <Leaders>
                        {category.leaders.map(({ userInfo }) => userInfo.name).join(', ')}
                    </Leaders>
                </LeadersLabel>
            )}
        </>
    );
};
