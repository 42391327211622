import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import * as React from 'react';

interface IUserProfileActionsContainerProps {
    children: React.ReactNode;
    title: string;
}

export const UserProfileActionsContainer = (props: IUserProfileActionsContainerProps) => (
    <>
        <Box font="gray900 textMd semiBold">{props.title}</Box>

        <Spacer height="2" />

        <Flex
            css={{
                background: 'white',
                border: '1px solid $gray200',
                borderRadius: '$2',
                boxShadow: '$xs',
                overflow: 'hidden',
                '& > div:not(:last-child)': {
                    borderBottom: '1px solid $gray200'
                }
            }}
            direction="column"
        >
            {props.children}
        </Flex>
    </>
);
