import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { isNonEmptyString } from 'common/src/util/string';
import { FilterPredicate, Operator, Predicate } from 'common/src/vo/segment';
import * as React from 'react';
import { Button } from '../../designSystem/components/button';
import { RadioText } from '../../designSystem/components/radio';
import { TextInput } from '../../designSystem/components/textInput';

interface IAddTextFilterProps {
    filterPredicate: FilterPredicate;
    showIsValid: boolean;

    onAdd(predicate: Predicate): void;
}

export const AddTextFilter = (props: IAddTextFilterProps) => {
    const translate = useTranslate();
    const [operator, setOperator] = React.useState<Operator>(
        props.filterPredicate.predicate?.operator ?? 'contains'
    );
    const [value, setValue] = React.useState(props.filterPredicate.predicate?.value ?? '');
    const valid =
        operator === 'contains' || operator === 'notContains' ? isNonEmptyString(value) : true;

    return (
        <>
            <RadioText
                state={operator === 'contains' ? 'checked' : 'unchecked'}
                onClick={() => {
                    setOperator('contains');
                }}
            >
                {translate('contient_34012')}
            </RadioText>

            {operator === 'contains' && (
                <>
                    <Spacer height="1" />

                    <Flex>
                        <Spacer width="6" />
                        <TextInput value={value} onChange={setValue} />
                    </Flex>

                    <Spacer height="1" />
                </>
            )}

            <Spacer height="3" />

            <RadioText
                state={operator === 'notContains' ? 'checked' : 'unchecked'}
                onClick={() => {
                    setOperator('notContains');
                }}
            >
                {translate('ne_contient_pas_85399')}
            </RadioText>

            {operator === 'notContains' && (
                <>
                    <Spacer height="1" />

                    <Flex>
                        <Spacer width="6" />
                        <TextInput value={value} onChange={setValue} />
                    </Flex>

                    <Spacer height="1" />
                </>
            )}

            <Spacer height="3" />

            <RadioText
                state={operator === 'startsWith' ? 'checked' : 'unchecked'}
                onClick={() => {
                    setOperator('startsWith');
                }}
            >
                {translate('commence_par_11056')}
            </RadioText>

            {operator === 'startsWith' && (
                <>
                    <Spacer height="1" />

                    <Flex>
                        <Spacer width="6" />
                        <TextInput value={value} onChange={setValue} />
                    </Flex>

                    <Spacer height="1" />
                </>
            )}

            <Spacer height="3" />

            <RadioText
                state={operator === 'endsWith' ? 'checked' : 'unchecked'}
                onClick={() => {
                    setOperator('endsWith');
                }}
            >
                {translate('finit_par_79657')}
            </RadioText>

            {operator === 'endsWith' && (
                <>
                    <Spacer height="1" />

                    <Flex>
                        <Spacer width="6" />
                        <TextInput value={value} onChange={setValue} />
                    </Flex>

                    <Spacer height="1" />
                </>
            )}

            {props.filterPredicate.filter.hideEmpty !== true && (
                <>
                    <Spacer height="3" />

                    <RadioText
                        state={operator === 'isEmpty' ? 'checked' : 'unchecked'}
                        onClick={() => {
                            setOperator('isEmpty');
                        }}
                    >
                        {translate('est_vide_96759')}
                    </RadioText>

                    <Spacer height="3" />

                    <RadioText
                        state={operator === 'isNotEmpty' ? 'checked' : 'unchecked'}
                        onClick={() => {
                            setOperator('isNotEmpty');
                        }}
                    >
                        {translate('n_est_pas_vide_45804')}
                    </RadioText>
                </>
            )}

            {props.showIsValid && (
                <>
                    <Spacer height="3" />

                    <RadioText
                        state={operator === 'isValid' ? 'checked' : 'unchecked'}
                        onClick={() => {
                            setOperator('isValid');
                        }}
                    >
                        {translate('est_valide_72630')}
                    </RadioText>

                    <Spacer height="3" />

                    <RadioText
                        state={operator === 'isNotValid' ? 'checked' : 'unchecked'}
                        onClick={() => {
                            setOperator('isNotValid');
                        }}
                    >
                        {translate('n_est_pas_valid_75405')}
                    </RadioText>
                </>
            )}

            <Spacer height="7" />

            <Flex>
                <Button
                    disabled={!valid}
                    onClick={() => {
                        props.onAdd({
                            operator,
                            value,
                            slug: props.filterPredicate.filter.slug
                        });
                    }}
                >
                    {translate('ajouter_le_filt_54222')}
                </Button>
            </Flex>
        </>
    );
};
