import { Empty } from 'common-front/src/components/empty/empty';
import { CommonEnvVars } from 'common/src/envVars';
import { HeaventEnv } from 'common/src/heaventEnv';
import { useHistory, useParams } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';

interface TermsAcceptedProps {
    children: React.ReactNode;
    termsAccepted: boolean;
}

export const TermsAccepted = ({ children, termsAccepted }: TermsAcceptedProps) => {
    const { organizationId } = useParams();
    const history = useHistory();

    if (
        termsAccepted ||
        CommonEnvVars.HEAVENT_ENV !== HeaventEnv.Recrewteer ||
        window.location.pathname === HeaventPaths.ACCEPT_TERMS_AND_CONDITIONS(organizationId)
    ) {
        return <>{children}</>;
    } else {
        return (
            <Empty
                path={HeaventPaths.ACCEPT_TERMS_AND_CONDITIONS(organizationId)}
                state={history.location}
            />
        );
    }
};
