import { CSS } from '@stitches/react';
import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';

const DEV = false;

const NAMES = [
    'TERRAIN',
    'VESTIAIRES',
    'ESPACE PRESSE',
    'SALON NEODIF',
    'SALON GEOFIT',
    'SALON PRESTIGE'
];

const BACKGROUNDS: { [name: string]: string } = {
    TERRAIN: 'Mode_Isolation_1.png',
    VESTIAIRES: 'Mode_Isolation_2.png',
    'ESPACE PRESSE': 'Mode_Isolation_3.png',
    'SALON NEODIF': 'Mode_Isolation_4.png',
    'SALON GEOFIT': 'Mode_Isolation_5.png',
    'SALON PRESTIGE': 'Mode_Isolation_6.png'
};

const Dot: React.FunctionComponent<
    React.ComponentProps<typeof Box> & { css: CSS; name: string }
> = ({ css, name }) => (
    <Box
        css={
            {
                background: `url(https://assets.recrewteer.com/badges/hbc/lnh/${BACKGROUNDS[name]}) no-repeat`,
                backgroundSize: 'cover',
                height: '30px',
                position: 'absolute',
                width: '30px',
                ...css
            } as React.ComponentProps<typeof Box>['css']
        }
    />
);

export const BadgeHbcLdc = ({ event }: { event: DocumentUserCustomBadgeFragment }) => {
    const vr = event.volunteerRegistration;
    const ui = vr.userInfo;

    if (DEV) {
        vr.positionsSlotsUsersInfos = [
            ...vr.positionsSlotsUsersInfos,
            { position: { name: 'Président' } }
        ] as DocumentUserCustomBadgeFragment['volunteerRegistration']['positionsSlotsUsersInfos'];
    }

    const position = ui.fields?.cf38222?.value;
    const accreditations = vr.accreditationsUsersInfos.reduce(
        (acc, aui) => {
            if (NAMES.includes(aui.accreditation.name)) {
                return { ...acc, [aui.accreditation.name]: true };
            } else {
                return acc;
            }
        },
        (DEV
            ? {
                  TERRAIN: true,
                  VESTIAIRES: true,
                  'ESPACE PRESSE': true,
                  'SALON NEODIF': true,
                  'SALON GEOFIT': false,
                  'SALON PRESTIGE': true
              }
            : {}) as {
            [name: string]: boolean;
        }
    );

    return (
        <>
            <Flex
                css={{
                    background: `url(https://assets.recrewteer.com/badges/hbc/ldc/bg_badge_recto.jpg) no-repeat`,
                    backgroundSize: 'cover',
                    color: '#FFF',
                    fontFamily: '$formulaCondensedBold',
                    position: 'relative'
                }}
                height={476}
                width={363}
            >
                <Box
                    css={{
                        fontSize: '26px',
                        fontWeight: 700,
                        left: '34px',
                        lineHeight: 'normal',
                        position: 'absolute',
                        textAlign: 'center',
                        textDecoration: 'uppercase',
                        top: '187px'
                    }}
                >
                    {`${ui.firstName} ${ui.lastName}`.toUpperCase()}
                </Box>
                <Box
                    css={{
                        fontSize: '13px',
                        fontWeight: '700',
                        left: '34px',
                        lineHeight: 'normal',
                        position: 'absolute',
                        textAlign: 'center',
                        top: '140px'
                    }}
                >
                    {ui.fields.cf38077?.value}
                </Box>
                <Box
                    css={{
                        fontSize: '26px',
                        fontWeight: 700,
                        left: '34px',
                        lineHeight: 'normal',
                        position: 'absolute',
                        textAlign: 'center',
                        textDecoration: 'uppercase',
                        top: '252px'
                    }}
                >
                    {(position || '').toUpperCase()}
                </Box>
                {accreditations['TERRAIN'] && (
                    <Dot css={{ left: '251px', top: '314px' }} name="TERRAIN" />
                )}
                {accreditations['VESTIAIRES'] && (
                    <Dot css={{ left: '299px', top: '314px' }} name="VESTIAIRES" />
                )}
                {accreditations['ESPACE PRESSE'] && (
                    <Dot css={{ left: '251px', top: '363px' }} name="ESPACE PRESSE" />
                )}
                {accreditations['SALON NEODIF'] && (
                    <Dot css={{ left: '299px', top: '363px' }} name="SALON NEODIF" />
                )}
                {accreditations['SALON GEOFIT'] && (
                    <Dot css={{ left: '251px', top: '412px' }} name="SALON GEOFIT" />
                )}
                {accreditations['SALON PRESTIGE'] && (
                    <Dot css={{ left: '299px', top: '412px' }} name="SALON PRESTIGE" />
                )}
            </Flex>
            <Flex
                css={{
                    background: `url(https://assets.recrewteer.com/badges/hbc/ldc/bg_badge_verso_v2.jpg) no-repeat`,
                    backgroundSize: 'cover',
                    position: 'relative'
                }}
                height={476}
                width={363}
            />
        </>
    );
};
