import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { getColorByBackgroundColor } from '../../../util/color';
import { getIconComponent } from '../icon';

interface IPastourelleAccreditationsProps {
    event: DocumentUserCustomBadgeFragment;
}

export const PastourelleAccreditations = (props: IPastourelleAccreditationsProps) => (
    <Flex
        css={{
            position: 'absolute',
            px: '34px',
            top: '516px'
        }}
        justify="between"
        width={1}
    >
        {props.event.volunteerRegistration.accreditationsUsersInfos.map((aui, index) => {
            const Icon = getIconComponent(aui.accreditation.icon);
            const fill = getColorByBackgroundColor(aui.accreditation.color);

            return (
                <Flex
                    key={index}
                    align="center"
                    direction="column"
                    gap="1"
                    justify="center"
                    width={75}
                >
                    <Flex
                        align="center"
                        css={{
                            background: aui.accreditation.color,
                            borderRadius: '$1',
                            '& svg': {
                                fill,
                                height: '50%',
                                width: '50%'
                            }
                        }}
                        height={24}
                        justify="center"
                        width={24}
                    >
                        <Icon />
                    </Flex>

                    <Box
                        color="gray800"
                        css={{ fontSize: '10px' }}
                        fontWeight="medium"
                        textAlign="center"
                    >
                        {aui.accreditation.name}
                    </Box>
                </Flex>
            );
        })}
    </Flex>
);
