import { MobileOverlay } from 'common-front/src/components/mobileOverlay/mobileOverlay';
import { styled } from 'common/src/designSystem/components/stitches';
import { useHistory, useTranslate } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import { useOrganizationsQuery } from '../../generated/graphqlHooks';
import { useOrganizationContext } from '../../organization/organizationContext';
import { OrganizationSwitchMobile } from './organizationSwitchMobile';

const _h3 = styled('h3', {
    color: '$gray800',
    fontWeight: '$bold',
    margin: '$3 $4'
});

export const OrganizationSwitchSheetMobile = () => {
    const { loader, data } = useOrganizationsQuery({});
    const { organization } = useOrganizationContext();
    const translate = useTranslate();
    const history = useHistory();

    const onClose = () => {
        history.push(HeaventPaths.ORGANIZATION_PARAMETERS(organization.id));
    };

    return (
        <MobileOverlay onClose={onClose}>
            <_h3>{translate('liste_de_vos_or_87919')}</_h3>
            {loader || <OrganizationSwitchMobile organizations={data.organizations} />}
        </MobileOverlay>
    );
};
