import { DeleteModal } from 'common-front/src/designSystem/components/deleteModal';
import { closeOtherDropdowns } from 'common-front/src/designSystem/components/dropdown/dropdown';
import { DropdownEllipsis } from 'common-front/src/designSystem/components/dropdown/dropdownEllipsis';
import { ItemIcon } from 'common-front/src/designSystem/components/dropdown/item';
import { Content } from 'common-front/src/designSystem/components/tooltip/content';
import { Tooltip } from 'common-front/src/designSystem/components/tooltip/tooltip';
import { Trigger } from 'common-front/src/designSystem/components/tooltip/trigger';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { I } from 'common/src/designSystem/components/i';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { CellControls } from 'common/src/designSystem/components/table/cellControls';
import { Row } from 'common/src/designSystem/components/table/row';
import { WeezeventRulesQuery } from 'common/src/generated/types';
import { TranslateFn } from 'common/src/services/translationService';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import { useWeezeventRuleDeleteMutation } from '../../../generated/graphqlHooks';

const getValue = (
    rule: WeezeventRulesQuery['event']['weezeventRules']['nodes'][0],
    translate: TranslateFn
) => {
    if (rule.idQuestion === 'ticket') {
        return rule.field?.choices?.find((c) => c.id === rule.value)?.label ?? '';
    } else {
        return typeof rule.value === 'boolean'
            ? rule.value
                ? translate('true')
                : translate('false')
            : rule.value;
    }
};

interface IWeezeventRuleRowProps {
    rule: WeezeventRulesQuery['event']['weezeventRules']['nodes'][0];

    reload(): void;
}

export const WeezeventRuleRow = (props: IWeezeventRuleRowProps) => {
    const {
        history,
        translate,
        params: { organizationId, eventId }
    } = useHeavent();
    const { mutate } = useWeezeventRuleDeleteMutation();
    const [isDeleteOpen, setIsDeleteOpen] = React.useState(false);

    return (
        <>
            <Row
                css={{
                    '&:hover': {
                        background: '$gray100'
                    }
                }}
                onMouseLeave={(e) => {
                    closeOtherDropdowns(e.target);
                }}
            >
                <Cell css={{ color: '$gray900' }}>{props.rule.name}</Cell>
                <Cell>{props.rule.segment.name}</Cell>
                <Cell>{props.rule.field?.name ?? props.rule.idQuestion}</Cell>
                <Cell>{getValue(props.rule, translate)}</Cell>
                <CellControls justify="end">
                    <Tooltip>
                        <Trigger>
                            <Box>
                                <I
                                    icon="pen"
                                    onClick={() => {
                                        history.push(
                                            HeaventPaths.EDIT_WEEZEVENT_RULE(
                                                organizationId,
                                                eventId,
                                                props.rule.id
                                            )
                                        );
                                    }}
                                />
                            </Box>
                        </Trigger>

                        <Content placement="top">{translate('_diter_62574')}</Content>
                    </Tooltip>

                    <DropdownEllipsis>
                        <ItemIcon
                            color="red"
                            icon="trash-can"
                            onClick={() => {
                                setIsDeleteOpen(true);
                            }}
                        >
                            {translate('supprimer_43083')}
                        </ItemIcon>
                    </DropdownEllipsis>
                </CellControls>
            </Row>

            {isDeleteOpen && (
                <DeleteModal
                    buttonText={translate('supprimer_43083')}
                    subtext={translate('_tes_vous_s_r_d_26427')}
                    text={translate('suppression_de_73669', props.rule.name)}
                    onClose={() => {
                        setIsDeleteOpen(false);
                    }}
                    onDelete={async () => {
                        await mutate({ eventId, ruleId: props.rule.id });

                        props.reload();
                    }}
                />
            )}
        </>
    );
};
