import * as React from 'react';

interface IModalContext {
    isOpen: boolean;

    onClose(): void;
    setIsOpen(isOpen: boolean): void;
}

const ModalContext = React.createContext<IModalContext>({} as any);

export function useModalContext() {
    return React.useContext(ModalContext);
}

interface IRootProps {
    children: React.ReactNode;
    isOpen?: boolean;

    onClose?(): void;
}

export const Root = (props: IRootProps) => {
    const [isOpen, setIsOpen] = React.useState(props.isOpen ?? false);
    const onClose = React.useCallback(() => {
        setIsOpen(false);
        props.onClose?.();
    }, [setIsOpen, props.onClose]);

    React.useEffect(() => {
        if (typeof props.isOpen === 'boolean') {
            setIsOpen(props.isOpen);
        }
    }, [props.isOpen]);

    return (
        <ModalContext.Provider
            value={{
                isOpen,
                onClose,
                setIsOpen
            }}
        >
            {props.children}
        </ModalContext.Provider>
    );
};
