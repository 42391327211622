import { groupBy, range } from 'lodash-es';
import { Interval } from 'luxon';
import * as React from 'react';
import { Avatar } from '../../components/avatar/avatar';
import { Box } from '../../designSystem/components/box';
import { Flex } from '../../designSystem/components/flex';
import { Grid } from '../../designSystem/components/grid';
import { I } from '../../designSystem/components/i';
import { DocumentUsersPlanningFragment, UsersInfoId } from '../../generated/types';
import { IntervalService } from '../../services/intervalService';
import { getColorByBackgroundColor } from '../../util/color';
import { useService, useTranslate } from '../../util/dependencies/dependencies';
import { HeaderCell } from '../cells';

type AvailabilitySlot = {
    type: 'availability';
    range: Interval;
    userInfoId: UsersInfoId;
};

type AssignmentSlot = {
    type: 'assignment';
    range: Interval;
    position: DocumentUsersPlanningFragment['positionsSlotsUsersInfos'][0]['position'];
    userInfoId: UsersInfoId;
};

export type UserInfoSlot = AvailabilitySlot | AssignmentSlot;

interface IUsersPlanningPageProps {
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    interval: Interval;
    slots: UserInfoSlot[];
    volunteersRegistrations: Array<Pick<DocumentUsersPlanningFragment, 'state' | 'userInfo'>>;

    onNextClick?(): void;
    onPreviousClick?(): void;
}

export const UsersPlanningPage = (props: IUsersPlanningPageProps) => {
    const translate = useTranslate();
    const intervalService = useService(IntervalService);
    const userInfoIdToSlots = React.useMemo(
        () => groupBy(props.slots, (s) => s.userInfoId),
        [props.slots]
    );

    return (
        <Grid
            css={{
                gridAutoRows: '48px'
            }}
            gridtemplatecolumns="200px repeat(720, 1fr)"
            width={1}
        >
            <Flex
                align="center"
                css={{
                    border: '1px solid $gray200',
                    gridColumn: '1 / 2',
                    gridRow: '1 / 2',
                    px: '$4'
                }}
            />

            <Flex
                align="center"
                css={{
                    borderBottom: '1px solid $gray200',
                    borderRight: '1px solid $gray200',
                    borderTop: '1px solid $gray200',
                    // borderTopRightRadius: '$2',
                    gridColumn: '2 / 722',
                    gridRow: '1 / 2',
                    px: '$6'
                }}
                gap="4"
            >
                <Box
                    css={{ cursor: 'pointer' }}
                    fontSize="textXs"
                    textAlign="start"
                    width={20}
                    onClick={() => {
                        if (props.hasPreviousPage) {
                            props.onPreviousClick?.();
                        }
                    }}
                >
                    {props.hasPreviousPage && <I icon="chevron-left" />}
                </Box>

                <Box color="gray800" textAlign="center" width={1}>
                    {intervalService.toDisplayString(props.interval, {
                        formats: { displayWeekday: true }
                    })}
                </Box>

                <Box
                    css={{ cursor: 'pointer' }}
                    fontSize="textXs"
                    textAlign="end"
                    width={20}
                    onClick={() => {
                        if (props.hasNextPage) {
                            props.onNextClick?.();
                        }
                    }}
                >
                    {props.hasNextPage && <I icon="chevron-right" />}
                </Box>
            </Flex>

            <Box
                css={{
                    borderBottom: '1px solid $gray200',
                    borderLeft: '1px solid $gray200',
                    borderRight: '1px solid $gray200',
                    gridColumn: `1 / 2`,
                    gridRow: '2 / 3'
                }}
            />

            {range(0, 6).map((i) => {
                const start = props.interval.start!.plus({ hour: 2 * i });
                const end = start.plus({ hour: 2 });
                const interval = Interval.fromDateTimes(start, end);

                return (
                    <HeaderCell
                        key={i}
                        border="gray200"
                        css={{
                            gridColumn: `${2 + i * 120} / ${2 + (i + 1) * 120}`,
                            gridRow: '2 / 3',
                            height: '48px'
                        }}
                    >
                        {interval.toFormat('HH:mm')}
                    </HeaderCell>
                );
            })}

            {props.volunteersRegistrations.map((vr, index) => {
                const userInfo = vr.userInfo;
                const slots = userInfoIdToSlots[userInfo.id] || [];

                return (
                    <React.Fragment key={index}>
                        <Flex
                            align="center"
                            css={{
                                borderBottom: '1px solid $gray200',
                                borderLeft: '1px solid $gray200',
                                borderRight: '1px solid $gray200',
                                gridColumn: '1 / 2',
                                gridRow: `${3 + index} / ${4 + index}`,
                                px: '$6'
                            }}
                            gap="3"
                        >
                            <Avatar
                                email={userInfo.email}
                                image={userInfo.picture?.url}
                                name={userInfo.name}
                                size={32}
                            />

                            <Box css={{ flex: '1', overflow: 'hidden' }}>
                                <Box
                                    css={{ ellipsis: true }}
                                    font="gray800 textSm medium"
                                    title={userInfo.name}
                                    width={1}
                                >
                                    {userInfo.name}
                                </Box>
                            </Box>
                        </Flex>

                        {range(0, 6).map((i) => (
                            <Box
                                key={i}
                                css={{
                                    borderBottom: '1px solid $gray200',
                                    borderRight: '1px solid $gray200',
                                    gridColumn: `${2 + i * 120} / ${2 + (i + 1) * 120}`,
                                    gridRow: `${3 + index} / ${4 + index}`
                                }}
                            />
                        ))}

                        {slots.map((slot, slotIndex) => {
                            const diffWithStart = slot.range
                                .start!.diff(props.interval.start!)
                                .shiftTo('hours', 'minutes');
                            const diff = slot.range
                                .end!.diff(slot.range.start!)
                                .shiftTo('hours', 'minutes');
                            const gridColumnStart = Math.floor(
                                2 + diffWithStart.hours * 60 + diffWithStart.minutes
                            );
                            const gridColumnEnd = Math.ceil(
                                gridColumnStart + diff.hours * 60 + diff.minutes
                            );

                            return (
                                <Flex
                                    key={slotIndex}
                                    css={{
                                        gridColumnStart,
                                        gridColumnEnd,
                                        gridRow: `${3 + index} / ${4 + index}`,
                                        py: '$2'
                                    }}
                                >
                                    {slot.type === 'assignment' ? (
                                        <Flex
                                            align="center"
                                            css={{
                                                background: slot.position.color,
                                                borderRadius: '$1',
                                                color: getColorByBackgroundColor(
                                                    slot.position.color
                                                ),
                                                fontSize: '$textXs',
                                                fontWeight: '$medium',
                                                px: '$2',
                                                zIndex: '20'
                                            }}
                                            height={1}
                                            width={1}
                                        >
                                            <Box
                                                css={{ ellipsis: true }}
                                                textAlign="center"
                                                title={slot.position.name}
                                                width={1}
                                            >
                                                {slot.position.name}
                                            </Box>
                                        </Flex>
                                    ) : (
                                        <Flex
                                            align="center"
                                            css={{
                                                background: 'white',
                                                border: '1px dashed $gray300',
                                                borderRadius: '$1',
                                                color: '$gray800',
                                                fontSize: '$textXs',
                                                fontWeight: '$medium',
                                                px: '$2',
                                                zIndex: '10'
                                            }}
                                            height={1}
                                            justify="center"
                                            width={1}
                                        >
                                            {translate('disponible_69523')}
                                        </Flex>
                                    )}
                                </Flex>
                            );
                        })}
                    </React.Fragment>
                );
            })}
        </Grid>
    );
};
