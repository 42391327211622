import * as React from 'react';
import { Avatar } from '../../../components/avatar/avatar';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { DocumentUserCustomBadgeFragment, Phone } from '../../../generated/types';
import { A4_SIZES } from '../../../util/pdf';
import { getFieldValue } from '../../../vo/userInfo';

interface IP2NAssignmentPageProps {
    children: React.ReactNode;
    event: DocumentUserCustomBadgeFragment;
    title: string;
}

export const P2NAssignmentPage = (props: IP2NAssignmentPageProps) => {
    const vr = props.event.volunteerRegistration;
    const ui = vr.userInfo;

    return (
        <Flex
            css={{
                background:
                    'url(https://assets.recrewteer.com/badges/p2n/assignment.jpg) no-repeat',
                backgroundSize: 'cover',
                pageBreakBefore: 'always',
                position: 'relative'
            }}
            direction="column"
            height={A4_SIZES['96dpi'].height}
            width={A4_SIZES['96dpi'].width}
        >
            <Box
                css={{
                    left: '48px',
                    position: 'absolute',
                    top: '47px'
                }}
            >
                <Avatar email={ui.nameOrEmail} image={ui.picture?.url} name={ui.name} size={40} />
            </Box>

            <Flex
                css={{
                    left: '104px',
                    position: 'absolute',
                    top: '45px'
                }}
                direction="column"
            >
                <Box
                    css={{
                        color: '$gray800',
                        fontSize: '16px',
                        fontWeight: '600'
                    }}
                >
                    {ui.name}
                </Box>

                <Box color="gray800">
                    {getFieldValue<Phone>(ui.fields, 'phone')?.internationalFormat || ''}
                </Box>
            </Flex>

            <Box
                css={{
                    color: 'black',
                    fontSize: '24px',
                    fontWeight: '700',
                    position: 'absolute',
                    top: '112px'
                }}
                textAlign="center"
                width={1}
            >
                {props.title}
            </Box>

            <Flex
                css={{
                    gap: '28px',
                    flex: '1',
                    left: '48px',
                    position: 'absolute',
                    top: '172px',
                    width: 'calc(100% - 96px)'
                }}
                direction="column"
            >
                {props.children}
            </Flex>
        </Flex>
    );
};
