export function parseFromString(str: string): Document {
    const stringWithoutHeader = str.replace(/<\?xml(.*)\?>/, '');
    const parser = new DOMParser();
    const result = parser.parseFromString(stringWithoutHeader, 'text/xml');

    if (result.querySelectorAll('parsererror').length !== 0) {
        throw new Error('Could not parse: ' + str);
    } else {
        return result;
    }
}
