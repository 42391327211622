import { TextInput } from 'common-front/src/designSystem/components/textInput';
import {
    EventId,
    SegmentCustomFieldFragment,
    VolunteerRegistrationFragment
} from 'common/src/generated/types';
import { useService } from 'common/src/util/dependencies/dependencies';
import { FieldService } from 'common/src/vo/field';
import { PossibleColumn } from 'common/src/vo/segment';
import * as React from 'react';
import { VolunteerRegistrationUpdateCell } from './volunteerRegistrationUpdateCell';

interface IVolunteerRegistrationTextCellProps {
    customField: SegmentCustomFieldFragment;
    eventId: EventId;
    field: PossibleColumn;
    volunteerRegistration: VolunteerRegistrationFragment;

    reload(): void;
}

export const VolunteerRegistrationTextCell = (props: IVolunteerRegistrationTextCellProps) => {
    const fieldService = useService(FieldService);
    const valueString = fieldService.getValueString(
        props.customField,
        props.volunteerRegistration.userInfo
    );

    return (
        <VolunteerRegistrationUpdateCell
            customField={props.customField}
            eventId={props.eventId}
            field={props.field}
            initialValue={valueString}
            reload={props.reload}
            valueString={valueString}
            volunteerRegistration={props.volunteerRegistration}
        >
            {(value, setValue) => (
                <TextInput label={props.customField.name} value={value} onChange={setValue} />
            )}
        </VolunteerRegistrationUpdateCell>
    );
};
