import { useHeavent } from 'common-front/src/hooks/useHeavent';
import * as React from 'react';
import { useOrganizationTranslationsStatsQuery } from '../../generated/graphqlHooks';
import { OrganizationTranslations } from './organizationTranslations';

export const OrganizationTranslationsLoader = () => {
    const {
        params: { organizationId }
    } = useHeavent();
    const { data, loader } = useOrganizationTranslationsStatsQuery({ organizationId });

    return loader ? loader : <OrganizationTranslations organization={data.organization} />;
};
