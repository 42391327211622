import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { UsersInfo } from 'common/src/generated/types';
import { Emptyable } from 'common/src/util/emptyable';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';

interface IPositionCreatedByCellProps {
    createdBy: Emptyable<Pick<UsersInfo, 'name' | 'email'>>;
}

export const PositionCreatedByCell = (props: IPositionCreatedByCellProps) => {
    const hasName = isNonEmptyString(props.createdBy?.name);
    const name = (hasName ? props.createdBy?.name : props.createdBy?.email) || '';

    return (
        <Cell>
            <Flex direction="column" width={1}>
                <Box css={{ ellipsis: true }} font="gray900 textSm medium" title={name} width={1}>
                    {name}
                </Box>

                {hasName && (
                    <Box
                        color="gray500"
                        css={{ ellipsis: true }}
                        title={props.createdBy?.email || ''}
                        width={1}
                    >
                        {props.createdBy?.email || ''}
                    </Box>
                )}
            </Flex>
        </Cell>
    );
};
