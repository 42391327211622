import { Button } from 'common-front/src/designSystem/components/button';
import { RadioText } from 'common-front/src/designSystem/form/radio';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { MassAssignStrategy } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';

interface IMassAccreditStep1Props {
    handleSubmit(): void;
}

export const MassAccreditStep1 = (props: IMassAccreditStep1Props) => {
    const translate = useTranslate();

    return (
        <>
            <Box font="gray900 textSm medium">{translate('que_souhaitez_v_87607')}</Box>

            <Spacer height="3" />

            <RadioText name="massAccredit.strategy" value={MassAssignStrategy.Add}>
                {translate('ajouter_des_acc_17744')}
            </RadioText>

            <Spacer height="3" />

            <RadioText name="massAccredit.strategy" value={MassAssignStrategy.Replace}>
                {translate('remplacer_les_a_92733')}
            </RadioText>

            <Spacer height="3" />

            <RadioText name="massAccredit.strategy" value={MassAssignStrategy.Delete}>
                {translate('supprimer_des_a_04246')}
            </RadioText>

            <Spacer height="7" />

            <Flex>
                <Button onClick={props.handleSubmit}>{translate('suivant_accr_25709')}</Button>
            </Flex>
        </>
    );
};
