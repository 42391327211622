import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { CommonEnvVars } from '../../../envVars';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { A4_SIZES } from '../../../util/pdf';
import { isNonEmptyString } from '../../../util/string';

interface IBadgeNuitsSecretesProps {
    event: DocumentUserCustomBadgeFragment;
}

export const BadgeNuitsSecretes = (props: IBadgeNuitsSecretesProps) => {
    const vr = props.event.volunteerRegistration;
    const ui = vr.userInfo;

    return (
        <Flex
            css={{
                background: `url(https://assets.recrewteer.com/badges/nuitssecretes/background.jpg) no-repeat`,
                backgroundSize: 'cover',
                fontFamily: '$poppins',
                position: 'relative'
            }}
            height={A4_SIZES['96dpi'].height}
            width={A4_SIZES['96dpi'].width}
        >
            <Box
                css={{
                    fontSize: '28px',
                    fontWeight: '700',
                    position: 'absolute',
                    textAlign: 'center',
                    top: '545px'
                }}
                width={1}
            >
                {ui.firstName}
            </Box>

            <Box
                css={{
                    fontSize: '28px',
                    fontWeight: '700',
                    position: 'absolute',
                    textAlign: 'center',
                    top: '584px'
                }}
                width={1}
            >
                {ui.lastName}
            </Box>

            <Flex
                align="center"
                css={{
                    position: 'absolute',
                    top: '654px'
                }}
                direction="column"
                gap="2"
                width={1}
            >
                <Box height={94} width={94}>
                    {isNonEmptyString(vr.weezeventParticipantInfo?.idBarcode) && (
                        <img
                            height="100%"
                            src={`https://api.${
                                CommonEnvVars.HEAVENT_EXTERNAL_DOMAIN
                            }/barcode/qr?text=${encodeURIComponent(
                                vr.weezeventParticipantInfo!.idBarcode
                            )}&color=000000&height=94&width=94`}
                            width="100%"
                        />
                    )}
                </Box>

                <Box css={{ fontSize: '10px' }}>{vr.weezeventParticipantInfo?.idBarcode ?? ''}</Box>
            </Flex>
        </Flex>
    );
};
