import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { styled } from 'common/src/designSystem/components/stitches';
import * as React from 'react';
import { PropsWithChildren } from 'react';

const BackArrow = styled(Flex, {
    margin: '0 $3',
    padding: '$3 $4'
});

export const FullPageHeader = ({ back, children }: PropsWithChildren<{ back: () => void }>) => (
    <Flex align="center" css={{ padding: '10px 0', borderBottom: '1px solid $gray100' }}>
        <BackArrow color="gray700" onClick={back}>
            <I icon={'chevron-left'} />
        </BackArrow>
        {children}
    </Flex>
);
