import { Button } from 'common-front/src/designSystem/components/button';
import { RadioText } from 'common-front/src/designSystem/form/radio';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { MassAssignStrategy, MassPreAssignLevel } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';

interface IMassPreAssignStep1Props {
    handleSubmit(): void;
}

export const MassPreAssignStep1 = (props: IMassPreAssignStep1Props) => {
    const translate = useTranslate();

    return (
        <>
            <Box font="gray900 textSm medium">{translate('que_souhaitez_v_87607')}</Box>

            <Spacer height="3" />

            <RadioText name="massPreAssign.strategy" value={MassAssignStrategy.Add}>
                {translate('ajouter_des_pr_46968')}
            </RadioText>

            <Spacer height="3" />

            <RadioText name="massPreAssign.strategy" value={MassAssignStrategy.Replace}>
                {translate('remplacer_les_p_59777')}
            </RadioText>

            <Spacer height="6" />

            <Box font="gray900 textSm medium">{translate('_quel_niveau_s_87878')}</Box>

            <Spacer height="3" />

            <RadioText name="massPreAssign.level" value={MassPreAssignLevel.Categories}>
                {translate('pr_affecter_su_68204')}
            </RadioText>

            <Spacer height="3" />

            <RadioText name="massPreAssign.level" value={MassPreAssignLevel.Positions}>
                {translate('pr_affecter_su_38603')}
            </RadioText>

            <Spacer height="7" />

            <Flex>
                <Button onClick={props.handleSubmit}>{translate('suivant_pr_a_99182')}</Button>
            </Flex>
        </>
    );
};
