import { CenteredContainer } from 'common-front/src/components/centeredContainer/centeredContainer';
import { Form } from 'common-front/src/components/form/form';
import { FormErrors } from 'common-front/src/components/form/formErrors';
import { FullScreenPopup } from 'common-front/src/components/fullScreenPopup/fullScreenPopup';
import { Button } from 'common-front/src/designSystem/components/button';
import { Flex } from 'common/src/designSystem/components/flex';
import { FilesImportInput } from 'common/src/generated/types';
import { FilesImportInputService } from 'common/src/input/filesImportInput';
import { ValidateService } from 'common/src/services/validateService';
import { useParams, useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { FormApi } from 'final-form';
import * as React from 'react';
import { ImportProcessingAlert } from '../../common/import/importProcessingAlert';
import {
    useFilesImportCustomFieldsQuery,
    useFilesImportMutation
} from '../../generated/graphqlHooks';
import { useNotificationContext } from '../../notifications/notificationContext';
import { ArchiveFormBox } from './archiveFormBox';
import { SelectFieldsFormBoxes } from './selectFieldsFormBoxes';

const isFileValid = (form: FormApi<{ input: FilesImportInputWithFile }>): boolean =>
    !!form.getFieldState('input.archive.key')?.valid &&
    !!form.getFieldState('input.archive.file')?.valid;

const removeFileFromInput = (
    input: FilesImportInput & {
        archive: {
            file?: File;
        };
    }
) => {
    const archiveCopy = { ...input.archive };

    delete archiveCopy.file;

    return {
        ...input,
        archive: archiveCopy
    };
};

type FilesImportInputWithFile = FilesImportInput & {
    archive: {
        file?: File;
    };
};

interface IFilesImportProps {
    fromPath: string;
}

export const FilesImport = ({ fromPath }: IFilesImportProps) => {
    const translate = useTranslate();
    const FilesImportInput = useService(FilesImportInputService);
    const validateService = useService(ValidateService);
    const centeredContainerRef = React.useRef<HTMLDivElement | null>(null);
    const { eventId, organizationId } = useParams();
    const { data, loader } = useFilesImportCustomFieldsQuery({ organizationId });
    const { mutate } = useFilesImportMutation();
    const { checkFilesImport } = useNotificationContext();

    if (loader) {
        return loader;
    } else {
        return (
            <Form
                height={1}
                hideErrors={true}
                initialValues={{
                    input: FilesImportInput.default().input as FilesImportInputWithFile
                }}
                render={({ form, handleSubmit, submitting, values }) => (
                    <FullScreenPopup
                        button={
                            <Button isLoading={submitting} onClick={handleSubmit}>
                                {translate('importer_la_lis_12866')}
                            </Button>
                        }
                        category={translate('importe_des_fic_85041')}
                        closePath={fromPath}
                        color="dark"
                        title={translate('nouvel_import_48306')}
                    >
                        <CenteredContainer ref={centeredContainerRef}>
                            {form.getState().submitSucceeded && <ImportProcessingAlert />}
                            <FormErrors />

                            <Flex direction="column" gap="5">
                                <ArchiveFormBox />

                                {values.input.archive?.file && isFileValid(form) && (
                                    <SelectFieldsFormBoxes
                                        customFileFields={data.organization.customFileFields.nodes}
                                    />
                                )}
                            </Flex>
                        </CenteredContainer>
                    </FullScreenPopup>
                )}
                validate={validateService.validateForForm(FilesImportInput.schema())}
                onShowErrors={() => {
                    if (centeredContainerRef.current) {
                        centeredContainerRef.current.scrollTop = 0;
                    }
                }}
                onSubmit={async ({ input }) => {
                    const inputWithoutFile = removeFileFromInput(input);

                    try {
                        const { jobId } = await mutate({
                            eventId,
                            organizationId,
                            input: inputWithoutFile
                        });
                        if (centeredContainerRef.current) {
                            centeredContainerRef.current.scrollTop = 0;
                        }
                        checkFilesImport(organizationId, jobId);
                    } catch (e) {
                        /* Already displayed in <FormErrors />. */
                    }
                }}
            />
        );
    }
};
