import * as React from 'react';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { BadgeElephantsBack } from './badgeElephantsBack';
import { BadgeElephantsFront } from './badgeElephantsFront';

interface IBadgeElephantsProps {
    event: DocumentUserCustomBadgeFragment;
}

export const BadgeElephants = (props: IBadgeElephantsProps) => (
    <>
        <BadgeElephantsFront event={props.event} />

        <BadgeElephantsBack />
    </>
);
