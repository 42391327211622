import { parseFromString } from './xml';

const parsePointNode = (point: Element): Array<[number, number]> => {
    const lat = parseFloat(point.getAttribute('lat')!);
    const lon = parseFloat(point.getAttribute('lon')!);

    if (isNaN(lat) || isNaN(lon)) {
        return [];
    } else {
        return [[lat, lon]];
    }
};

export function parseGpx(gpxString: string): Array<[number, number]> {
    const xml = parseFromString(gpxString);
    const trkpts = Array.from(xml.querySelectorAll('trk trkseg trkpt'));
    const wpts = Array.from(xml.querySelectorAll('wpt'));
    const rtepts = Array.from(xml.querySelectorAll('rte rtept'));

    if (trkpts.length > 0) {
        return trkpts.flatMap(parsePointNode);
    } else if (wpts.length > 0) {
        return wpts.flatMap(parsePointNode);
    } else {
        return rtepts.flatMap(parsePointNode);
    }
}
