import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { SegmentsGrid } from 'common-front/src/segments/segmentsGrid';
import {
    PositionsSegmentsQuery,
    SegmentCustomFieldFragment,
    SegmentId,
    SegmentType
} from 'common/src/generated/types';
import { useService } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { DEFAULT_PAGINATION_LIMIT } from 'common/src/vo/pagination';
import { PositionsSegmentsService } from 'common/src/vo/segments/positionsSegmentsService';
import * as React from 'react';
import { useEventContext } from '../../events/show/eventContext';
import { PositionsContent } from './positionsContent';
import { PositionsContextProvider } from './positionsContext';

interface IPositionsProps {
    customFields: SegmentCustomFieldFragment[];
    event: PositionsSegmentsQuery['event'];

    reload(): void;
}

export const Positions = (props: IPositionsProps) => {
    const {
        history,
        translate,
        params: { organizationId, eventId, segmentId }
    } = useHeavent();
    const { isEventAdmin, localEvent, updateLocalEvent } = useEventContext();
    const segmentService = useService(PositionsSegmentsService);
    const filters = React.useMemo(
        () => segmentService.getPositionsFilters(props.event, props.customFields),
        [props.event, props.customFields]
    );
    const possibleColumns = React.useMemo(
        () => segmentService.getPositionsPossibleColumns(props.customFields),
        [props.customFields]
    );

    return (
        <PositionsContextProvider eventId={eventId} organizationId={organizationId}>
            <SegmentsGrid
                eventId={eventId}
                filters={filters}
                filtersButtonText={translate('filtrer_les_mis_43681')}
                filtersSubtitle={translate('appliquer_des_f_16037')}
                getSegmentPath={(id: SegmentId) =>
                    HeaventPaths.POSITIONS_SEGMENT(organizationId, eventId, id)
                }
                hideSegments={props.event.numberOfPositions === 0}
                initialLimit={localEvent?.positionsLimit ?? DEFAULT_PAGINATION_LIMIT}
                initialSegmentsOpen={localEvent?.arePositionsSegmentsOpen ?? true}
                isAdmin={isEventAdmin}
                organizationId={organizationId}
                possibleColumns={possibleColumns}
                reload={props.reload}
                segmentId={segmentId}
                segmentType={SegmentType.Positions}
                segmentsFolders={props.event.segmentsFolders}
                setLimit={(positionsLimit) => {
                    updateLocalEvent({ positionsLimit });
                }}
                onSegmentClick={(selectedSegmentId) => {
                    updateLocalEvent({ positionsSegmentId: selectedSegmentId });
                }}
                onSegmentDelete={async () => {
                    await updateLocalEvent({ positionsSegmentId: null });

                    history.push(HeaventPaths.POSITIONS(organizationId, eventId));
                }}
                onSegmentsToggle={async (areSegmentsOpen) => {
                    await updateLocalEvent({ arePositionsSegmentsOpen: areSegmentsOpen });
                }}
            >
                <PositionsContent
                    customFields={props.customFields}
                    numberOfPositions={props.event.numberOfPositions}
                    positionsCategories={props.event.positionsCategories}
                />
            </SegmentsGrid>
        </PositionsContextProvider>
    );
};
