import { Button } from 'common-front/src/designSystem/components/button';
import { OrderRightPanel } from 'common-front/src/designSystem/components/orderRightPanel';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { SegmentCustomFieldFragment } from 'common/src/generated/types';
import { ICreateUpdateDelegationValues } from 'common/src/input/delegationInput';
import { useService } from 'common/src/util/dependencies/dependencies';
import { DelegationMembersSegmentsService } from 'common/src/vo/segments/delegationMembersSegmentsService';
import { compact, without } from 'lodash-es';
import * as React from 'react';

interface ICreateDelegationColumnsProps {
    customFields: SegmentCustomFieldFragment[];
    values: ICreateUpdateDelegationValues;

    change(name: string, value: any): void;
}

export const CreateDelegationColumns = (props: ICreateDelegationColumnsProps) => {
    const { translate } = useHeavent();
    const delegationMembersSegmentsService = useService(DelegationMembersSegmentsService);
    const [areColumnsOpen, setAreColumnsOpen] = React.useState(false);
    // forEvent is always true because we want to be able to create community delegations
    // with accreditations and missions columns even if those columns are not used in the community
    // they can be used by children delegations
    const possibleColumns = React.useMemo(
        () =>
            delegationMembersSegmentsService.getDelegationMembersPossibleColumns(
                props.customFields,
                { forEvent: true }
            ),
        [delegationMembersSegmentsService, props.customFields]
    );
    const columns = React.useMemo(
        () =>
            compact(
                props.values.delegation.columns.map((slug) =>
                    possibleColumns.find((c) => c.slug === slug)
                )
            ),
        [possibleColumns, props.values.delegation]
    );

    return (
        <>
            <Flex direction="column" gap="2" width={1}>
                <Box font="gray800 textSm semiBold">{translate('tableau_des_mem_70038')}</Box>

                <Flex
                    css={{
                        border: '1px solid $gray200',
                        borderRadius: '$2',
                        boxShadow: '$xs',
                        overflow: 'hidden'
                    }}
                    direction="column"
                >
                    <Flex
                        css={{
                            background: '$gray50',
                            borderBottom: '1px solid $gray200',
                            color: '$gray500',
                            padding: '$3 $6'
                        }}
                    >
                        {translate('liste_des_colon_20101')}
                    </Flex>

                    <Flex
                        css={{
                            padding: '$4'
                        }}
                        direction="column"
                        gap="4"
                    >
                        <Flex direction="column" gap="2">
                            {columns.map((column) => (
                                <Flex
                                    key={column.slug}
                                    align="center"
                                    css={{
                                        border: '1px solid $gray200',
                                        borderRadius: '$1',
                                        overflow: 'hidden',
                                        padding: '$3'
                                    }}
                                    gap="3"
                                >
                                    <Box color="gray700" css={{ flex: '1' }}>
                                        {column.name}
                                    </Box>

                                    <Button
                                        color="white"
                                        leftIcon="trash-can"
                                        size="sm"
                                        onClick={() => {
                                            props.change(
                                                'delegation.columns',
                                                without(
                                                    props.values.delegation.columns,
                                                    column.slug
                                                )
                                            );
                                        }}
                                    />
                                </Flex>
                            ))}
                        </Flex>

                        <Button
                            color="invisible"
                            leftIcon="gear"
                            onClick={() => {
                                setAreColumnsOpen(true);
                            }}
                        >
                            {translate('_diter_les_colo_39630')}
                        </Button>
                    </Flex>
                </Flex>
            </Flex>

            {areColumnsOpen && (
                <OrderRightPanel
                    columns={possibleColumns}
                    initialSelectedColumns={props.values.delegation.columns}
                    saveButtonText={translate('_diter_62574')}
                    searchPlaceholder={translate('rechercher_un_c_86475')}
                    subtitle={translate('personnaliser_l_96096')}
                    title={translate('_dition_des_col_70300')}
                    onClose={() => {
                        setAreColumnsOpen(false);
                    }}
                    onSave={(newColumns: string[]) => {
                        props.change('delegation.columns', newColumns);
                    }}
                />
            )}
        </>
    );
};
