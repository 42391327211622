/* eslint-disable react/no-unused-prop-types */
import { maxBy, minBy, range as lodashRange } from 'lodash-es';
import { DateTime, Interval } from 'luxon';
import * as React from 'react';
import { Flex } from '../../designSystem/components/flex';
import { DocumentUserPlanningFragment } from '../../generated/types';
import { isNonEmptyArray } from '../../util/array';
import { splitAt } from '../../util/interval';
import { parseTime } from '../../util/time';
import { UserPlanningCalendar } from './userPlanningCalendar';
import { UserPlanningHeader } from './userPlanningHeader';

const NUMBER_OF_DAYS_PER_PAGE = 3;

interface IUserPlanningProps {
    date?: DateTime;
    endDate?: DateTime;
    event: DocumentUserPlanningFragment;
}

const UserPlanningEmpty = (props: IUserPlanningProps) => (
    <Flex
        css={{
            background: 'white',
            pageBreakBefore: 'always'
        }}
        direction="column"
        width={1}
    >
        <UserPlanningHeader
            nameOrEmail={props.event.volunteerRegistration.userInfo.nameOrEmail}
            numberOfAssignments={0}
        />
    </Flex>
);

const UserPlanningComponent = (props: IUserPlanningProps) => {
    const [startHour] = React.useMemo(
        () => parseTime(props.event.dayStartTime)!,
        [props.event.dayStartTime]
    );
    const assignments = React.useMemo(
        () =>
            splitAt(
                props.event.volunteerRegistration.positionsSlotsUsersInfos.map(
                    ({ position, positionSlot }) => ({
                        name: position.name,
                        range: positionSlot.range
                    })
                ),
                props.event.startAt,
                props.event.endAt,
                startHour
            )
                .map((interval) => ({
                    ...interval,
                    rangeMinusStart: interval.range.mapEndpoints((d) =>
                        d.minus({ hour: startHour })
                    )
                }))
                .filter((s) => {
                    if (props.date?.isValid && props.endDate?.isValid) {
                        const interval = Interval.fromDateTimes(
                            props.date.startOf('day'),
                            props.endDate.endOf('day')
                        );

                        return s.range.overlaps(interval);
                    } else if (props.date?.isValid) {
                        return s.range.start!.startOf('day').equals(props.date.startOf('day'));
                    } else {
                        return true;
                    }
                }),
        [props.event, startHour]
    );

    if (isNonEmptyArray(assignments)) {
        const ranges = assignments.map(({ range }) => range);
        const rangesMinusStart = assignments.map(({ rangeMinusStart }) => rangeMinusStart);
        const minHour =
            startHour === 0 ? Math.min(minBy(ranges.map((r) => r.start!.hour))!, 8) : startHour;
        const numberOfSlots = startHour === 0 ? (24 - minHour) / 2 : 12;
        const startDate = minBy(
            rangesMinusStart.map((r) => r.start!),
            (d) => d.toMillis()
        )!.startOf('day');
        const endDate = maxBy(
            rangesMinusStart.map((r) => r.end!),
            (d) => d.toMillis()
        )!.endOf('day');
        const numberOfDays = Math.ceil(endDate.diff(startDate, 'days').days);

        const stats = {
            startDate,
            endDate,
            minHour,
            numberOfSlots,
            numberOfDays
        };

        return (
            <>
                {lodashRange(0, stats.numberOfDays, NUMBER_OF_DAYS_PER_PAGE).map((day, index) => {
                    const startDate = stats.startDate.plus({ day });
                    const endDate = startDate
                        .plus({ day: NUMBER_OF_DAYS_PER_PAGE - 1 })
                        .endOf('day');
                    const finalEndDate = endDate > stats.endDate ? stats.endDate : endDate;
                    const interval = Interval.fromDateTimes(startDate, finalEndDate);
                    const daysAssignments = assignments.filter((assignment) =>
                        assignment.rangeMinusStart.overlaps(interval)
                    );

                    return (
                        <Flex
                            key={index}
                            css={{
                                background: 'white',
                                pageBreakBefore: index !== 0 ? 'always' : undefined
                            }}
                            direction="column"
                            width={1}
                        >
                            <UserPlanningHeader
                                interval={interval}
                                nameOrEmail={props.event.volunteerRegistration.userInfo.nameOrEmail}
                                numberOfAssignments={daysAssignments.length}
                            />

                            <UserPlanningCalendar
                                assignments={daysAssignments}
                                endDate={finalEndDate}
                                numberOfSlots={stats.numberOfSlots}
                                startDate={startDate}
                                startHour={stats.minHour}
                            />
                        </Flex>
                    );
                })}
            </>
        );
    } else {
        return <UserPlanningEmpty {...props} />;
    }
};

export const UserPlanning = (props: IUserPlanningProps) => {
    if (isNonEmptyArray(props.event.volunteerRegistration.positionsSlotsUsersInfos)) {
        return <UserPlanningComponent {...props} />;
    } else {
        return <UserPlanningEmpty {...props} />;
    }
};
