export enum UserDocumentType {
    Assignments = 'Assignments',
    Badge = 'Badge',
    Certificate = 'Certificate',
    UserPlanning = 'UserPlanning',
    UserPlanningDays = 'UserPlanningDays',
    UserPlanningList = 'UserPlanningList'
}

export const ALL_USER_DOCUMENT_TYPE = [
    UserDocumentType.Assignments,
    UserDocumentType.Badge,
    UserDocumentType.Certificate,
    UserDocumentType.UserPlanning,
    UserDocumentType.UserPlanningDays,
    UserDocumentType.UserPlanningList
];
