import * as React from 'react';

interface IQueryLoadingProps {
    children?: React.ReactNode;
    loader: JSX.Element | null;

    onLoad?(): void;
}

export const QueryLoading = (props: IQueryLoadingProps) => {
    React.useEffect(() => {
        if (!props.loader && props.onLoad) {
            props.onLoad();
        }
    }, [props.loader]);

    if (props.loader) {
        return props.loader;
    } else {
        return <>{props.children || <div />}</>;
    }
};
