import { DateTime } from 'luxon';
import { Feature, Organization } from '../generated/types';

// 1033: Weezevent Staffing
// 1787: Weezevent Switzerland
// 1538: Weezevent Canada
export const WEEEVENT_ORGANIZATIONS_IDS = [1033, 1538, 1787];

const TRIAL_DAYS = 45;

export function hasFeature(organization: Pick<Organization, 'features'>, feature: Feature) {
    return organization.features.includes(feature);
}

export function remainingDays(organization: Pick<Organization, 'periodEndAt'>): number {
    return Math.ceil(organization.periodEndAt.diff(DateTime.utc(), 'days').days);
}

export function showTrial(days: number): boolean {
    return days > 0 && days <= TRIAL_DAYS;
}
