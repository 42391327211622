import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { theme } from 'common/src/designSystem/components/stitches';
import { FilterPredicate } from 'common/src/vo/segment';
import * as React from 'react';
import { Button } from '../../designSystem/components/button';
import { filterTypeToIcon } from '../../vo/customField';

interface IFiltersListProps {
    filtersPredicates: FilterPredicate[];
    isEdit: boolean;

    onAdd?(filterPredicate: FilterPredicate): void;
    onEdit?(filterPredicate: FilterPredicate): void;
    onDelete?(slug: string): void;
}

export const FiltersList = (props: IFiltersListProps) => (
    <Flex
        css={{
            border: '1px solid $gray200',
            borderRadius: '$1',
            boxShadow: '$xs',
            px: '$4',
            py: '$1'
        }}
        direction="column"
    >
        {props.filtersPredicates.map((filterPredicate) => {
            const { filter } = filterPredicate;

            return (
                <Flex
                    key={filter.slug}
                    align="center"
                    css={{
                        py: '$3',
                        '&:not(:last-child)': {
                            borderBottom: '1px solid $gray200'
                        }
                    }}
                    gap="3"
                >
                    <Flex
                        align="center"
                        css={{
                            borderRadius: '$1',
                            background: theme.colors[`${filter.color}100`].value,
                            color: theme.colors[`${filter.color}700`].value,
                            height: '32px',
                            width: '32px'
                        }}
                        justify="center"
                    >
                        <I icon={filterTypeToIcon(filter.fieldType)} />
                    </Flex>

                    <Box
                        color="gray900"
                        css={{
                            ellipsis: '',
                            flex: '1'
                        }}
                        fontWeight="medium"
                        title={filter.name}
                    >
                        {filter.name}
                    </Box>

                    {props.isEdit ? (
                        <>
                            <Button
                                color="white"
                                leftIcon="pen"
                                size="sm"
                                onClick={() => {
                                    props.onEdit!(filterPredicate);
                                }}
                            />

                            <Button
                                color="white"
                                leftIcon="trash-can"
                                size="sm"
                                onClick={() => {
                                    props.onDelete!(filter.slug);
                                }}
                            />
                        </>
                    ) : (
                        <Button
                            color="white"
                            leftIcon="plus"
                            size="sm"
                            onClick={() => {
                                props.onAdd!(filterPredicate);
                            }}
                        />
                    )}
                </Flex>
            );
        })}
    </Flex>
);
