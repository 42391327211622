import * as React from 'react';
import Calendar from 'svgComponents/fontawesome/calendar.svg';
import LocationDot from 'svgComponents/fontawesome/locationDot.svg';
import Sitemap from 'svgComponents/fontawesome/sitemap.svg';
import Tag from 'svgComponents/fontawesome/tag.svg';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { RichText } from '../../../designSystem/components/richEditor/richText';
import { Spacer } from '../../../designSystem/components/spacer';
import {
    AsoassignmentDocumentOptions,
    DocumentUserCustomBadgeFragment,
    EventId
} from '../../../generated/types';
import { DateTimeService } from '../../../services/dateTimeService';
import { isNonEmptyArray } from '../../../util/array';
import { getColorByBackgroundColor } from '../../../util/color';
import { useEnvVars, useService } from '../../../util/dependencies/dependencies';
import { googleMapsHref } from '../../../util/googleMaps';
import { LocaleFormats } from '../../../util/luxon';
import { A4_SIZES } from '../../../util/pdf';
import { isNonEmptyString } from '../../../util/string';

interface IAsoAssignmentPage1Props {
    eventId: EventId;
    options: AsoassignmentDocumentOptions;
    positionSlotUserInfo: DocumentUserCustomBadgeFragment['volunteerRegistration']['positionsSlotsUsersInfos'][0];
    userInfo: DocumentUserCustomBadgeFragment['volunteerRegistration']['userInfo'];
}

export const AsoAssignmentPage1 = (props: IAsoAssignmentPage1Props) => {
    const { HEAVENT_EXTERNAL_DOMAIN } = useEnvVars();
    const dateTimeService = useService(DateTimeService);
    const position = props.positionSlotUserInfo.position;
    const positionSlot = props.positionSlotUserInfo.positionSlot;
    const textColor = getColorByBackgroundColor(props.options.color);

    return (
        <Flex
            css={{
                background: 'white',
                fontFamily: '$default'
            }}
            direction="column"
            height={A4_SIZES['96dpi'].height}
            width={A4_SIZES['96dpi'].width}
        >
            <Flex
                align="center"
                css={{
                    px: '60px',
                    py: '18px'
                }}
                height={92}
                justify="between"
                width={1}
            >
                <Flex
                    css={{
                        height: '56px',
                        '& > img': {
                            height: '100%',
                            width: 'auto'
                        }
                    }}
                >
                    {isNonEmptyString(props.options.logo?.content) && (
                        <img src={props.options.logo.content} />
                    )}
                </Flex>

                <Flex
                    align="center"
                    css={{
                        background: props.options.color,
                        borderRadius: '$1',
                        color: textColor,
                        fontSize: '28px',
                        fontWeight: '$bold',
                        px: '$6'
                    }}
                    height={44}
                >
                    Fiche d&apos;affectation
                </Flex>
            </Flex>

            <Flex
                css={{
                    background: isNonEmptyString(props.options.background?.content)
                        ? `url(${props.options.background.content}) no-repeat`
                        : '$gray50',
                    backgroundSize: 'cover',
                    flex: '1',
                    padding: '32px 56px 0 56px'
                }}
                width={1}
            >
                <Flex
                    css={{
                        borderTopLeftRadius: '10px',
                        borderTopRightRadius: '10px',
                        background: 'white',
                        padding: '$7'
                    }}
                    direction="column"
                    height={1}
                    width={1}
                >
                    <Flex justify="between" width={1}>
                        <Box css={{ lineHeight: '28px' }} font="gray800 displayXs bold">
                            {props.userInfo.firstName}
                            <br />
                            {props.userInfo.lastName}
                        </Box>

                        <Flex align="end" direction="column">
                            <Box
                                css={{
                                    color: props.options.color,
                                    fontSize: '22px',
                                    fontWeight: '600',
                                    textTransform: 'capitalize'
                                }}
                            >
                                {dateTimeService.toLocaleString(
                                    positionSlot.range!.start!,
                                    LocaleFormats.DateOnly.WeekdayLongMonthLong
                                )}
                            </Box>

                            <Box fontSize="textMd" fontWeight="medium">
                                {position.name}
                            </Box>
                        </Flex>
                    </Flex>

                    <Spacer height="7" />

                    {isNonEmptyString(props.options.introText) && (
                        <>
                            <Box
                                color="gray800"
                                css={{
                                    '& .h-rich-text > p': {
                                        margin: '0'
                                    }
                                }}
                            >
                                <RichText text={props.options.introText} />
                            </Box>

                            <Spacer height="6" />
                        </>
                    )}

                    <Flex
                        css={{
                            border: '1px solid $gray200',
                            borderRadius: '$1',
                            padding: '$4'
                        }}
                        gap="6"
                        height={280}
                    >
                        <Flex direction="column" gap="4" width={1}>
                            <Flex align="center" gap="2">
                                <Box
                                    css={{
                                        svg: {
                                            fill: '$gray700',
                                            height: '100%',
                                            width: '100%'
                                        }
                                    }}
                                    height={16}
                                    width={16}
                                >
                                    <Sitemap />
                                </Box>

                                <Flex css={{ flex: '1' }} direction="column">
                                    <Box color="gray800" fontWeight="semiBold">
                                        Mission affectée
                                    </Box>

                                    <Box color="gray800">{position.name}</Box>
                                </Flex>
                            </Flex>

                            <Flex align="center" gap="2">
                                <Box
                                    css={{
                                        svg: {
                                            fill: '$gray700',
                                            height: '100%',
                                            width: '100%'
                                        }
                                    }}
                                    height={16}
                                    width={16}
                                >
                                    <Calendar />
                                </Box>

                                <Flex css={{ flex: '1' }} direction="column">
                                    <Box color="gray800" fontWeight="semiBold">
                                        Heure de la mission
                                    </Box>

                                    <Box color="gray800">
                                        De{' '}
                                        {dateTimeService.toLocaleString(
                                            positionSlot.range.start!,
                                            LocaleFormats.TimeOnly
                                        )}{' '}
                                        à{' '}
                                        {dateTimeService.toLocaleString(
                                            positionSlot.range.end!,
                                            LocaleFormats.TimeOnly
                                        )}
                                    </Box>
                                </Flex>
                            </Flex>

                            <Flex align="center" gap="2">
                                <Box
                                    css={{
                                        svg: {
                                            fill: '$gray700',
                                            height: '100%',
                                            width: '100%'
                                        }
                                    }}
                                    height={16}
                                    width={16}
                                >
                                    <LocationDot />
                                </Box>

                                <Flex css={{ flex: '1' }} direction="column">
                                    <Box color="gray800" fontWeight="semiBold">
                                        Lieu de rendez-vous
                                    </Box>

                                    <Box color="gray800">{position.address}</Box>
                                </Flex>
                            </Flex>

                            {isNonEmptyString(position.fields.cf24181) && (
                                <Flex align="center" gap="2">
                                    <Box
                                        css={{
                                            svg: {
                                                fill: '$gray700',
                                                height: '100%',
                                                width: '100%'
                                            }
                                        }}
                                        height={16}
                                        width={16}
                                    >
                                        <Tag />
                                    </Box>

                                    <Flex css={{ flex: '1' }} direction="column">
                                        <Box color="gray800" fontWeight="semiBold">
                                            Transport en commun
                                        </Box>

                                        <Box color="gray800">{position.fields.cf24181}</Box>
                                    </Flex>
                                </Flex>
                            )}
                        </Flex>

                        <a
                            href={googleMapsHref(position.latitude!, position.longitude!)}
                            style={{
                                background: `url(https://api.${HEAVENT_EXTERNAL_DOMAIN}/image/event/${props.eventId}/position/${position.id}.png)`,
                                backgroundPosition: 'center',
                                backgroundSize: 'cover',
                                display: 'block',
                                height: '100%',
                                width: '100%'
                            }}
                        />
                    </Flex>

                    <Spacer height="4" />

                    {isNonEmptyString(position.description) && (
                        <>
                            <Box
                                color="gray800"
                                css={{
                                    '& .h-rich-text > p': {
                                        margin: '0'
                                    }
                                }}
                            >
                                <RichText text={position.description} />
                            </Box>

                            <Spacer height="4" />
                        </>
                    )}

                    {isNonEmptyArray(position.leaders) && (
                        <>
                            <Box color="gray800">
                                Merci de te présenter avec cette fiche d&apos;affectation auprès de
                                ton responsable sur place :
                            </Box>

                            <Spacer height="2" />

                            <Box
                                css={{
                                    color: props.options.color
                                }}
                                fontSize="textMd"
                                fontWeight="bold"
                                textAlign="center"
                                width={1}
                            >
                                {position.leaders[0].userInfo.name} :{' '}
                                {position.leaders[0].userInfo.phone?.internationalFormat}
                            </Box>

                            <Spacer height="4" />
                        </>
                    )}

                    <Box css={{ flex: '1' }} />

                    {isNonEmptyString(props.options.outroText) && (
                        <Box
                            color="gray800"
                            css={{
                                '& .h-rich-text > p': {
                                    margin: '0'
                                }
                            }}
                        >
                            <RichText text={props.options.outroText} />
                        </Box>
                    )}

                    {isNonEmptyString(props.options.signature) && (
                        <>
                            <Spacer height="7" />

                            <Box
                                color="gray800"
                                css={{
                                    '& .h-rich-text > p': {
                                        margin: '0'
                                    }
                                }}
                            >
                                <RichText text={props.options.signature} />
                            </Box>
                        </>
                    )}
                </Flex>
            </Flex>

            <Flex
                css={{
                    background: props.options.color,
                    color: textColor,
                    fontSize: '10px',
                    fontWeight: '600',
                    py: '$3',
                    textAlign: 'center'
                }}
                height={64}
                justify="center"
                width={1}
            >
                Paris Athlétisme Compétition - Cub Marathon
                <br />
                Quai Ouest, 40/42 Quai du Point du Jour - 92100 Boulogne Billancourt
                <br />
                Tél : 01.41.33.46.67 / contact@partagetapassion.com
            </Flex>
        </Flex>
    );
};
