import { AvatarWithState } from 'common/src/components/avatarWithState/avatarWithState';
import { Box } from 'common/src/designSystem/components/box';
import { styled } from 'common/src/designSystem/components/stitches';
import { UserInfoMobileQuery } from 'common/src/generated/types';
import * as React from 'react';

const Name = styled(Box, {
    lineHeight: '28px',
    marginTop: '$3',
    maxWidth: '100%',
    ellipsis: ''
});

const Email = styled(Box, {
    lineHeight: '20px',
    maxWidth: '100%',
    ellipsis: ''
});

interface IUserTopInfoProps {
    registration: UserInfoMobileQuery['event']['volunteerRegistration'];
}

export const UserTopInfo = ({ registration }: IUserTopInfoProps) => (
    <>
        <AvatarWithState
            email={registration.userInfo.email}
            image={registration.userInfo.picture?.url}
            name={registration.userInfo.name}
            registrationState={registration.state}
            size={64}
        />
        <Name color="gray800" fontSize="textLg" fontWeight="semiBold">
            {registration.userInfo.name}
        </Name>
        <Email color="gray500" fontSize="textSm">
            {registration.userInfo.email}
        </Email>
    </>
);
