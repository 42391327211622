import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useSegmentsContext } from 'common-front/src/segments/segmentsContext';
import { Box } from 'common/src/designSystem/components/box';
import { Link } from 'common/src/designSystem/components/link';
import { DelegationProperty, DelegationsDelegationFragment } from 'common/src/generated/types';
import * as React from 'react';
import { useDelegationsContext } from '../delegationsContext';
import { DelegationsUpdateCell } from './delegationsUpdateCell';

interface IDelegationsNameCellProps {
    delegation: DelegationsDelegationFragment;

    reload(): void;
}

export const DelegationsNameCell = (props: IDelegationsNameCellProps) => {
    const { translate } = useHeavent();
    const { isEditMode } = useSegmentsContext();
    const { getShowPath } = useDelegationsContext();
    const css = isEditMode
        ? { ellipsis: true }
        : {
              cursor: 'pointer',
              ellipsis: true,
              '&:hover': {
                  textDecoration: 'underline'
              }
          };

    return (
        <DelegationsUpdateCell
            delegation={props.delegation}
            initialValue={props.delegation.name}
            property={DelegationProperty.Name}
            reload={props.reload}
            renderInput={(value, setValue) => (
                <TextInput
                    label={translate('nom_de_la_d_l_g_28087')}
                    value={value}
                    onChange={setValue}
                />
            )}
        >
            {!isEditMode ? (
                <Link title={props.delegation.name} to={getShowPath(props.delegation.id)}>
                    <Box css={css} font="gray900 textSm medium" width={1}>
                        {props.delegation.name}
                    </Box>
                </Link>
            ) : (
                <Box css={css} font="gray900 textSm medium" title={props.delegation.name} width={1}>
                    {props.delegation.name}
                </Box>
            )}
        </DelegationsUpdateCell>
    );
};
