import { useHistory, useParams } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import { UserSheetMobile } from '../../../users/mobile/show/userSheetMobile';

export const VolunteersSearchUserSheetMobile = () => {
    const { organizationId, eventId, userInfoId } = useParams();
    const history = useHistory();
    const onClose = () => {
        history.push(HeaventPaths.VOLUNTEERS_SEARCH(organizationId, eventId));
    };
    const basePath = HeaventPaths.VOLUNTEERS_SEARCH_USER(organizationId, eventId, userInfoId);

    return <UserSheetMobile basePath={basePath} onClose={onClose} />;
};
