import { Form } from 'common-front/src/components/form/form';
import { FormErrors } from 'common-front/src/components/form/formErrors';
import { Button } from 'common-front/src/designSystem/components/button';
import { Modal } from 'common-front/src/designSystem/components/modal';
import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { CheckboxText } from 'common-front/src/designSystem/form/checkbox';
import { useStateDebounce } from 'common-front/src/hooks/useStateDebounce';
import { Images } from 'common-front/src/util/assets';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { EventId, OrganizationId, UsersInfoId } from 'common/src/generated/types';
import {
    IVolunteersRegistrationsAdminAddValues,
    VolunteerRegistrationInputService
} from 'common/src/input/volunteerRegistrationInput';
import { ValidateService } from 'common/src/services/validateService';
import { toggle } from 'common/src/util/array';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { useVolunteersRegistrationsAdminAddMutation } from '../../generated/graphqlHooks';
import { ExistingVolunteers } from './existingVolunteers';

interface IAddExistingVolunteersProps {
    organizationId: OrganizationId;
    eventId: EventId;

    onClose(): void;
    reload(): void;
}

export const AddExistingVolunteers = (props: IAddExistingVolunteersProps) => {
    const translate = useTranslate();
    const volunteerRegistrationInput = useService(VolunteerRegistrationInputService);
    const validateService = useService(ValidateService);
    const [email, emailDebounced, setEmail] = useStateDebounce('');
    const { mutate, isLoading } = useVolunteersRegistrationsAdminAddMutation();

    return (
        <Form
            hideErrors={true}
            initialValues={{
                usersInfosIds: [],
                sendVolunteerRegistrationEmail: false
            }}
            render={({ form, handleSubmit, values }) => {
                const changeUsersInfosIds = (userInfoId: UsersInfoId) => {
                    form.change('usersInfosIds', toggle(values.usersInfosIds, userInfoId));
                };

                return (
                    <Modal size="md" onClose={props.onClose}>
                        <Box color="gray800" css={{ height: '56px', textAlign: 'center' }}>
                            <img alt="Avatars" height="100%" src={Images.AvatarGroup} />
                        </Box>

                        <Spacer height="6" />

                        <Box
                            color="gray800"
                            fontSize="textLg"
                            fontWeight="medium"
                            textAlign="center"
                        >
                            {translate('ajouter_un_memb_33261')}
                        </Box>

                        <Spacer height="1" />

                        <Box color="gray500" textAlign="center">
                            {translate('ajouter_un_ou_d_44771')}
                        </Box>

                        <Spacer height="6" />

                        <TextInput
                            icon="magnifying-glass"
                            placeholder={translate('rechercher_un_m_05904')}
                            value={email}
                            onChange={setEmail}
                        />

                        <Spacer height="6" />

                        <FormErrors />

                        <Flex css={{ flex: '1', overflowY: 'auto' }} direction="column">
                            <ExistingVolunteers
                                eventId={props.eventId}
                                nameOrEmail={emailDebounced}
                                organizationId={props.organizationId}
                                select={changeUsersInfosIds}
                                usersInfosIds={values.usersInfosIds}
                            />
                        </Flex>

                        <Spacer height="7" />

                        <Flex align="center" gap="3" justify="end">
                            <CheckboxText name="sendVolunteerRegistrationEmail">
                                {translate('envoyer_l_e_mai_35622')}
                            </CheckboxText>

                            <Button isLoading={isLoading} onClick={handleSubmit}>
                                {translate('ajouter_97327')}
                            </Button>

                            <Button color="white" onClick={props.onClose}>
                                {translate('annuler_48254')}
                            </Button>
                        </Flex>
                    </Modal>
                );
            }}
            validate={validateService.validateForForm(volunteerRegistrationInput.adminAddSchema())}
            onSubmit={async (values: IVolunteersRegistrationsAdminAddValues) => {
                await mutate({
                    eventId: props.eventId,
                    usersInfosIds: values.usersInfosIds,
                    sendVolunteerRegistrationEmail: values.sendVolunteerRegistrationEmail
                });

                props.reload();
                props.onClose();
            }}
        />
    );
};
