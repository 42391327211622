import { DetailBox } from 'common-front/src/designSystem/components/detailBox';
import { Separator } from 'common-front/src/designSystem/components/separator';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { fromIconVO, I } from 'common/src/designSystem/components/i';
import { Accreditation } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';

interface IAccreditationsSlotParametersCardProps {
    accreditationCategoryName: string;
    accreditationName?: string;
    color: Accreditation['color'];
    icon: Accreditation['icon'];
}

export const AccreditationsSlotParametersCard = ({
    accreditationCategoryName,
    accreditationName,
    color,
    icon
}: IAccreditationsSlotParametersCardProps) => {
    const translate = useTranslate();

    return (
        <DetailBox
            css={{
                flex: '2 1'
            }}
            title={translate('param_tres_78420')}
        >
            <Flex direction="column" gap="4" width={1}>
                <Flex gap="3" justify="between">
                    <Box color="gray500">{translate('cat_gorie_00291')}</Box>

                    <Box color="gray800" fontWeight="medium">
                        {accreditationCategoryName}
                    </Box>
                </Flex>

                {accreditationName && (
                    <>
                        <Separator direction="horizontal" />

                        <Flex gap="3" justify="between">
                            <Box color="gray500">{translate('Accreditation')}</Box>

                            <Box color="gray800" fontWeight="medium">
                                {accreditationName}
                            </Box>
                        </Flex>
                    </>
                )}

                <Separator direction="horizontal" />

                <Flex gap="3" justify="between">
                    <Box color="gray500">{translate('Color')}</Box>

                    <Box
                        color="gray800"
                        css={{
                            background: color,
                            borderRadius: '20px',
                            height: '20px',
                            width: '20px'
                        }}
                    />
                </Flex>

                <Separator direction="horizontal" />

                <Flex gap="3" justify="between">
                    <Box color="gray500">{translate('ic_ne_55554')}</Box>

                    <Box color="gray800">
                        <I icon={fromIconVO(icon)} />
                    </Box>
                </Flex>
            </Flex>
        </DetailBox>
    );
};
