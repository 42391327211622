import { TableFilters } from 'common-front/src/designSystem/components/tableFilters';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { useParams, useTranslate } from 'common/src/util/dependencies/dependencies';
import { noop } from 'lodash-es';
import * as React from 'react';
import { useInvoicesQuery } from '../../generated/graphqlHooks';
import { InvoiceRow } from './invoiceRow';

export const Invoices = () => {
    const translate = useTranslate();
    const { organizationId } = useParams();
    const { data, loader } = useInvoicesQuery({ organizationId });

    return (
        loader || (
            <Flex css={{ overflowY: 'auto', padding: '$7' }} direction="column" height={1}>
                <Box color="gray900" fontSize="displayXs" fontWeight="semiBold">
                    {translate('factures_03552')}
                </Box>

                <Spacer height="7" />

                <TableFilters
                    filters={<></>}
                    headerCells={
                        <>
                            <HeaderCell>{translate('num_ro_de_factu_68150')}</HeaderCell>
                            <HeaderCell>{translate('Date')}</HeaderCell>
                            <HeaderCell>{translate('montant_55595')}</HeaderCell>
                            <HeaderCell>{translate('status_06428')}</HeaderCell>
                            <HeaderCell />
                        </>
                    }
                    numberOfPages={1}
                    offset={0}
                    rows={
                        <>
                            {data.organization.invoices.map((invoice, index) => (
                                <InvoiceRow
                                    key={index}
                                    invoice={invoice}
                                    isBackoffice={false}
                                    showColumn={() => true}
                                />
                            ))}
                        </>
                    }
                    setOffset={noop}
                    title={translate('factures_03552')}
                    totalCount={0}
                />
            </Flex>
        )
    );
};
