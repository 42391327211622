import { Flex } from 'common/src/designSystem/components/flex';
import { Skeleton } from 'common/src/designSystem/components/skeleton';
import { Spacer } from 'common/src/designSystem/components/spacer';
import * as React from 'react';

export const UserOverlayHeaderSkeleton = () => (
    <Flex align="center" direction="column">
        <Skeleton size={96} variant="circular" />

        <Spacer height="6" />

        <Skeleton borderRadius="$1" height={34} width={0.5} />

        <Spacer height="1" />

        <Skeleton borderRadius="$1" height={20} width={0.5} />
    </Flex>
);
