import { DateInput } from 'common-front/src/designSystem/components/date/dateInput';
import {
    EventId,
    SegmentCustomFieldFragment,
    VolunteerRegistrationFragment
} from 'common/src/generated/types';
import { useService } from 'common/src/util/dependencies/dependencies';
import { isNonEmptyString } from 'common/src/util/string';
import { FieldService } from 'common/src/vo/field';
import { PossibleColumn } from 'common/src/vo/segment';
import { DateTime } from 'luxon';
import * as React from 'react';
import { VolunteerRegistrationUpdateCell } from './volunteerRegistrationUpdateCell';

interface IVolunteerRegistrationDateCellProps {
    customField: SegmentCustomFieldFragment;
    eventId: EventId;
    field: PossibleColumn;
    volunteerRegistration: VolunteerRegistrationFragment;

    reload(): void;
}

export const VolunteerRegistrationDateCell = (props: IVolunteerRegistrationDateCellProps) => {
    const fieldService = useService(FieldService);
    const valueString = fieldService.getValueString(
        props.customField,
        props.volunteerRegistration.userInfo
    );
    const initialValue = React.useMemo(() => {
        const value = fieldService.getValue<DateTime | string>(
            props.customField,
            props.volunteerRegistration.userInfo
        );

        if (isNonEmptyString(value)) {
            return DateTime.fromISO(value, { zone: 'utc' });
        } else {
            return value;
        }
    }, []);

    return (
        <VolunteerRegistrationUpdateCell
            customField={props.customField}
            eventId={props.eventId}
            field={props.field}
            initialValue={initialValue as any}
            reload={props.reload}
            valueString={valueString}
            volunteerRegistration={props.volunteerRegistration}
        >
            {(value, setValue) => (
                <DateInput label={props.customField.name} value={value} onChange={setValue} />
            )}
        </VolunteerRegistrationUpdateCell>
    );
};
