import { SupportedLanguage, TranslationFragment } from '../generated/types';
import { isNonEmptyArray } from '../util/array';
import { isNonEmptyString } from '../util/string';

export function getString(translation: TranslationFragment, language: SupportedLanguage): string {
    const str = (translation as any)[language.toLowerCase()];

    if (isNonEmptyString(str)) {
        return str;
    } else if (isNonEmptyString(translation.en)) {
        return translation.en;
    } else if (isNonEmptyString(translation.fr)) {
        return translation.fr;
    } else if (isNonEmptyString(translation.es)) {
        return translation.es;
    } else if (isNonEmptyString(translation.de)) {
        return translation.de;
    } else if (isNonEmptyString(translation.nl)) {
        return translation.nl;
    } else {
        return '';
    }
}

export function getUnformattedString(
    translation: TranslationFragment,
    language: SupportedLanguage
): string {
    const str = (translation as any)[`${language.toLowerCase()}String`];

    if (isNonEmptyString(str)) {
        return str;
    } else if (isNonEmptyString(translation.enString)) {
        return translation.enString;
    } else if (isNonEmptyString(translation.frString)) {
        return translation.frString;
    } else if (isNonEmptyString(translation.esString)) {
        return translation.esString;
    } else if (isNonEmptyString(translation.deString)) {
        return translation.deString;
    } else if (isNonEmptyString(translation.nlString)) {
        return translation.nlString;
    } else {
        return '';
    }
}

export function getStringArray(
    translation: TranslationFragment,
    language: SupportedLanguage
): string[] {
    const stringArray = (translation as any)[language.toLowerCase()] as string[];

    if (isNonEmptyArray(stringArray)) {
        return stringArray;
    } else if (isNonEmptyArray(translation.en as string[])) {
        return translation.en;
    } else if (isNonEmptyArray(translation.fr as string[])) {
        return translation.fr;
    } else if (isNonEmptyArray(translation.es as string[])) {
        return translation.es;
    } else if (isNonEmptyArray(translation.de as string[])) {
        return translation.de;
    } else if (isNonEmptyArray(translation.nl as string[])) {
        return translation.nl;
    } else {
        return [];
    }
}
