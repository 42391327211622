import { FormBox } from 'common-front/src/designSystem/components/formBox';
import { FileS3Input } from 'common-front/src/designSystem/form/file/fileS3Input';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Accept } from 'common-front/src/util/accept';
import { MAX_CSV_SIZE } from 'common/src/input/csvInput';
import { NumberService } from 'common/src/services/numberService';
import * as React from 'react';

export const CSVFormBox = () => {
    const {
        translate,
        params: { organizationId }
    } = useHeavent();

    return (
        <FormBox
            hideToggle={true}
            initialIsOpen={true}
            subtitle={translate(
                's_lectionnez_vo_125161',
                NumberService.bytesToMegabytes(MAX_CSV_SIZE)
            )}
            title={translate('votre_fichier_d_51776')}
        >
            <FileS3Input
                accept={Accept.Csv}
                acl="private"
                organizationId={organizationId}
                prefix="input.csv."
                withFileField={true}
            />
        </FormBox>
    );
};
