import { CheckboxText } from 'common-front/src/designSystem/components/checkbox';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Accreditation, AccreditationProperty } from 'common/src/generated/types';
import * as React from 'react';
import { AccreditationsUpdateCell } from './accreditationsUpdateCell';

interface IAccreditationsAutoAccreditCellProps {
    accreditation: Pick<Accreditation, 'id' | 'willAutoAccredit'>;

    reload(): void;
}

export const AccreditationsAutoAccreditCell = (props: IAccreditationsAutoAccreditCellProps) => {
    const { translate } = useHeavent();

    return (
        <AccreditationsUpdateCell
            accreditationId={props.accreditation.id}
            initialValue={props.accreditation.willAutoAccredit}
            property={AccreditationProperty.AutoAccredit}
            reload={props.reload}
            renderInput={(value, setValue) => (
                <CheckboxText
                    state={value ? 'checked' : 'unchecked'}
                    onClick={(newState) => {
                        setValue(newState === 'checked');
                    }}
                >
                    {translate('AutoAccredit')}
                </CheckboxText>
            )}
        >
            {props.accreditation.willAutoAccredit ? translate('oui_54361') : translate('non_33516')}
        </AccreditationsUpdateCell>
    );
};
