import { CheckboxText } from 'common-front/src/designSystem/components/checkbox';
import { LabelOptional } from 'common-front/src/designSystem/components/input/labelOptional';
import { ColorInput } from 'common-front/src/designSystem/form/colorInput';
import { IconSelect } from 'common-front/src/designSystem/form/iconSelect';
import { RichEditor } from 'common-front/src/designSystem/form/richEditor';
import { RichSelect } from 'common-front/src/designSystem/form/richSelect';
import { TextInput } from 'common-front/src/designSystem/form/textInput';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { CreateAccreditationEventFragment } from 'common/src/generated/types';
import { ICreateUpdateAccreditationValues } from 'common/src/input/accreditationInput';
import * as React from 'react';
import { CreateAccreditationCategory } from '../../accreditationsCategories/create/createUpdateAccreditationCategory';

interface ICreateAccreditationInformationsProps {
    event: CreateAccreditationEventFragment;
    values: ICreateUpdateAccreditationValues;

    change(name: string, value: any): void;
}

export const CreateAccreditationInformations = (props: ICreateAccreditationInformationsProps) => {
    const {
        translate,
        params: { eventId }
    } = useHeavent();
    const [isCreateCategoryOpen, setIsCreateCategoryOpen] = React.useState(false);
    const [accreditationCategories, setAccreditationCategories] = React.useState(
        props.event.accreditationsCategories
    );
    const isLimited = typeof props.values.accreditation.weezeventNumberOfAccess === 'number';

    return (
        <>
            <TextInput label={translate('nom_de_votre_ac_85997')} name="accreditation.name" />

            <Spacer height="6" />

            <Flex gap="4">
                <RichSelect
                    isCreateVisible={true}
                    isSearchVisible={true}
                    label={translate('cat_gorie_00291')}
                    name="accreditation.accreditationCategoryId"
                    onCreateClick={() => {
                        setIsCreateCategoryOpen(true);
                    }}
                >
                    {accreditationCategories.map(({ id, name }) => (
                        <option key={id} value={id}>
                            {name}
                        </option>
                    ))}
                </RichSelect>

                <TextInput
                    label={<LabelOptional>{translate('Acronym')}</LabelOptional>}
                    name="accreditation.acronym"
                />
            </Flex>

            <Spacer height="6" />

            <Flex gap="4">
                <ColorInput label={translate('Color')} name="accreditation.color" />

                <IconSelect label={translate('ic_ne_55554')} name="accreditation.icon" />
            </Flex>

            <Spacer height="6" />

            <RichEditor
                label={<LabelOptional>{translate('description_58935')}</LabelOptional>}
                name="accreditation.description"
            />

            {typeof props.event.weezevent?.id === 'number' && (
                <>
                    <Spacer height="6" />

                    <Flex align="center" gap="4">
                        <TextInput
                            disabled={!isLimited}
                            hint={translate('la_limite_du_no_02045')}
                            label={translate('WeezeventNumberOfAccess')}
                            name="accreditation.weezeventNumberOfAccess"
                            shouldParseAsInt={true}
                        />

                        <CheckboxText
                            state={isLimited ? 'unchecked' : 'checked'}
                            onClick={(newValue) => {
                                if (newValue === 'checked') {
                                    props.change('accreditation.weezeventNumberOfAccess', null);
                                } else {
                                    props.change('accreditation.weezeventNumberOfAccess', 1);
                                }
                            }}
                        >
                            {translate('illimite')}
                        </CheckboxText>
                    </Flex>
                </>
            )}

            {isCreateCategoryOpen && (
                <CreateAccreditationCategory
                    eventId={eventId}
                    onClose={() => {
                        setIsCreateCategoryOpen(false);
                    }}
                    onSuccess={(accreditationCategory) => {
                        setAccreditationCategories([
                            ...accreditationCategories,
                            accreditationCategory
                        ]);

                        props.change(
                            'accreditation.accreditationCategoryId',
                            accreditationCategory.id
                        );
                    }}
                />
            )}
        </>
    );
};
