import { EventQuery, PositionId, PositionsCategoryId } from 'common/src/generated/types';

type Event = Omit<EventQuery['event'], 'numberOfWaitingAssigments'>;

export function isPositionCategoryAdmin(
    event: Event,
    positionCategoryId?: PositionsCategoryId
): boolean {
    if (positionCategoryId) {
        return (
            event.permissions.isEventAdmin ||
            event.permissions.adminPositionsCategoriesIds.includes(positionCategoryId)
        );
    } else {
        return (
            event.permissions.isEventAdmin ||
            event.permissions.adminPositionsCategoriesIds.length > 0
        );
    }
}

export function isPositionAdmin(
    event: Event,
    positionCategoryId?: PositionsCategoryId,
    positionId?: PositionId
) {
    if (positionCategoryId && positionId) {
        return (
            isPositionCategoryAdmin(event, positionCategoryId) ||
            event.permissions.adminPositionsIds.includes(positionId)
        );
    } else {
        return isPositionCategoryAdmin(event) || event.permissions.adminPositionsIds.length > 0;
    }
}

export function isPositionCategoryViewer(
    event: Event,
    positionCategoryId?: PositionsCategoryId
): boolean {
    if (positionCategoryId) {
        return (
            isPositionCategoryAdmin(event, positionCategoryId) ||
            event.permissions.positionsCategoriesIds.includes(positionCategoryId)
        );
    } else {
        return (
            event.permissions.isEventAdmin || event.permissions.positionsCategoriesIds.length > 0
        );
    }
}

export function isPositionViewer(
    event: Event,
    positionCategoryId?: PositionsCategoryId,
    positionId?: PositionId
) {
    if (positionCategoryId && positionId) {
        return (
            isPositionAdmin(event, positionCategoryId, positionId) ||
            isPositionCategoryViewer(event, positionCategoryId) ||
            event.permissions.positionsIds.includes(positionId)
        );
    } else {
        return isPositionCategoryViewer(event) || event.permissions.positionsIds.length > 0;
    }
}
