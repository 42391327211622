import { Map } from 'common-front/src/components/map/map';
import { DetailBox } from 'common-front/src/designSystem/components/detailBox';
import { Box } from 'common/src/designSystem/components/box';
import { MapLayer, TraceFragment } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { Emptyable } from 'common/src/util/emptyable';
import { extractCoordinates } from 'common/src/util/map';
import { hasAddress, hasAddressOrCoordinates, hasCoordinates } from 'common/src/vo/position';
import * as React from 'react';

type Location = {
    address?: Emptyable<string>;
    latitude?: Emptyable<number>;
    layer: MapLayer;
    longitude?: Emptyable<number>;
    traces?: TraceFragment[];
    zoom: number;
};

interface ILocationCardProps {
    location: Location;
}

export const LocationCard = ({
    location,
    location: { zoom, layer, traces, address }
}: ILocationCardProps) => {
    const translate = useTranslate();
    const hasContent = hasAddressOrCoordinates(location);

    return (
        <DetailBox
            css={{
                border: hasContent && !hasAddress ? 'none' : '1px solid $gray200',
                overflow: 'hidden',
                padding: hasContent ? 0 : '$4',
                '@tablet': {
                    border: '1px solid $gray200',
                    padding: 0,
                    '& .h-detail-box-content': {
                        padding: hasContent ? '0' : '$6'
                    }
                }
            }}
            title={translate('localisation_38416')}
        >
            {hasContent ? (
                <>
                    {hasCoordinates(location) && (
                        <Box height={380} width={1}>
                            <Map
                                initialZoom={zoom}
                                layer={layer}
                                point={extractCoordinates(location)}
                                traces={traces}
                            />
                        </Box>
                    )}

                    {hasAddress(location) && (
                        <Box color="gray800" css={{ padding: '$4', '@tablet': { padding: '$7' } }}>
                            {address}
                        </Box>
                    )}
                </>
            ) : (
                <Box color="gray500" textAlign="center" width={1}>
                    {translate('aucune_localisa_78216')}
                </Box>
            )}
        </DetailBox>
    );
};
