import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { PositionsPlanning } from 'common/src/documents/positionsPlanning/positionsPlanning';
import { DocumentType } from 'common/src/generated/types';
import { useParams, useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { usePlanningDocumentQuery } from '../../generated/graphqlHooks';
import { DocumentHeader } from './documentHeader';
import { DocumentShadow } from './documentShadow';

export const PositionsPlanningDocument = () => {
    const translate = useTranslate();
    const { organizationId, eventId, segmentId } = useParams();
    const { data, loader } = usePlanningDocumentQuery({ eventId, segmentId });

    return (
        <Flex css={{ overflowY: 'auto', padding: '$7' }} direction="column" height={1} width={1}>
            <DocumentHeader
                documentType={DocumentType.Planning}
                eventId={eventId}
                languages={data.event?.languages ?? []}
                organizationId={organizationId}
                segmentsFolders={data.event?.segmentsFolders ?? []}
                title={translate('planning_de_l_96909')}
            />

            <Spacer height="7" />

            {loader || (
                <DocumentShadow width={1}>
                    <Box css={{ overflow: 'auto' }} width={1}>
                        <PositionsPlanning event={data.event} />
                    </Box>
                </DocumentShadow>
            )}
        </Flex>
    );
};
