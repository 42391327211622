import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { SegmentsGrid } from 'common-front/src/segments/segmentsGrid';
import { CampaignsSegmentsQuery, SegmentId, SegmentType } from 'common/src/generated/types';
import { useService } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { DEFAULT_PAGINATION_LIMIT } from 'common/src/vo/pagination';
import { CampaignsSegmentsService } from 'common/src/vo/segments/campaignsSegmentsService';
import * as React from 'react';
import { useOrganizationContext } from '../../../organization/organizationContext';
import { CommunityCampaignsContent } from './communityCampaignsContent';

interface ICommunityCampaignsProps {
    organization: CampaignsSegmentsQuery['organization'];

    reload(): void;
}

export const CommunityCampaigns = (props: ICommunityCampaignsProps) => {
    const {
        history,
        translate,
        params: { organizationId, segmentId }
    } = useHeavent();
    const { localOrganization, updateLocalOrganization } = useOrganizationContext();
    const segmentService = useService(CampaignsSegmentsService);

    return (
        <SegmentsGrid
            filters={segmentService.getCampaignsFilters(props.organization.campaignsCreators)}
            filtersButtonText={translate('filtrer_les_cam_97457')}
            filtersSubtitle={translate('appliquer_des_f_57442')}
            getSegmentPath={(id: SegmentId) =>
                HeaventPaths.COMMUNITY_CAMPAIGNS_SEGMENT(organizationId, id)
            }
            initialLimit={localOrganization?.campaignsLimit ?? DEFAULT_PAGINATION_LIMIT}
            initialSegmentsOpen={localOrganization?.areCampaignsSegmentsOpen ?? true}
            isAdmin={true}
            organizationId={organizationId}
            possibleColumns={[]}
            reload={props.reload}
            segmentId={segmentId}
            segmentType={SegmentType.Campaigns}
            segmentsFolders={props.organization.segmentsFolders}
            setLimit={(campaignsLimit) => {
                updateLocalOrganization({ campaignsLimit });
            }}
            onSegmentClick={(selectedSegmentId) => {
                updateLocalOrganization({ campaignsSegmentId: selectedSegmentId });
            }}
            onSegmentDelete={async () => {
                await updateLocalOrganization({ campaignsSegmentId: null });

                history.push(HeaventPaths.COMMUNITY_CAMPAIGNS(organizationId));
            }}
            onSegmentsToggle={async (areSegmentsOpen) => {
                await updateLocalOrganization({ areCampaignsSegmentsOpen: areSegmentsOpen });
            }}
        >
            <CommunityCampaignsContent />
        </SegmentsGrid>
    );
};
