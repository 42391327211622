import { AccreditAssignWaitingVolunteerSkeleton } from 'common-front/src/accreditAssign/leftPanel/accreditAssignWaitingVolunteerSkeleton';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Badge } from 'common/src/designSystem/components/badge';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { TeamAssignmentWaitingTeamsQuery } from 'common/src/generated/types';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import { TeamAssignmentLeftPanelTeam } from './teamAssignmentLeftPanelTeam';

interface ITeamAssignmentLeftPanelProps {
    event: TeamAssignmentWaitingTeamsQuery['event'];
    isLoading: boolean;
}

export const TeamAssignmentLeftPanel = (props: ITeamAssignmentLeftPanelProps) => {
    const {
        history,
        translate,
        params: { organizationId, eventId }
    } = useHeavent();
    const numberOfTeams = props.event?.teams.totalCount ?? 0;
    const teams = props.event?.teams.nodes ?? [];

    return (
        <Flex direction="column" height={1} width={1}>
            <Flex css={{ padding: '$5 $4' }} direction="column" gap="2">
                <Flex
                    align="center"
                    css={{
                        color: '$primary700',
                        cursor: 'pointer'
                    }}
                    gap="2"
                    onClick={() => {
                        history.push(HeaventPaths.ASSIGNMENTS(organizationId, eventId));
                    }}
                >
                    <Box>
                        <I icon="arrow-left" />
                    </Box>

                    <Box>{translate('retour_92202')}</Box>
                </Flex>

                <Flex align="center" gap="2">
                    <Box font="gray900 textLg medium">{translate('affectation_plural')}</Box>

                    <Badge color="error" size="sm">
                        {translate('_1_quipe_rest_49090', numberOfTeams)}
                    </Badge>
                </Flex>
            </Flex>

            <Spacer height="3" />

            <Flex css={{ flex: '1', overflowY: 'auto' }} direction="column">
                {props.isLoading ? (
                    <>
                        <AccreditAssignWaitingVolunteerSkeleton />
                        <AccreditAssignWaitingVolunteerSkeleton />
                        <AccreditAssignWaitingVolunteerSkeleton />
                    </>
                ) : (
                    teams.map((team) => (
                        <TeamAssignmentLeftPanelTeam key={team.teamCode} team={team} />
                    ))
                )}
            </Flex>
        </Flex>
    );
};
