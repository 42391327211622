import { identity } from 'lodash-es';
import * as React from 'react';
import { Field } from 'react-final-form';
import { isInvalid } from '../../util/isInvalid';
import { IFormInputProps } from '../components/input/commonInputProps';
import {
    IRichEditorProps,
    RichEditor as RichEditorComponent
} from '../components/richEditor/richEditor';

export const RichEditor = ({
    name,
    hint,
    state,
    ...rest
}: Omit<IFormInputProps<IRichEditorProps>, 'initialValue'>) => (
    <Field
        name={name}
        parse={identity}
        render={(fieldProps) => {
            const invalid = isInvalid(fieldProps.meta);

            return (
                <RichEditorComponent
                    hint={invalid ? fieldProps.meta.error : hint}
                    initialValue={fieldProps.input.value!}
                    name={name}
                    state={invalid ? 'error' : state}
                    onChange={(value) => {
                        fieldProps.input.onChange(value);
                    }}
                    {...rest}
                />
            );
        }}
    />
);
