import { Flex } from 'common/src/designSystem/components/flex';
import { Skeleton } from 'common/src/designSystem/components/skeleton';
import * as React from 'react';

interface IOrganizationTranslationSkeletonProps {
    isFirst: boolean;
    isLast: boolean;
}

export const OrganizationTranslationSkeleton = (props: IOrganizationTranslationSkeletonProps) => (
    <Flex
        css={{
            background: 'white',
            border: '1px solid $gray200',
            borderBottom: props.isLast ? '1px solid $gray200' : 'none',
            borderBottomLeftRadius: props.isLast ? '$2' : '0',
            borderBottomRightRadius: props.isLast ? '$2' : '0',
            borderTopLeftRadius: props.isFirst ? '$2' : '0',
            borderTopRightRadius: props.isFirst ? '$2' : '0',
            padding: '$4'
        }}
    >
        <Skeleton borderRadius="$2" height={32} width={1} />
    </Flex>
);
