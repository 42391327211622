import { LoadMoreControl } from 'common-front/src/components/pagination/mobile/loadMoreControl';
import { Button } from 'common-front/src/designSystem/components/button';
import { CheckboxText, ICheckboxState } from 'common-front/src/designSystem/components/checkbox';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { styled } from 'common/src/designSystem/components/stitches';
import {
    PositionSlotMembersMobileQuery,
    PositionsSlotsUsersInfoId
} from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { PropsWithChildren, cloneElement, useEffect, useState } from 'react';

const SelectAllLink = styled(Flex, {
    fontSize: '$textSm',
    color: '$blue600'
});

type Registration =
    PositionSlotMembersMobileQuery['event']['volunteersRegistrations']['nodes'][number];

type PositionSlotMembersCheckFormValuesMobile = Array<
    Registration & {
        state?: ICheckboxState;
    }
>;

interface IPositionSlotMembersCheckFormMobileProps {
    onSubmit: (values: PositionsSlotsUsersInfoId[]) => void;
    data: PositionSlotMembersMobileQuery;
    loader: JSX.Element | undefined;
    loadMore: () => void;
    xs: Registration[];
    disabled: PositionsSlotsUsersInfoId[];
}

export const PositionSlotMembersCheckFormMobile = ({
    onSubmit,
    children,
    data,
    loader,
    loadMore,
    xs,
    disabled
}: PropsWithChildren<IPositionSlotMembersCheckFormMobileProps>) => {
    const translate = useTranslate();
    const [values, setValues] = useState(xs as PositionSlotMembersCheckFormValuesMobile);
    useEffect(() => {
        setValues(xs.toSpliced(0, values.length, ...values));
    }, [xs]);
    const setState = (index: number, state: ICheckboxState) => {
        setValues(values.toSpliced(index, 1, { ...values[index], state }));
    };
    const selectAll = () => {
        setValues(values.map((registration) => ({ ...registration, state: 'checked' })));
    };
    const submitValues = values
        .filter(
            (registration) =>
                registration.state === 'checked' &&
                !disabled.includes(registration.positionSlotUserInfo!.id)
        )
        .map((registration) => registration.positionSlotUserInfo!.id);

    return (
        <Flex direction="column" height="100%" width="100%">
            <Flex
                css={{ flex: 1, overflow: 'auto', padding: '$6 $4 $3 $4' }}
                direction="column"
                gap="5"
            >
                <Flex justify="between">
                    <Box fontSize="textMd" fontWeight="semiBold">
                        {translate('liste_des_membr_86405', xs.length)}
                    </Box>

                    <SelectAllLink onClick={selectAll}>
                        {translate('tout_s_l_ctionn_18234')}
                    </SelectAllLink>
                </Flex>
                <Flex direction="column" gap="3">
                    {values.map((registration, index) => (
                        <CheckboxText
                            key={index}
                            disabled={disabled.includes(registration.positionSlotUserInfo!.id)}
                            state={registration.state}
                            onClick={(state) => setState(index, state)}
                        >
                            {registration.userInfo.name}
                        </CheckboxText>
                    ))}
                    <LoadMoreControl
                        buttonText={translate('afficher_plus_d_41906')}
                        isLoading={loader !== undefined}
                        loadMore={loadMore}
                        pageInfo={data.event.volunteersRegistrations.pageInfo}
                        statusText={translate(
                            '_1_sur_2_mem_14323',
                            xs.length,
                            data.event.volunteersRegistrations.totalCount
                        )}
                    />
                </Flex>
            </Flex>
            <Flex css={{ boxShadow: '$mdReverse', padding: '$6 $4' }} direction="column">
                {React.isValidElement<React.ComponentProps<typeof Button>>(children)
                    ? cloneElement(children, {
                          onClick: () => onSubmit(submitValues),
                          disabled: submitValues.length === 0
                      })
                    : children}
            </Flex>
        </Flex>
    );
};
