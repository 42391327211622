import { chunk } from 'lodash-es';
import * as React from 'react';
import { Box } from '../../designSystem/components/box';
import { Flex } from '../../designSystem/components/flex';
import {
    CustomFieldWithConditionFragment,
    PositionsSlot as PositionSlotVO,
    VolunteerPdfFragment
} from '../../generated/types';
import { usePositionSlotIsRenamedRange } from '../../hooks/usePositionSlotIsRenamedRange';
import { useTranslate } from '../../util/dependencies/dependencies';
import { Emptyable } from '../../util/emptyable';
import { VolunteersTable } from '../volunteersTable';
import { PositionSheetHeader } from './positionSheetHeader';

const NUMBER_OF_USERS_PER_PAGE = 19;

interface IPositionSheetPositionSlotProps {
    customFields: CustomFieldWithConditionFragment[];
    positionAcronym: Emptyable<string>;
    positionName: string;
    slot: Pick<PositionSlotVO, 'name' | 'range'>;
    slugsToDisplay: string[];
    volunteers: VolunteerPdfFragment[];
}

export const PositionSheetPositionSlot = (props: IPositionSheetPositionSlotProps) => {
    const translate = useTranslate();
    const { isRenamed, finalName, rangeString } = usePositionSlotIsRenamedRange(props.slot);

    return (
        <>
            {chunk(props.volunteers, NUMBER_OF_USERS_PER_PAGE).map((volunteers, index) => (
                <Flex
                    key={index}
                    css={{
                        background: 'white',
                        pageBreakBefore: 'always'
                    }}
                    direction="column"
                    width={1}
                >
                    <PositionSheetHeader
                        acronym={props.positionAcronym}
                        name={props.positionName}
                    />

                    <Flex css={{ padding: '$7 56px' }} direction="column" gap="3">
                        <Flex direction="column" gap="1">
                            <Box font="gray800 textSm medium">
                                {translate('membres_pour_le_20317', finalName)} (
                                {props.volunteers.length})
                            </Box>

                            {isRenamed && <Box font="gray800 textXs medium">{rangeString}</Box>}
                        </Flex>

                        <VolunteersTable
                            customFields={props.customFields}
                            slugsToDisplay={props.slugsToDisplay}
                            volunteers={volunteers}
                        />
                    </Flex>
                </Flex>
            ))}
        </>
    );
};
