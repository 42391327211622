import { DetailBox } from 'common-front/src/designSystem/components/detailBox';
import { Box } from 'common/src/designSystem/components/box';
import { RichText } from 'common/src/designSystem/components/richEditor/richText';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';

interface IDescriptionCardProps {
    description: string;
}

export const DescriptionCard = ({ description }: IDescriptionCardProps) => {
    const translate = useTranslate();

    return (
        <DetailBox title={translate('description_58935')}>
            {isNonEmptyString(description) ? (
                <RichText text={description} />
            ) : (
                <Box color="gray500" textAlign="center" width={1}>
                    {translate('aucune_descript_25737')}
                </Box>
            )}
        </DetailBox>
    );
};
