import * as React from 'react';
import { Avatar } from '../components/avatar/avatar';
import { Box } from '../designSystem/components/box';
import { Flex } from '../designSystem/components/flex';
import { Spacer } from '../designSystem/components/spacer';
import { BadgeDocumentOptions, DocumentUserBadgeFragment, PdfFormat } from '../generated/types';
import { IntervalService } from '../services/intervalService';
import { useService, useTranslate } from '../util/dependencies/dependencies';
import { PAGE_SIZES } from '../util/pdf';
import { isNonEmptyString } from '../util/string';

interface IUserBadgeProps {
    badgeDocumentFragment: DocumentUserBadgeFragment;
    options: BadgeDocumentOptions;
    showPageSeparator: boolean;
}

export const UserBadge = (props: IUserBadgeProps) => {
    const translate = useTranslate();
    const intervalService = useService(IntervalService);
    const pageSize =
        props.options.format === PdfFormat.A4Portrait
            ? PAGE_SIZES.A4
            : props.options.format === PdfFormat.A5Portrait
              ? PAGE_SIZES.A5
              : PAGE_SIZES.A6;
    const volunteerRegistration = props.badgeDocumentFragment.volunteerRegistration;

    return (
        <Box width={pageSize.width}>
            <Flex
                css={{
                    background: `linear-gradient(190deg, ${props.options.color} 40%, white 40%)`
                }}
                direction="column"
                height={pageSize.height}
                width={1}
            >
                <Box
                    css={{
                        height: 'calc(40% - 40px)'
                    }}
                />

                <Flex align="center" direction="column" width={1}>
                    <Avatar
                        email={volunteerRegistration.userInfo.email}
                        image={volunteerRegistration.userInfo.picture?.url}
                        name={volunteerRegistration.userInfo.name}
                        size={96}
                    />

                    <Spacer height="4" />

                    <Box color="gray900" fontSize="textXl" fontWeight="medium">
                        {volunteerRegistration.userInfo.nameOrEmail}
                    </Box>

                    <Spacer height="1" />

                    <Box color="gray800" fontSize="textLg">
                        {isNonEmptyString(props.options.name)
                            ? props.options.name
                            : translate('membre_45658')}
                    </Box>
                </Flex>

                <Box css={{ flex: '1' }} />

                {isNonEmptyString(props.options.logo?.content) && (
                    <Flex height={96} justify="center">
                        <img height="100%" src={props.options.logo.content} />
                    </Flex>
                )}

                <Spacer height="6" />
            </Flex>

            {props.showPageSeparator && (
                <Box css={{ background: 'transparent', height: '$space$6' }} />
            )}

            <Flex
                css={{
                    background: 'white',
                    pageBreakBefore: 'always',
                    padding: '$4 $6'
                }}
                direction="column"
                height={pageSize.height}
                width={1}
            >
                {isNonEmptyString(props.options.logo?.content) && (
                    <Flex height={32} justify="center">
                        <img height="100%" src={props.options.logo.content} />
                    </Flex>
                )}

                <Spacer height="6" />

                {props.options.showAssignments !== false && (
                    <>
                        <Box color="gray900" fontWeight="medium">
                            {translate('affectation_plural')}
                        </Box>

                        <Spacer height="2" />

                        {volunteerRegistration.positionsSlotsUsersInfos.map(
                            ({ position, positionSlot }, index) => (
                                <React.Fragment key={index}>
                                    <Box color="gray500" fontSize="textXs">
                                        {translate('affectation_n_78121', index + 1)}
                                    </Box>

                                    <Box color="gray800">
                                        {position.name} :{' '}
                                        {intervalService.toDisplayString(positionSlot.range)}
                                    </Box>

                                    <Spacer height="2" />
                                </React.Fragment>
                            )
                        )}
                    </>
                )}

                {isNonEmptyString(props.options.infos) && (
                    <>
                        <Box color="gray500" fontSize="textXs">
                            {translate('renseigements_c_00093')}
                        </Box>

                        <Box color="gray800" css={{ whiteSpace: 'pre-wrap' }}>
                            {props.options.infos}
                        </Box>
                    </>
                )}
            </Flex>
        </Box>
    );
};
