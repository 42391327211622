import { AccreditAssignLeftPanelMember } from 'common-front/src/accreditAssign/leftPanel/accreditAssignLeftPanelMember';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { TeamAssignmentWaitingTeamsQuery } from 'common/src/generated/types';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';

interface ITeamAssignmentLeftPanelTeamProps {
    team: TeamAssignmentWaitingTeamsQuery['event']['teams']['nodes'][0];
}

export const TeamAssignmentLeftPanelTeam = (props: ITeamAssignmentLeftPanelTeamProps) => {
    const {
        history,
        translate,
        params: { organizationId, eventId, teamCode }
    } = useHeavent();
    const isSelected = teamCode === props.team.teamCode;
    const [isOpen, setIsOpen] = React.useState(isSelected);

    return (
        <>
            <Flex
                align="center"
                css={{
                    background: isSelected ? '$primary100' : 'white',
                    borderBottom: isOpen ? 'none' : '1px solid $gray200',
                    cursor: 'pointer',
                    padding: '$4',
                    '&:hover': {
                        background: '$primary100'
                    }
                }}
                gap="4"
                onClick={() => {
                    history.push(
                        HeaventPaths.TEAM_ASSIGNMENT(organizationId, eventId, props.team.teamCode)
                    );
                }}
            >
                <Flex css={{ flex: '1' }} direction="column" gap="1">
                    <Box font="gray800 textSm semiBold">{props.team.teamCode}</Box>

                    <Box color="gray500">
                        {translate('_1_membre_49737', props.team.members.length)}
                    </Box>
                </Flex>

                <Box
                    color="gray900"
                    css={{ cursor: 'pointer', padding: '$2' }}
                    fontSize="textMd"
                    onClick={(e) => {
                        e.stopPropagation();
                        e.nativeEvent.stopImmediatePropagation();

                        setIsOpen(!isOpen);
                    }}
                >
                    <I icon={isOpen ? 'chevron-down' : 'chevron-right'} />
                </Box>
            </Flex>

            {isOpen &&
                props.team.members.map((vr, index) => (
                    <AccreditAssignLeftPanelMember
                        key={vr.userInfo.id}
                        displayBorder={index === props.team.members.length - 1}
                        isSelected={isSelected}
                        vr={vr}
                        onClick={() => {
                            history.push(
                                HeaventPaths.TEAM_ASSIGNMENT(
                                    organizationId,
                                    eventId,
                                    props.team.teamCode
                                )
                            );
                        }}
                    />
                ))}
        </>
    );
};
