import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { IIcon } from 'common/src/designSystem/components/i';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { ValidateService } from 'common/src/services/validateService';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { FormRenderProps } from 'react-final-form';
import { Form } from '../../components/form/form';
import { FormErrors } from '../../components/form/formErrors';
import { Button } from './button';
import { IconBackground } from './iconBackground';
import { IModalSize, Modal } from './modal';

interface IFormModalProps<T> {
    buttonText: string;
    children:
        | React.ReactNode
        | ((formRenderProps: Pick<FormRenderProps<T>, 'form' | 'values'>) => React.ReactNode);
    icon: IIcon;
    initialValues: T;
    schema?: any;
    size?: IModalSize;
    subtitle?: string;
    title: string;

    onClose(): void;
    onSubmit(values: T): Promise<any>;
}

export const FormModal = <T extends {}>(props: IFormModalProps<T>) => {
    const translate = useTranslate();
    const validateService = useService(ValidateService);

    return (
        <Modal size={props.size || 'sm'} onClose={props.onClose}>
            <Form
                css={{ overflow: 'hidden' }}
                direction="column"
                height={1}
                hideErrors={true}
                initialValues={props.initialValues}
                render={(formRenderProps) => {
                    const { handleSubmit, submitting } = formRenderProps;

                    return (
                        <>
                            <Flex justify="center">
                                <IconBackground color="primary" icon={props.icon} />
                            </Flex>

                            <Spacer height="4" />

                            <Box
                                color="gray900"
                                fontSize="textLg"
                                fontWeight="medium"
                                textAlign="center"
                            >
                                {props.title}
                            </Box>

                            {props.subtitle && (
                                <>
                                    <Spacer height="1" />

                                    <Box color="gray500" textAlign="center">
                                        {props.subtitle}
                                    </Box>
                                </>
                            )}

                            <Spacer height="6" />

                            <FormErrors />

                            {typeof props.children === 'function'
                                ? props.children(formRenderProps)
                                : props.children}

                            <Spacer height="7" />

                            <Flex gap="3" justify="end">
                                <Button isLoading={submitting} onClick={handleSubmit}>
                                    {props.buttonText}
                                </Button>

                                <Button color="white" onClick={props.onClose}>
                                    {translate('annuler_48254')}
                                </Button>
                            </Flex>
                        </>
                    );
                }}
                validate={(values: T) => {
                    if (props.schema) {
                        return validateService.validateForForm(props.schema)(values);
                    } else {
                        return {};
                    }
                }}
                onSubmit={props.onSubmit}
            />
        </Modal>
    );
};
