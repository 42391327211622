import { Button } from 'common-front/src/designSystem/components/button';
import { KpiCard } from 'common-front/src/designSystem/components/kpiCard';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { SortDirection } from 'common/src/generated/types';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import { useEventContext } from '../../events/show/eventContext';
import { usePreAssignmentsKpisQuery } from '../../generated/graphqlHooks';

export const AssignmentsPositionAdmin = () => {
    const {
        history,
        translate,
        params: { organizationId, eventId }
    } = useHeavent();
    const { localEvent } = useEventContext();
    const { data, loader } = usePreAssignmentsKpisQuery({
        eventId,
        direction: localEvent?.['assignment.leftPanelDirection'] ?? SortDirection.Desc
    });

    return (
        loader || (
            <Flex css={{ padding: '$7' }} direction="column">
                <Box color="gray900" css={{ flex: '1' }} fontSize="displayXs" fontWeight="semiBold">
                    {translate('mes_affectation_73224')}
                </Box>

                <Spacer height="7" />

                <Flex gap="6">
                    <KpiCard
                        button={
                            <Button
                                disabled={data.event.preAssigneds.totalCount === 0}
                                onClick={() => {
                                    history.push(
                                        HeaventPaths.ASSIGNMENT(
                                            organizationId,
                                            eventId,
                                            data.event.preAssigneds.nodes[0].userInfoId
                                        )
                                    );
                                }}
                            >
                                {translate('affecter_les_me_77160')}
                            </Button>
                        }
                        color="success"
                        count={data.event.preAssigneds.totalCount}
                        icon="arrow-trend-up"
                        text={translate(
                            'membre_en_atten_84228',
                            data.event.preAssigneds.totalCount
                        )}
                    />
                </Flex>
            </Flex>
        )
    );
};
