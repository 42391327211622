import { FiltersPredicatesBadges } from 'common-front/src/segments/filtersPredicates/filtersPredicatesBadges';
import { useSegmentsContext } from 'common-front/src/segments/segmentsContext';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { isNonEmptyArray } from 'common/src/util/array';
import * as React from 'react';
import { EventsHeader } from './eventsHeader';
import { EventsList } from './eventsList';
import { EventsSearchFilters } from './eventsSearchFilters';

export const EventsContent = () => {
    const { filtersPredicates } = useSegmentsContext();

    return (
        <>
            <EventsHeader />

            <Spacer height="7" />

            <EventsSearchFilters />

            {isNonEmptyArray(filtersPredicates) && (
                <>
                    <Spacer height="3" />

                    <FiltersPredicatesBadges />
                </>
            )}

            <Spacer height="6" />

            <EventsList />
        </>
    );
};
