import { PositionId } from 'common/src/generated/types';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import { usePositionDuplicateMutation } from '../generated/graphqlHooks';
import { useHeavent } from '../hooks/useHeavent';

export function usePositionDuplicate() {
    const {
        history,
        params: { organizationId, eventId }
    } = useHeavent();
    const { mutate } = usePositionDuplicateMutation();

    return React.useCallback(
        async (positionId: PositionId) => {
            const { positionDuplicate } = await mutate({ eventId, positionId });

            history.push(HeaventPaths.EDIT_POSITION(organizationId, eventId, positionDuplicate.id));
        },
        [organizationId, eventId, mutate]
    );
}
