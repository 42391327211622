import { groupBy } from 'lodash-es';
import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { CommonEnvVars } from '../../../envVars';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { A4_SIZES } from '../../../util/pdf';
import { isNonEmptyString } from '../../../util/string';

interface IBadgeMooreaProps {
    event: DocumentUserCustomBadgeFragment;
}

export const BadgeMoorea = (props: IBadgeMooreaProps) => {
    const vr = props.event.volunteerRegistration;
    const ui = vr.userInfo;
    const byDates = Object.values(
        groupBy(vr.positionsSlotsUsersInfos, (psui) =>
            psui.positionSlot.range.start!.startOf('day').toMillis()
        )
    );

    return (
        <>
            <Flex
                css={{
                    background: `url(https://assets.recrewteer.com/badges/moorea/contremarque.jpg) no-repeat`,
                    backgroundSize: 'cover',
                    fontFamily: '$unbounded',
                    position: 'relative'
                }}
                height={A4_SIZES['96dpi'].height}
                width={A4_SIZES['96dpi'].width}
            >
                <Flex
                    css={{
                        color: '$gray800',
                        fontSize: '22px',
                        fontWeight: '700',
                        left: '96px',
                        position: 'absolute',
                        top: '239px'
                    }}
                    direction="column"
                >
                    <Box>{ui.firstName}</Box>
                    <Box>{ui.lastName}</Box>
                </Flex>

                <Box
                    css={{
                        position: 'absolute',
                        right: '96px',
                        top: '222px'
                    }}
                    height={90}
                    width={90}
                >
                    {isNonEmptyString(vr.weezeventParticipantInfo?.idBarcode) && (
                        <img
                            height="100%"
                            src={`https://api.${
                                CommonEnvVars.HEAVENT_EXTERNAL_DOMAIN
                            }/barcode/qr?text=${encodeURIComponent(
                                vr.weezeventParticipantInfo!.idBarcode
                            )}&color=000000&height=90&width=90`}
                            width="100%"
                        />
                    )}
                </Box>

                <Box
                    color="gray800"
                    css={{
                        fontSize: '12px',
                        left: '96px',
                        position: 'absolute',
                        top: '548px'
                    }}
                >
                    Chèr.e {ui.firstName},
                </Box>
            </Flex>

            <Flex
                css={{
                    background: `url(https://assets.recrewteer.com/badges/moorea/Planning.jpg) no-repeat`,
                    backgroundSize: 'cover',
                    fontFamily: '$unbounded',
                    pageBreakBefore: 'always',
                    position: 'relative'
                }}
                height={A4_SIZES['96dpi'].height}
                width={A4_SIZES['96dpi'].width}
            >
                <Flex
                    css={{
                        left: '92px',
                        position: 'absolute',
                        top: '372px'
                    }}
                    direction="column"
                    gap="6"
                >
                    {byDates.map((psuis, index) => {
                        const first = psuis[0];

                        return (
                            <Flex key={index} direction="column" gap="2">
                                <Box
                                    css={{
                                        color: '#a26fd2',
                                        fontSize: '16px',
                                        fontWeight: '700',
                                        textTransform: 'uppercase'
                                    }}
                                >
                                    {first.positionSlot.range.start!.toFormat('cccc dd MMMM yyyy', {
                                        locale: 'fr'
                                    })}
                                </Box>

                                {psuis.map(({ position, positionSlot: { range } }, psuiIndex) => (
                                    <Box key={psuiIndex}>
                                        {position.name} / {range.start!.toFormat('HH:mm')}-
                                        {range.end!.toFormat('HH:mm')}
                                    </Box>
                                ))}
                            </Flex>
                        );
                    })}
                </Flex>
            </Flex>
        </>
    );
};
