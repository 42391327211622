import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { uuidv4 } from 'common/src/util/uuid';
import * as React from 'react';
import { Button } from '../designSystem/components/button';
import { Dropdown } from '../designSystem/components/dropdown/dropdown';
import { Menu } from '../designSystem/components/dropdown/menu';
import { Trigger } from '../designSystem/components/dropdown/trigger';
import { useSegmentsContext } from '../segments/segmentsContext';

const H_UPDATE_CELL_OPEN = 'h-update-cell-open';

interface IUpdateCellProps<T> {
    cellCss?: any;
    children: React.ReactNode;
    initialValue: T;

    mutate(value: T): Promise<any>;
    reload(): void;
    renderInput(value: T, setValue: (v: T) => void): React.ReactNode;
}

export const UpdateCell = <T extends {}>(props: IUpdateCellProps<T>) => {
    const translate = useTranslate();
    const uuidRef = React.useRef(uuidv4());
    const { isEditMode } = useSegmentsContext();
    const [isOpen, _setIsOpen] = React.useState(false);
    const [updatedValue, setUpdatedValue] = React.useState(props.initialValue);
    const setIsOpen = React.useCallback(
        (newIsOpen: boolean) => {
            _setIsOpen(newIsOpen);

            document.body.dispatchEvent(
                new CustomEvent(H_UPDATE_CELL_OPEN, {
                    bubbles: true,
                    detail: { uuid: uuidRef.current }
                })
            );
        },
        [_setIsOpen]
    );
    const updateCellOpenHandler = React.useCallback(
        (e: CustomEvent) => {
            if (e.detail.uuid !== uuidRef.current) {
                _setIsOpen(false);
            }
        },
        [_setIsOpen]
    );
    const onCancel = React.useCallback(() => {
        setIsOpen(false);
        setUpdatedValue(props.initialValue);
    }, [props.initialValue, setIsOpen, setUpdatedValue]);
    const onSave = async () => {
        await props.mutate(updatedValue);

        props.reload();
    };

    React.useEffect(() => {
        document.addEventListener(H_UPDATE_CELL_OPEN, updateCellOpenHandler as any);

        return () => {
            document.removeEventListener(H_UPDATE_CELL_OPEN, updateCellOpenHandler as any);
        };
    }, [updateCellOpenHandler]);

    if (isEditMode) {
        return (
            <Dropdown doNotCloseOnOtherOpen={true} isOpen={isOpen} onStateChange={setIsOpen}>
                <Trigger>
                    <Cell
                        css={{
                            ...(props.cellCss || {}),
                            border: '2px solid transparent',
                            cursor: 'text',
                            '&:hover': {
                                border: '2px solid $primary700'
                            }
                        }}
                        onClick={(e) => {
                            e.stopPropagation();
                            e.nativeEvent.stopImmediatePropagation();
                        }}
                    >
                        {props.children}
                    </Cell>
                </Trigger>

                <Menu css={{ padding: '0' }} placement="bottom-start" width={380}>
                    <Flex css={{ padding: '$4 $6' }}>
                        {props.renderInput(updatedValue, setUpdatedValue)}
                    </Flex>

                    <Flex
                        align="center"
                        css={{
                            borderTop: '1px solid $gray200',
                            padding: '$4 $6'
                        }}
                        gap="4"
                        justify="end"
                    >
                        <Box
                            css={{ cursor: 'pointer' }}
                            font="gray700 textSm medium"
                            onClick={onCancel}
                        >
                            {translate('annuler_48254')}
                        </Box>

                        <Button size="sm" onClick={onSave}>
                            {translate('appliquer_05753')}
                        </Button>
                    </Flex>
                </Menu>
            </Dropdown>
        );
    } else {
        return (
            <Cell
                css={{
                    ...props.cellCss,
                    border: '2px solid transparent'
                }}
            >
                {props.children}
            </Cell>
        );
    }
};
