import { Select } from 'common-front/src/designSystem/components/select/select';
import { TableFilters } from 'common-front/src/designSystem/components/tableFilters';
import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { useEnumToOptions } from 'common-front/src/hooks/useEnumToOptions';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { usePaginationInfos } from 'common-front/src/hooks/usePaginationInfos';
import { useStateDebounce } from 'common-front/src/hooks/useStateDebounce';
import { Box } from 'common/src/designSystem/components/box';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { RowSkeleton } from 'common/src/designSystem/components/table/rowSkeleton';
import { ALL_EMAILSTATE, EmailState } from 'common/src/generated/types';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';
import { CampaignEmailRow } from '../../campaigns/show/campaignEmailRow';
import { useAccreditationsAssignmentsEmailsQuery } from '../../generated/graphqlHooks';

export const AccreditationsAssignmentsEmails = () => {
    const {
        translate,
        params: { eventId }
    } = useHeavent();
    const [name, nameDebounced, setName] = useStateDebounce('');
    const [state, setState] = React.useState<'' | EmailState>('');
    const [offset, setOffset] = React.useState(0);
    const { data, isLoading } = useAccreditationsAssignmentsEmailsQuery({
        eventId,
        name: isNonEmptyString(nameDebounced) ? nameDebounced : undefined,
        state: state !== '' ? state : undefined,
        offset
    });
    const enumToOptions = useEnumToOptions();
    const { numberOfPages, totalCount } = usePaginationInfos(data.event?.emails);

    return (
        <TableFilters
            filters={
                <>
                    <Box width={200}>
                        <Select
                            value={state}
                            onChange={(newState: '' | EmailState) => {
                                setState(newState);
                                setOffset(0);
                            }}
                        >
                            <option value="">{translate('tous_les_status_67644')}</option>

                            {enumToOptions(ALL_EMAILSTATE.filter((s) => s !== EmailState.Queued))}
                        </Select>
                    </Box>

                    <Box width={320}>
                        <TextInput
                            icon="magnifying-glass"
                            placeholder={translate('rechercher_un_d_02329')}
                            value={name}
                            onChange={(newName: string) => {
                                setName(newName);
                                setOffset(0);
                            }}
                        />
                    </Box>
                </>
            }
            headerCells={
                <>
                    <HeaderCell>{translate('nom_du_membre_69353')}</HeaderCell>
                    <HeaderCell>{translate('date_d_envoi_74668')}</HeaderCell>
                    <HeaderCell>{translate('status_06428')}</HeaderCell>
                </>
            }
            numberOfPages={numberOfPages}
            offset={offset}
            rows={
                isLoading ? (
                    <>
                        <RowSkeleton bx={true} />
                        <RowSkeleton bx={true} />
                        <RowSkeleton bx={true} />
                    </>
                ) : (
                    data.event.emails.nodes.map((email) => (
                        <CampaignEmailRow key={email.id} email={email} />
                    ))
                )
            }
            setOffset={setOffset}
            title={translate('liste_des_envoi_14764')}
            totalCount={totalCount}
        />
    );
};
