import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { fromIconVO, I } from 'common/src/designSystem/components/i';
import { UserPanelAccreditationSlotUserInfoFragment } from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { getColorByBackgroundColor } from 'common/src/util/color';
import { useService } from 'common/src/util/dependencies/dependencies';
import { AccreditationsPaths } from 'common/src/util/paths/accreditationsPaths';
import { fullName } from 'common/src/vo/accreditationSlot';
import * as React from 'react';

interface IAccreditationsAccreditationProps {
    accreditationUserInfo: UserPanelAccreditationSlotUserInfoFragment;
}

export const AccreditationsAccreditation = (props: IAccreditationsAccreditationProps) => {
    const {
        history,
        params: { organizationId, eventId }
    } = useHeavent();
    const dateTimeService = useService(DateTimeService);
    const accreditation = props.accreditationUserInfo.accreditation;
    const slot = props.accreditationUserInfo.accreditationSlot;

    return (
        <Flex
            align="center"
            css={{
                background: 'white',
                border: '1px solid $gray200',
                borderRadius: '$2',
                boxShadow: '$xs',
                cursor: 'pointer',
                padding: '$3 $4'
            }}
            gap="3"
            onClick={() => {
                history.push(
                    AccreditationsPaths.ACCREDITATION({
                        organizationId,
                        eventId,
                        accreditationId: accreditation.id
                    })
                );
            }}
        >
            <Flex
                align="center"
                css={{
                    background: accreditation.color,
                    borderRadius: '12px',
                    color: getColorByBackgroundColor(accreditation.color),
                    fontSize: '$textMd',
                    height: '40px',
                    width: '40px'
                }}
                justify="center"
            >
                <I icon={fromIconVO(accreditation.icon)} />
            </Flex>

            <Flex css={{ flex: '1' }} direction="column" gap="1">
                <Box color="gray800" fontWeight="semiBold">
                    {accreditation.name}
                </Box>

                <Box color="gray500">
                    {fullName(dateTimeService, slot, accreditation.name, { defaultName: '-' })}
                </Box>
            </Flex>
        </Flex>
    );
};
