import { PaginationCell } from 'common-front/src/designSystem/components/pagination/paginationCell';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { usePaginationInfos } from 'common-front/src/hooks/usePaginationInfos';
import { useSegmentsContext } from 'common-front/src/segments/segmentsContext';
import { Flex } from 'common/src/designSystem/components/flex';
import { EventId, EventsSortAttributes } from 'common/src/generated/types';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';
import { useEventsQuery } from '../../generated/graphqlHooks';
import { EventsEvent } from './eventsEvent';
import { EventsEventSkeleton } from './eventsEventSkeleton';

export const EventsList = () => {
    const {
        params: { organizationId }
    } = useHeavent();
    const { nameDebounced, offset, predicates, reloadKey, setOffset } = useSegmentsContext<
        EventId,
        EventsSortAttributes
    >();
    const { data, isLoading, reload } = useEventsQuery({
        organizationId,
        predicates,
        name: isNonEmptyString(nameDebounced) ? nameDebounced : null,
        offset,
        limit: 10
    });
    const { numberOfPages, totalCount } = usePaginationInfos(data.organization?.events);

    React.useEffect(() => {
        if (reloadKey > 1) {
            reload();
        }
    }, [reloadKey]);

    return (
        <Flex css={{ flex: '1' }} direction="column" gap="4" width={1}>
            {isLoading ? (
                <>
                    <EventsEventSkeleton />
                    <EventsEventSkeleton />
                </>
            ) : (
                data.organization.events.nodes.map((event) => (
                    <EventsEvent key={event.id} event={event} reload={reload} />
                ))
            )}

            <PaginationCell
                limit={10}
                numberOfPages={numberOfPages}
                offset={offset}
                setOffset={setOffset}
                totalCount={totalCount}
            />
        </Flex>
    );
};
