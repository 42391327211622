import { Empty } from 'common-front/src/components/empty/empty';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { PositionsPaths } from 'common/src/util/paths/positionsPaths';
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { usePositionQuery } from '../../generated/graphqlHooks';
import { Position } from './position';

export const PositionLoader = () => {
    const {
        params: { organizationId, eventId, positionId }
    } = useHeavent();
    const { data, loader } = usePositionQuery({ organizationId, eventId, positionId });

    return (
        loader || (
            <Switch>
                <Route
                    exact
                    path={PositionsPaths.POSITION({
                        organizationId: ':organizationId',
                        eventId: ':eventId',
                        positionId: ':positionId'
                    })}
                >
                    <Empty
                        path={PositionsPaths.POSITION_DASHBOARD({
                            organizationId,
                            eventId,
                            positionId
                        })}
                        replace={true}
                    />
                </Route>

                <Route>
                    <Position organization={data.organization} />
                </Route>
            </Switch>
        )
    );
};
