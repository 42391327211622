import { TextMessageState } from '../generated/types';
import { IBadgeColorIcon } from './volunteerRegistration';

export function getStateBadgeColorIcon(state: TextMessageState): IBadgeColorIcon {
    switch (state) {
        case TextMessageState.Waiting:
            return { color: 'warning', leftIcon: 'hourglass-empty' };
        case TextMessageState.Sent:
        case TextMessageState.Delivered:
            return { color: 'success', leftIcon: 'check' };
        default:
            return { color: 'error', leftIcon: 'circle-exclamation' };
    }
}
