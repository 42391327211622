import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import * as React from 'react';

interface IFormColumnsProps {
    children: React.ReactNode;
    title: string;
}

export const FormColumns = (props: IFormColumnsProps) => (
    <Flex gap="7">
        <Box font="gray800 textSm medium" width={280}>
            {props.title}
        </Box>

        <Flex css={{ flex: '1' }} direction="column">
            {props.children}
        </Flex>
    </Flex>
);
