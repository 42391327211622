import { DetailBox } from 'common-front/src/designSystem/components/detailBox';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useMobileQuery } from 'common-front/src/hooks/useMobileQuery';
import { Flex } from 'common/src/designSystem/components/flex';
import { fromIconVO, I } from 'common/src/designSystem/components/i';
import {
    CustomFieldWithConditionFragment,
    PositionDashboardQuery
} from 'common/src/generated/types';
import { IntervalService } from 'common/src/services/intervalService';
import { useService } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { FieldService } from 'common/src/vo/field';
import * as React from 'react';
import { PositionColorMobile } from '../mobile/show/positionColorMobile';
import { PositionColor } from './positionColor';
import {
    Label,
    Parameter,
    ParameterSeparator,
    PositionParametersList,
    Value
} from './positionParametersList';

interface IPositionParametersProps {
    customFields: CustomFieldWithConditionFragment[];
    position: Pick<
        PositionDashboardQuery['event']['position'],
        'id' | 'positionCategoryId' | 'positionCategoryName' | 'color' | 'icon' | 'range' | 'fields'
    >;
}

export const PositionParameters = ({ customFields, position }: IPositionParametersProps) => {
    const onMobile = useMobileQuery();
    const {
        history,
        translate,
        params: { organizationId, eventId }
    } = useHeavent();
    const intervalService = useService(IntervalService);
    const fieldService = useService(FieldService);

    return (
        <DetailBox title={translate('param_tres_78420')}>
            <Flex direction="column" gap="4" width={1}>
                <PositionParametersList>
                    <Parameter>
                        <Label>{translate('cat_gorie_00291')}</Label>
                        <Value
                            {...(onMobile
                                ? {}
                                : {
                                      css: { cursor: 'pointer' },
                                      onClick: () => {
                                          history.push(
                                              HeaventPaths.POSITION_CATEGORY(
                                                  organizationId,
                                                  eventId,
                                                  position.positionCategoryId
                                              )
                                          );
                                      }
                                  })}
                        >
                            {position.positionCategoryName}
                        </Value>
                    </Parameter>
                    <ParameterSeparator direction="horizontal" />
                    <Parameter>
                        <Label>{translate('Color')}</Label>
                        <Value>
                            {onMobile ? (
                                <PositionColorMobile position={position} />
                            ) : (
                                <PositionColor position={position} />
                            )}
                        </Value>
                    </Parameter>
                    <ParameterSeparator direction="horizontal" />
                    <Parameter>
                        <Label>{translate('ic_ne_55554')}</Label>
                        <Value>
                            <I icon={fromIconVO(position.icon)} />
                        </Value>
                    </Parameter>
                    <ParameterSeparator direction="horizontal" />
                    <Parameter>
                        <Label>{translate('Date')}</Label>
                        <Value>
                            {onMobile
                                ? intervalService.toLocaleString(position.range, undefined, {
                                      year: 'numeric',
                                      month: 'long',
                                      day: 'numeric'
                                  })
                                : intervalService.toDisplayString(position.range)}
                        </Value>
                    </Parameter>

                    {customFields.map((cf) => {
                        if (position.fields[cf.slug]) {
                            return (
                                <React.Fragment key={cf.slug}>
                                    <ParameterSeparator direction="horizontal" />
                                    <Parameter>
                                        <Label>{cf.name}</Label>
                                        <Value>
                                            {fieldService.getValueString(cf, position, {
                                                fileReturnValue: 'name'
                                            })}
                                        </Value>
                                    </Parameter>
                                </React.Fragment>
                            );
                        } else {
                            return null;
                        }
                    })}
                </PositionParametersList>
            </Flex>
        </DetailBox>
    );
};
