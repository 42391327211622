import * as React from 'react';
import { Loader } from '../components/loader/loader';

const loader = <Loader />;

interface IUsePromiseResult<T> {
    isLoading: boolean;
    loader: JSX.Element | null;
    result: T;
}

export function usePromise<T>(promise: () => Promise<T>): IUsePromiseResult<T> {
    const [isLoading, setIsLoading] = React.useState(true);
    const [currentResult, setResult] = React.useState<T | null>(null);

    React.useEffect(() => {
        promise().then(
            (result) => {
                setResult(result);
                setIsLoading(false);
            },
            () => {
                setIsLoading(false);
            }
        );
    }, []);

    return {
        isLoading,
        loader: isLoading ? loader : null,
        result: currentResult!
    };
}
