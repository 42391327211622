import { groupBy, range as lodashRange, sortBy } from 'lodash-es';
import { DateTime } from 'luxon';
import * as React from 'react';
import XMark from 'svgComponents/fontawesome/xmark.svg';
import { Avatar } from '../../components/avatar/avatar';
import { Box } from '../../designSystem/components/box';
import { Flex } from '../../designSystem/components/flex';
import { DocumentPositionsVolunteersPlanningFragment } from '../../generated/types';
import { DateTimeService } from '../../services/dateTimeService';
import { useService, useTranslate } from '../../util/dependencies/dependencies';
import { getNumberOfDays } from '../../util/interval';
import { LocaleFormats } from '../../util/luxon';
import { filterSameDaySlots } from '../../vo/positionSlot';
import { AssignedCell, HeaderCell, NameCell, UnavailableCell } from '../cells';
import { PositionsVolunteersPlanningHeader } from './positionsVolunteersPlanningHeader';

const SLOT_WIDTH = 100;

interface IPositionsVolunteersPlanningPageProps {
    eventName: string;
    segmentName: string;
    slots: DocumentPositionsVolunteersPlanningFragment['positionsSlots']['nodes'];
    volunteersRegistrations: DocumentPositionsVolunteersPlanningFragment['volunteersRegistrations']['nodes'];
}

export const PositionsVolunteersPlanningPage = (props: IPositionsVolunteersPlanningPageProps) => {
    const translate = useTranslate();
    const dateTimeService = useService(DateTimeService);
    const numberOfDays = React.useMemo(() => {
        const interval = props.slots
            .map(({ range }) => range)
            .reduce(
                (previousValue, currentValue) => previousValue.union(currentValue),
                props.slots[0].range
            );

        return getNumberOfDays(interval);
    }, [props.slots]);
    const start = props.slots[0].range.start!;
    const getFilteredSlots = (day: DateTime) =>
        sortBy(filterSameDaySlots(day, props.slots), [
            (s) => s.position.name.toLowerCase(),
            (s) => s.range.start!.toMillis()
        ]);

    return (
        <Flex
            css={{
                background: 'white',
                pageBreakBefore: 'always'
            }}
            direction="column"
            width={1}
        >
            <PositionsVolunteersPlanningHeader
                eventName={props.eventName}
                segmentName={props.segmentName}
            />

            <Flex
                css={{
                    background: 'white',
                    padding: '$6'
                }}
                direction="column"
                width={1}
            >
                <Flex>
                    <HeaderCell width={SLOT_WIDTH * 2}>{translate('jours_88317')}</HeaderCell>

                    {lodashRange(0, numberOfDays).map((i) => {
                        const day = start.plus({ days: i });
                        const filteredSlots = filterSameDaySlots(day, props.slots);

                        return (
                            <HeaderCell key={i} width={(filteredSlots.length || 1) * SLOT_WIDTH}>
                                {dateTimeService.toLocaleString(
                                    day,
                                    LocaleFormats.DateOnly.MonthShort
                                )}
                            </HeaderCell>
                        );
                    })}
                </Flex>

                <Flex>
                    <HeaderCell width={SLOT_WIDTH * 2}>{translate('missions_63972')}</HeaderCell>

                    {lodashRange(0, numberOfDays).map((i) => {
                        const day = start.plus({ days: i });
                        const positions = sortBy(
                            Object.values(
                                groupBy(filterSameDaySlots(day, props.slots), (s) => s.position.id)
                            ).map((groupSlots) => ({
                                position: groupSlots[0].position,
                                numberOfSlots: groupSlots.length
                            })),
                            ({ position }) => position.name.toLowerCase()
                        );

                        if (positions.length === 0) {
                            return (
                                <UnavailableCell
                                    key={i}
                                    css={{ height: '40px' }}
                                    width={SLOT_WIDTH}
                                />
                            );
                        } else {
                            return positions.map(({ position, numberOfSlots }) => (
                                <HeaderCell
                                    key={`${i}-${position.id}`}
                                    width={numberOfSlots * SLOT_WIDTH}
                                >
                                    <Box
                                        css={{ ellipsis: true, px: '$1' }}
                                        textAlign="center"
                                        title={position.name}
                                        width={1}
                                    >
                                        {position.name}
                                    </Box>
                                </HeaderCell>
                            ));
                        }
                    })}
                </Flex>

                <Flex>
                    <HeaderCell width={SLOT_WIDTH * 2}>{translate('cr_neaux_33401')}</HeaderCell>

                    {lodashRange(0, numberOfDays).map((i) => {
                        const day = start.plus({ days: i });
                        const filteredSlots = getFilteredSlots(day);

                        if (filteredSlots.length === 0) {
                            return (
                                <UnavailableCell
                                    key={i}
                                    css={{ height: '40px' }}
                                    width={SLOT_WIDTH}
                                />
                            );
                        } else {
                            return filteredSlots.map((slot) => (
                                <HeaderCell key={`${i}-${slot.id}`} width={SLOT_WIDTH}>
                                    {slot.range.toFormat('HH:mm', { separator: '-' })}
                                </HeaderCell>
                            ));
                        }
                    })}
                </Flex>

                {props.volunteersRegistrations.map((volunteerRegistration) => {
                    const userInfo = volunteerRegistration.userInfo;

                    return (
                        <Flex key={volunteerRegistration.id}>
                            <NameCell width={SLOT_WIDTH * 2}>
                                <Avatar
                                    email={userInfo.email}
                                    image={userInfo.picture?.url}
                                    name={userInfo.name}
                                    size={24}
                                />

                                <Box css={{ flex: '1', ellipsis: '' }} title={userInfo.name}>
                                    {userInfo.name}
                                </Box>
                            </NameCell>

                            {lodashRange(0, numberOfDays).map((i) => {
                                const day = start.plus({ days: i });
                                const filteredSlots = getFilteredSlots(day);

                                if (filteredSlots.length === 0) {
                                    return <UnavailableCell key={i} width={SLOT_WIDTH} />;
                                } else {
                                    return filteredSlots.map((slot) => {
                                        const key = `${i}-${slot.id}`;
                                        const isAssigned =
                                            volunteerRegistration.positionsSlotsUsersInfos.find(
                                                ({ positionSlotId }) => positionSlotId === slot.id
                                            ) !== undefined;

                                        return isAssigned ? (
                                            <AssignedCell key={key} width={SLOT_WIDTH}>
                                                <Box>
                                                    <XMark />
                                                </Box>
                                            </AssignedCell>
                                        ) : (
                                            <UnavailableCell key={key} width={SLOT_WIDTH} />
                                        );
                                    });
                                }
                            })}
                        </Flex>
                    );
                })}
            </Flex>
        </Flex>
    );
};
