import { FormModal } from 'common-front/src/designSystem/components/formModal';
import { TextInput } from 'common-front/src/designSystem/form/textInput';
import { OrganizationId, Tag } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { useTagCreateMutation } from '../../generated/graphqlHooks';

interface ICreateTagModalProps {
    organizationId: OrganizationId;

    onClose(): void;
    onSuccess(tag: Pick<Tag, 'id' | 'name'>): void;
}

export const TagCreateModal = (props: ICreateTagModalProps) => {
    const translate = useTranslate();
    const { mutate } = useTagCreateMutation();

    return (
        <FormModal
            buttonText={translate('cr_er_82895')}
            icon="tag"
            initialValues={{ name: '' }}
            title={translate('cr_er_un_tag_59505')}
            onClose={props.onClose}
            onSubmit={async (values: { name: string }) => {
                const { tagCreate } = await mutate({
                    organizationId: props.organizationId,
                    tag: { name: values.name }
                });

                props.onSuccess(tagCreate);
                props.onClose();
            }}
        >
            <TextInput label={translate('nom_du_tag_95054')} name="name" />
        </FormModal>
    );
};
