import { getAccreditationLimits } from 'common-front/src/delegations/accreditations/getAccreditationLimits';
import { ProgressBar } from 'common-front/src/designSystem/components/progressBar';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { Row } from 'common/src/designSystem/components/table/row';
import { AccreditationSlotsQuery } from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { NumberService } from 'common/src/services/numberService';
import { useService } from 'common/src/util/dependencies/dependencies';
import { LocaleFormats } from 'common/src/util/luxon';
import { AccreditationsPaths } from 'common/src/util/paths/accreditationsPaths';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';

interface IAccreditationSlotsSlotRowProps {
    slot: AccreditationSlotsQuery['event']['accreditationsSlots']['nodes'][0];
}

export const AccreditationSlotsSlotRow = ({ slot }: IAccreditationSlotsSlotRowProps) => {
    const {
        history,
        params: { organizationId, eventId, accreditationId },
        translate
    } = useHeavent();
    const dateTimeService = useService(DateTimeService);
    const { assignedResources, maxResources, percent } = getAccreditationLimits(undefined, slot);
    const numberService = useService(NumberService);

    return (
        <Row
            css={{
                cursor: 'pointer',
                userSelect: 'none',
                '&:hover': {
                    background: '$gray100'
                }
            }}
            onClick={() =>
                history.push(
                    AccreditationsPaths.ACCREDITATION_SLOT({
                        organizationId,
                        eventId,
                        accreditationId,
                        accreditationSlotId: slot.id
                    })
                )
            }
        >
            <Cell>
                <Box font="gray900 textSm medium">
                    {isNonEmptyString(slot.name) ? slot.name : '-'}
                </Box>
            </Cell>

            <Cell>
                {slot.date
                    ? dateTimeService.toLocaleString(
                          slot.date,
                          LocaleFormats.DateOnly.WeekdayLongMonthLong
                      )
                    : '-'}
            </Cell>

            <Cell>
                <Flex
                    align="center"
                    css={{
                        '& > div': {
                            width: '100%'
                        }
                    }}
                    gap="2"
                    width={1}
                >
                    <Box>
                        {translate(
                            '_1_2_membres_04485',
                            assignedResources,
                            numberService.toNumberOrInfinity(maxResources)
                        )}
                    </Box>

                    {maxResources && <ProgressBar percent={percent!} />}
                </Flex>
            </Cell>
        </Row>
    );
};
