import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { CommonEnvVars } from '../../../envVars';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { isNonEmptyString } from '../../../util/string';

interface IBadgeSeattleProps {
    event: DocumentUserCustomBadgeFragment;
}

export const BadgeSeattle = (props: IBadgeSeattleProps) => {
    const vr = props.event.volunteerRegistration;
    const ui = vr.userInfo;
    const population = vr.accreditationsUsersInfos[0]?.accreditation.name ?? '';

    return (
        <>
            <Flex
                css={{
                    background: `url(https://assets.recrewteer.com/badges/christmas/bg_recto_Seattle.jpg) no-repeat`,
                    backgroundSize: 'cover',
                    fontFamily: '$montserrat',
                    position: 'relative'
                }}
                height={325}
                width={204}
            >
                <Box
                    css={{
                        left: '44px',
                        position: 'absolute',
                        top: '79px'
                    }}
                    height={133}
                    width={117}
                >
                    {isNonEmptyString(ui.picture?.url) && (
                        <img height="100%" src={ui.picture!.url} width="100%" />
                    )}
                </Box>

                <Box
                    css={{
                        color: '#3f9797',
                        fontSize: '12px',
                        fontWeight: '700',
                        left: '20px',
                        position: 'absolute',
                        textTransform: 'uppercase',
                        top: '237px'
                    }}
                >
                    {ui.lastName}
                </Box>

                <Box
                    css={{
                        color: '#3f9797',
                        fontSize: '12px',
                        left: '20px',
                        position: 'absolute',
                        textTransform: 'uppercase',
                        top: '252px'
                    }}
                >
                    {ui.firstName}
                </Box>

                <Box
                    css={{
                        color: '#514e66',
                        fontSize: '12px',
                        fontWeight: '700',
                        left: '20px',
                        position: 'absolute',
                        textTransform: 'uppercase',
                        top: '275px'
                    }}
                    width={110}
                >
                    {vr.delegation?.name ?? ''}
                </Box>

                <Box
                    css={{
                        background: 'white',
                        left: '139px',
                        padding: '2px',
                        position: 'absolute',
                        top: '259px'
                    }}
                    height={56}
                    width={56}
                >
                    <img
                        height="100%"
                        src={`https://api.${
                            CommonEnvVars.HEAVENT_EXTERNAL_DOMAIN
                        }/barcode/qr?text=${encodeURIComponent(
                            vr.ticketId
                        )}&color=000000&height=56&width=56`}
                        width="100%"
                    />
                </Box>
            </Flex>

            <Flex
                css={{
                    background: `url(https://assets.recrewteer.com/badges/christmas/seattle_verso.jpg) no-repeat`,
                    backgroundSize: 'cover',
                    fontFamily: '$montserrat',
                    pageBreakBefore: 'always',
                    position: 'relative'
                }}
                height={325}
                width={204}
            >
                <Box
                    css={{
                        color: '#f2e6d6',
                        fontSize: '36px',
                        fontWeight: '700',
                        left: '67px',
                        padding: '8px',
                        position: 'absolute',
                        textAlign: 'center',
                        textTransform: 'uppercase',
                        transform: 'rotate(90deg)',
                        transformOrigin: 'top left'
                    }}
                    width={325}
                >
                    {population}
                </Box>
            </Flex>
        </>
    );
};
