import { RadioRow } from 'common-front/src/designSystem/form/radioRow';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { IWeezeventConnectionValues } from 'common/src/input/weezeventInput';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { useWeezeventTicketsQuery } from '../../../../generated/graphqlHooks';

interface IWeezeventConnectionStep3Props {
    values: IWeezeventConnectionValues;
}

export const WeezeventConnectionStep3 = (props: IWeezeventConnectionStep3Props) => {
    const translate = useTranslate();
    const { data, loader } = useWeezeventTicketsQuery({
        apiKey: props.values.weezevent.apiKey,
        email: props.values.weezevent.email,
        password: props.values.weezevent.password,
        idEvent: props.values.weezevent.idEvent
    });

    return (
        loader || (
            <>
                <Box color="gray900" fontWeight="medium">
                    {translate('quel_tarif_souh_17826')}
                </Box>

                <Flex css={{ overflowY: 'auto' }} direction="column">
                    {data.weezevent.tickets.map((ticket) => (
                        <React.Fragment key={ticket.id}>
                            <Spacer height="3" />

                            <RadioRow
                                label={ticket.name}
                                name="weezevent.idTicket"
                                value={ticket.id}
                            />
                        </React.Fragment>
                    ))}
                </Flex>
            </>
        )
    );
};
