import { Form } from 'common-front/src/components/form/form';
import { Button } from 'common-front/src/designSystem/components/button';
import { TextInput } from 'common-front/src/designSystem/form/textInput';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { AuthInputService, ICodeValues } from 'common/src/input/authInput';
import { ValidateService } from 'common/src/services/validateService';
import { useHistory, useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import { AuthPage } from './authPage';

interface ICodeProps {
    email: string;
    isRestartVisible: boolean;
    buttonText: string;

    onSubmit(code: string): void;
    onFailure(): void;
}

export const Code = (props: ICodeProps) => {
    const translate = useTranslate();
    const authInput = useService(AuthInputService);
    const validateService = useService(ValidateService);
    const history = useHistory();

    if (props.email.length === 0) {
        history.push(HeaventPaths.AUTH_SIGN_IN_EMAIL);
    }

    return (
        <AuthPage>
            <Form
                direction="column"
                initialValues={{
                    code: ''
                }}
                render={({ handleSubmit, hasValidationErrors, submitting }) => (
                    <>
                        <Box color="white" fontSize="displaySm" fontWeight="semiBold">
                            {translate('saisissez_votre_20218')}
                        </Box>

                        <Spacer height="3" />

                        <Box color="white" fontSize="textMd">
                            {translate('il_s_agit_du_co_07957', props.email)}
                        </Box>

                        <Spacer height="7" />

                        <TextInput
                            autoComplete="one-time-code"
                            label={translate('code_20843')}
                            name="code"
                            placeholder={translate('code_20843')}
                            state={props.isRestartVisible ? 'disabled' : undefined}
                            type="number"
                        />

                        <Spacer height="6" />

                        {props.isRestartVisible ? (
                            <Button onClick={props.onFailure}>
                                <Box textAlign="center" width={1}>
                                    {translate('recommencer_14712')}
                                </Box>
                            </Button>
                        ) : (
                            <Button
                                disabled={hasValidationErrors}
                                isLoading={submitting}
                                onClick={handleSubmit}
                            >
                                <Box textAlign="center" width={1}>
                                    {props.buttonText}
                                </Box>
                            </Button>
                        )}

                        <Spacer height="7" />

                        <Flex gap="1" justify="center" width={1}>
                            <Box color="white">{translate('vous_n_avez_pas_69078')}</Box>

                            <Box
                                color="white"
                                css={{ cursor: 'pointer' }}
                                onClick={() => {
                                    history.push(HeaventPaths.AUTH_SIGN_IN_EMAIL);
                                }}
                            >
                                {translate('r_essayer_21582')}
                            </Box>
                        </Flex>
                    </>
                )}
                validate={(values: ICodeValues) =>
                    validateService.validateForForm(authInput.codeSchema())(values)
                }
                onSubmit={(values: ICodeValues) => props.onSubmit(values.code)}
            />
        </AuthPage>
    );
};
