import * as React from 'react';
import { INumberInputProps } from './input/commonInputProps';
import { TextInput } from './textInput';

export const FloatInput = ({ value: _value, onChange, ...rest }: INumberInputProps) => {
    const [value, setValue] = React.useState(_value?.toString() ?? '');
    const [savedValue, setSavedValue] = React.useState<number | null>(_value);

    if (savedValue !== _value) {
        setValue(_value?.toString() ?? '');
        setSavedValue(_value);
    }

    return (
        <TextInput
            value={value}
            onChange={(newValue: string) => {
                const floatValue = parseFloat(newValue);

                setValue(newValue);
                setSavedValue(isNaN(floatValue) ? null : floatValue);
                onChange(isNaN(floatValue) ? null : floatValue);
            }}
            {...rest}
        />
    );
};
