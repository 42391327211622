import { Empty } from 'common-front/src/components/empty/empty';
import { useParams } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import { useEventsMobileQuery } from '../../generated/graphqlHooks';

export const EventsMobile = () => {
    const { organizationId } = useParams();
    const { loader, data } = useEventsMobileQuery({ organizationId });

    return (
        loader || (
            <Empty
                path={HeaventPaths.DASHBOARD_EVENT_SWITCH(
                    organizationId,
                    data.organization.events.nodes[0].id
                )}
            />
        )
    );
};
