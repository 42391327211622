import { Blank } from 'common-front/src/components/blank/blank';
import { Button } from 'common-front/src/designSystem/components/button';
import { ButtonDropdown } from 'common-front/src/designSystem/components/buttonDropdown';
import { Dropdown } from 'common-front/src/designSystem/components/dropdown/dropdown';
import { ItemIcon } from 'common-front/src/designSystem/components/dropdown/item';
import { Menu } from 'common-front/src/designSystem/components/dropdown/menu';
import { Trigger } from 'common-front/src/designSystem/components/dropdown/trigger';
import { Svgs } from 'common-front/src/util/assets';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    FormCustomFieldFragment,
    FormElementsQuery,
    FormElementType
} from 'common/src/generated/types';
import { reorder } from 'common/src/util/array';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { uuidv4 } from 'common/src/util/uuid';
import { produce } from 'immer';
import * as React from 'react';
import { CreateCustomField } from '../../../customFields/create/createUpdateCustomField';
import { useFormElementsUpdateMutation } from '../../../generated/graphqlHooks';
import { useFormContext } from '../../show/formContext';
import { AddFieldsMenu } from './addFieldsMenu';
import { AddSectionModal } from './addSectionModal';
import { AddTextModal } from './addTextModal';
import { FormElementsList } from './formElementsList';

interface FormElementsContent {
    customFields: FormCustomFieldFragment[];
    initialElements: FormElementsQuery['organization']['form']['elements'];
}

export const FormElementsContent = (props: FormElementsContent) => {
    const translate = useTranslate();
    const { eventId, formId, isSaving, organizationId, setIsSaving } = useFormContext();
    const { mutate } = useFormElementsUpdateMutation();
    const [elements, setElements] = React.useState(props.initialElements);
    const [addSectionVisible, setAddSectionVisible] = React.useState(false);
    const [addTextVisible, setAddTextVisible] = React.useState(false);
    const [isCreatecustomFieldOpen, setIsCreatecustomFieldOpen] = React.useState(false);
    const [customFields, setCustomFields] = React.useState(props.customFields);
    const selectedCustomFieldsIds = React.useMemo(
        () =>
            new Set(
                elements.flatMap((element) => {
                    if (element.elementType === FormElementType.Field) {
                        return [element.customFieldId!];
                    } else {
                        return [];
                    }
                })
            ),
        [elements]
    );
    const onDragEnd = React.useCallback(
        ({ reason, source, destination }) => {
            if (reason === 'DROP' && destination && source.index !== destination.index) {
                setElements(reorder(elements, source.index, destination.index));
            }
        },
        [elements, setElements]
    );
    const fieldToggle = React.useCallback(
        (field: FormCustomFieldFragment) => {
            if (selectedCustomFieldsIds.has(field.id)) {
                setElements(
                    elements.filter((e) => !e.customFieldId || e.customFieldId !== field.id)
                );
            } else {
                setElements([
                    ...elements,
                    {
                        id: uuidv4(),
                        elementType: FormElementType.Field,
                        customFieldId: field.id,
                        isMandatory: true
                    }
                ]);
            }
        },
        [elements, setElements, selectedCustomFieldsIds]
    );
    const fieldsToggleMandatory = React.useCallback(
        (index: number, isMandatory: boolean) => {
            setElements(
                produce((currentElements) => {
                    currentElements[index].isMandatory = isMandatory;
                })
            );
        },
        [setElements]
    );
    const sectionOnAdd = React.useCallback(
        (name: string) => {
            setElements([
                ...elements,
                {
                    id: uuidv4(),
                    elementType: FormElementType.Section,
                    section: name
                }
            ]);
        },
        [elements, setElements]
    );
    const sectionOnRename = React.useCallback(
        (index: number, name: string) => {
            setElements(
                produce((currentElements) => {
                    currentElements[index].section = name;
                })
            );
        },
        [setElements]
    );
    const textOnAdd = React.useCallback(
        (text: string) => {
            setElements([
                ...elements,
                {
                    id: uuidv4(),
                    elementType: FormElementType.Text,
                    text
                }
            ]);
        },
        [elements, setElements]
    );
    const textOnEdit = React.useCallback(
        (index: number, text: string) => {
            setElements(
                produce((currentElements) => {
                    currentElements[index].text = text;
                })
            );
        },
        [setElements]
    );
    const onDelete = React.useCallback(
        (index: number) => {
            setElements(
                produce((currentElements) => {
                    currentElements.splice(index, 1);
                })
            );
        },
        [setElements]
    );
    const onSave = React.useCallback(async () => {
        await mutate({
            organizationId,
            eventId,
            formId,
            elements: elements.map((element, index) => ({
                id: element.id,
                elementType: element.elementType,
                customFieldId: element.customFieldId,
                section: element.section,
                text: element.text,
                isMandatory: element.isMandatory,
                index
            }))
        });

        setIsSaving(false);
    }, [elements, mutate, setIsSaving]);
    const updateCustomField = React.useCallback(
        (customField: FormCustomFieldFragment) => {
            setCustomFields(
                customFields.map((cf) => (cf.id === customField.id ? customField : cf))
            );
        },
        [customFields]
    );

    React.useEffect(() => {
        if (isSaving) {
            onSave();
        }
    }, [isSaving]);

    return (
        <>
            {elements.length === 0 ? (
                <Box height={0.5} width={1}>
                    <Blank
                        imageSrc={Svgs.FormsElementsBlank}
                        subtitle={translate('ajouter_des_cha_37944')}
                        title={translate('aucun_champ_ou_18338')}
                    />
                </Box>
            ) : (
                <FormElementsList
                    customFields={customFields}
                    elements={elements}
                    fieldsToggleMandatory={fieldsToggleMandatory}
                    sectionOnRename={sectionOnRename}
                    textOnEdit={textOnEdit}
                    updateCustomField={updateCustomField}
                    onDelete={onDelete}
                    onDragEnd={onDragEnd}
                />
            )}

            <Spacer height="6" />

            <Flex gap="4" justify={elements.length === 0 ? 'center' : 'start'} width={1}>
                <Dropdown>
                    <Trigger>
                        <ButtonDropdown
                            menu={
                                <>
                                    <ItemIcon
                                        icon="pen-field"
                                        onClick={() => {
                                            setIsCreatecustomFieldOpen(true);
                                        }}
                                    >
                                        {translate('cr_eer_un_champ_08883')}
                                    </ItemIcon>
                                </>
                            }
                        >
                            {translate('ajouter_des_cha_35843')}
                        </ButtonDropdown>
                    </Trigger>

                    <Menu css={{ padding: '0' }} placement="top-start" width={400}>
                        <AddFieldsMenu
                            customFields={customFields}
                            selectedCustomFieldsIds={selectedCustomFieldsIds}
                            toggle={fieldToggle}
                        />
                    </Menu>
                </Dropdown>

                <Button
                    color="white"
                    onClick={() => {
                        setAddSectionVisible(true);
                    }}
                >
                    {translate('ajouter_une_sec_90063')}
                </Button>

                <Button
                    color="white"
                    onClick={() => {
                        setAddTextVisible(true);
                    }}
                >
                    {translate('ajouter_un_cham_31151')}
                </Button>
            </Flex>

            {addSectionVisible && (
                <AddSectionModal
                    initialName=""
                    onAdd={sectionOnAdd}
                    onClose={() => {
                        setAddSectionVisible(false);
                    }}
                />
            )}

            {addTextVisible && (
                <AddTextModal
                    initialText=""
                    onAdd={textOnAdd}
                    onClose={() => {
                        setAddTextVisible(false);
                    }}
                />
            )}

            {isCreatecustomFieldOpen && (
                <CreateCustomField
                    isFromForm={true}
                    organizationId={organizationId}
                    onClose={() => {
                        setIsCreatecustomFieldOpen(false);
                    }}
                    onSuccess={async (customField: FormCustomFieldFragment) => {
                        setCustomFields([...customFields, customField]);

                        setTimeout(() => {
                            fieldToggle(customField);
                            onSave();
                        });

                        setIsCreatecustomFieldOpen(false);
                    }}
                />
            )}
        </>
    );
};
