import { Tab } from 'common-front/src/designSystem/components/tabs/tab';
import { TabList } from 'common-front/src/designSystem/components/tabs/tabList';
import { TabPanel } from 'common-front/src/designSystem/components/tabs/tabPanel';
import { Tabs } from 'common-front/src/designSystem/components/tabs/tabs';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Feature } from 'common/src/generated/types';
import { PathComponents } from 'common/src/util/paths/commonPaths';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';
import { useEventContext } from '../../events/show/eventContext';
import {
    useEventUserProfileTabsQuery,
    useOrganizationUserProfileTabsQuery
} from '../../generated/graphqlHooks';
import { useOrganizationContext } from '../../organization/organizationContext';
import { UserProfileAccreditations } from './accreditations/userProfileAccreditations';
import { EventUserProfileActions } from './actions/eventUserProfileActions';
import { OrganizationUserProfileActions } from './actions/organizationUserProfileActions';
import { UserProfileHistory } from './history/userProfileHistory';
import {
    UserProfileInformationsEvent,
    UserProfileInformationsOrganization
} from './informations/userProfileInformations';
import { UserProfileComments } from './userProfileComments';

interface IUserProfileTabsProps {
    basePath: string;
    isWeezeventConnected: boolean;
    numberOfAccreditations: number;
    numberOfAssignments: number;
    numberOfComments: number;
    showAccreditations: boolean;
    showActions: boolean;
    showComments: boolean;
}

const UserProfileTabs = (props: IUserProfileTabsProps) => {
    const {
        translate,
        params: { organizationId, eventId, userInfoId }
    } = useHeavent();

    return (
        <Tabs>
            <TabList>
                <Tab path={`${props.basePath}/${PathComponents.SECTIONS.INFORMATION}`}>
                    {translate('informations_22954')}
                </Tab>

                {eventId && (
                    <Tab path={`${props.basePath}/positions`}>
                        {translate('missions_1_38931', props.numberOfAssignments)}
                    </Tab>
                )}

                {props.showAccreditations && (
                    <Tab path={`${props.basePath}/accreditations`}>
                        {translate('accr_ditations_45867', props.numberOfAccreditations)}
                    </Tab>
                )}

                {eventId && props.isWeezeventConnected && (
                    <Tab path={`${props.basePath}/integrations`}>
                        {translate('int_grations_88619')}
                    </Tab>
                )}

                {props.showActions && (
                    <Tab path={`${props.basePath}/actions`}>{translate('actions_21689')}</Tab>
                )}

                {props.showComments && (
                    <Tab path={`${props.basePath}/comments`}>
                        {translate('commentaires_97857', props.numberOfComments)}
                    </Tab>
                )}

                <Tab
                    basePath={`${props.basePath}/history`}
                    path={`${props.basePath}/history/campaigns`}
                >
                    {translate('historique_06296')}
                </Tab>
            </TabList>

            <TabPanel path={`${props.basePath}/${PathComponents.SECTIONS.INFORMATION}`}>
                {eventId ? (
                    <UserProfileInformationsEvent
                        eventId={eventId}
                        organizationId={organizationId}
                        userInfoId={userInfoId}
                    />
                ) : (
                    <UserProfileInformationsOrganization
                        organizationId={organizationId}
                        userInfoId={userInfoId}
                    />
                )}
            </TabPanel>

            {props.showAccreditations && (
                <TabPanel path={`${props.basePath}/accreditations`}>
                    <UserProfileAccreditations
                        eventId={eventId}
                        organizationId={organizationId}
                        userInfoId={userInfoId}
                    />
                </TabPanel>
            )}

            {props.showActions && (
                <TabPanel path={`${props.basePath}/actions`}>
                    {eventId ? (
                        <EventUserProfileActions
                            isWeezeventConnected={props.isWeezeventConnected}
                        />
                    ) : (
                        <OrganizationUserProfileActions />
                    )}
                </TabPanel>
            )}

            {props.showComments && (
                <TabPanel path={`${props.basePath}/comments`}>
                    <UserProfileComments
                        eventId={eventId}
                        organizationId={organizationId}
                        userInfoId={userInfoId}
                    />
                </TabPanel>
            )}

            <TabPanel path={`${props.basePath}/history`}>
                <UserProfileHistory
                    basePath={props.basePath}
                    eventId={eventId}
                    organizationId={organizationId}
                    userInfoId={userInfoId}
                />
            </TabPanel>
        </Tabs>
    );
};

interface IEventUserProfileTabsProps {
    basePath: string;
}

export const EventUserProfileTabs = (props: IEventUserProfileTabsProps) => {
    const {
        params: { eventId, userInfoId }
    } = useHeavent();
    const { hasFeature } = useOrganizationContext();
    const {
        event: {
            permissions: { isEventAdmin }
        },
        isPositionAdmin
    } = useEventContext();
    const { data, loader } = useEventUserProfileTabsQuery({ eventId, userInfoId });

    return (
        loader || (
            <UserProfileTabs
                basePath={props.basePath}
                isWeezeventConnected={isNonEmptyString(
                    data.event?.weezeventConnectionInfos?.apiKey
                )}
                numberOfAccreditations={data.event.volunteerRegistration.numberOfAccreditations}
                numberOfAssignments={data.event.volunteerRegistration.numberOfAssignments}
                numberOfComments={data.event.volunteerRegistration.userInfo.numberOfComments}
                showAccreditations={hasFeature(Feature.Accreditation) && isEventAdmin}
                showActions={isPositionAdmin()}
                showComments={isEventAdmin}
            />
        )
    );
};

interface IOrganizationUserProfileTabsProps {
    basePath: string;
}

export const OrganizationUserProfileTabs = (props: IOrganizationUserProfileTabsProps) => {
    const {
        params: { organizationId, userInfoId }
    } = useHeavent();
    const { data, loader } = useOrganizationUserProfileTabsQuery({
        organizationId,
        userInfoId
    });

    return (
        loader || (
            <UserProfileTabs
                basePath={props.basePath}
                isWeezeventConnected={false}
                numberOfAccreditations={0}
                numberOfAssignments={0}
                numberOfComments={data.organization.userInfo.numberOfComments}
                showAccreditations={false}
                showActions={true}
                showComments={true}
            />
        )
    );
};
