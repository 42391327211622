import { API_BASE_URL } from 'common-front/src/consts';
import { Button } from 'common-front/src/designSystem/components/button';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { CustomFieldSlug, FieldType } from 'common/src/generated/types';
import * as React from 'react';
import { useUserFieldUpdateQuery } from './generated/graphqlHooks';

export const UserFieldUpdate = () => {
    const {
        translate,
        params: { organizationId, userInfoId, customFieldSlug, value }
    } = useHeavent<{ customFieldSlug: CustomFieldSlug; value: any }>();
    const { data, loader } = useUserFieldUpdateQuery({ organizationId, slug: customFieldSlug });
    const name = data.organization?.customField.name ?? '';
    const fieldType = data.organization?.customField.fieldType ?? FieldType.Text;
    const valueString =
        fieldType === FieldType.Checkbox
            ? value === 'true'
                ? translate('oui_54361')
                : translate('non_33516')
            : value;
    const link = `${API_BASE_URL}users/${userInfoId}/field/${customFieldSlug}/update/${value}`;

    return (
        <Flex
            align="center"
            css={{
                background: '$gray50',
                overflow: 'hidden'
            }}
            height={1}
            justify="center"
            width={1}
        >
            {loader || (
                <Flex
                    align="center"
                    css={{
                        background: 'white',
                        border: '1px solid $gray200',
                        borderRadius: '$2',
                        boxShadow: '$xs',
                        padding: '$7'
                    }}
                    direction="column"
                    width={640}
                >
                    <Box font="gray900 textXl semiBold">
                        {translate('mise_jour_de_17055', name)}
                    </Box>

                    <Spacer height="1" />

                    <Box color="gray500" textAlign="center">
                        {translate('en_cliquant_sur_60846', name, valueString)}
                    </Box>

                    <Spacer height="7" />

                    <Box width={1}>
                        <Button
                            textAlign="center"
                            onClick={() => {
                                window.open(link);
                            }}
                        >
                            {translate('mettre_jour_l_79460')}
                        </Button>
                    </Box>
                </Flex>
            )}
        </Flex>
    );
};
