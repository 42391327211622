import { Images } from 'common-front/src/util/assets';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import * as React from 'react';

interface IAuthPageProps {
    children: React.ReactNode;
}

export const AuthPage = (props: IAuthPageProps) => (
    <Flex align="center" height={1} width={1}>
        <Flex
            align="center"
            css={{
                background: '$primary700',
                color: 'white',
                flex: '1',
                fontSize: '28px'
            }}
            direction="column"
            height={1}
            justify="center"
        >
            <Box textAlign="center">
                Basile, la solution de gestion bénévoles <br />
                pour les organisateurs.
            </Box>

            <Spacer height="9" />

            <Box textAlign="center">
                Notre mission est de : <br /> <b>Mobiliser</b>, <b>Encadrer</b> et <b>Fidéliser</b>{' '}
                <br />
                votre communauté de bénévoles.
            </Box>
        </Flex>

        <Flex align="center" css={{ flex: '1' }} direction="column" height={1}>
            <Spacer height="10" />

            <Box>
                <img alt="Logo Basile" height="100" src={Images.AmicaleLogo} />
            </Box>

            <Box height={148} />

            <Flex direction="column" width={360}>
                {props.children}
            </Flex>
        </Flex>
    </Flex>
);
