import { theme } from '../designSystem/components/stitches';
import {
    AsoassignmentDocumentOptions,
    BadgeDocumentOptions,
    BruleursDocumentOptions,
    CertificateDocumentOptions,
    OrganizationCertificateDefaultFragment,
    PdfFormat,
    PecbadgeDocumentOptions,
    PositionDocumentOptions,
    T24assignmentDocumentOptions,
    UserAssignmentsDocumentOptions,
    UserPlanningDaysOptions,
    UserPlanningListOptions,
    VolunteersListingDocumentOptions
} from '../generated/types';

export function getDefaultFileOptions() {
    return {
        name: '',
        content: ''
    };
}

export function getDefaultBadgeOptions(): BadgeDocumentOptions {
    return {
        format: PdfFormat.A6Portrait,
        logo: getDefaultFileOptions(),
        color: theme.colors.gray900.value,
        name: '',
        infos: '',
        showAssignments: true
    };
}

export function getDefaultPositionOptions(): PositionDocumentOptions {
    return {
        format: PdfFormat.A4Portrait,
        showPositionData: true,
        showVolunteersLists: true,
        slugsToDisplay: ['fullName', 'phone']
    };
}

export function getDefaultVolunteersListingOptions(): VolunteersListingDocumentOptions {
    return {
        header: getDefaultFileOptions(),
        slugsToDisplay: ['fullName', 'phone']
    };
}

export function getDefaultCertificateOptions(
    options: OrganizationCertificateDefaultFragment
): CertificateDocumentOptions {
    return {
        address: `${options.name}\n${options.street || ''}\n${options.postalCode || ''}\n${
            options.city || ''
        }`.trim(),
        header: getDefaultFileOptions(),
        infos: '',
        name: options.adminName || '',
        place: 'Montpellier',
        signature: getDefaultFileOptions(),
        title: `Président de ${options.name}`
    };
}

export function getDefaultUserPlanningDaysOptions(): UserPlanningDaysOptions {
    return {
        logo: getDefaultFileOptions()
    };
}

export function getDefaultUserPlanningListOptions(): UserPlanningListOptions {
    return {
        showHours: true
    };
}

export function getDefaultUserAssignmentsOptions(): UserAssignmentsDocumentOptions {
    return {
        header: getDefaultFileOptions(),
        headerText: '',
        leadersData: 'both'
    };
}

export function getDefaultAsoassignmentOptions(): AsoassignmentDocumentOptions {
    return {
        background: getDefaultFileOptions(),
        color: '#005943',
        introText: '',
        logo: getDefaultFileOptions(),
        outroText: '',
        signature: ''
    };
}

export function getDefaultPecbadgeOptions(): PecbadgeDocumentOptions {
    return {
        header: getDefaultFileOptions()
    };
}

export function getDefaultT24assignmentOptions(): T24assignmentDocumentOptions {
    return {
        logo: getDefaultFileOptions()
    };
}

export function getDefaultBruleursDocumentOptions(): BruleursDocumentOptions {
    return {
        logo: getDefaultFileOptions()
    };
}
