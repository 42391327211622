import { DateInput } from 'common-front/src/designSystem/form/date/dateInput';
import { RichSelect } from 'common-front/src/designSystem/form/richSelect';
import { Select } from 'common-front/src/designSystem/form/select/select';
import { TextInput } from 'common-front/src/designSystem/form/textInput';
import { DelegationProperty, Form, Tag } from 'common/src/generated/types';
import { isNonEmptyArray } from 'common/src/util/array';
import { assertUnreachable } from 'common/src/util/assertUnreachable';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { isNonEmptyString } from 'common/src/util/string';
import { DateTime } from 'luxon';
import * as React from 'react';

interface IDelegationsMassEditFieldInputProps {
    forms: Array<Pick<Form, 'id' | 'name'>>;
    property: DelegationProperty;
    tags: Array<Pick<Tag, 'id' | 'name'>>;
    value: any;

    change(name: string, value: any): void;
}

export const DelegationsMassEditFieldInput = (props: IDelegationsMassEditFieldInputProps) => {
    const translate = useTranslate();

    React.useEffect(() => {
        switch (props.property) {
            case DelegationProperty.DeadlineDate:
                if (!DateTime.isDateTime(props.value) && !isNonEmptyString(props.value)) {
                    props.change('massEdit.value', DateTime.invalid('Invalid'));
                }
                break;
            case DelegationProperty.Form:
                if (typeof props.value !== 'number') {
                    props.change('massEdit.value', props.forms[0].id);
                }
                break;
            case DelegationProperty.MaxResources:
                if (typeof props.value !== 'number') {
                    props.change('massEdit.value', 0);
                }
                break;
            case DelegationProperty.Name:
                props.change('massEdit.value', '');
                break;
            case DelegationProperty.ShowInDelegationSpace:
            case DelegationProperty.ShowFormLinkInDelegationSpace:
                if (typeof props.value !== 'boolean') {
                    props.change('massEdit.value', true);
                }
                break;
            case DelegationProperty.Tag:
                if (!isNonEmptyArray(props.value)) {
                    props.change('massEdit.value', []);
                }
                break;
            default:
                assertUnreachable(props.property);
        }
    }, [props.property, props.value]);

    switch (props.property) {
        case DelegationProperty.DeadlineDate:
            return <DateInput name="massEdit.value" />;
        case DelegationProperty.Form:
            return (
                <RichSelect isSearchVisible={true} multiple={false} name="massEdit.value">
                    {props.forms.map((form) => (
                        <option key={form.id} value={form.id}>
                            {form.name}
                        </option>
                    ))}
                </RichSelect>
            );
        case DelegationProperty.MaxResources:
            return <TextInput name="massEdit.value" shouldParseAsInt={true} />;
        case DelegationProperty.Name:
            throw new Error('Should not happen');
        case DelegationProperty.ShowFormLinkInDelegationSpace:
            return (
                <Select name="massEdit.value" shouldParseAsBoolean={true}>
                    <option value="true">{translate('oui_54361')}</option>
                    <option value="false">{translate('non_33516')}</option>
                </Select>
            );
        case DelegationProperty.ShowInDelegationSpace:
            return (
                <Select name="massEdit.value" shouldParseAsBoolean={true}>
                    <option value="true">{translate('oui_54361')}</option>
                    <option value="false">{translate('non_33516')}</option>
                </Select>
            );
        case DelegationProperty.Tag:
            return (
                <RichSelect isSearchVisible={true} multiple={true} name="massEdit.value">
                    {props.tags.map((tag) => (
                        <option key={tag.id} value={tag.id}>
                            {tag.name}
                        </option>
                    ))}
                </RichSelect>
            );
        default:
            return assertUnreachable(props.property);
    }
};
