import { Button } from 'common-front/src/designSystem/components/button';
import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { useStateDebounce } from 'common-front/src/hooks/useStateDebounce';
import { AvatarNameEmail } from 'common/src/components/avatarNameEmail';
import { Badge } from 'common/src/designSystem/components/badge';
import { Flex } from 'common/src/designSystem/components/flex';
import {
    EventId,
    LeaderInfosFragment,
    LeaderUserInfoFragment,
    OrganizationId,
    UsersInfoId
} from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';
import { usePopper } from 'react-popper';
import { useCollaboratorsVolunteersQuery } from '../../generated/graphqlHooks';

interface IAddLeadersProps {
    currentLeaders: LeaderInfosFragment[];
    eventId: EventId;
    organizationId: OrganizationId;

    onAdd(newLeader: LeaderUserInfoFragment): void;
}

export const AddLeaders = (props: IAddLeadersProps) => {
    const translate = useTranslate();
    const [search, searchDebounced, setSearch] = useStateDebounce('');
    const { data, loader } = useCollaboratorsVolunteersQuery({
        organizationId: props.organizationId,
        eventId: props.eventId,
        name: isNonEmptyString(searchDebounced) ? searchDebounced : undefined
    });
    const [isOpen, setIsOpen] = React.useState(false);
    const [referenceElement, setReferenceElement] = React.useState<HTMLDivElement | null>(null);
    const [popperElement, setPopperElement] = React.useState<HTMLDivElement | null>(null);
    const { styles, attributes, update } = usePopper(referenceElement, popperElement, {
        placement: 'bottom-start',
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [0, 8]
                }
            }
        ]
    });
    const width = React.useMemo(
        () => referenceElement?.getBoundingClientRect().width ?? 100,
        [referenceElement]
    );
    const usersInfos = React.useMemo(() => {
        const isDisabled = (id: UsersInfoId) =>
            props.currentLeaders.find((l) => l.userInfo.id === id) !== undefined;

        return (data.event?.collaborators ?? [])
            .map(({ userInfo }) => ({
                ...userInfo,
                isAdmin: true,
                isDisabled: isDisabled(userInfo.id)
            }))
            .concat(
                (data.event?.volunteersRegistrations.nodes ?? []).map(({ userInfo }) => ({
                    ...userInfo,
                    isAdmin: false,
                    isDisabled: isDisabled(userInfo.id)
                }))
            );
    }, [data.event, props.currentLeaders]);

    React.useEffect(() => {
        update?.();
    }, [update, isOpen, referenceElement, usersInfos]);

    return (
        <>
            <Flex
                ref={setReferenceElement}
                onClick={(e) => {
                    e.nativeEvent.stopImmediatePropagation();
                    e.stopPropagation();

                    setIsOpen(!isOpen);
                }}
            >
                <TextInput
                    icon="magnifying-glass"
                    placeholder={translate('rechercher_un_m_17775')}
                    value={search}
                    onChange={setSearch}
                />
            </Flex>

            <Flex
                ref={setPopperElement}
                css={{
                    background: 'white',
                    border: '1px solid $gray100',
                    borderRadius: '$2',
                    boxShadow: '$md',
                    display: isOpen ? 'flex' : 'none',
                    maxHeight: '360px',
                    overflowY: 'auto',
                    py: '$2',
                    zIndex: 1000,
                    ...styles.popper
                }}
                direction="column"
                width={width}
                onClick={(e) => {
                    e.nativeEvent.stopImmediatePropagation();
                    e.stopPropagation();
                }}
                {...attributes.poppeer}
            >
                {loader ||
                    usersInfos.map((userInfo) => (
                        <Flex
                            key={userInfo.id}
                            align="center"
                            css={{
                                cursor: userInfo.isDisabled ? 'initial' : 'pointer',
                                padding: '10px $4',
                                userSelect: 'none',
                                '&:hover': {
                                    background: userInfo.isDisabled ? 'inherit' : '$primary100'
                                }
                            }}
                            gap="3"
                        >
                            <AvatarNameEmail
                                badge={
                                    userInfo.isAdmin && (
                                        <Badge color="primary">
                                            {translate('administrateur_38490')}
                                        </Badge>
                                    )
                                }
                                disabled={userInfo.isDisabled}
                                hasName={isNonEmptyString(userInfo.name)}
                                userInfo={userInfo}
                            />

                            {!userInfo.isDisabled && (
                                <Button
                                    color="white"
                                    leftIcon="plus"
                                    size="sm"
                                    onClick={() => {
                                        props.onAdd(userInfo);
                                    }}
                                />
                            )}
                        </Flex>
                    ))}
            </Flex>
        </>
    );
};
