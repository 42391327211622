import { Button } from 'common-front/src/designSystem/components/button';
import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useSegmentsContext } from 'common-front/src/segments/segmentsContext';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import * as React from 'react';

export const EventsSearchFilters = () => {
    const { translate } = useHeavent();
    const {
        isAdmin,
        isSaveVisible,
        name,
        setIsFilterOpen,
        setIsSaveSegmentVisible,
        setName,
        setNameDebounced,
        setSelectedFilterPredicate
    } = useSegmentsContext();

    return (
        <Flex
            css={{
                background: '$gray100',
                borderRadius: '$2',
                padding: '$5'
            }}
            gap="3"
        >
            <Box css={{ flex: '1' }}>
                <TextInput
                    icon="magnifying-glass"
                    placeholder={translate('rechercher_un_40024')}
                    value={name}
                    onChange={(newName: string) => {
                        setName(newName);
                        setNameDebounced(newName);
                    }}
                />
            </Box>

            <Button
                color="white"
                leftIcon="bars-filter"
                onClick={() => {
                    setSelectedFilterPredicate(null);
                    setIsFilterOpen(true);
                }}
            >
                {translate('filtres_64574')}
            </Button>

            {isAdmin && isSaveVisible && (
                <Flex
                    align="center"
                    css={{ color: '$primary700', cursor: 'pointer' }}
                    onClick={() => {
                        setIsSaveSegmentVisible(true);
                    }}
                >
                    {translate('enregistrer_la_22884')}
                </Flex>
            )}
        </Flex>
    );
};
