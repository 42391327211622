import { PositionId, PositionsCategoryId, PositionsSlotId } from 'common/src/generated/types';
import { useLocation } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';

interface IAssignmentContext {
    isEdit: boolean;
    isPreAssign: boolean;
    positionsIds: PositionId[];
    positionsCategoriesIds: PositionsCategoryId[];
    positionsSlotsIds: PositionsSlotId[];

    showNextVolunteer(): void;
    setPositionsIds(positionIds: PositionId[]): void;
    setPositionsCategoriesIds(positionsCategoriesIds: PositionsCategoryId[]): void;
    setPositionsSlotsIds(positionsSlotsIds: PositionsSlotId[]): void;
}

const AssignmentContext = React.createContext<IAssignmentContext>({} as any);

interface IAssignmentContextProviderProps {
    children: React.ReactNode;
    isEdit: boolean;
    positionsIds: PositionId[];
    positionsCategoriesIds: PositionsCategoryId[];
    positionsSlotsIds: PositionsSlotId[];

    showNextVolunteer(): void;
}

export const AssignmentContextProvider = (props: IAssignmentContextProviderProps) => {
    const location = useLocation();
    const [positionsIds, setPositionsIds] = React.useState<PositionId[]>(props.positionsIds);
    const [positionsCategoriesIds, setPositionsCategoriesIds] = React.useState<
        PositionsCategoryId[]
    >(props.positionsCategoriesIds);
    const [positionsSlotsIds, setPositionsSlotsIds] = React.useState<PositionsSlotId[]>(
        props.positionsSlotsIds
    );

    return (
        <AssignmentContext.Provider
            value={{
                isEdit: props.isEdit,
                isPreAssign: location.pathname.includes('pre-assignment'),
                positionsIds,
                positionsCategoriesIds,
                positionsSlotsIds,
                showNextVolunteer: props.showNextVolunteer,
                setPositionsIds,
                setPositionsCategoriesIds,
                setPositionsSlotsIds
            }}
        >
            {props.children}
        </AssignmentContext.Provider>
    );
};

export function useAssignmentContext() {
    return React.useContext(AssignmentContext);
}
