import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { HeaderRow } from 'common/src/designSystem/components/table/headerRow';
import { Table } from 'common/src/designSystem/components/table/table';
import { EmailType, LoginCodeSource } from 'common/src/generated/types';
import * as React from 'react';
import { EmailTypeRow } from '../../emails/templates/emailTypeRow';
import { Template } from '../../emails/templates/template';

export const OrganizationMembersSpaceEmails = () => {
    const {
        params: { organizationId },
        translate
    } = useHeavent();
    const [emailType, setEmailType] = React.useState(EmailType.Code);

    return (
        <Flex direction="column" width={1}>
            <Spacer height="7" />

            <Flex gap="7">
                <Flex css={{ flex: '1' }} direction="column">
                    <Box color="gray900" fontSize="textLg" fontWeight="medium">
                        {translate('liste_des_e_mai_73508')}
                    </Box>

                    <Spacer height="6" />

                    <Table>
                        <HeaderRow>
                            <HeaderCell>{translate('type_d_e_mail_06577')}</HeaderCell>
                        </HeaderRow>

                        <EmailTypeRow
                            isSelected={emailType === EmailType.Code}
                            subtitle={translate('cet_e_mail_est_80490')}
                            title={translate('code_20843')}
                            onClick={() => setEmailType(EmailType.Code)}
                        />
                    </Table>
                </Flex>

                <Box
                    css={{
                        borderRight: '1px solid $gray200',
                        width: '1px'
                    }}
                />

                <Flex css={{ flex: '1', py: '$3' }}>
                    <Template
                        emailType={emailType}
                        eventId={null}
                        formId={null}
                        organizationId={organizationId}
                        source={LoginCodeSource.MembersSpace}
                    />
                </Flex>
            </Flex>
        </Flex>
    );
};
