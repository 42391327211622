import * as React from 'react';
import { Flex } from '../../../designSystem/components/flex';
import { DocumentUserCustomBadgesFragment } from '../../../generated/types';
import { EurockeennesA4 } from './eurockeennesA4';

interface IEurockeennesProps {
    event: DocumentUserCustomBadgesFragment;
}

export const Eurockeennes = (props: IEurockeennesProps) => (
    <>
        {props.event.volunteersRegistrations.nodes.map((vr, index) => (
            <Flex
                key={vr.utid}
                css={{
                    background: 'white',
                    pageBreakBefore: index !== 0 ? 'always' : undefined
                }}
                direction="column"
                width={1}
            >
                <EurockeennesA4
                    event={{
                        ...props.event,
                        volunteerRegistration: vr
                    }}
                />
            </Flex>
        ))}
    </>
);
