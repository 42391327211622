import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useSegmentsContext } from 'common-front/src/segments/segmentsContext';
import { Box } from 'common/src/designSystem/components/box';
import { Link } from 'common/src/designSystem/components/link';
import { Accreditation, AccreditationProperty } from 'common/src/generated/types';
import { AccreditationsPaths } from 'common/src/util/paths/accreditationsPaths';
import * as React from 'react';
import { AccreditationsUpdateCell } from './accreditationsUpdateCell';

interface IAccreditationsNameCellProps {
    accreditation: Pick<Accreditation, 'id' | 'name'>;

    reload(): void;
}

export const AccreditationsNameCell = (props: IAccreditationsNameCellProps) => {
    const {
        translate,
        params: { organizationId, eventId }
    } = useHeavent();
    const { isEditMode } = useSegmentsContext();
    const css = isEditMode
        ? { ellipsis: true }
        : {
              cursor: 'pointer',
              ellipsis: true,
              '&:hover': {
                  textDecoration: 'underline'
              }
          };

    return (
        <AccreditationsUpdateCell
            accreditationId={props.accreditation.id}
            initialValue={props.accreditation.name}
            property={AccreditationProperty.Name}
            reload={props.reload}
            renderInput={(value, setValue) => (
                <TextInput
                    label={translate('nom_de_l_accr_d_11908')}
                    value={value}
                    onChange={setValue}
                />
            )}
        >
            {!isEditMode ? (
                <Link
                    title={props.accreditation.name}
                    to={AccreditationsPaths.ACCREDITATION({
                        organizationId,
                        eventId,
                        accreditationId: props.accreditation.id
                    })}
                >
                    <Box css={css} font="gray900 textSm medium" width={1}>
                        {props.accreditation.name}
                    </Box>
                </Link>
            ) : (
                <Box
                    css={css}
                    font="gray900 textSm medium"
                    title={props.accreditation.name}
                    width={1}
                >
                    {props.accreditation.name}
                </Box>
            )}
        </AccreditationsUpdateCell>
    );
};
