import { ParseError, parse } from 'papaparse';
import * as React from 'react';

interface CSVHeaderLoadingState {
    isLoading: true;
    columns: undefined;
    errors: undefined;
}

interface CSVHeaderSuccessState {
    isLoading: false;
    columns: string[];
    errors: undefined;
}

interface CSVHeaderErrorState {
    isLoading: false;
    columns: undefined;
    errors: ParseError[];
}

type CSVHeaderState = CSVHeaderLoadingState | CSVHeaderSuccessState | CSVHeaderErrorState;

type CSVHeaderAction =
    | { type: 'LOADING' }
    | { type: 'SUCCESS'; columns: string[] }
    | { type: 'ERROR'; errors: ParseError[] };

type CSVHeaderReducer = (state: CSVHeaderState, action: CSVHeaderAction) => CSVHeaderState;

const csvHeaderReducer = (_: CSVHeaderState, action: CSVHeaderAction): CSVHeaderState => {
    switch (action.type) {
        case 'LOADING':
            return {
                isLoading: true,
                columns: undefined,
                errors: undefined
            };
        case 'SUCCESS':
            return {
                isLoading: false,
                columns: action.columns,
                errors: undefined
            };
        case 'ERROR':
            return {
                isLoading: false,
                columns: undefined,
                errors: action.errors
            };
    }
};

export const useCSVHeader = (file: File) => {
    const [state, dispatch] = React.useReducer<CSVHeaderReducer>(csvHeaderReducer, {
        isLoading: true,
        columns: undefined,
        errors: undefined
    });

    React.useEffect(() => {
        dispatch({ type: 'LOADING' });
        parse<string[]>(file, {
            preview: 1,
            complete: ({ data, errors }) => {
                if (errors.length) {
                    dispatch({ type: 'ERROR', errors });
                } else {
                    dispatch({ type: 'SUCCESS', columns: data[0] });
                }
            }
        });
    }, [file]);

    return state;
};
