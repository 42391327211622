import { Form } from 'common-front/src/components/form/form';
import { FormErrors } from 'common-front/src/components/form/formErrors';
import { Button } from 'common-front/src/designSystem/components/button';
import { Modal } from 'common-front/src/designSystem/components/modal';
import { Images } from 'common-front/src/util/assets';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { EventId } from 'common/src/generated/types';
import { IWeezeventConnectionValues, WeezeventInputService } from 'common/src/input/weezeventInput';
import { ValidateService } from 'common/src/services/validateService';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { useWeezeventConnectionInfosUpsertMutation } from '../../../../generated/graphqlHooks';
import { WeezeventConnectionStep1 } from './weezeventConnectionStep1';
import { WeezeventConnectionStep2 } from './weezeventConnectionStep2';
import { WeezeventConnectionStep3 } from './weezeventConnectionStep3';

interface IWeezeventConnectionProps {
    eventId: EventId;

    onClose(): void;
    onConnect(): void;
}

export const WeezeventConnection = (props: IWeezeventConnectionProps) => {
    const translate = useTranslate();
    const validateService = useService(ValidateService);
    const weezeventInput = useService(WeezeventInputService);
    const { mutate } = useWeezeventConnectionInfosUpsertMutation();
    const [step, setStep] = React.useState(0);

    return (
        <Form
            hideErrors={true}
            initialValues={{
                weezevent: weezeventInput.connectionInfosDefault()
            }}
            render={({ handleSubmit, submitting, values }) => (
                <Modal size="md" onClose={props.onClose}>
                    <Box height={41} textAlign="center" width={1}>
                        <img
                            alt="Logo Weezevent"
                            height="100%"
                            src={Images.LogoWeezeventSquare}
                            width="41px"
                        />
                    </Box>

                    <Spacer height="5" />

                    <Box
                        color="gray900"
                        fontSize="textLg"
                        fontWeight="medium"
                        textAlign="center"
                        width={1}
                    >
                        {translate('connexion_avec_79860')}
                    </Box>

                    <Spacer height="7" />

                    <FormErrors />

                    {step === 0 ? (
                        <WeezeventConnectionStep1 />
                    ) : step === 1 ? (
                        <WeezeventConnectionStep2 values={values} />
                    ) : (
                        <WeezeventConnectionStep3 values={values} />
                    )}

                    <Spacer height="7" />

                    <Flex gap="3">
                        {step !== 0 && (
                            <Button
                                color="white"
                                leftIcon="arrow-left"
                                onClick={() => {
                                    setStep(Math.max(0, step - 1));
                                }}
                            >
                                {translate('retour_92202')}
                            </Button>
                        )}

                        <Box css={{ flex: '1' }} />

                        {step === 2 ? (
                            <Button isLoading={submitting} onClick={handleSubmit}>
                                {translate('connecter_36439')}
                            </Button>
                        ) : (
                            <Button rightIcon="arrow-right" onClick={handleSubmit}>
                                {translate('suivant_1_3_37236', step + 1)}
                            </Button>
                        )}
                    </Flex>
                </Modal>
            )}
            validate={(values: IWeezeventConnectionValues) => {
                if (step === 0) {
                    return validateService.validateForForm(weezeventInput.connectionStep1Schema())(
                        values
                    );
                } else if (step === 1) {
                    return validateService.validateForForm(weezeventInput.connectionStep2Schema())(
                        values
                    );
                } else {
                    return validateService.validateForForm(weezeventInput.connectionStep3Schema())(
                        values
                    );
                }
            }}
            onSubmit={async (values: IWeezeventConnectionValues) => {
                if (step === 2) {
                    await mutate({
                        eventId: props.eventId,
                        connectionInfos: values.weezevent
                    });

                    props.onConnect();
                } else {
                    setStep(step + 1);
                }
            }}
        />
    );
};
