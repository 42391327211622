import { useLoadMore } from 'common-front/src/components/graphql/useLoadMore';
import { LoadMoreControl } from 'common-front/src/components/pagination/mobile/loadMoreControl';
import { VolunteerRowMobile } from 'common-front/src/components/volunteerRowMobile/volunteerRowMobile';
import { Flex } from 'common/src/designSystem/components/flex';
import { Segment } from 'common/src/generated/types';
import { useHistory, useParams, useTranslate } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { DEFAULT_PAGINATION_LIMIT } from 'common/src/vo/pagination';
import * as React from 'react';
import { executeVolunteersMobileQuery } from '../../../generated/graphqlHooks';
import { VolunteersListBlankMobile } from './volunteersListBlankMobile';

interface IVolunteersListMobileProps {
    segment: Pick<Segment, 'id' | 'name' | 'count' | 'predicates'>;
}

const getNodes = (result: Awaited<ReturnType<typeof executeVolunteersMobileQuery>>) =>
    result.event.volunteersRegistrations.nodes;

export const VolunteersListMobile = ({ segment }: IVolunteersListMobileProps) => {
    const translate = useTranslate();
    const { organizationId, eventId, segmentId } = useParams();
    const variables = {
        eventId,
        predicates: segment.predicates,
        limit: DEFAULT_PAGINATION_LIMIT,
        offset: 0
    };
    const { data, loader, loadMore, xs } = useLoadMore(
        executeVolunteersMobileQuery,
        variables,
        getNodes
    );
    const history = useHistory();

    if (loader && data === undefined) {
        return loader;
    } else {
        if (xs.length === 0) {
            return <VolunteersListBlankMobile />;
        } else {
            return (
                <Flex direction="column" gap="6">
                    {xs.map((volunteer, index) => {
                        const openVolunteer = () => {
                            history.push(
                                HeaventPaths.VOLUNTEERS_LIST_USER_INFORMATIONS(
                                    organizationId,
                                    eventId,
                                    segmentId,
                                    volunteer.userInfo.id
                                )
                            );
                        };

                        return (
                            <VolunteerRowMobile
                                key={index}
                                openVolunteer={openVolunteer}
                                registrationState={volunteer.state}
                                userInfo={volunteer.userInfo}
                            />
                        );
                    })}
                    <LoadMoreControl
                        buttonText={translate('afficher_plus_d_41906')}
                        isLoading={loader !== undefined}
                        loadMore={loadMore}
                        pageInfo={data.event.volunteersRegistrations.pageInfo}
                        statusText={translate(
                            '_1_sur_2_mem_14323',
                            xs.length,
                            data.event.volunteersRegistrations.totalCount
                        )}
                    />
                </Flex>
            );
        }
    }
};
