import { FieldSpacer } from 'common-front/src/components/users/show/fieldSpacer';
import { UserField } from 'common-front/src/components/users/show/userField';
import { ButtonDropdown } from 'common-front/src/designSystem/components/buttonDropdown';
import { Dropdown } from 'common-front/src/designSystem/components/dropdown/dropdown';
import { ItemIcon } from 'common-front/src/designSystem/components/dropdown/item';
import { Menu } from 'common-front/src/designSystem/components/dropdown/menu';
import { Trigger } from 'common-front/src/designSystem/components/dropdown/trigger';
import { OrderRightPanel } from 'common-front/src/designSystem/components/orderRightPanel';
import { HorizontalSpacerSeparator } from 'common-front/src/designSystem/components/separator';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { copy } from 'common-front/src/util/commands';
import { Avatar } from 'common/src/components/avatar/avatar';
import { Badge } from 'common/src/designSystem/components/badge';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { Link } from 'common/src/designSystem/components/link';
import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    CustomFieldSlug,
    CustomFieldWithConditionFragment,
    DelegationUserProfileFragment,
    EventId,
    EventUserProfilePrivateFieldsQuery,
    OrganizationId,
    UserInfoOverlayFragment
} from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { isNonEmptyArray } from 'common/src/util/array';
import { useService } from 'common/src/util/dependencies/dependencies';
import { Emptyable } from 'common/src/util/emptyable';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { LocaleFormats } from 'common/src/util/luxon';
import { isNonEmptyString } from 'common/src/util/string';
import { shouldDisplay } from 'common/src/vo/field';
import { sortBy } from 'lodash-es';
import { DateTime } from 'luxon';
import * as React from 'react';
import { UseroverlayEditEmailModal } from '../../overlay/useroverlayEditEmailModal';
import { UserProfilePrivateFieldsTeam } from './userProfilePrivateFieldsTeam';

interface IUserProfilePrivateFieldsInnerProps {
    customFields: CustomFieldWithConditionFragment[];
    delegations: DelegationUserProfileFragment[];
    eventId: Emptyable<EventId>;
    organizationId: OrganizationId;
    profilePrivateFields: CustomFieldSlug[];
    registrationDate: DateTime;
    teams: EventUserProfilePrivateFieldsQuery['organization']['userInfo']['formsUsersInfos'];
    userInfo: UserInfoOverlayFragment;

    profilePrivateFieldsUpdate(profilePrivateFields: CustomFieldSlug[]): Promise<any>;
    reload(): void;
}

export const UserProfilePrivateFieldsInner = (props: IUserProfilePrivateFieldsInnerProps) => {
    const { history, translate } = useHeavent();
    const dateTimeService = useService(DateTimeService);
    const [isOrderPrivateFieldsOpen, setIsOrderPrivateFieldsOpen] = React.useState(false);
    const [isEditEmailOpen, setIsEditEmailOpen] = React.useState(false);
    const privateCustomFields = React.useMemo(
        () => props.customFields.filter((cf) => cf.isPrivate),
        [props.customFields]
    );
    const profilePrivateFields = React.useMemo(
        () =>
            isNonEmptyArray(props.profilePrivateFields)
                ? props.profilePrivateFields
                : privateCustomFields.map((cf) => cf.slug),
        [privateCustomFields, props.profilePrivateFields]
    );
    const displayedFields = React.useMemo(
        () =>
            sortBy(
                privateCustomFields.filter(
                    (cf) =>
                        profilePrivateFields.includes(cf.slug) &&
                        shouldDisplay(cf, props.userInfo.fields, props.customFields)
                ),
                ({ slug }) => profilePrivateFields.indexOf(slug)
            ),
        [props.customFields, privateCustomFields, profilePrivateFields]
    );
    const orderOnSave = React.useCallback(
        async (profilePrivateFields: CustomFieldSlug[]) => {
            await props.profilePrivateFieldsUpdate(profilePrivateFields);

            props.reload();
        },
        [props.profilePrivateFieldsUpdate, props.reload]
    );
    const isPhoneValid = isNonEmptyString(props.userInfo.phone?.internationalFormat);

    return (
        <>
            <Flex align="center" justify="between" width={1}>
                <Avatar
                    email={props.userInfo.email}
                    image={props.userInfo.picture?.url}
                    name={props.userInfo.name}
                    size={48}
                />

                <ButtonDropdown
                    color="white"
                    menu={
                        <>
                            <ItemIcon
                                icon="gear"
                                onClick={() => {
                                    setIsOrderPrivateFieldsOpen(true);
                                }}
                            >
                                {translate('g_rer_les_champ_00431')}
                            </ItemIcon>
                        </>
                    }
                    onClick={() => {
                        history.push(
                            props.eventId
                                ? HeaventPaths.EDIT_USER_FORM(
                                      props.organizationId,
                                      props.eventId,
                                      props.userInfo.id,
                                      'all'
                                  )
                                : HeaventPaths.COMMUNITY_EDIT_USER_FORM(
                                      props.organizationId,
                                      props.userInfo.id,
                                      'all'
                                  )
                        );
                    }}
                >
                    {translate('_diter_62574')}
                </ButtonDropdown>
            </Flex>

            <Spacer height="3" />

            <Box font="gray800 textXl bold">{props.userInfo.name}</Box>

            <Spacer height="1" />

            <Flex>
                <Dropdown>
                    <Trigger>
                        <Badge
                            color="white"
                            cursor="pointer"
                            leftIcon="at"
                            rightIcon="chevron-down"
                        >
                            {props.userInfo.email}
                        </Badge>
                    </Trigger>

                    <Menu placement="bottom-start">
                        <ItemIcon
                            icon="copy"
                            onClick={() => {
                                copy(props.userInfo.email);
                            }}
                        >
                            {translate('copier_1_59210', props.userInfo.email)}
                        </ItemIcon>

                        <ItemIcon
                            icon="pen"
                            onClick={() => {
                                setIsEditEmailOpen(true);
                            }}
                        >
                            {translate('modifier_1_38587', props.userInfo.email)}
                        </ItemIcon>

                        <Link to={{ pathname: `mailto:${props.userInfo.email}` }}>
                            <ItemIcon icon="at">
                                {translate('_crire_1_90053', props.userInfo.email)}
                            </ItemIcon>
                        </Link>
                    </Menu>
                </Dropdown>
            </Flex>

            <Spacer height="2" />

            <Flex>
                <Dropdown>
                    <Trigger>
                        <Badge
                            color="white"
                            cursor={isPhoneValid ? 'pointer' : undefined}
                            leftIcon="phone"
                            rightIcon={isPhoneValid ? 'chevron-down' : undefined}
                        >
                            {props.userInfo.phone?.internationalFormat ?? '-'}
                        </Badge>
                    </Trigger>

                    {isPhoneValid && (
                        <Menu placement="bottom-start">
                            <ItemIcon
                                color="default"
                                icon="copy"
                                onClick={() => copy(props.userInfo.phone!.internationalFormat)}
                            >
                                {translate(
                                    'copier_1_59210',
                                    props.userInfo.phone?.internationalFormat ?? '-'
                                )}
                            </ItemIcon>

                            <Link
                                to={{
                                    pathname: `tel:${props.userInfo.phone!.internationalFormat.replaceAll(
                                        ' ',
                                        ''
                                    )}`
                                }}
                            >
                                <ItemIcon color="default" icon="phone">
                                    {translate(
                                        'appeler_1_57163',
                                        props.userInfo.phone?.internationalFormat ?? '-'
                                    )}
                                </ItemIcon>
                            </Link>
                        </Menu>
                    )}
                </Dropdown>
            </Flex>

            <HorizontalSpacerSeparator height="4" />

            {props.teams.map((team, index) => (
                <UserProfilePrivateFieldsTeam
                    key={index}
                    eventId={props.eventId!}
                    organizationId={props.organizationId}
                    teamMembers={team.teamMembers}
                />
            ))}

            {isNonEmptyArray(props.delegations) && (
                <>
                    <Flex align="center" gap="2">
                        <Box color="gray800">
                            <I icon="people-group" />
                        </Box>

                        <Box font="gray800 textMd semiBold">{translate('Delegation')}</Box>
                    </Flex>

                    <Spacer height="1" />

                    <Flex gap="2" wrap="wrap">
                        {props.delegations.map((delegation) => {
                            const name = props.eventId
                                ? delegation.name
                                : delegation.event
                                  ? `${delegation.event.name} : ${delegation.name}`
                                  : delegation.name;

                            return (
                                <Badge
                                    key={delegation.id}
                                    cursor="pointer"
                                    size="md"
                                    onClick={() => {
                                        history.push(
                                            delegation.event
                                                ? HeaventPaths.DELEGATION(
                                                      props.organizationId,
                                                      delegation.event.id,
                                                      delegation.id
                                                  )
                                                : HeaventPaths.COMMUNITY_DELEGATION(
                                                      props.organizationId,
                                                      delegation.id
                                                  )
                                        );
                                    }}
                                >
                                    {name}
                                </Badge>
                            );
                        })}
                    </Flex>

                    <Spacer height="7" />
                </>
            )}

            <Flex align="center" gap="3">
                <I icon="lock-keyhole" />

                <Box font="gray800 textMd semiBold">{translate('informations_pr_15873')}</Box>
            </Flex>

            <Spacer height="3" />

            {displayedFields.map((customField, index) => (
                <React.Fragment key={customField.slug}>
                    {index !== 0 && <FieldSpacer />}

                    <UserField field={customField} userInfo={props.userInfo} />
                </React.Fragment>
            ))}

            <Spacer height="6" />

            <Box color="gray500" css={{ textTransform: 'uppercase' }} fontSize="textXs">
                {translate(
                    'inscrit_le_1_12018',
                    dateTimeService.toLocaleString(
                        props.registrationDate.toLocal(),
                        LocaleFormats.DateTime
                    )
                )}
            </Box>

            {isEditEmailOpen && (
                <UseroverlayEditEmailModal
                    email={props.userInfo.email}
                    eventId={props.eventId}
                    organizationId={props.organizationId}
                    userInfoId={props.userInfo.id}
                    onClose={() => {
                        setIsEditEmailOpen(false);
                    }}
                    onSuccess={props.reload}
                />
            )}

            {isOrderPrivateFieldsOpen && (
                <OrderRightPanel
                    columns={privateCustomFields}
                    initialSelectedColumns={profilePrivateFields}
                    saveButtonText={translate('mettre_jour_85141')}
                    searchPlaceholder={translate('rechercher_un_c_86475')}
                    subtitle={translate('personnaliser_l_69296')}
                    title={translate('gestion_des_cha_66775')}
                    onClose={() => {
                        setIsOrderPrivateFieldsOpen(false);
                    }}
                    onSave={orderOnSave}
                />
            )}
        </>
    );
};
