import { MetricCard } from 'common-front/src/designSystem/components/metricCard';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { OrganizationTranslationsStatsQuery } from 'common/src/generated/types';
import * as React from 'react';

interface IOrganizationTranslationsKpisProps {
    organization: OrganizationTranslationsStatsQuery['organization'];
}

export const OrganizationTranslationsKpis = (props: IOrganizationTranslationsKpisProps) => {
    const { translate } = useHeavent();
    const nonTranslated =
        props.organization.translations.totalCount - props.organization.translated.totalCount;

    return (
        <Flex css={{ height: 'fit-content' }} gap="6" width={1}>
            <Box width={0.3}>
                <MetricCard css={{ height: '100%' }}>
                    <Flex direction="column" gap="4">
                        <Box font="gray500 textSm medium">{translate('nombre_de_langu_75924')}</Box>

                        <Box font="gray900 displayMd medium">
                            {props.organization.languages.length}
                        </Box>
                    </Flex>
                </MetricCard>
            </Box>

            <Box css={{ flex: '1' }}>
                <MetricCard>
                    <Flex gap="6" justify="between">
                        <Flex direction="column" gap="4">
                            <Box font="gray500 textSm medium">
                                {translate('nombre_de_champ_26560')}
                            </Box>

                            <Box font="gray900 displayMd medium">
                                {props.organization.translations.totalCount}
                            </Box>
                        </Flex>

                        <Flex direction="column" gap="3">
                            <Box font="gray700 textXs semiBold">{translate('dont_10477')}</Box>

                            <Flex align="center" gap="8" justify="between">
                                <Box font="gray500 textSm medium">
                                    {translate('champs_tradui_47609')}
                                </Box>

                                <Box font="gray900 textMd semiBold">{nonTranslated}</Box>
                            </Flex>

                            <Flex align="center" gap="8" justify="between">
                                <Box font="gray500 textSm medium">
                                    {translate('champs_traduits_03119')}
                                </Box>

                                <Box font="gray900 textMd semiBold">
                                    {props.organization.translated.totalCount}
                                </Box>
                            </Flex>
                        </Flex>
                    </Flex>
                </MetricCard>
            </Box>
        </Flex>
    );
};
