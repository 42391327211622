import * as React from 'react';
import { styled } from '../../designSystem/components/stitches';
import { CheckInState, VolunteerRegistrationState } from '../../generated/types';
import { Avatar } from '../avatar/avatar';
import { StateIcon } from '../stateIcon/stateIcon';

const getStateIconSize = (avatarSize: React.ComponentProps<typeof Avatar>['size']) => {
    switch (avatarSize) {
        case 24:
            return 10;
        case 32:
            return 12;
        case 40:
            return 14;
        case 48:
            return 16;
        case 56:
            return 18;
        case 64:
            return 20;
        case 96:
            return 24;
        case 110:
            return 30;
        case 160:
            return 40;
        case 310:
            return 80;
    }
};

interface IRegistrationStateProps {
    registrationState: VolunteerRegistrationState;
}

interface ICheckInStateProps {
    checkInState: CheckInState;
}

type IAvatarWithStateProps = (IRegistrationStateProps | ICheckInStateProps) &
    React.ComponentProps<typeof Avatar>;

const _Div = styled('div', { position: 'relative' });

export const AvatarWithState = (props: IAvatarWithStateProps) => {
    let stateIconProps;
    let avatarProps;
    if ('registrationState' in props) {
        stateIconProps = {
            registrationState: props.registrationState,
            size: getStateIconSize(props.size)
        };
        const { registrationState, ...rest } = props;
        avatarProps = rest;
    } else {
        stateIconProps = {
            checkInState: props.checkInState,
            size: getStateIconSize(props.size)
        };
        const { checkInState, ...rest } = props;
        avatarProps = rest;
    }

    return (
        <_Div>
            <Avatar {...avatarProps} />
            <StateIcon {...stateIconProps} />
        </_Div>
    );
};
