import { CheckboxText } from 'common-front/src/designSystem/components/checkbox';
import { RichSelect } from 'common-front/src/designSystem/components/richSelect/richSelect';
import { Select } from 'common-front/src/designSystem/components/select/select';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { PositionSheet } from 'common/src/documents/positionSheet/positionSheet';
import { DocumentType, PdfFormat, PositionSheetDocumentQuery } from 'common/src/generated/types';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { getDefaultPositionOptions } from 'common/src/vo/eventDocument';
import * as React from 'react';
import { usePositionSheetDocumentQuery } from '../../generated/graphqlHooks';
import { DocumentHeader } from './documentHeader';
import { DocumentOptions, useDocumentOptions } from './documentOptions';
import { DocumentShadow } from './documentShadow';
import { DocumentsPositionSelector } from './documentsPositionSelector';

interface IPositionDocumentContentProps {
    data: PositionSheetDocumentQuery;
}

const PositionDocumentContent = (props: IPositionDocumentContentProps) => {
    const {
        translate,
        params: { organizationId, eventId }
    } = useHeavent();
    const [options, setOptions] = useDocumentOptions(
        props.data.event.document.options,
        getDefaultPositionOptions(),
        eventId,
        props.data.event.document.id
    );

    return (
        <Flex css={{ flex: '1', overflow: 'hidden' }} gap="7" width={1}>
            <DocumentOptions>
                <Select
                    value={options.format}
                    onChange={(newFormat: string) => {
                        setOptions({ format: newFormat as PdfFormat });
                    }}
                >
                    <option value={PdfFormat.A4Portrait}>{translate('a4_portrait_92125')}</option>
                    <option value={PdfFormat.A4Landscape}>{translate('a4_paysage_24039')}</option>
                </Select>

                <CheckboxText
                    state={options.showPositionData ? 'checked' : 'unchecked'}
                    onClick={(state) => {
                        setOptions({ showPositionData: state === 'checked' });
                    }}
                >
                    {translate('afficher_les_do_85282')}
                </CheckboxText>

                <CheckboxText
                    state={options.showVolunteersLists ? 'checked' : 'unchecked'}
                    onClick={(state) => {
                        setOptions({ showVolunteersLists: state === 'checked' });
                    }}
                >
                    {translate('afficher_les_li_18523')}
                </CheckboxText>

                <RichSelect
                    isSearchVisible={true}
                    multiple={true}
                    placeholder={translate('champs_affich_17945')}
                    values={options.slugsToDisplay}
                    onChange={(values: string[]) => {
                        setOptions({ slugsToDisplay: values });
                    }}
                >
                    <option value="email">{translate('Email')}</option>
                    <option value="fullName">{translate('nom_complet_05907')}</option>
                    <option value="teamCode">{translate('code_quipe_14296')}</option>

                    {props.data.organization.customFields.nodes.map((customField) => (
                        <option key={customField.id} value={customField.slug}>
                            {customField.name}
                        </option>
                    ))}
                </RichSelect>
            </DocumentOptions>

            <Flex css={{ flex: '1' }} direction="column">
                <DocumentsPositionSelector
                    getPath={(positionId) =>
                        HeaventPaths.DOCUMENTS_POSITION(organizationId, eventId, positionId)
                    }
                />

                <DocumentShadow width={1}>
                    <PositionSheet options={options} positionDocumentFragment={props.data} />
                </DocumentShadow>
            </Flex>
        </Flex>
    );
};

export const PositionSheetDocument = () => {
    const {
        translate,
        params: { organizationId, eventId, positionId }
    } = useHeavent();
    const { data, loader } = usePositionSheetDocumentQuery({ organizationId, eventId, positionId });

    return (
        <Flex css={{ padding: '$7' }} direction="column" height={1} width={1}>
            <DocumentHeader
                documentType={DocumentType.Position}
                eventId={eventId}
                languages={data.event?.languages ?? []}
                organizationId={organizationId}
                segmentsFolders={data.event?.segmentsFolders ?? []}
                showDate={true}
                title={translate('fiche_de_missio_61604')}
            />

            <Spacer height="7" />

            {loader || <PositionDocumentContent data={data} />}
        </Flex>
    );
};
