import { Button } from 'common-front/src/designSystem/components/button';
import { RadioRow } from 'common-front/src/designSystem/components/radioRow';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { OrganizationsQuery } from 'common/src/generated/types';
import { useHistory, useParams, useTranslate } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import { useState } from 'react';

export const OrganizationSwitchMobile = ({
    organizations
}: {
    organizations: OrganizationsQuery['organizations'];
}) => {
    const { organizationId } = useParams();
    const [selectedOrganizationId, setSelectedOrganizationId] = useState(organizationId);
    const translate = useTranslate();
    const history = useHistory();
    const switchOrganization = () => {
        history.push(HeaventPaths.ORGANIZATION_PARAMETERS(selectedOrganizationId));
    };

    return (
        <Box css={{ margin: '0 $4 $7 $4' }}>
            <Flex direction="column" gap="4">
                {organizations.map((organization) => (
                    <RadioRow
                        key={organization.id}
                        state={organization.id === selectedOrganizationId ? 'checked' : 'unchecked'}
                        onClick={() => setSelectedOrganizationId(organization.id)}
                    >
                        {organization.name}
                    </RadioRow>
                ))}
            </Flex>
            <Spacer height={7} />
            <Button onClick={switchOrganization}>{translate('s_lectionner_l_18914')}</Button>
        </Box>
    );
};
