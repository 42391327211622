import { Badge } from 'common/src/designSystem/components/badge';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { isNonEmptyArray } from 'common/src/util/array';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { FilterPredicate, SegmentService } from 'common/src/vo/segment';
import { without } from 'lodash-es';
import * as React from 'react';
import { useFiltersLoader } from '../filters/data/useFilterLoader';
import { useSegmentsContext } from '../segmentsContext';

export const FiltersPredicatesBadges = () => {
    const translate = useTranslate();
    const segmentService = useService(SegmentService);
    const {
        filtersPredicates,
        clearNameCursors,
        setPredicates,
        setIsFilterOpen,
        setSelectedFilterPredicate,
        predicates
    } = useSegmentsContext();
    const onCleanClick = React.useCallback(() => {
        clearNameCursors();
        setPredicates([]);
    }, [clearNameCursors, setPredicates]);
    const onContentClick = React.useCallback(
        (filterPredicate: FilterPredicate) => {
            setSelectedFilterPredicate(filterPredicate);
            setIsFilterOpen(true);
        },
        [setIsFilterOpen, setSelectedFilterPredicate]
    );
    const onRightIconClick = React.useCallback(
        (filterPredicate: FilterPredicate) => {
            clearNameCursors();
            setPredicates(without(predicates, filterPredicate.predicate!));
        },
        [predicates, clearNameCursors, setPredicates]
    );
    useFiltersLoader(filtersPredicates);

    if (isNonEmptyArray(filtersPredicates)) {
        return (
            <Flex align="center" gap="3" width={1} wrap="wrap">
                {filtersPredicates.map((filterPredicate, index) => {
                    if (filterPredicate.filter.needLoading) {
                        return <Badge key={index} leftIcon="loader" />;
                    } else {
                        return (
                            <Badge
                                key={index}
                                cursor="pointer"
                                ellipsis={true}
                                rightIcon="xmark"
                                onContentClick={() => {
                                    onContentClick(filterPredicate);
                                }}
                                onRightIconClick={() => {
                                    onRightIconClick(filterPredicate);
                                }}
                            >
                                {segmentService.filterPredicateToText(filterPredicate)}
                            </Badge>
                        );
                    }
                })}

                <Box
                    color="gray700"
                    css={{ cursor: 'pointer' }}
                    fontWeight="medium"
                    onClick={onCleanClick}
                >
                    {translate('r_initialiser_l_66835')}
                </Box>
            </Flex>
        );
    } else {
        return null;
    }
};
