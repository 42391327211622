import { range as lodashRange } from 'lodash-es';
import { DateTime, Interval } from 'luxon';
import * as React from 'react';
import { Box } from '../../designSystem/components/box';
import { Flex } from '../../designSystem/components/flex';
import { theme } from '../../designSystem/components/stitches';
import { DateTimeService } from '../../services/dateTimeService';
import { hexToRgba } from '../../util/color';
import { useService } from '../../util/dependencies/dependencies';
import { LocaleFormats } from '../../util/luxon';
import { HeaderCell, UnavailableCell } from '../cells';

const PADDING_X = 56;
const PADDING_Y = 32;
const PADDING_ASSIGNMENT = 8;
const TIME_COLUMN_WIDTH = 120;
const DAY_COLUMN_WIDTH = 170;
const DAY_ROW_HEIGHT = 40;
const CELL_HEIGHT = 60;

interface IUserPlanningCalendarProps {
    assignments: Array<{ name: string; range: Interval; rangeMinusStart: Interval }>;
    endDate: DateTime;
    numberOfSlots: number;
    startDate: DateTime;
    startHour: number;
}

export const UserPlanningCalendar = (props: IUserPlanningCalendarProps) => {
    const dateTimeService = useService(DateTimeService);
    const starts = props.assignments.map(({ range }) => range.start!);
    const hasSlot = (dateTime: DateTime) =>
        starts.some((start) => start.startOf('day').equals(dateTime.startOf('day')));
    const numberOfDays = Math.ceil(props.endDate.diff(props.startDate, 'days').days);

    return (
        <Flex
            css={{
                background: 'white',
                flex: '1',
                padding: `${PADDING_Y}px ${PADDING_X}px`,
                position: 'relative'
            }}
            direction="column"
        >
            <Flex>
                <HeaderCell width={TIME_COLUMN_WIDTH} />

                {lodashRange(0, numberOfDays).map((i) => {
                    const day = props.startDate.plus({ days: i });

                    return (
                        <HeaderCell key={i} width={DAY_COLUMN_WIDTH}>
                            {dateTimeService.toLocaleString(day, LocaleFormats.DateOnly.Numeric)}
                        </HeaderCell>
                    );
                })}
            </Flex>

            {lodashRange(0, props.numberOfSlots).map((i) => {
                const start = (props.startHour + i * 2) % 24;
                const end = (start + 2) % 24;
                const startTime = DateTime.fromObject({
                    hour: start,
                    minute: 0
                });
                const endTime = DateTime.fromObject({
                    hour: end,
                    minute: 0
                });

                return (
                    <Flex key={i}>
                        <HeaderCell height={CELL_HEIGHT} width={TIME_COLUMN_WIDTH}>
                            {dateTimeService.toLocaleString(startTime, LocaleFormats.TimeOnly)} -{' '}
                            {dateTimeService.toLocaleString(endTime, LocaleFormats.TimeOnly)}
                        </HeaderCell>

                        {lodashRange(0, numberOfDays).map((j) => {
                            if (hasSlot(props.startDate.plus({ days: j }))) {
                                return (
                                    <HeaderCell
                                        key={`${i}-${j}`}
                                        height={CELL_HEIGHT}
                                        width={DAY_COLUMN_WIDTH}
                                    />
                                );
                            } else {
                                return (
                                    <UnavailableCell
                                        key={`${i}-${j}`}
                                        height={CELL_HEIGHT}
                                        width={DAY_COLUMN_WIDTH}
                                    />
                                );
                            }
                        })}
                    </Flex>
                );
            })}

            {props.assignments.map(({ name, range, rangeMinusStart }, index) => {
                const start = range.start!;
                const end = range.end!;
                const left =
                    PADDING_X +
                    PADDING_ASSIGNMENT +
                    TIME_COLUMN_WIDTH +
                    Math.floor(rangeMinusStart.start!.diff(props.startDate, 'days').days) *
                        DAY_COLUMN_WIDTH;
                const top =
                    DAY_ROW_HEIGHT +
                    PADDING_Y +
                    range.start!.diff(
                        rangeMinusStart.start!.set({ hour: props.startHour, minute: 0 }),
                        'minutes'
                    ).minutes *
                        0.5;
                const height = range.length('minutes') * 0.5;

                return (
                    <Flex
                        key={index}
                        css={{
                            background: hexToRgba(theme.colors.blue400.value, 50),
                            borderRadius: '$1',
                            height: `${height}px`,
                            left: `${left}px`,
                            padding: '$3 $2',
                            position: 'absolute',
                            top: `${top}px`,
                            width: `${DAY_COLUMN_WIDTH - 16}px`
                        }}
                        direction="column"
                    >
                        <Box color="gray800">{name}</Box>
                        <Box color="gray800" fontSize="textXs">
                            {dateTimeService.toLocaleString(start, LocaleFormats.TimeOnly)} -{' '}
                            {dateTimeService.toLocaleString(end, LocaleFormats.TimeOnly)}
                        </Box>
                    </Flex>
                );
            })}
        </Flex>
    );
};
