import { DropdownEllipsis } from 'common-front/src/designSystem/components/dropdown/dropdownEllipsis';
import { ItemIcon } from 'common-front/src/designSystem/components/dropdown/item';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { OrganizationCategoriesPositionsQuery } from 'common/src/generated/types';
import * as React from 'react';
import { DeletePositionCategory } from '../../positions/list/deletePositionCategory';
import { UpdatePositionCategory } from '../../positionsCategories/create/createUpdatePositionCategory';

interface IOrganizationCategoriesPositionRowDropdownProps {
    positionCategory: OrganizationCategoriesPositionsQuery['organization']['positionsCategories']['nodes'][0];

    reload(): void;
}

export const OrganizationCategoriesPositionRowDropdown = (
    props: IOrganizationCategoriesPositionRowDropdownProps
) => {
    const { translate } = useHeavent();
    const [isRenameOpen, setIsRenameOpen] = React.useState(false);
    const [isDeleteOpen, setIsDeleteOpen] = React.useState(false);

    return (
        <>
            <DropdownEllipsis>
                <ItemIcon
                    icon="pen"
                    onClick={() => {
                        setIsRenameOpen(true);
                    }}
                >
                    {translate('renommer_42135')}
                </ItemIcon>

                <ItemIcon
                    color="red"
                    icon="trash-can"
                    onClick={() => {
                        setIsDeleteOpen(true);
                    }}
                >
                    {translate('supprimer_43083')}
                </ItemIcon>
            </DropdownEllipsis>

            {isRenameOpen && (
                <UpdatePositionCategory
                    eventId={props.positionCategory.eventId}
                    positionCategoryId={props.positionCategory.id}
                    positionCategoryName={props.positionCategory.name}
                    onClose={() => {
                        setIsRenameOpen(false);
                    }}
                    onSuccess={props.reload}
                />
            )}

            {isDeleteOpen && (
                <DeletePositionCategory
                    eventId={props.positionCategory.eventId}
                    positionCategory={props.positionCategory}
                    onClose={() => {
                        setIsDeleteOpen(false);
                    }}
                    onSuccess={props.reload}
                />
            )}
        </>
    );
};
