import { Empty } from 'common-front/src/components/empty/empty';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import { Route, Switch } from 'react-router';
import { BackofficeOrganizations } from './backofficeOrganizations';
import { BackofficeInvoices } from './invoices/backofficeInvoices';
import { UpdateBackofficeOrganization } from './updateBackofficeOrganization';

export const Backoffice = () => (
    <Switch>
        <Route exact path={HeaventPaths.BACKOFFICE_ORGANIZATIONS}>
            <BackofficeOrganizations />
        </Route>

        <Route exact path={HeaventPaths.EDIT_BACKOFFICE_ORGANIZATION(':organizationId')}>
            <UpdateBackofficeOrganization />
        </Route>

        <Route exact path={HeaventPaths.BACKOFFICE_INVOICES}>
            <BackofficeInvoices />
        </Route>

        <Route>
            <Empty path={HeaventPaths.BACKOFFICE_ORGANIZATIONS} replace={true} />
        </Route>
    </Switch>
);
