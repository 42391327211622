import { Textarea } from 'common-front/src/designSystem/components/textarea';
import {
    OrganizationId,
    SegmentCustomFieldFragment,
    UserInfoCommunityFragment
} from 'common/src/generated/types';
import { useService } from 'common/src/util/dependencies/dependencies';
import { FieldService } from 'common/src/vo/field';
import { PossibleColumn } from 'common/src/vo/segment';
import * as React from 'react';
import { UserInfoUpdateCell } from '../../../volunteers/list/list/cells/volunteerRegistrationUpdateCell';

interface ICommunityUserTextareaCellProps {
    customField: SegmentCustomFieldFragment;
    field: PossibleColumn;
    organizationId: OrganizationId;
    userInfo: UserInfoCommunityFragment;

    reload(): void;
}

export const CommunityUserTextareaCell = (props: ICommunityUserTextareaCellProps) => {
    const fieldService = useService(FieldService);
    const value = fieldService.getValueString(props.customField, props.userInfo);

    return (
        <UserInfoUpdateCell
            customField={props.customField}
            field={props.field}
            initialValue={value}
            organizationId={props.organizationId}
            reload={props.reload}
            userInfoId={props.userInfo.id}
            valueString={value}
        >
            {(updatedValue, setUpdatedValue) => (
                <Textarea
                    label={props.customField.name}
                    value={updatedValue}
                    onChange={setUpdatedValue}
                />
            )}
        </UserInfoUpdateCell>
    );
};
