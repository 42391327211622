import { Flex } from 'common/src/designSystem/components/flex';
import { styled } from 'common/src/designSystem/components/stitches';
import * as React from 'react';

const _Flex = styled(Flex, {
    backgroundColor: '$gray100',
    borderRadius: '$1',
    padding: '2px'
});

export const TabListMobile = ({ children, ...props }: React.ComponentProps<typeof _Flex>) => (
    <_Flex {...props}>{children}</_Flex>
);
