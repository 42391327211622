import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { Segment } from 'common/src/generated/types';
import * as React from 'react';
import { Draggable, DraggableProvided } from 'react-beautiful-dnd';

interface ISegmentsFoldersReorderSegmentProps {
    index: number;
    segment: Pick<Segment, 'id' | 'name'>;
}

export const SegmentsFoldersReorderSegment = (props: ISegmentsFoldersReorderSegmentProps) => (
    <Draggable draggableId={`s-${props.segment.id}`} index={props.index}>
        {(draggableProvided: DraggableProvided) => (
            <Flex
                css={{
                    background: 'white',
                    border: '1px solid $gray200',
                    borderRadius: '$1',
                    padding: '$3'
                }}
                gap="3"
                {...draggableProvided.draggableProps}
                ref={draggableProvided.innerRef}
            >
                <Box
                    color="gray300"
                    css={{ cursor: 'pointer' }}
                    {...draggableProvided.dragHandleProps}
                >
                    <I icon="grip-vertical" iconStyle="solid" />
                </Box>

                <Box font="gray900 textSm medium">{props.segment.name}</Box>
            </Flex>
        )}
    </Draggable>
);
