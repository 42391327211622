import { Spacer } from 'common/src/designSystem/components/spacer';
import { ValidateService } from 'common/src/services/validateService';
import { useService } from 'common/src/util/dependencies/dependencies';
import { FormApi, SubmissionErrors } from 'final-form';
import arrayMutators from 'final-form-arrays';
import { isEqual } from 'lodash-es';
import * as React from 'react';
import { Form, FormRenderProps } from 'react-final-form';
import * as yup from 'yup';
import { forceErrors, FormRender } from './form';
import { FormBox } from './formBox';
import { FormContext } from './formContext';

interface IFormWizardPageProps<FormValues> {
    // eslint-disable-next-line react/no-unused-prop-types
    title: string; // Prop value accessed in `FormWizard` component
    // eslint-disable-next-line react/no-unused-prop-types
    schema: yup.Schema<any>; // Prop value accessed in `FormWizard` component
    formProps?: FormRenderProps<FormValues>;

    render(
        props: Pick<
            FormRenderProps<FormValues>,
            'form' | 'handleSubmit' | 'invalid' | 'submitting' | 'errors' | 'values'
        >
    ): React.ReactNode;
}

export const FormWizardPage = <T extends {}>(props: IFormWizardPageProps<T>) => (
    <FormRender direction="column" formRenderProps={props.formProps!} render={props.render} />
);

interface IFormWizardProps<FormValues> {
    children: React.ReactNode;
    initialValues: FormValues;
    isEdit: boolean;

    onSubmit(
        values: FormValues,
        form: FormApi<FormValues>
    ): SubmissionErrors | Promise<SubmissionErrors | undefined> | undefined | void;
}

export const FormWizard = <FormValues extends {}>(props: IFormWizardProps<FormValues>) => {
    const [showErrors, setShowErrors] = React.useState(false);
    const [stepIndex, setStepIndex] = React.useState(0);
    const validateService = useService(ValidateService);
    const steps = React.Children.toArray(props.children);

    return (
        <Form
            initialValues={props.initialValues}
            initialValuesEqual={isEqual}
            mutators={{
                ...arrayMutators,
                forceErrors
            }}
            render={(formProps) =>
                steps.map((step: any, index: number) => {
                    const canSelect = stepIndex >= index || (props.isEdit && formProps.valid);

                    return (
                        <FormContext.Provider
                            key={index}
                            value={{
                                errors: formProps.errors,
                                showErrors,
                                submitErrors: formProps.submitErrors,
                                setShowErrors
                            }}
                        >
                            <FormBox
                                canSelect={canSelect}
                                isCompleted={stepIndex > index}
                                isOpen={stepIndex === index}
                                stepNumber={index + 1}
                                title={step.props.title}
                                onSelect={() => {
                                    if (canSelect) {
                                        setShowErrors(false);
                                        setStepIndex(index);
                                    }
                                }}
                            >
                                {React.cloneElement(step, {
                                    formProps
                                })}
                            </FormBox>

                            <Spacer height="6" />
                        </FormContext.Provider>
                    );
                })
            }
            validate={(values: FormValues) =>
                validateService.validateForForm((steps[stepIndex] as any).props.schema)(values)
            }
            onSubmit={async (values: FormValues, form: FormApi<FormValues>) => {
                if (stepIndex === steps.length - 1) {
                    await props.onSubmit(values, form);
                } else {
                    setShowErrors(false);
                    setStepIndex(stepIndex + 1);
                }
            }}
        />
    );
};
