import { DropdownEllipsis } from 'common-front/src/designSystem/components/dropdown/dropdownEllipsis';
import { ItemIcon } from 'common-front/src/designSystem/components/dropdown/item';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { DelegationsDelegationFragment, EventId, OrganizationId } from 'common/src/generated/types';
import { Emptyable } from 'common/src/util/emptyable';
import * as React from 'react';
import { useDelegationDuplicateMutation } from '../../generated/graphqlHooks';
import { DelegationDeleteModal } from './delegationDeleteModal';
import { useDelegationsContext } from './delegationsContext';

interface IDelegationRowDropdownProps {
    delegation: DelegationsDelegationFragment;
    organizationId: OrganizationId;
    eventId: Emptyable<EventId>;

    reload(): void;
}

export const DelegationRowDropdown = (props: IDelegationRowDropdownProps) => {
    const { history, translate } = useHeavent();
    const { getEditPath } = useDelegationsContext();
    const { mutate } = useDelegationDuplicateMutation();
    const [isDeleteOpen, setIsDeleteOpen] = React.useState(false);

    return (
        <>
            <DropdownEllipsis>
                <ItemIcon icon="pen" to={getEditPath(props.delegation.id)}>
                    {translate('_diter_62574')}
                </ItemIcon>

                <ItemIcon
                    icon="clone"
                    onClick={async () => {
                        const { delegationDuplicate } = await mutate({
                            organizationId: props.organizationId,
                            eventId: props.eventId,
                            delegationId: props.delegation.id
                        });

                        history.push(getEditPath(delegationDuplicate.id));
                    }}
                >
                    {translate('dupliquer_28256')}
                </ItemIcon>

                <ItemIcon
                    color="red"
                    icon="trash-can"
                    onClick={async () => {
                        setIsDeleteOpen(true);
                    }}
                >
                    {translate('supprimer_43083')}
                </ItemIcon>
            </DropdownEllipsis>

            {isDeleteOpen && (
                <DelegationDeleteModal
                    delegation={props.delegation}
                    onClose={() => {
                        setIsDeleteOpen(false);
                    }}
                    onSuccess={props.reload}
                />
            )}
        </>
    );
};
