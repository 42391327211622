import { CommonEnvVars, getAdminsEmails } from '../envVars';
import { HeaventEnv } from '../heaventEnv';

export function canAccessBackoffice(email: string) {
    if (CommonEnvVars.HEAVENT_ENV === HeaventEnv.Recrewteer) {
        return email.endsWith('@weezevent.com');
    } else {
        return getAdminsEmails().includes(email);
    }
}
