import { DropdownFilters } from 'common-front/src/designSystem/components/dropdownFilters';
import { Select } from 'common-front/src/designSystem/components/select/select';
import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { Box } from 'common/src/designSystem/components/box';
import { EventId, PositionsSlot, PositionsSlotId } from 'common/src/generated/types';
import { IntervalService } from 'common/src/services/intervalService';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { Emptyable } from 'common/src/util/emptyable';
import { fullName } from 'common/src/vo/positionSlot';
import * as React from 'react';

interface IPositionMembersFiltersProps {
    eventId: Emptyable<EventId>;
    filterSlotId: Emptyable<PositionsSlotId>;
    search: string;
    positionSlots: Pick<PositionsSlot, 'id' | 'name' | 'range'>[];

    setFilterSlotId(filterSlot: Emptyable<PositionsSlotId>): void;
    setOffset(offset: number): void;
    setSearch(search: string): void;
}

export const PositionMembersFilters = ({
    filterSlotId,
    eventId,
    search,
    setFilterSlotId,
    setOffset,
    setSearch,
    positionSlots: slots
}: IPositionMembersFiltersProps) => {
    const translate = useTranslate();

    const intervalService = useService(IntervalService);

    return (
        <>
            <Box width={320}>
                <TextInput
                    icon="magnifying-glass"
                    placeholder={translate('rechercher_un_m_05904')}
                    value={search}
                    onChange={(newName: string) => {
                        setSearch(newName);
                        setOffset(0);
                    }}
                />
            </Box>

            {eventId && (
                <DropdownFilters color="white" showButtons={false}>
                    <Select
                        label={translate('liste_des_cr_ne_94056')}
                        shouldParseAsInt={true}
                        value={filterSlotId ?? -1}
                        onChange={(newFilterSlotId) => {
                            setFilterSlotId(
                                newFilterSlotId ? (newFilterSlotId as PositionsSlotId) : null
                            );
                        }}
                    >
                        <option value="-1">{translate('tous_les_cr_nea_66703')}</option>

                        {slots.map((slot) => (
                            <option key={slot.id} value={slot.id}>
                                {fullName(intervalService, slot)}
                            </option>
                        ))}
                    </Select>
                </DropdownFilters>
            )}
        </>
    );
};
