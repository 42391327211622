import * as React from 'react';
import Camera from 'svgComponents/fontawesome/camera.svg';
import Ustenstils from 'svgComponents/fontawesome/ustenstils.svg';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { Icon } from '../../../generated/types';

interface IMealProps {
    icon: Icon;
    name: string;
}

export const Meal = (props: IMealProps) => (
    <Flex align="center" direction="column" gap="1">
        <Flex
            align="center"
            css={{
                background: 'white',
                borderRadius: '34px',
                color: '#336998',
                height: '34px',
                width: '34px',
                svg: {
                    fill: '#5a9e9f',
                    height: '50%',
                    width: '50%'
                }
            }}
            justify="center"
        >
            {props.icon === 'Camera' ? <Camera /> : <Ustenstils />}
        </Flex>

        <Box
            css={{
                color: '#761c31',
                fontSize: '10px'
            }}
        >
            {props.name}
        </Box>
    </Flex>
);
