import { Button } from 'common-front/src/designSystem/components/button';
import { DeleteModal } from 'common-front/src/designSystem/components/deleteModal';
import {
    useUserInfoDelegationDeleteMutation,
    useVolunteersRegistrationsMassDeleteMutation
} from 'common-front/src/generated/graphqlHooks';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    AccreditationState,
    Delegation,
    EmailType,
    EventId,
    Feature,
    OrganizationId,
    UserInfoDocumentLink,
    UserInfoOverlayFragment,
    UserOverlayEventQuery,
    VolunteerRegistrationState,
    VolunteersRegistrationId
} from 'common/src/generated/types';
import { isNonEmptyArray } from 'common/src/util/array';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { getDocumentFullPath } from 'common/src/util/documentsPaths';
import { Emptyable } from 'common/src/util/emptyable';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { isNonEmptyString } from 'common/src/util/string';
import { getDisplayName } from 'common/src/vo/customBadge';
import { sortBy } from 'lodash-es';
import * as React from 'react';
import { useEventContext } from '../../../events/show/eventContext';
import {
    useEmailResendMutation,
    useUpdateStateMutation,
    useVolunteerRegistrationAccreditationStateUpdateMutation,
    useVolunteersRegistrationsMassWeezeventSynchronizeMutation
} from '../../../generated/graphqlHooks';
import { useOrganizationContext } from '../../../organization/organizationContext';

interface IUserOverlayActionsEventProps {
    accreditationState: AccreditationState;
    customDocuments: UserOverlayEventQuery['event']['customDocuments'];
    delegation?: Emptyable<Pick<Delegation, 'id' | 'name'>>;
    organizationId: OrganizationId;
    eventId: EventId;
    isWeezeventConnected: boolean;
    links: Emptyable<UserInfoDocumentLink>;
    state: VolunteerRegistrationState;
    userInfo: UserInfoOverlayFragment;
    volunteerRegistrationId: VolunteersRegistrationId;

    onDelete(): void;
    onUpdateState(): void;
}

export const UserOverlayActionsEvent = (props: IUserOverlayActionsEventProps) => {
    const translate = useTranslate();
    const { organization, hasFeature } = useOrganizationContext();
    const { isEventAdmin, isPositionAdmin } = useEventContext();
    const { mutate: emailResend, isLoading: isEmailResendLoading } = useEmailResendMutation();
    const { mutate: changeToWaiting, isLoading: isChangeToWaitingLoading } =
        useUpdateStateMutation();
    const { mutate: changeToRefused, isLoading: isChangeToRefusedLoading } =
        useUpdateStateMutation();
    const { mutate: changeToNotApplicable, isLoading: isChangeToNotApplicableLoading } =
        useUpdateStateMutation();
    const { mutate: userInfoDelegationDelete } = useUserInfoDelegationDeleteMutation();
    const { mutate: volunteerRegistrationDelete } = useVolunteersRegistrationsMassDeleteMutation();
    const {
        mutate: volunteersRegistrationsMassWeezeventSynchronize,
        isLoading: isVolunteersRegistrationsMassWeezeventSynchronizeLoading
    } = useVolunteersRegistrationsMassWeezeventSynchronizeMutation();
    const {
        mutate: accreditationStateNotApplicable,
        isLoading: isAccreditationStateNotApplicableLoading
    } = useVolunteerRegistrationAccreditationStateUpdateMutation();
    const {
        mutate: accreditationStateWaitingAccreditation,
        isLoading: isAccreditationStateWaitingAccreditationLoading
    } = useVolunteerRegistrationAccreditationStateUpdateMutation();
    const [isRemoveFromDelegationOpen, setIsRemoveFromDelegationOpen] = React.useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
    const customDocuments = React.useMemo(
        () =>
            sortBy(
                props.customDocuments.filter(
                    (cd) =>
                        cd.populations.length === 0 ||
                        cd.populations.includes(props.userInfo.population)
                ),
                (cd) => cd.name
            ),
        [props.customDocuments, props.userInfo]
    );
    const hasDocuments =
        (props.links &&
            (props.state === VolunteerRegistrationState.Assigned ||
                isNonEmptyArray(organization.customBadges))) ||
        isNonEmptyString(customDocuments);

    return (
        <Flex direction="column">
            {isPositionAdmin() && (
                <>
                    <Spacer height="6" />

                    <Box color="gray800" fontSize="textMd" fontWeight="semiBold">
                        {translate('affectation')}
                    </Box>
                </>
            )}

            {isEventAdmin &&
                (props.state === VolunteerRegistrationState.Assigned ||
                    props.state === VolunteerRegistrationState.Refused ||
                    props.state === VolunteerRegistrationState.NotApplicable ||
                    props.state === VolunteerRegistrationState.PreAssigned) && (
                    <>
                        <Spacer height="3" />

                        <Button
                            color="white"
                            isLoading={isChangeToWaitingLoading}
                            leftIcon="arrow-right-arrow-left"
                            rightIcon="arrow-right"
                            onClick={async () => {
                                await changeToWaiting({
                                    eventId: props.eventId,
                                    volunteerRegistrationId: props.volunteerRegistrationId,
                                    state: VolunteerRegistrationState.WaitingAssignment
                                });

                                props.onUpdateState();
                            }}
                        >
                            {translate('changer_le_stat_90266')}
                        </Button>
                    </>
                )}

            {isEventAdmin &&
                (props.state === VolunteerRegistrationState.Assigned ||
                    props.state === VolunteerRegistrationState.WaitingAssignment ||
                    props.state === VolunteerRegistrationState.NotApplicable ||
                    props.state === VolunteerRegistrationState.PreAssigned) && (
                    <>
                        <Spacer height="3" />

                        <Button
                            color="white"
                            isLoading={isChangeToRefusedLoading}
                            leftIcon="arrow-right-arrow-left"
                            rightIcon="arrow-right"
                            onClick={async () => {
                                await changeToRefused({
                                    eventId: props.eventId,
                                    volunteerRegistrationId: props.volunteerRegistrationId,
                                    state: VolunteerRegistrationState.Refused
                                });

                                props.onUpdateState();
                            }}
                        >
                            {translate('changer_le_stat_68392')}
                        </Button>
                    </>
                )}

            {isEventAdmin &&
                (props.state === VolunteerRegistrationState.WaitingAssignment ||
                    props.state === VolunteerRegistrationState.Assigned) && (
                    <>
                        <Spacer height="3" />

                        <Button
                            color="white"
                            isLoading={isChangeToNotApplicableLoading}
                            leftIcon="arrow-right-arrow-left"
                            rightIcon="arrow-right"
                            onClick={async () => {
                                await changeToNotApplicable({
                                    eventId: props.eventId,
                                    volunteerRegistrationId: props.volunteerRegistrationId,
                                    state: VolunteerRegistrationState.NotApplicable
                                });

                                props.onUpdateState();
                            }}
                        >
                            {translate('changer_le_stat_62507')}
                        </Button>
                    </>
                )}

            {isEventAdmin && props.state === VolunteerRegistrationState.Assigned && (
                <>
                    <Spacer height="3" />

                    <Button
                        color="white"
                        leftIcon="pen"
                        rightIcon="arrow-right"
                        to={HeaventPaths.ASSIGNMENT(
                            props.organizationId,
                            props.eventId,
                            props.userInfo.id
                        )}
                    >
                        {translate('_diter_les_affe_42283')}
                    </Button>
                </>
            )}

            {isPositionAdmin() &&
                (props.state === VolunteerRegistrationState.WaitingAssignment ||
                    props.state === VolunteerRegistrationState.PreAssigned) && (
                    <>
                        <Spacer height="3" />

                        <Button
                            color="white"
                            leftIcon="wand-magic-sparkles"
                            rightIcon="arrow-right"
                            to={HeaventPaths.ASSIGNMENT(
                                props.organizationId,
                                props.eventId,
                                props.userInfo.id
                            )}
                        >
                            {translate('affecter_94255')}
                        </Button>
                    </>
                )}

            {isPositionAdmin() && props.state === VolunteerRegistrationState.Assigned && (
                <>
                    <Spacer height="3" />

                    <Button
                        color="white"
                        isLoading={isEmailResendLoading}
                        leftIcon="paper-plane"
                        rightIcon="arrow-right"
                        onClick={async () => {
                            await emailResend({
                                eventId: props.eventId,
                                userInfoId: props.userInfo.id,
                                emailType: EmailType.Assignments
                            });
                        }}
                    >
                        {translate('r_envoyer_l_e_65315')}
                    </Button>
                </>
            )}

            {isEventAdmin && props.state === VolunteerRegistrationState.Refused && (
                <>
                    <Spacer height="3" />

                    <Button
                        color="white"
                        isLoading={isEmailResendLoading}
                        leftIcon="paper-plane"
                        rightIcon="arrow-right"
                        onClick={async () => {
                            await emailResend({
                                eventId: props.eventId,
                                userInfoId: props.userInfo.id,
                                emailType: EmailType.Refused
                            });
                        }}
                    >
                        {translate('r_envoyer_l_e_06733')}
                    </Button>
                </>
            )}

            {hasFeature(Feature.Weezevent) && props.isWeezeventConnected && isEventAdmin && (
                <>
                    <Spacer height="3" />

                    <Button
                        color="white"
                        isLoading={isVolunteersRegistrationsMassWeezeventSynchronizeLoading}
                        leftIcon="arrows-rotate"
                        rightIcon="arrow-right"
                        onClick={async () => {
                            await volunteersRegistrationsMassWeezeventSynchronize({
                                eventId: props.eventId,
                                usersInfosIds: [props.userInfo.id]
                            });
                        }}
                    >
                        {translate('synchroniser_av_82451')}
                    </Button>
                </>
            )}

            {hasFeature(Feature.Accreditation) && isEventAdmin && (
                <>
                    <Spacer height="6" />

                    <Box color="gray800" fontSize="textMd" fontWeight="semiBold">
                        {translate('Accreditation')}
                    </Box>

                    {(props.accreditationState === AccreditationState.NotApplicable ||
                        props.accreditationState === AccreditationState.Refused ||
                        props.accreditationState === AccreditationState.Accredited) && (
                        <>
                            <Spacer height="3" />

                            <Button
                                color="white"
                                isLoading={isAccreditationStateWaitingAccreditationLoading}
                                leftIcon="arrow-right-arrow-left"
                                rightIcon="arrow-right"
                                onClick={async () => {
                                    await accreditationStateWaitingAccreditation({
                                        eventId: props.eventId,
                                        volunteerRegistrationId: props.volunteerRegistrationId,
                                        state: AccreditationState.WaitingAccreditation
                                    });

                                    props.onUpdateState();
                                }}
                            >
                                {translate('changer_le_stat_20528')}
                            </Button>
                        </>
                    )}

                    {(props.accreditationState === AccreditationState.Accredited ||
                        props.accreditationState === AccreditationState.WaitingAccreditation ||
                        props.accreditationState === AccreditationState.NotApplicable) && (
                        <>
                            <Spacer height="3" />

                            <Button
                                color="white"
                                isLoading={isAccreditationStateWaitingAccreditationLoading}
                                leftIcon="arrow-right-arrow-left"
                                rightIcon="arrow-right"
                                onClick={async () => {
                                    await accreditationStateWaitingAccreditation({
                                        eventId: props.eventId,
                                        volunteerRegistrationId: props.volunteerRegistrationId,
                                        state: AccreditationState.Refused
                                    });

                                    props.onUpdateState();
                                }}
                            >
                                {translate('changer_le_stat_68392')}
                            </Button>
                        </>
                    )}

                    {(props.accreditationState === AccreditationState.WaitingAccreditation ||
                        props.accreditationState === AccreditationState.Accredited) && (
                        <>
                            <Spacer height="3" />

                            <Button
                                color="white"
                                isLoading={isAccreditationStateNotApplicableLoading}
                                leftIcon="arrow-right-arrow-left"
                                rightIcon="arrow-right"
                                onClick={async () => {
                                    await accreditationStateNotApplicable({
                                        eventId: props.eventId,
                                        volunteerRegistrationId: props.volunteerRegistrationId,
                                        state: AccreditationState.NotApplicable
                                    });

                                    props.onUpdateState();
                                }}
                            >
                                {translate('changer_le_stat_62507')}
                            </Button>
                        </>
                    )}

                    {props.accreditationState === AccreditationState.Accredited && (
                        <>
                            <Spacer height="3" />

                            <Button
                                color="white"
                                leftIcon="pen"
                                rightIcon="arrow-right"
                                to={HeaventPaths.ACCREDIT(
                                    props.organizationId,
                                    props.eventId,
                                    props.userInfo.id
                                )}
                            >
                                {translate('_diter_les_accr_86042')}
                            </Button>
                        </>
                    )}

                    {props.accreditationState === AccreditationState.WaitingAccreditation && (
                        <>
                            <Spacer height="3" />

                            <Button
                                color="white"
                                leftIcon="badge-check"
                                rightIcon="arrow-right"
                                to={HeaventPaths.ACCREDIT(
                                    props.organizationId,
                                    props.eventId,
                                    props.userInfo.id
                                )}
                            >
                                {translate('accr_diter_70543')}
                            </Button>
                        </>
                    )}

                    {props.accreditationState === AccreditationState.Accredited && (
                        <>
                            <Spacer height="3" />

                            <Button
                                color="white"
                                isLoading={isEmailResendLoading}
                                leftIcon="paper-plane"
                                rightIcon="arrow-right"
                                onClick={async () => {
                                    await emailResend({
                                        eventId: props.eventId,
                                        userInfoId: props.userInfo.id,
                                        emailType: EmailType.Accreditations
                                    });
                                }}
                            >
                                {translate('r_envoyer_l_e_24677')}
                            </Button>
                        </>
                    )}
                </>
            )}

            {hasDocuments && (
                <>
                    <Spacer height="6" />

                    <Box color="gray800" fontSize="textMd" fontWeight="semiBold">
                        {translate('documents_87028')}
                    </Box>

                    <Spacer height="3" />
                </>
            )}

            {hasDocuments && props.state === VolunteerRegistrationState.Assigned && (
                <>
                    <Button
                        color="white"
                        download={`${props.userInfo.nameOrEmail}-assignments`}
                        leftIcon="arrow-down-to-bracket"
                        rightIcon="arrow-right"
                        target="_blank"
                        to={{
                            pathname: getDocumentFullPath(props.links!.userAssignmentsLink, 'pdf')
                        }}
                    >
                        {translate('t_l_charger_la_82882')}
                    </Button>

                    <Spacer height="3" />

                    <Button
                        color="white"
                        download={`${props.userInfo.nameOrEmail}-planning`}
                        leftIcon="arrow-down-to-bracket"
                        rightIcon="arrow-right"
                        target="_blank"
                        to={{
                            pathname: getDocumentFullPath(props.links!.userPlanningLink, 'pdf')
                        }}
                    >
                        {translate('t_l_charger_le_74660')}
                    </Button>

                    <Spacer height="3" />

                    <Button
                        color="white"
                        download={`${props.userInfo.nameOrEmail}-planning-days`}
                        leftIcon="arrow-down-to-bracket"
                        rightIcon="arrow-right"
                        target="_blank"
                        to={{
                            pathname: getDocumentFullPath(props.links!.userPlanningDaysLink, 'pdf')
                        }}
                    >
                        {translate('t_l_charger_le_43587')}
                    </Button>

                    <Spacer height="3" />

                    <Button
                        color="white"
                        download={`${props.userInfo.nameOrEmail}-planning-list`}
                        leftIcon="arrow-down-to-bracket"
                        rightIcon="arrow-right"
                        target="_blank"
                        to={{
                            pathname: getDocumentFullPath(props.links!.userPlanningListLink, 'pdf')
                        }}
                    >
                        {translate('t_l_charger_le_58518')}
                    </Button>

                    {hasFeature(Feature.Documents) && (
                        <>
                            <Spacer height="3" />

                            <Button
                                color="white"
                                download={`${props.userInfo.nameOrEmail}-badge`}
                                leftIcon="arrow-down-to-bracket"
                                rightIcon="arrow-right"
                                target="_blank"
                                to={{
                                    pathname: getDocumentFullPath(props.links!.userBadgeLink, 'pdf')
                                }}
                            >
                                {translate('t_l_charger_le_53240')}
                            </Button>

                            <Spacer height="3" />

                            <Button
                                color="white"
                                download={`${props.userInfo.nameOrEmail}-certificate`}
                                leftIcon="arrow-down-to-bracket"
                                rightIcon="arrow-right"
                                target="_blank"
                                to={{
                                    pathname: getDocumentFullPath(
                                        props.links!.userCertificateLink,
                                        'pdf'
                                    )
                                }}
                            >
                                {translate('t_l_charger_l_a_02386')}
                            </Button>
                        </>
                    )}
                </>
            )}

            {organization.customBadges.map((customBadge) => (
                <React.Fragment key={customBadge}>
                    <Spacer height="3" />

                    <Button
                        color="white"
                        download={`${props.userInfo.nameOrEmail}-custom-badge`}
                        leftIcon="arrow-down-to-bracket"
                        rightIcon="arrow-right"
                        target="_blank"
                        to={{
                            pathname: getDocumentFullPath(
                                props.links!.userCustomBadgeLinks[customBadge],
                                'pdf'
                            )
                        }}
                    >
                        {translate('t_l_charger_1_80752', getDisplayName(customBadge))}
                    </Button>
                </React.Fragment>
            ))}

            {customDocuments.map((customDocument) => (
                <React.Fragment key={customDocument.id}>
                    <Spacer height="3" />

                    <Button
                        color="white"
                        download={`${props.userInfo.name}-custom-document`}
                        leftIcon="arrow-down-to-bracket"
                        rightIcon="arrow-right"
                        target="_blank"
                        to={{
                            pathname: getDocumentFullPath(customDocument.link, 'pdf')
                        }}
                    >
                        {translate('t_l_charger_1_80752', customDocument.name)}
                    </Button>
                </React.Fragment>
            ))}

            {isEventAdmin && (
                <>
                    <Spacer height="6" />

                    <Box color="gray800" fontSize="textMd" fontWeight="semiBold">
                        {translate('g_n_ral_64953')}
                    </Box>

                    {hasFeature(Feature.Delegation) && props.delegation && (
                        <>
                            <Spacer height="3" />

                            <Button
                                color="white"
                                leftIcon="minus"
                                rightIcon="arrow-right"
                                onClick={() => setIsRemoveFromDelegationOpen(true)}
                            >
                                {translate('retirer_de_la_d_64963')}
                            </Button>
                        </>
                    )}
                    <Spacer height="3" />

                    <Button
                        color="white"
                        leftIcon="trash-can"
                        rightIcon="arrow-right"
                        onClick={() => setIsDeleteModalOpen(true)}
                    >
                        {translate('d_sinscrire_de_06416')}
                    </Button>
                </>
            )}

            {hasFeature(Feature.Delegation) && props.delegation && isRemoveFromDelegationOpen && (
                <DeleteModal
                    buttonText={translate('retirer_de_la_d_64963')}
                    subtext={[translate('_tes_vous_sur_d_34901', props.delegation.name)]}
                    text={translate('suppression_de_73669', props.userInfo.name)}
                    onClose={() => setIsRemoveFromDelegationOpen(false)}
                    onDelete={async () => {
                        await userInfoDelegationDelete({
                            organizationId: organization.id,
                            eventId: props.eventId,
                            userInfoId: props.userInfo.id,
                            delegationId: props.delegation!.id
                        });

                        props.onDelete();
                    }}
                />
            )}

            {isDeleteModalOpen && (
                <DeleteModal
                    buttonText={translate('d_sinscrire_27046')}
                    subtext={[
                        translate('_tes_vous_s_r_d_29576'),
                        translate('_1_sera_toujou_13334', props.userInfo.nameOrEmail)
                    ]}
                    text={translate('d_sinscription_69522', props.userInfo.nameOrEmail)}
                    onClose={() => setIsDeleteModalOpen(false)}
                    onDelete={async () => {
                        await volunteerRegistrationDelete({
                            eventId: props.eventId,
                            usersInfosIds: [props.userInfo.id]
                        });

                        props.onDelete();
                    }}
                />
            )}

            <Spacer height="8" />
        </Flex>
    );
};
