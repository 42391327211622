import { compact, groupBy, intersection, sortBy, uniqBy } from 'lodash-es';
import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { Spacer } from '../../../designSystem/components/spacer';
import { Span } from '../../../designSystem/components/span';
import { CommonEnvVars } from '../../../envVars';
import { DocumentUserCustomBadgeFragment, PositionsCategoryId } from '../../../generated/types';
import { isNonEmptyArray } from '../../../util/array';
import { A4_SIZES } from '../../../util/pdf';
import { isNonEmptyString } from '../../../util/string';

const CATERING_MIDI_SOIR_ID = 2682;
const CATERING_MIDI_ID = 2649;
const CATERING_SOIR_ID = 2650;

const getFrontBackground = (categoriesIds: PositionsCategoryId[]) => {
    if (isNonEmptyArray(intersection(categoriesIds, [13901, 16909, 13902, 13905]))) {
        return `https://assets.recrewteer.com/badges/francofoliesEsch/benevoles/v2/contremarque_page1A.jpg`;
    } else if (isNonEmptyArray(intersection(categoriesIds, [11668, 13985]))) {
        return `https://assets.recrewteer.com/badges/francofoliesEsch/benevoles/v2/contremarque_page1B.jpg`;
    } else {
        return `https://assets.recrewteer.com/badges/francofoliesEsch/benevoles/v2/contremarque_page1C.jpg`;
    }
};

interface IBadgeFrancofoliesEschProps {
    event: DocumentUserCustomBadgeFragment;
}

export const BadgeFrancofoliesEsch = (props: IBadgeFrancofoliesEschProps) => {
    const vr = props.event.volunteerRegistration;
    const ui = vr.userInfo;
    const positionsCategories = uniqBy(
        vr.positionsSlotsUsersInfos.map(({ positionCategory }) => positionCategory),
        (pc) => pc.id
    );
    const categoriesIds = positionsCategories.map(({ id }) => id);
    const categoriesString = sortBy(
        positionsCategories
            .filter((pc) => pc.id !== 15570 && pc.id !== 16024)
            .map(({ name }) => name)
    ).join(', ');
    const groupedByDay = sortBy(
        Object.entries(
            groupBy(vr.positionsSlotsUsersInfos, (psui) =>
                psui.positionSlot.range.start!.startOf('day').toMillis()
            )
        ),
        ([start]) => start
    );
    const midiSoir = vr.accreditationsUsersInfos.flatMap((aui) =>
        aui.accreditation.id === CATERING_MIDI_SOIR_ID ? [aui.accreditationSlot.name] : []
    );
    const midi = vr.accreditationsUsersInfos
        .flatMap((aui) =>
            aui.accreditation.id === CATERING_MIDI_ID ? [aui.accreditationSlot.name] : []
        )
        .join(', ');
    const soir = vr.accreditationsUsersInfos
        .flatMap((aui) =>
            aui.accreditation.id === CATERING_SOIR_ID ? [aui.accreditationSlot.name] : []
        )
        .join(', ');
    const catering = compact([midiSoir, midi, soir]).join(', ');

    return ui.fields?.cf21345?.value === 'Bénévoles' ? (
        <>
            <Flex
                css={{
                    background: `url(${getFrontBackground(categoriesIds)}) no-repeat`,
                    backgroundSize: 'cover',
                    fontFamily: '$urbanist',
                    position: 'relative'
                }}
                height={A4_SIZES['96dpi'].height}
                width={A4_SIZES['96dpi'].width}
            >
                <Box
                    css={{
                        color: '#26377a',
                        fontSize: '18px',
                        fontWeight: '800',
                        left: '56px',
                        position: 'absolute',
                        textTransform: 'uppercase',
                        top: '341px'
                    }}
                >
                    Bienvenue {ui.firstName}
                </Box>
            </Flex>

            <Flex
                css={{
                    background: `url(https://assets.recrewteer.com/badges/francofoliesEsch/benevoles/v2/contremarque_benevole_page2.jpg) no-repeat`,
                    backgroundSize: 'cover',
                    fontFamily: '$urbanist',
                    pageBreakBefore: 'always',
                    position: 'relative'
                }}
                height={A4_SIZES['96dpi'].height}
                width={A4_SIZES['96dpi'].width}
            >
                <Flex
                    css={{
                        color: 'black',
                        fontSize: '24px',
                        fontWeight: '800',
                        left: '96px',
                        position: 'absolute',
                        textTransform: 'uppercase',
                        top: '379px'
                    }}
                    direction="column"
                >
                    <Box>{ui.firstName}</Box>
                    <Box>{ui.lastName}</Box>

                    <Spacer height="2" />

                    <Box
                        css={{
                            color: '#26377a',
                            fontSize: '22px',
                            fontWeight: '600',
                            textTransform: 'capitalize'
                        }}
                    >
                        Bénévole | {categoriesString}
                    </Box>
                </Flex>

                <Flex
                    align="center"
                    css={{
                        position: 'absolute',
                        right: '96px',
                        top: '379px'
                    }}
                    direction="column"
                    gap="1"
                >
                    <Box height={90} width={90}>
                        {isNonEmptyString(vr.weezeventParticipantInfo?.idBarcode) && (
                            <img
                                height="100%"
                                src={`https://api.${
                                    CommonEnvVars.HEAVENT_EXTERNAL_DOMAIN
                                }/barcode/qr?text=${encodeURIComponent(
                                    vr.weezeventParticipantInfo!.idBarcode
                                )}&color=000000&height=90&width=90`}
                                width="100%"
                            />
                        )}
                    </Box>

                    <Box
                        css={{
                            color: 'black',
                            fontWeight: '600'
                        }}
                    >
                        {vr.weezeventParticipantInfo?.idBarcode || ''}
                    </Box>
                </Flex>
            </Flex>

            <Flex
                css={{
                    background: `url(https://assets.recrewteer.com/badges/francofoliesEsch/benevoles/contremarque_page3.jpg) no-repeat`,
                    backgroundSize: 'cover',
                    fontFamily: '$urbanist',
                    pageBreakBefore: 'always',
                    position: 'relative'
                }}
                height={A4_SIZES['96dpi'].height}
                width={A4_SIZES['96dpi'].width}
            >
                <Flex
                    css={{
                        position: 'absolute',
                        px: '96px',
                        top: '366px'
                    }}
                    direction="column"
                >
                    <Box height={40} width={170}>
                        <img
                            height="100%"
                            src="https://assets.recrewteer.com/badges/francofoliesEsch/benevoles/v2/planning.jpg"
                            width="100%"
                        />
                    </Box>

                    <Spacer height="6" />

                    <Flex direction="column" gap="3">
                        {groupedByDay.map(([_, psuis], index) => {
                            const first = psuis[0];

                            return (
                                <Flex key={index} direction="column" gap="1">
                                    <Box
                                        css={{
                                            color: 'black',
                                            fontSize: '16px',
                                            fontWeight: '800',
                                            textTransform: 'uppercase'
                                        }}
                                    >
                                        {first.positionSlot.range.start!.toFormat(
                                            'cccc d MMMM yyyy',
                                            {
                                                locale: 'fr'
                                            }
                                        )}
                                    </Box>

                                    <Flex direction="column" gap="2">
                                        {psuis.map(({ position, positionSlot }) => {
                                            const leader = position.leaders[0];

                                            return (
                                                <Box key={positionSlot.id} fontSize="textMd">
                                                    <Span fontWeight="semiBold">
                                                        {position.name}
                                                    </Span>{' '}
                                                    | De{' '}
                                                    {positionSlot.range.start!.toFormat('H:mm')} à{' '}
                                                    {positionSlot.range.end!.toFormat('H:mm')} |{' '}
                                                    {leader
                                                        ? `${leader.userInfo.name} ${leader.userInfo.phone?.internationalFormat}`
                                                        : ''}
                                                </Box>
                                            );
                                        })}
                                    </Flex>
                                </Flex>
                            );
                        })}
                    </Flex>

                    <Spacer height="10" />

                    <Box height={40} width={164}>
                        <img
                            height="100%"
                            src="https://assets.recrewteer.com/badges/francofoliesEsch/benevoles/v2/catering.png"
                            width="100%"
                        />
                    </Box>

                    <Spacer height="6" />

                    <Flex direction="column" gap="2">
                        <Box fontSize="textMd">
                            Un repas au catering est prévu pour toi le(s) :{' '}
                        </Box>
                        <Box fontSize="textMd" fontWeight="semiBold">
                            {catering}
                        </Box>
                        <Box fontSize="textMd">
                            Tu pourras le prendre au stade près de la tente bénévoles. Tu as indiqué
                            être {ui.fields?.cf22890?.value} merci de respecter ton choix !
                        </Box>
                    </Flex>
                </Flex>
            </Flex>
        </>
    ) : (
        <Flex
            css={{
                background: `url(https://assets.recrewteer.com/badges/francofoliesEsch/benevoles/v2/contremarque_staff.jpg) no-repeat`,
                backgroundSize: 'cover',
                fontFamily: '$urbanist',
                position: 'relative'
            }}
            height={A4_SIZES['96dpi'].height}
            width={A4_SIZES['96dpi'].width}
        >
            <Flex
                css={{
                    color: 'black',
                    fontSize: '24px',
                    fontWeight: '600',
                    left: '56px',
                    position: 'absolute',
                    textTransform: 'uppercase',
                    top: '401px'
                }}
                direction="column"
            >
                <Box>{ui.firstName}</Box>
                <Box>{ui.lastName}</Box>

                <Spacer height="2" />

                <Box
                    css={{
                        color: '#26377a',
                        fontSize: '22px',
                        fontWeight: '500',
                        textTransform: 'capitalize'
                    }}
                >
                    {vr.delegation?.name ?? ''}
                </Box>
            </Flex>

            <Flex
                align="center"
                css={{
                    position: 'absolute',
                    right: '56px',
                    top: '401px'
                }}
                direction="column"
                gap="1"
            >
                <Box height={90} width={90}>
                    {isNonEmptyString(vr.weezeventParticipantInfo?.idBarcode) && (
                        <img
                            height="100%"
                            src={`https://api.${
                                CommonEnvVars.HEAVENT_EXTERNAL_DOMAIN
                            }/barcode/qr?text=${encodeURIComponent(
                                vr.weezeventParticipantInfo!.idBarcode
                            )}&color=000000&height=90&width=90`}
                            width="100%"
                        />
                    )}
                </Box>

                <Box
                    css={{
                        color: 'black',
                        fontWeight: '600'
                    }}
                >
                    {vr.weezeventParticipantInfo?.idBarcode || ''}
                </Box>
            </Flex>
        </Flex>
    );
};
