import { Button } from 'common-front/src/designSystem/components/button';
import { DeleteModal } from 'common-front/src/designSystem/components/deleteModal';
import { Dropdown } from 'common-front/src/designSystem/components/dropdown/dropdown';
import { ItemIcon } from 'common-front/src/designSystem/components/dropdown/item';
import { Menu } from 'common-front/src/designSystem/components/dropdown/menu';
import { Trigger } from 'common-front/src/designSystem/components/dropdown/trigger';
import { useSegmentsContext } from 'common-front/src/segments/segmentsContext';
import {
    PositionId,
    PositionsMassEditInput,
    PositionsSortAttributes
} from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import {
    usePositionsMassDeleteMutation,
    usePositionsMassEditMutation
} from '../../../generated/graphqlHooks';
import { MassConditionsModal } from '../../massConditions/massConditionsModal';
import { MassEditModal } from '../../massEdit/massEditModal';

export const PositionsActions = () => {
    const translate = useTranslate();
    const {
        eventId,
        isSegmentSelected,
        massEditSegmentId,
        massEditSelectedIds,
        numberOfSelected,
        onMassEditSuccess,
        organizationId,
        reload,
        segment
    } = useSegmentsContext<PositionId, PositionsSortAttributes>();
    const { mutate: positionsMassEdit } = usePositionsMassEditMutation();
    const { mutate: positionsMassDelete } = usePositionsMassDeleteMutation();
    const [isMassEditVisible, setIsMassEditVisible] = React.useState(false);
    const [isMassConditionsVisible, setIsMassConditionsVisible] = React.useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
    const finalNumberOfSelected = isSegmentSelected ? segment.count : numberOfSelected;

    return (
        <>
            <Button
                onClick={() => {
                    setIsMassEditVisible(true);
                }}
            >
                {translate('_diter_une_prop_26724')}
            </Button>

            <Dropdown>
                <Trigger>
                    <Button color="white" leftIcon="ellipsis-vertical" />
                </Trigger>

                <Menu placement="bottom-end">
                    <ItemIcon
                        icon="lock"
                        onClick={() => {
                            setIsMassConditionsVisible(true);
                        }}
                    >
                        {translate('_diter_les_cond_95618')}
                    </ItemIcon>

                    <ItemIcon
                        color="red"
                        icon="trash-can"
                        onClick={() => {
                            setIsDeleteModalOpen(true);
                        }}
                    >
                        {translate('supprimer_43083')}
                    </ItemIcon>
                </Menu>
            </Dropdown>

            {isMassEditVisible && (
                <MassEditModal
                    eventId={eventId!}
                    ids={massEditSelectedIds}
                    mutate={(massEdit: PositionsMassEditInput) =>
                        positionsMassEdit({
                            eventId: eventId!,
                            massEdit
                        })
                    }
                    organizationId={organizationId}
                    segmentId={massEditSegmentId}
                    type="positions"
                    onClose={() => {
                        setIsMassEditVisible(false);
                    }}
                    onSuccess={onMassEditSuccess}
                />
            )}

            {isMassConditionsVisible && (
                <MassConditionsModal
                    eventId={eventId!}
                    organizationId={organizationId}
                    positionsIds={massEditSelectedIds}
                    segmentId={massEditSegmentId}
                    onClose={() => {
                        setIsMassConditionsVisible(false);
                    }}
                    onSuccess={onMassEditSuccess}
                />
            )}

            {isDeleteModalOpen && (
                <DeleteModal
                    buttonText={translate('supprimer_43083')}
                    subtext={[translate('_tes_vous_s_r_d_65487')]}
                    text={translate('suppression_de_81835', finalNumberOfSelected)}
                    onClose={() => {
                        setIsDeleteModalOpen(false);
                    }}
                    onDelete={async () => {
                        await positionsMassDelete({
                            eventId: eventId!,
                            positionsIds: massEditSelectedIds,
                            segmentId: massEditSegmentId
                        });

                        reload();
                    }}
                />
            )}
        </>
    );
};
