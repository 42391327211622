import { BaseFlexProps, Flex, pickBaseFlexProps } from 'common/src/designSystem/components/flex';
import * as React from 'react';

interface IContentProps extends BaseFlexProps {
    children: React.ReactNode;
}

export const Content = (props: IContentProps) => (
    <Flex
        css={{
            borderTop: '1px solid $gray200',
            padding: '$5 $7 $7 $7'
        }}
        direction="column"
        {...pickBaseFlexProps(props)}
    >
        {props.children}
    </Flex>
);
