import { Button } from 'common-front/src/designSystem/components/button';
import { IconBackground } from 'common-front/src/designSystem/components/iconBackground';
import { Modal } from 'common-front/src/designSystem/components/modal';
import { RichEditor } from 'common-front/src/designSystem/components/richEditor/richEditor';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';

interface IAddTextModalProps {
    initialText: string;

    onAdd(text: string): void;
    onClose(): void;
}

export const AddTextModal = (props: IAddTextModalProps) => {
    const translate = useTranslate();
    const [text, setText] = React.useState(props.initialText);

    return (
        <Modal size="md" onClose={props.onClose}>
            <Flex justify="center">
                <IconBackground color="primary" icon="sliders" />
            </Flex>

            <Spacer height="4" />

            <Box color="gray900" fontSize="textLg" fontWeight="medium" textAlign="center">
                {translate('champ_texte_lib_91587')}
            </Box>

            <Spacer height="1" />

            <Box color="gray500" textAlign="center">
                {translate('ajouter_des_cha_68672')}
            </Box>

            <Spacer height="7" />

            <Box css={{ overflow: 'hidden auto' }} width={1}>
                <RichEditor initialValue={text} onChange={setText} />
            </Box>

            <Spacer height="7" />

            <Flex gap="3" justify="end">
                <Button
                    onClick={() => {
                        props.onAdd(text);
                        props.onClose();
                    }}
                >
                    {isNonEmptyString(props.initialText)
                        ? translate('mettre_jour_l_69246')
                        : translate('ajouter_le_cham_74670')}
                </Button>

                <Button color="white" onClick={props.onClose}>
                    {translate('annuler_48254')}
                </Button>
            </Flex>
        </Modal>
    );
};
