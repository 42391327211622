import { BaseFlexProps, Flex, pickBaseFlexProps } from 'common/src/designSystem/components/flex';
import * as React from 'react';

interface IHeaderProps extends BaseFlexProps {
    children: React.ReactNode;
    hideBorder?: boolean;
}

export const Header = (props: IHeaderProps) => (
    <Flex
        css={{
            background: 'white',
            borderBottom: props.hideBorder ? '' : '1px solid $gray200',
            padding: '$6 $7'
        }}
        {...pickBaseFlexProps(props)}
    >
        {props.children}
    </Flex>
);
