import { DeleteModal } from 'common-front/src/designSystem/components/deleteModal';
import {
    closeOtherDropdowns,
    Dropdown
} from 'common-front/src/designSystem/components/dropdown/dropdown';
import { ItemIcon } from 'common-front/src/designSystem/components/dropdown/item';
import { Menu } from 'common-front/src/designSystem/components/dropdown/menu';
import { Trigger } from 'common-front/src/designSystem/components/dropdown/trigger';
import { AvatarNameEmail } from 'common/src/components/avatarNameEmail';
import { Badge } from 'common/src/designSystem/components/badge';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { Row } from 'common/src/designSystem/components/table/row';
import { EventAdminsLeadersQuery, EventId } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';
import {
    useEmailEventInviteResendMutation,
    useUserEventDeleteMutation
} from '../../generated/graphqlHooks';

interface IEventUserProps {
    eventId: EventId;
    user: EventAdminsLeadersQuery['event']['adminsLeaders']['nodes'][0];

    reload(): void;
}

export const EventUser = (props: IEventUserProps) => {
    const translate = useTranslate();
    const { mutate: emailResend } = useEmailEventInviteResendMutation();
    const { mutate: userDelete } = useUserEventDeleteMutation();

    const [isDeleteOpen, setIsDeleteOpen] = React.useState(false);

    return (
        <Row>
            <Cell>
                <AvatarNameEmail
                    hasName={isNonEmptyString(props.user.userInfo.name)}
                    userInfo={props.user.userInfo}
                />
            </Cell>
            <Cell>
                <Badge color="primary">{translate('administrateur_38490')}</Badge>
            </Cell>
            <Cell />
            <Cell />
            <Cell justify="end" width={100}>
                <Dropdown>
                    <Trigger>
                        <Flex
                            align="center"
                            css={{ cursor: 'pointer' }}
                            height={20}
                            justify="center"
                            width={20}
                        >
                            <I icon="ellipsis-vertical" />
                        </Flex>
                    </Trigger>

                    <Menu placement="bottom-end">
                        <ItemIcon
                            icon="paper-plane"
                            onClick={async (e) => {
                                closeOtherDropdowns(e.target);

                                await emailResend({
                                    eventId: props.eventId,
                                    userInfoId: props.user.userInfo.id
                                });
                            }}
                        >
                            {translate('r_envoyer_l_e_96215')}
                        </ItemIcon>

                        <ItemIcon
                            color="red"
                            icon="trash-can"
                            onClick={(e) => {
                                closeOtherDropdowns(e.target);

                                setIsDeleteOpen(true);
                            }}
                        >
                            {translate('supprimer_l_acc_85342')}
                        </ItemIcon>
                    </Menu>
                </Dropdown>
            </Cell>

            {isDeleteOpen && (
                <DeleteModal
                    buttonText="Supprimer"
                    subtext={translate('_tes_vous_s_r_d_72161', props.user.userInfo.email)}
                    text={translate('supprimer_l_acc_85342')}
                    onClose={() => {
                        setIsDeleteOpen(false);
                    }}
                    onDelete={async () => {
                        await userDelete({
                            eventId: props.eventId,
                            userId: props.user.userId
                        });

                        props.reload();
                    }}
                />
            )}
        </Row>
    );
};
