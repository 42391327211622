import { Alert } from 'common-front/src/designSystem/components/alert';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { HeaderRow } from 'common/src/designSystem/components/table/headerRow';
import { Table } from 'common/src/designSystem/components/table/table';
import { CommonEnvVars } from 'common/src/envVars';
import { DkimStatus, EmailType, EventId, OrganizationId } from 'common/src/generated/types';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import { useEmailsTemplatesQuery } from '../../generated/graphqlHooks';
import { EmailTypeRow } from './emailTypeRow';
import { Template } from './template';

interface ITemplatesProps {
    organizationId: OrganizationId;
    eventId: EventId;
}

export const Templates = (props: ITemplatesProps) => {
    const { history, translate } = useHeavent();
    const { data, loader } = useEmailsTemplatesQuery({ eventId: props.eventId });
    const [emailType, setEmailType] = React.useState(EmailType.Assignments);

    return (
        loader || (
            <>
                {(!data.event.emailSender ||
                    data.event.emailSender.domainStatus !== DkimStatus.Success) && (
                    <>
                        <Flex gap="4" width={1}>
                            {data.event.emailSender &&
                                data.event.emailSender.domainStatus !== DkimStatus.Success && (
                                    <Alert color="error" leftIcon="circle-exclamation" width={1}>
                                        <Flex
                                            css={{ fontWeight: '$medium' }}
                                            direction="column"
                                            gap="3"
                                        >
                                            <Box>{translate('vos_entr_es_dns_68834')}</Box>

                                            <Flex
                                                align="center"
                                                css={{ cursor: 'pointer' }}
                                                gap="3"
                                                onClick={() => {
                                                    history.push(
                                                        HeaventPaths.ORGANIZATION_PARAMETERS_EMAILS_DNS(
                                                            props.organizationId
                                                        )
                                                    );
                                                }}
                                            >
                                                <Box>{translate('configurer_les_65453')}</Box>
                                                <Box>
                                                    <I icon="arrow-right" />
                                                </Box>
                                            </Flex>
                                        </Flex>
                                    </Alert>
                                )}

                            {!data.event.emailSender && (
                                <Alert color="warning" leftIcon="triangle-exclamation" width={1}>
                                    <Flex
                                        css={{ fontWeight: '$medium' }}
                                        direction="column"
                                        gap="3"
                                    >
                                        <Box>
                                            {translate(
                                                'aucun_metteur_26635',
                                                CommonEnvVars.EMAIL_FROM
                                            )}
                                        </Box>

                                        <Flex
                                            align="center"
                                            css={{ cursor: 'pointer' }}
                                            gap="3"
                                            onClick={() => {
                                                history.push(
                                                    HeaventPaths.ORGANIZATION_PARAMETERS_EMAILS_SENDERS(
                                                        props.organizationId
                                                    )
                                                );
                                            }}
                                        >
                                            <Box>{translate('configurer_les_56837')}</Box>
                                            <Box>
                                                <I icon="arrow-right" />
                                            </Box>
                                        </Flex>
                                    </Flex>
                                </Alert>
                            )}
                        </Flex>

                        <Spacer height="6" />
                    </>
                )}

                <Flex gap="7">
                    <Flex css={{ flex: '1', py: '$3' }} direction="column">
                        <Box color="gray900" fontSize="textLg" fontWeight="medium">
                            {translate('liste_des_e_mai_73508')}
                        </Box>

                        <Spacer height="6" />

                        <Table>
                            <HeaderRow>
                                <HeaderCell>{translate('type_d_e_mail_06577')}</HeaderCell>
                            </HeaderRow>

                            <EmailTypeRow
                                isSelected={emailType === EmailType.VolunteerRegistration}
                                subtitle={translate('cet_e_mail_est_75023')}
                                title={translate('UserInfoAdminCreate')}
                                onClick={() => setEmailType(EmailType.VolunteerRegistration)}
                            />

                            <EmailTypeRow
                                isSelected={emailType === EmailType.Assignments}
                                subtitle={translate('cet_e_mail_cont_98777')}
                                title={translate('affectation_u_11128')}
                                onClick={() => setEmailType(EmailType.Assignments)}
                            />

                            <EmailTypeRow
                                isSelected={emailType === EmailType.Refused}
                                subtitle={translate('cet_e_mail_est_69069')}
                                title={translate('refus_d_affecta_71455')}
                                onClick={() => setEmailType(EmailType.Refused)}
                            />

                            <EmailTypeRow
                                isSelected={emailType === EmailType.Accreditations}
                                subtitle={translate('cet_e_mail_cont_75798')}
                                title={translate('affectation_u_39802')}
                                onClick={() => setEmailType(EmailType.Accreditations)}
                            />

                            <EmailTypeRow
                                isSelected={emailType === EmailType.AccreditationsRefused}
                                subtitle={translate('cet_e_mail_est_69069')}
                                title={translate('refus_d_accr_di_89997')}
                                onClick={() => setEmailType(EmailType.AccreditationsRefused)}
                            />

                            <EmailTypeRow
                                isSelected={emailType === EmailType.DelegationLeaderAssign}
                                subtitle={translate('email_sent_when_appointing_team_manager')}
                                title={translate('appointment_as_team_manager')}
                                onClick={() => setEmailType(EmailType.DelegationLeaderAssign)}
                            />

                            <EmailTypeRow
                                isSelected={emailType === EmailType.PositionLeaderAssign}
                                subtitle={translate('cet_e_mail_est_82173')}
                                title={translate('nomination_en_t_06971')}
                                onClick={() => setEmailType(EmailType.PositionLeaderAssign)}
                            />

                            <EmailTypeRow
                                isSelected={emailType === EmailType.PositionCategoryLeaderAssign}
                                subtitle={translate('cet_e_mail_est_15118')}
                                title={translate('nomination_en_t_14476')}
                                onClick={() => setEmailType(EmailType.PositionCategoryLeaderAssign)}
                            />

                            <EmailTypeRow
                                isSelected={emailType === EmailType.NotifyLeaders}
                                subtitle={translate('cet_e_mail_est_90425')}
                                title={translate('un_responsable_88525')}
                                onClick={() => setEmailType(EmailType.NotifyLeaders)}
                            />

                            <EmailTypeRow
                                isSelected={emailType === EmailType.Code}
                                subtitle={translate('cet_e_mail_est_49663')}
                                title={translate('code_20843')}
                                onClick={() => setEmailType(EmailType.Code)}
                            />
                        </Table>
                    </Flex>

                    <Box
                        css={{
                            borderRight: '1px solid $gray200',
                            width: '1px'
                        }}
                    />

                    <Flex css={{ flex: '1', py: '$3' }}>
                        <Template
                            emailType={emailType}
                            eventId={props.eventId}
                            formId={null}
                            organizationId={props.organizationId}
                            source={null}
                        />
                    </Flex>
                </Flex>
            </>
        )
    );
};
