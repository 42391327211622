import { styled } from 'common/src/designSystem/components/stitches';
import * as React from 'react';

const _Submit = styled('input', {
    left: '-9999px',
    position: 'absolute',
    top: '-9999px',
    visibility: 'hidden'
});

export const Submit = () => <_Submit tabIndex={-1} type="submit" value="Submit" />;
