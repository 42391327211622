import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { heaventEnvDisplayName, heaventEnvSupportEmail } from 'common/src/heaventEnv';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import BlockedImage from 'svgComponents/blocked.svg';

export const OrganizationBlocked = () => {
    const translate = useTranslate();

    return (
        <Flex
            align="center"
            css={{
                background: '$gray50'
            }}
            height={1}
            justify="center"
            width={1}
        >
            <Flex
                align="center"
                css={{
                    background: 'white',
                    borderRadius: '$2',
                    boxShadow: '$xs',
                    padding: '$9'
                }}
                direction="column"
                gap="2"
                justify="center"
                width={840}
            >
                <Box
                    css={{
                        '& svg': {
                            height: '100%',
                            width: '100%'
                        }
                    }}
                    height={140}
                    width={180}
                >
                    <BlockedImage />
                </Box>

                <Box color="gray900" fontSize="displayXs" fontWeight="semiBold">
                    {translate('votre_compte_1_85226', heaventEnvDisplayName())}
                </Box>

                <Box color="gray500" textAlign="center">
                    {translate('ne_vous_inqui_t_38880')} <br />
                    {translate(
                        `contactez_nous_35526`,
                        heaventEnvSupportEmail(),
                        heaventEnvDisplayName()
                    )}
                </Box>
            </Flex>
        </Flex>
    );
};
