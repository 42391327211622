import * as React from 'react';
import { Flex } from '../../../designSystem/components/flex';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { A6_SIZES } from '../../../util/pdf';

interface IBadgeBruleursA6BackProps {
    event: DocumentUserCustomBadgeFragment;
}

export const BadgeBruleursA6Back = (props: IBadgeBruleursA6BackProps) => {
    const background =
        props.event.id === 3838
            ? 'https://assets.recrewteer.com/badges/bruleurs/20242025/bg_accreditation_saison_verso.jpg'
            : 'https://assets.recrewteer.com/badges/bruleurs/20242025/bg_accreditation_match_verso.jpg';

    return (
        <Flex
            css={{
                background: `url(${background}) no-repeat`,
                backgroundSize: 'cover',
                pageBreakBefore: 'always'
            }}
            height={A6_SIZES['96dpi'].height}
            width={A6_SIZES['96dpi'].width}
        />
    );
};
