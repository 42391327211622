import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import * as React from 'react';
import { useSegmentsContext } from '../segmentsContext';

export const SegmentsClosed = () => {
    const { setAreSegmentsOpen } = useSegmentsContext();

    return (
        <Flex
            css={{
                background: '$gray50',
                py: '$7'
            }}
            height={1}
            justify="center"
            width={1}
        >
            <Box
                color="gray800"
                css={{ cursor: 'pointer' }}
                fontSize="textLg"
                onClick={() => {
                    setAreSegmentsOpen(true);
                }}
            >
                <I icon="arrow-right-from-line" />
            </Box>
        </Flex>
    );
};
